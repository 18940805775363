export const companies = [
  { code: 110012450, name: 'AB "Grigeo"' },
  { code: 110022220, name: 'UAB "KOMEX"' },
  {
    code: 110087517,
    name: 'Uždaroji akcinė bendrovė "Visagino energija"'
  },
  { code: 110241050, name: "Gedeon Richter Plc. atstovybė Lietuvoje" },
  {
    code: 110309494,
    name: 'Lietuvos ir Vengrijos uždaroji akcinė bendrovė "BRAČA-SPORT"'
  },
  { code: 110342843, name: 'UŽDAROJI AKCINĖ BENDROVĖ "INTERVILŽA"' },
  {
    code: 110382414,
    name: 'Lietuvos ir Prancūzijos uždaroji akcinė bendrovė "Eurolinen"'
  },
  {
    code: 110414641,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "KLAIPĖDOS DURBĖ"'
  },
  { code: 110433047, name: 'UAB "Mida LT"' },
  {
    code: 110451593,
    name: 'Bendra Lietuvos-Vokietijos įmonė uždaroji akcinė bendrovė "ALHANS"'
  },
  { code: 110484732, name: 'Uždaroji akcinė bendrovė "VITULA"' },
  { code: 110497046, name: 'Uždaroji akcinė bendrovė "KUERKUS"' },
  {
    code: 110547339,
    name: 'Bendra Lietuvos - Rusijos įmonė uždaroji akcinė bendrovė "TAUTILĖ"'
  },
  {
    code: 110553420,
    name: 'Lietuvos-Vokietijos-Lenkijos uždaroji akcinė bendrovė "INEZA"'
  },
  {
    code: 110572926,
    name: 'Bendra Lietuvos ir Vokietijos įmonė uždaroji akcinė bendrovė "THURINGIA MEGAPLAST - UKMERGĖ"'
  },
  {
    code: 110583940,
    name: 'Uždaroji akcinė bendrovė "SAEGEWERK UND PALETTENFERTIGUNG PAUL MAAG"'
  },
  { code: 110599930, name: "UAB Baltik vairas" },
  { code: 110612687, name: 'UAB "SAPROPEL ORGANICS"' },
  { code: 110618373, name: 'UAB "AVOTEC"' },
  { code: 110639887, name: 'Akcinė bendrovė "PLASTA"' },
  { code: 110652977, name: "Littelfuse LT, UAB" },
  { code: 110669492, name: 'Uždaroji akcinė bendrovė "MANTINGA"' },
  {
    code: 110673380,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "EUROPLAST BALT"'
  },
  {
    code: 110681480,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "JŪSŲ NAMAS"'
  },
  {
    code: 110689032,
    name: 'Lietuvos ir Suomijos uždaroji akcinė bendrovė "SAVOLAX"'
  },
  {
    code: 110692352,
    name: 'Bendra Lietuvos-Vokietijos įmonė uždaroji akcinė bendrovė "BAULITA"'
  },
  {
    code: 110697132,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "DALAUDAS"'
  },
  { code: 110698387, name: "UAB Gargždų plytų gamykla" },
  { code: 110702540, name: 'UAB "Aveplast"' },
  { code: 110703457, name: "Cane-line Lithuania, UAB" },
  { code: 110708760, name: 'Uždaroji akcinė bendrovė "Sandra and Co"' },
  { code: 110710455, name: 'Uždaroji akcinė bendrovė "ACHEMPAK"' },
  { code: 110711219, name: 'Uždaroji akcinė bendrovė "ASKOVITA"' },
  { code: 110711742, name: 'UAB "PAROC"' },
  {
    code: 110723673,
    name: 'Lietuvos ir Norvegijos uždaroji akcinė bendrovė "NORVELITA"'
  },
  {
    code: 110724394,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "Stilsas"'
  },
  { code: 110737089, name: 'Uždaroji akcinė bendrovė "CIPEL BALTIKA"' },
  { code: 110737317, name: 'UAB "MEFFERT BALTICA"' },
  {
    code: 110739478,
    name: 'LIETUVOS IR VOKIETIJOS UŽDAROJI AKCINĖ BENDROVĖ "GURAN"'
  },
  { code: 110742111, name: 'Uždaroji akcinė bendrovė "TOPPLASTAS"' },
  {
    code: 110746857,
    name: 'Lietuvos ir Didžiosios Britanijos uždaroji akcinė bendrovė "PIPRA"'
  },
  {
    code: 110751085,
    name: 'Lietuvos ir Olandijos uždaroji akcinė bendrovė "RAINBOW COATINGS"'
  },
  {
    code: 110753855,
    name: 'Uždaroji akcinė bendrovė "OSTSEE FISCH KRETINGA"'
  },
  { code: 110759541, name: 'UAB "Ežerėlio vaivorykštė"' },
  {
    code: 110760134,
    name: 'Lietuvos ir Italijos uždaroji akcinė bendrovė "Profilita"'
  },
  { code: 110760287, name: 'Lietuvos ir Prancūzijos UAB "DANIMEDA"' },
  { code: 110764912, name: "UAB GEMEGA" },
  {
    code: 110769311,
    name: 'Lietuvos ir Lenkijos uždaroji akcinė bendrovė "RUFAX"'
  },
  { code: 110773733, name: 'Uždaroji akcinė bendrovė "RUGOSA"' },
  { code: 110785479, name: 'UAB "MV Stimelit"' },
  { code: 110788817, name: 'UAB "United furniture workshop"' },
  {
    code: 110791941,
    name: 'Lietuvos ir Rusijos uždaroji akcinė bendrovė "Kvartonas"'
  },
  { code: 110795249, name: 'UAB "BRUCHER LAIPTAI"' },
  { code: 110796340, name: 'Uždaroji akcinė bendrovė "SABALIN"' },
  { code: 110797780, name: 'UAB "Lietmetas"' },
  {
    code: 110807598,
    name: 'Uždaroji akcinė bendrovė "ORGANIKA VILNIUS"'
  },
  {
    code: 110808351,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "ALGERMA"'
  },
  { code: 110810384, name: 'UAB "VMG Food"' },
  { code: 110818317, name: 'Uždaroji akcinė bendrovė "LITESKO"' },
  { code: 110820916, name: 'UAB "NATŪRALUS MEDIS"' },
  { code: 110830643, name: 'UAB "Šilputa"' },
  { code: 110833415, name: 'Uždaroji akcinė bendrovė "HANSEAT VILNIUS"' },
  { code: 110846971, name: "UAB PAINA IR KO" },
  { code: 110850336, name: 'UAB "LITSPIN"' },
  { code: 110853827, name: 'Uždaroji akcinė bendrovė "AUGERA"' },
  {
    code: 110854886,
    name: 'Uždaroji akcinė bendrovė "Baltijos marmuras"'
  },
  { code: 110855073, name: 'UŽDAROJI AKCINĖ BENDROVĖ "VISOLA"' },
  { code: 110855269, name: 'Lietuvos ir Baltarusijos UAB "Atana"' },
  { code: 110859328, name: 'UAB "FabricAir"' },
  { code: 110860444, name: 'UAB "VĖJO VATAS"' },
  { code: 110860978, name: '"ANTHALIT", UAB' },
  { code: 110861884, name: 'Uždaroji akcinė bendrovė "ERGOLAIN"' },
  { code: 110864656, name: 'UAB "ZEELANDIA"' },
  { code: 110867232, name: 'UAB "WOKU BALTICA"' },
  { code: 110871654, name: 'UAB "NOREINA"' },
  { code: 110873477, name: 'UAB "GANDRO LIZDAS"' },
  { code: 110876587, name: 'UAB "ALTAS komercinis transportas"' },
  { code: 110878595, name: 'UAB "STOP LINIJA"' },
  { code: 110879544, name: 'UAB "MIROLVAS"' },
  { code: 110883585, name: 'UAB "Artilux NMF"' },
  {
    code: 110884491,
    name: "Uždaroji akcinė bendrovė Kauno termofikacijos elektrinė"
  },
  { code: 110888899, name: 'UAB "DALIS GERO"' },
  { code: 110890964, name: 'UAB "Keramlitus"' },
  { code: 111446581, name: 'UAB "Mars Lietuva"' },
  { code: 111449691, name: 'UAB "INwood"' },
  { code: 111456885, name: 'UAB "POLSKI REJESTR STATKOW KLAIPĖDA"' },
  {
    code: 111475323,
    name: 'Uždaroji akcinė bendrovė "NEDLIT INTERNATIONAL"'
  },
  { code: 111512921, name: 'UAB "BUREAU VERITAS LIT"' },
  { code: 111513642, name: 'Uždaroji akcinė bendrovė "Sollor"' },
  { code: 111515650, name: 'Uždaroji akcinė bendrovė "PLASTOLITAS"' },
  { code: 111516229, name: 'UAB "Smurfit Kappa Baltic"' },
  { code: 111557610, name: 'Uždaroji akcinė bendrovė "MEGATRANS"' },
  { code: 111568254, name: 'UAB "Vilbretas"' },
  { code: 111580057, name: 'Uždaroji akcinė bendrovė "OFFICE STYLE"' },
  { code: 111587411, name: 'Uždaroji akcinė bendrovė "DENIRE"' },
  { code: 111600015, name: 'Uždaroji akcinė bendrovė "MEVARIN"' },
  { code: 111618351, name: 'Uždaroji akcinė bendrovė "PERSOLITA"' },
  { code: 111619649, name: "UAB Bauwerk Group Lietuva" },
  { code: 111627891, name: 'UAB "SCAPA BALTIC"' },
  { code: 111649820, name: 'UAB "FLEXPRO"' },
  {
    code: 111654582,
    name: 'Uždaroji akcinė bendrovė "SWISCO PRODUCTION"'
  },
  { code: 111656829, name: 'UAB "GILUKSA"' },
  { code: 111656971, name: 'UAB "JAKOBSEN HOME CO"' },
  { code: 111660489, name: "UAB Teva Baltics" },
  {
    code: 111661242,
    name: 'Uždaroji akcinė bendrovė "KAIŠIADORYS WOOD INDUSTRIES"'
  },
  {
    code: 111685332,
    name: 'Uždaroji akcinė bendrovė "PAINA LEGNO BALTIJA"'
  },
  { code: 111687536, name: '"LITBED BEDDING COMPANY" UAB' },
  { code: 111688976, name: 'Uždaroji akcinė bendrovė "DOFITA"' },
  { code: 111692298, name: 'Uždaroji akcinė bendrovė "NORWEGIAN WOOD"' },
  { code: 111701427, name: 'UAB "HIGHLIFE"' },
  { code: 111712060, name: 'UAB "MEDIENOS STAKLĖS"' },
  { code: 111717036, name: 'UAB "LIETLINEN"' },
  { code: 111719197, name: 'UAB "VELGA VILNIUS"' },
  { code: 111748843, name: 'UAB "Benglita"' },
  {
    code: 111755656,
    name: 'Uždaroji akcinė bendrovė "BALTIC PREMATOR KLAIPĖDA"'
  },
  { code: 111757130, name: 'UAB "EUROPEAN WOOD PRODUCTION"' },
  { code: 111765426, name: "Wilbergs Group, UAB" },
  { code: 111770569, name: 'Uždaroji akcinė bendrovė "RW PRODUKTAI"' },
  { code: 111776255, name: 'Uždaroji akcinė bendrovė "Danspin"' },
  { code: 111781430, name: '"Bigso" UAB' },
  { code: 111783972, name: 'UAB "STORA ENSO LIETUVA"' },
  { code: 111789092, name: 'UAB "Biomin Lietuva"' },
  { code: 111795031, name: 'UAB "Energijos taupymo sistemos"' },
  { code: 111796286, name: 'UAB "ARLENOVA"' },
  {
    code: 111800069,
    name: 'Uždaroji akcinė bendrovė "Orion Global pet"'
  },
  { code: 111804128, name: 'UAB "Miško namas"' },
  { code: 111812185, name: 'UAB "UNITED LOGGERS BALTIJA"' },
  { code: 111817531, name: 'Uždaroji akcinė bendrovė "SCANDYE"' },
  { code: 111823438, name: 'UAB "Egersund Net"' },
  { code: 111823961, name: "UAB Ekologinių technologijų centras" },
  { code: 111824910, name: 'UAB "Carlo Gavazzi Industri Kaunas"' },
  { code: 111832597, name: "Paragon Nordic UAB" },
  {
    code: 111879337,
    name: 'Uždarosios akcinės bendrovės bendros Ukrainos-Lietuvos įmonės "GERMES" filialas'
  },
  { code: 111882080, name: "LITADAN Aps filialas" },
  { code: 111882995, name: "Weatherford Energy Services GmbH filialas" },
  { code: 111884512, name: "LLOYD`S REGISTER EMEA Klaipėdos filialas" },
  { code: 120013547, name: 'MB "Pušinis kampelis"' },
  {
    code: 120032857,
    name: "UAB Tatjanos Drutel sveikatos mokykla, organizmo valymo ir stiprinimo centras"
  },
  { code: 120040238, name: "V. Petružio individuali įmonė" },
  {
    code: 120050531,
    name: 'Individuali P. Meščerskio gamybinė firma "SMEGI"'
  },
  {
    code: 120090255,
    name: 'Akcinė bendrovė "Vilniaus gelžbetoninių konstrukcijų gamykla Nr. 3"'
  },
  { code: 120092110, name: 'UAB "VORAS IR KO"' },
  { code: 120094086, name: 'Akcinė bendrovė "VILMA"' },
  { code: 120110170, name: "V. Liukomo personali įmonė" },
  { code: 120112189, name: 'Uždaroji akcinė bendrovė "AGVASA"' },
  {
    code: 120149878,
    name: 'Uždaroji akcinė bendrovė Prekybos namai "LINA"'
  },
  { code: 120179711, name: "Individuali J. Ratkevičiaus įmonė" },
  { code: 120191471, name: "R. Gnedevičiaus individuali įmonė" },
  { code: 120191852, name: 'IĮ "Getija"' },
  { code: 120203525, name: 'Uždaroji akcinė bendrovė "LIREGUS"' },
  { code: 120213448, name: 'Uždaroji akcinė bendrovė "VILDETA"' },
  { code: 120265669, name: "I. Vorobjovos personalinė įmonė" },
  {
    code: 120274141,
    name: 'D. P. Kazakevičiaus medžio apdirbimo personali firma "IGLĖ"'
  },
  {
    code: 120294553,
    name: 'Gamybinė-paslaugų uždaroji akcinė bendrovė "VILDIVA"'
  },
  { code: 120312275, name: 'Uždaroji akcinė bendrovė firma "ANALITA"' },
  { code: 120371341, name: 'UAB "AT HIDRAULIC"' },
  { code: 120375891, name: 'Uždaroji akcinė bendrovė "AUTOPACK"' },
  {
    code: 120406340,
    name: "A. Maklajausko individuali statybinių medžiagų gamybos įmonė"
  },
  { code: 120415738, name: 'Uždaroji akcinė bendrovė "AIŽA"' },
  {
    code: 120467425,
    name: 'Tikroji ūkinė bendrija V. Gylio ir S. Pilinkaus bendrovė "Langas"'
  },
  { code: 120504795, name: 'Uždaroji akcinė bendrovė "Žalvaris"' },
  { code: 120519345, name: 'L. Chitriko įmonė "OPTI"' },
  { code: 120536985, name: 'UAB "BIOK laboratorija"' },
  { code: 120538993, name: 'Uždaroji akcinė bendrovė "ACD"' },
  { code: 120539180, name: 'UAB "VEIKA"' },
  { code: 120541256, name: 'Uždaroji akcinė bendrovė "MELBA"' },
  { code: 120561668, name: 'Uždaroji akcinė bendrovė "TVILŪNA"' },
  { code: 120587613, name: "Dubinsko personali įmonė" },
  {
    code: 120605292,
    name: 'UŽDAROJI AKCINĖ BENDROVĖ "BALDŲ INTERJERO GIDAS"'
  },
  { code: 120687923, name: 'UAB "GRINDUKAS"' },
  { code: 120694017, name: 'UAB "Sėkmės vartai"' },
  { code: 120761035, name: 'Uždaroji akcinė bendrovė "INTERJERAS"' },
  { code: 120779752, name: 'UŽDAROJI AKCINĖ BENDROVĖ "MANARA"' },
  { code: 120795386, name: 'Uždaroji akcinė bendrovė "Saulės energija"' },
  { code: 120813140, name: 'UAB "Ąžuolų pynė"' },
  { code: 120891331, name: 'Vytauto Paulaičio įmonė "LIBRO"' },
  { code: 120945836, name: 'Privati G. Čelutkos firma "TITA"' },
  { code: 120959622, name: 'Uždaroji akcinė bendrovė "VOKĖ-III"' },
  { code: 120973771, name: 'Uždaroji akcinė bendrovė "EMKA"' },
  { code: 121082286, name: 'R. Butkevičiaus įmonė "GARSAS IR VAIZDAS"' },
  { code: 121087066, name: 'Volko individuali įmonė "GILĖ"' },
  { code: 121135536, name: 'Uždaroji akcinė bendrovė "LESTA"' },
  { code: 121195138, name: 'Bernoto S. B. individuali įmonė "BEKO"' },
  { code: 121217823, name: 'Uždaroji akcinė bendrovė "MIRITA"' },
  {
    code: 121235846,
    name: "Antipenkovo J. mokslinė ir projektavimo įmonė"
  },
  {
    code: 121312253,
    name: 'A. Danilovo gamybinė-prekybinė įmonė "DERNA"'
  },
  { code: 121412378, name: 'Uždaroji akcinė bendrovė "MESLA"' },
  {
    code: 121421612,
    name: 'Uždaroji akcinė bendrovė gamybinė-komercinė firma "URMIKA"'
  },
  { code: 121436468, name: 'UAB "BALTVITA"' },
  { code: 121453617, name: 'Uždaroji akcinė bendrovė "EX VOTO"' },
  { code: 121461489, name: 'MB "PRO MODUM"' },
  { code: 121499212, name: 'Uždaroji akcinė bendrovė "PLASA"' },
  { code: 121504969, name: 'UAB "Balticsofa"' },
  { code: 121506443, name: 'Uždaroji akcinė bendrovė "VILGERA"' },
  { code: 121516213, name: 'Personalinė M. Rudaičio įmonė "SIRTA"' },
  { code: 121530939, name: "G. Mikuckij medžio drožybos ir piešimo įmonė" },
  { code: 121537150, name: "R. Bražiūno baldų gamybos įmonė" },
  { code: 121540470, name: 'Uždaroji akcinė bendrovė "REKIN"' },
  { code: 121559766, name: "INHUS Prefab, UAB" },
  { code: 121560544, name: "Šumino privati įmonė" },
  { code: 121680013, name: 'Uždaroji akcinė bendrovė "VIELARIS"' },
  { code: 121687335, name: 'UAB "BLANKŲ LEIDYKLA"' },
  { code: 121723265, name: 'Uždaroji akcinė bendrovė "ROTAPRINTAS"' },
  { code: 121740371, name: 'Uždaroji akcinė bendrovė "GARDELĖ"' },
  {
    code: 121779698,
    name: 'J. Pietkun, M. Mikelionio, T. Šurpickij bendrija "Technopakas"'
  },
  { code: 121805367, name: "GINTLUKAS, UAB" },
  { code: 121841598, name: "V. Vladimirovo įmonė" },
  { code: 121895336, name: 'Uždaroji akcinė bendrovė "Sąstatas"' },
  { code: 121914345, name: 'Uždaroji akcinė bendrovė "OZAS"' },
  { code: 121922783, name: 'Akcinė bendrovė "Vilniaus baldai"' },
  { code: 121929571, name: 'Uždaroji akcinė bendrovė "VEDRANA"' },
  { code: 121949830, name: 'Uždaroji akcinė bendrovė "POLIFEKTA"' },
  { code: 121992536, name: 'Uždaroji akcinė bendrovė "TRANSDAKA"' },
  { code: 122007945, name: 'Uždaroji akcinė bendrovė "CESTA"' },
  { code: 122051895, name: 'Uždaroji akcinė bendrovė "NABA"' },
  { code: 122074011, name: "Bolonkovskajos prekybinė firma" },
  { code: 122104764, name: 'UAB "Rivyda"' },
  { code: 122115636, name: "Augulio firma" },
  { code: 122148547, name: 'UAB "RETAL Lithuania"' },
  { code: 122149453, name: 'A. Kulešovo firma "ARTSANA"' },
  { code: 122218294, name: 'Uždaroji akcinė bendrovė "KRIOLIS"' },
  { code: 122267971, name: 'Uždaroji akcinė bendrovė "Solingas"' },
  { code: 122276269, name: 'Uždaroji akcinė bendrovė "BUKRITA"' },
  { code: 122292120, name: "A. Guogos personalinė įmonė" },
  { code: 122294281, name: 'Aleksandros Surdokienės firma "SARASTA"' },
  { code: 122313333, name: 'V. Guobio personalinė įmonė "Afiša"' },
  { code: 122331018, name: 'UAB "ILANA"' },
  { code: 122349354, name: 'A. Leikos firma "ALIVILA"' },
  { code: 122400425, name: 'G. Kniežos įmonė "Žiestė"' },
  { code: 122409036, name: 'UAB "Liugas ir dukros"' },
  { code: 122489769, name: "Gintaro Palaimos personalinė įmonė" },
  { code: 122541027, name: "S. Kiero firma" },
  { code: 122580368, name: 'Uždaroji akcinė bendrovė "VESIDA"' },
  { code: 122584427, name: 'Uždaroji akcinė bendrovė "FORŠTA"' },
  { code: 122590280, name: 'Uždaroji akcinė bendrovė "PAKMARKAS"' },
  { code: 122606350, name: 'Uždaroji akcinė bendrovė "Tradita"' },
  { code: 122609275, name: "UAB Infinite solutions" },
  { code: 122611155, name: 'Uždaroji akcinė bendrovė "MONBLANA"' },
  { code: 122619426, name: 'Uždaroji akcinė bendrovė "PALMUSTA"' },
  {
    code: 122627864,
    name: 'Statybos remonto uždaroji akcinė bendrovė "ŠARVAS"'
  },
  { code: 122628966, name: 'Uždaroji akcinė bendrovė "VIOTAS"' },
  { code: 122638693, name: 'Uždaroji akcinė bendrovė "NOMIJA"' },
  { code: 122643345, name: 'UAB "FUDŽIJA"' },
  { code: 122660451, name: 'Uždaroji akcinė bendrovė "ALRUNA"' },
  { code: 122675492, name: 'UAB "RBI idėjos"' },
  { code: 122682152, name: 'UAB "Auksinė idėja"' },
  { code: 122700636, name: "UAB Plastmasės fabrikas" },
  { code: 122780988, name: 'Uždaroji akcinė bendrovė "BRENAURAS"' },
  { code: 122831849, name: 'UAB "RAMUSTA"' },
  {
    code: 122863277,
    name: 'Arvydo Čestausko personalinė įmonė "Čemeda"'
  },
  { code: 122875621, name: 'V. Bazio įmonė "MEBLONA"' },
  { code: 122902698, name: 'Uždaroji akcinė bendrovė "VILAKRA"' },
  { code: 122903266, name: 'Uždaroji akcinė bendrovė "Somlita"' },
  { code: 122911747, name: 'Uždaroji akcinė bendrovė "TADMAKSAS"' },
  { code: 122930295, name: 'UAB "LATEMA"' },
  { code: 122983846, name: 'UAB "Juknevičiaus kompostas"' },
  { code: 122990844, name: 'Uždaroji akcinė bendrovė "LANRETA"' },
  { code: 122991227, name: "S. Jaščanino personalinė įmonė" },
  { code: 123016363, name: 'Uždaroji akcinė bendrovė "ARSADA"' },
  { code: 123017084, name: 'Uždaroji akcinė bendrovė "VOLIJA"' },
  { code: 123018186, name: 'UAB "Betono centras"' },
  { code: 123019473, name: 'Uždaroji akcinė bendrovė "LT Technologies"' },
  { code: 123126824, name: 'IĮ "Medvena"' },
  { code: 123138036, name: 'A. Darčanov personalinė įmonė "PAKULNIS"' },
  { code: 123153668, name: "M. Miškinio personalinė įmonė" },
  { code: 123177224, name: 'UAB "Magdala LT"' },
  { code: 123205392, name: "A. Trano firma" },
  { code: 123214256, name: 'UAB "PAKLOTAS"' },
  {
    code: 123220686,
    name: 'Uždaroji akcinė bendrovė "STATYBOS APDAILOS RANGA"'
  },
  { code: 123233228, name: 'Uždaroji akcinė bendrovė "MAIŠELIS"' },
  { code: 123236480, name: 'Uždaroji akcinė bendrovė "IKSADA"' },
  { code: 123241470, name: 'Uždaroji akcinė bendrovė "KALMEDA"' },
  { code: 123241851, name: 'Uždaroji akcinė bendrovė "ŽALIAMIŠKIS"' },
  { code: 123242953, name: 'Uždaroji akcinė bendrovė "VILRODA"' },
  { code: 123245725, name: 'Uždaroji akcinė bendrovė "AUGTIS"' },
  {
    code: 123285787,
    name: 'Tatjanos Tirlikienės firma "Prie Bražuolės"'
  },
  { code: 123293353, name: 'UAB "Roblux"' },
  { code: 123307568, name: 'UAB "Plastiklita"' },
  { code: 123309619, name: 'UAB "NARBUD"' },
  { code: 123311641, name: 'R. Glavinskio įmonė "RUBINTA"' },
  { code: 123356526, name: 'D. Gadliausko firma "Mažoji afiša"' },
  { code: 123404234, name: 'Uždaroji akcinė bendrovė "ARLANGA wood"' },
  { code: 123424984, name: 'UAB "PLASTVIS"' },
  { code: 123432212, name: 'Uždaroji akcinė bendrovė "MEDICATA FILIA"' },
  { code: 123446870, name: 'Uždaroji akcinė bendrovė "PARKESTA"' },
  {
    code: 123467284,
    name: 'Uždaroji akcinė bendrovė "PADVAISKAS IR KO"'
  },
  { code: 123469716, name: 'Uždaroji akcinė bendrovė "MEDALPAS"' },
  { code: 123514314, name: 'S. Jurkyno firma "ARCHETIPAS"' },
  { code: 123522033, name: "A. Žiemio personalinė įmonė" },
  { code: 123565814, name: "Personalinė A. Mackūno įmonė" },
  { code: 123570619, name: "G. K. Niauros individuali įmonė" },
  { code: 123575541, name: 'Uždaroji akcinė bendrovė "AMŽINASIS AKMUO"' },
  {
    code: 123587853,
    name: 'Uždaroji akcinė bendrovė "VILNIAUS STIKLO STUDIJA"'
  },
  { code: 123593564, name: "V. Daugio privati firma" },
  { code: 123619742, name: 'Uždaroji akcinė bendrovė "MEDGRAUŽIS"' },
  { code: 123622877, name: 'Uždaroji akcinė bendrovė "GLOSMIGA"' },
  { code: 123631164, name: "UAB Melisa Laboratorium" },
  { code: 123633891, name: 'Uždaroji akcinė bendrovė "VILDIKA"' },
  { code: 123637612, name: 'Uždaroji akcinė bendrovė "JUSTURA"' },
  {
    code: 123642036,
    name: 'Uždaroji akcinė bendrovė "EKOLOGIJOS PASLAUGOS"'
  },
  { code: 123644425, name: "Van Hessen Lithuania UAB" },
  { code: 123645146, name: 'Uždaroji akcinė bendrovė "ANTVILA"' },
  { code: 123681039, name: 'UAB "BOKER"' },
  { code: 123699756, name: "M. Kazijevos personalinė įmonė" },
  { code: 123701716, name: "D. Macijausko individuali įmonė" },
  { code: 123707893, name: 'UAB "Unique brick"' },
  { code: 123709944, name: 'UAB "Iksbaldai"' },
  { code: 123727771, name: 'J. Jankausko įmonė "PRIE HALĖS"' },
  { code: 123743786, name: 'Uždaroji akcinė bendrovė "ESTILITA"' },
  { code: 123746177, name: 'UAB "BAJARTA"' },
  { code: 123758140, name: 'Uždaroji akcinė bendrovė "RIEŠVA"' },
  { code: 123783542, name: 'D. Kadikino firma "KREONTAS"' },
  { code: 123807331, name: 'Uždaroji akcinė bendrovė "LEMERAS"' },
  { code: 123839293, name: 'Uždaroji akcinė bendrovė "ELTAKA"' },
  {
    code: 123850941,
    name: 'Uždaroji akcinė bendrovė "ŠIUOLAIKINIAI BALDAI"'
  },
  { code: 123852230, name: 'UAB "Baltic Konis"' },
  { code: 123854968, name: 'Uždaroji akcinė bendrovė "AUTOCHEMIJA"' },
  { code: 123862491, name: "V. Peciukonio įmonė" },
  { code: 123915741, name: 'Uždaroji akcinė bendrovė "BALRESTA"' },
  { code: 123923122, name: 'Uždaroji akcinė bendrovė "GELMEDA"' },
  { code: 123945880, name: 'Uždaroji akcinė bendrovė "UPARTAS"' },
  {
    code: 123946644,
    name: 'Uždaroji akcinė bendrovė "MIKO IR TADO LEIDYKLOS" SPAUSTUVĖ'
  },
  { code: 123961219, name: 'Uždaroji akcinė bendrovė "ŠILTOJI SIENA"' },
  { code: 123961938, name: 'Uždaroji akcinė bendrovė "ŽARGA"' },
  { code: 123968683, name: 'P. Staškevičiaus įmonė "APLANKAS"' },
  { code: 123981435, name: "J. Zdanovič įmonė" },
  { code: 123993747, name: "A. Očnevo įmonė" },
  { code: 124002740, name: 'UŽDAROJI AKCINĖ BENDROVĖ "IVILTRA"' },
  { code: 124008818, name: 'Viešoji įstaiga "BLIŪŠKIS"' },
  { code: 124019451, name: "V. Savarausko individuali įmonė" },
  {
    code: 124026645,
    name: 'D. Petraitienės įmonė "INTELIGENTIŠKOS STATYBINĖS SISTEMOS"'
  },
  { code: 124032018, name: "UAB I. Vrublevskis ir anūkai" },
  { code: 124040075, name: "Zofijos Zacharovos įmonė" },
  { code: 124045389, name: 'Uždaroji akcinė bendrovė "BALTICUM BALDAI"' },
  { code: 124064731, name: "R. Kondrato individuali įmonė" },
  { code: 124097980, name: 'Uždaroji akcinė bendrovė "NORASTA"' },
  { code: 124105594, name: 'Uždaroji akcinė bendrovė "PROKMA"' },
  { code: 124135580, name: "AB Vilniaus šilumos tinklai" },
  { code: 124140613, name: 'Uždaroji akcinė bendrovė "GALANDAS"' },
  { code: 124151823, name: 'A. Unikausko firma "MEDŽIO INTARSIJA"' },
  { code: 124174811, name: "Dailininkės R. Brazauskienės studija" },
  { code: 124185640, name: "Izmailo Voronovičiaus individuali įmonė" },
  { code: 124190064, name: "Andrejaus Romanenkos firma" },
  { code: 124205947, name: 'Uždaroji akcinė bendrovė "ELROMA"' },
  { code: 124242550, name: 'Viešoji įstaiga "Mobilumas ir sveikata"' },
  { code: 124262243, name: 'Uždaroji akcinė bendrovė "ESLIDA"' },
  {
    code: 124262439,
    name: 'Uždaroji akcinė bendrovė "AUDRIAUS MEDŽIO STUDIJA"'
  },
  { code: 124264828, name: 'Uždaroji akcinė bendrovė "FENOLITA"' },
  {
    code: 124289824,
    name: 'Viačeslavo Butrymo personalinė įmonė "RĖJUSVA"'
  },
  { code: 124304589, name: 'UAB "BALDŲ STUDIJA"' },
  { code: 124317273, name: 'V. KAPICKO FIRMA "SKANDICUS"' },
  {
    code: 124358665,
    name: 'Uždaroji akcinė bendrovė "DALILA IR PARTNERIAI"'
  },
  { code: 124382584, name: 'Uždaroji akcinė bendrovė "MEGAVARAS"' },
  { code: 124390346, name: 'Uždaroji akcinė bendrovė "DAESTA"' },
  { code: 124395279, name: 'Uždaroji akcinė bendrovė "MEDIDA"' },
  { code: 124398193, name: 'UAB "OLTENA"' },
  { code: 124420031, name: "Juliaus Remeikos personalinė įmonė" },
  { code: 124423522, name: 'Meškunec firma "AVISA"' },
  { code: 124433064, name: 'UAB "Polichroma"' },
  { code: 124434885, name: "R. Aškelovičiaus personalinė įmonė" },
  { code: 124443215, name: "UAB Aplinkos ir interjero centras" },
  { code: 124445180, name: 'Uždaroji akcinė bendrovė "BALTEKSLITA"' },
  {
    code: 124445757,
    name: 'Uždaroji akcinė bendrovė "Pažangios ekolinijos"'
  },
  { code: 124449392, name: 'Uždaroji akcinė bendrovė "SKILANDUKAS"' },
  { code: 124452189, name: 'UAB "EKODAS"' },
  {
    code: 124475939,
    name: 'Tikroji ūkinė bendrija "Tikslusis nivelyras"'
  },
  { code: 124479236, name: 'Uždaroji akcinė bendrovė "SKREMA"' },
  { code: 124487521, name: "Z. Skebėrio personalinė įmonė" },
  { code: 124493951, name: 'Uždaroji akcinė bendrovė "AKVASANAS"' },
  { code: 124526419, name: "N. Žigaus personalinė įmonė" },
  {
    code: 124532468,
    name: 'R. Semaškos įmonės "MEDLANGA" ir Tukio privačios firmos tikroji ūkinė bendrija'
  },
  { code: 124534519, name: 'UAB "Rokfelerio dantis"' },
  {
    code: 124545729,
    name: 'Viešoji įstaiga "Juodosios keramikos centras"'
  },
  { code: 124549898, name: 'Uždaroji akcinė bendrovė "IN PLENO"' },
  { code: 124554888, name: 'Uždaroji akcinė bendrovė "VILPILIS"' },
  { code: 124578825, name: 'UAB "GLASS LT"' },
  { code: 124579012, name: 'Uždaroji akcinė bendrovė "UGNIAVIETĖ"' },
  { code: 124601875, name: 'Uždaroji akcinė bendrovė "STILMEDA"' },
  { code: 124637939, name: 'Uždaroji akcinė bendrovė "ALBRUVA"' },
  { code: 124646183, name: 'R. Dudnik individuali įmonė "Ledervila"' },
  { code: 124715067, name: 'Uždaroji akcinė bendrovė "UNOLITA"' },
  { code: 124806937, name: 'UAB "Valveta"' },
  { code: 124807996, name: "Jono Skendelio personalinė įmonė" },
  { code: 124820171, name: 'Uždaroji akcinė bendrovė "MITUVĖLĖ"' },
  { code: 124827721, name: 'Uždaroji akcinė bendrovė "LAIPTŲ STUDIJA"' },
  { code: 124833051, name: 'UAB "VESELA"' },
  { code: 124843693, name: "Stanislavo Olechnovič personalinė įmonė" },
  { code: 124857860, name: "Uždaroji akcinė bendrovė ARS LIBERALIS" },
  { code: 124929092, name: 'Uždaroji akcinė bendrovė "KOFILAS"' },
  { code: 124931734, name: 'Uždaroji akcinė bendrovė "SUNVILA"' },
  { code: 124940174, name: 'UAB "Aukmergės baldai"' },
  { code: 124940740, name: 'Uždaroji akcinė bendrovė "POLIVEKTRIS"' },
  { code: 124968857, name: 'Uždaroji akcinė bendrovė "BALSITA"' },
  { code: 125008415, name: 'Viešoji įstaiga "ĮGŪDŽIŲ TOBULINIMO GRUPĖ"' },
  { code: 125028784, name: 'Uždaroji akcinė bendrovė "AUTOIDĖJA"' },
  { code: 125030479, name: 'Uždaroji akcinė bendrovė "MEGRAME" MEDIS' },
  {
    code: 125104132,
    name: 'I. Ruikienės gamybos ir paslaugų firma "AKSIRA"'
  },
  { code: 125111511, name: "Virginijos Černiauskienės individuali įmonė" },
  { code: 125121391, name: "Tarimanto Jankausko individuali įmonė" },
  { code: 125121968, name: 'A. Živialo firma "BRUKSTA"' },
  { code: 125161681, name: 'UAB "GERAS GARAS"' },
  { code: 125231852, name: 'UAB "TIKSLIOJI SINTEZĖ"' },
  { code: 125261991, name: 'UŽDAROJI AKCINĖ BENDROVĖ "LIETPAK"' },
  { code: 125276922, name: 'UAB "Baltijos biuro projektai"' },
  { code: 125288287, name: 'H. Kačanovskij įmonė "LEOREDA"' },
  { code: 125294564, name: 'IĮ "NIONA"' },
  { code: 125355533, name: 'UŽDAROJI AKCINĖ BENDROVĖ "OVDIJA"' },
  { code: 125367998, name: 'Uždaroji akcinė bendrovė "DIVIGERA"' },
  { code: 125373360, name: 'UŽDAROJI AKCINĖ BENDROVĖ "GALJANA"' },
  { code: 125403437, name: 'UŽDAROJI AKCINĖ BENDROVĖ "PLASTIKSĖ"' },
  { code: 125464098, name: 'Uždaroji akcinė bendrovė "SIDLITA"' },
  { code: 125471858, name: 'UAB "HAMPIDJAN BALTIC"' },
  { code: 125472383, name: 'Uždaroji akcinė bendrovė "GLAISVITA"' },
  { code: 125472426, name: 'UAB "POLYWOOD"' },
  { code: 125474434, name: 'UŽDAROJI AKCINĖ BENDROVĖ "VELGUMAS"' },
  { code: 125496092, name: 'UAB "Nux LT"' },
  { code: 125515525, name: 'UŽDAROJI AKCINĖ BENDROVĖ "GAMLANA"' },
  { code: 125521955, name: 'UŽDAROJI AKCINĖ BENDROVĖ "METRANSA"' },
  { code: 125526735, name: 'G. Želvio įmonė "GLASARTAS"' },
  { code: 125542935, name: 'UŽDAROJI AKCINĖ BENDROVĖ "ALEKNATIS"' },
  { code: 125544562, name: 'UAB "BATERIS"' },
  { code: 125559265, name: 'UAB "TIKSLO SIEKIS"' },
  { code: 125563534, name: 'UŽDAROJI AKCINĖ BENDROVĖ "PEGAMA"' },
  { code: 125564789, name: 'UAB "ARTIVA" IR KO' },
  { code: 125573838, name: '"Veva", UAB' },
  { code: 125587777, name: 'UŽDAROJI AKCINĖ BENDROVĖ "REGENERACIJA"' },
  { code: 125638257, name: 'A. Daujoto įmonė "ALDAUJANA"' },
  {
    code: 125643585,
    name: 'UAB "TECHNOLITAS BALDAI" KŪB "BALDŲ KOMPONENTAI"'
  },
  { code: 125657752, name: "E. Rokickio įmonė" },
  { code: 125660734, name: 'UAB "TECHNOLITAS BALDAI" KŪB "METANOMAS"' },
  { code: 125675241, name: 'UAB "SPALVA"' },
  { code: 125679834, name: 'UAB "VILPRIDA"' },
  { code: 125688274, name: 'UAB "DALISIMA"' },
  { code: 125711171, name: 'B. ZADOJENKIENĖS FIRMA "KRUPONAS"' },
  { code: 125716528, name: 'UAB "ETIMARKAS"' },
  { code: 125722381, name: 'UAB "Leoprint"' },
  { code: 125730524, name: 'UŽDAROJI AKCINĖ BENDROVĖ "TAMULAS"' },
  { code: 125736897, name: 'T. Matijošiaus firma "SLĖGIO ZONA"' },
  { code: 125739854, name: 'A. Veismanio įmonė "ARB"' },
  { code: 125740632, name: "A. Radzevičiaus įmonė" },
  { code: 125744278, name: 'UAB "JOGREVA"' },
  { code: 125747420, name: 'Uždaroji akcinė bendrovė "DETMETAS"' },
  { code: 125751995, name: 'UAB "JULSATA"' },
  { code: 125752944, name: "UAB FLIESSER BALTIC" },
  { code: 125758630, name: 'UŽDAROJI AKCINĖ BENDROVĖ "SĖKMĖS AURA"' },
  { code: 125766730, name: 'UŽDAROJI AKCINĖ BENDROVĖ "MOTOVIZIJOS"' },
  { code: 125780737, name: "KĘSTUČIO ŠIMKO FIRMA" },
  { code: 125790845, name: 'UAB "AAF"' },
  { code: 125799075, name: "S. R. Kulikausko firma" },
  { code: 125806349, name: "M. GUKOV FIRMA" },
  { code: 125810956, name: 'R. A. Statkauskaitės įmonė "DŽIUGENA"' },
  { code: 125821785, name: 'UAB "FIBRILĖ"' },
  { code: 125829141, name: 'Uždaroji akcinė bendrovė "MALTOSA"' },
  { code: 125846258, name: "M. Valeikos įmonė" },
  { code: 125850146, name: "Mariaus Pajarsko firma" },
  { code: 125860069, name: 'UAB "KADALIJAS"' },
  { code: 125875523, name: 'UŽDAROJI AKCINĖ BENDROVĖ "VELANSTA"' },
  {
    code: 125886167,
    name: "Ž. Janulevičiaus baldų projektavimo ir gamybos įmonė"
  },
  { code: 125889081, name: 'R. MARTINKO ĮMONĖ "AKTIVELA"' },
  { code: 125891495, name: "Živilės Kulbokienės individuali įmonė" },
  { code: 125908286, name: "Irenos Adelės Pažėrienės individuali įmonė" },
  { code: 125917716, name: 'UAB "GERA VIRTUVĖ"' },
  { code: 125931146, name: 'UAB "RAMI EIGA"' },
  { code: 125941592, name: 'UAB "ARFIDAS"' },
  { code: 125950837, name: "A. Raulinavičiaus personalinė įmonė" },
  { code: 125955040, name: 'UŽDAROJI AKCINĖ BENDROVĖ "EURILATA"' },
  { code: 125957397, name: 'UAB "RYTŲ ENERGETIKOS TINKLŲ PROJEKTAI"' },
  { code: 125964776, name: 'UAB "MEDŽIO LINIJA"' },
  { code: 125976935, name: 'Uždaroji akcinė bendrovė "Prefabeta"' },
  { code: 125994424, name: 'UAB "Baltas alksnis"' },
  { code: 125999738, name: 'UAB "Baldų mozaika"' },
  { code: 126001039, name: 'UAB "Naujoji Barmunė"' },
  { code: 126001181, name: 'Viešoji įstaiga "Dizaino artelė"' },
  { code: 126038151, name: 'O. Fedotovo įmonė "Gultuva"' },
  { code: 126045726, name: 'UAB "Anrimata" ir ko' },
  { code: 126051056, name: 'UAB "Vilniaus karė centras"' },
  { code: 126060639, name: 'UAB "Skaigama"' },
  { code: 126072755, name: 'UAB "NEFRITAS"' },
  { code: 126085973, name: 'Ilonos Rajeckaitės firma "Lemurija"' },
  { code: 126113999, name: 'UAB "Markučių mozaika"' },
  { code: 126138842, name: 'Uždaroji akcinė bendrovė "Vidvestuma"' },
  { code: 126138995, name: 'Uždaroji akcinė bendrovė "Meidalja"' },
  { code: 126141781, name: 'UAB "Keraplast"' },
  { code: 126142011, name: 'Uždaroji akcinė bendrovė "NEO GROUP"' },
  { code: 126150264, name: 'UAB "DP technologijos"' },
  { code: 126154095, name: 'Uždaroji akcinė bendrovė "Regrasa"' },
  { code: 126164812, name: 'UAB "Žemaitijos staliai"' },
  { code: 126189438, name: "Liubovės Izotovos individuali įmonė" },
  { code: 126191275, name: "UAB Dažų ir dangų fabrikas" },
  { code: 126199731, name: 'UAB "Grigeo Baltwood"' },
  { code: 126214724, name: "Jūratės Čelkienės individuali įmonė" },
  { code: 126220620, name: 'S. Michejevienės įmonė "Saulės stiklai"' },
  { code: 126220816, name: 'UAB "Gerų langų centras"' },
  { code: 126223698, name: 'UAB "Moderni klasika"' },
  { code: 126233272, name: 'UAB "Vizualūs sprendimai"' },
  { code: 126242136, name: 'UAB "Neotonas"' },
  { code: 126243238, name: 'UAB "GRAANUL INVEST"' },
  { code: 126248356, name: "S. Jarulio įmonė" },
  { code: 126261599, name: "Viešoji įstaiga Vilniaus puodžių cechas" },
  { code: 126267666, name: 'Uždaroji akcinė bendrovė "Gursmeda"' },
  { code: 126273181, name: 'UAB "Baldų sistemos"' },
  { code: 126278919, name: 'UAB "NS2"' },
  { code: 126297271, name: 'UAB "RETAL Baltic Films"' },
  { code: 126332480, name: 'UAB "Baldų gausa"' },
  { code: 126339464, name: 'UAB "IVO" ir ko' },
  { code: 126346658, name: 'UAB "Nordigeras"' },
  { code: 126356951, name: "Balterma ir ko, UAB" },
  { code: 126360316, name: 'UAB "Plastic chips"' },
  { code: 126383838, name: 'UAB "Švaros alėja"' },
  {
    code: 126389481,
    name: "Viešoji įstaiga Pakuočių ir atliekų tvarkymo centras"
  },
  { code: 126400643, name: "UAB Naftos atliekų perdirbimo centras" },
  { code: 126405619, name: 'UAB "Optinės dangos"' },
  { code: 126408686, name: 'UAB "Testudo"' },
  { code: 126416829, name: 'UAB "Lengvas vėjas"' },
  { code: 132009752, name: 'R. Simanavičiaus įmonė "Kiko"' },
  { code: 132039891, name: 'Uždaroji akcinė bendrovė "Kauno šilas"' },
  { code: 132067684, name: 'R. Dagilio įmonė "Daro"' },
  { code: 132073395, name: 'A. Montrimo įmonė "Jūra"' },
  { code: 132084071, name: 'Uždaroji akcinė bendrovė "Granitas"' },
  { code: 132088511, name: 'Č. Čiuro individuali įmonė "Stima"' },
  { code: 132103276, name: 'A. Štelmoko įmonė "Timora"' },
  { code: 132117062, name: 'UAB "AIRDEKOR"' },
  { code: 132136372, name: 'Uždaroji akcinė bendrovė "Tela"' },
  { code: 132173171, name: "V. ŠČIUPOKO individuali įmonė" },
  { code: 132201382, name: 'UAB "Ltintus"' },
  { code: 132205975, name: 'Uždaroji akcinė bendrovė "Šilumininkas"' },
  { code: 132206696, name: 'Uždaroji akcinė bendrovė "Gliptika"' },
  { code: 132224032, name: 'Aldivino Kedanio firma "Molija"' },
  { code: 132241487, name: "Jono Tamoševičiaus įmonė" },
  { code: 132265958, name: "Alvydo Bakšos įmonė" },
  { code: 132305413, name: 'UAB "Azarijas"' },
  { code: 132324723, name: 'UAB "Rovytas"' },
  { code: 132335171, name: 'Vidmanto Janušauskio įmonė "Vid"' },
  {
    code: 132345137,
    name: 'Uždaroji akcinė bendrovė "NAUJASIS NEVĖŽIS"'
  },
  { code: 132350312, name: "E. Kuzmicko firma" },
  { code: 132423365, name: "Aleksandro Gorbunovo įmonė" },
  { code: 132427196, name: 'R. Šeferio įmonė "Modum"' },
  { code: 132439693, name: 'Uždaroji akcinė bendrovė "Energija"' },
  { code: 132461070, name: "Laimono Gudeliausko įmonė" },
  { code: 132478543, name: 'Uždaroji akcinė bendrovė "Verpstė"' },
  { code: 132515618, name: 'Uždaroji akcinė bendrovė "Staigmena"' },
  { code: 132534732, name: 'IĮ "Austela"' },
  { code: 132566018, name: 'J. Navicko įmonė "Piper"' },
  { code: 132567981, name: 'UAB "Ardi LT"' },
  { code: 132613681, name: 'Uždaroji akcinė bendrovė "Skliautai"' },
  {
    code: 132659091,
    name: 'Komanditinė ūkinė bendrija "Pharmaceutica Lituanica ex Noreika"'
  },
  { code: 132721949, name: 'R. Sakalausko įmonė "Parketas"' },
  { code: 132771051, name: "Liudo Mikalavičiaus įmonė" },
  { code: 132795718, name: "Rimanto Dukavičiaus įmonė" },
  { code: 132825937, name: 'G. Paškevičiaus firma "Ehoba"' },
  { code: 132907124, name: 'Uždaroji akcinė bendrovė "Pharmcos"' },
  {
    code: 132907277,
    name: 'R. Pauliukaičio tikroji ūkinė bendrija "Orionas"'
  },
  { code: 132930660, name: 'Uždaroji akcinė bendrovė "Plastic formo"' },
  { code: 132937110, name: "Rimanto Adomaičio įmonė" },
  { code: 132941685, name: 'UAB "Numeris"' },
  { code: 132987857, name: 'Uždaroji akcinė bendrovė "Atranka"' },
  { code: 133031118, name: 'V. Kolomyjsko įmonė "Irviktus"' },
  {
    code: 133058841,
    name: "V. Šumausko biocheminių preparatų gamybos įmonė"
  },
  {
    code: 133083481,
    name: 'Genoefos ir Juliaus Kondrotų įmonė "Jugevai"'
  },
  { code: 133096938, name: 'Uždaroji akcinė bendrovė "Tomis"' },
  { code: 133097463, name: 'UAB "Pintinė Tau"' },
  { code: 133140587, name: 'Uždaroji akcinė bendrovė "Samsonas"' },
  { code: 133144646, name: 'UAB "Termopalas"' },
  { code: 133162669, name: "Stasio Karpavičiaus įmonė" },
  { code: 133217546, name: 'Uždaroji akcinė bendrovė "NTA ir ko"' },
  { code: 133218071, name: 'Uždaroji akcinė bendrovė "Maksima"' },
  { code: 133236518, name: 'Uždaroji akcinė bendrovė "Tikutis"' },
  { code: 133265217, name: 'Uždaroji akcinė bendrovė "Upsala"' },
  { code: 133291678, name: 'Michailo Solovjovo įmonė "Akvamarinas"' },
  { code: 133301147, name: 'M. R. Inkėno firma "Marsota"' },
  { code: 133306112, name: 'Stasio Miknevičiaus įmonė "Laiklita"' },
  { code: 133310915, name: 'UAB "Vilina"' },
  { code: 133324092, name: 'Uždaroji akcinė bendrovė "Angaras"' },
  { code: 133350062, name: "Jurijaus Pachomovo įmonė" },
  { code: 133360366, name: 'UAB "Germė"' },
  { code: 133386126, name: 'UAB "Freda"' },
  { code: 133414337, name: 'Uždaroji akcinė bendrovė "Fagus"' },
  {
    code: 133415581,
    name: 'Uždaroji akcinė bendrovė - prekybos namai "Karta"'
  },
  { code: 133418353, name: "Kęstučio Juozo Šimkaus įmonė" },
  { code: 133436180, name: 'Uždaroji akcinė bendrovė "GAVILDAS"' },
  { code: 133464354, name: 'M. Krakausko firma "Energetika"' },
  { code: 133480935, name: 'V. Remeikos firma "Remarta"' },
  { code: 133489165, name: 'Uždaroji akcinė bendrovė "Barema"' },
  { code: 133502520, name: 'Akcinė bendrovė "Aksa"' },
  { code: 133505979, name: 'Uždaroji akcinė bendrovė "Artas"' },
  { code: 133513698, name: 'Akcinė bendrovė "Kauno baldai"' },
  { code: 133562841, name: 'Uždaroji akcinė bendrovė "Pušis"' },
  { code: 133587314, name: 'UAB "Tauras LT"' },
  { code: 133616246, name: "P. Dragūno įmonė" },
  { code: 133643122, name: 'Akcinė bendrovė "NAUJOJI RINGUVA"' },
  { code: 133644943, name: 'UAB "Corpus Medica"' },
  { code: 133650654, name: 'Uždaroji akcinė bendrovė "Litusbas"' },
  { code: 133663349, name: 'Uždaroji akcinė bendrovė "Litvaista"' },
  {
    code: 133667027,
    name: 'Uždaroji akcinė bendrovė "Kauno gelžbetonis"'
  },
  { code: 133689066, name: 'Uždaroji akcinė bendrovė "Viromis"' },
  { code: 133692429, name: 'Uždaroji akcinė bendrovė "Suprena"' },
  { code: 133701287, name: "Giedriaus Rastausko firma" },
  { code: 133720597, name: "UAB Grigo studija" },
  { code: 133724460, name: 'Uždaroji akcinė bendrovė "Švaistūnas"' },
  { code: 133747263, name: 'UAB "Rimina LT"' },
  { code: 133766573, name: 'UAB "Pikardija"' },
  { code: 133791822, name: 'Uždaroji akcinė bendrovė "Linolitas"' },
  { code: 133799712, name: 'S. Rudžionio firma "Saurita"' },
  { code: 133818917, name: 'Akcinė bendrovė "Kauno grūdai"' },
  { code: 133820373, name: 'Uždaroji akcinė bendrovė "Arvadas"' },
  { code: 133831626, name: 'R. Grėbliūno firma "Astijus"' },
  { code: 133854233, name: 'UAB "HDG"' },
  { code: 133861427, name: 'UAB "ROLEKS"' },
  { code: 133959095, name: 'UAB "Domus fausta"' },
  { code: 133960788, name: "Jurijaus Ledniovo firma" },
  { code: 134016521, name: 'UAB "STABRO"' },
  { code: 134043365, name: 'UAB "Rivjeros baldai"' },
  { code: 134059355, name: "PAKA, UAB" },
  { code: 134060133, name: 'Uždaroji akcinė bendrovė "Fantastai"' },
  { code: 134074453, name: 'Laimono Dagilio firma "Ergidas"' },
  { code: 134133229, name: "UAB Petrašiūnų lentpjūvė" },
  { code: 134160639, name: 'UAB "HIDROENERGIJA"' },
  { code: 134165942, name: 'Akcinė bendrovė "Liepsna"' },
  { code: 134170932, name: 'Akcinė bendrovė "Detonas"' },
  { code: 134208094, name: 'UAB "Virūna"' },
  {
    code: 134240494,
    name: 'Diemanto Gudaičio firma "Reprodukcijos ir plakatai"'
  },
  { code: 134241824, name: "R. Matijošiaus firma" },
  { code: 134248765, name: 'Uždaroji akcinė bendrovė "Avona"' },
  { code: 134249529, name: 'UAB "Spalvų vėjas"' },
  { code: 134261855, name: 'UAB "RVG baldai"' },
  { code: 134274354, name: 'UAB "Autogrotas"' },
  { code: 134284462, name: "R. Kiltinavičiaus projektavimo įmonė" },
  { code: 134302565, name: 'Uždaroji akcinė bendrovė "Petrašiūnų gaja"' },
  {
    code: 134308447,
    name: 'D. Bankausko firma "Dekoratyviniai stiklai"'
  },
  { code: 134352810, name: 'Gintauto Bejerio firma "Diversitas"' },
  { code: 134355169, name: "G. E. Baguckio firma" },
  { code: 134366945, name: "Romualdo Andrikio firma" },
  { code: 134372275, name: 'UAB "A. Preišegolavičiaus įmonė"' },
  { code: 134378538, name: 'Tomo Gruodžio firma "AXON"' },
  { code: 134403596, name: 'Uždaroji akcinė bendrovė "Adedra"' },
  { code: 134409282, name: 'E. Pociūno firma "Mūsų šeimynėlė"' },
  { code: 134424238, name: "E. Tauperio firma" },
  { code: 134432295, name: "Ryčio Pilkausko įmonė" },
  { code: 134436920, name: "Vidmanto Kasparaičio įmonė" },
  { code: 134443886, name: 'UAB "Segmena"' },
  { code: 134481787, name: 'UAB "KLINGAI"' },
  { code: 134482921, name: 'Uždaroji akcinė bendrovė "Spartum"' },
  { code: 134487092, name: 'UAB "Astreta"' },
  { code: 134489143, name: 'Uždaroji akcinė bendrovė "Reprodukcija"' },
  { code: 134505238, name: 'UAB "Saldoga"' },
  { code: 134511515, name: 'IĮ "Kendra"' },
  { code: 134517692, name: 'Uždaroji akcinė bendrovė "Venditor"' },
  { code: 134518118, name: "R. Guntoriaus įmonė" },
  { code: 134543139, name: 'Uždaroji akcinė bendrovė "Limanta"' },
  { code: 134546053, name: "Artūro Palubinsko įmonė" },
  { code: 134555440, name: 'Vidmanto Urbono firma "Vizitas į namus"' },
  { code: 134600387, name: 'Uždaroji akcinė bendrovė "Dasta"' },
  { code: 134600768, name: 'Uždaroji akcinė bendrovė "Estakada"' },
  { code: 134621552, name: 'UAB "AUDVIKA"' },
  { code: 134640677, name: 'Uždaroji akcinė bendrovė "Gembės" medis' },
  { code: 134656329, name: 'Uždaroji akcinė bendrovė "Saumanta"' },
  {
    code: 134665520,
    name: 'Uždaroji akcinė bendrovė "Grynmedžio dirbiniai"'
  },
  { code: 134674156, name: 'Uždaroji akcinė bendrovė "Similė"' },
  { code: 134693466, name: "Gintauto Eiseno firma" },
  { code: 134698431, name: "Alvydo Davailio firma" },
  { code: 134709493, name: 'Uždaroji akcinė bendrovė "Elektrola"' },
  { code: 134709536, name: 'Uždaroji akcinė bendrovė "Gurda"' },
  { code: 134716349, name: 'Uždaroji akcinė bendrovė "Hidrojėgainė"' },
  { code: 134721524, name: 'UAB "Sanveda"' },
  { code: 134738192, name: 'Uždaroji akcinė bendrovė "Fortes fortuna"' },
  { code: 134743410, name: "Jono Kisielio įmonė" },
  { code: 134761390, name: 'Uždaroji akcinė bendrovė "Frumenta"' },
  { code: 134777957, name: 'Uždaroji akcinė bendrovė "Boruna"' },
  { code: 134778144, name: 'Uždaroji akcinė bendrovė "Legacija"' },
  { code: 134792293, name: 'UAB "ADEGO"' },
  { code: 134793438, name: 'UAB "Orinvita"' },
  { code: 134796014, name: 'R. Brastavičiaus firma "Gintrega"' },
  { code: 134816659, name: 'Uždaroji akcinė bendrovė "Asterna"' },
  { code: 134822174, name: 'UAB "TIKSIS"' },
  { code: 134833231, name: "Ryčio Kapūstos įmonė" },
  { code: 134841484, name: "Gintaro Skučo įmonė" },
  { code: 134842390, name: 'Uždaroji akcinė bendrovė "Trilogija"' },
  { code: 134842771, name: 'Uždaroji akcinė bendrovė "BALTOSKANDIJA"' },
  { code: 134870030, name: 'Uždaroji akcinė bendrovė "Ekorema"' },
  { code: 134876446, name: 'UAB "Glansa"' },
  { code: 134879022, name: "R. Mikelsko individuali įmonė" },
  { code: 134882342, name: 'UAB "INCO Production"' },
  { code: 134900445, name: "Marijaus Butkevičiaus firma" },
  { code: 134902453, name: "Vitaliaus Kybarto įmonė" },
  {
    code: 134913663,
    name: "Karolio Galinio individuali konsultacinė-inovacinė įmonė"
  },
  { code: 134919545, name: 'Ž. Dūdos firma "Baltoji menė"' },
  { code: 134926543, name: "V. Trijonio įmonė" },
  { code: 134931914, name: 'UAB "ROLEKSPA"' },
  { code: 134951411, name: "Aiškuolės Speičienės įmonė" },
  {
    code: 134958886,
    name: 'Uždaroji akcinė bendrovė "Galinta ir partneriai"'
  },
  { code: 134961868, name: "R. Mačiulaičio firma" },
  { code: 134966648, name: 'Uždaroji akcinė bendrovė "Inčas"' },
  {
    code: 134985958,
    name: 'Uždaroji akcinė bendrovė "Medžio interjeras"'
  },
  { code: 135003434, name: 'UAB "OMNIVITUS"' },
  { code: 135007831, name: 'Uždaroji akcinė bendrovė "SENATOR BALTIC"' },
  { code: 135020889, name: 'Uždaroji akcinė bendrovė "Husas"' },
  { code: 135026618, name: "Edvino Bliumbergo firma" },
  { code: 135040614, name: 'Uždaroji akcinė bendrovė "Akmendaila"' },
  { code: 135058950, name: "AKVATERA LT, UAB" },
  { code: 135067433, name: 'Uždaroji akcinė bendrovė "Balduva"' },
  { code: 135068916, name: 'Uždaroji akcinė bendrovė "Martis ir Ko"' },
  { code: 135074965, name: 'Uždaroji akcinė bendrovė "Ambora"' },
  { code: 135104999, name: 'G. Savicko įmonė "Saginta"' },
  { code: 135129657, name: 'UAB "Narcetas"' },
  { code: 135133730, name: 'Uždaroji akcinė bendrovė "Langenta"' },
  { code: 135137223, name: "Raimundo Gleiznio įmonė" },
  { code: 135160955, name: 'B. Benaičio firma "Brobena"' },
  { code: 135166118, name: 'Uždaroji akcinė bendrovė "Formpakas"' },
  { code: 135167177, name: 'UAB "Plastus"' },
  { code: 135168845, name: 'A. Andrijausko firma "Arteva"' },
  { code: 135177666, name: 'Uždaroji akcinė bendrovė "Monumentalis"' },
  { code: 135181554, name: 'Uždaroji akcinė bendrovė "Guodra"' },
  { code: 135188538, name: 'Uždaroji akcinė bendrovė "Ailona"' },
  { code: 135215070, name: 'UAB "Laimuva"' },
  { code: 135217121, name: 'Sauliaus Čivilio firma "Mediale"' },
  { code: 135240515, name: 'Uždaroji akcinė bendrovė "Pilkasis ruonis"' },
  { code: 135242338, name: 'Uždaroji akcinė bendrovė "Priegolė"' },
  { code: 135243244, name: 'Uždaroji akcinė bendrovė "Laimonas ir Ko"' },
  { code: 135249464, name: 'Uždaroji akcinė bendrovė "Currus"' },
  { code: 135250623, name: "Vido Raibikio įmonė" },
  { code: 135251910, name: 'Uždaroji akcinė bendrovė "Vidvalis"' },
  { code: 135264758, name: "Artūro Ilevičiaus įmonė" },
  { code: 135275391, name: 'V. Jaruko įmonė "Rokelių trinkelė"' },
  { code: 135275772, name: 'UAB "LTP TEXDAN"' },
  { code: 135276917, name: 'Uždaroji akcinė bendrovė "Murameda"' },
  {
    code: 135290870,
    name: 'Viešoji įstaiga "Priešgaisrinių paslaugų garantas"'
  },
  { code: 135440416, name: "G. Labanausko firma" },
  { code: 135473327, name: 'A. Plekavičiaus įmonė "Galartas"' },
  { code: 135480282, name: 'Uždaroji akcinė bendrovė "Erdonas"' },
  { code: 135495747, name: 'Uždaroji akcinė bendrovė "Žilvičio vytelė"' },
  { code: 135499959, name: 'UAB "Aztrekas"' },
  { code: 135539033, name: 'UAB "Burtažodis"' },
  { code: 135542168, name: 'UAB "BALTIC HYDROENERGY"' },
  { code: 135550834, name: "A. Madzeliausko įmonė" },
  { code: 135551936, name: 'UAB "BETONIKA"' },
  { code: 135554131, name: 'Uždaroji akcinė bendrovė "Aconitum"' },
  { code: 135556673, name: 'Uždaroji akcinė bendrovė "Soldeka"' },
  { code: 135562384, name: 'UAB "Granplasta"' },
  { code: 135575111, name: 'Uždaroji akcinė bendrovė "Markista"' },
  { code: 135579095, name: "Alvyros Jakštienės įmonė" },
  { code: 135584313, name: "G. Zalecko individuali įmonė" },
  { code: 135596582, name: 'Audriaus Račkausko įmonė "Ksilonas"' },
  {
    code: 135599735,
    name: 'Uždaroji akcinė bendrovė "Techniniai gumos gaminiai"'
  },
  { code: 135606966, name: 'Uždaroji akcinė bendrovė "Kosvita"' },
  {
    code: 135607349,
    name: 'Uždaroji akcinė bendrovė "Omniteksas fabrics"'
  },
  { code: 135628286, name: 'UAB "EVENIDA"' },
  { code: 135631268, name: 'UAB "A- PAK"' },
  { code: 135631649, name: 'Uždaroji akcinė bendrovė "Kukras"' },
  { code: 135631791, name: 'UAB "Stikvija"' },
  { code: 135634182, name: 'Uždaroji akcinė bendrovė "Lazertechas"' },
  { code: 135645392, name: 'Uždaroji akcinė bendrovė "Lasima" ir Ko' },
  { code: 135657366, name: 'UAB "Medrodė"' },
  { code: 135663839, name: "Audronės Strašauskienės įmonė" },
  { code: 135666415, name: 'Uždaroji akcinė bendrovė "Medaiva"' },
  { code: 135668957, name: "Vytauto Boleslovo Sruogos įmonė" },
  { code: 135675193, name: 'Uždaroji akcinė bendrovė "Baltfora"' },
  { code: 135675421, name: 'Uždaroji akcinė bendrovė "Inturika"' },
  { code: 135676480, name: 'Mindaugo Sliesoraičio firma "Milasa"' },
  { code: 135685910, name: "G. Dėdelės įmonė" },
  { code: 135690291, name: "D. Snarskio įmonė" },
  { code: 135699283, name: 'UAB "Epinera"' },
  { code: 135699511, name: 'UAB "Zeraida"' },
  { code: 135699664, name: 'Uždaroji akcinė bendrovė "Pas Henriką"' },
  { code: 135717614, name: 'UAB "Dideiva"' },
  { code: 135725867, name: 'Viešoji įstaiga "Spalvų paslaptys"' },
  { code: 135736739, name: 'A. Balsienės įmonė "Baluja"' },
  { code: 135739991, name: 'UAB "Gipura"' },
  { code: 135740627, name: "V. Matuzo įmonė" },
  { code: 135744262, name: 'Uždaroji akcinė bendrovė "Anatega"' },
  { code: 135765057, name: 'Uždaroji akcinė bendrovė "Roalsa"' },
  { code: 135765623, name: "R. Armono įmonė" },
  { code: 135780874, name: 'UAB "LANGDENA"' },
  { code: 135797813, name: 'V. Kokštos įmonė "Giedmara"' },
  { code: 135800832, name: 'Uždaroji akcinė bendrovė "Stamintė"' },
  { code: 135806671, name: 'UAB "Dajakuta"' },
  { code: 135808494, name: "A. Navicko firma" },
  { code: 135810940, name: "Tado Jonkaus individuali įmonė" },
  { code: 135814771, name: 'UAB "GABORA"' },
  { code: 135823635, name: 'UAB "Teneritas"' },
  { code: 135829289, name: 'UAB "Livigė"' },
  { code: 135847497, name: 'V. Gabšytės įmonė "Parfumeda"' },
  { code: 135850130, name: 'UAB "Vlatausa"' },
  { code: 135852868, name: 'UAB "Arūno langai"' },
  { code: 135863544, name: 'Uždaroji akcinė bendrovė "Vaivora" ir Ko' },
  { code: 135871110, name: 'UAB "Lukandra"' },
  { code: 135878628, name: 'L. Bulovo firma "Elektromedija"' },
  { code: 135880127, name: 'UAB "Aera nostra"' },
  { code: 135887634, name: 'UAB "ĄŽUOLĖLIS"' },
  { code: 135888921, name: "D. Bendžiaus firma" },
  { code: 135896640, name: 'Uždaroji akcinė bendrovė "Valentis"' },
  { code: 135897557, name: 'UAB "Vėjo jėgainių projektai"' },
  { code: 135899184, name: 'UAB "Jostarta"' },
  { code: 135907179, name: 'UAB "Peko"' },
  { code: 135907211, name: 'Uždaroji akcinė bendrovė "Drastila"' },
  { code: 135922996, name: 'UAB "Tututis"' },
  { code: 135928878, name: 'UAB "Rosmundė"' },
  { code: 135931130, name: 'Uždaroji akcinė bendrovė "Laiptida"' },
  { code: 135936597, name: 'Uždaroji akcinė bendrovė "Elucida"' },
  { code: 135958526, name: 'UAB "Ekoresursai"' },
  { code: 135962567, name: 'UAB "TOPCOLOR"' },
  { code: 135969889, name: 'Uždaroji akcinė bendrovė "Didchema"' },
  { code: 135974879, name: 'A. Kolupailos firma "Timberlina"' },
  { code: 135976734, name: 'UAB "Ekofrisa"' },
  { code: 135979278, name: 'UAB "Peraltis"' },
  { code: 135979844, name: 'UAB "Girkasas"' },
  { code: 135981724, name: "V. Mincaitės-Žukaitienės įmonė" },
  { code: 136024011, name: 'UAB "Vėjų spektras"' },
  { code: 136025070, name: 'UAB "Energijos sistemų servisas"' },
  { code: 136033551, name: 'UAB "ENG"' },
  { code: 136036519, name: 'Uždaroji akcinė bendrovė "Baltergas"' },
  { code: 136044195, name: 'UAB "RTZ. NT"' },
  { code: 136046812, name: 'UAB "Medienos era"' },
  { code: 136051236, name: 'UAB "KMT"' },
  { code: 136051574, name: "UAB S. Danielius ir ko" },
  { code: 136053963, name: 'UAB "Aldengas"' },
  { code: 136057456, name: 'UAB "Magnuva"' },
  { code: 136057837, name: 'UAB "Visvitas"' },
  { code: 136061344, name: 'UAB "Daliktos mediena"' },
  { code: 140022870, name: 'UAB "Šopa"' },
  { code: 140060248, name: 'UAB "Šviesos Loftai"' },
  { code: 140064645, name: 'L. Sopino firma "ELLIPS"' },
  { code: 140067755, name: 'Uždaroji akcinė bendrovė "KRYTĖ"' },
  { code: 140103151, name: 'Uždaroji akcinė bendrovė "ELMA"' },
  { code: 140135341, name: 'Uždaroji akcinė bendrovė "VAKARAI"' },
  { code: 140155415, name: 'Uždaroji akcinė bendrovė "ARID"' },
  { code: 140167727, name: 'Uždaroji akcinė bendrovė "KORSARAS"' },
  { code: 140245389, name: 'Uždaroji akcinė bendrovė "LAIVŲ SERVISAS"' },
  { code: 140253140, name: 'UAB "SENDVARIO BALDAI"' },
  { code: 140355654, name: 'UAB "Klaipėdos laivų remontas"' },
  { code: 140381058, name: "A. Samauskio statybos-remonto įmonė" },
  { code: 140419886, name: "R. Putkos gamybinė-komercinė firma" },
  { code: 140438324, name: 'Uždaroji akcinė bendrovė "GELEŽIES LAUŽAS"' },
  { code: 140455398, name: 'Uždaroji akcinė bendrovė "Kaiva"' },
  { code: 140492382, name: 'Uždaroji akcinė bendrovė "RR"' },
  { code: 140504621, name: 'Uždaroji akcinė bendrovė "LIVITA"' },
  { code: 140506825, name: 'UAB "EVA"' },
  { code: 140513257, name: 'Uždaroji akcinė bendrovė "KLAIPĖDOS UGNĖ"' },
  { code: 140516029, name: "V. Bambalo keramikos gaminių įmonė" },
  { code: 140518037, name: 'Uždaroji akcinė bendrovė "PERDANGA"' },
  { code: 140521161, name: 'Uždaroji akcinė bendrovė "BERG"' },
  { code: 140528679, name: "A. Narbutaičio prekybos įmonė" },
  { code: 140566765, name: "G. Gaspariano gamybinė įmonė" },
  { code: 140568054, name: 'Uždaroji akcinė bendrovė "RAGNĖ"' },
  { code: 140574299, name: 'UAB "Domus ARX"' },
  { code: 140582246, name: 'Uždaroji akcinė bendrovė "AKVASERVIS"' },
  { code: 140623860, name: "R. Gusevo komercinė firma" },
  { code: 140647950, name: 'R. Žemaičio IĮ "Žemaičių žuvis"' },
  { code: 140656052, name: 'Akcinė bendrovė "KLAIPĖDOS BALDAI"' },
  { code: 140661238, name: "S. Rumbučio prekybinė firma" },
  { code: 140666018, name: 'Uždaroji akcinė bendrovė "Uostamiestis"' },
  { code: 140683462, name: "N. Čichun individuali įmonė" },
  { code: 140684183, name: 'UAB "Algeima"' },
  { code: 140724172, name: 'Uždaroji akcinė bendrovė "Anionas"' },
  { code: 140749026, name: "S. Božio komercinė firma" },
  { code: 140768489, name: 'A. Butkaus firma "AUTONOVA"' },
  { code: 140790247, name: "A. Bogačiovo individuali įmonė" },
  {
    code: 140801918,
    name: 'Uždaroji akcinė bendrovė "KLAIPĖDOS RADARAS"'
  },
  { code: 140875459, name: 'Uždaroji akcinė bendrovė "JAVINĖ"' },
  { code: 140955542, name: "R. Montvydo individuali įmonė" },
  { code: 140964025, name: "V. Vasiliausko individuali įmonė" },
  { code: 140992537, name: "G. Giedraičio individuali įmonė" },
  { code: 141011268, name: 'AB "Grigeo Klaipėda"' },
  { code: 141062234, name: 'UAB "Baldų dokas"' },
  { code: 141063140, name: 'Uždaroji akcinė bendrovė "BANGPŪTYS"' },
  { code: 141074350, name: 'UAB "Hoja Group"' },
  { code: 141089772, name: 'UAB "JOLADA"' },
  { code: 141183296, name: 'UAB "BALDŲ MEKA"' },
  { code: 141195074, name: '"Be-Ge Baltic", UAB' },
  {
    code: 141237986,
    name: 'Uždaroji akcinė bendrovė "ETNOS EKSPOZICIJA"'
  },
  { code: 141242638, name: 'UAB "Diesel Service Group"' },
  { code: 141268017, name: 'Uždaroji akcinė bendrovė "TEBATA"' },
  { code: 141271675, name: "R. Bernoto individuali įmonė" },
  { code: 141280496, name: 'Uždaroji akcinė bendrovė "KLAMETA"' },
  { code: 141314774, name: "O. Bistrovo individuali įmonė" },
  { code: 141315157, name: 'A. Vaišnio firma "Porcelita"' },
  { code: 141376950, name: "S. Drapanauskio gamybinė įmonė" },
  { code: 141390619, name: 'Uždaroji akcinė bendrovė "MERONDA"' },
  { code: 141392812, name: 'Uždaroji akcinė bendrovė "REFRIKA"' },
  { code: 141453172, name: 'Uždaroji akcinė bendrovė "EKOTECHNA"' },
  { code: 141455223, name: "A. Litvino įmonė" },
  { code: 141455561, name: 'Uždaroji akcinė bendrovė "KEGALSTA"' },
  { code: 141511946, name: 'Uždaroji akcinė bendrovė "LAIVŲ TECHNIKA"' },
  { code: 141524826, name: 'UAB "Betono mozaika"' },
  { code: 141525351, name: 'UAB "RSB NOVIKONTAS"' },
  { code: 141525928, name: 'UAB "Belita"' },
  { code: 141557737, name: 'Uždaroji akcinė bendrovė "GILAIVA"' },
  { code: 141570827, name: "A. Zubavičiaus individuali įmonė" },
  { code: 141583511, name: 'UAB "Egesta"' },
  { code: 141597450, name: 'UAB "Vitopas"' },
  { code: 141597984, name: 'V. Daukšio firma "Uvida"' },
  { code: 141602488, name: 'Uždaroji akcinė bendrovė "LISKANDAS"' },
  { code: 141631187, name: "D. Griušio individuali įmonė" },
  { code: 141642582, name: 'UAB "Savi baldai"' },
  { code: 141669825, name: 'S. Žostauto firma "Infosta"' },
  { code: 141681432, name: 'Uždaroji akcinė bendrovė "VINKELIS IR KO"' },
  { code: 141686027, name: 'UAB "Ekonovus"' },
  { code: 141703366, name: 'Uždaroji akcinė bendrovė "RIKNEDA"' },
  { code: 141712372, name: 'UAB "GLASVEGA"' },
  { code: 141725786, name: "R. Buivydo individuali įmonė" },
  { code: 141748055, name: 'Uždaroji akcinė bendrovė "JŪRMAZGĖ"' },
  { code: 141753426, name: 'Uždaroji akcinė bendrovė "ODIVITA"' },
  { code: 141765357, name: 'Uždaroji akcinė bendrovė "REVAKA"' },
  { code: 141765923, name: "R. Jenciaus individuali įmonė" },
  {
    code: 141776414,
    name: 'Uždaroji akcinė bendrovė "BALTIJOS DELIKATESAI"'
  },
  { code: 141805150, name: 'UAB "Deksta"' },
  { code: 141805531, name: 'Uždaroji akcinė bendrovė "SADVITA"' },
  { code: 141811242, name: 'Uždaroji akcinė bendrovė "GLONIJA"' },
  { code: 141815116, name: "R. Morkūno individuali įmonė" },
  { code: 141828149, name: "V. Kosatikovo gamybinė-komercinė įmonė" },
  { code: 141830367, name: 'Uždaroji akcinė bendrovė "IMEDEKSA"' },
  { code: 141844534, name: "VIA AQUARIA UAB" },
  { code: 141870995, name: "A. Bagdono gamybos ir paslaugų firma" },
  { code: 141907326, name: 'Uždaroji akcinė bendrovė "FELTOMA"' },
  { code: 141912316, name: "I. Kontauto individuali įmonė" },
  { code: 141915426, name: 'UAB "Mabrocona"' },
  { code: 141937650, name: 'Uždaroji akcinė bendrovė "ILANGA"' },
  { code: 141960859, name: 'UAB "Autonomas"' },
  { code: 141982898, name: 'UAB "Ostara"' },
  { code: 141986761, name: 'N. Augusčio įmonė "KLASIŠKA MEDIENA"' },
  { code: 142009932, name: "T. Capenkovos komercinė firma" },
  { code: 142010525, name: 'A. Lesnicko individuali įmonė "TISMATAS"' },
  { code: 142034953, name: "G. Pleikio individuali įmonė" },
  { code: 142035336, name: 'UAB "KLAIPĖDOS KARTONO TARA"' },
  { code: 142063652, name: 'UAB "BETONO DANGOS"' },
  { code: 142071752, name: 'Uždaroji akcinė bendrovė "LAUREMIJA"' },
  { code: 142076728, name: 'UAB "SANDARIKLIS"' },
  { code: 142079838, name: "E. Palubinsko individuali įmonė" },
  { code: 142095842, name: 'Uždaroji akcinė bendrovė "APK"' },
  { code: 142098386, name: 'UAB "PACK KLAIPĖDA"' },
  { code: 142107091, name: 'UAB "JAUKUS PASAULIS"' },
  { code: 142108236, name: 'UAB "ŽEMAITIJOS SPAUDA"' },
  { code: 142111218, name: 'UAB "KRISTALO SPINDESYS"' },
  { code: 142129554, name: 'UAB "AVANGARD MARINE"' },
  { code: 142133061, name: 'UAB "KLOSTĖ"' },
  { code: 142136214, name: 'Uždaroji akcinė bendrovė "SKANLIKA"' },
  { code: 142145220, name: "V. Brazo individuali įmonė" },
  { code: 142158068, name: 'UAB "TRYS GIJOS"' },
  { code: 142158972, name: 'Uždaroji akcinė bendrovė "AUTOSTABILA"' },
  { code: 142162294, name: 'UAB "UOSTAMIESČIO LANGAI"' },
  { code: 142166353, name: "UAB Vakarų Baltijos laivų statykla" },
  { code: 142172445, name: 'UAB "SP Construction"' },
  {
    code: 142177182,
    name: 'Uždaroji akcinė bendrovė "VAKARŲ LAIVŲ REMONTAS"'
  },
  { code: 144083792, name: 'UAB "RVT"' },
  { code: 144139433, name: 'UAB "Molinis suvenyras"' },
  { code: 144217280, name: 'IĮ "Autostiklaplastis"' },
  { code: 144263858, name: "Sigito Lanzbergo avalynės gamybos įmonė" },
  { code: 144264230, name: 'Alberto Krėpštos įmonė "Elirona"' },
  { code: 144333114, name: 'AIDO BURGAILOS įmonė "ARTA"' },
  { code: 144360296, name: 'Uždaroji akcinė bendrovė "Dora"' },
  { code: 144411495, name: 'UAB "Stiklas LT"' },
  { code: 144446119, name: 'UAB "Lamekas"' },
  { code: 144466488, name: 'Vaidoto Dijoko gamybinė įmonė "Gojus"' },
  { code: 144482492, name: '"PRODUCTS OF STONE" UAB' },
  { code: 144578423, name: 'Ryčio Kučo įmonė "Ritis"' },
  { code: 144592768, name: 'UAB "Šeigas"' },
  { code: 144634427, name: "Ramūno Čečkausko įmonė" },
  { code: 144663126, name: 'Uždaroji akcinė bendrovė "Largo"' },
  {
    code: 144667719,
    name: 'Vidos Monkevič tikroji ūkinė bendrija "BALTUPĖNAI"'
  },
  { code: 144675776, name: 'Uždaroji akcinė bendrovė "Dionisas"' },
  { code: 144690874, name: "Remigijaus Gusto įmonė" },
  { code: 144723484, name: 'UAB "LBI"' },
  { code: 144729170, name: "A. Vileikio fotografijos centras" },
  { code: 144749778, name: 'Uždaroji akcinė bendrovė "ELGA"' },
  { code: 144839631, name: 'UAB "BALTIC ELEKTRO GROUP"' },
  {
    code: 144855112,
    name: 'Uždaroji akcinė bendrovė "GELŽBETONINĖS KONSTRUKCIJOS"'
  },
  { code: 144932815, name: 'UAB "Juoresta"' },
  { code: 144939037, name: "Vlado Krivicko įmonė" },
  {
    code: 144981883,
    name: 'Uždaroji akcinė bendrovė "Šiaulių tauro statyba"'
  },
  { code: 145019942, name: "Kęstučio Rusteikos įmonė" },
  { code: 145073477, name: "Alvydo Pakarnio įmonė" },
  { code: 145130572, name: 'UAB "Beconis"' },
  { code: 145130953, name: 'Uždaroji akcinė bendrovė "KAROLINOS HES"' },
  { code: 145150799, name: "Sauliaus Rodos įmonė" },
  { code: 145162424, name: "Albino Maraškos įmonė" },
  { code: 145163711, name: 'UAB "Miškoveikla"' },
  { code: 145165534, name: 'UAB "VEREPAS"' },
  { code: 145167357, name: 'Vazgen Davojan įmonė "Jufta"' },
  { code: 145179669, name: "Roberto Alijošiaus įmonė" },
  { code: 145181168, name: 'Uždaroji akcinė bendrovė "RESMITA"' },
  { code: 145196056, name: 'Uždaroji akcinė bendrovė "PUTOKŠNIS"' },
  { code: 145206582, name: 'Uždaroji akcinė bendrovė "Nilvaida"' },
  { code: 145241220, name: 'Uždaroji akcinė bendrovė "BODESA"' },
  { code: 145262887, name: 'UAB "Zelma"' },
  { code: 145273225, name: 'Uždaroji akcinė bendrovė "LETPAKA"' },
  { code: 145278724, name: 'Uždaroji akcinė bendrovė "Armetlina"' },
  { code: 145279979, name: "Benjamino Zurbos įmonė" },
  { code: 145289368, name: "Gedimino Ramanausko įmonė" },
  { code: 145292688, name: 'UAB "Aretida"' },
  { code: 145324577, name: 'Nerijaus Survilos įmonė "AUNEKA"' },
  { code: 145331618, name: 'Uždaroji akcinė bendrovė "Beržalapė"' },
  { code: 145342632, name: 'UAB "MODOPAK"' },
  { code: 145354378, name: 'Uždaroji akcinė bendrovė "SAUDUVA"' },
  { code: 145371527, name: "Rolando Radavičiaus įmonė" },
  { code: 145374594, name: "Ramūno Glazausko individuali įmonė" },
  { code: 145376111, name: 'Uždaroji akcinė bendrovė "METALOIDAS"' },
  { code: 145385313, name: 'UAB "Grafų baldai"' },
  { code: 145387855, name: 'Uždaroji akcinė bendrovė "Nediva"' },
  { code: 145393751, name: 'Ritos Vaidilienės įmonė "Elvasa"' },
  { code: 145395040, name: 'Uždaroji akcinė bendrovė "ARDEZIJA"' },
  { code: 145405577, name: "Tomo Mendelio įmonė" },
  { code: 145407770, name: 'UŽDAROJI AKCINĖ BENDROVĖ "VIBALTPEGA"' },
  { code: 145417355, name: 'UAB "Mini keramika"' },
  { code: 145420718, name: 'UŽDAROJI AKCINĖ BENDROVĖ "TDL ODA"' },
  { code: 145439622, name: 'UAB "Aptaka"' },
  {
    code: 145453052,
    name: "Juozo Katiliaus ir partnerių tikroji ūkinė bendrija"
  },
  {
    code: 145457830,
    name: 'Uždaroji akcinė bendrovė "ODOS GAMINIAI" ir KO'
  },
  { code: 145458747, name: 'UAB "Daisadus"' },
  { code: 145465179, name: "Virginijaus Jakučio įmonė" },
  { code: 145466128, name: 'Uždaroji akcinė bendrovė "AUSNĖ"' },
  { code: 145466651, name: 'UŽDAROJI AKCINĖ BENDROVĖ "ALDAKSA"' },
  { code: 145479684, name: "Audriaus Leparsko įmonė" },
  { code: 145544018, name: 'UAB "Skonio uostas"' },
  { code: 145547651, name: 'UAB "Kamjorda"' },
  { code: 145548034, name: 'UAB "ANTROCELAS"' },
  { code: 145557574, name: "Antano Zauros įmonė" },
  { code: 145571766, name: "Almos Liaudanskienės įmonė" },
  { code: 145582442, name: 'Jono Milašiaus įmonė "Risvės baldai"' },
  { code: 145582976, name: 'UŽDAROJI AKCINĖ BENDROVĖ "ZINERA"' },
  { code: 145595137, name: 'UAB "Aurivita"' },
  { code: 145606950, name: "Egidijaus Griniaus įmonė" },
  { code: 145624592, name: "Neringos Statulevičienės įmonė" },
  { code: 145663442, name: 'UŽDAROJI AKCINĖ BENDROVĖ "SODBETA"' },
  { code: 145744638, name: 'UAB "Sausta"' },
  { code: 145745697, name: "Konrado Geriko įmonė" },
  { code: 145760457, name: 'UAB "GAIRELITA"' },
  { code: 145768913, name: 'UAB "Ergolain baldai"' },
  {
    code: 145787276,
    name: "VIEŠOJI ĮSTAIGA ŠIAULIŲ REGIONO ATLIEKŲ TVARKYMO CENTRAS"
  },
  { code: 145787657, name: 'Uždaroji akcinė bendrovė "Granulta"' },
  { code: 145788225, name: "Vidmanto Andrijaičio įmonė" },
  { code: 145793368, name: "Sauliaus Šiukšterio įmonė" },
  { code: 145838137, name: "Virgilijaus Karinausko įmonė" },
  { code: 145840736, name: "UAB TRIVILITA" },
  { code: 145842559, name: 'UAB "ZAKA"' },
  { code: 145845473, name: 'R. Butkaus firma "Baldų akcentai"' },
  { code: 145847296, name: 'UAB "Stikluva"' },
  { code: 145866072, name: 'UAB "LAIPTUVA"' },
  { code: 145867217, name: 'UAB "Arnesima"' },
  { code: 145890187, name: "STANISLOVO STIRBIO įmonė" },
  { code: 145910526, name: 'UAB "Technesa"' },
  { code: 147001291, name: 'S. Petruškevičiaus įmonė "Retro"' },
  { code: 147012163, name: "V. Amboto įmonė" },
  { code: 147021550, name: 'Uždaroji akcinė bendrovė "Lūgnė"' },
  { code: 147027966, name: 'UAB "Karjerai"' },
  { code: 147031669, name: "Roquette Amilina, AB" },
  { code: 147032618, name: 'UAB "VAIŠĖS VILOJE"' },
  { code: 147038795, name: 'AB "Panevėžio stiklas"' },
  { code: 147058869, name: 'UAB "SNB"' },
  { code: 147062419, name: 'Uždaroji akcinė bendrovė "VEIKLOS RATAS"' },
  { code: 147086466, name: 'UAB "Rukatonas"' },
  { code: 147092024, name: 'IĮ "ŠAMOTO KERAMIKA"' },
  { code: 147094185, name: "V. Žiaunio įmonė" },
  { code: 147095287, name: 'Uždaroji akcinė bendrovė "Nika"' },
  { code: 147099684, name: 'UAB "Plaskanta"' },
  { code: 147100161, name: 'Uždaroji akcinė bendrovė "KRIAUTĖ"' },
  { code: 147125734, name: "A. Apšegos įmonė" },
  { code: 147128278, name: 'Uždaroji akcinė bendrovė "ESTEKA"' },
  { code: 147143223, name: 'Uždaroji akcinė bendrovė "TEMPAS"' },
  { code: 147143942, name: 'Uždaroji akcinė bendrovė "KENTAURAS"' },
  { code: 147162533, name: 'UAB "Medžio meistras"' },
  { code: 147171016, name: "HARJU ELEKTER, UAB" },
  { code: 147173558, name: "J. Čerkelio įmonė" },
  { code: 147190130, name: "F. Banio įmonė" },
  { code: 147203777, name: 'I. Kriščiūno firma "IGIS"' },
  { code: 147213928, name: "D. Fijalkausko individuali įmonė" },
  { code: 147217563, name: 'R. Balčienės įmonė "Kristina"' },
  { code: 147224561, name: '"Plastis", UAB' },
  { code: 147227529, name: 'UAB "Dajanta"' },
  { code: 147231755, name: 'Uždaroji akcinė bendrovė "Galenitas"' },
  { code: 147248313, name: 'AB "PANEVĖŽIO ENERGIJA"' },
  { code: 147290255, name: "SCHMITZ CARGOBULL BALTIC, UAB" },
  { code: 147300781, name: "G. Randžio įmonė" },
  { code: 147310028, name: "G. Rudžio įmonė" },
  { code: 147324871, name: "V. Daunoravičiaus įmonė" },
  { code: 147341792, name: "V. Kaušinio įmonė" },
  { code: 147348438, name: "V. Kasperavičiaus įmonė" },
  { code: 147389787, name: "R. Puzino įmonė" },
  { code: 147397691, name: 'V. Puslio įmonė "Vaizdinys"' },
  { code: 147401093, name: "K. Černeckio individuali įmonė" },
  { code: 147421352, name: "R. Maženio įmonė" },
  { code: 147434951, name: 'UAB "Dainaka"' },
  { code: 147441764, name: "K. Grinskio įmonė" },
  { code: 147464567, name: "L. Tebelškio įmonė" },
  { code: 147517589, name: 'UAB "Fenistra"' },
  { code: 147538569, name: "L. Joco įmonė" },
  { code: 147579612, name: 'UAB "Suvena"' },
  { code: 147583272, name: "R. Žebrio įmonė" },
  { code: 147669052, name: 'Uždaroji akcinė bendrovė "ŽLUGTAS"' },
  { code: 147671270, name: 'UAB "Plastara"' },
  { code: 147706465, name: 'Uždaroji akcinė bendrovė "MERLANGAS"' },
  { code: 147738655, name: 'Akcinė bendrovė "LIETKABELIS"' },
  { code: 147781731, name: 'UAB "Rovyma"' },
  { code: 147822822, name: "J. Zaveckienės įmonė" },
  { code: 147829382, name: 'Uždaroji akcinė bendrovė "AULAUKIS"' },
  { code: 147863452, name: 'Uždaroji akcinė bendrovė "Multimeda"' },
  { code: 147955858, name: "J. Daužicko įmonė" },
  {
    code: 147996488,
    name: 'Uždaroji akcinė bendrovė "PANEVĖŽIO LIRONTA"'
  },
  { code: 148042138, name: 'Uždaroji akcinė bendrovė "ŽLIOBIŠKIS"' },
  { code: 148050042, name: 'A. Vaičiūno firma "VAINRENA"' },
  { code: 148051482, name: 'Uždaroji akcinė bendrovė "MEDRIKA"' },
  { code: 148052627, name: 'Uždaroji akcinė bendrovė "Slavita"' },
  { code: 148055922, name: "D. Usonio IĮ" },
  { code: 148063794, name: 'UAB "Soltera"' },
  { code: 148078497, name: 'Uždaroji akcinė bendrovė "VANDEINA"' },
  { code: 148083868, name: 'UAB "RATAURA"' },
  { code: 148084240, name: 'Uždaroji akcinė bendrovė "VINITOMA"' },
  { code: 148094359, name: 'UAB "Molio Motiejukas"' },
  { code: 148107080, name: 'Uždaroji akcinė bendrovė "VITGINA"' },
  { code: 148115757, name: "L. Musteikio įmonė" },
  { code: 148128975, name: 'UAB "Step master"' },
  { code: 148131423, name: 'Uždaroji akcinė bendrovė "Eratka"' },
  {
    code: 148143692,
    name: 'R. Lukoševičiaus dizaino studija "NAUJA LINIJA"'
  },
  { code: 148149955, name: 'Uždaroji akcinė bendrovė "LEVENDRA"' },
  { code: 148150929, name: "UAB Petro mėsinė" },
  { code: 148153843, name: "V. Danilevičiaus firma" },
  { code: 148153996, name: "B. Kauniečio firma" },
  { code: 148156768, name: 'R. Beleškienės įmonė "RASOS STUDIJA"' },
  { code: 148187475, name: "R. Kazakevičiaus įmonė" },
  { code: 148190119, name: "R. Medučio įmonė" },
  { code: 148194135, name: 'UAB "Sortima"' },
  { code: 148198913, name: 'Uždaroji akcinė bendrovė "GIROVILIS"' },
  { code: 148216635, name: 'UAB "Balteka"' },
  { code: 148236132, name: "E. Sribikės įmonė" },
  { code: 148236470, name: "V. Gaudinskaitės įmonė" },
  { code: 148272744, name: 'IĮ "Plastonas"' },
  { code: 148284718, name: 'Uždaroji akcinė bendrovė "SKYDMEDIS"' },
  { code: 148322122, name: 'Uždaroji akcinė bendrovė "ALŠASTA"' },
  { code: 148332383, name: 'Arūno Meškausko firma "ARMISTA"' },
  { code: 148363514, name: 'UAB "AUTOMATIKOS SISTEMOS"' },
  { code: 148371614, name: 'UAB "GIROJA"' },
  { code: 148384832, name: 'Uždaroji akcinė bendrovė "GLASREMIS"' },
  { code: 148392747, name: 'Uždaroji akcinė bendrovė "ARLEVITA"' },
  { code: 148395661, name: "V. Morozo įmonė" },
  { code: 148396578, name: 'UAB "Metplasta"' },
  { code: 148411941, name: 'UAB "ATMETAS"' },
  { code: 148427212, name: "AQ Wiring Systems UAB" },
  { code: 148432889, name: 'UAB "BALDLITAS"' },
  { code: 148434744, name: 'UAB "DEKROJA"' },
  { code: 148448530, name: 'UAB "HJELLEGJERDE BALTIJA"' },
  { code: 148451131, name: 'UAB "Linas Agro" Grūdų centrai' },
  { code: 148497260, name: "D. Blinkevičiaus įmonė" },
  { code: 148500475, name: "A. Dūdos įmonė" },
  { code: 148501196, name: 'UAB "Imeralė"' },
  { code: 148504872, name: '"IF Concept", UAB' },
  {
    code: 148507797,
    name: 'Vaclavos Grinienės individuali įmonė "GMDG"'
  },
  { code: 148516846, name: "UAB ECOpipes" },
  { code: 148521074, name: 'UAB "PDG"' },
  { code: 148523463, name: "D. Kmiliausko įmonė" },
  { code: 148535394, name: 'UAB "Šaltinio srovė"' },
  { code: 149525645, name: 'R. Bakšio individuali įmonė "LOTERA"' },
  { code: 149547684, name: 'UAB "Kertas"' },
  { code: 149562782, name: "Koslita, UAB" },
  { code: 149625421, name: 'UAB "He - Ma"' },
  { code: 149692740, name: 'AB "Alytaus chemija"' },
  { code: 149708397, name: 'Uždaroji akcinė bendrovė "ALKOMPLEKTAS"' },
  { code: 149717784, name: 'Uždaroji akcinė bendrovė "Alseka"' },
  { code: 149720232, name: 'UAB "Alytaus gelžbetonis"' },
  { code: 149723538, name: 'Uždaroji akcinė bendrovė "Riamona"' },
  { code: 149733112, name: 'Akcinė bendrovė "STALIŲ GAMINIAI"' },
  { code: 149751135, name: "Eivydo Tarasevičiaus firma" },
  { code: 149790661, name: "J. Juso individuali įmonė" },
  { code: 149887466, name: 'Uždaroji akcinė bendrovė "SURGEDĖ"' },
  { code: 149931539, name: "A. Petkevičiaus individuali įmonė" },
  { code: 149938099, name: 'V. Slavinskienės firma "Viversita"' },
  {
    code: 149947714,
    name: 'Uždaroji akcinė bendrovė "Alytaus šilumos tinklai"'
  },
  { code: 149955967, name: "Sigitos Česnakauskienės individuali įmonė" },
  { code: 149970346, name: 'Uždaroji akcinė bendrovė "VIDEMAS"' },
  { code: 150062817, name: 'UAB "Pajūrio turtas"' },
  { code: 150063157, name: 'Uždaroji akcinė bendrovė "Gumos technika"' },
  { code: 150090414, name: "Gintaro Liutkausko individuali įmonė" },
  { code: 150134768, name: 'A. Gegužio individuali įmonė "Algumitas"' },
  { code: 150142672, name: 'UAB "Agjauda"' },
  { code: 150156088, name: 'UAB "Feliksnavis"' },
  { code: 150162212, name: 'UAB "RV Oferta"' },
  { code: 150163652, name: 'UAB "Linmedė"' },
  {
    code: 151031130,
    name: "Albinos Jakštienės prekybos ir pynimo iš vytelių įmonė"
  },
  { code: 151145760, name: 'J. Savicko įmonė "Zena"' },
  { code: 151150227, name: 'Uždaroji akcinė bendrovė "SŪDUVOS KLEVAS"' },
  { code: 151156970, name: 'UAB "Barsis"' },
  { code: 151214459, name: 'Uždaroji akcinė bendrovė "Didma"' },
  { code: 151223650, name: 'Akcinė bendrovė "Vernitas"' },
  { code: 151240048, name: 'Uždaroji akcinė bendrovė "Marivita"' },
  { code: 151293022, name: "Ginto Jarašiaus medžio gaminių dirbtuvė" },
  { code: 151296285, name: 'L. Legano įmonė "Legla"' },
  { code: 151319168, name: 'UAB "Veikta"' },
  { code: 151329657, name: 'Uždaroji akcinė bendrovė "Asdona"' },
  { code: 151335891, name: "Š. R. Brusoko prekybos ir paslaugų įmonė" },
  { code: 151386149, name: 'K. Grigaitienės firma "Balduma"' },
  { code: 151410148, name: "Romualdo Penkausko paslaugų įmonė" },
  { code: 151416749, name: "Jūratės Rugienienės prekybos įmonė" },
  {
    code: 151425755,
    name: 'Uždaroji akcinė bendrovė "Marijampolės šilumos tinklai"'
  },
  { code: 151440853, name: "Zitos Navickienės reklamos firma" },
  { code: 151452250, name: "UAB Baltijos molas" },
  { code: 151454116, name: 'UAB "SVAJAVITA"' },
  { code: 151456277, name: 'UAB "Ugira LT"' },
  {
    code: 151466232,
    name: 'UAB "ARVI KALAKUTAI" komanditinė ūkinė bendrija'
  },
  { code: 151473383, name: 'Uždaroji akcinė bendrovė "IVEGA" IR KO' },
  {
    code: 151479265,
    name: "UAB Marijampolės apskrities atliekų tvarkymo centras"
  },
  { code: 152003664, name: 'Uždaroji akcinė bendrovė "Dzūkija"' },
  { code: 152087922, name: "R. Jucevičiaus įmonė" },
  { code: 152092499, name: 'UAB "Apona"' },
  { code: 152403651, name: 'UAB "Pirklio baldai"' },
  { code: 152623245, name: "D. Dubinienės įmonė" },
  { code: 152658164, name: "V. Žvilaičio individuali įmonė" },
  {
    code: 152697886,
    name: 'Uždaroji akcinė bendrovė "Palangos šilumos tinklai"'
  },
  { code: 152701516, name: 'Uždaroji akcinė bendrovė "Paslauga"' },
  {
    code: 152703524,
    name: 'Uždaroji akcinė bendrovė "NERINGOS KOMUNALININKAS"'
  },
  { code: 152760920, name: 'Uždaroji akcinė bendrovė "Nidos žuvis"' },
  {
    code: 152768582,
    name: 'Uždaroji akcinė bendrovė "Neringos energija"'
  },
  { code: 152833973, name: 'V. Raišio firma "Divėja"' },
  { code: 152840633, name: 'Uždaroji akcinė bendrovė "Birštono šiluma"' },
  {
    code: 152903578,
    name: 'Uždaroji akcinė bendrovė "Naujosios Akmenės komunalininkas"'
  },
  { code: 152976245, name: 'Rimvydo Strigūno įmonė "DASMA"' },
  { code: 153009143, name: 'Akcinė bendrovė "Akmenės cementas"' },
  { code: 153026826, name: "V. Alijošienės parduotuvė" },
  { code: 153040637, name: "A. Ručinsko automobilių remonto įmonė" },
  { code: 153050211, name: 'UAB "Osrem"' },
  { code: 153074073, name: 'UAB "Medis ir betonas"' },
  {
    code: 153251171,
    name: 'Uždaroji akcinė bendrovė "AKMENĖS ENERGIJA"'
  },
  { code: 153251214, name: 'UAB "MORTAR AKMENĖ"' },
  { code: 153276025, name: 'UAB "GPIX"' },
  { code: 153656515, name: 'S. Ludavičiaus įmonė "Linvyda"' },
  { code: 153679122, name: "Rimo Bilinsko medžio apdirbimo įmonė" },
  { code: 153682442, name: 'Uždaroji akcinė bendrovė "Alberna"' },
  { code: 153700164, name: 'Uždaroji akcinė bendrovė "Eremas"' },
  { code: 153702749, name: 'Uždaroji akcinė bendrovė "Sautūras"' },
  {
    code: 153703655,
    name: 'Uždaroji akcinė bendrovė "VITA BALTIC INTERNATIONAL"'
  },
  { code: 153719798, name: "A. Sankaičio individuali įmonė" },
  {
    code: 153720195,
    name: 'Alytaus rajono savivaldybės įmonė "Simno komunalininkas"'
  },
  { code: 153722627, name: "A. Bačiuškos individuali įmonė" },
  { code: 153725694, name: "D. Tamulionio individuali įmonė" },
  {
    code: 153731633,
    name: 'Uždaroji akcinė bendrovė "Jundos stalių gaminiai"'
  },
  { code: 153734558, name: 'G. Baleženčio firma "Tringala"' },
  { code: 153735083, name: 'UAB "Baldaika"' },
  { code: 153737091, name: "R. Murausko individuali įmonė" },
  { code: 153740269, name: 'Uždaroji akcinė bendrovė "Sanlangis"' },
  { code: 153744328, name: 'Uždaroji akcinė bendrovė "Šiaurinis namas"' },
  { code: 154112751, name: 'Uždaroji akcinė bendrovė "Anykščių šiluma"' },
  { code: 154115523, name: 'Individuali įmonė "Šilas"' },
  { code: 154147670, name: 'Uždaroji akcinė bendrovė "ANRESTAS"' },
  {
    code: 154156915,
    name: 'Valento Dragūno individuali įmonė "VD statyba"'
  },
  { code: 154161481, name: 'MB "Mediena"' },
  { code: 154161524, name: 'Uždaroji akcinė bendrovė "Guoba"' },
  { code: 154167210, name: 'UAB "ESSPO"' },
  { code: 154188681, name: 'A. Češiūno įmonė "Žibuldė"' },
  { code: 154203521, name: "Alvydo Eimonto individuali įmonė" },
  { code: 154251530, name: 'V. Kaštelianovo firma "Kirvilė"' },
  { code: 154271942, name: 'Uždaroji akcinė bendrovė "Lartekas"' },
  { code: 154272478, name: 'Uždaroji akcinė bendrovė "Kirvilė"' },
  { code: 154284594, name: 'UAB "Dekoreksas"' },
  { code: 154319821, name: "A. Vaitonio firma" },
  { code: 154329210, name: "Martyno Katkevičiaus įmonė" },
  { code: 154332879, name: 'UAB "Baldjoda"' },
  { code: 154333251, name: "V. Valikonytės įmonė" },
  { code: 154704169, name: 'Uždaroji akcinė bendrovė "Tyla"' },
  { code: 154709091, name: 'UAB "Papilio kibirkštis"' },
  { code: 154742821, name: 'Uždaroji akcinė bendrovė "Agaras"' },
  {
    code: 154799441,
    name: 'Uždaroji akcinė bendrovė "Biržų komunalinis ūkis"'
  },
  {
    code: 154866655,
    name: 'Uždaroji akcinė bendrovė "Biržų šilumos tinklai"'
  },
  { code: 155294919, name: "Algirdo Skinderskio įmonė" },
  { code: 155299122, name: "Dariaus Lukinsko įmonė" },
  { code: 155306353, name: 'UAB "VIRBANTĖ"' },
  { code: 155314072, name: "Reginos Sabašauskienės įmonė" },
  { code: 155316842, name: 'UAB "Būginių gausa"' },
  { code: 155475129, name: 'Akcinė bendrovė firma "Tiekimas"' },
  {
    code: 155513971,
    name: 'Uždaroji akcinė bendrovė "Didžiasalio komunalinės paslaugos"'
  },
  { code: 155576498, name: "J. Jurkėnienės įmonė" },
  { code: 155597510, name: 'UAB "Linkmedis"' },
  { code: 155604556, name: "Vaclovo Baltakio individuali įmonė" },
  { code: 155605081, name: "Vaidanto Jurgelevičiaus įmonė" },
  { code: 155626780, name: 'A. Telyčėno firma "Alfega"' },
  {
    code: 155634880,
    name: "Uždaroji akcinė bendrovė Ignalinos šilumos tinklai"
  },
  { code: 155635982, name: 'Gendručio Stuko įmonė "Kiaunos vingis"' },
  { code: 155637271, name: "Ž. Mudėno individuali įmonė" },
  { code: 155929959, name: "J. Melkovo individuali įmonė" },
  { code: 155937144, name: 'Uždaroji akcinė bendrovė "VISMETA"' },
  { code: 155950049, name: '"BONDAR ir ko" IĮ' },
  {
    code: 155967130,
    name: 'Uždaroji akcinė bendrovė "Draugystės žiedas"'
  },
  { code: 156515249, name: 'Uždaroji akcinė bendrovė "Jonavos Nita"' },
  { code: 156581128, name: "G. Kontrimavičiaus individuali įmonė" },
  { code: 156587533, name: 'Uždaroji akcinė bendrovė "Ajeras"' },
  { code: 156591574, name: 'Uždaroji akcinė bendrovė "Jonavos stalius"' },
  { code: 156605974, name: 'Žemės ūkio bendrovė "Topolis"' },
  { code: 156607459, name: 'Žemės ūkio bendrovė "Barsta"' },
  {
    code: 156628243,
    name: 'Jonavos rajono žemės ūkio bendrovė "Girnos"'
  },
  { code: 156637064, name: 'Uždaroji akcinė bendrovė "Megapakas"' },
  { code: 156663949, name: "R. Goštauto individuali įmonė" },
  { code: 156665238, name: 'S. Bucevičiaus individuali įmonė "Seguva"' },
  { code: 156667399, name: 'Akcinė bendrovė "Achema"' },
  { code: 156667584, name: 'Uždaroji akcinė bendrovė "Baldai jums"' },
  {
    code: 156667812,
    name: 'Uždaroji akcinė bendrovė "Jonavos energetika"'
  },
  { code: 156671668, name: 'UAB "Adalonija"' },
  { code: 156676633, name: "A. Markevičiaus įmonė" },
  { code: 156716241, name: 'Uždaroji akcinė bendrovė "Agmena"' },
  { code: 156718630, name: 'Uždaroji akcinė bendrovė "EDEGA"' },
  { code: 156720859, name: 'UAB "OLSANTRA"' },
  { code: 156725596, name: 'Uždaroji akcinė bendrovė "Admodė"' },
  {
    code: 156737189,
    name: 'Uždaroji akcinė bendrovė "Jonavos šilumos tinklai"'
  },
  { code: 156871035, name: 'UAB "Kokybės linija"' },
  { code: 156876534, name: 'UAB "Godamita"' },
  { code: 156894361, name: "Arvydo Noreikos įmonė" },
  { code: 156915955, name: 'Uždaroji akcinė bendrovė "Eurostilius"' },
  { code: 157005345, name: 'Uždaroji akcinė bendrovė "Saugera"' },
  { code: 157015791, name: 'UAB "Justaura"' },
  { code: 157021698, name: 'UAB "TTT"' },
  { code: 157022070, name: 'UAB "Logesa"' },
  { code: 157022832, name: 'UAB "V. Kavoliūnienės įmonė"' },
  { code: 157024655, name: 'Uždaroji akcinė bendrovė "ANTIJARA"' },
  { code: 157035527, name: "Vadimo Novokščionovo įmonė" },
  { code: 157531199, name: "Romo Sperskio medienos apdirbimo įmonė" },
  { code: 157534722, name: 'Uždaroji akcinė bendrovė "Rytas"' },
  { code: 157547221, name: 'Žemės ūkio bendrovė "Delikatesas"' },
  { code: 157548095, name: 'UAB "Rėmas"' },
  { code: 157593375, name: 'UAB "Kerdara"' },
  { code: 157602461, name: 'UAB "JONIŠKIO GRŪDAI"' },
  { code: 157609826, name: "Sauliaus Žilio individuali įmonė" },
  { code: 157625264, name: "Viliaus-Arvydo Šukio individuali įmonė" },
  { code: 157634651, name: "Edmundo Jakučio individuali įmonė" },
  { code: 157645480, name: "Stanislavos Valainienės individuali įmonė" },
  { code: 157687636, name: "UAB Gren Joniškis" },
  { code: 157689644, name: 'UAB "Joniškio baldai"' },
  { code: 157693151, name: "Virginijaus Baikščio individuali įmonė" },
  { code: 157940388, name: 'A. Kalinausko įmonė "Šiaurės medis"' },
  { code: 157940573, name: "Vytauto Bačkio individuali įmonė" },
  { code: 158113548, name: 'L. Drungilienės įmonė "Rožė"' },
  { code: 158139450, name: 'UAB "Vėjo parkas"' },
  { code: 158147027, name: 'N. Breitmozerienės įmonė "Krėva"' },
  { code: 158183258, name: "G. Godvaišos lentpjūvė" },
  {
    code: 158193747,
    name: 'Jurbarko rajono žemės ūkio bendrovė "Spyglys"'
  },
  { code: 158203935, name: 'Uždaroji akcinė bendrovė "Dainiai"' },
  { code: 158217689, name: 'Uždaroji akcinė bendrovė "Lybiškiai"' },
  { code: 158238135, name: "Algimos Štulienės firma" },
  { code: 158249726, name: 'UAB "Jurbarkų mėsa"' },
  {
    code: 158257445,
    name: 'Jurbarko rajono žemės ūkio bendrovė "Šimkaičiai"'
  },
  { code: 158274366, name: 'UAB "ANTESTO"' },
  { code: 158285423, name: "A. Vančio individuali įmonė" },
  { code: 158299362, name: "R. Pinaičio individuali įmonė" },
  { code: 158323557, name: 'UAB "REGUMA"' },
  { code: 158738628, name: "K. Simanavičiaus individuali įmonė" },
  {
    code: 158742516,
    name: 'Žemės ūkio kooperatyvas "Jurbarko linų verslas"'
  },
  { code: 158748540, name: 'UAB "Laukmeda"' },
  { code: 158756793, name: "Smalininkų bendruomenės centras" },
  {
    code: 158810974,
    name: "Virginijaus Linkaus automobilių salonų remonto personalinė įmonė"
  },
  { code: 158813212, name: 'UAB "Skroblas"' },
  { code: 158814271, name: 'Uždaroji akcinė bendrovė "Mega"' },
  { code: 158819813, name: 'Uždaroji akcinė bendrovė "Beržas"' },
  { code: 158828449, name: 'Uždaroji akcinė bendrovė "Roda"' },
  { code: 158831388, name: 'UAB "A. Jasevičiaus baldai"' },
  { code: 158853993, name: "Gražinos Marcinkevičienės įmonė" },
  { code: 158891218, name: "Akcinė bendrovė Kaišiadorių paukštynas" },
  { code: 158893183, name: 'UAB "Relektra"' },
  { code: 158906339, name: 'G. Kanaševičiaus personalinė įmonė "DESĖ"' },
  { code: 158915911, name: 'Žemės ūkio bendrovė "Nematekas"' },
  { code: 158919938, name: 'UAB "Elektrėnų mėsa"' },
  {
    code: 158938714,
    name: 'Gintaro Kurmino individuali įmonė "KURGINTA"'
  },
  { code: 158953050, name: "Raimondo Sasnausko įmonė" },
  { code: 158954871, name: "Genės Svetakaitės keramikos dirbinių įmonė" },
  { code: 158955820, name: 'Uždaroji akcinė bendrovė "AROSTĖ"' },
  { code: 158957643, name: "Eugenijaus Banio įmonė" },
  { code: 158959128, name: 'UAB "Clemence Richard"' },
  { code: 158973277, name: "JONIKO STALIAUS DIRBTUVĖ" },
  { code: 158978438, name: 'Uždaroji akcinė bendrovė "Ovalda"' },
  {
    code: 158981224,
    name: "Kaišiadorių rajono žemės ūkio bendrovė AGROPASLAUGA"
  },
  { code: 158985589, name: "Laimučio-Mykolo Belekevičiaus įmonė" },
  { code: 158992815, name: "Kęstučio Medzevičiaus personalinė įmonė" },
  {
    code: 158994442,
    name: 'Uždaroji akcinė bendrovė "Nemuno baldų prekyba"'
  },
  {
    code: 158996646,
    name: 'Uždaroji akcinė bendrovė "KAIŠIADORIŲ ŠILUMA"'
  },
  { code: 158997214, name: "Modesto Zujaus įmonė" },
  { code: 159001242, name: "UAB INEDINA" },
  { code: 159001580, name: "K. Misiūno personalinė įmonė" },
  { code: 159004167, name: "Zenono Vaitonio įmonė" },
  { code: 159421260, name: 'UAB "Tilas"' },
  { code: 159421456, name: 'Uždaroji akcinė bendrovė "SVAUNĖ"' },
  { code: 159428625, name: "Antano Kučinsko įmonė" },
  { code: 159429727, name: "Uždaroji akcinė bendrovė MIGLIUKAS" },
  { code: 159501888, name: 'L. Skridailos įmonė "Liudvikas"' },
  { code: 159515717, name: "Povilo Staikūno įmonė" },
  { code: 159519886, name: "V. Vaitkūno įmonė" },
  { code: 159555779, name: 'UAB "Ronika"' },
  { code: 159592044, name: 'Uždaroji akcinė bendrovė "Kauno žuvelė"' },
  { code: 159601098, name: 'Zenono Šveikausko įmonė "Polidek"' },
  { code: 159603715, name: "Romo Vaičaičio įmonė" },
  { code: 159620440, name: "UAB Kalėdienės ūkis" },
  {
    code: 159658850,
    name: 'B. A. Steponavičiaus prekybos įmonė "Ąžuolas"'
  },
  { code: 159665097, name: 'Uždaroji akcinė bendrovė "Keva"' },
  { code: 159673578, name: 'Uždaroji akcinė bendrovė "Mega"' },
  { code: 159678696, name: "O. Savickienės keramikos dirbinių įmonė" },
  { code: 159707137, name: 'Uždaroji akcinė bendrovė "Tartokas"' },
  { code: 159722192, name: 'Žemės ūkio bendrovė "Kadagynė"' },
  { code: 159753476, name: 'R. Keliausko įmonė "Notanga"' },
  { code: 159755331, name: 'S. Abramavičiaus firma "Satigis"' },
  { code: 159760093, name: "A. Valutkevičiaus įmonė" },
  { code: 159760855, name: "Audronio Vaško įmonė" },
  { code: 159770397, name: 'Uždaroji akcinė bendrovė "Ebonitas"' },
  { code: 159774794, name: "A. Statkevičiaus firma" },
  {
    code: 159785470,
    name: 'Uždaroji akcinė bendrovė "Durpių puodeliai"'
  },
  { code: 159826942, name: "Rimutės Povilaitienės firma" },
  { code: 159833189, name: "Alfonso Raišučio firma" },
  { code: 159833221, name: 'V. Sriubo firma "Vaidvilė"' },
  { code: 159835425, name: "Valentino Tuleikio įmonė" },
  { code: 159848262, name: 'UAB "Diforma"' },
  { code: 159850676, name: "V. Kvalitos įmonė" },
  { code: 159860446, name: "P. V. Dulinsko įmonė" },
  { code: 159867234, name: 'Vyganto Jazersko įmonė "Puodžiai"' },
  { code: 159869395, name: 'UAB "Eldvena"' },
  { code: 159874047, name: "Jono Kugio įmonė" },
  { code: 159883968, name: 'R. Lapūno įmonė "Lorama"' },
  { code: 159890813, name: "Juozo Ilevičiaus įmonė" },
  { code: 159901341, name: 'Uždaroji akcinė bendrovė "Tauriga"' },
  { code: 159912932, name: 'A. Akelio įmonė "Girkštis"' },
  { code: 159921949, name: "Teresės Rimkienės įmonė" },
  { code: 159925627, name: "V. Vaitiekūno įmonė" },
  { code: 159929643, name: "Sauliaus Banaičio įmonė" },
  { code: 159931295, name: 'Uždaroji akcinė bendrovė "Girga"' },
  { code: 159938083, name: 'Uždaroji akcinė bendrovė "Printela"' },
  { code: 159940682, name: "Alberto Diliaus įmonė" },
  { code: 159950267, name: 'Uždaroji akcinė bendrovė "Daistana"' },
  { code: 159963713, name: 'Uždaroji akcinė bendrovė "Poliplastas"' },
  { code: 159975644, name: "A. Ragelio įmonė" },
  { code: 159984312, name: "Romo Cilišausko įmonė" },
  { code: 160204019, name: 'ŽŪB "MINKUVA"' },
  { code: 160285462, name: 'Uždaroji akcinė bendrovė "Ledana"' },
  { code: 160289293, name: 'Uždaroji akcinė bendrovė "Medinedas"' },
  { code: 160302082, name: "Giedriaus Gaberto įmonė" },
  { code: 160304329, name: 'UAB "Zigelita"' },
  { code: 160304471, name: "A. Kukučio įmonė" },
  { code: 160309447, name: 'UAB "Algyta"' },
  { code: 160311131, name: "Alfredo Šimonio individuali įmonė" },
  { code: 160320333, name: "R. Žiaugros individuali įmonė" },
  { code: 160328223, name: 'UAB "Sportema"' },
  { code: 160328757, name: 'Uždaroji akcinė bendrovė "TITAGONAS"' },
  { code: 160421364, name: 'UAB "BALTIJOS POLISTIRENAS"' },
  { code: 160422847, name: 'UAB "Adikopas"' },
  { code: 160425195, name: 'UAB "GALIUS E"' },
  { code: 160432617, name: 'UAB "NIJANGA"' },
  { code: 160445073, name: 'Uždaroji akcinė bendrovė "Baltic baldai"' },
  { code: 160453216, name: 'UAB "Parksaka"' },
  { code: 161110455, name: 'Akcinė bendrovė "LIFOSA"' },
  { code: 161111219, name: 'Akcinė bendrovė "Nordic Sugar Kėdainiai"' },
  { code: 161124960, name: "Jono Kriukelio įmonė" },
  {
    code: 161133639,
    name: 'A. Mižutavičiaus tikroji ūkinė bendrija "Tūbiai"'
  },
  { code: 161192324, name: 'Romano Žukausko firma "Lijola"' },
  { code: 161219180, name: 'Vigimanto Lukšio įmonė "Maišelis"' },
  { code: 161223264, name: 'UAB "VILTADA"' },
  { code: 161289086, name: 'Dariaus Grigaro firma "Pakeda"' },
  { code: 161300967, name: "Gintauto Volkevičiaus komercinė firma" },
  { code: 161356713, name: "Ernesto Berankio Įmonė" },
  { code: 161411049, name: 'Uždaroji akcinė bendrovė "RIMVINA"' },
  { code: 161425173, name: 'Uždaroji akcinė bendrovė "Statmeta"' },
  { code: 161427181, name: 'UAB "Po obelėle"' },
  { code: 161435858, name: "Žymanto Sirvido įmonė" },
  {
    code: 161453347,
    name: 'Uždaroji akcinė bendrovė "LOLITA IR PARTNERIAI"'
  },
  { code: 161455355, name: "Rolando Neicelio įmonė" },
  { code: 161637614, name: "MINDAUGO PRALGAUSKO ĮMONĖ" },
  { code: 161649545, name: "GINTARO ŠNIUKŠTOS ĮMONĖ" },
  { code: 161732197, name: 'Uždaroji akcinė bendrovė "Svaitransa"' },
  {
    code: 161732763,
    name: "Uždaroji akcinė bendrovė P. Varkojis ir kompanija"
  },
  { code: 161735535, name: 'Uždaroji akcinė bendrovė "Raguvilė"' },
  { code: 161740144, name: 'UAB "VERMEGA"' },
  { code: 161740678, name: "Rimgaudo Serapino įmonė" },
  { code: 162409528, name: "V. Šimkaus baldų įmonė" },
  { code: 162444276, name: 'A. Arlausko individuali įmonė "Kukutis"' },
  { code: 162463433, name: 'KELMĖS RAJONO ŽEMĖS ŪKIO BENDROVĖ "DAUBA"' },
  { code: 162526410, name: "Arūno Kazakevičiaus gamybinė-komercinė firma" },
  { code: 162534510, name: 'Uždaroji akcinė bendrovė "ŽEMAIRA"' },
  { code: 162537773, name: "Valdo Rutkūno gamybinė komercinė įmonė" },
  { code: 162569810, name: "Liucijaus Jokūbausko įmonė" },
  { code: 162582487, name: 'Uždaroji akcinė bendrovė "SMIDRŪNAS"' },
  { code: 162757748, name: "Alekso Gėdvilos įmonė" },
  { code: 162768762, name: "Juozo Birgiolos įmonė" },
  { code: 162775575, name: "Vaclovo Kulevičiaus įmonė" },
  { code: 162941844, name: 'UAB "Kelmolis"' },
  { code: 163111851, name: 'Uždaroji akcinė bendrovė "Etna"' },
  { code: 163112387, name: 'Uždaroji akcinė bendrovė "Meta"' },
  { code: 163116784, name: 'D. Latako įmonė "Šulinys"' },
  { code: 163131544, name: 'AB "LOTOS Geonafta"' },
  { code: 163138147, name: "A. Grubliausko pynimo įmonė" },
  { code: 163185392, name: 'Uždaroji akcinė bendrovė "Agrovet"' },
  { code: 163216375, name: 'Uždaroji akcinė bendrovė "Konvesta"' },
  { code: 163231288, name: "L. Karbauskienės statybos įmonė" },
  { code: 163262419, name: 'UAB "Faustėjos baldai"' },
  { code: 163273248, name: 'Uždaroji akcinė bendrovė "Akmeda"' },
  { code: 163294947, name: "T. Surplio gamybinė - komercinė firma" },
  { code: 163314524, name: 'Uždaroji akcinė bendrovė "SAKUONA"' },
  { code: 163316913, name: "V. Urbono mėsos gamybos įmonė" },
  { code: 163335461, name: "E. Kumpio įmonė" },
  { code: 163335657, name: "S. Girčiaus gamybinė - komercinė įmonė" },
  { code: 163344510, name: 'UAB "AMRD Amber"' },
  { code: 163351095, name: 'UAB "Inter sedes"' },
  { code: 163356441, name: "A. Einikio medienos apdirbimo įmonė" },
  { code: 163377574, name: 'IĮ "Agmeda"' },
  { code: 163381124, name: "P. Kardelio individuali įmonė" },
  { code: 163383132, name: 'Uždaroji akcinė bendrovė "Dumpiai"' },
  { code: 163383285, name: "S. Venckaus individuali įmonė" },
  { code: 163387725, name: 'UAB "Veivirza LT"' },
  { code: 163387878, name: 'Uždaroji akcinė bendrovė "Vertika"' },
  { code: 163391951, name: 'Uždaroji akcinė bendrovė "Simita"' },
  { code: 163396927, name: 'Uždaroji akcinė bendrovė "DOLENA"' },
  {
    code: 163397267,
    name: 'A. Girtavičiaus ekologinė - gamybinė įmonė "Ekovesta"'
  },
  { code: 163400090, name: "A. Šatkaus įmonė" },
  { code: 163402675, name: 'G. Stapono individuali įmonė "Galfindas"' },
  { code: 163545513, name: "V. Stankaus individuali įmonė" },
  { code: 163652255, name: "R. Kuprevičiaus medžio apdirbimo įmonė" },
  { code: 163659196, name: "M. Zakarevičiaus individuali įmonė" },
  { code: 163659239, name: 'UAB "Žemaitijos hidroenergija"' },
  { code: 163659762, name: 'Uždaroji akcinė bendrovė "Dauparų žuvis"' },
  { code: 163660921, name: "Ž. Daugėlos individuali įmonė" },
  { code: 163666618, name: "J. Kniukštos medžio apdirbimo įmonė" },
  { code: 163679793, name: 'UAB "ESPERSEN LIETUVA"' },
  { code: 163679836, name: "P. Cirtauto individuali įmonė" },
  { code: 163683158, name: 'Uždaroji akcinė bendrovė "Baldija"' },
  { code: 163683724, name: "D. Tamošauskienės įmonė" },
  { code: 163690722, name: 'Uždaroji akcinė bendrovė "Kerežis"' },
  { code: 163691443, name: 'Uždaroji akcinė bendrovė "Eurogrupė"' },
  {
    code: 163693070,
    name: 'Uždaroji akcinė bendrovė "Hoja electronics"'
  },
  { code: 163710071, name: "V. Matučio personalinė įmonė" },
  { code: 163710648, name: 'UAB "Rogija"' },
  { code: 163722045, name: 'Uždaroji akcinė bendrovė "Ręstinis būstas"' },
  { code: 163723866, name: "R. Perutavičiaus individuali įmonė" },
  { code: 163728646, name: "V. Beinorio gamybinė-komercinė įmonė" },
  { code: 163737848, name: 'Uždaroji akcinė bendrovė "Litimbera"' },
  { code: 163742457, name: 'UAB "GABITA"' },
  {
    code: 163743744,
    name: "Uždaroji akcinė bendrovė Klaipėdos regiono atliekų tvarkymo centras"
  },
  { code: 163746135, name: 'UAB "Trimtima"' },
  { code: 163751082, name: 'UAB "Montroma"' },
  { code: 163752565, name: "R. Juškos individuali įmonė" },
  { code: 163936832, name: 'Uždaroji akcinė bendrovė "Stanius ir Ko"' },
  { code: 163961134, name: 'UAB "Medis"' },
  { code: 164022010, name: "R. Ramanausko prekybos įmonė" },
  { code: 164035196, name: "A. Pociaus meno dirbinių įmonė" },
  {
    code: 164056219,
    name: 'Kretingos rajono žemės ūkio bendrovė "Statyba"'
  },
  { code: 164080476, name: 'Uždaroji akcinė bendrovė "VARA"' },
  { code: 164090246, name: 'UAB "Edka"' },
  { code: 164111110, name: "A. Staškausko komercinė-ūkinė įmonė" },
  {
    code: 164111459,
    name: 'Uždaroji akcinė bendrovė "Kretingos autoremonto įmonė"'
  },
  { code: 164112899, name: 'Akcinė bendrovė "Žemaitijos linai"' },
  { code: 164143987, name: 'Uždaroji akcinė bendrovė "Terekas"' },
  { code: 164196580, name: "R. Kupstienės komercinė-ūkinė įmonė" },
  {
    code: 164231647,
    name: 'Uždaroji akcinė bendrovė "Kalniaus mediena"'
  },
  { code: 164272277, name: 'UAB "Medžio gija"' },
  { code: 164272462, name: "S. Pleikio įmonė" },
  {
    code: 164294882,
    name: "Uždaroji akcinė bendrovė Kretingos šilumos tinklai"
  },
  { code: 164603122, name: 'Uždaroji akcinė bendrovė "Vandlita"' },
  { code: 164694150, name: 'Uždaroji akcinė bendrovė "STREMEDA"' },
  { code: 164695829, name: "Virginijaus Dargenio individuali įmonė" },
  { code: 164699083, name: 'Uždaroji akcinė bendrovė "MEKREDA"' },
  { code: 164715363, name: 'G. Balčiūno firma "Rimolita"' },
  { code: 164749419, name: 'UAB "Rivilda"' },
  {
    code: 164750688,
    name: 'Kupiškio rajono žemės ūkio bendrovė "Antašavos centras"'
  },
  { code: 164756417, name: 'Uždaroji akcinė bendrovė "Auliškis"' },
  {
    code: 164767399,
    name: "K. Cesevičiaus individuali plastmasinių gaminių įmonė"
  },
  { code: 164780489, name: 'Akcinė bendrovė "Simega"' },
  { code: 164791699, name: 'B. Slieko įmonė "Lingera"' },
  { code: 164802117, name: 'UAB "Sagvi"' },
  { code: 164817877, name: 'Uždaroji akcinė bendrovė "Vetagra"' },
  { code: 165107168, name: 'P. Gelčio įmonė "Vaistažolė"' },
  { code: 165118182, name: 'UAB "Albimeda"' },
  { code: 165142710, name: 'Uždaroji akcinė bendrovė "Ąžuolas"' },
  { code: 165159236, name: "G. Truskos personalinė įmonė" },
  { code: 165213417, name: "R. Burškio personalinė įmonė" },
  { code: 165219441, name: 'Uždaroji akcinė bendrovė "Lazdijų šiluma"' },
  { code: 165220372, name: 'Uždaroji akcinė bendrovė "Pietų Megrame"' },
  { code: 165223482, name: 'Uždaroji akcinė bendrovė "Vakatas"' },
  { code: 165224584, name: "J. Kvederio gamybinė - komercinė įmonė" },
  { code: 165242835, name: "Ramunės Orliukienės personalinė įmonė" },
  { code: 165381952, name: "A. Užtupienės individuali įmonė" },
  { code: 165438652, name: 'UAB "Pietų terminalas"' },
  {
    code: 165635062,
    name: 'V. Šerkšno gamybinė prekybos reklamos firma "Šerkšnas"'
  },
  { code: 165645747, name: 'L. Skinkio keramikos dirbtuvė "GANDAS"' },
  { code: 165651981, name: "Vaikučio medžio apdirbimo įmonė" },
  { code: 165666531, name: "Užgirių žemės ūkio bendrovė" },
  { code: 165697434, name: 'Uždaroji akcinė bendrovė "JŪRĖS MEDIS"' },
  {
    code: 165708539,
    name: "Artūro Kavaliausko gamybos ir komercijos įmonė"
  },
  { code: 165711510, name: 'Uždaroji akcinė bendrovė "SUMEDA"' },
  { code: 165728940, name: 'Uždaroji akcinė bendrovė "Senpilė"' },
  { code: 165733745, name: 'Uždaroji akcinė bendrovė "GERNORA"' },
  { code: 165738678, name: "Juozupo Zimnicko prekybos įmonė" },
  {
    code: 165741464,
    name: 'Uždaroji akcinė bendrovė "Vandens jėgainės"'
  },
  { code: 165746625, name: 'Uždaroji akcinė bendrovė "Juodeliai"' },
  { code: 165746963, name: "UAB IKEA Industry Lietuva" },
  { code: 165764267, name: 'UAB "Kazlų Rūdos baldai"' },
  {
    code: 165773316,
    name: "Almanto Matusevičiaus gamybinė prekybinė įmonė"
  },
  { code: 165779874, name: "Reimundo Tyro įmonė" },
  {
    code: 165793685,
    name: 'Gintauto Petravičiaus individuali įmonė "ELPEGA"'
  },
  { code: 165828499, name: 'Uždaroji akcinė bendrovė "ASAJA"' },
  { code: 165828912, name: 'Uždaroji akcinė bendrovė "Gikra"' },
  { code: 165832049, name: "Rolando Aukštakalnio firma" },
  {
    code: 165841436,
    name: 'L. Plikaičio medžio apdirbimo įmonė "LAURASTA"'
  },
  { code: 166090017, name: 'UAB "Lukalna"' },
  { code: 166091457, name: 'UAB "Kupriai"' },
  {
    code: 166092559,
    name: 'Uždaroji akcinė bendrovė "Kazlų Rūdos šilumos tinklai"'
  },
  { code: 166427573, name: 'UAB "Mažeikių mėsinė"' },
  { code: 166435716, name: "Rimanto Skališiaus baldų gamybos įmonė" },
  { code: 166451720, name: 'Akcinė bendrovė "ORLEN Lietuva"' },
  { code: 166540592, name: 'Martyno Juciaus įmonė "Rema"' },
  { code: 166558829, name: 'Uždaroji akcinė bendrovė "Sedos medis"' },
  { code: 166568360, name: 'UAB "Gluosnėja"' },
  { code: 166635955, name: 'UAB "Lyra group"' },
  { code: 166709957, name: 'UAB "Randevu"' },
  { code: 166727784, name: 'UAB "Stepono baldai"' },
  { code: 166744171, name: "Dainoros Račkauskienės individuali įmonė" },
  { code: 166778750, name: 'UAB "Malorija"' },
  { code: 166779286, name: 'Uždaroji akcinė bendrovė "Vigesta"' },
  { code: 166821874, name: 'Uždaroji akcinė bendrovė "Gonitas"' },
  {
    code: 166846151,
    name: 'Uždaroji akcinė bendrovė "A. Juzokas ir partneriai"'
  },
  { code: 166857938, name: 'UAB "Viržada"' },
  { code: 166890678, name: "Albino Bučio individuali įmonė" },
  {
    code: 166893973,
    name: 'Uždaroji akcinė bendrovė "Mažeikių acetilenas"'
  },
  { code: 166895458, name: 'MB "Baldinis"' },
  {
    code: 166901968,
    name: 'Uždaroji akcinė bendrovė "Mažeikių šilumos tinklai"'
  },
  { code: 166919432, name: 'UAB "Bogvila"' },
  { code: 166919813, name: 'Uždaroji akcinė bendrovė "Vistana"' },
  { code: 167206670, name: 'Uždaroji akcinė bendrovė "Taumona"' },
  { code: 167344881, name: 'UAB "Eglitana"' },
  { code: 167346170, name: 'UAB "Kuprijus"' },
  { code: 167350820, name: 'UAB "Tirmeda" ir Ko' },
  { code: 167355415, name: 'UAB "Migiris"' },
  { code: 167359584, name: 'UAB "Linvigma"' },
  { code: 167369888, name: 'UAB "Arbaldas"' },
  { code: 167390010, name: 'UAB "Enhydris"' },
  { code: 167392171, name: 'UAB "Klijuotas tašas"' },
  { code: 167394560, name: 'UAB "Medrievė"' },
  { code: 167401072, name: 'UAB "Dasma" ir partneriai' },
  { code: 167531379, name: 'Uždaroji akcinė bendrovė "ŽIŽMAUKA"' },
  { code: 167535057, name: 'Uždaroji akcinė bendrovė "LAKAJA"' },
  {
    code: 167540613,
    name: 'Uždaroji akcinė bendrovė "ALANTOS AGROSERVISAS"'
  },
  { code: 167571135, name: 'Uždaroji akcinė bendrovė "MALATŪNAS"' },
  { code: 167580294, name: 'UAB "Tigmis"' },
  { code: 167582911, name: "A. Krupausko įmonė" },
  { code: 167583066, name: 'Uždaroji akcinė bendrovė "Langvija"' },
  { code: 167583447, name: "Antano Ivanausko įmonė" },
  {
    code: 167584549,
    name: 'Uždaroji akcinė bendrovė "Ateities statyba"'
  },
  { code: 167584887, name: 'Juliaus Gylio įmonė "Pakelės liepa"' },
  { code: 167602641, name: 'A. Bagdono įmonė "BALDESTA"' },
  { code: 167603939, name: "S. Žukausko įmonė" },
  { code: 167610175, name: 'Uždaroji akcinė bendrovė "Molėtų šiluma"' },
  { code: 167909640, name: 'Uždaroji akcinė bendrovė "Pakruojo šiluma"' },
  {
    code: 167930983,
    name: 'UŽDAROJI AKCINĖ BENDROVĖ "GERASIS AITVARAS"'
  },
  { code: 167997220, name: 'Uždaroji akcinė bendrovė "Medis"' },
  { code: 167999958, name: 'Individuali G. Areliūno įmonė "Voltas"' },
  { code: 168030339, name: "A. Adomaičio individuali įmonė" },
  { code: 168048294, name: 'UAB "Parketo gamyba"' },
  {
    code: 168051842,
    name: 'Uždaroji akcinė bendrovė "Pakruojo parketas"'
  },
  { code: 168290027, name: 'UAB "Linkuvos mėsa"' },
  { code: 168401627, name: 'Bručo įmonė "AIRA"' },
  { code: 168402914, name: 'Konoržiausko privati įmonė "REDA"' },
  { code: 168414998, name: 'Aukštuolio įmonė "LĖVUO"' },
  { code: 168419963, name: 'Garbausko įmonė "Agva"' },
  { code: 168425489, name: 'IĮ "MODELIS"' },
  {
    code: 168431232,
    name: 'Palijansko tikroji ūkinė bendrija "Medeina"'
  },
  { code: 168433621, name: 'Malinausko įmonė "ŠVIESTUVAS"' },
  { code: 168465398, name: 'V. Kadžio įmonė "Skaistgiriai"' },
  { code: 168468921, name: 'A. Plėščio įmonė "Agava"' },
  { code: 168471522, name: 'R. Turauskienės įmonė "UNIKALUS VAIZDAS"' },
  { code: 168473683, name: 'V. Jodoko įmonė "Vilius"' },
  { code: 168477361, name: 'S. Geneikio įmonė "Papojus"' },
  { code: 168483072, name: 'R. Endriukaičio IĮ "RIM"' },
  { code: 168489488, name: 'UAB "Violė"' },
  { code: 168499443, name: "D. Mažeikos gamybinė-komercinė įmonė" },
  { code: 168515680, name: "R. Pankevičiaus gamybinė įmonė" },
  { code: 168530974, name: 'Uždaroji akcinė bendrovė "RODAI"' },
  {
    code: 168535035,
    name: 'Uždaroji akcinė bendrovė "Staniūnų lentpjūvė"'
  },
  { code: 168543092, name: 'Uždaroji akcinė bendrovė "Kalneliai"' },
  { code: 168544760, name: 'UAB "Meksas"' },
  {
    code: 168554911,
    name: "R. Andriuškevičiaus plastmasinių gaminių įmonė"
  },
  {
    code: 168560437,
    name: 'Uždaroji akcinė bendrovė "Ramygalos malūnas"'
  },
  { code: 168564453, name: 'Uždaroji akcinė bendrovė "Bermeda"' },
  { code: 168567378, name: 'UŽDAROJI AKCINĖ BENDROVĖ "MIDENĖ"' },
  {
    code: 168586873,
    name: 'UŽDAROJI AKCINĖ BENDROVĖ "KREKENAVOS AGROFIRMA"'
  },
  { code: 168586916, name: "E. Baltranienės kavinė" },
  { code: 168601332, name: 'S. Momeniškio firma "Gurmanas"' },
  { code: 168620076, name: "ANTANO RAMANAUSKO skerdykla" },
  { code: 168620838, name: "V. Baliūno įmonė" },
  { code: 168623229, name: "Dainos Dambrauskienės įmonė" },
  { code: 168631133, name: 'Uždaroji akcinė bendrovė "ELITĖJA"' },
  { code: 168634624, name: "V. Žalgos įmonė" },
  { code: 168635726, name: 'Uždaroji akcinė bendrovė "DILUMAS"' },
  { code: 168641394, name: 'IĮ "Termopakas"' },
  { code: 168642496, name: "Arnoldo Petraičio įmonė" },
  { code: 168644013, name: 'MB "GRUNDALAS"' },
  { code: 168656859, name: 'Uždaroji akcinė bendrovė "DRIVEKTA"' },
  { code: 168660551, name: "Žemaičio įmonė" },
  { code: 168662755, name: 'Uždaroji akcinė bendrovė "RISTVA"' },
  { code: 168664044, name: "Ramūno Sakalausko įmonė" },
  { code: 168665527, name: 'Uždaroji akcinė bendrovė "Medritas"' },
  {
    code: 168667916,
    name: 'Uždaroji akcinė bendrovė "Piniavos berželis"'
  },
  { code: 168672678, name: 'Uždaroji akcinė bendrovė "KEMENA"' },
  { code: 168673246, name: 'Uždaroji akcinė bendrovė "KAJONAS"' },
  { code: 168677796, name: 'R. Šaparnio įmonė "RIJESTA"' },
  { code: 168678179, name: "V. Paškevičiaus baldų gamybos įmonė" },
  { code: 168686083, name: 'Uždaroji akcinė bendrovė "RODVITA"' },
  { code: 168688091, name: 'Uždaroji akcinė bendrovė "ALKSNIO DŪMAS"' },
  { code: 168690548, name: "V. Sakalausko įmonė" },
  { code: 168695328, name: "Jono Augustinavičiaus įmonė" },
  { code: 168696953, name: '"MOLIO NAMUKAS", UAB' },
  { code: 168935894, name: 'R. PREIDŽIO įmonė "ROPAKAS"' },
  { code: 168944943, name: 'UAB "RISTVA" IR Ko' },
  { code: 168956340, name: 'A. KLOKMANO FIRMA "MEDGAUSA"' },
  { code: 168957442, name: "Lino Striškos įmonė" },
  { code: 168961679, name: "Žibartonių mėsa, MB" },
  { code: 168963534, name: "Ričardo Chmeliausko įmonė" },
  { code: 168979481, name: 'UAB "Neimondas"' },
  { code: 168980074, name: 'UAB "Livamedis"' },
  { code: 169104080, name: 'Uždaroji akcinė bendrovė "Stigma"' },
  { code: 169140735, name: 'UAB "USTUKIŲ MALŪNAS"' },
  { code: 169153387, name: "S. Gailiūno įmonė" },
  {
    code: 169156497,
    name: 'Pasvalio rajono žemės ūkio bendrovė "Ąžuolas"'
  },
  { code: 169225566, name: 'Uždaroji akcinė bendrovė "Artema"' },
  { code: 169241766, name: "Arūno Lungio įmonė" },
  { code: 169245063, name: 'Uždaroji akcinė bendrovė "Mūšos malūnas"' },
  { code: 169249460, name: 'Uždaroji akcinė bendrovė "Paįstriečiai"' },
  { code: 169249656, name: "V. Indrišiūno įmonė" },
  { code: 169251874, name: "Uždaroji akcinė bendrovė Dirvonų lentpjūvė" },
  { code: 169281294, name: 'Uždaroji akcinė bendrovė "MŪSŲ TERITORIJA"' },
  { code: 169297284, name: "S. Kairio įmonė" },
  { code: 169704164, name: 'UAB "Rietavo veterinarinė sanitarija"' },
  { code: 169726812, name: 'Dubinsko firma "Melita"' },
  {
    code: 169730287,
    name: 'Vytauto Bertašiaus individuali gamybinė įmonė "Druja"'
  },
  { code: 169744988, name: "J. Gailiaus gamybinė-komercinė įmonė" },
  { code: 169751833, name: "Vytauto Alimo staliaus įmonė" },
  { code: 169771145, name: 'UAB "Kertmedis"' },
  {
    code: 169796294,
    name: 'A. Norvilio gamybos ir komercijos įmonė "MS"'
  },
  { code: 169841469, name: "R. Karaliaus įmonė" },
  { code: 169850094, name: 'Uždaroji akcinė bendrovė "Brėžtas"' },
  { code: 169864838, name: "A. Vaičekausko grūdų malimo įmonė" },
  { code: 169887598, name: 'Uždaroji akcinė bendrovė "Plungės Šaltis"' },
  { code: 169904631, name: 'Uždaroji akcinė bendrovė "Kopčius"' },
  { code: 169920831, name: "UAB Rietavo mediena" },
  { code: 169924477, name: 'UAB "Paalančio medis"' },
  { code: 169939899, name: "R. Virkučio gamybinė-komercinė įmonė" },
  { code: 169951549, name: "L. Barusieinės gamybinė-komercinė įmonė" },
  { code: 169955750, name: 'Uždaroji akcinė bendrovė "Koitis"' },
  { code: 169969166, name: 'Uždaroji akcinė bendrovė "Kirda"' },
  {
    code: 170000239,
    name: 'V. Buivydo gamybinė- komercinė įmonė "Šaka"'
  },
  { code: 170011068, name: "UAB Bio wood trading" },
  { code: 170011787, name: 'UAB "Josta"' },
  { code: 170021895, name: "V. Jautako paslaugų-komercinė įmonė" },
  { code: 170027962, name: 'Uždaroji akcinė bendrovė "Augriva"' },
  { code: 170039936, name: "E. Bartosevičiaus medienos gaminių įmonė" },
  { code: 170055036, name: 'Uždaroji akcinė bendrovė "Ravilda"' },
  { code: 170055940, name: 'Uždaroji akcinė bendrovė "Manifoldas"' },
  {
    code: 170535455,
    name: 'Uždaroji akcinė bendrovė "Plungės šilumos tinklai"'
  },
  { code: 170614447, name: 'MB "Skaidris"' },
  { code: 170647924, name: 'Uždaroji akcinė bendrovė "Davi"' },
  { code: 170665947, name: 'UAB "Liepaloto lentpjūvė"' },
  {
    code: 170667389,
    name: "Prienų rajono Šiauliškių statybos remonto žemės ūkio bendrovė"
  },
  { code: 170676438, name: 'Uždaroji akcinė bendrovė "Aviatika"' },
  { code: 170679352, name: 'Uždaroji akcinė bendrovė "Doleta"' },
  { code: 170680479, name: 'UAB "Osmedas"' },
  {
    code: 170687267,
    name: "Antano Gribino gamybos ir komercinės veiklos įmonė"
  },
  { code: 170691721, name: "Broniaus Bradausko individuali įmonė" },
  { code: 170707410, name: "UAB Gyvulių skerdykla" },
  { code: 170717714, name: 'UAB "Trupinys"' },
  { code: 170733914, name: "UAB Vido Rusecko lentpjūvė" },
  { code: 170755615, name: "Gintaro Keblio personalinė įmonė" },
  { code: 170755768, name: 'UAB "Skriaudžių medis"' },
  { code: 170756674, name: "V. Zorūbos įmonė" },
  { code: 170759250, name: 'Akcinė bendrovė "Prienų šilumos tinklai"' },
  { code: 170765538, name: 'UAB "Vikrigiva"' },
  { code: 170778418, name: "Birutės Revuckienės individuali įmonė" },
  { code: 170781161, name: 'UAB "Darginta"' },
  { code: 170784314, name: "VšĮ Meninė drožyba" },
  { code: 170794575, name: "Uždaroji akcinė bendrovė Strielčių lentpjūvė" },
  { code: 170795677, name: 'UAB "Trakų energija"' },
  { code: 170803661, name: 'TŪB "Radomedas"' },
  { code: 171031643, name: 'Uždaroji akcinė bendrovė "Sparkija"' },
  { code: 171042853, name: 'UŽDAROJI AKCINĖ BENDROVĖ "BILINGA"' },
  { code: 171047067, name: "E. Paulausko firma" },
  { code: 171115372, name: 'Uždaroji akcinė bendrovė "Arborator"' },
  { code: 171119584, name: 'UAB "Viravitus"' },
  { code: 171236139, name: 'Uždaroji akcinė bendrovė "Oktava"' },
  { code: 171259312, name: 'S. Krivicko įmonė "Fasma"' },
  { code: 171276233, name: 'Uždaroji akcinė bendrovė "ATJAUTA"' },
  {
    code: 171276614,
    name: "Vidmanto Krikščiūno plastmasinių gaminių įmonė"
  },
  { code: 171277488, name: 'Uždaroji akcinė bendrovė "HERA"' },
  {
    code: 171307369,
    name: 'Radviliškio rajono žemės ūkio bendrovė "Šiaulėnų statyba"'
  },
  { code: 171311061, name: "Povilo Janučio malūnas" },
  { code: 171378932, name: "Kęstučio Gailiūno įmonė" },
  {
    code: 171444859,
    name: 'Uždaroji akcinė bendrovė "Radviliškio šiluma"'
  },
  { code: 171651562, name: "Voldemaro Bielskio įmonė" },
  { code: 171663689, name: 'UAB "Baisogalos bioenergija"' },
  { code: 171665010, name: "Elenos Strikienės įmonė" },
  { code: 171670534, name: "R. Židickio įmonė" },
  { code: 171694243, name: 'Uždaroji akcinė bendrovė "Plungės baldai"' },
  {
    code: 171695726,
    name: 'Uždaroji akcinė bendrovė "Plungės bioenergija"'
  },
  {
    code: 171704584,
    name: 'Uždaroji akcinė bendrovė "Virginijus ir KO"'
  },
  { code: 171754459, name: 'Uždaroji akcinė bendrovė "Donaisa"' },
  { code: 171754982, name: 'Uždaroji akcinė bendrovė "Ramonora"' },
  { code: 171755027, name: 'Uždaroji akcinė bendrovė "POLIKORA"' },
  {
    code: 171757754,
    name: 'Uždaroji akcinė bendrovė "VIČIŪNAI IR PARTNERIAI"'
  },
  {
    code: 171764033,
    name: 'Uždaroji akcinė bendrovė "Plungės medienos centras"'
  },
  { code: 171769532, name: 'UAB "Adelmonsta"' },
  { code: 171773192, name: 'IĮ "Radmena"' },
  { code: 172201536, name: "Valdo Daukševičiaus individuali įmonė" },
  { code: 172232962, name: 'A. Voro stalių dirbtuvės "Arbor"' },
  { code: 172237895, name: 'Uždaroji akcinė bendrovė "Danmedis"' },
  { code: 172263299, name: 'Uždaroji akcinė bendrovė "Šanis"' },
  { code: 172292945, name: 'UAB "Rivaka"' },
  { code: 172335663, name: 'Č. Skuodžio individuali įmonė "Negirva"' },
  { code: 172343382, name: "A. Žilienės įmonė" },
  { code: 172370835, name: 'S. Voro įmonė "Vorsta"' },
  { code: 172385876, name: 'Uždaroji akcinė bendrovė "Loskana"' },
  { code: 172403826, name: 'UAB "Rodarta"' },
  { code: 172411926, name: 'UAB "TEKSRENA"' },
  {
    code: 172412113,
    name: 'Uždaroji akcinė bendrovė "Raseinių šilumos tinklai"'
  },
  { code: 172427154, name: 'J. Kaminsko įmonė "KALNATA"' },
  { code: 172771696, name: 'Uždaroji akcinė bendrovė "ESKALADA" ir KO' },
  { code: 172773213, name: 'Uždaroji akcinė bendrovė "Joluma"' },
  { code: 172781847, name: 'Uždaroji akcinė bendrovė "Butkimeda"' },
  { code: 172786399, name: "S. Stonio individuali įmonė" },
  { code: 173000664, name: 'Akcinė bendrovė "Rokiškio komunalininkas"' },
  { code: 173016316, name: 'Uždaroji akcinė bendrovė "Aila"' },
  {
    code: 173026577,
    name: "Ivano Puchiro automobilių detalių gamybos įmonė"
  },
  { code: 173046793, name: 'Uždaroji akcinė bendrovė "Ričvis"' },
  { code: 173112229, name: 'Uždaroji akcinė bendrovė "Kriaunų malūnas"' },
  { code: 173123396, name: 'UAB "Bajorų žuvis"' },
  { code: 173203294, name: 'UAB "GIEMEDA"' },
  { code: 173208455, name: "D. Gindvilio individuali įmonė" },
  { code: 173213445, name: "Ričardo Valiulio individuali įmonė" },
  { code: 173213979, name: 'UAB "ROKMEDIS"' },
  {
    code: 173220062,
    name: 'Uždaroji akcinė bendrovė "Rokiškio pragiedruliai"'
  },
  { code: 173228333, name: 'Uždaroji akcinė bendrovė "Duguva"' },
  { code: 173234382, name: 'Uždaroji akcinė bendrovė "Masmala"' },
  { code: 173236629, name: "Virgilijaus Tauperio individuali įmonė" },
  { code: 173253735, name: "Vlado Sesicko individuali įmonė" },
  { code: 173727496, name: 'UAB "Lašų duona"' },
  { code: 173733620, name: "Olego Kapytkovo individuali įmonė" },
  { code: 173738749, name: 'UAB "Rokiškio betonas"' },
  { code: 173802657, name: 'D. Kurpavičienės individuali įmonė "Dina"' },
  { code: 173835372, name: 'Uždaroji akcinė bendrovė "Skalsa"' },
  { code: 173858175, name: 'UAB "Notės energija"' },
  { code: 173893913, name: "Algirdo Beniušio medžio apdirbimo įmonė" },
  { code: 173919678, name: 'Uždaroji akcinė bendrovė "Žemaičių virvės"' },
  { code: 173919863, name: 'Uždaroji akcinė bendrovė "Baldarys"' },
  { code: 173924853, name: 'Uždaroji akcinė bendrovė "VERSUPIS"' },
  { code: 174164766, name: 'Uždaroji akcinė bendrovė "Skuodo medis"' },
  { code: 174204036, name: 'UAB "FLEMING BALDAI"' },
  { code: 174276135, name: 'UAB "Šakių gėlės"' },
  { code: 174294496, name: 'R. Bybarto įmonė "Baldai"' },
  {
    code: 174323994,
    name: 'Uždaroji akcinė bendrovė "Gelgaudiškio gelžbetonis"'
  },
  { code: 174333383, name: 'UAB "Mediresta"' },
  { code: 174357516, name: "E. Dragūnaičio gamybos ir komercijos įmonė" },
  { code: 174369828, name: 'UAB "Dvaro žuvys"' },
  { code: 174383258, name: "Remigijaus Gudiškio agroserviso įmonė" },
  { code: 174392079, name: 'Uždaroji akcinė bendrovė "Lauksva"' },
  { code: 174392798, name: 'Uždaroji akcinė bendrovė "Rimrega"' },
  { code: 174396095, name: 'Uždaroji akcinė bendrovė "Vitresa"' },
  { code: 174397763, name: "Vaclovo Jurgučio įmonė" },
  { code: 174405039, name: "Broniaus Zeleniaus įmonė" },
  { code: 174405562, name: 'UAB "Tiekimo broliai"' },
  {
    code: 174409393,
    name: 'Uždaroji akcinė bendrovė "Šakių šilumos tinklai"'
  },
  { code: 174419882, name: 'UAB "Saubalda"' },
  { code: 174434119, name: "Antano Varankovo įmonė" },
  { code: 174442938, name: 'Uždaroji akcinė bendrovė "ELODENA"' },
  { code: 174569871, name: "Raimundo Buivydo personalinė įmonė" },
  { code: 174574861, name: "R. Česnauskio medienos apdirbimo įmonė" },
  { code: 174580572, name: "A. Sendrausko individuali įmonė" },
  { code: 174585352, name: "P. Bučio gamybinė-komercinė įmonė" },
  { code: 174587556, name: "H. Graževičiaus individuali įmonė" },
  { code: 174904615, name: "Ivano Marinovskio individuali įmonė" },
  { code: 174936043, name: 'UAB "Juotada"' },
  {
    code: 174943422,
    name: 'Uždaroji akcinė bendrovė "Jašiūnų vaistinė"'
  },
  { code: 174962732, name: 'Uždaroji akcinė bendrovė "Namesta"' },
  { code: 174969716, name: "K. Tubelevič komercinė firma" },
  { code: 174976148, name: "K. Leonovič komercinė firma" },
  {
    code: 174976486,
    name: 'Uždaroji akcinė bendrovė "Šalčininkų šilumos tinklai"'
  },
  {
    code: 174977969,
    name: 'Uždaroji akcinė bendrovė "Jašiūnų keramika"'
  },
  { code: 174987358, name: "V. Bojarun įmonė" },
  { code: 175002824, name: 'Uždaroji akcinė bendrovė "Plastita"' },
  { code: 175006840, name: 'Uždaroji akcinė bendrovė "Keturiai"' },
  { code: 175008282, name: "G. Bovgiro individuali įmonė" },
  { code: 175023423, name: "Kristinos Svorobovič įmonė" },
  { code: 175028160, name: 'Uždaroji akcinė bendrovė "Stantijaras"' },
  { code: 175028922, name: "Z. Volochovič individuali įmonė" },
  { code: 175036118, name: "M. Jankovskajos prekybos įmonė" },
  {
    code: 175613922,
    name: 'Milašauskių tikroji ūkinė bendrija "Žaibas"'
  },
  { code: 175624566, name: "Žemecko Arvydo įmonė" },
  { code: 175633572, name: 'UAB "ADI keramika"' },
  { code: 175690598, name: "Violetos Kazlauskienės įmonė" },
  { code: 175711843, name: 'UŽDAROJI AKCINĖ BENDROVĖ "BAGETA"' },
  { code: 175721428, name: "Elenos Slavinskienės įmonė" },
  { code: 175721570, name: "Repšių žemės ūkio bendrovė" },
  { code: 175723436, name: "Šlepkų žemės ūkio bendrovė" },
  { code: 175759838, name: "Rimanto Viktoravičiaus įmonė" },
  { code: 175789977, name: 'Uždaroji akcinė bendrovė "Valvena"' },
  { code: 175799551, name: "Šarūno Pevcevičiaus įmonė" },
  { code: 175813638, name: "Kastyčio Pečiulio įmonė" },
  { code: 175821542, name: "Erlando Parimskio įmonė" },
  { code: 175835481, name: 'UAB "Vilraima"' },
  { code: 175841388, name: "Egidijaus Zagorskio įmonė" },
  { code: 175846549, name: 'UAB "MEDYNAS"' },
  { code: 175870468, name: 'Uždaroji akcinė bendrovė "JOVAINĖ"' },
  { code: 175872095, name: 'Uždaroji akcinė bendrovė "GUMINUKAS"' },
  { code: 175885160, name: 'UŽDAROJI AKCINĖ BENDROVĖ "GEDVAIRA"' },
  { code: 175894743, name: 'UŽDAROJI AKCINĖ BENDROVĖ "EKOLOGINĖ SFERA"' },
  { code: 176520937, name: 'Uždaroji akcinė bendrovė "Uosis"' },
  { code: 176522030, name: 'Uždaroji akcinė bendrovė "Šilalės statyba"' },
  { code: 176526438, name: 'Uždaroji akcinė bendrovė "Zobėlija"' },
  { code: 176536731, name: "Stanislavos Eugenijos Dajorienės įmonė" },
  { code: 176562288, name: "Stepono Mikniaus agroserviso įmonė" },
  {
    code: 176592121,
    name: 'Šilalės rajono žemės ūkio bendrovė "Kontautėliai"'
  },
  { code: 176594663, name: "Artūro Pociaus įmonė" },
  { code: 176597816, name: 'UAB "Avirginida"' },
  { code: 176603379, name: 'UAB "GRIMEDA"' },
  { code: 176604851, name: 'IĮ "KEMPIDA"' },
  { code: 176606489, name: 'UAB "DANRIMA"' },
  { code: 176622689, name: "Juozo Grinkevičiaus įmonė" },
  { code: 176636518, name: 'UAB "Energostata"' },
  { code: 176639966, name: "Edmundo Levickio įmonė" },
  { code: 177003088, name: 'UAB "Žemukas"' },
  {
    code: 177009721,
    name: 'Uždaroji akcinė bendrovė "Šilutės gelžbetonis"'
  },
  { code: 177034219, name: 'Petro Stungurio individuali įmonė "Uola"' },
  { code: 177040791, name: "A. Šilinsko medžio gaminių įmonė" },
  { code: 177058451, name: 'Petro Dargio įmonė "Guoba"' },
  { code: 177066932, name: 'UAB "Klaipėdos mėsinė"' },
  { code: 177092521, name: "R. Kaniausko įmonė" },
  { code: 177104870, name: 'Uždaroji akcinė bendrovė "Skatulė"' },
  {
    code: 177109084,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "RUSNĖ FISCH"'
  },
  { code: 177158238, name: "R. Šarlioko įmonė" },
  { code: 177161177, name: 'UAB "SMILTALĖ"' },
  { code: 177209437, name: "UAB Klasmann-Deilmann Šilutė" },
  { code: 177211840, name: 'Uždaroji akcinė bendrovė "Stunguva"' },
  {
    code: 177217875,
    name: 'Uždaroji akcinė bendrovė "Šilutės šilumos tinklai"'
  },
  { code: 177243126, name: 'UAB "Virekta"' },
  { code: 177250462, name: "R. Straukos įmonė" },
  { code: 177250658, name: "A. Stonio įmonė" },
  { code: 177281027, name: "V. Maurico įmonė" },
  { code: 177301095, name: 'G. Engelkio firma "GEFA"' },
  { code: 177301138, name: "R. Paulauskaitės UAB" },
  { code: 177303331, name: "P. Nedzelskio įmonė" },
  {
    code: 177306975,
    name: 'B. Domeikio, B. Skurdelio, S. Štrapėlos tikroji ūkinė bendrija "Šyšos klevas"'
  },
  { code: 177307358, name: "V. Zajankausko įmonė" },
  { code: 177315458, name: 'Uždaroji akcinė bendrovė "Vitransa"' },
  { code: 177318415, name: "IĮ V. Bliūdžiaus dailidžių dirbtuvės" },
  { code: 177325947, name: "A. Jonušo įmonė" },
  { code: 177337725, name: "R. Budriko įmonė" },
  { code: 177344157, name: "R. Keršio įmonė" },
  { code: 177351874, name: "G. Stankevičiaus įmonė" },
  { code: 177356316, name: "A. Šlakaičio įmonė" },
  { code: 177392928, name: 'Uždaroji akcinė bendrovė "Šilutės betonas"' },
  { code: 177400727, name: "Budriko UAB" },
  { code: 177413379, name: 'UAB "Meksimela"' },
  { code: 177415191, name: "Eglės Pundžiuvienės įmonė" },
  { code: 177417438, name: 'Uždaroji akcinė bendrovė "JANULFAS"' },
  { code: 177419827, name: "Sigito Lengvino įmonė" },
  { code: 177420562, name: 'UAB "Individualūs baldų sprendimai"' },
  { code: 177425876, name: 'UAB "Vitsigas"' },
  { code: 177974184, name: "Mariaus Kerpės įmonė" },
  { code: 177983614, name: 'IĮ "ADALIS"' },
  {
    code: 178200195,
    name: 'VARTOJIMO REIKMENŲ F. STIMBURIO PERSONALINĖ ĮMONĖ "FELIX"'
  },
  { code: 178228910, name: 'Uždaroji akcinė bendrovė "Kazliškės"' },
  {
    code: 178234774,
    name: 'Uždaroji akcinė bendrovė "Spadviliškio lentpjūvė"'
  },
  { code: 178256094, name: 'Uždaroji akcinė bendrovė "Alekniškis"' },
  { code: 178263516, name: "VšĮ Existing look art foundation" },
  { code: 178273396, name: 'UAB "PRANETA"' },
  { code: 178281877, name: "Vaclovo Gudeikos personalinė įmonė" },
  { code: 178287410, name: "IRMOS STIMBURYTĖS PERSONALINĖ ĮMONĖ" },
  { code: 178296231, name: 'T. Saveikio firma "ŠIRMEDA"' },
  { code: 178297486, name: 'Uždaroji akcinė bendrovė "SIGTIGA"' },
  { code: 178305666, name: "Kęstučio Norušio personalinė įmonė" },
  { code: 178319114, name: 'UŽDAROJI AKCINĖ BENDROVĖ "SPIRALĖ" IR KO' },
  { code: 178323493, name: 'UAB "Širvintų polistirolas"' },
  { code: 178324780, name: 'UAB "KRN"' },
  { code: 178324976, name: "UAB Humuso technologijų centras" },
  { code: 178602952, name: 'UAB "PABRADĖS KOMUNALINIS ŪKIS"' },
  { code: 178609893, name: 'UAB "Švenčionių Vėtrungė"' },
  { code: 178611392, name: 'UAB "Automobilistas LT"' },
  { code: 178612156, name: "Ščikno šiltnamis" },
  { code: 178628484, name: "V. Urbano staliaus dirbinių dirbtuvė" },
  { code: 178628865, name: 'UAB "Karina"' },
  { code: 178647837, name: 'Uždaroji akcinė bendrovė "Senas malūnas"' },
  { code: 178649845, name: 'Uždaroji akcinė bendrovė "Belis"' },
  { code: 178660012, name: "Medarto Jacevičiaus individuali įmonė" },
  { code: 178667149, name: 'L. Puzyriovos įmonė "Pas Lilę"' },
  { code: 178681298, name: "Aleksej Zinkevič individuali įmonė" },
  { code: 178690532, name: "V. Jasudo individuali įmonė" },
  { code: 178693457, name: "A. Tepliakovo individuali įmonė" },
  { code: 178696371, name: "J. Dmitrijev individuali įmonė" },
  { code: 178727920, name: 'A. Kliuko individuali įmonė "Alsa"' },
  { code: 178735835, name: "M. Chaleckio individuali įmonė" },
  { code: 178738411, name: "Č. Patiejūno individuali įmonė" },
  { code: 178740444, name: 'Uždaroji akcinė bendrovė "Egsis"' },
  { code: 178741927, name: 'Uždaroji akcinė bendrovė "Pamąstyk"' },
  { code: 178860251, name: "UAB Gren Švenčionys" },
  { code: 178861549, name: "H. Marcinkevičiaus individuali įmonė" },
  {
    code: 178993134,
    name: 'Uždaroji akcinė bendrovė "Pabradės kartonas"'
  },
  { code: 179230355, name: 'Juozo Damulio įmonė "Buratinas"' },
  {
    code: 179242852,
    name: 'Vaclovo Šiaulinsko staliaus gaminių įmonė "Vėtrungė"'
  },
  { code: 179277433, name: 'UAB "Medžio manufaktūra"' },
  { code: 179277967, name: 'UAB "Vlado medis"' },
  { code: 179281474, name: "Edmundo Krompalco gamybos įmonė" },
  { code: 179287737, name: "Andrejaus Bamšo gamybos įmonė" },
  { code: 179294920, name: 'UAB "JURAJA"' },
  { code: 179321844, name: 'IĮ "ALME"' },
  { code: 179336732, name: 'Uždaroji akcinė bendrovė "Benera"' },
  { code: 179337072, name: "Antano Mockaus gamybos įmonė" },
  { code: 179343883, name: "Valerijos Nausėdienės prekybos įmonė" },
  { code: 179349384, name: 'J. Preikšaičio firma "Taujanta"' },
  { code: 179362136, name: 'Uždaroji akcinė bendrovė "NUOKALNĖ"' },
  { code: 179370193, name: 'Uždaroji akcinė bendrovė "Arša"' },
  { code: 179374971, name: "V. Bendiko individuali įmonė" },
  { code: 179376260, name: 'UAB "Durima"' },
  { code: 179404852, name: 'R. Bajorino IĮ "Trys pušys"' },
  { code: 179423596, name: 'UAB "ALGINTOS BALDAI"' },
  { code: 179426553, name: "A. Mėlinavičiaus gamybos įmonė" },
  { code: 179451040, name: "Jono Kuperskio įmonė" },
  { code: 179454531, name: 'UAB "TVOROS"' },
  { code: 179463167, name: 'A. Gaižausko įmonė "Miltauja"' },
  { code: 179466996, name: 'Uždaroji akcinė bendrovė "Alantas"' },
  { code: 179472173, name: 'UAB "LAUKBALDIS"' },
  { code: 179478589, name: 'Uždaroji akcinė bendrovė "Taurameda"' },
  { code: 179478621, name: "UAB Tauragės šilumos tinklai" },
  { code: 179479723, name: 'UAB "Kaimiškas dūmas"' },
  { code: 179490239, name: "Rolando Vaidžiulio individuali įmonė" },
  { code: 179758420, name: 'Uždaroji akcinė bendrovė "Jusilta"' },
  { code: 179759860, name: 'Uždaroji akcinė bendrovė "Baldsta"' },
  { code: 179872018, name: "Stasio Sabockio įmonė" },
  { code: 179875128, name: 'UAB "VYTO BALDAI"' },
  { code: 179877093, name: 'UAB "GAURĖS MEDIS"' },
  { code: 179884515, name: 'Uždaroji akcinė bendrovė "Nagoja"' },
  { code: 179885236, name: 'Uždaroji akcinė bendrovė "Herantas"' },
  { code: 179885955, name: "Uždaroji akcinė bendrovė Jatkančių mėsinė" },
  { code: 179905685, name: 'UAB "Netmark"' },
  { code: 179909025, name: 'UAB "Taukestus"' },
  { code: 180106372, name: 'Uždaroji akcinė bendrovė "Pamatas"' },
  {
    code: 180182174,
    name: "J. Žukausko prekybos ir tarpininkavimo biuras"
  },
  { code: 180184410, name: "I. Norvaišienės veterinarijos vaistinė" },
  { code: 180189158, name: "R. Arlauskio statybos ir remonto darbų baras" },
  { code: 180205242, name: "S. Baublio veterinarijos ambulatorija" },
  {
    code: 180245532,
    name: 'Uždaroji akcinė bendrovė "Telšių gelžbetonis"'
  },
  { code: 180275486, name: "S. Cibulskio firma" },
  { code: 180277156, name: "A. Stanio firma" },
  { code: 180299195, name: 'Uždaroji akcinė bendrovė "Jampolė"' },
  { code: 180303697, name: 'UAB "Robmona"' },
  { code: 180330235, name: 'Uždaroji akcinė bendrovė "Bimeda"' },
  {
    code: 180373788,
    name: 'Uždaroji akcinė bendrovė "Telšių šilumos tinklai"'
  },
  { code: 180705888, name: "D. Jokubauskienės įmonė" },
  { code: 180720071, name: "V. Blažinausko firma" },
  { code: 180877382, name: "R. Valausko firma" },
  {
    code: 180878527,
    name: 'Uždaroji akcinė bendrovė "Vigantiškių statyba"'
  },
  { code: 180884042, name: 'UAB "TZV"' },
  { code: 180884195, name: 'UAB "BIO zona"' },
  {
    code: 181121797,
    name: "Uždaroji akcinė bendrovė Trakų šilumos tinklai"
  },
  {
    code: 181124569,
    name: "Antano Kijansko individuali staliaus gaminių įmonė"
  },
  { code: 181182348, name: 'Uždaroji akcinė bendrovė "Elena"' },
  { code: 181194126, name: 'IĮ "Stadrida"' },
  { code: 181206094, name: 'Uždaroji akcinė bendrovė "SELVA"' },
  { code: 181210897, name: 'Uždaroji akcinė bendrovė "Maviko"' },
  { code: 181216245, name: 'Uždaroji akcinė bendrovė "Pūgžlys"' },
  { code: 181221954, name: 'Uždaroji akcinė bendrovė "Trakų spaustuvė"' },
  { code: 181226353, name: 'Žemės ūkio bendrovė "Pjūklas"' },
  { code: 181292766, name: "V. Vinskevičiaus personalinė įmonė" },
  { code: 181305911, name: "L. Danilovo įmonė" },
  { code: 181306066, name: "Remigijaus Gančieriaus individuali įmonė" },
  {
    code: 181319131,
    name: 'Uždaroji akcinė bendrovė "Rūdiškių lentpjūvė"'
  },
  { code: 181320781, name: 'Uždaroji akcinė bendrovė "Hidromodulis"' },
  { code: 181339881, name: "Gintauto Dzingos individuali įmonė" },
  { code: 181347077, name: "G. Petkūno individuali įmonė" },
  { code: 181351346, name: 'Uždaroji akcinė bendrovė "DAILINTA"' },
  {
    code: 181355558,
    name: 'Uždaroji akcinė bendrovė "Apvalūs medžio gaminiai"'
  },
  { code: 181359955, name: 'Uždaroji akcinė bendrovė "RUDMĖ"' },
  { code: 181361835, name: "J. Matkevičiaus individuali įmonė" },
  { code: 181372479, name: "R. Lecko individuali įmonė" },
  { code: 181373951, name: "V. Burdos individuali įmonė" },
  { code: 181376495, name: 'UAB "Retrorega"' },
  { code: 181377444, name: "Z. S. Beliak individuali įmonė" },
  { code: 181382053, name: 'Uždaroji akcinė bendrovė "SEVIKA"' },
  { code: 181396035, name: 'Uždaroji akcinė bendrovė "EDNITA"' },
  {
    code: 181531173,
    name: 'Uždaroji akcinė bendrovė "Lentvario mediena"'
  },
  { code: 181611986, name: 'L. Trafimovos firma "AKMENS SIMFONIJA"' },
  {
    code: 181613656,
    name: 'Uždaroji akcinė bendrovė "ELEKTRĖNŲ KOMUNALINIS ŪKIS"'
  },
  { code: 181621756, name: 'Uždaroji akcinė bendrovė "APDAILOS AKMUO"' },
  { code: 181682264, name: 'Uždaroji akcinė bendrovė "Medarvitas"' },
  { code: 181686280, name: "D. Bogumilo IĮ" },
  { code: 181705485, name: 'Uždaroji akcinė bendrovė "VAATC"' },
  { code: 182700879, name: 'Uždaroji akcinė bendrovė "S.K.S."' },
  { code: 182701251, name: "Ričardo Gervinsko įmonė" },
  { code: 182714850, name: 'Uždaroji akcinė bendrovė "Ukmergės šiluma"' },
  { code: 182728266, name: 'UAB "LATERUS"' },
  { code: 182755142, name: 'Uždaroji akcinė bendrovė "Veprių krosnis"' },
  { code: 182760513, name: "SAAKO JERICIANO komercinė įmonė" },
  {
    code: 182760666,
    name: 'Egidijaus Šniuikos plataus vartojimo prekių gamybos įmonė "KON-A"'
  },
  { code: 182778664, name: "MB Aiva LT" },
  { code: 182814975, name: 'Akcinė bendrovė "Ukmergės gelžbetonis"' },
  {
    code: 182827474,
    name: 'Uždaroji akcinė bendrovė "UŽMOJAI SU GARANTIJOMIS"'
  },
  { code: 182837244, name: 'UAB "Stradivarijai"' },
  { code: 182842768, name: 'M. Vilkevičiaus įmonė "VILKEMA"' },
  { code: 182858758, name: 'Vyto Valaikos personalinė įmonė "Vismina"' },
  { code: 182889312, name: "S. Miliuko individuali įmonė" },
  { code: 182899769, name: "Vidos Markauskienės individuali įmonė" },
  { code: 182900965, name: 'Uždaroji akcinė bendrovė "ROVADA"' },
  { code: 182909423, name: 'UAB "VILPORĖ"' },
  { code: 182914566, name: 'UAB "HYTTER"' },
  {
    code: 182919346,
    name: 'Uždaroji akcinė bendrovė "Mažoji energetika"'
  },
  { code: 182922328, name: "V. Žagelienės individuali įmonė" },
  { code: 182923191, name: "V. Ibensko individuali įmonė" },
  { code: 182923768, name: "K. Dragūno individuali įmonė" },
  { code: 182941823, name: 'A. Pakėno firma "VILGIDA"' },
  { code: 183106274, name: 'Uždaroji akcinė bendrovė "NOMARTAS"' },
  { code: 183110543, name: 'R. Vilučio įmonė "EGVILSA"' },
  { code: 183113087, name: 'Uždaroji akcinė bendrovė "SMAGŪS DARBAI"' },
  { code: 183115519, name: 'Uždaroji akcinė bendrovė "JUOSTMEDIS"' },
  { code: 183120847, name: 'Mažoji bendrija "Kaimiška mėsa"' },
  {
    code: 183127592,
    name: 'Uždaroji akcinė bendrovė "UNIVERSALŪS MEDŽIO PRODUKTAI"'
  },
  { code: 183136456, name: "R. Rudoko individuali įmonė" },
  { code: 183204042, name: 'UAB "Miesto energija"' },
  { code: 183217075, name: 'Uždaroji akcinė bendrovė "DOMUS VESTRA"' },
  {
    code: 183219083,
    name: 'Uždaroji akcinė bendrovė "UKMERGĖS VERSMĖS" LANGŲ CENTRAS'
  },
  { code: 183219998, name: 'Uždaroji akcinė bendrovė "EGILAGA"' },
  { code: 183227379, name: 'Uždaroji akcinė bendrovė "Rolimega"' },
  { code: 183244866, name: 'Uždaroji akcinė bendrovė "Forutė"' },
  { code: 183245968, name: 'Uždaroji akcinė bendrovė "LTC langai"' },
  { code: 183247638, name: "UAB MMC Eco Homes" },
  { code: 183654961, name: "G. Giedrio individuali įmonė" },
  { code: 183698210, name: 'Uždaroji akcinė bendrovė "Utenos šilas"' },
  { code: 183709468, name: 'Akcinė bendrovė "UTENOS TRIKOTAŽAS"' },
  { code: 183725668, name: "S. Laskausko individuali įmonė" },
  { code: 183728582, name: 'Uždaroji akcinė bendrovė "Umaras"' },
  { code: 183735776, name: 'Uždaroji akcinė bendrovė "Inera"' },
  { code: 183750721, name: 'V. Kalyčio individuali įmonė "Rutera"' },
  { code: 183764856, name: 'A. Bumblio firma "GINARVĖ"' },
  { code: 183765577, name: 'Uždaroji akcinė bendrovė "Bikusta"' },
  { code: 183782683, name: "A. Kavolio individuali įmonė" },
  { code: 183794319, name: 'Uždaroji akcinė bendrovė "Remstatva"' },
  { code: 183799394, name: 'UAB "Milivatas"' },
  {
    code: 183800829,
    name: 'Uždaroji akcinė bendrovė "Utenos antrinis popierius"'
  },
  { code: 183808480, name: "Rimanto Bereikos individuali įmonė" },
  { code: 183819733, name: 'UAB "VIDISTA"' },
  {
    code: 183843314,
    name: 'Uždaroji akcinė bendrovė "Utenos šilumos tinklai"'
  },
  { code: 183843652, name: "L. Gedvilienės individuali įmonė" },
  {
    code: 183845322,
    name: 'Uždaroji akcinė bendrovė "Antalgės paukštynas"'
  },
  { code: 183863726, name: 'Uždaroji akcinė bendrovė "Anstama"' },
  { code: 184075312, name: "J. Žutauto individuali įmonė" },
  { code: 184084329, name: 'A. Blažio įmonė "Aukso skeptras"' },
  { code: 184093335, name: "D. Kondrašovo individuali įmonė" },
  { code: 184198959, name: "Ž. Stuko individuali įmonė" },
  { code: 184210799, name: 'Uždaroji akcinė bendrovė "Antalgėlė"' },
  { code: 184220373, name: 'Uždaroji akcinė bendrovė "Norusta"' },
  { code: 184241353, name: "G. Urbano individuali įmonė" },
  { code: 184266730, name: 'Uždaroji akcinė bendrovė "Ledausta"' },
  { code: 184290126, name: 'UAB "Strengas"' },
  {
    code: 184532743,
    name: 'R. Sinkevičiaus personalinė įmonė "Akvilonas"'
  },
  {
    code: 184560917,
    name: 'Varėnos rajono žemės ūkio bendrovė "Beržas"'
  },
  { code: 184564029, name: 'Uždaroji akcinė bendrovė "Beržupis"' },
  { code: 184570797, name: 'Uždaroji akcinė bendrovė "VARĖNOS STATYBA"' },
  { code: 184582041, name: "Lapuko personalinė įmonė" },
  { code: 184603635, name: 'UAB "Norkūno mediena"' },
  { code: 184617236, name: 'Uždaroji akcinė bendrovė "MEDASTA"' },
  { code: 184625489, name: "Jono Kibildžio personalinė įmonė" },
  { code: 184630098, name: 'Uždaroji akcinė bendrovė "Edvima"' },
  { code: 184759659, name: "Otilijos Lazickienės individuali įmonė" },
  { code: 184827583, name: 'Uždaroji akcinė bendrovė "Varėnos šiluma"' },
  { code: 184829787, name: 'Uždaroji akcinė bendrovė "ŠERDIS"' },
  { code: 184842539, name: 'D. Turausko firma "ANDARNERA"' },
  { code: 184848563, name: 'Uždaroji akcinė bendrovė "Miško švara"' },
  {
    code: 185114526,
    name: "Individuali Zigmo Stemplio medžio gaminių įmonė"
  },
  { code: 185115813, name: 'UAB "Pro colore"' },
  { code: 185125921, name: "Individuali Antano Margelio statybos įmonė" },
  { code: 185184617, name: "Ričardo Kairio įmonė" },
  { code: 185186963, name: 'Eimučio Inkratos įmonė "Galvė"' },
  { code: 185190132, name: 'Valdo Lenčiausko individuali įmonė "Aista"' },
  { code: 185220351, name: "Valdo Saukaičio parduotuvė" },
  { code: 185225850, name: 'Uždaroji akcinė bendrovė "Sauja jaukumo"' },
  { code: 185226048, name: 'Uždaroji akcinė bendrovė "Medista"' },
  { code: 185233427, name: "Jono Bendoraičio įmonė" },
  { code: 185272572, name: "Algimanto Bondzinsko įmonė" },
  { code: 185274242, name: 'Uždaroji akcinė bendrovė "Kelio ženklai"' },
  { code: 185274438, name: "Algimanto Lekecko įmonė" },
  { code: 185301885, name: 'R. Radzevičiaus įmonė "Uosija"' },
  { code: 185312223, name: "Gitanos Vyšniauskienės įmonė" },
  { code: 185324492, name: 'Uždaroji akcinė bendrovė "LUGVITA"' },
  { code: 185487176, name: 'Uždaroji akcinė bendrovė "Simedva"' },
  { code: 185488997, name: 'J. Ūsienės įmonė "Alspreta"' },
  {
    code: 185492166,
    name: 'Uždaroji akcinė bendrovė "Vilkaviškio šilumos tinklai"'
  },
  { code: 185595629, name: 'UAB "Vokės hidroelektrinė"' },
  { code: 185596916, name: 'Uždaroji akcinė bendrovė "Ruksma"' },
  { code: 185605393, name: "Tomo Gago įmonė" },
  { code: 185610426, name: "UAB Pilviškių hidroelektrinė" },
  { code: 185667612, name: 'UAB "Gemavila"' },
  { code: 185667765, name: 'UAB "Odas"' },
  { code: 185670366, name: 'Viešoji įstaiga "Suvalkijos kraštotvarka"' },
  { code: 186019465, name: 'Uždaroji akcinė bendrovė "VIGRITA"' },
  { code: 186021498, name: "R. Čingos meno dirbinių įmonė" },
  {
    code: 186021911,
    name: 'Babano staliaus darbų individuali įmonė "Beriozka"'
  },
  {
    code: 186024793,
    name: "S. Batėno gelžbetoninių gaminių individuali įmonė"
  },
  { code: 186044714, name: 'K. Debeikio individuali įmonė "PLASTPAK"' },
  { code: 186048926, name: 'G. Janavičienės personalinė firma "Genė"' },
  {
    code: 186063262,
    name: 'Uždaroji akcinė bendrovė "Nemėžio komunalininkas"'
  },
  {
    code: 186073566,
    name: 'Vilniaus rajono žemės ūkio bendrovė "Melekonys"'
  },
  { code: 186074515, name: "S. Buinickio individuali įmonė" },
  { code: 186080030, name: 'Uždaroji akcinė bendrovė "ALAZANĖ"' },
  { code: 186081285, name: 'Uždaroji akcinė bendrovė "Pailgė"' },
  {
    code: 186085910,
    name: 'Vilniaus rajono žemės ūkio bendrovė "Rakonys"'
  },
  { code: 186098377, name: 'V. Jurgučio personalinė įmonė "Juvolda"' },
  { code: 186109471, name: 'Uždaroji akcinė bendrovė "ALGESA"' },
  { code: 186112072, name: 'UŽDAROJI AKCINĖ BENDROVĖ "LIJAŠAS"' },
  { code: 186116850, name: 'Uždaroji akcinė bendrovė "Kairėnai"' },
  { code: 186119622, name: 'Uždaroji akcinė bendrovė "KARMAZINAI"' },
  { code: 186124427, name: 'UŽDAROJI AKCINĖ BENDROVĖ "VOMEDA"' },
  { code: 186128639, name: 'Uždaroji akcinė bendrovė "JUMESA"' },
  { code: 186131425, name: "Antano Kvaracėjaus individuali įmonė" },
  { code: 186134535, name: 'Uždaroji akcinė bendrovė "GERMETA"' },
  { code: 186140399, name: "I. Voleikienės medžio apdirbimo įmonė" },
  { code: 186143922, name: 'Uždaroji akcinė bendrovė "Etalga"' },
  {
    code: 186145211,
    name: 'Z. Benkevič individuali įmonė "PRIE TRAKTO"'
  },
  { code: 186154185, name: 'Uždaroji akcinė bendrovė "VILPLAS"' },
  { code: 186172817, name: 'A. Deveikos firma "AUDĖVA"' },
  { code: 186185469, name: "UAB Sadovskaja ir ko" },
  { code: 186194660, name: 'Uždaroji akcinė bendrovė "DAMEKA"' },
  { code: 186195424, name: 'UAB "AKRILEKSAS"' },
  { code: 186208341, name: "J. Govkelevič personalinė įmonė" },
  { code: 186230067, name: 'IĮ "JURPLASTAS"' },
  { code: 186231692, name: 'UAB "D. VAREC"' },
  { code: 186232456, name: 'UAB "Iloro"' },
  { code: 186235751, name: "Vidmanto Žilinsko individuali įmonė" },
  { code: 186255825, name: 'Uždaroji akcinė bendrovė "KIEMO APDAILA"' },
  {
    code: 186442084,
    name: 'Uždaroji akcinė bendrovė "Nemenčinės komunalininkas"'
  },
  {
    code: 186442312,
    name: 'Vilniaus rajono žemės ūkio bendrovė "Papilėnai"'
  },
  { code: 186445956, name: 'UAB "Anmargire"' },
  { code: 186449972, name: "Igno Tamašausko individuali įmonė" },
  { code: 186451090, name: 'Uždaroji akcinė bendrovė "GAROTIEKIS"' },
  { code: 186452769, name: "S. Zapolskio individuali įmonė" },
  { code: 186480028, name: 'Uždaroji akcinė bendrovė "SAUGRETA"' },
  { code: 186482940, name: "Stasio Janavičiaus personalinė įmonė" },
  { code: 186483661, name: 'UAB "Medžio srautas"' },
  { code: 186720822, name: "V. JAKŠTO FIRMA UOSININKŲ LENTPJŪVĖ" },
  { code: 186746888, name: 'UAB "Algimanto baldai"' },
  { code: 186749279, name: 'Uždaroji akcinė bendrovė "PVCH"' },
  { code: 186758513, name: 'UAB "Mirivona"' },
  {
    code: 187831416,
    name: 'Zarasų rajono žemės ūkio bendrovė "Ateitis"'
  },
  { code: 187858388, name: 'Uždaroji akcinė bendrovė "Akadas"' },
  {
    code: 187860649,
    name: 'M. Jurevičiaus individuali įmonė "Juminela"'
  },
  { code: 187870376, name: "E. Maskoliūno individuali įmonė" },
  {
    code: 187882492,
    name: 'P. Samochvalovo individuali įmonė "Žuvėdra"'
  },
  { code: 187882688, name: "L. Šiginos individuali įmonė" },
  { code: 187886170, name: 'Uždaroji akcinė bendrovė "VILVĖ"' },
  { code: 187903367, name: 'UAB "Edarmeda"' },
  { code: 187909587, name: "P. Gaidamavičiaus firma" },
  { code: 187928025, name: "S. Savicko įmonė" },
  { code: 210036160, name: 'Uždaroji akcinė bendrovė "LISIPLAST"' },
  {
    code: 210184860,
    name: 'M. Slomiano individuali įmonė "MAREK SLOMIAN"'
  },
  {
    code: 210384380,
    name: 'Lietuvos ir Lenkijos uždaroji akcinė bendrovė "AWA and Co"'
  },
  {
    code: 210430080,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "QUALI TECH IR ANTANINA"'
  },
  { code: 210502640, name: 'UAB "SCT Lubricants"' },
  { code: 210655020, name: 'UAB "SMILTAINIS IR KO"' },
  {
    code: 210713370,
    name: 'Lietuvos ir Norvegijos uždaroji akcinė bendrovė "NOKVĖJA"'
  },
  { code: 210734350, name: 'Uždaroji akcinė bendrovė "WAVIN BALTIC"' },
  { code: 210742450, name: 'UAB "Volimeda"' },
  { code: 210753660, name: 'UAB "PLANKMARA"' },
  {
    code: 210764870,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "GEALAN BALTIC"'
  },
  {
    code: 210793950,
    name: 'Lietuvos ir Vokietijos uždaroji akcinė bendrovė "Heinzmann"'
  },
  {
    code: 210863740,
    name: 'Uždaroji akcinė bendrovė "PAKRUOJO KERAMIKA"'
  },
  { code: 211503720, name: 'Uždaroji akcinė bendrovė "LITPOLSTAR"' },
  { code: 211514930, name: 'UAB "BHJ BALTIC"' },
  { code: 211551010, name: 'UAB "Cebeco grupė"' },
  { code: 211574870, name: 'Uždaroji akcinė bendrovė "GIJONA"' },
  { code: 211625350, name: 'Uždaroji akcinė bendrovė "VICAMEDIS"' },
  { code: 211690280, name: "HARALD OLSEN personalinė įmonė" },
  { code: 211716120, name: "ELECTRONIC HOUSE, UAB" },
  {
    code: 211791920,
    name: 'Uždaroji akcinė bendrovė "KRISTENSEN & KRISTENSEN"'
  },
  { code: 220603470, name: "V. Pašinos individuali įmonė" },
  { code: 220858930, name: 'Linčiaus firma "TELEREX"' },
  { code: 221308170, name: 'Uždaroji akcinė bendrovė "TECHSTA"' },
  { code: 221421570, name: "A. Zacharovo firma" },
  { code: 221513780, name: 'Akcinė bendrovė "Morena"' },
  {
    code: 221514010,
    name: 'Lietuvos ir Slovakijos akcinė bendrovė "GRAFOBAL VILNIUS"'
  },
  { code: 221579830, name: 'Uždaroji akcinė bendrovė "Sedes regia"' },
  { code: 221968760, name: 'Akcinė bendrovė "SILIKATAS"' },
  { code: 222343320, name: "I. Kostyk personalinė įmonė" },
  { code: 222432420, name: 'A. K. Sabaičio TŪB "GEPA"' },
  { code: 222657470, name: 'Uždaroji akcinė bendrovė "MOTIEJUS"' },
  { code: 222785420, name: 'R. Bagočiūno firma "RAIBĖ"' },
  { code: 222837340, name: 'IĮ "DELORE"' },
  { code: 223761080, name: 'Uždaroji akcinė bendrovė "LAKUMAS"' },
  { code: 223790160, name: 'Uždaroji akcinė bendrovė "SELDRA"' },
  { code: 224031440, name: "Audriaus Dimavičiaus personalinė įmonė" },
  { code: 224032880, name: 'A. Nedovizijaus firma "MIŠKUVA"' },
  {
    code: 224253380,
    name: 'Uždaroji akcinė bendrovė "NEMENČINĖS MEDIENA"'
  },
  { code: 224417050, name: "Tamaros Sliusarenko įmonė" },
  { code: 224470430, name: 'Uždaroji akcinė bendrovė "JUKAVA"' },
  { code: 224476650, name: 'Uždaroji akcinė bendrovė "RIARDŽIS"' },
  { code: 224912610, name: 'UAB "Fortimas"' },
  { code: 225165360, name: 'Uždaroji akcinė bendrovė "AISTIKA"' },
  { code: 225178240, name: "V. Markevičiaus įmonė" },
  { code: 225455310, name: 'Uždaroji akcinė bendrovė "MARSEMUS"' },
  { code: 225490820, name: "Augustino Citavičiaus personalinė įmonė" },
  { code: 225601320, name: 'UAB "HIMĖJA"' },
  {
    code: 225643390,
    name: 'UAB "TECHNOLITAS BALDAI" KŪB "DROŽLIŲ PLOKŠTĖ"'
  },
  { code: 225774450, name: "UAB DS Smith Packaging Lithuania" },
  { code: 225821590, name: 'UAB "VIRIDITAS"' },
  { code: 225829480, name: 'UAB "INTERLANGAI"' },
  { code: 226077340, name: 'Uždaroji akcinė bendrovė "IZKADA"' },
  { code: 226086880, name: 'UAB "Inter omnes constat"' },
  { code: 226095210, name: 'UAB "Štancas"' },
  { code: 226150450, name: 'UAB "Profidentas"' },
  { code: 226281510, name: 'UAB "Aplankalita"' },
  { code: 226341530, name: 'UAB "Elgamos šviesa"' },
  { code: 232051880, name: 'R. Sabaliausko įmonė "Vikis"' },
  { code: 233107990, name: 'Uždaroji akcinė bendrovė "Kauno atikas"' },
  { code: 233370660, name: 'Uždaroji akcinė bendrovė "Stiklo lakštas"' },
  { code: 233393310, name: "ITAB Lithuania, AB" },
  { code: 233414480, name: 'Uždaroji akcinė bendrovė "Lausta"' },
  { code: 233483850, name: 'Uždaroji akcinė bendrovė "Stadija"' },
  { code: 233898540, name: 'Uždaroji akcinė bendrovė "Baltasis vėjas"' },
  { code: 234003980, name: 'Uždaroji akcinė bendrovė "Saloža"' },
  { code: 234116070, name: 'Uždaroji akcinė bendrovė "Gravera"' },
  { code: 234326460, name: 'Uždaroji akcinė bendrovė "Gruinė"' },
  { code: 234489710, name: 'Uždaroji akcinė bendrovė "Stiklita"' },
  { code: 234498150, name: "Ovidijaus Dukavičiaus firma" },
  { code: 234509440, name: "Virginijaus Rabcevičiaus įmonė" },
  { code: 234691110, name: 'Uždaroji akcinė bendrovė "Pupulis"' },
  { code: 234983940, name: 'UAB "RUT"' },
  { code: 235014830, name: 'Akcinė bendrovė "Kauno energija"' },
  {
    code: 235073330,
    name: 'Uždaroji akcinė bendrovė "Amandos" dizainas'
  },
  { code: 235168650, name: 'Uždaroji akcinė bendrovė "Lenbra"' },
  { code: 235497560, name: "UAB Trijų artelė" },
  { code: 235561130, name: "Editos Valeckienės įmonė" },
  { code: 235573780, name: 'UAB "Impregnantas"' },
  { code: 235599540, name: 'Uždaroji akcinė bendrovė "Parandė"' },
  { code: 235677430, name: 'Uždaroji akcinė bendrovė "Reveka"' },
  { code: 235697080, name: 'E. Raudoniaus įmonė "Rabalda"' },
  { code: 235746990, name: 'Uždaroji akcinė bendrovė "Madisonas"' },
  { code: 235886490, name: 'UAB "Novameta"' },
  { code: 235953170, name: 'UAB "Airuslita"' },
  { code: 235974150, name: 'Uždaroji akcinė bendrovė "Rimantėja"' },
  { code: 240188850, name: "F. Pogosian avalynės gamybos įmonė" },
  { code: 240616710, name: 'Akcinė bendrovė "KLAIPĖDOS MEDIENA"' },
  { code: 240781950, name: 'Uždaroji akcinė bendrovė "JUTEMA"' },
  { code: 241081730, name: 'A. Kruglikovo firma "ANIPA"' },
  { code: 241616460, name: 'Uždaroji akcinė bendrovė "KLAIMEDA"' },
  { code: 241844720, name: 'Uždaroji akcinė bendrovė "ARLANGA pvc"' },
  { code: 241856270, name: 'UAB "ASMODAS"' },
  { code: 241993760, name: "L. Banionienės firma" },
  { code: 242061830, name: 'UAB "STALBALTA"' },
  { code: 242082810, name: 'UAB "SKAIDROLA"' },
  { code: 242110640, name: 'Uždaroji akcinė bendrovė "UODERĖ"' },
  { code: 242129740, name: 'UAB "BALTSETA"' },
  { code: 242155710, name: 'Uždaroji akcinė bendrovė "JLA"' },
  { code: 242182020, name: 'Uždaroji akcinė bendrovė "LKB"' },
  { code: 244670310, name: 'Uždaroji akcinė bendrovė "TOKSIKA"' },
  { code: 244832310, name: 'Uždaroji akcinė bendrovė "Vikmedis"' },
  { code: 244951930, name: "Rimanto Šeškaus įmonė" },
  { code: 245268040, name: 'UŽDAROJI AKCINĖ BENDROVĖ "DAUGAUDAS"' },
  { code: 245273030, name: 'UAB "Valmedis"' },
  { code: 245335820, name: "Viliaus Gedriko įmonė" },
  { code: 245358580, name: 'Akcinė bendrovė "Šiaulių energija"' },
  { code: 245435030, name: "Giedriaus Almino įmonė" },
  { code: 245908070, name: 'UAB "Porolita"' },
  { code: 247021170, name: 'Uždaroji akcinė bendrovė "VOLTAS"' },
  { code: 247108390, name: "E. Janaudžio įmonė" },
  { code: 247788520, name: "V. Preidžiaus įmonė" },
  { code: 248213330, name: "R. Čeriaukos įmonė" },
  { code: 248217880, name: 'Albino Vološkevičiaus firma "REGAMA"' },
  { code: 248331510, name: "E. Vienažindžio įmonė" },
  { code: 248368590, name: 'Uždaroji akcinė bendrovė "ELEKTROSFERA"' },
  { code: 248515930, name: 'UAB "Bengidis"' },
  { code: 250085610, name: 'Uždaroji akcinė bendrovė "Statisa"' },
  {
    code: 251237590,
    name: 'Uždaroji akcinė bendrovė "Marijampolės komprojektas"'
  },
  { code: 251427230, name: "Edmanto Mališausko reklamos firma" },
  { code: 252632590, name: 'UAB "VAKARŲ MEDIENA TORSO"' },
  { code: 253249520, name: "A. Tamašauskienės įmonė" },
  { code: 253250070, name: 'UAB "Akvitas"' },
  { code: 253253180, name: "Uždaroji akcinė bendrovė RUDIKIŲ MALŪNAS" },
  { code: 253604590, name: 'Uždaroji akcinė bendrovė "Daugė"' },
  { code: 254164820, name: 'UAB "SIGIRIS"' },
  { code: 254221730, name: "R. Dabregienės įmonė" },
  { code: 254229620, name: "J. Kazlausko įmonė" },
  { code: 254704540, name: 'MB "Balčiūno medis"' },
  { code: 255311910, name: "Alio Krisikėno įmonė" },
  { code: 255401350, name: 'Individuali įmonė "Žilin M. J. MIG"' },
  { code: 255450080, name: "VALSTYBĖS ĮMONĖ IGNALINOS ATOMINĖ ELEKTRINĖ" },
  { code: 255973180, name: 'UAB "VAGENSAS"' },
  { code: 256580550, name: "R. Keturkos privati įmonė" },
  { code: 256694080, name: "V. Petrausko individuali įmonė" },
  { code: 257603030, name: "UAB GEJŪNA" },
  {
    code: 257693490,
    name: 'S. Pladžio įmonė "Metalo ir medžio meniniai gaminiai"'
  },
  {
    code: 258325370,
    name: 'Uždaroji akcinė bendrovė "Jurbarko komunalininkas"'
  },
  { code: 258888760, name: "Nerijaus Bučio įmonė" },
  { code: 258908490, name: 'R. Motiekaičio įmonė "Katoma"' },
  { code: 258921580, name: "Artūro Žemčužnikovo personalinė įmonė" },
  { code: 258999180, name: "Gintauto Karnilos įmonė" },
  { code: 259423650, name: 'UAB "Bokenas"' },
  { code: 259513090, name: "Genės Stankevičienės įmonė" },
  { code: 259595340, name: 'Uždaroji akcinė bendrovė "Žėrutis"' },
  { code: 259712270, name: 'ŽŪB "Vilkijos pašarai"' },
  { code: 259765440, name: 'Uždaroji akcinė bendrovė "Augija"' },
  { code: 259789530, name: "V. Kairio įmonė" },
  { code: 259936980, name: 'A. Mačiūtos įmonė "Sedma"' },
  { code: 262431810, name: 'UAB "Kalvaratas"' },
  { code: 263156160, name: 'Uždaroji akcinė bendrovė "AKAI"' },
  { code: 263170690, name: 'Uždaroji akcinė bendrovė "JIEVA"' },
  { code: 263341020, name: 'IĮ "Pajūrio portalas"' },
  { code: 263373210, name: 'Uždaroji akcinė bendrovė "Sadiva"' },
  { code: 263531870, name: "L. Strumylienės individuali įmonė" },
  { code: 263667710, name: 'B. Krauleidžio firma "Miško medžiagos"' },
  { code: 263687360, name: "Vyganto Razmos individuali įmonė" },
  { code: 263706750, name: 'Uždaroji akcinė bendrovė "Mūsų miškas"' },
  { code: 263713410, name: "Č. Martinaičio ūkinė - komercinė firma" },
  { code: 263721510, name: 'Uždaroji akcinė bendrovė "Olavetė"' },
  { code: 263740820, name: 'Uždaroji akcinė bendrovė "Alrasina"' },
  { code: 264309470, name: "Neringos Žilytės įmonė" },
  { code: 264592890, name: 'UAB "Ineksa"' },
  { code: 265220220, name: "A. Pilecko individuali įmonė" },
  { code: 265641340, name: 'J. Makarevičiaus įmonė "Jovaras"' },
  { code: 265774180, name: 'UAB "Rimedis"' },
  { code: 266591940, name: 'Uždaroji akcinė bendrovė "Rimtis"' },
  { code: 266769930, name: 'Uždaroji akcinė bendrovė "Valcetas"' },
  { code: 266856260, name: 'Uždaroji akcinė bendrovė "Šerkšnėnai"' },
  { code: 267245140, name: 'UAB "Balėnų medis"' },
  { code: 267366430, name: 'UAB "Jūrpa"' },
  { code: 267387410, name: 'UAB "Finoila"' },
  { code: 267395510, name: 'UAB "VITVUDAS"' },
  { code: 268040590, name: "A. Butnoriaus individuali įmonė" },
  { code: 268428970, name: 'UAB "Raguvos baldai" ir ko' },
  { code: 268605730, name: "Valdemaro Povilaičio įmonė" },
  { code: 268983370, name: 'UAB "Plasmigida"' },
  { code: 270052450, name: "K. Rimkevičiaus paslaugų įmonė" },
  { code: 270532150, name: 'Uždaroji akcinė bendrovė "Vigrima"' },
  { code: 270533590, name: 'UAB "KBF"' },
  { code: 271205760, name: 'Br. Juodpalio įmonė "Kermeta"' },
  { code: 271386990, name: "Arūno Marašinsko įmonė" },
  { code: 273737790, name: 'UAB "Tagris"' },
  { code: 273882280, name: "Egidijaus Pocevičiaus meno dirbinių įmonė" },
  { code: 273889830, name: 'Uždaroji akcinė bendrovė "Skuodo šiluma"' },
  { code: 274290470, name: 'Uždaroji akcinė bendrovė "Vinkšna"' },
  { code: 274974130, name: 'Uždaroji akcinė bendrovė "Poliplastika"' },
  { code: 276627840, name: "Stasio Gailevičiaus įmonė" },
  { code: 277046140, name: 'UAB "Stoniškių žara"' },
  { code: 277074990, name: 'UAB "Energetikos objektų statyba"' },
  { code: 277132130, name: 'Akcinė bendrovė "Šilutės baldai"' },
  { code: 277157660, name: "G. Bukausko įmonė" },
  { code: 277171090, name: 'IĮ "Rodana"' },
  {
    code: 277188410,
    name: 'Uždaroji akcinė bendrovė "Juknaičių vaistinė"'
  },
  { code: 277275840, name: "S. Baubkaus įmonė" },
  { code: 277416860, name: 'UAB "LINEGIS"' },
  { code: 277418530, name: "Valmanto Mickevičiaus įmonė" },
  { code: 278671180, name: 'Uždaroji akcinė bendrovė "Almra"' },
  { code: 278724430, name: 'Uždaroji akcinė bendrovė "Savutė"' },
  { code: 278725870, name: "M. Jakubcevičiaus individuali įmonė" },
  { code: 278993320, name: 'Uždaroji akcinė bendrovė "Orginesta"' },
  {
    code: 279281660,
    name: "Inos Užkuraitienės visuomeninio maitinimo įmonė"
  },
  { code: 279357670, name: "Algirdo Stanelio įmonė" },
  {
    code: 279876220,
    name: 'Uždaroji akcinė bendrovė "Geranta" ir partneriai'
  },
  { code: 281367860, name: 'Uždaroji akcinė bendrovė "ELPURA"' },
  { code: 282888930, name: "V. Mimo individuali įmonė" },
  { code: 282912210, name: 'UAB "ŽELVOS MEDIS"' },
  { code: 283214870, name: "DARIAUS PETRAUSKO įmonė" },
  { code: 283675180, name: 'Uždaroji akcinė bendrovė "Pakasas"' },
  { code: 283834070, name: 'UAB "Samsonas motorsport"' },
  { code: 284232490, name: 'UAB "Architera"' },
  { code: 284268020, name: "BIOVELA-UTENOS MĖSA, UAB" },
  { code: 285317680, name: "Zenono Raulynaičio įmonė" },
  { code: 285345320, name: "Vito Adomavičiaus įmonė" },
  { code: 285470270, name: "Vytauto Špakausko įmonė" },
  {
    code: 286086970,
    name: 'Vilniaus rajono žemės ūkio bendrovė "Tarakonys"'
  },
  { code: 286479240, name: 'Uždaroji akcinė bendrovė "NEKODA"' },
  { code: 286485670, name: 'UAB "JULIAN ir KO"' },
  { code: 286752970, name: 'UAB "Sanipax"' },
  { code: 287841710, name: 'Zarasų rajono žemės ūkio bendrovė "Bočiai"' },
  { code: 300000170, name: 'Uždaroji akcinė bendrovė "Multipakas"' },
  { code: 300001176, name: 'J. Pupšio įmonė "Spygliuočių lentos"' },
  { code: 300001315, name: 'A. Kunigiškio IĮ "Lijogeda"' },
  { code: 300001735, name: 'Žemės ūkio kooperatyvas "Sūduvos biokuras"' },
  { code: 300002157, name: "UAB Akumuliatorių perdirbimo centras" },
  { code: 300002495, name: 'UAB "TIKO BALDAI"' },
  { code: 300003985, name: 'UAB "Baltic plastic products"' },
  { code: 300004343, name: 'UAB "MEDVIKA"' },
  { code: 300004763, name: "Mereabi OU Klaipėdos filialas" },
  { code: 300008142, name: 'Individuali įmonė "TIRISTORIUS"' },
  { code: 300008562, name: 'IĮ "Andžej ir Ko"' },
  { code: 300008929, name: "L. Kazlausko individuali įmonė" },
  { code: 300009582, name: 'UAB "Arteka"' },
  { code: 300009600, name: 'UAB "Darkuta"' },
  { code: 300009746, name: "CJ WILDBIRD FOODS LIMITED FILIALAS" },
  { code: 300010200, name: 'UAB "Laučera"' },
  { code: 300010976, name: "Vaidos Šaltmerienės IĮ" },
  { code: 300011010, name: 'UAB "Aromika"' },
  {
    code: 300011131,
    name: "Viešoji įstaiga Ekonominės integracijos agentūra"
  },
  { code: 300011188, name: 'UAB "Tvorlitus"' },
  { code: 300011398, name: 'UAB "Perfora"' },
  { code: 300011672, name: 'UAB "TAURYS"' },
  { code: 300011932, name: 'Uždaroji akcinė bendrovė "IMPREGMEDA"' },
  { code: 300012386, name: 'UAB "Baltic metal"' },
  { code: 300012482, name: 'VIEŠOJI ĮSTAIGA "LG TALENTAI"' },
  { code: 300012518, name: 'Individuali įmonė "Ylakio statyba"' },
  { code: 300013328, name: 'UAB "Laiptvija"' },
  { code: 300014704, name: 'Viešoji įstaiga "SKAPTUKAS"' },
  { code: 300014750, name: 'UAB "Lanresta"' },
  { code: 300014914, name: 'UAB "Vakarų ūkas"' },
  { code: 300014921, name: 'UAB "Neosomatas"' },
  { code: 300015208, name: 'UAB "Sander Baltic"' },
  { code: 300015304, name: 'Uždaroji akcinė bendrovė "Granmax"' },
  { code: 300015585, name: 'IĮ "Vysiba"' },
  { code: 300015642, name: 'Viešoji įstaiga "Žemės fragmentai"' },
  { code: 300016192, name: 'UAB "Aumila"' },
  { code: 300016210, name: 'IĮ "KVIKAS"' },
  { code: 300016527, name: "INGOS BAŠKIENĖS IĮ" },
  { code: 300016580, name: 'UAB "Skado medis"' },
  { code: 300016801, name: 'UAB "FPI Baltic"' },
  { code: 300017508, name: 'UAB "Centaurus"' },
  { code: 300017586, name: 'UAB "Donaja"' },
  { code: 300018930, name: 'UAB "Žiliukai"' },
  { code: 300020080, name: 'UAB "KOJORA"' },
  { code: 300020397, name: 'UAB "Kedfarma"' },
  { code: 300020835, name: 'UAB "MASTER-SERVIS"' },
  { code: 300021175, name: 'UAB "PALANGOS EGINTAS"' },
  { code: 300023952, name: 'UAB "Rolrimė"' },
  {
    code: 300024303,
    name: 'E. Rekašiaus individuali įmonė "BALTIC FOREST"'
  },
  { code: 300024748, name: 'UAB "TAILOR-MADE"' },
  { code: 300024901, name: 'UAB "Lekomeda"' },
  { code: 300025992, name: 'UAB "SENATOR projektai"' },
  { code: 300026343, name: 'UAB "Dastolija"' },
  { code: 300026464, name: 'UAB "Šiaulių trinkelė"' },
  { code: 300027737, name: 'UAB "S-Form"' },
  { code: 300028305, name: 'UAB "Dumesta" ir ko' },
  { code: 300029453, name: 'IĮ "Baldena"' },
  { code: 300031881, name: 'Individuali įmonė "SPALVOS"' },
  {
    code: 300032232,
    name: 'Viešoji įstaiga "Kalinių socialinės integracijos centras"'
  },
  { code: 300032556, name: 'TŪB "Aukštaitijos baldai"' },
  { code: 300033028, name: "Kristinos Stankauskienės individuali įmonė" },
  { code: 300034055, name: 'IĮ "Staliaus pastogė"' },
  { code: 300034817, name: 'Daivos Kurienės IĮ "Saukura"' },
  { code: 300035577, name: 'Uždaroji akcinė bendrovė "Palangė"' },
  { code: 300036490, name: 'UAB "CHEMOPOLIS"' },
  { code: 300037101, name: 'UAB "Kanrugėlė"' },
  { code: 300037592, name: 'UAB "Kauno stiklas"' },
  { code: 300038445, name: "UAB TRIPLAN LT" },
  {
    code: 300038872,
    name: 'V. Gružausko individuali įmonė "Vidas ir partneriai"'
  },
  { code: 300039390, name: '"DIAB", UAB' },
  { code: 300039561, name: 'UAB "Miltodis"' },
  { code: 300039860, name: "Manto Petraičio individuali įmonė" },
  { code: 300040784, name: 'UAB "Baldų linija"' },
  { code: 300040898, name: 'UAB "IEI"' },
  { code: 300041039, name: 'UAB "Likmerė"' },
  { code: 300041523, name: 'UAB "CARLSEN BALTIC"' },
  { code: 300042792, name: 'UAB "Baldų aibė"' },
  { code: 300042810, name: 'UAB "GLASTIKA"' },
  { code: 300043296, name: 'UAB "Rėmai ir rėmeliai"' },
  { code: 300044551, name: 'UAB "HANNING VILNIUS"' },
  { code: 300044747, name: 'UAB "Ravela"' },
  { code: 300045482, name: "Donato Sliosoro IĮ" },
  { code: 300045678, name: 'UAB "EKOLOGINĖS ENERGO SISTEMOS"' },
  { code: 300045781, name: 'UAB "AVITUS"' },
  { code: 300045920, name: 'UAB "RIZZI"' },
  { code: 300046029, name: 'UAB "Gutronikas"' },
  { code: 300046538, name: "BAGES UAB" },
  { code: 300046641, name: 'IĮ "Baldsvaja"' },
  { code: 300046851, name: 'UAB "NORTHCLIFFE LIGHTING"' },
  { code: 300047914, name: 'UAB "RSP"' },
  { code: 300049203, name: 'UAB "COGI Baltija"' },
  { code: 300050141, name: 'UAB "BHK Lietuva"' },
  { code: 300051229, name: 'Uždaroji akcinė bendrovė "Galanis"' },
  { code: 300051485, name: 'UAB "Lomeras"' },
  { code: 300051599, name: 'UAB "Gobana"' },
  { code: 300052352, name: 'UAB "Langasa"' },
  { code: 300052929, name: 'UAB "Drūtas"' },
  { code: 300054175, name: 'IĮ "Trianona"' },
  { code: 300055409, name: 'Viešoji įstaiga "Menų namai"' },
  { code: 300055494, name: 'UAB "Arstalita"' },
  { code: 300057310, name: "Wittson Cycles UAB" },
  { code: 300057997, name: 'UAB "Tytmedis"' },
  { code: 300058152, name: 'UAB "Atliekų perdirbimo centras"' },
  { code: 300058191, name: 'UAB "Ramilė"' },
  {
    code: 300058782,
    name: 'UAB "VAISTŲ GAMYBOS IR PREKYBOS TECHNOLOGIJOS"'
  },
  { code: 300060908, name: 'UAB "RJ Baltic"' },
  { code: 300061180, name: 'UAB "Meanmeda"' },
  { code: 300061547, name: "Vytauto Almino individuali įmonė" },
  { code: 300061814, name: 'UAB "MNS-1"' },
  { code: 300062688, name: 'UAB "Vikbarta"' },
  { code: 300063434, name: 'UAB "ARGUTUS"' },
  { code: 300063815, name: "Kęstučio Navardausko IĮ" },
  { code: 300063822, name: 'IĮ "Glasuritas"' },
  { code: 300063847, name: 'UAB "EPH Elektronik"' },
  { code: 300064116, name: 'UAB "Albo baldai"' },
  { code: 300064237, name: 'UAB "Ferax"' },
  { code: 300065257, name: 'UAB "Medlain"' },
  { code: 300066398, name: 'UAB "GESTAR"' },
  { code: 300066964, name: '"KB Components", UAB' },
  { code: 300067151, name: "Dariaus Padelsko individuali įmonė" },
  { code: 300067386, name: 'IĮ "Sevalda"' },
  {
    code: 300068520,
    name: 'Uždaroji akcinė bendrovė "Vitlita diagnostika"'
  },
  { code: 300069825, name: 'UAB "NASK"' },
  { code: 300070304, name: 'UAB "Gintaro sala"' },
  { code: 300070877, name: 'UAB "LIETVILNA"' },
  { code: 300070959, name: 'UAB "Randers Reb International"' },
  { code: 300071331, name: 'UAB "Agrojavai"' },
  { code: 300071915, name: 'UAB "Camira Fabrics"' },
  { code: 300072401, name: 'UAB "Mavylė"' },
  { code: 300073065, name: 'UAB "Baltstatus"' },
  { code: 300073161, name: 'UAB "MARINE BELL LLC"' },
  { code: 300073229, name: 'UAB "Maldis"' },
  { code: 300073535, name: 'MB "Madika"' },
  { code: 300073802, name: 'UAB "Pramonės energija"' },
  { code: 300075290, name: 'UAB "NOVIKONTAS TSS"' },
  { code: 300076467, name: 'Uždaroji akcinė bendrovė "Viverlita"' },
  { code: 300076741, name: 'UAB "Alevista"' },
  { code: 300076798, name: "UAB SANI group" },
  { code: 300077583, name: 'UAB "REBA"' },
  { code: 300077697, name: 'UAB "Baldvita"' },
  { code: 300078429, name: 'UAB "MEFA"' },
  { code: 300078817, name: 'Uždaroji akcinė bendrovė "Medera"' },
  { code: 300079844, name: "Josifo Daukševič individuali įmonė" },
  { code: 300080017, name: 'UAB "Stronglasas"' },
  { code: 300080259, name: 'IĮ "Aso wood"' },
  { code: 300080661, name: 'UAB "JONAVOS EKSPORTAS"' },
  { code: 300081496, name: "Nordic Door Components UAB" },
  { code: 300082153, name: 'IĮ "Myline"' },
  { code: 300082737, name: 'UAB "Laiptų karalystė"' },
  { code: 300083127, name: 'UAB "GVA technika"' },
  {
    code: 300083878,
    name: 'UAB "Utenos regiono atliekų tvarkymo centras"'
  },
  { code: 300084072, name: 'UAB "Stellaprint"' },
  { code: 300084179, name: 'UAB "Skaituva"' },
  { code: 300084236, name: 'UAB "Dargiris"' },
  { code: 300085719, name: 'UAB "CPS Engineering"' },
  { code: 300085733, name: 'Individuali įmonė "ANLINGA"' },
  { code: 300087353, name: 'IĮ "VARDAN"' },
  { code: 300088277, name: 'UAB "Juodoji audinė"' },
  { code: 300088619, name: 'UAB "Meranti"' },
  { code: 300088793, name: 'UAB "Baltfloor"' },
  { code: 300089105, name: 'UAB "Prosangvis"' },
  { code: 300089315, name: 'UAB "EXPOSYSTEM"' },
  {
    code: 300089728,
    name: 'G. Trinkūno individuali įmonė "Buhalterinės apskaitos paslaugos"'
  },
  { code: 300090730, name: "SAERTEX Baltics UAB" },
  { code: 300091330, name: 'UAB "METEC"' },
  { code: 300092090, name: 'UAB "KURANA"' },
  { code: 300092724, name: 'M. Burgailos IĮ "Bumana"' },
  {
    code: 300092998,
    name: "Viešoji įstaiga Kauno regiono atliekų tvarkymo centras"
  },
  { code: 300093477, name: 'UAB "Madiks projektai"' },
  { code: 300093687, name: 'UAB "DEIMEDA"' },
  { code: 300095617, name: 'UAB "Stiklita Klaipėda"' },
  { code: 300095834, name: 'UAB "MEDŽIO FABRIKAS NIDA"' },
  { code: 300095898, name: 'IĮ "Rimkosa"' },
  { code: 300096726, name: 'UAB "BALTIC COMPOSITE CONSTRUCTIONS"' },
  { code: 300097027, name: 'UAB "MESTILLA"' },
  { code: 300097582, name: "Jono Kaupelio IĮ" },
  { code: 300097746, name: "Kęstučio Kelpšos IĮ" },
  { code: 300098104, name: "UAB Klaipėdos jūrinis servisas" },
  { code: 300098524, name: "Vilmers UAB" },
  { code: 300100644, name: "UAB Inno Line" },
  { code: 300103213, name: 'UAB "Todus"' },
  { code: 300104258, name: 'UAB "SK Design"' },
  { code: 300104614, name: 'UAB "Karuba"' },
  { code: 300104831, name: 'IĮ "AKMENS TVORA"' },
  { code: 300104906, name: 'IĮ "Paulita"' },
  { code: 300104977, name: 'UAB "Statega"' },
  { code: 300105805, name: "IĮ BALTA- MĖLYNA" },
  { code: 300106476, name: 'IĮ "Tojo baldai"' },
  { code: 300108178, name: 'UAB "Mood Furniture"' },
  { code: 300110161, name: 'Uždaroji akcinė bendrovė "Alytaus pašarai"' },
  { code: 300110282, name: 'UAB "ASK linija"' },
  { code: 300110503, name: 'UAB "RALKO"' },
  { code: 300110649, name: 'UAB "Kranto ekspeditoriai"' },
  { code: 300110784, name: 'UAB "Saudara"' },
  { code: 300110791, name: "S. Brovko individuali įmonė" },
  { code: 300111338, name: 'UAB "JMP Group"' },
  { code: 300113830, name: 'UAB "BALTLED"' },
  { code: 300114124, name: 'UAB "Idea LT"' },
  { code: 300114291, name: "V. Baltakio IĮ" },
  { code: 300114359, name: 'UAB "Baldai man"' },
  { code: 300114633, name: 'UAB "Nordvila"' },
  { code: 300115105, name: 'UAB "MEDVEŽA"' },
  { code: 300115233, name: 'UAB "Modus SD"' },
  { code: 300115404, name: "A. Cikocko individuali įmonė" },
  { code: 300116278, name: 'UAB "Baltic Car Systems"' },
  { code: 300116456, name: 'UAB "Uosintva"' },
  { code: 300116545, name: 'UAB "Vilgrita"' },
  { code: 300117177, name: '"ARCO TIMBER", UAB' },
  { code: 300118051, name: 'UAB "Ginora"' },
  { code: 300118592, name: "Vaidoto Maliausko individuali įmonė" },
  { code: 300119623, name: 'UAB "Cornat"' },
  { code: 300120390, name: 'UAB "Electric box"' },
  { code: 300120515, name: 'UAB "Milvita"' },
  { code: 300122306, name: 'UAB "Panorama nordic"' },
  { code: 300122459, name: 'UAB "MEDINIAI NAMAI"' },
  { code: 300124759, name: 'UAB "BALDŲ IDĖJOS"' },
  {
    code: 300127004,
    name: "UAB Panevėžio regiono atliekų tvarkymo centras"
  },
  { code: 300127342, name: 'Individuali įmonė "FORMEDA"' },
  { code: 300127470, name: 'UAB "Reval baldai"' },
  { code: 300128070, name: 'UAB "PLUNGITA"' },
  { code: 300128362, name: 'UAB "AVITRA"' },
  { code: 300128597, name: "Mindaugo Pavlavičiaus IĮ" },
  { code: 300128818, name: 'UAB "Energopliusas"' },
  { code: 300128914, name: 'Uždaroji akcinė bendrovė "ANTVITA"' },
  { code: 300129119, name: 'UAB "Impregnuotas medis"' },
  { code: 300129521, name: 'UAB "RE betono konstrukcijos"' },
  { code: 300129838, name: 'Individuali įmonė "TKA"' },
  { code: 300130566, name: 'UAB "FB SPLIT"' },
  { code: 300131209, name: 'UAB "Eurohouse"' },
  { code: 300132040, name: 'UAB "Termota"' },
  { code: 300132364, name: 'UAB "Frilux"' },
  { code: 300132752, name: 'UAB "Taisonas"' },
  { code: 300132909, name: 'UAB "CTT"' },
  { code: 300133391, name: 'UAB "Daviluka"' },
  { code: 300134283, name: 'UAB "Lisadas"' },
  { code: 300134600, name: 'UAB "AVANTY"' },
  { code: 300136284, name: 'UAB "Plastyl"' },
  { code: 300136779, name: 'UAB "LITTECH"' },
  { code: 300137518, name: 'Uždaroji akcinė bendrovė "Kaunas WSB"' },
  { code: 300139266, name: 'UAB "DVR paslaugos"' },
  { code: 300139736, name: 'Uždaroji akcinė bendrovė "Ligirduja"' },
  { code: 300143485, name: 'UAB "ELTAVA"' },
  { code: 300146289, name: 'UAB "Nordic Prime Foods"' },
  { code: 300146524, name: 'UAB "Fitohema"' },
  { code: 300146759, name: 'Uždaroji akcinė bendrovė "Lankesta"' },
  { code: 300148685, name: 'UAB "GENSTARTA"' },
  { code: 300148831, name: 'UAB "Giola"' },
  { code: 300149812, name: 'UAB "Ambinga"' },
  { code: 300150451, name: 'UAB "Grandinas"' },
  { code: 300151934, name: 'UAB "Vudesta"' },
  { code: 300153860, name: "UAB Klasmann-Deilmann Verdenis" },
  { code: 300154161, name: 'UAB "Enga"' },
  { code: 300154916, name: 'UAB "Dikse"' },
  { code: 300500236, name: 'UAB "Energogrupė"' },
  { code: 300501498, name: 'UAB "Plastma"' },
  { code: 300501975, name: 'UAB "Šiluma Jums"' },
  { code: 300503086, name: 'IĮ "Mobili gama"' },
  { code: 300504576, name: 'IĮ "Lugnika"' },
  { code: 300504811, name: 'UAB "Hefaistas"' },
  { code: 300508724, name: 'UAB "NORDISKA ETIKETT"' },
  { code: 300508756, name: 'UAB "Jomeda"' },
  { code: 300509491, name: 'UAB "Pramoniniai dažai"' },
  { code: 300509534, name: 'IĮ "Meridus"' },
  { code: 300509694, name: "E. Norkevičiaus individuali įmonė" },
  { code: 300512295, name: 'UAB "SCANDIC SEAFOOD"' },
  { code: 300513244, name: 'UAB "UPINĖ"' },
  { code: 300513600, name: 'UAB "VĖJO VALDOS"' },
  { code: 300513664, name: 'IĮ "Velomobilis"' },
  { code: 300513696, name: 'IĮ "MEGARĖ"' },
  { code: 300514022, name: 'UAB "Gera energija"' },
  { code: 300514129, name: 'UAB "Dafilija"' },
  { code: 300516781, name: 'UAB "Ekotechnologijos"' },
  { code: 300516863, name: 'UAB "Geldmeta"' },
  { code: 300517100, name: 'UAB "Šustė"' },
  { code: 300518362, name: 'UAB "INTERBALT"' },
  { code: 300520491, name: 'UAB "RIKARA"' },
  { code: 300520915, name: 'UAB "Gerdexa"' },
  { code: 300521593, name: 'UAB "NS3"' },
  { code: 300524582, name: 'UAB "Vytėja"' },
  { code: 300524632, name: 'IĮ "Laikas ir kokybė"' },
  { code: 300524828, name: 'UAB "Baltic Furniture Components"' },
  { code: 300525435, name: 'UAB "Energijos gausa"' },
  { code: 300526583, name: 'UAB "Lubas"' },
  { code: 300526626, name: 'UAB "Alkena"' },
  { code: 300527518, name: 'UAB "Medžio masyvas"' },
  { code: 300527557, name: 'UAB "BALTNET"' },
  { code: 300529387, name: 'UAB "Taurų kedras"' },
  { code: 300531210, name: 'UAB "BIOGRA"' },
  { code: 300531616, name: 'UAB "Energijos parkas"' },
  { code: 300531630, name: 'UAB "ITALIJOS ŽVAIGŽDĖ"' },
  { code: 300533503, name: 'Uždaroji akcinė bendrovė "IMUNOLITA"' },
  { code: 300534441, name: '"Constructer", UAB' },
  { code: 300535511, name: 'UAB "INO LT"' },
  { code: 300536613, name: 'UAB "STALDA"' },
  { code: 300536684, name: 'Individuali įmonė "Linaras"' },
  { code: 300536727, name: 'VšĮ "Limeza"' },
  { code: 300541546, name: 'UAB "Romjumeda"' },
  { code: 300541966, name: 'UAB "Almasta"' },
  { code: 300542331, name: 'UAB "Kalmita"' },
  { code: 300543725, name: 'UAB "GRETOS VĖJAS"' },
  { code: 300544802, name: 'UAB "Theca Furniture"' },
  { code: 300547524, name: '"NORMANTAS IR KO", UAB' },
  { code: 300547944, name: 'UAB "AGROINVEST"' },
  { code: 300548875, name: 'UAB "Pavarų elementai"' },
  { code: 300549525, name: 'UAB "Gardumeli"' },
  { code: 300550196, name: 'IĮ "KYTO"' },
  { code: 300550310, name: 'UAB "LAUKOBALDIS"' },
  { code: 300553210, name: "Povilo Bučinsko individuali įmonė" },
  { code: 300554376, name: 'UAB "Gražinos baldai"' },
  { code: 300554401, name: 'UAB "RASMEDIENA"' },
  { code: 300555695, name: 'UAB "VĖTRĖ"' },
  { code: 300556651, name: 'IĮ "LED Lights"' },
  { code: 300556847, name: 'UAB "Aujura"' },
  { code: 300557454, name: 'UAB "RASVETA"' },
  { code: 300559067, name: 'UAB "DYNAMIC SOLAR"' },
  { code: 300559868, name: 'UAB "Baldreina"' },
  { code: 300560817, name: 'UAB "Kauno elektrinė"' },
  { code: 300561837, name: 'UAB "Kauno Jiesia"' },
  { code: 300561851, name: 'UAB "AMG baldai"' },
  { code: 300562729, name: 'UAB "Hermetika"' },
  { code: 300563749, name: 'UAB "Stilvita"' },
  { code: 300564961, name: 'UAB "AM statybinės atliekos"' },
  { code: 300565700, name: 'UŽDAROJI AKCINĖ BENDROVĖ "SVL INVEST"' },
  { code: 300565839, name: 'IĮ "Vėrūna"' },
  { code: 300566332, name: 'UAB "BERTAS"' },
  { code: 300566841, name: 'UAB "BETATRANS"' },
  { code: 300567352, name: 'UAB "Baltic Modules"' },
  {
    code: 300568810,
    name: 'L. Gasiūnienės individuali įmonė "ARTLAIMA"'
  },
  { code: 300569111, name: 'IĮ "Mediksa"' },
  { code: 300569346, name: 'UAB "Klasikiniai baldai"' },
  { code: 300571443, name: 'UAB "Superija"' },
  { code: 300571571, name: 'UAB "DELTA CONSTRUCTION"' },
  { code: 300573736, name: 'UAB "MEGTA"' },
  { code: 300573864, name: 'UAB "Greiti langai"' },
  { code: 300573914, name: 'UAB "Baldingas"' },
  { code: 300575744, name: 'UAB "Emet"' },
  { code: 300575872, name: 'UAB "LL investicijos"' },
  { code: 300576006, name: 'UAB "ULEMA"' },
  { code: 300576077, name: 'UAB "Lietmeta"' },
  { code: 300576942, name: 'UAB "EURAKRAS"' },
  {
    code: 300577300,
    name: 'VšĮ "Baryso Zvozskavo Baltarusijos žmogaus teisių namai"'
  },
  { code: 300577421, name: 'UAB "Daivilda"' },
  { code: 300577827, name: 'UAB "LUXHUS"' },
  { code: 300580499, name: 'UAB "BALTIC FURNITURE"' },
  { code: 300582308, name: 'UAB "Daneta"' },
  { code: 300583641, name: 'UAB "Kvesmedis"' },
  { code: 300583748, name: 'Uždaroji akcinė bendrovė "Astra-V"' },
  { code: 300583958, name: 'UAB "Energetikos projektai"' },
  { code: 300584355, name: 'UAB "TOLNEKA"' },
  { code: 300584608, name: 'UAB "Du partneriai"' },
  { code: 300585165, name: 'Uždaroji akcinė bendrovė "Doskadas"' },
  { code: 300585902, name: 'UAB "Danizė"' },
  { code: 300586178, name: 'UAB "KVVM"' },
  { code: 300587344, name: 'UAB "PLAMIKA"' },
  { code: 300589060, name: 'UAB "TERAcorp"' },
  { code: 300590739, name: 'UAB "Idea bis"' },
  { code: 300591314, name: 'UAB "Narbutas International"' },
  { code: 300592067, name: 'UAB "Stamiksas"' },
  { code: 300592302, name: "Nijolės Zaleckienės IĮ" },
  { code: 300592665, name: 'UAB "SNP energija"' },
  { code: 300593500, name: 'UAB "Viešvė"' },
  { code: 300593653, name: 'IĮ "MAČAS"' },
  { code: 300594552, name: 'UAB "Nando"' },
  { code: 300595686, name: 'UAB "Artukas"' },
  { code: 300596717, name: 'UAB "Fleksus"' },
  { code: 300597502, name: "Vladimiro Akimovo Individuali įmonė" },
  { code: 300597826, name: 'UAB "Vakarų vamzdynų sistemos"' },
  { code: 300598077, name: 'UAB "LAUMĖS BALDŲ GAMYBA"' },
  { code: 300598294, name: 'UAB "GRANULITA"' },
  { code: 300598522, name: 'UAB "SAKRET LT"' },
  { code: 300599930, name: 'UAB "Ramalis"' },
  { code: 300600140, name: 'UAB "Stiklija"' },
  { code: 300601000, name: 'UAB "Kafas"' },
  { code: 300601406, name: 'UAB "TRAMCORP"' },
  { code: 300601534, name: 'UAB "Gilarta"' },
  { code: 300603617, name: 'IĮ "Astoda"' },
  { code: 300604199, name: 'Kooperatinė bendrovė "SURPLYS IR KO"' },
  { code: 300604338, name: 'UAB "VIVA NIKA"' },
  { code: 300604612, name: 'UAB "Ekoharmonija"' },
  { code: 300604950, name: 'UAB "Dažų pasaulis"' },
  { code: 300605244, name: 'UAB "SILVA PLANT"' },
  { code: 300605319, name: 'UAB "Regieka"' },
  { code: 300606047, name: 'UAB "Elektromatika"' },
  { code: 300608190, name: "Wolf Group LT, UAB" },
  { code: 300608475, name: 'UAB "Pavėsinės"' },
  { code: 300610679, name: 'UAB "SOSTINĖS BALDAI"' },
  { code: 300611083, name: 'UAB "TAPEGA"' },
  { code: 300612954, name: 'UAB "ALEKVYTA"' },
  { code: 300614040, name: 'UAB "Arpolis"' },
  {
    code: 300614193,
    name: "INTERNATIONAL ENGINEERING & CONSULTING, INC. atstovybė Klaipėdoje"
  },
  { code: 300614446, name: 'UAB "Girių projektai"' },
  { code: 300614752, name: 'UAB "EIG"' },
  { code: 300615384, name: 'UAB "VLL Group"' },
  { code: 300615459, name: 'UAB "Jupiter Bach Lietuva"' },
  { code: 300616746, name: "Andriaus Žemaičio individuali įmonė" },
  { code: 300616792, name: 'IĮ "LĄSTŪNĖ"' },
  { code: 300616842, name: 'UAB "Alionių keramika"' },
  { code: 300616970, name: 'UAB "LADUGA"' },
  { code: 300618708, name: 'UAB "HILLCREST"' },
  { code: 300618971, name: 'UAB "Lajūra"' },
  { code: 300619571, name: 'UAB "Aukštaitijos medis"' },
  { code: 300621266, name: 'UAB "JUMPS"' },
  { code: 300621750, name: 'UAB "VRS RAPIDO"' },
  { code: 300622293, name: 'UAB "Marine Services Klaipėda"' },
  { code: 300623413, name: 'UAB "Baltic Printing House"' },
  { code: 300623452, name: 'UAB "Menesa"' },
  { code: 300623979, name: 'UAB "Vebesta"' },
  { code: 300624216, name: 'IĮ "Kaneva"' },
  { code: 300625670, name: 'UAB "Stakaris"' },
  { code: 300626295, name: 'UAB "EKOMATRICA"' },
  { code: 300626548, name: "Tomo Pociaus IĮ" },
  { code: 300626701, name: 'UAB "GANIS"' },
  { code: 300627397, name: '"ORCA MARINE", UAB' },
  { code: 300628937, name: 'UAB "ALIN"' },
  {
    code: 300629163,
    name: 'Viešoji įstaiga "Laisvalaikio idėjų centras"'
  },
  { code: 300629430, name: 'UAB "KRIVLINAS"' },
  { code: 300629569, name: 'UAB "Archiprojektas"' },
  { code: 300630728, name: '"GLASSBEL BALTIC", UAB' },
  { code: 300631260, name: 'UAB "Prosperitas"' },
  { code: 300631997, name: 'UAB "Heliksas"' },
  { code: 300632782, name: 'UAB "PATS SAU BALDŽIUS"' },
  { code: 300632946, name: 'Uždaroji akcinė bendrovė "Arvi fertis"' },
  { code: 300634107, name: 'UAB "Domieva"' },
  { code: 300634598, name: 'UAB "FORM SYSTEM"' },
  { code: 300636364, name: 'UAB "Ekobetonas"' },
  { code: 300637562, name: 'UAB "Pika ir Ko"' },
  { code: 300637651, name: 'UAB "TEAK BOATS"' },
  { code: 300638778, name: 'UAB "AURIDA"' },
  { code: 300639307, name: 'UAB "BLOMSTRING"' },
  { code: 300639403, name: 'IĮ "Zebrana"' },
  { code: 300642584, name: 'UAB "Finnfoam"' },
  { code: 300645833, name: 'UAB "Libera forma"' },
  { code: 300646141, name: 'IĮ "Arominda"' },
  { code: 300646604, name: 'UAB "Branduolinis"' },
  { code: 300647357, name: 'UAB "Forneris"' },
  { code: 300650296, name: 'UAB "RODIMA"' },
  { code: 300650403, name: 'IĮ "AJDANAS"' },
  { code: 300651252, name: 'UAB "Baldų spalvos"' },
  { code: 300651291, name: 'UAB "RGE Baltic"' },
  { code: 300651804, name: 'UAB "Dalgvida"' },
  { code: 300654138, name: 'UAB "EDVAMEDA"' },
  { code: 300655610, name: 'UAB "Eigida"' },
  { code: 300656737, name: 'UAB "Labras"' },
  { code: 300656961, name: 'UAB "ALMECA"' },
  { code: 300657376, name: 'UAB "AER style"' },
  { code: 300657700, name: 'UAB "AR - STUDIO"' },
  {
    code: 300659516,
    name: 'Giedriaus Kalčinsko individuali baldų projektavimo ir gamybos įmonė "DIARA"'
  },
  { code: 300659936, name: 'UAB "Rąstiniai namai"' },
  { code: 300660319, name: 'UAB "ADRIJUS"' },
  { code: 300660550, name: "Edvardo Balevičiaus individuali įmonė" },
  { code: 300663660, name: 'UAB "Traksta"' },
  { code: 300663692, name: 'UAB "KOLLER HMS"' },
  { code: 300666553, name: 'UAB "TECHNIJA"' },
  { code: 300667057, name: 'UAB "United Woodworks"' },
  { code: 300667192, name: 'IĮ "Stolveri"' },
  { code: 300667274, name: 'UAB "TEISLITA"' },
  { code: 300669930, name: 'UAB "VIRAMA"' },
  { code: 300670181, name: 'UAB "DALBA"' },
  { code: 300670441, name: 'UAB "RANLangai"' },
  { code: 300677336, name: "Amber paper, UAB" },
  { code: 300701207, name: 'UAB "Lokta"' },
  { code: 300702191, name: 'UAB "Orex Group"' },
  { code: 300709563, name: 'IĮ "Balvaida"' },
  { code: 300711379, name: 'UAB "Trys A"' },
  { code: 300731517, name: 'UAB "EDMINTOS BALDAI"' },
  { code: 300753442, name: 'IĮ "Vakarų energija"' },
  { code: 300764474, name: 'UAB "LARINTA"' },
  { code: 300766639, name: 'Individuali įmonė "LUGĖ"' },
  { code: 300772439, name: 'UAB "PUŠŲ PAUNKSMĖ"' },
  { code: 300774447, name: 'UAB "Diremus"' },
  { code: 300802448, name: 'UAB "Baldų sfera"' },
  { code: 300812435, name: "Aurelijaus Venclovo individuali įmonė" },
  { code: 300814436, name: 'UAB "EG-Baldai"' },
  { code: 300818445, name: 'UAB "Laiptė"' },
  { code: 300823481, name: 'UAB "JL transportas"' },
  { code: 300834435, name: 'UAB "Gumos technologijos"' },
  { code: 300835462, name: 'UAB "Ekobazė"' },
  { code: 300845563, name: 'UAB "Artėjos baldai"' },
  { code: 300847468, name: 'UAB "Albright Lietuva"' },
  { code: 300849519, name: 'UAB "Novoblokas"' },
  { code: 300851840, name: 'UAB "Ortmeta"' },
  { code: 300855778, name: 'UAB "Gardulis"' },
  { code: 300857722, name: 'UAB "Agnetė"' },
  { code: 300865904, name: 'IĮ "VILMORA"' },
  { code: 300867008, name: 'UAB "LUXE POOLS"' },
  { code: 300869607, name: 'UAB "MINGRIDAS"' },
  { code: 300870666, name: 'UAB "VĖJŲ ENERGIJA"' },
  { code: 300870869, name: 'UAB "DEVERUS"' },
  { code: 300870965, name: 'Akcinė bendrovė "Dastros medis"' },
  { code: 300878556, name: 'UAB "Liumi"' },
  { code: 300882736, name: 'IĮ "Domigna"' },
  { code: 300883610, name: 'UAB "Sigito baldai"' },
  { code: 300883756, name: 'UAB "Pranciškaus staliai"' },
  { code: 300886777, name: 'UAB "ROKVILIS"' },
  { code: 300886859, name: 'UAB "Wienerberger statybinė keramika"' },
  { code: 300886880, name: "UAB AGROETANOLIS" },
  { code: 300887676, name: 'IĮ "Arlinos baldai"' },
  { code: 300887943, name: 'UAB "Kauno logistika"' },
  { code: 300892709, name: 'Žemės ūkio bendrovė "AGRARA MJR"' },
  { code: 300895598, name: 'UAB "Topkodas"' },
  { code: 300895609, name: 'UAB "Stiklo magija"' },
  { code: 300899696, name: 'UAB "Dirbtinis akmuo"' },
  { code: 300902683, name: 'UAB "Sbetonas"' },
  { code: 300904691, name: 'UAB "Utenos betonas"' },
  { code: 300905583, name: 'UAB "Rąstinukai Jums"' },
  { code: 300908533, name: 'UAB "ISP BALTIC"' },
  { code: 300909628, name: 'UAB "United Baltic Trade"' },
  { code: 300911700, name: "IĮ BUDGINAS IR KO" },
  { code: 300916537, name: 'UAB "LIDO MARINE"' },
  { code: 300917824, name: 'IĮ "Forsaita"' },
  { code: 300931681, name: 'UAB "Frinab NMF"' },
  {
    code: 300945535,
    name: 'Uždaroji akcinė bendrovė "ALTERNATYVŪS BALDŲ SPRENDIMAI"'
  },
  { code: 300958575, name: "S. Ivonciaus IĮ" },
  { code: 300959549, name: 'UAB "HVAC Baltic"' },
  { code: 300959588, name: 'UAB "Miglūnas"' },
  { code: 300959606, name: 'UAB "SCAN SORLIE BALTIC"' },
  { code: 300960583, name: 'UAB "Vilniaus betono gaminiai"' },
  { code: 300960672, name: 'UAB "Elamas"' },
  { code: 300970606, name: 'UAB "SIBO PAKUOTĖS"' },
  { code: 300974533, name: 'UAB "Arieva"' },
  { code: 300974540, name: 'UAB "DAGYTA"' },
  { code: 300976705, name: 'UAB "Lido Marine Furniture"' },
  { code: 300989574, name: 'UAB "Vatlit"' },
  { code: 301005793, name: 'IĮ "Baldama"' },
  { code: 301007552, name: 'UAB "Plasteda"' },
  { code: 301011643, name: 'UAB "AP-EKO"' },
  { code: 301019775, name: 'IĮ "KOLIBRI ART"' },
  { code: 301033547, name: 'UAB "NG projektai"' },
  { code: 301049544, name: 'UAB "BIO & KOM"' },
  { code: 301057637, name: 'UAB "Newline Furniture"' },
  { code: 301060551, name: 'UAB "Jonglė"' },
  { code: 301064539, name: 'UAB "Rusnaitės žuvis"' },
  { code: 301064546, name: 'IĮ "Viktema"' },
  { code: 301086044, name: 'UAB "ŽIEŽMARIŲ MĖSA"' },
  { code: 301088547, name: 'UAB "ELTAYEB"' },
  { code: 301089599, name: 'UAB "IMANA"' },
  { code: 301089649, name: 'UAB "Meno gija"' },
  { code: 301089656, name: 'UAB "ESD baldai"' },
  { code: 301095538, name: 'UAB "Meplast"' },
  { code: 301098712, name: 'UAB "Antriteksa"' },
  { code: 301098737, name: 'UAB "Lekemė"' },
  { code: 301102616, name: 'UAB "AZETAS"' },
  { code: 301105562, name: 'UAB "DIMENTA"' },
  { code: 301106778, name: "Č. Rimkaus IĮ" },
  { code: 301126791, name: 'UAB "BALTLITA"' },
  { code: 301128575, name: 'UAB "Laiptera"' },
  { code: 301133985, name: 'UAB "Vizujus ir partneriai"' },
  { code: 301134012, name: 'UAB "ELMER"' },
  { code: 301142571, name: 'UAB "Aukristė"' },
  { code: 301142639, name: 'IĮ "Lisonova"' },
  { code: 301147807, name: 'IĮ "JUNEX ENGINEERING"' },
  { code: 301149911, name: 'UAB "Wood Windows"' },
  { code: 301150568, name: "Idex Taikos elektrinė UAB" },
  { code: 301150664, name: 'UAB "Brolis timber"' },
  { code: 301150671, name: 'UAB "RaitWood"' },
  { code: 301151848, name: 'UAB "Deimera"' },
  { code: 301152003, name: 'UAB "SVENHEIM"' },
  { code: 301152544, name: 'UAB "B-SET"' },
  { code: 301162588, name: 'UAB "Gularis"' },
  { code: 301163569, name: 'UAB "Robitoma"' },
  { code: 301168655, name: 'UAB "IN FLEXUM"' },
  { code: 301169796, name: 'UAB "VYRIS"' },
  { code: 301170720, name: 'UAB "JANTORA"' },
  { code: 301170827, name: 'UAB "ASAGVA"' },
  { code: 301171249, name: 'IĮ "Saurolita"' },
  { code: 301174583, name: 'IĮ "Dalduva"' },
  { code: 301188572, name: 'UAB "Vinconos medis"' },
  { code: 301196537, name: 'UAB "GINTAU"' },
  { code: 301206650, name: 'UAB "OPTODECOR"' },
  { code: 301206732, name: "ABS EUROPE LTD. filialas" },
  { code: 301227559, name: 'UAB "Navistas"' },
  { code: 301240738, name: '"AG Baltic", UAB' },
  { code: 301247533, name: 'UAB "SKP stiklas"' },
  { code: 301249865, name: 'UAB "Ariogalos statybinės konstrukcijos"' },
  { code: 301250554, name: 'UAB "ABL"' },
  { code: 301272764, name: 'Viešoji įstaiga "Elektros ABC"' },
  { code: 301275536, name: 'UAB "Hyperis"' },
  { code: 301275582, name: 'UAB "Uostamiesčio medis"' },
  { code: 301276531, name: "UAB Gren Klaipėda" },
  { code: 301319566, name: 'UAB "AZ Baldai"' },
  { code: 301355530, name: 'UAB "SEORA"' },
  { code: 301385534, name: 'MB "Eukesta"' },
  { code: 301405549, name: 'UAB "PVD"' },
  { code: 301432539, name: 'Viešoji įstaiga "Iron X"' },
  { code: 301440532, name: 'UAB "BALTIC ENERGY GROUP"' },
  { code: 301472537, name: 'UAB "Jachtų sala"' },
  { code: 301489732, name: 'UAB "MAZUS"' },
  { code: 301490542, name: 'UAB "Momblan"' },
  { code: 301498667, name: 'UAB "RIDVALDA"' },
  { code: 301498973, name: 'UAB "Neonita - Reklama"' },
  { code: 301505631, name: 'UAB "BS Classic"' },
  { code: 301505859, name: 'UAB "Bomesa"' },
  { code: 301506078, name: 'UAB "Iter meum"' },
  { code: 301509626, name: 'UAB "Reval MBG"' },
  { code: 301519549, name: 'UAB "Ekovalda"' },
  { code: 301526636, name: 'UAB "Sauliaus mediena"' },
  { code: 301530556, name: 'UAB "Kietųjų detalių dirbtuvės"' },
  { code: 301530570, name: 'UAB "Termoplasta"' },
  { code: 301531672, name: 'UAB "Bresta"' },
  { code: 301532991, name: 'UAB "RETRUM"' },
  { code: 301533285, name: 'IĮ "Dažera"' },
  { code: 301534622, name: "Magnaplast Sp. z o.o. Kauno filialas" },
  { code: 301534686, name: 'UAB "VD PACK"' },
  { code: 301534693, name: 'UAB "NV kavinė"' },
  { code: 301537077, name: 'UAB "POLIBARR"' },
  { code: 301537305, name: 'UAB "Liuks farmacija"' },
  { code: 301537376, name: 'UAB "Minora"' },
  { code: 301538122, name: 'UAB "Wenge"' },
  { code: 301538186, name: 'IĮ "Fil Collection"' },
  { code: 301538649, name: 'UAB "Alfa Private"' },
  { code: 301540625, name: 'Uždaroji akcinė bendrovė "Grūdmalta"' },
  { code: 301540739, name: 'UAB "KGMETA"' },
  { code: 301540867, name: 'UAB "Vigada"' },
  { code: 301541079, name: 'UAB "GFORMA"' },
  { code: 301541741, name: 'UAB "Mardanas"' },
  { code: 301544545, name: 'UAB "Lukona"' },
  { code: 301549599, name: 'UAB "Autoris"' },
  { code: 301549738, name: "SLT Industrial Components, UAB" },
  { code: 301554596, name: "UAB FEZ Trade Solutions" },
  { code: 301554607, name: "UAB FEZ Business Management" },
  { code: 301555591, name: 'UAB "Kaunas FEZ Business Development"' },
  { code: 301560649, name: 'UAB "Faber klasika"' },
  { code: 301566716, name: 'UAB "Vindas"' },
  { code: 301567679, name: 'UAB "VALDEKAS"' },
  { code: 301582542, name: 'IĮ "MONTERIS"' },
  { code: 301585823, name: 'UAB "MENO KALVĖ"' },
  { code: 301592903, name: 'UAB "Stiklo arka"' },
  { code: 301592942, name: 'UAB "Solo namas"' },
  { code: 301593670, name: 'UAB "ALPEKS"' },
  { code: 301595632, name: 'UAB "Grankuras"' },
  { code: 301608649, name: 'UAB "Metmega"' },
  { code: 301608994, name: 'UAB "LUMILITA"' },
  { code: 301611531, name: 'UAB "Gaura baldai"' },
  { code: 301616539, name: 'UAB "SOTI Union"' },
  { code: 301628783, name: "K-UNI BALTIC, UAB" },
  { code: 301635692, name: 'UAB "Brolenta"' },
  { code: 301645583, name: 'UAB "SVEDELITA"' },
  { code: 301645601, name: 'UAB "Ėglius"' },
  { code: 301645697, name: 'IĮ "KURPIUS"' },
  { code: 301672694, name: 'UAB "PolyPack Vilnius"' },
  { code: 301673038, name: 'UAB "Furniture4you"' },
  { code: 301673515, name: 'UAB "Grėjas"' },
  { code: 301673604, name: 'UAB "Mivona"' },
  { code: 301673668, name: 'UAB "FurniOks"' },
  { code: 301673839, name: 'UAB "BALDVIKA"' },
  { code: 301673928, name: 'UAB "Malsena plius"' },
  { code: 301674478, name: 'MB "RERIDA"' },
  { code: 301675719, name: 'UAB "Spalvų tema"' },
  { code: 301676618, name: 'UAB "RAIVINTA"' },
  { code: 301678590, name: 'UAB "UV Statyba"' },
  { code: 301694566, name: "UAB Timber Cabins" },
  { code: 301694687, name: 'UAB "NS Trading"' },
  { code: 301695903, name: 'IĮ "Medžio era"' },
  { code: 301696898, name: 'UAB "SONAVITA"' },
  { code: 301697014, name: 'UAB "Nameliukas"' },
  { code: 301699531, name: 'UAB "AG Electric"' },
  { code: 301699702, name: 'UAB "Altimas"' },
  { code: 301724582, name: "I. Unguvaitienės IĮ" },
  { code: 301724618, name: 'UAB "ALUNGU"' },
  { code: 301724632, name: 'UAB "ENERGO VENTUM"' },
  { code: 301724664, name: 'UAB "Revale"' },
  { code: 301725531, name: 'UAB "Jokra"' },
  { code: 301726537, name: "R. Pakalniškienės IĮ" },
  { code: 301727692, name: 'UAB "Projektas SOLO"' },
  { code: 301729832, name: 'UAB "AVORO"' },
  { code: 301729946, name: 'UAB "EKOSTAT"' },
  { code: 301730724, name: 'UAB "NAPC"' },
  { code: 301732173, name: "UAB Kvapų namai" },
  { code: 301732198, name: 'UAB "BRB DECO"' },
  { code: 301732312, name: 'UAB "Ajala"' },
  { code: 301733396, name: "UAB MASSIVE WOOD CONSTRUCTION" },
  { code: 301733421, name: 'UAB "Lino apdaila"' },
  { code: 301733827, name: "UAB BUCI" },
  { code: 301734078, name: 'UAB "VYVA"' },
  { code: 301734167, name: 'Individuali įmonė "ZigMak"' },
  { code: 301735664, name: 'UAB "BALDŽIAI"' },
  { code: 301735721, name: 'UAB "DEVIVA"' },
  { code: 301735753, name: 'UAB "PG FOOD"' },
  { code: 301735785, name: 'UAB "INPEKO"' },
  { code: 301736549, name: 'UAB "AG baldai"' },
  { code: 301736702, name: 'UAB "EDUCATIO"' },
  { code: 301737697, name: 'IĮ "Paslauguva"' },
  { code: 301737779, name: 'UAB "VERS DEKO"' },
  { code: 301737989, name: 'UAB "Econdo Production LT"' },
  { code: 301738653, name: 'UAB "VD projektai"' },
  { code: 301741368, name: 'UAB "DMS gama"' },
  { code: 301741521, name: "Dainiaus Petkausko individuali įmonė" },
  { code: 301741959, name: 'UAB "AMBER FOREST"' },
  { code: 301743636, name: 'IĮ "Daimanas JBG"' },
  { code: 301744542, name: 'UAB "Trinkelių mozaika"' },
  { code: 301785585, name: 'UAB "S2S"' },
  { code: 301790582, name: 'UAB "Edveina"' },
  { code: 301791798, name: 'UAB "Klaipėdos eglė"' },
  { code: 301794755, name: 'UAB "PPR Investicijos"' },
  { code: 301806702, name: 'UAB "TOP SPOT"' },
  { code: 301815605, name: 'UAB "AleksAnn"' },
  { code: 301817830, name: 'UAB "Regvėja"' },
  { code: 301818042, name: 'UAB "Aradas"' },
  { code: 301819621, name: "Enefit Wind, UAB" },
  { code: 301821540, name: 'UAB "STRAVE BATAI"' },
  { code: 301824682, name: "UAB LZT" },
  { code: 301835682, name: 'UAB "Provectus"' },
  { code: 301836535, name: 'UAB "Kuteka"' },
  { code: 301841703, name: 'UAB "Akmatex"' },
  { code: 301841984, name: 'UAB "SULTENIS"' },
  { code: 301842837, name: 'UAB "DSF"' },
  { code: 301842926, name: 'UAB "Nordfenster"' },
  { code: 301843597, name: 'UAB "SOLEMLUX"' },
  { code: 301844375, name: 'UAB "RBNT"' },
  {
    code: 301845694,
    name: 'Uždaroji akcinė bendrovė "Stiklita Šiauliai"'
  },
  { code: 301846604, name: "UAB Komunalinių paslaugų centras" },
  { code: 301850773, name: 'UAB "REMKA"' },
  { code: 301851590, name: 'UAB "Senosios Varėnės HE"' },
  { code: 301851633, name: 'UAB "Demedos stiklas"' },
  { code: 301852692, name: "TOKĖ LT, UAB" },
  { code: 301879554, name: 'UAB "Sveikas vyras"' },
  { code: 301880542, name: 'IĮ "Marakis"' },
  { code: 301881772, name: 'UAB "Nerijaus baldai"' },
  { code: 301886577, name: 'UAB "BAV SERVISAS"' },
  { code: 301886794, name: 'IĮ "Petrov12"' },
  { code: 301887572, name: 'UAB "KITAIP"' },
  { code: 301901538, name: 'UAB "Dendrus"' },
  { code: 301911557, name: 'IĮ "VIP BALDAI"' },
  { code: 301925585, name: 'UAB "GSV Group"' },
  { code: 301928574, name: 'UAB "Eco Manta Pak"' },
  { code: 301934545, name: 'UAB "Pakavimo sprendimai"' },
  { code: 301945538, name: 'UAB "Skylių gręžimas"' },
  { code: 301966607, name: 'UAB "GILDENTA"' },
  { code: 302031563, name: 'UAB "ALOBERA MEDIENA"' },
  { code: 302036546, name: 'UAB "Jonvida"' },
  { code: 302041600, name: 'UAB "SGV technologijos"' },
  { code: 302091592, name: 'UAB "Light Wind"' },
  { code: 302097549, name: 'UAB "Dominati"' },
  { code: 302198530, name: 'UAB "Senasis malūnas"' },
  { code: 302240730, name: 'UAB "Mantmeda"' },
  { code: 302240755, name: 'UAB "POROLONAS"' },
  { code: 302243580, name: 'UAB "SG Production"' },
  { code: 302245535, name: 'UAB "Jėgainių parkas"' },
  { code: 302245542, name: 'UAB "Vejerga"' },
  { code: 302245567, name: 'UAB "Vejinta"' },
  { code: 302247689, name: 'Uždaroji akcinė bendrovė "TAKI"' },
  { code: 302248143, name: 'UAB "Hydro2"' },
  { code: 302248481, name: 'UAB "Dubysos vėjas"' },
  { code: 302248499, name: 'UAB "Clemence Richard Furniture"' },
  { code: 302248809, name: 'UAB "NIKMILA"' },
  { code: 302249074, name: 'UAB "VENTA LT"' },
  { code: 302249277, name: 'UAB "GENERGA"' },
  { code: 302249462, name: "ARDEX SKANDINAVIA A/S atstovybė" },
  { code: 302250500, name: 'UAB "Ekopartneris"' },
  { code: 302250532, name: 'UAB "Fanerukė LT"' },
  { code: 302250735, name: 'UAB "Indigo projektai"' },
  { code: 302251100, name: 'UAB "OLKORA"' },
  { code: 302251762, name: 'IĮ "Ąžuolo namai"' },
  { code: 302251958, name: 'UAB "Kegesa"' },
  { code: 302254178, name: 'UAB "CASTRADE INDUSTRY"' },
  { code: 302254662, name: 'UAB "Faktumas"' },
  { code: 302276609, name: 'UAB "Domonas"' },
  { code: 302278574, name: 'UAB "Bugusta"' },
  { code: 302281588, name: 'UAB "DECORATUS"' },
  { code: 302283532, name: 'UAB "GINRIM"' },
  { code: 302290683, name: 'Individuali įmonė "ILLIANA"' },
  { code: 302290719, name: 'UAB "Vilniaus karjerai"' },
  { code: 302290758, name: 'UAB "Bionova Lt"' },
  { code: 302291155, name: 'UAB "Tauro šėlsmas"' },
  { code: 302291162, name: 'UAB "Energo strategija"' },
  { code: 302291187, name: 'UAB "Tauraganas"' },
  { code: 302295890, name: 'UAB "AMKODOR BALTIC"' },
  { code: 302295901, name: 'UAB "AIZ"' },
  { code: 302296501, name: 'UAB "Baltic Wind Group"' },
  { code: 302297304, name: 'UAB "MEDŽIO SILUETAI"' },
  { code: 302298413, name: "UAB Panemunės veterinarija" },
  { code: 302299401, name: 'UAB "Commercial transport service"' },
  { code: 302301254, name: 'UAB "Klaipėdos biokuras"' },
  { code: 302301724, name: "UAB RAMZO" },
  { code: 302302139, name: 'UAB "MATO LT"' },
  { code: 302302826, name: 'UAB "Neptune Services"' },
  { code: 302302833, name: 'UAB "Klaipėdos miesto tvarka"' },
  { code: 302303383, name: 'UAB "Alorum"' },
  { code: 302305053, name: 'UAB "Norwegian cabins"' },
  { code: 302305480, name: 'ŽŪB "GENERAL FEED TECHNOLOGIES"' },
  { code: 302305815, name: 'UAB "LITEM"' },
  { code: 302306671, name: 'BENDRUOMENĖ "ŠLYNINKOS VANDENS MALŪNAS"' },
  { code: 302306949, name: 'UAB "Proracing"' },
  { code: 302308245, name: 'UAB "Langų parkas"' },
  { code: 302308423, name: "GYVA KOSMETIKA, UAB" },
  { code: 302308868, name: 'UAB "Puikus pasirinkimas"' },
  { code: 302309429, name: 'UAB "Dvarų keramika"' },
  { code: 302310545, name: 'UAB "Šilutės geoterma"' },
  { code: 302311031, name: 'UAB "Vudva & Co"' },
  { code: 302311686, name: 'UAB "LAIPTŲ FABRIKAS"' },
  { code: 302315282, name: 'UAB "Pamario jėgainės"' },
  { code: 302315752, name: "UAB AQUA BONA" },
  { code: 302316441, name: "UAB Vertikalus lūžis" },
  { code: 302316555, name: 'UAB "Navigator Group"' },
  { code: 302316701, name: 'UAB "Siguldos baldai"' },
  { code: 302317333, name: 'UAB "KEIGRA"' },
  { code: 302320386, name: 'IĮ "MESTERIS"' },
  { code: 302320429, name: 'UAB "SEDARAS"' },
  { code: 302323507, name: 'UAB "Kesi"' },
  { code: 302324534, name: 'UAB "Minties gija"' },
  { code: 302327288, name: 'UAB "Celekta"' },
  { code: 302327402, name: 'Uždaroji akcinė bendrovė "MANTISA"' },
  { code: 302328276, name: 'UAB "BALT FURN"' },
  { code: 302328760, name: 'UAB "Litvudas"' },
  { code: 302329015, name: 'UAB "Aros Marine"' },
  { code: 302329061, name: 'UAB "Grigeo Packaging"' },
  { code: 302329385, name: 'UAB "RANK3"' },
  { code: 302329727, name: 'UAB "POLAS"' },
  { code: 302330373, name: 'UAB "ADEKO"' },
  { code: 302330633, name: 'UAB "ODA LT"' },
  { code: 302330918, name: '"SAWAS", UAB' },
  { code: 302330957, name: 'UAB "Surenkamos statybos konstrukcijos"' },
  { code: 302331023, name: 'Kooperatinė bendrovė "Minijos lanka"' },
  { code: 302331817, name: 'Kooperatyvas "Gera mėsa"' },
  { code: 302331831, name: 'UAB "BALTIC STICKS"' },
  { code: 302332883, name: 'UAB "GL business"' },
  { code: 302333572, name: 'UAB "TANKOS"' },
  { code: 302334012, name: 'UAB "DONAPAKAS"' },
  { code: 302334261, name: 'UAB "Raska wood"' },
  { code: 302334788, name: 'UAB "IMRENA"' },
  { code: 302334877, name: 'UAB "Bonus modus"' },
  { code: 302335032, name: 'UAB "VITAINTERNATIONAL"' },
  { code: 302335107, name: '"AZ-Pack", UAB' },
  { code: 302337325, name: "M. Klenovskij IĮ" },
  { code: 302337994, name: 'UAB "Gintarinis žirgas"' },
  { code: 302338014, name: 'UAB "Deividas ir KO"' },
  { code: 302338427, name: 'UAB "ACEO"' },
  { code: 302338434, name: 'UAB "AUTOGEDAS"' },
  { code: 302339109, name: 'UAB "PVA baldai"' },
  { code: 302340517, name: 'UAB "Vėjo parkai"' },
  { code: 302341284, name: '"Marine Surveys & Services", UAB' },
  { code: 302341900, name: 'UAB "Olidėja"' },
  { code: 302342653, name: 'UAB "Barocco linija"' },
  { code: 302342895, name: 'VšĮ "Perspektus"' },
  { code: 302343075, name: 'UAB "EKURAS"' },
  { code: 302343214, name: 'UAB "Pellets Group"' },
  { code: 302343673, name: 'UAB "Autoplasta"' },
  { code: 302345514, name: 'UAB "Eviralda"' },
  { code: 302345635, name: 'UAB "Nagrita"' },
  { code: 302347141, name: 'UAB "LRS agentūra"' },
  { code: 302347369, name: 'UAB "FURNITERA LT"' },
  { code: 302350924, name: 'UAB "Kalsto gaminiai"' },
  { code: 302357267, name: 'UAB "Vesvela"' },
  { code: 302360256, name: 'UAB "GRANDE STILE"' },
  { code: 302381268, name: 'UAB "Nermėsa"' },
  { code: 302382256, name: 'UAB "SJBT group"' },
  { code: 302409301, name: 'UAB "WINDLITA"' },
  { code: 302409536, name: "UAB HCTC" },
  { code: 302410129, name: 'UAB "BS Chemical"' },
  { code: 302410321, name: 'UAB "Baltic food partners"' },
  { code: 302412482, name: 'UAB "RE-notas"' },
  { code: 302412557, name: 'UAB "Vėjo nauda"' },
  { code: 302412614, name: 'UAB "Vėjininkystė"' },
  { code: 302413260, name: 'UAB "Brolis"' },
  {
    code: 302413926,
    name: "CHINA OCEAN ENGINEERING CORPORATION Klaipėdos filialas"
  },
  { code: 302415133, name: 'UAB "L-VĖJAS"' },
  { code: 302415521, name: 'UAB "MELIONAS"' },
  { code: 302416107, name: 'UAB "Langų gama"' },
  { code: 302416331, name: 'UAB "Pamario jėgainių energija"' },
  { code: 302416349, name: 'UAB "Litoplast"' },
  { code: 302419434, name: 'UAB "Ergonomas"' },
  { code: 302420059, name: 'UAB "Reflectors"' },
  { code: 302421022, name: 'UAB "Gobermeda"' },
  { code: 302421168, name: 'UAB "ECO FUEL SOLUTIONS"' },
  { code: 302421339, name: "AFRIDO INVEST, UAB" },
  { code: 302421816, name: 'UAB "Poliplastikas"' },
  { code: 302422373, name: 'UAB "Teblius"' },
  { code: 302423379, name: 'Uždaroji akcinė bendrovė "PETGAMA"' },
  { code: 302423404, name: 'UAB "RYNO"' },
  { code: 302423735, name: 'UAB "Sakvoja"' },
  { code: 302423824, name: 'UAB "BALTIK IBERIKA"' },
  { code: 302424132, name: 'UAB "Viva Sofa"' },
  { code: 302424634, name: 'UAB "Betono linija"' },
  { code: 302424958, name: 'UAB "MODEMI"' },
  {
    code: 302425330,
    name: 'UAB "Baltic Technical & Technological Solutions"'
  },
  { code: 302426133, name: 'UAB "P&C Regale"' },
  { code: 302427000, name: 'UAB "Energetikos išteklių valdymas"' },
  { code: 302427751, name: 'UAB "Tadesa"' },
  { code: 302427769, name: 'UAB "Inepas"' },
  { code: 302427986, name: 'UAB "DOMO VERSLAS"' },
  { code: 302428472, name: 'UAB "Šilutės energija"' },
  { code: 302428586, name: 'UAB "Stikloporas"' },
  { code: 302428604, name: 'UAB "Žemaitijos ekspresas"' },
  { code: 302428846, name: 'UAB "Rytų partneris"' },
  { code: 302430459, name: 'UAB "SMV projektai"' },
  { code: 302430879, name: 'UAB "Dailės prekės"' },
  { code: 302431778, name: 'UAB "Tango laverna"' },
  { code: 302432638, name: "UAB Arto baldai" },
  { code: 302432862, name: 'IĮ "PELENĖS BALDAI"' },
  { code: 302433715, name: 'UAB "EMKO"' },
  { code: 302433804, name: 'UAB "Telšių vėjas"' },
  { code: 302433836, name: 'UAB "RĖMGAMA"' },
  { code: 302433932, name: 'UAB "ENIM"' },
  { code: 302434062, name: 'UAB "Avastata"' },
  { code: 302434468, name: 'IĮ "Jusepa"' },
  { code: 302434870, name: 'UAB "Aluonos grūdai"' },
  { code: 302435278, name: 'UAB "Microlanas"' },
  { code: 302435381, name: 'UAB "GJ baldai"' },
  { code: 302440414, name: "UAB VLI TIMBER" },
  { code: 302440567, name: 'UAB "Estuba"' },
  { code: 302441480, name: 'UAB "GOOD HOUSE"' },
  { code: 302441516, name: 'UAB "Open Dream"' },
  { code: 302442696, name: 'UAB "Medienos era LT"' },
  {
    code: 302442739,
    name: "AOP Orphan Pharmaceuticals GmbH atstovybė Baltijos šalims"
  },
  { code: 302442746, name: "HOME GROUP, UAB" },
  { code: 302443150, name: 'UAB "Giedrys"' },
  { code: 302443734, name: 'UAB "Sembalita"' },
  { code: 302444010, name: 'UAB "ANVY"' },
  { code: 302444569, name: 'UAB "BALTIC SUN ENERGY"' },
  { code: 302444640, name: 'UAB "Žvirgždaičių energija"' },
  { code: 302445297, name: "UAB EKLG ENERGIJA" },
  { code: 302445379, name: "UAB SOLTEC Europe Industries" },
  { code: 302446374, name: 'UAB "Ecso"' },
  { code: 302447526, name: 'UAB "Dailsa"' },
  { code: 302447750, name: 'UAB "Adoris"' },
  { code: 302447953, name: 'UAB "Carttara"' },
  { code: 302448998, name: 'UAB "MIŠKO MEDIS"' },
  { code: 302449025, name: 'UAB "PUODYNKIEMIS"' },
  { code: 302449139, name: "UAB Šeduvos šilas" },
  { code: 302450629, name: 'UAB "Laimos baldai"' },
  { code: 302452612, name: 'UAB "Foksita"' },
  { code: 302453034, name: 'UAB "Auminga"' },
  { code: 302453212, name: 'UAB "Vėjoteka"' },
  { code: 302453881, name: 'UAB "Atsinaujinantieji ištekliai"' },
  { code: 302454360, name: 'Uždaroji akcinė bendrovė "EKO PIRK"' },
  { code: 302455633, name: 'UAB "GBS ENERGY"' },
  { code: 302456888, name: 'UAB "AVERTON"' },
  { code: 302459119, name: 'UAB "VĖJO BANGA"' },
  { code: 302460242, name: 'UAB "ANI plast"' },
  { code: 302460783, name: 'UAB "Gargždų pramogos"' },
  { code: 302461344, name: 'UAB "Bravion studio"' },
  { code: 302462040, name: 'UAB "Kėdainių vėjas"' },
  { code: 302462357, name: 'UAB "Energeta"' },
  { code: 302462396, name: 'UAB "Žaliasis vėjas"' },
  { code: 302462866, name: 'UAB "Litprint"' },
  { code: 302464803, name: 'UAB "Vikapak"' },
  { code: 302465321, name: 'UAB "Baltic fish export"' },
  { code: 302466334, name: 'UAB "EKO ENERGIJA"' },
  { code: 302466633, name: 'UAB "Pamario altergrana"' },
  { code: 302467450, name: 'UAB "Navko"' },
  { code: 302467888, name: 'UAB "Tamarija"' },
  { code: 302468029, name: 'UAB "M&T Energy"' },
  { code: 302469266, name: 'UAB "Neovida"' },
  { code: 302469437, name: 'UAB "Korsara"' },
  { code: 302469519, name: 'UAB "VAMĖSA"' },
  { code: 302469743, name: 'UAB "Linasta LT"' },
  { code: 302470133, name: 'UAB "R&R IDEAS"' },
  { code: 302470343, name: 'UAB "EKO ENERGIJA-1"' },
  { code: 302470382, name: 'UAB "Sprendimai verslo plėtrai"' },
  { code: 302470966, name: "MACGREGOR BLRT BALTIC, UAB" },
  { code: 302471089, name: 'UAB "Ekoinversta"' },
  { code: 302471687, name: 'UAB "Energijos žara"' },
  { code: 302471897, name: 'UAB "Sedos jėgainė"' },
  { code: 302472052, name: 'UAB "AEnergija"' },
  { code: 302472508, name: 'UAB "Saulės jėgainė"' },
  { code: 302472682, name: 'UAB "Kretingos energija"' },
  { code: 302472942, name: 'UAB "MĖSINUKĖ"' },
  { code: 302472967, name: 'IĮ "Vladis"' },
  { code: 302473713, name: 'UAB "DORVIS"' },
  { code: 302475073, name: 'UAB "Medieva"' },
  { code: 302475568, name: 'UAB "LITWOOL"' },
  { code: 302475778, name: 'UAB "Surinketa"' },
  { code: 302476008, name: "HOLD Furniture, UAB" },
  { code: 302476570, name: 'UAB "My Land LT"' },
  { code: 302477380, name: 'UAB "Metvora"' },
  { code: 302477882, name: 'UAB "Danish Wood Windows"' },
  { code: 302477925, name: 'UAB "Dzūkijos popierius ir KO"' },
  { code: 302478176, name: 'UAB "Sėkmės malūnai"' },
  { code: 302478322, name: 'UAB "Baltic Stream"' },
  { code: 302479157, name: 'UAB "IP Projektai"' },
  { code: 302479552, name: 'UAB "Sunpower LT"' },
  { code: 302479748, name: 'UAB "Didžiosios Sėlos"' },
  { code: 302480330, name: 'UAB "PLUS Windows"' },
  { code: 302481368, name: "UAB SIB TRAKAI" },
  { code: 302481375, name: 'UAB "Solar LT"' },
  { code: 302481699, name: 'UAB "NARWELLA"' },
  { code: 302482349, name: 'UAB "Manvela"' },
  { code: 302482858, name: 'IĮ "Pagranda"' },
  { code: 302482993, name: 'UAB "Furniture masters"' },
  { code: 302483814, name: 'UAB "VEROSTA"' },
  { code: 302484040, name: 'UAB "Pamario baldų prekyba"' },
  { code: 302484752, name: 'UAB "Alyterma"' },
  { code: 302485509, name: 'UAB "ANVEJA"' },
  { code: 302485740, name: 'UAB "Denava"' },
  { code: 302486237, name: "UAB SALTUM" },
  { code: 302486461, name: 'UAB "Vėjo alėja"' },
  { code: 302487816, name: 'IĮ "RZ lentpjūvė"' },
  { code: 302487983, name: 'ŽŪB "Sėlynės agro"' },
  { code: 302488252, name: 'UAB "PELLETLINK"' },
  { code: 302488569, name: '"Skandinavijos langai", UAB' },
  { code: 302489144, name: 'UAB "Modeire"' },
  { code: 302490189, name: 'UAB "Krisminda"' },
  { code: 302490303, name: 'UAB "Versor"' },
  { code: 302491095, name: 'UAB "Lorizon Energy"' },
  { code: 302491558, name: 'UAB "Foto energija"' },
  { code: 302492813, name: 'UAB "SPR Vilnius"' },
  { code: 302492973, name: 'ŽŪB "Grosas agro"' },
  { code: 302493228, name: 'UAB "ART VETRO"' },
  { code: 302493420, name: 'UAB "Lietuvos prekybos kompanija"' },
  { code: 302493541, name: 'UAB "Stiklo gama"' },
  { code: 302493687, name: 'UAB "PANEVĖŽIO GALIS"' },
  { code: 302494248, name: 'UAB "Baltijos žuvies perdirbimas"' },
  { code: 302494294, name: 'UAB "Vėjo kalnas"' },
  { code: 302494860, name: 'UAB "Stiklorama"' },
  { code: 302495542, name: 'UAB "IPRIS"' },
  { code: 302495567, name: "UAB INNO SYSTEM" },
  { code: 302496765, name: 'UAB "DARBO BALDAI"' },
  { code: 302496772, name: 'UAB "BMT company"' },
  { code: 302498734, name: 'UAB "Vėjo pašvaistė"' },
  { code: 302498912, name: 'UAB "ADMEX"' },
  { code: 302498990, name: 'UAB "ENERGY SPARING SOLUTION"' },
  { code: 302499081, name: 'UAB "Strugas"' },
  { code: 302500562, name: 'UAB "MAX SOLAR ENERGY"' },
  { code: 302501002, name: "UAB Fire proof keramiks" },
  { code: 302501205, name: 'UAB "Vygriai"' },
  { code: 302501803, name: 'UAB "Tryškių Energija"' },
  { code: 302502054, name: 'UAB "Finavita"' },
  { code: 302502563, name: 'UAB "LANGVALDA"' },
  { code: 302502691, name: 'UAB "Green Power LT"' },
  { code: 302503035, name: 'UAB "Liepynai"' },
  { code: 302504016, name: 'UAB "EDJUSTA"' },
  { code: 302504525, name: 'UAB "Germinta"' },
  { code: 302505246, name: 'UAB "GD group"' },
  { code: 302505278, name: 'Uždaroji akcinė bendrovė "EKO MEGAVATAI"' },
  { code: 302505851, name: 'UAB "Šviesos energija"' },
  { code: 302506120, name: 'UAB "ALL group"' },
  { code: 302506330, name: 'UAB "TVILIS"' },
  { code: 302507101, name: 'UAB "Agronovus"' },
  { code: 302507457, name: 'UAB "Gamybos linija"' },
  { code: 302507902, name: 'UAB "ReSo"' },
  { code: 302508025, name: 'IĮ "Lapūnas"' },
  { code: 302508477, name: 'UAB "D&D GROUP"' },
  { code: 302508491, name: 'UAB "Maigų elektra"' },
  { code: 302508687, name: 'UAB "Lageras"' },
  { code: 302509440, name: 'UAB "Mokykla ir biuras"' },
  { code: 302513143, name: 'UAB "IGA LT"' },
  { code: 302513307, name: 'UAB "LAVADA"' },
  { code: 302513563, name: 'UAB "VDE energija"' },
  { code: 302514505, name: 'UAB "Šviesos greitis"' },
  { code: 302514786, name: 'UAB "Vėjo gėlė"' },
  { code: 302515977, name: "Karolio Pužausko IĮ" },
  { code: 302516972, name: 'UAB "Wood Market"' },
  { code: 302518507, name: "IĮ R&K baldų projektai" },
  { code: 302518521, name: 'UAB "BŪSTO FABRIKAS"' },
  { code: 302520294, name: 'UAB "Rojaus baldai"' },
  { code: 302520668, name: "UAB Blix LT" },
  { code: 302521777, name: 'UAB "Baltic Home"' },
  { code: 302521898, name: "UAB Betono produktai" },
  { code: 302523301, name: 'UAB "Iralit"' },
  { code: 302524734, name: 'UAB "RetroVilbaiva"' },
  { code: 302525220, name: 'UAB "BC Consulting"' },
  { code: 302526443, name: 'UAB "ELTINO"' },
  { code: 302530057, name: "Idex Paneriškių UAB" },
  { code: 302530580, name: 'UAB "Jogijus"' },
  { code: 302530819, name: 'UAB "FORMADETA"' },
  { code: 302530908, name: 'UAB "Crest LT"' },
  { code: 302531216, name: 'UAB "Helija sistemos"' },
  { code: 302532624, name: 'UAB "Ofus"' },
  { code: 302532656, name: 'UAB "Ecodomus"' },
  { code: 302533014, name: 'IĮ "Česvita"' },
  { code: 302533224, name: 'UAB "EVALDITA"' },
  { code: 302533270, name: "ABURABU, UAB" },
  { code: 302533338, name: "Amatų kalnelis, UAB" },
  { code: 302533466, name: 'UAB "PAULIS"' },
  { code: 302535296, name: 'UAB "AGRO LT"' },
  { code: 302536590, name: "VAIPOLIS, UAB" },
  { code: 302536786, name: 'UAB "Sairana"' },
  { code: 302537361, name: 'UAB "VĖJO DEMONAS"' },
  { code: 302537888, name: 'UAB "Galvės langai"' },
  { code: 302538538, name: 'UAB "Dzūkijos baldų fabrikas"' },
  { code: 302539095, name: 'UAB "Lacio"' },
  { code: 302539736, name: 'UAB "DONAVI"' },
  { code: 302540717, name: 'UAB "Socialiniai sprendimai"' },
  { code: 302540998, name: 'UAB "Industrial Investment International"' },
  { code: 302541541, name: 'UAB "Baltic Contracting Group"' },
  { code: 302542006, name: 'UAB "Kensa"' },
  { code: 302542629, name: '"Emus", UAB' },
  { code: 302542718, name: 'UAB "Biodinamika"' },
  { code: 302543097, name: 'UAB "Vėjūta"' },
  { code: 302544231, name: "Dariaus Rimonio IĮ" },
  { code: 302544313, name: 'UAB "NedLink"' },
  { code: 302545340, name: 'UAB "DEMAVA"' },
  { code: 302545447, name: "UAB VP TRADING" },
  { code: 302545657, name: "UAB OPS Baltic" },
  { code: 302546652, name: 'UAB "Igrita"' },
  { code: 302546741, name: 'UAB "Regera"' },
  { code: 302547619, name: 'UAB "Gyvybės paletė"' },
  { code: 302547989, name: "Karolio Kavaliausko individuali įmonė" },
  { code: 302548411, name: "AMBERWIND, UAB" },
  { code: 302548596, name: '"Invictus", UAB' },
  { code: 302550693, name: 'UAB "Ekovatas"' },
  { code: 302550864, name: '"EA Baltic", UAB' },
  { code: 302551375, name: 'UAB "Baldų projektai"' },
  { code: 302551382, name: "UAB Esmaks" },
  { code: 302551763, name: 'UAB "Bio wood"' },
  { code: 302551806, name: 'UAB "Sevada"' },
  { code: 302553668, name: 'UAB "J&R group"' },
  { code: 302553928, name: '"Swedcolor", UAB' },
  { code: 302554389, name: 'UAB "Elkesta"' },
  { code: 302555473, name: 'UAB "Juto Heat"' },
  { code: 302555765, name: 'UAB "Finalta"' },
  { code: 302555904, name: 'UAB "Baltijos virvės"' },
  { code: 302556194, name: 'UAB "ERELITA FURNITURE"' },
  { code: 302556290, name: 'UAB "EcoLotus"' },
  { code: 302556397, name: 'UAB "Vėjo srautas"' },
  { code: 302556486, name: 'UAB "Pamariukas"' },
  { code: 302556664, name: 'UAB "Lietwood"' },
  { code: 302557894, name: "UAB STRAPA" },
  { code: 302558448, name: 'UAB "Plasteks"' },
  { code: 302558989, name: 'UAB "Baldgama"' },
  { code: 302559016, name: 'UAB "Medmega"' },
  { code: 302559183, name: "NMF DECOR UAB" },
  { code: 302559849, name: 'UAB "BioGranulita"' },
  { code: 302559856, name: 'UAB "Dominari"' },
  { code: 302560132, name: '"Premium Oils", UAB' },
  { code: 302560171, name: "UAB Domosta" },
  { code: 302560673, name: 'UAB "Biohumusas"' },
  { code: 302560716, name: 'UAB "Kauno kailiai"' },
  { code: 302560869, name: 'UAB "Namai be dūmų"' },
  { code: 302560958, name: 'UAB "KOMPOSTA"' },
  { code: 302561227, name: 'UAB "Bio substratas"' },
  { code: 302561234, name: 'UAB "AD Wood"' },
  { code: 302561519, name: "UAB Utenos briketai" },
  { code: 302561622, name: 'UAB "Countryplast"' },
  { code: 302562254, name: "UAB R-vizija" },
  { code: 302562560, name: 'IĮ "Legarta"' },
  { code: 302563089, name: "AXON Production, UAB" },
  { code: 302563153, name: 'UAB "Kaisera"' },
  { code: 302563491, name: 'UAB "Laisvės baldai"' },
  { code: 302563712, name: 'UAB "Vogbera"' },
  { code: 302565268, name: '"LAIPTŲ STILIUS", UAB' },
  { code: 302565275, name: "IMPEXINVEST, UAB" },
  { code: 302565624, name: 'UAB "Woodhouses.lt"' },
  { code: 302566060, name: 'UAB "MSC Antakalnis"' },
  { code: 302566306, name: 'UAB "PRANCIŠKONIŲ PREKYBA"' },
  { code: 302566466, name: '"Dujus", UAB' },
  { code: 302566968, name: 'UAB "Garant Diving"' },
  { code: 302567333, name: 'Uždaroji akcinė bendrovė "MEDEIVĖ"' },
  { code: 302567529, name: "Gintaro Dedelos individuali įmonė" },
  { code: 302568111, name: 'UAB "KIBIRAS"' },
  { code: 302568798, name: 'UAB "Litprofit"' },
  { code: 302570870, name: 'UAB "Alytaus baldai"' },
  { code: 302571431, name: 'UAB "ECO PRODUCT"' },
  { code: 302573208, name: 'UAB "Meldina"' },
  { code: 302573592, name: '"Every Pet", UAB' },
  { code: 302573959, name: 'UAB "Garant Service"' },
  { code: 302575248, name: 'UAB "FRAME ART"' },
  { code: 302575433, name: 'IĮ "Rukla10"' },
  { code: 302576300, name: 'UAB "Šviesos gamyba"' },
  { code: 302576987, name: 'VšĮ "Nojė"' },
  { code: 302578205, name: 'UAB "F.B. Production"' },
  { code: 302579232, name: 'UAB "Kaniūkų gelžbetonis"' },
  { code: 302579969, name: 'UAB "STONKUS HOUSE"' },
  { code: 302580238, name: 'UAB "Enertonas"' },
  { code: 302580697, name: 'UAB "Stalvita"' },
  { code: 302582381, name: "UAB Geistera" },
  { code: 302582417, name: "UAB Trakena" },
  { code: 302584959, name: "UAB STIKLUDAS" },
  { code: 302585299, name: 'UAB "Baltic corundum"' },
  { code: 302586262, name: 'UAB "Satus Baltic"' },
  { code: 302586725, name: 'UAB "New House Factory"' },
  { code: 302586764, name: 'UAB "Baldų manufaktūra"' },
  { code: 302586999, name: '"Rodeta", UAB' },
  { code: 302588644, name: 'UAB "Soluva"' },
  { code: 302589162, name: 'UAB "Steka"' },
  { code: 302589600, name: 'UAB "SVEIKATA TAU"' },
  { code: 302589874, name: 'UAB "BALTFUR"' },
  { code: 302590129, name: 'V. Kalvelienės IĮ "Vijoklis"' },
  { code: 302591918, name: 'UAB "DukEn Link"' },
  { code: 302592468, name: 'UAB "Ekostartas"' },
  { code: 302592564, name: 'UAB "LANGANA"' },
  { code: 302592596, name: 'UAB "Solimeta"' },
  { code: 302593584, name: "UAB MEDIASTA" },
  { code: 302593997, name: 'UAB "CLASSmed"' },
  { code: 302594177, name: 'UAB "GLASSLT FIRE"' },
  { code: 302595806, name: 'UAB "Vitrėja"' },
  { code: 302596630, name: 'UAB "AML Baltic"' },
  { code: 302597084, name: 'UAB "NT Cargo"' },
  { code: 302597294, name: 'UAB "MGM Baltic"' },
  { code: 302597337, name: "IĮ DEXON" },
  { code: 302597508, name: 'UAB "Talasa"' },
  { code: 302597522, name: "UAB ECO IDEA" },
  { code: 302597547, name: 'UAB "MWood"' },
  { code: 302599263, name: 'UAB "Minijos telkinys"' },
  { code: 302599441, name: 'UAB "Žilveda"' },
  { code: 302599758, name: 'UAB "NamasteLT"' },
  { code: 302599861, name: "Perlite, UAB" },
  { code: 302600363, name: 'UAB "IPSU"' },
  { code: 302601020, name: 'UAB "Woodeka"' },
  { code: 302601465, name: 'UAB "ENERCONICS"' },
  { code: 302601643, name: 'IĮ "MEDELITA"' },
  { code: 302602243, name: 'UAB "Granulių linija"' },
  { code: 302602407, name: 'UAB "Senolių gardumynai"' },
  { code: 302603142, name: 'UAB "Sinvesta"' },
  { code: 302604187, name: 'UAB "LOGHOUSE PARADISE"' },
  { code: 302604657, name: "UAB GRID LT" },
  { code: 302604689, name: 'UAB "AMB Oils"' },
  { code: 302605257, name: "UAB BALTIC TRANSFORMER FURNITURE" },
  { code: 302605442, name: '"Medienos apsauga", UAB' },
  { code: 302605741, name: 'UAB "Tirova"' },
  { code: 302606042, name: 'UAB "MT Production"' },
  { code: 302608680, name: 'UAB "SYSTEMBYGG"' },
  { code: 302608762, name: 'UAB "ELANDRA"' },
  { code: 302609049, name: 'UAB "Ambeta"' },
  { code: 302609490, name: 'UAB "ARNISTA"' },
  { code: 302610012, name: 'UAB "Kumpeliukas"' },
  { code: 302611783, name: 'UAB "TAMING"' },
  { code: 302611833, name: 'UAB "Cool Master"' },
  { code: 302612084, name: 'UAB "Vakarų automatika"' },
  { code: 302613211, name: "UAB VIA AURUM" },
  { code: 302613898, name: "UAB Baltic ILP" },
  { code: 302614053, name: 'UAB "Ferti BIO"' },
  { code: 302614822, name: 'UAB "Bio Gamykla"' },
  { code: 302614861, name: 'UAB "Rineka"' },
  { code: 302615778, name: 'UAB "Eivuda LT"' },
  { code: 302616086, name: 'UAB "Spaudos artelė"' },
  { code: 302616353, name: 'UAB "Delikatesiniai gaminiai"' },
  { code: 302616442, name: 'UAB "Ekotopas"' },
  { code: 302617213, name: 'UAB "Tavla stalių gaminiai"' },
  { code: 302617576, name: 'UAB "Plasteksa"' },
  { code: 302617875, name: 'UAB "Alvigra"' },
  { code: 302619591, name: "UAB PALABA" },
  { code: 302620070, name: 'UAB "Vitaneda"' },
  { code: 302621724, name: 'UAB "Zenito investicijos"' },
  { code: 302622114, name: 'UAB "Semilita"' },
  { code: 302622502, name: 'UAB "NOTRUM"' },
  { code: 302622662, name: "UAB Addeco" },
  { code: 302624115, name: 'UAB "Hovden"' },
  { code: 302625288, name: 'UAB "Elektra iš vėjo"' },
  { code: 302625498, name: "UAB Draugiška energija" },
  { code: 302625555, name: 'UAB "Ginsona"' },
  { code: 302625683, name: 'UAB "Findėja"' },
  { code: 302626116, name: 'UAB "Arunita"' },
  { code: 302627093, name: 'UAB "Ferum LT"' },
  { code: 302627111, name: 'VšĮ "Gamtos pilnatvė"' },
  {
    code: 302627442,
    name: 'UAB "Alternatyvios energetikos įmonių grupė"'
  },
  { code: 302627741, name: "IĮ Medinera LT" },
  { code: 302628423, name: 'UAB "Mediniai baldai"' },
  { code: 302628569, name: 'UAB "RILWOOD"' },
  { code: 302628619, name: 'UAB "PUIKIO"' },
  { code: 302628875, name: "POS Holding Group, UAB" },
  { code: 302629055, name: 'UAB "ASTIN-INTERIOR"' },
  { code: 302629393, name: 'UAB "VG Agrogrūdai"' },
  { code: 302629799, name: 'UAB "Salmon LT"' },
  { code: 302630214, name: 'UAB "Biogranulių gamyba"' },
  { code: 302630812, name: "UAB Ambervit" },
  { code: 302631227, name: 'UAB "Deluxe baldai"' },
  { code: 302631259, name: 'UAB "Grasma"' },
  { code: 302631992, name: "UAB Pabalnotas vėjas" },
  { code: 302632432, name: 'UAB "Lantesa"' },
  { code: 302632514, name: 'UAB "Omni langai"' },
  { code: 302633420, name: 'UAB "Jominta"' },
  { code: 302634401, name: 'UAB "Baltic Firewood"' },
  { code: 302635282, name: 'UAB "Domantas"' },
  { code: 302635325, name: 'UAB "MEDIDOMUS"' },
  { code: 302636459, name: 'UAB "Katmedis"' },
  { code: 302636637, name: 'UAB "R&D CONSULTING"' },
  { code: 302636968, name: 'UAB "Bedfortas"' },
  { code: 302637059, name: 'UAB "Greimantas"' },
  { code: 302637155, name: 'UAB "Labenerji"' },
  { code: 302637447, name: 'UAB "FREZAVIMO CENTRAS"' },
  { code: 302638143, name: 'UAB "Barta LT"' },
  { code: 302638741, name: 'UAB "Ramena"' },
  { code: 302639067, name: 'UAB "Novus langai"' },
  { code: 302640831, name: 'UAB "MASUMA"' },
  { code: 302640863, name: "UAB ERASTA" },
  { code: 302641698, name: 'UAB "JDF AGRO"' },
  { code: 302642362, name: 'UAB "Resvitas"' },
  { code: 302642572, name: 'UAB "Nordic Idea"' },
  { code: 302642864, name: "UAB MARGININKŲ MEDIENA" },
  { code: 302643119, name: 'UAB "Vilniaus rafinavimo gamykla"' },
  { code: 302643457, name: 'UAB "Mobili lentpjūvė"' },
  { code: 302644267, name: 'UAB "Bajorų Kelias - 2 valdymas"' },
  { code: 302644274, name: 'UAB "Branda LT"' },
  { code: 302644349, name: "UAB WOOD PRO" },
  { code: 302645248, name: 'UAB "NordTub"' },
  { code: 302645390, name: 'UAB "TORNIS"' },
  { code: 302645981, name: 'UAB "Roventus"' },
  { code: 302646414, name: 'UAB "REPRO-PET"' },
  { code: 302648237, name: "UAB Litbyg" },
  { code: 302648276, name: "UAB Gamta ir energija" },
  { code: 302648707, name: 'AB "Ignitis gamyba"' },
  { code: 302648842, name: 'UAB "Vitosana"' },
  { code: 302649136, name: "UAB Kachel keramika" },
  { code: 302649200, name: 'UAB "Minkštų baldų idėja"' },
  { code: 302649289, name: 'UAB "FURLITA"' },
  { code: 302649442, name: 'UAB "Vėjo gama"' },
  { code: 302650124, name: 'UAB "Nausodės energija"' },
  { code: 302651297, name: "UAB Kviglis" },
  { code: 302651347, name: 'UAB "K Marine"' },
  { code: 302651532, name: 'UAB "Polymer recycling"' },
  { code: 302651703, name: 'UAB "Saulės šviesa"' },
  { code: 302651735, name: 'UAB "SMD Ekspedicija"' },
  { code: 302652602, name: "UAB PROTINGI BALDAI" },
  { code: 302653152, name: "JUST SIMPLE, UAB" },
  { code: 302653501, name: 'UAB "Grafo line"' },
  { code: 302654069, name: 'UAB "N&D TRADE"' },
  { code: 302655566, name: 'UAB "Megė"' },
  { code: 302656248, name: 'UAB "3 peaks Lithuania"' },
  { code: 302656351, name: 'UAB "PHE LT"' },
  { code: 302657026, name: 'UAB "GePo"' },
  { code: 302657357, name: "UAB Pelletpro" },
  { code: 302657414, name: 'UAB "ARBENA"' },
  { code: 302658014, name: 'UAB "Studija Sensus"' },
  { code: 302658092, name: 'UAB "AM SUPPLY"' },
  { code: 302658167, name: 'UAB "Pupt"' },
  { code: 302658181, name: 'UAB "Tera Bonum"' },
  { code: 302658199, name: 'UAB "Kalifėja"' },
  { code: 302658719, name: 'UAB "Ekoatliekos"' },
  { code: 302658847, name: 'UAB "JUNVA"' },
  { code: 302658982, name: 'UAB "RIEŠĖS BALDAI"' },
  { code: 302659205, name: 'UAB "Soft design"' },
  { code: 302660990, name: 'UAB "ESLERA"' },
  { code: 302661455, name: 'UAB "3D baldai"' },
  { code: 302661672, name: 'UAB "Kuršmedis"' },
  { code: 302662105, name: '"Logoplasta", UAB' },
  { code: 302663171, name: 'UAB "S-ENERGIJA"' },
  { code: 302663335, name: 'UAB "SUNERGO"' },
  { code: 302663794, name: 'UAB "Sun Electric"' },
  { code: 302664818, name: 'UAB "Vingauda"' },
  { code: 302665090, name: "BALTIC GOLD, UAB" },
  { code: 302665560, name: "FGK Europe, UAB" },
  { code: 302666616, name: "Vydmantai wind park, UAB" },
  { code: 302666947, name: 'UAB "Saulės metas"' },
  { code: 302667020, name: 'UAB "Burbulai"' },
  { code: 302667209, name: 'UAB "Baldų arsenalas"' },
  { code: 302667675, name: 'UAB "A&R paslaugos"' },
  { code: 302667682, name: 'UAB "Elektromobiliai"' },
  { code: 302668243, name: 'UAB "LITFIX"' },
  { code: 302668439, name: 'UAB "Kelmės vėjo energija"' },
  { code: 302668656, name: 'UAB "DGT Baldai"' },
  { code: 302668688, name: "UAB Mobelsnekker Professional" },
  { code: 302669822, name: 'UAB "Dragona"' },
  { code: 302670251, name: 'UAB "Vaidotų aptarnavimas"' },
  { code: 302670493, name: "B.P. Technology UAB" },
  { code: 302670550, name: 'UAB "Alksniškių energija"' },
  { code: 302670746, name: "UAB Roll Paper LT" },
  { code: 302670760, name: 'UAB "Egrupė"' },
  { code: 302671894, name: 'UAB "Pakvita"' },
  { code: 302672590, name: 'UAB "Iffa Biz"' },
  { code: 302672793, name: 'UAB "DOVEINA"' },
  { code: 302673895, name: "AUTOGEDAS&CO, UAB" },
  { code: 302674125, name: 'UAB "Medidėja"' },
  { code: 302674488, name: 'UAB "Grigiškių energija"' },
  { code: 302674837, name: 'UAB "Bioeka"' },
  { code: 302674869, name: 'UAB "Autofina"' },
  { code: 302675451, name: '"Rolls-Rolls LT", UAB' },
  { code: 302676133, name: 'UAB "Stakoda"' },
  { code: 302677897, name: 'UAB "Green Wood Products"' },
  { code: 302678383, name: "UAB VILGMA GI" },
  { code: 302679243, name: 'UAB "STIKLO KONSTRUKCIJOS"' },
  { code: 302679407, name: 'UAB "Tikri langai"' },
  { code: 302680021, name: 'UAB "DANGA LT"' },
  { code: 302680046, name: "UAB PROSYSTEMS" },
  { code: 302680295, name: 'UAB "Justeko"' },
  { code: 302680466, name: 'UAB "JUKIS"' },
  { code: 302680509, name: 'UAB "MS baldai"' },
  { code: 302680516, name: "Idex Pakalniškių UAB" },
  { code: 302680530, name: "Idex Taika UAB" },
  { code: 302680555, name: 'UAB "AGP Baltic"' },
  { code: 302680740, name: 'UAB "Domona"' },
  { code: 302680943, name: 'UAB "GinFa"' },
  { code: 302681041, name: 'UAB "EGLARTA"' },
  { code: 302681116, name: "UAB Oillitana" },
  { code: 302681319, name: 'UAB "Aditis"' },
  { code: 302681899, name: 'UAB "Balėnų MK"' },
  { code: 302682054, name: 'UAB "LINOR"' },
  { code: 302682563, name: 'UAB "Stakvijus"' },
  { code: 302682830, name: 'UAB "Litlaftas"' },
  { code: 302683010, name: 'UAB "GT energija"' },
  { code: 302683042, name: 'UAB "AUGLITA"' },
  { code: 302683195, name: 'UAB "Saflora"' },
  { code: 302683359, name: "Fenster, UAB" },
  { code: 302683932, name: 'UAB "Lorte studio"' },
  { code: 302684240, name: 'UAB "NOR trust"' },
  { code: 302684361, name: 'UAB "Baldorė"' },
  { code: 302684639, name: "UAB VALŲ MECHANINĖS DIRBTUVĖS" },
  { code: 302685214, name: 'UAB "Stagenta"' },
  { code: 302685449, name: 'UAB "AGBG"' },
  { code: 302685926, name: 'UAB "Promfactor Baltic"' },
  { code: 302686259, name: 'UAB "Energy Houses"' },
  { code: 302686394, name: 'UAB "FAVORICA"' },
  { code: 302687051, name: 'UAB "Vilniaus pakuotė"' },
  { code: 302687279, name: "Bio Green, UAB" },
  { code: 302687400, name: 'UAB "Sobivera"' },
  { code: 302687578, name: "ENWIPELLETS, UAB" },
  { code: 302687681, name: 'UAB "Saulės langai"' },
  { code: 302687884, name: 'UAB "Plastoera"' },
  { code: 302687927, name: 'Uždaroji akcinė bendrovė "ORNATAS"' },
  { code: 302688018, name: 'UAB "ALTERNATE HEAT"' },
  { code: 302688139, name: 'UAB "Ecohumus"' },
  { code: 302688178, name: "UAB DUONKIEMIS" },
  { code: 302688395, name: 'UAB "Landrosa"' },
  { code: 302688979, name: 'UAB "Primeco"' },
  { code: 302689223, name: "JANOLEX, UAB" },
  { code: 302689458, name: 'UAB "Ekograna"' },
  { code: 302690033, name: '"Baltic teak", UAB' },
  { code: 302690236, name: 'UAB "BIRMETA"' },
  { code: 302690460, name: "Green energy projects UAB" },
  { code: 302690656, name: 'UAB "WM Baltic"' },
  { code: 302690866, name: 'Z. Arbočiaus individuali įmonė "Vėjaratis"' },
  { code: 302691338, name: 'UAB "BALTIC GREEN TECHNOLOGY"' },
  { code: 302691555, name: 'UAB "Environ Composit"' },
  { code: 302691758, name: 'Uždaroji akcinė bendrovė "JORUSKO"' },
  { code: 302691822, name: 'UAB "Donitus"' },
  { code: 302691904, name: 'UAB "Plastigesta"' },
  { code: 302692269, name: 'UAB "Semita Solis"' },
  { code: 302692326, name: 'UAB "DROBĖS"' },
  { code: 302692632, name: "UAB Migusta" },
  { code: 302694252, name: "UAB Acomposites" },
  { code: 302694373, name: "Balttread, UAB" },
  { code: 302694448, name: 'UAB "Daujotas"' },
  { code: 302695315, name: 'UAB "Medinis pasaulis"' },
  { code: 302695614, name: "Mažeikių auto sporto klubas" },
  { code: 302696919, name: 'UAB "Kunionių rūkyklėlė"' },
  { code: 302697519, name: 'UAB "Ami langų remontas"' },
  { code: 302697615, name: 'UAB "Modepa"' },
  { code: 302698350, name: 'UAB "SOLARTIA"' },
  { code: 302698507, name: 'UAB "VĖJO SMŪGIS"' },
  { code: 302699274, name: 'UAB "Poliprofile"' },
  { code: 302699947, name: 'UAB "PROFLINE"' },
  { code: 302700189, name: 'UAB "BERŽO GAMINIAI"' },
  { code: 302700940, name: 'UAB "ISTI"' },
  { code: 302701120, name: 'UAB "Nt LT"' },
  { code: 302701946, name: 'UAB "Via fortis"' },
  { code: 302702425, name: "UAB VIVA energija" },
  { code: 302703114, name: 'UAB "TEMPLARI"' },
  { code: 302703413, name: 'UAB "ŠIRVINTOS POLISTIRENAS"' },
  { code: 302703993, name: "Aljara&Co, UAB" },
  { code: 302704629, name: "UAB AFINA LT" },
  { code: 302705503, name: 'UAB "CP Invest"' },
  { code: 302707212, name: 'UAB "Furnera"' },
  { code: 302707397, name: "UAB Milija" },
  { code: 302707447, name: "UAB STONELITA" },
  { code: 302709291, name: 'UAB "Prince Solis"' },
  { code: 302709569, name: '"Lukmedis", UAB' },
  { code: 302710048, name: 'UAB "CKI baldai"' },
  { code: 302710596, name: 'UAB "General Wood Group"' },
  { code: 302710863, name: "UAB Vitmedis" },
  { code: 302711050, name: "UAB Pifka" },
  { code: 302711253, name: 'UAB "R2 baldai"' },
  { code: 302711723, name: "UAB Lauris Furniture" },
  { code: 302713254, name: 'UAB "Kesandra Group"' },
  { code: 302715369, name: 'UAB "LANVITA"' },
  { code: 302716421, name: "UAB AMBER WAVE Ltd" },
  { code: 302716446, name: 'UAB "Baldų Grupė"' },
  { code: 302716542, name: 'UAB "LumaHell"' },
  { code: 302716663, name: 'UAB "IM-TEX"' },
  { code: 302716688, name: 'UAB "Daily Art"' },
  { code: 302717174, name: 'UAB "STEIVA"' },
  { code: 302717402, name: 'UAB "Prasila"' },
  { code: 302717943, name: 'UAB "RATENA"' },
  { code: 302718511, name: 'UAB "ŠERNO PĖDA"' },
  { code: 302718625, name: "IMEXTRA, UAB" },
  { code: 302719289, name: 'UAB "Virgus"' },
  { code: 302719951, name: 'UAB "Tribus"' },
  { code: 302720601, name: 'UAB "VLADAS IR KO"' },
  { code: 302721379, name: 'UAB "AGROARTA"' },
  { code: 302721386, name: "UAB Baldeina" },
  { code: 302721799, name: "UAB LED ŠVIESA" },
  { code: 302722552, name: 'UAB "WOODLITA"' },
  { code: 302724165, name: 'UAB "ALLIANCE WOOD"' },
  { code: 302724927, name: "UAB ŽVAKIŲ MOZAIKA" },
  { code: 302724966, name: "UAB WOODMETA" },
  { code: 302725018, name: 'UAB "U-LAST"' },
  { code: 302725025, name: "UAB EMIX COMPOSITES" },
  { code: 302725961, name: 'UAB "Betono bazė"' },
  { code: 302726376, name: "UAB Renatas service" },
  { code: 302728135, name: 'IĮ "Kęstučio stilius"' },
  { code: 302729123, name: "UAB RIMTAS BALDININKAS" },
  { code: 302729162, name: 'UAB "DELIDA"' },
  { code: 302729194, name: 'UAB "GP projektai"' },
  { code: 302729205, name: 'UAB "Alimega House"' },
  { code: 302730474, name: 'UAB "Sodbaldis"' },
  { code: 302730517, name: 'UAB "Suncapt"' },
  { code: 302730549, name: 'UAB "Sunstyle"' },
  { code: 302731149, name: "WoodEco, UAB" },
  { code: 302731291, name: 'UAB "Baltic Moon"' },
  { code: 302733890, name: 'UAB "ProBioSanus"' },
  { code: 302734864, name: 'UAB "EUSITA"' },
  { code: 302735400, name: "UAB ELIS LT" },
  { code: 302735852, name: 'UAB "Arcus Center"' },
  { code: 302736598, name: 'UAB "GG Saulės parkai"' },
  { code: 302736687, name: 'UAB "GT TRANSPORT"' },
  { code: 302736737, name: 'UAB "Kužių mėsa"' },
  { code: 302737383, name: 'UAB "Daridena"' },
  { code: 302737928, name: 'UAB "Elnovis"' },
  { code: 302739384, name: 'UAB "BTM Energija"' },
  { code: 302739441, name: "UAB G&P Contractor" },
  { code: 302739911, name: 'UAB "BerCapital"' },
  { code: 302740671, name: "Asociacija NEĮGALIŲJŲ RŪPYBA" },
  { code: 302741524, name: "UAB A5Energija" },
  { code: 302741638, name: 'UAB "OLD LT"' },
  { code: 302743386, name: "NAŠMITA, UAB" },
  { code: 302743411, name: 'UAB "Robenas"' },
  { code: 302745227, name: 'Individuali įmonė "LASAULA"' },
  { code: 302745241, name: 'VšĮ "Puodynė pieno"' },
  { code: 302745451, name: "UAB IKKANO Group" },
  { code: 302746012, name: 'UAB "Techplast"' },
  { code: 302747623, name: 'UAB "TRIMATE"' },
  { code: 302747993, name: 'UAB "Žalia pieva"' },
  { code: 302748180, name: 'UAB "Saulaja"' },
  { code: 302749909, name: 'UAB "ŠILTAS BŪSTAS LT"' },
  { code: 302752136, name: 'VšĮ "Aukso raidės"' },
  { code: 302752588, name: 'UAB "GRINDŲ MIRAŽAS"' },
  { code: 302752613, name: 'UAB "Mantradas"' },
  { code: 302752691, name: 'UAB "Baldų meistras"' },
  { code: 302752702, name: 'UAB "Samalis"' },
  { code: 302752741, name: 'UAB "Nometras"' },
  { code: 302752759, name: 'UAB "Lantina"' },
  { code: 302752773, name: 'UAB "Jolantena"' },
  { code: 302752798, name: 'UAB "Vaisgeda"' },
  { code: 302752905, name: 'UAB "SPLICAS"' },
  { code: 302754639, name: 'UAB "Furnitus"' },
  { code: 302755794, name: "Saulės modulis, UAB" },
  { code: 302756992, name: 'UAB "Fotoelektra"' },
  { code: 302757489, name: 'UAB "TRANSDOSA"' },
  { code: 302757514, name: 'UAB "GETANA"' },
  { code: 302757724, name: 'Individuali įmonė "Septyni amatai"' },
  { code: 302758623, name: 'UAB "VM Energy"' },
  { code: 302758630, name: 'UAB "NOVIDA"' },
  { code: 302759903, name: 'UAB "EGLI"' },
  { code: 302760357, name: 'UAB "JP7"' },
  { code: 302760624, name: "UAB Kemenos namai" },
  { code: 302761402, name: 'UAB "Stilforma"' },
  { code: 302761480, name: "UAB Solis Spectrum" },
  { code: 302761509, name: "UAB Terra Venti" },
  { code: 302761968, name: "SparkLight LT, UAB" },
  { code: 302762098, name: "Time Brand, UAB" },
  { code: 302762162, name: "LitVision, UAB" },
  { code: 302763015, name: "NAIL-WEB.LT UAB" },
  { code: 302763182, name: 'UAB "Eurorope"' },
  { code: 302764195, name: 'UAB "MRE Investicija"' },
  { code: 302764455, name: 'UAB "AVILĖ"' },
  { code: 302765062, name: "Multi-integration, UAB" },
  { code: 302765856, name: 'UAB "PLOKŠTUMA 1"' },
  { code: 302766666, name: 'UAB "Langista"' },
  { code: 302766748, name: 'UAB "Provincijos mėsa"' },
  { code: 302766876, name: "UAB SUNRITA" },
  { code: 302767501, name: 'UAB "Inmodera"' },
  { code: 302769032, name: 'IĮ "Vetfilijus"' },
  { code: 302769388, name: 'UAB "Baldai namams"' },
  { code: 302769630, name: 'UAB "Gryn"' },
  { code: 302770636, name: 'UAB "Gofra"' },
  { code: 302771492, name: 'UAB "Langučiai"' },
  { code: 302772039, name: 'UAB "Solarfit"' },
  { code: 302772085, name: 'UAB "SAULEKTRA"' },
  { code: 302772690, name: 'UAB "Saulės Servisas"' },
  { code: 302773408, name: 'UAB "SAULĖS FOTOELEKTRA"' },
  { code: 302773447, name: 'UAB "ŠVIESOS SRAUTAS"' },
  { code: 302773461, name: "UAB KREDRO GRUP" },
  { code: 302773536, name: 'UAB "ŠVIESOS LINIJA"' },
  { code: 302773568, name: 'Kooperatyvas "AGROAVES GROUP"' },
  { code: 302773657, name: 'UAB "RadioSolis"' },
  { code: 302773664, name: 'UAB "Sangeda"' },
  { code: 302774887, name: "UAB Terra Fortis" },
  { code: 302775380, name: 'IĮ "Saulės pieva"' },
  { code: 302775900, name: "UAB MAIZA" },
  { code: 302776557, name: "UAB Sol Energija" },
  { code: 302776913, name: 'UAB "EKO ENERGIJA-3"' },
  { code: 302776938, name: 'UAB "EKO ENERGIJA-4"' },
  { code: 302777004, name: 'UAB "Askera"' },
  { code: 302777036, name: "UAB EVERI" },
  { code: 302777506, name: 'UAB "Lamvuda"' },
  { code: 302777730, name: "UAB ADIANA Interio Baltic" },
  { code: 302777748, name: 'UAB "Reteksa"' },
  { code: 302778437, name: 'UAB "Saumega"' },
  { code: 302778444, name: 'UAB "Sauliama"' },
  { code: 302778647, name: 'UAB "Laimis ir Ko"' },
  { code: 302778704, name: 'UAB "LAKSAULITA"' },
  { code: 302779902, name: "BLUE SOLAR ENERGY, UAB" },
  { code: 302780057, name: "IĮ Nolėja" },
  { code: 302780915, name: 'UAB "ALDAMA"' },
  { code: 302780922, name: 'UAB "JS Baltic"' },
  { code: 302781127, name: 'UAB "Absque metus"' },
  { code: 302783231, name: 'UAB "Biolitex"' },
  { code: 302783879, name: 'UAB "IRMautomatika"' },
  { code: 302784283, name: "UAB BW Capital" },
  { code: 302784931, name: "PROMO SERVISAS UAB" },
  { code: 302785951, name: 'UAB "RADAMA"' },
  { code: 302785976, name: "UAB DIJ invest" },
  { code: 302786042, name: 'UAB "MONALA"' },
  { code: 302786170, name: 'UAB "Gerena"' },
  { code: 302787169, name: 'UAB "Solvina"' },
  { code: 302787176, name: 'UAB "Solairė"' },
  { code: 302787450, name: "UAB EU Business service" },
  { code: 302788189, name: 'UAB "DL energy"' },
  { code: 302788463, name: '"Sauliūnė" UAB' },
  { code: 302788513, name: "UAB AKTO" },
  { code: 302788602, name: 'UAB "GANIKA"' },
  { code: 302788698, name: 'UAB "EMERTA"' },
  { code: 302788723, name: "UAB Rėminimo galerija" },
  { code: 302788730, name: 'UAB "Mažasis brolis"' },
  { code: 302788755, name: 'UAB "BroLT"' },
  { code: 302790158, name: 'UAB "VASPETA"' },
  { code: 302790934, name: 'UAB "AINIŲ BALDAI"' },
  { code: 302791192, name: 'UAB "Švenčionių solar"' },
  { code: 302791858, name: 'UAB "Saulės blykstė"' },
  { code: 302792134, name: 'UAB "Solindustria"' },
  { code: 302792223, name: 'UAB "Energosun"' },
  { code: 302792458, name: 'UAB "Suvalkijos laivai"' },
  { code: 302792465, name: 'UAB "Saulės sfera"' },
  { code: 302792472, name: 'UAB "Mažonų saulė"' },
  { code: 302792497, name: 'UAB "SILICIS"' },
  { code: 302792547, name: 'UAB "Vensola"' },
  { code: 302793040, name: 'UAB "Smetas"' },
  { code: 302793275, name: '"SAULĖS TECHNOLOGIJOS", UAB' },
  { code: 302793916, name: 'UAB "BNB Capital"' },
  { code: 302794039, name: 'UAB "PV Investments"' },
  { code: 302794217, name: "Mockėnų ŽŪB" },
  { code: 302794384, name: 'UAB "Aukštaitijos medienos prekyba"' },
  { code: 302794391, name: 'UAB "NOVILA"' },
  { code: 302794975, name: 'UAB "ŠPOKAS"' },
  { code: 302796129, name: "UAB Gerher" },
  { code: 302796296, name: 'UAB "Taurata"' },
  { code: 302797067, name: 'UAB "Linea solaris"' },
  { code: 302797074, name: 'UAB "Linea fortis"' },
  { code: 302797569, name: 'UAB "SANTIDA"' },
  { code: 302797619, name: "UAB ARD Tikslai" },
  { code: 302797640, name: 'UAB "Soltara LT"' },
  { code: 302797793, name: 'UAB "Laitkrafta"' },
  { code: 302797804, name: 'UAB "Lumoforta"' },
  { code: 302798468, name: 'VšĮ "AMXSAS"' },
  { code: 302798482, name: 'UAB "Saulės energetikos projektai"' },
  { code: 302798557, name: 'UAB "Ūtos Sruogos energija"' },
  { code: 302798621, name: "UAB EKO Industrija" },
  { code: 302798895, name: 'UAB "Cocos LT"' },
  { code: 302799189, name: "UAB Jotega" },
  { code: 302799374, name: 'UAB "Pietuškių elektra"' },
  { code: 302799602, name: 'UAB "Solbega"' },
  { code: 302799716, name: 'UAB "ALKOS šviesa"' },
  { code: 302799723, name: 'UAB "ALKOS šiluma"' },
  { code: 302799730, name: 'UAB "ALKOS statyba"' },
  { code: 302799748, name: 'UAB "ALKOS konstrukcijos"' },
  { code: 302799755, name: 'UAB "ALKOS energija"' },
  { code: 302800524, name: 'UAB "Sigeka"' },
  { code: 302800613, name: "UAB Solarija" },
  { code: 302800652, name: 'UAB "ELIOINVEST"' },
  { code: 302800990, name: "AARGAU UAB" },
  { code: 302801003, name: "SBSUN UAB" },
  { code: 302802144, name: 'UAB "Tauragės metalas"' },
  { code: 302802183, name: 'UAB "ALKOS ekologija"' },
  { code: 302802411, name: "GMQ EUROPE, UAB" },
  { code: 302802475, name: 'UAB "SAKALIUS"' },
  { code: 302802571, name: 'VšĮ "RCE"' },
  { code: 302803417, name: "UAB Sun Epsilon" },
  { code: 302803431, name: "UAB Sun Gamma" },
  { code: 302803456, name: "UAB Sun Delta" },
  { code: 302803470, name: "UAB Sun Alpha" },
  { code: 302804362, name: 'UAB "Volmita"' },
  { code: 302804960, name: 'UAB "Mėsinga"' },
  { code: 302804978, name: 'UAB "Pietuškių saulės jėgainė"' },
  { code: 302804992, name: 'UAB "30 kilovatų"' },
  { code: 302805546, name: 'UAB "Auto Trans Grup"' },
  { code: 302805553, name: "UAB Eurolift Lithuania" },
  { code: 302805731, name: 'UAB "SOLVINTA"' },
  { code: 302805795, name: 'UAB "DS10"' },
  { code: 302805891, name: 'UAB "DS2"' },
  { code: 302805966, name: 'UAB "DS3"' },
  { code: 302806018, name: 'UAB "DS4"' },
  { code: 302806025, name: 'UAB "Solnovus"' },
  { code: 302806032, name: 'UAB "FM Real Estate"' },
  { code: 302806071, name: 'UAB "FC Real Estate"' },
  { code: 302806107, name: 'UAB "DS6"' },
  { code: 302806139, name: 'UAB "DS7"' },
  { code: 302806356, name: 'UAB "DS8"' },
  { code: 302806598, name: 'UAB "Hamida"' },
  { code: 302806687, name: 'UAB "Green Fin"' },
  { code: 302806776, name: 'UAB "Promar"' },
  { code: 302806872, name: "UAB ALTOKA" },
  { code: 302806908, name: 'UAB "BALEMĖ"' },
  { code: 302807771, name: "UAB Innospark" },
  { code: 302808371, name: 'UAB "MB Energija"' },
  { code: 302808389, name: 'UAB "REN Energija"' },
  { code: 302808396, name: 'UAB "SP Energija"' },
  { code: 302808574, name: "Jėgainė 3, UAB" },
  { code: 302808599, name: "UAB ATOMMEX" },
  { code: 302808617, name: "Jėgainė 5, UAB" },
  { code: 302808631, name: "Jėgainė 7, UAB" },
  { code: 302808695, name: "UAB MORTEKA" },
  { code: 302808866, name: 'UAB "Ledigma"' },
  { code: 302809651, name: 'UAB "Investicija į saulę"' },
  { code: 302809911, name: 'UAB "Ukmergės katilinė"' },
  { code: 302810340, name: 'UAB "Kolminga"' },
  { code: 302810988, name: 'UAB "AP Invest"' },
  { code: 302811143, name: "UAB SAULĖS ŽEMĖ" },
  { code: 302811150, name: "UAB ATSAKINGA ENERGIJA" },
  { code: 302811168, name: "UAB ŠEŠTADIENIO SAULĖ" },
  { code: 302811175, name: "UAB Sky Invest" },
  { code: 302811182, name: "UAB ŽALIA PLANETA" },
  { code: 302811773, name: 'UAB "Zeta Saulė 2"' },
  { code: 302812373, name: 'UAB "Saulė Jums"' },
  { code: 302812551, name: "UAB ELANIS" },
  { code: 302812601, name: 'UAB "KASPARO BALDAI"' },
  { code: 302812900, name: "UAB DROMENA" },
  { code: 302813607, name: 'UAB "ALD Baltic"' },
  { code: 302813639, name: "SAULĖS INVESTICIJOS, UAB" },
  { code: 302813774, name: 'UAB "TYKLE SUN"' },
  { code: 302813849, name: 'UAB "MSD Consulting"' },
  { code: 302813952, name: 'UAB "GREEN JOINER"' },
  { code: 302814011, name: 'UAB "IKEDA"' },
  { code: 302814036, name: "UAB Amanta" },
  { code: 302814367, name: 'UAB "EKOGALIOS"' },
  { code: 302814424, name: 'UAB "TET Energy"' },
  { code: 302814456, name: 'UAB "Next Investment"' },
  { code: 302814520, name: "UAB SE Sistemos" },
  { code: 302814919, name: "UAB IGITA" },
  { code: 302815501, name: "SunEKS, UAB" },
  { code: 302815526, name: 'UAB "PP Investment"' },
  { code: 302815729, name: 'UAB "Medienos komponentai"' },
  { code: 302815775, name: 'UAB "Transport Services"' },
  { code: 302815896, name: "MeetAGene, UAB" },
  { code: 302815985, name: "UAB Saulės kvantas" },
  { code: 302816941, name: "Sirio RDS, UAB" },
  { code: 302817363, name: "ŠIAUŠĖ UAB" },
  { code: 302817445, name: 'UAB "KAMILIS"' },
  { code: 302817527, name: "EGIDIJAUS SAFONOVO INDIVIDUALI ĮMONĖ" },
  { code: 302817573, name: 'UAB "RELIT"' },
  { code: 302817687, name: "VYTAUTO VAISIAUSKO IĮ" },
  { code: 302817744, name: "UAB Solar product" },
  { code: 302817819, name: "UAB Kaliptus" },
  { code: 302818465, name: "UAB ŠEIMOS MĖSINĖ" },
  { code: 302819510, name: 'UAB "Erkesta"' },
  { code: 302819567, name: "UAB Saulės ištekliai" },
  { code: 302819574, name: "UAB Saulės delta" },
  { code: 302819599, name: "UAB Solis Services" },
  { code: 302819713, name: "UAB Baltic Lux" },
  { code: 302820498, name: 'UAB "AE saulės vatas"' },
  { code: 302820509, name: 'UAB "PV saulės energija"' },
  { code: 302820719, name: 'UAB "DOVMINA"' },
  { code: 302821002, name: "UAB DIENOS ZENITAS" },
  { code: 302821034, name: "UAB AMŽINA ENERGIJA" },
  { code: 302821205, name: 'UAB "Družai II"' },
  { code: 302821290, name: "UAB Solis Vertus" },
  { code: 302821486, name: 'UAB "JUOSTATA"' },
  { code: 302821504, name: 'UAB "REMREMA"' },
  { code: 302821511, name: 'UAB "GV Invest"' },
  { code: 302822780, name: "UAB Inno Lietuva" },
  { code: 302822983, name: 'UAB "AG jėgainė"' },
  { code: 302823373, name: 'UAB "OLMENA"' },
  { code: 302823583, name: "UAB Sunrise Solutions" },
  { code: 302823811, name: 'UAB "Nimava"' },
  { code: 302824030, name: "UAB GEIDMA" },
  { code: 302824105, name: 'UAB "AG saulės jėgainė"' },
  { code: 302824112, name: 'UAB "MAVY STUDIJA"' },
  { code: 302824151, name: 'UAB "TAN Oil"' },
  { code: 302824258, name: 'UAB "S.ENERGY"' },
  { code: 302824856, name: 'Viešoji įstaiga "UroBioLab"' },
  { code: 302825292, name: "UAB DALVAJA" },
  { code: 302825342, name: "UAB GRAIL" },
  { code: 302826024, name: "UAB KELSA" },
  { code: 302826056, name: 'UAB "RINGERA"' },
  { code: 302826095, name: 'MB "Proeka"' },
  { code: 302826330, name: "WARTSILA BLRT ESTONIA OU Lietuvos filialas" },
  { code: 302827126, name: 'UAB "Klaipėdos rajono energija"' },
  { code: 302828406, name: "UAB Visagino PV" },
  { code: 302828420, name: "UAB Vygreka" },
  { code: 302828438, name: "UAB VPV" },
  { code: 302828445, name: "UAB TOPsolis" },
  { code: 302828477, name: "UAB VSG PV" },
  { code: 302828484, name: 'UAB "EKO terminalas"' },
  { code: 302828541, name: 'UAB "EKO Srautas"' },
  { code: 302828566, name: "UAB BiKs" },
  { code: 302828573, name: "UAB SGD investicijos" },
  { code: 302828979, name: "UAB Vasaros diena" },
  { code: 302829038, name: 'UAB "SAULARA"' },
  { code: 302829248, name: 'UAB "KDX group"' },
  { code: 302829376, name: "UAB Green Bean Energy" },
  { code: 302830250, name: 'UAB "Kariotiškių energija"' },
  { code: 302830414, name: "BOZONAS, UAB" },
  { code: 302830663, name: "VANAGIS, UAB" },
  { code: 302830670, name: "UAB Elektros banga" },
  { code: 302830784, name: "BUVEINIS, UAB" },
  { code: 302830834, name: "BŪGNELIS, UAB" },
  { code: 302830841, name: "IŠTOKA, UAB" },
  { code: 302830859, name: "ELBENTAS, UAB" },
  { code: 302830873, name: 'UAB "Nemunas River Line"' },
  { code: 302831352, name: "V SAULĖ, UAB" },
  { code: 302832365, name: 'UAB "OVESTA"' },
  { code: 302832408, name: 'UAB "ROBINETA"' },
  { code: 302832550, name: "UAB Viridis Navitas" },
  { code: 302833378, name: 'UAB "Surana"' },
  { code: 302833588, name: 'UAB "ZAROLA"' },
  { code: 302833848, name: 'UAB "KAMEVA"' },
  { code: 302833862, name: 'UAB "ADOLENA"' },
  { code: 302833983, name: "UAB Minerva Media" },
  { code: 302834398, name: "SENOLIŲ ALKSNYNĖ, UAB" },
  { code: 302834658, name: 'UAB "AUTOSMILGA"' },
  { code: 302834779, name: 'UAB "LITSERTA"' },
  { code: 302835518, name: "UAB Mozūriškės energija" },
  { code: 302836132, name: '"Vilniaus inžineriniai projektai", UAB' },
  { code: 302836883, name: 'UAB "MIR PROJEKTAI"' },
  { code: 302836901, name: 'UAB "Sasnavos energija"' },
  { code: 302836940, name: "UAB Baldai4u" },
  { code: 302837120, name: "ALMEKS, UAB" },
  { code: 302837145, name: "IĮ STAR POWER" },
  { code: 302837202, name: 'UAB "Lygumų vėjas"' },
  { code: 302837273, name: 'UAB "NORGETA"' },
  { code: 302837298, name: 'UAB "KORESA"' },
  { code: 302837348, name: 'UAB "JORITAS"' },
  { code: 302838044, name: 'UAB "Baltijos investicijų vartai"' },
  { code: 302838286, name: "Ž. Andrulio IĮ" },
  { code: 302838382, name: "IĮ Stella IT" },
  { code: 302838877, name: 'UAB "AG šiluma"' },
  { code: 302838891, name: 'UAB "LAG spindulys"' },
  { code: 302839025, name: 'UAB "Aiva Grupė"' },
  { code: 302839107, name: 'UAB "TG baldai"' },
  { code: 302839178, name: "UAB RD Trade" },
  { code: 302839228, name: 'UAB "VINTIDA"' },
  { code: 302839274, name: 'UAB "Vakarų jėgainės"' },
  { code: 302839370, name: 'UAB "SKR SUN"' },
  { code: 302839388, name: 'UAB "PB investicijos"' },
  { code: 302839897, name: "UAB Saulėkyla" },
  { code: 302840006, name: "UAB MV Energija" },
  { code: 302840052, name: 'UAB "Dansk Tub"' },
  { code: 302841243, name: 'UAB "ROMVITAS"' },
  { code: 302841325, name: 'UAB "KOSERTA"' },
  { code: 302841364, name: 'UAB "Green Energy & Consulting"' },
  { code: 302841446, name: "UAB Merkio Saulė" },
  { code: 302842060, name: 'UAB "Montrans"' },
  { code: 302842886, name: 'UAB "Aufis"' },
  { code: 302842936, name: "UAB ELER" },
  { code: 302842943, name: 'UAB "NORDEKA"' },
  { code: 302842950, name: "UAB ELERAS" },
  { code: 302843162, name: 'UAB "WOOD STYLE"' },
  { code: 302843276, name: "UAB Green solution" },
  { code: 302843372, name: 'UAB "Saulės voltas"' },
  { code: 302843511, name: 'UAB "Zigmada"' },
  { code: 302843881, name: "UAB Laukinukės" },
  { code: 302843924, name: "UAB Iblija" },
  { code: 302843963, name: 'UAB "Audentis"' },
  { code: 302845213, name: "UAB RETROSHTERN" },
  { code: 302846062, name: 'UAB "AgroSaulė 7"' },
  { code: 302846094, name: 'UAB "Romano LPG"' },
  { code: 302846105, name: 'UAB "AgroSaulė 8"' },
  { code: 302846112, name: 'UAB "Ramzis"' },
  { code: 302846169, name: 'UAB "AgroSaulė 10"' },
  { code: 302846233, name: 'UAB "AgroSaulė 13"' },
  { code: 302846258, name: 'UAB "AgroSaulė 15"' },
  { code: 302846354, name: 'UAB "AgroSaulė 16"' },
  { code: 302846379, name: 'UAB "AgroSaulė 17"' },
  { code: 302846386, name: 'UAB "Grid sistemos"' },
  { code: 302846393, name: "UAB MB Sistemos" },
  { code: 302846404, name: 'UAB "RA Energija"' },
  { code: 302846436, name: 'UAB "AgroSaulė 18"' },
  { code: 302846468, name: 'UAB "AgroSaulė 19"' },
  { code: 302846475, name: 'UAB "AgroSaulė 5"' },
  { code: 302846482, name: 'UAB "AgroSaulė 20"' },
  { code: 302846500, name: "UAB ELEREMA" },
  { code: 302846518, name: "UAB ELERANA" },
  { code: 302846525, name: "UAB ELERENA" },
  { code: 302846532, name: "UAB ELEREDA" },
  { code: 302846557, name: "UAB ELERETA" },
  { code: 302846614, name: 'UAB "AgroSaulė 3"' },
  { code: 302846945, name: 'UAB "PVS Energija"' },
  { code: 302848070, name: 'UAB "Ivecita"' },
  { code: 302848095, name: "SVM ENERGIJA, UAB" },
  { code: 302848145, name: 'UAB "ELKEDA"' },
  { code: 302848476, name: 'UAB "Vovsalta"' },
  { code: 302848864, name: "UAB MONTEKSA" },
  { code: 302849432, name: 'UAB "MONETORA"' },
  { code: 302849507, name: 'UAB "SDA Pakuotė"' },
  { code: 302850071, name: 'UAB "Psenergija"' },
  { code: 302850107, name: 'UAB "Cenergija"' },
  { code: 302850178, name: 'UAB "AM projektas"' },
  { code: 302850242, name: 'UAB "Družai IV"' },
  { code: 302850274, name: 'UAB "Kenergija"' },
  { code: 302850388, name: 'UAB "GEOMAKSAS"' },
  { code: 302850648, name: "UAB Sun Traders" },
  { code: 302850801, name: 'UAB "Gecon"' },
  { code: 302851757, name: 'UAB "Alytaus SEI"' },
  { code: 302851764, name: 'UAB "Linea lux"' },
  { code: 302851807, name: 'UAB "Šilalės vėjas"' },
  { code: 302851903, name: 'VšĮ "Diveriks"' },
  { code: 302852357, name: 'UAB "Termopolis"' },
  { code: 302852706, name: "UAB GRANDARA" },
  { code: 302852880, name: 'UAB "Ruberta"' },
  { code: 302853772, name: 'UAB "DS Energija"' },
  { code: 302853822, name: "UAB GILEKTRA" },
  { code: 302853854, name: 'UAB "ELUK"' },
  { code: 302854632, name: 'UAB "Alkonta"' },
  { code: 302855104, name: 'UAB "SAULĖS KODAS"' },
  { code: 302855588, name: "UAB Markupis" },
  { code: 302855613, name: 'UAB "SPONERA"' },
  { code: 302856117, name: "Tommy & Sons, UAB" },
  { code: 302856455, name: 'UAB "GS elektra"' },
  { code: 302856601, name: 'UAB "Vilgema"' },
  { code: 302856868, name: 'UAB "SNSA"' },
  { code: 302856971, name: 'UAB "MERKANTA"' },
  { code: 302857393, name: "UAB Project M" },
  { code: 302857500, name: 'UAB "Softlines"' },
  { code: 302857646, name: 'MB "IŠ SAULĖS"' },
  { code: 302857653, name: "UAB VALIAI" },
  { code: 302857703, name: 'UAB "Sky2"' },
  { code: 302858883, name: '"Sachara", UAB' },
  { code: 302858940, name: 'UAB "AURONUS"' },
  { code: 302859145, name: 'UAB "SUN10"' },
  { code: 302859330, name: 'UAB "JM Projektai"' },
  { code: 302859622, name: 'UAB "Darkupa"' },
  { code: 302859661, name: 'UAB "Ignava"' },
  { code: 302859800, name: 'UAB "SAUGERTA"' },
  { code: 302859825, name: 'UAB "MONOSA"' },
  { code: 302860051, name: 'UAB "Verslo greitis"' },
  { code: 302860119, name: 'UAB "VAIGOSTA"' },
  { code: 302860311, name: 'UAB "Lukrasa"' },
  { code: 302860343, name: 'UAB "Munanta"' },
  { code: 302860585, name: "UAB Saulės Rifas" },
  { code: 302860667, name: 'UAB "Alternatyvi sinergija"' },
  { code: 302861210, name: 'UAB "Sunsails Systems"' },
  { code: 302861527, name: 'UAB "VINBESTA"' },
  { code: 302861954, name: 'UAB "Mustenių ekoenergija"' },
  { code: 302862084, name: 'UAB "MU3"' },
  { code: 302862401, name: 'UAB "RG energija"' },
  { code: 302862472, name: "UAB Saulėlyda" },
  { code: 302862497, name: "UAB Lauginja" },
  { code: 302862515, name: 'UAB "Sauenergetika"' },
  { code: 302862579, name: 'UAB "Terrasolis"' },
  { code: 302862593, name: 'UAB "Sky 5"' },
  { code: 302862807, name: "MB Sneginta" },
  { code: 302863161, name: "UAB RO LT" },
  { code: 302863211, name: 'UAB "Alytaus SEIII"' },
  { code: 302863243, name: 'UAB "Alytaus SEII"' },
  { code: 302863396, name: 'UAB "AICORA"' },
  { code: 302864014, name: "VJ Energy, UAB" },
  { code: 302864224, name: 'UAB "Vakas"' },
  { code: 302864320, name: 'UAB "Alytaus SEV"' },
  { code: 302864480, name: "UAB Siglendas" },
  { code: 302864555, name: 'UAB "Neringvėja"' },
  { code: 302865707, name: "UAB Vaidora pro" },
  { code: 302865714, name: 'UAB "A-energy"' },
  { code: 302865792, name: "ETA energia, UAB" },
  { code: 302865874, name: 'UAB "Tandijus"' },
  { code: 302865899, name: "ANDERUS, UAB" },
  { code: 302865995, name: 'UAB "SOSLORA"' },
  { code: 302866467, name: "UAB Saulės pasaulis" },
  { code: 302866990, name: "UAB Elektros parkas" },
  { code: 302867124, name: 'UAB "Alektrona"' },
  { code: 302867131, name: 'MB "Adomo saulė"' },
  { code: 302867188, name: 'MB "Rimutės saulė"' },
  { code: 302867220, name: 'UAB "Lokauša"' },
  { code: 302867398, name: "UAB Mapo Energy" },
  { code: 302867626, name: 'MB "Urtės saulė"' },
  { code: 302867708, name: 'UAB "Alektra"' },
  { code: 302867754, name: 'UAB "Eldolit"' },
  { code: 302867779, name: 'UAB "Auringeda"' },
  { code: 302868404, name: "UAB GOLEBO" },
  { code: 302868621, name: "UAB GAMMA VENTUS" },
  { code: 302868767, name: 'UAB "Pentagrama"' },
  { code: 302868824, name: 'MB "MG projektai"' },
  { code: 302869043, name: "MB Teravatas" },
  { code: 302869424, name: 'UAB "EKOGALINGUMAS"' },
  { code: 302869513, name: 'UAB "EkoFloRida"' },
  { code: 302869545, name: "UAB VENTUS energijos grupė" },
  { code: 302869552, name: 'UAB "EKOBLYKSTĖ"' },
  { code: 302869787, name: "UAB THETA VENTUS" },
  { code: 302869844, name: 'UAB "Saulės akys"' },
  { code: 302869901, name: "UAB ZETA VENTUS" },
  { code: 302870049, name: "Idex Zietela UAB" },
  { code: 302870070, name: 'UAB "EG Invest"' },
  { code: 302870088, name: 'UAB "EG Agro"' },
  { code: 302870113, name: "AKONUS, UAB" },
  { code: 302870120, name: "ADOVA, UAB" },
  { code: 302870259, name: 'UAB "RDJ energija"' },
  { code: 302871090, name: 'UAB "Mondaga"' },
  { code: 302871101, name: 'UAB "Kirduva"' },
  { code: 302871197, name: 'UAB "Kontika"' },
  { code: 302871215, name: "UAB PRATIS" },
  { code: 302871286, name: 'UAB "SB ENERSOL"' },
  { code: 302871350, name: 'UAB "SORNA"' },
  { code: 302871418, name: "UAB RHO VENTUS" },
  { code: 302871471, name: "UAB LOTA VENTUS" },
  { code: 302871496, name: "UAB Organic Fertilizers Factory" },
  { code: 302871592, name: "MB Eklevas" },
  { code: 302871674, name: "UAB PK 2" },
  { code: 302871927, name: 'UAB "Sun Media"' },
  { code: 302872395, name: "UAB Elektros šaltinis" },
  { code: 302872541, name: "UAB Žiežmarių mėsinė" },
  { code: 302872573, name: 'UAB "Lukcita"' },
  { code: 302873134, name: 'MB "Galinos saulė"' },
  { code: 302873853, name: "BIOFLAVUS UAB" },
  { code: 302874193, name: "UAB Šviesos jungtis" },
  { code: 302874229, name: "UAB Saulėtas dangus" },
  { code: 302874250, name: "UAB Saulės jungtis" },
  { code: 302874282, name: 'UAB "RR Projektai"' },
  { code: 302874300, name: "UAB Šviesos raktas" },
  { code: 302874364, name: 'MB "Ginto saulė"' },
  { code: 302874777, name: "MB JUVYSOL" },
  { code: 302874784, name: 'IĮ "Lennycraft"' },
  { code: 302874923, name: 'UAB "C.C.V. Real Estate"' },
  { code: 302875427, name: 'UAB "TomARTAS"' },
  { code: 302875441, name: 'UAB "RINVITA"' },
  { code: 302875740, name: "UAB Varok LT" },
  { code: 302875929, name: 'MB "Margio spindulys"' },
  { code: 302876269, name: 'MB "Electron Z"' },
  { code: 302876283, name: 'MB "J Elektrinė"' },
  { code: 302876358, name: "UAB FUTURE HOME ENERGY" },
  { code: 302876867, name: 'MB "Medenergia"' },
  { code: 302877271, name: 'UAB "Vandovis"' },
  { code: 302877360, name: 'UAB "Valterna"' },
  { code: 302877499, name: "UAB Elektros taškas" },
  { code: 302877570, name: 'UAB "DOMUSINVEST"' },
  { code: 302877652, name: "UAB Dabitų saulė" },
  { code: 302877691, name: 'UAB "KOSTORA"' },
  { code: 302877944, name: "UAB AKTYVUS NAMAS" },
  { code: 302878448, name: "A. Žadeikos MB" },
  { code: 302878487, name: 'MB "Eko idea"' },
  { code: 302878761, name: 'UAB "AROSETA"' },
  { code: 302879201, name: "UAB Saulera" },
  { code: 302879322, name: 'MB "SIMOKO"' },
  { code: 302879386, name: "UAB Solektra" },
  { code: 302879952, name: "MB AMenergy" },
  { code: 302879977, name: 'MB "Jaunada"' },
  { code: 302880004, name: 'MB "DJ energija"' },
  { code: 302880029, name: "PSS Invest, UAB" },
  { code: 302880125, name: 'UAB "Bruišė"' },
  { code: 302880303, name: 'UAB "ALOTONA"' },
  { code: 302881024, name: 'UAB "SIGMA SOLAR"' },
  { code: 302881120, name: 'UAB "Ostira"' },
  { code: 302881266, name: 'UAB "Sauleda"' },
  { code: 302881273, name: 'UAB "Hensira"' },
  { code: 302881355, name: 'UAB "Sanesetas"' },
  { code: 302881394, name: 'UAB "Henora"' },
  { code: 302881437, name: 'UAB "Tanvira"' },
  { code: 302881476, name: 'UAB "Sandita"' },
  { code: 302881711, name: 'UAB "Mini elektrinė"' },
  { code: 302881736, name: 'UAB "Riškevičių elektrinė"' },
  { code: 302881768, name: 'UAB "SAULĖ4"' },
  { code: 302881782, name: 'UAB "Convest Group"' },
  { code: 302882400, name: 'UAB "SAULĖ1"' },
  { code: 302882464, name: "UAB Patilčiai" },
  { code: 302882489, name: 'UAB "Agmora"' },
  { code: 302882507, name: "UAB Žebertvatas" },
  { code: 302882521, name: 'MB "Žvaigždės šviesa"' },
  { code: 302882603, name: "Penta Electronics UAB" },
  { code: 302882756, name: "UAB Terre energy" },
  { code: 302883064, name: 'MB "Sunstep"' },
  { code: 302883299, name: 'UAB "SOPONA"' },
  { code: 302883395, name: '"Wide route" UAB' },
  { code: 302883908, name: 'UAB "LOTANSA"' },
  { code: 302884013, name: "MB SOLEDAR ALFA" },
  { code: 302884159, name: 'MB "Solarera"' },
  { code: 302884312, name: 'MB "JG sistemos"' },
  { code: 302884383, name: 'UAB "Kervina"' },
  { code: 302884440, name: 'MB "RES verslas"' },
  { code: 302884853, name: '"Rinda", MB' },
  { code: 302884903, name: "UAB Graži diena" },
  { code: 302884928, name: "UAB Šviesus rytas" },
  { code: 302884942, name: "UAB Elektros jėga" },
  { code: 302884974, name: "UAB Rasos lašas" },
  { code: 302885229, name: "UAB Ryški šviesa" },
  { code: 302885243, name: "UAB RD power" },
  { code: 302885478, name: "UAB Elektros mada" },
  { code: 302885656, name: 'UAB "Futurensol LT"' },
  { code: 302885738, name: 'MB "Gemos Baldai"' },
  { code: 302885752, name: "UAB Rebilda" },
  { code: 302886427, name: "UAB Pigi saulė" },
  { code: 302886441, name: 'UAB "AUTOTIRA"' },
  { code: 302886733, name: 'UAB "Vila Tekila"' },
  { code: 302887148, name: 'UAB "Eurotimberis"' },
  { code: 302887251, name: 'UAB "OSTERDA"' },
  { code: 302887290, name: 'UAB "AMRISANA"' },
  { code: 302888403, name: 'UAB "Antovita"' },
  { code: 302888499, name: "UAB AROSATA" },
  { code: 302888702, name: 'UAB "Alsolis"' },
  { code: 302889277, name: 'UAB "Obras"' },
  { code: 302890361, name: 'UAB "Ruberus"' },
  { code: 302890436, name: 'UAB "ARC elektra"' },
  { code: 302890621, name: 'UAB "Grinerga"' },
  { code: 302890906, name: 'UAB "ALMINTA"' },
  { code: 302891029, name: 'UAB "RG projektai"' },
  { code: 302891246, name: 'UAB "ANDRIKUS"' },
  { code: 302891253, name: "UAB SVELBA" },
  { code: 302891260, name: "UAB Steimeda" },
  { code: 302891303, name: 'MB "Luksas"' },
  { code: 302891374, name: 'UAB "Saulėtenis"' },
  { code: 302891456, name: "UAB SUNGARD1" },
  { code: 302892024, name: 'MB "Prienlaukio elektra"' },
  { code: 302892049, name: 'UAB "TED energija"' },
  { code: 302892177, name: "Pasvalio alternatyvi energija MB" },
  { code: 302892330, name: 'UAB "Solar House"' },
  { code: 302892469, name: 'UAB "Neišsenkanti saulės energija"' },
  { code: 302892572, name: 'UAB "Žemaitijos energija"' },
  { code: 302892939, name: 'UAB "Interlogus"' },
  { code: 302892985, name: "UAB Saulės Svertas" },
  { code: 302893044, name: "UAB JUTOS SAULĖ" },
  { code: 302893133, name: 'UAB "Saulėčia"' },
  { code: 302893158, name: 'UAB "Sunbelt LT"' },
  { code: 302893247, name: 'UAB "Solaren Works"' },
  { code: 302893261, name: 'UAB "EKO ENERGIJA-8"' },
  { code: 302893400, name: 'MB "JURMONĖ"' },
  { code: 302893496, name: 'MB "TOMJUSTA"' },
  { code: 302893539, name: 'UAB "SOFUZ"' },
  { code: 302893592, name: "UAB Aukštaitijos baldų fabrikas" },
  { code: 302893877, name: 'MB "SOLWAY LT"' },
  { code: 302893934, name: "LEO LT, MB" },
  { code: 302894018, name: "UAB NARADAVOS SAULĖ" },
  { code: 302894071, name: "UAB NERINDA" },
  { code: 302894445, name: "MB Migdolo žiedas" },
  { code: 302894769, name: '"Packus", UAB' },
  { code: 302895383, name: 'UAB "Muvita"' },
  { code: 302895935, name: "NEO ELEKTRA, MB" },
  { code: 302896001, name: "MB Spindulio spektras" },
  { code: 302896026, name: "MB Spindulio centras" },
  { code: 302896033, name: "MB Saulės elementas" },
  { code: 302896371, name: 'UAB "DAUSMETA"' },
  { code: 302896446, name: 'UAB "Mėnulio šešėlis"' },
  { code: 302896948, name: "UAB ONASA" },
  { code: 302897085, name: 'UAB "OAKWILL"' },
  { code: 302897181, name: 'MB "Visuomis"' },
  { code: 302897224, name: 'MB "gilėpilė"' },
  { code: 302897288, name: "UAB ENAFA" },
  { code: 302897295, name: "UAB ONAFA" },
  { code: 302897498, name: "UAB Ambertip" },
  { code: 302897683, name: 'MB "SL190"' },
  { code: 302898059, name: "Rimvydo Intuko mažoji bendrija" },
  { code: 302898408, name: 'UAB "Dermesta"' },
  { code: 302898461, name: 'MB "GURONIŲ SAULĖS MIESTAS-1"' },
  { code: 302898536, name: 'MB "TVDJ"' },
  { code: 302898739, name: 'MB "GURONIŲ SAULĖS MIESTAS-3"' },
  { code: 302898828, name: 'MB "LUTUVA"' },
  { code: 302898842, name: "UAB ONAHA" },
  { code: 302898917, name: "UAB ONAZA" },
  { code: 302899079, name: 'UAB "Bolerita"' },
  { code: 302899150, name: 'UAB "Boleris"' },
  { code: 302899168, name: 'UAB "Bolera"' },
  { code: 302899339, name: 'UAB "Egrinesta"' },
  { code: 302899442, name: 'MB "RG Energy"' },
  { code: 302899517, name: 'UAB "AINORA"' },
  { code: 302899620, name: "MB Giesole" },
  { code: 302899855, name: 'UAB "Deramita"' },
  { code: 302899951, name: 'UAB "Betanet energy"' },
  { code: 302900396, name: 'UAB "Demanra"' },
  { code: 302900446, name: 'UAB "FurnMaster"' },
  { code: 302900670, name: 'MB "NANDUS"' },
  { code: 302900841, name: 'UAB "Mildrena"' },
  { code: 302901594, name: "Tech Studio, MB" },
  { code: 302901644, name: 'MB "Violėja"' },
  { code: 302901651, name: 'Mažoji bendrija "Saulės fėja"' },
  { code: 302901669, name: 'Mažoji bendrija "Saulės daina"' },
  { code: 302901676, name: 'UAB "TYRETRA"' },
  { code: 302901726, name: "Ava Sol UAB" },
  { code: 302901797, name: 'MB "Adelės Energija"' },
  { code: 302901808, name: 'MB "Jonėnų Saulė"' },
  { code: 302901822, name: 'MB "Jūratės Energija"' },
  { code: 302901847, name: 'MB "Giedrės Energija"' },
  { code: 302901936, name: 'MB "VITRENA"' },
  { code: 302902365, name: 'UAB "Rigosta"' },
  { code: 302903015, name: 'UAB "Žvaigždūnė"' },
  { code: 302903150, name: 'MB "Neringos saulė"' },
  { code: 302903360, name: "MB Nastelga" },
  { code: 302904341, name: 'MAŽOJI BENDRIJA "SAULĖS ADEPTAS"' },
  { code: 302904487, name: 'UAB "Lomesta"' },
  { code: 302904505, name: 'UAB "Boleris1"' },
  { code: 302904512, name: "UAB Šlamesys" },
  { code: 302904601, name: 'UAB "Monovizija"' },
  { code: 302904658, name: 'UAB "Boleris2"' },
  { code: 302904665, name: 'MB "ELANKOS"' },
  { code: 302904715, name: 'UAB "Bolerita1"' },
  { code: 302904747, name: 'UAB "Bolera1"' },
  { code: 302904754, name: 'UAB "Raikinta"' },
  { code: 302904971, name: 'UAB "Oistu Grupė"' },
  { code: 302906260, name: 'MB "Patikimas srautas"' },
  { code: 302906424, name: "MB ELEKTRO 6" },
  { code: 302906506, name: "TolTrade, UAB" },
  { code: 302906666, name: 'UAB "Vytora"' },
  { code: 302906673, name: 'UAB "Edigerma"' },
  { code: 302907138, name: 'MB "RAINCORD"' },
  { code: 302907191, name: 'UAB "Vilinitis"' },
  { code: 302907889, name: "UAB DUTCH ENERGY" },
  { code: 302908293, name: 'Uždaroji akcinė bendrovė "Egipas"' },
  { code: 302908375, name: 'VšĮ "SEZAURUS"' },
  { code: 302909491, name: 'UAB "Bolera2"' },
  { code: 302909527, name: 'UAB "Onos saulė"' },
  { code: 302909534, name: 'UAB "Juro spindulys"' },
  { code: 302909566, name: 'UAB "Bolerita2"' },
  { code: 302909986, name: 'UAB "Soleitas"' },
  { code: 302910198, name: "UAB Yellow Energy LT" },
  { code: 302910515, name: 'UAB "OSV Europos technologija"' },
  { code: 302910928, name: "UAB GERVAITA" },
  { code: 302911204, name: "UAB PIMD" },
  { code: 302911396, name: 'Mažoji bendrija "Batareika"' },
  { code: 302912110, name: 'UAB "Žalia galia"' },
  { code: 302912181, name: 'UAB "Virtuvės baldai"' },
  { code: 302912459, name: "UAB Isotope" },
  { code: 302912473, name: "UAB Rhomb" },
  { code: 302913212, name: 'MB "Rytinė saulė"' },
  { code: 302913358, name: "MB MP energy" },
  { code: 302913504, name: 'UAB "Boleris3"' },
  { code: 302913931, name: "MB SL Energy" },
  { code: 302914054, name: 'MB "Energy business partners"' },
  { code: 302914168, name: 'UAB "SOLHESA"' },
  { code: 302914385, name: 'MB "Eurostairs"' },
  { code: 302914855, name: "UAB LAMEKSTA" },
  { code: 302915131, name: "UAB DARGUVIS" },
  { code: 302915156, name: "UAB CYPRUS ENERGY" },
  { code: 302915195, name: "UAB ELTEKAS" },
  { code: 302915213, name: "UAB TEKSOLIS" },
  { code: 302915626, name: 'MB "MT Duty"' },
  { code: 302915633, name: 'MB "IT Ferre"' },
  { code: 302915640, name: 'MB "InterModum"' },
  { code: 302915658, name: "MB SP Energy" },
  { code: 302916265, name: "MB ELSAN" },
  { code: 302916482, name: 'UAB "Granulita Energy"' },
  { code: 302916945, name: 'UAB "Neilandera"' },
  { code: 302917342, name: 'UAB "Elektrisitet"' },
  { code: 302917495, name: 'MB "Papirus plius"' },
  { code: 302918145, name: 'MB "GUUD"' },
  { code: 302918490, name: 'Mažoji bendrija "Druskininkų vaistažolės"' },
  { code: 302918647, name: "MB Aimijus" },
  {
    code: 302918896,
    name: 'Ribotos atsakomybės bendrovės "ATLANT Multiservice" filialas'
  },
  { code: 302919471, name: "MB PUŠYNĖLIAI" },
  { code: 302919489, name: "MB VAJANA" },
  { code: 302919496, name: "MB RAMI ŠIRŠĖ" },
  { code: 302919507, name: "MB GERDANIJA" },
  { code: 302919521, name: 'MB "OLASA"' },
  { code: 302919539, name: "MB FOLAS" },
  { code: 302919546, name: "MB PIEVYS" },
  { code: 302919560, name: "MB LAIMĖS" },
  { code: 302919610, name: "MB DARLAIMĖ" },
  { code: 302919635, name: "MB LAIMUŽĖLĖ" },
  { code: 302919674, name: "MB ŽALIAS ŽOLYNĖLIS" },
  { code: 302919681, name: "MB LAIMESA" },
  { code: 302919699, name: "MB LAIMĖS PIEVA" },
  { code: 302919731, name: "UAB VM PRODUCTION" },
  { code: 302919877, name: "MB SĖJAS" },
  { code: 302919884, name: "MB ALFAI" },
  { code: 302919902, name: "MB LADASA" },
  { code: 302919927, name: "MB KEITAI" },
  { code: 302919934, name: "UAB Swallow" },
  { code: 302919941, name: "MB ARLAS" },
  { code: 302919966, name: "MB KADARIUS" },
  { code: 302919973, name: "MB MUMBA" },
  { code: 302919980, name: "MB PIMAS" },
  { code: 302920139, name: "MB LURDA" },
  { code: 302920203, name: "MB GĖRISA" },
  { code: 302920267, name: "MB DARKAS" },
  { code: 302920281, name: 'UAB "RINTIS E"' },
  { code: 302920317, name: "MB LAUMANA" },
  { code: 302920331, name: "MB LASAULĖ" },
  { code: 302920527, name: "MB ČANGAS" },
  { code: 302920541, name: "MB TILAKALA" },
  { code: 302920580, name: "MB TILTAKA" },
  { code: 302920598, name: "MB SAULAS" },
  { code: 302920609, name: "MB TILTALA" },
  { code: 302920623, name: "MB ELASAS" },
  { code: 302920655, name: "MB MANOJA" },
  { code: 302920712, name: "MB LAKASA" },
  { code: 302920744, name: "MB MARSĖ" },
  { code: 302920776, name: "MB HERMAS" },
  { code: 302920783, name: "MB FARLA" },
  { code: 302920801, name: "MB CUNGA" },
  { code: 302920826, name: "MB GELUŽĖ" },
  { code: 302920833, name: "MB GAMAS" },
  { code: 302921102, name: "MB SONGAS" },
  { code: 302921127, name: 'MB "Vytrasola"' },
  { code: 302921134, name: 'MB "FINGO reklama"' },
  { code: 302921141, name: "MB AIŠKAS" },
  { code: 302921159, name: "MB DALASA" },
  { code: 302921180, name: "MB BETANA" },
  { code: 302921198, name: "MB DARSĖ" },
  { code: 302921209, name: "MB DELTAS" },
  { code: 302921216, name: "MB IRKA" },
  { code: 302921223, name: "Mažoji bendrija SUNDYS" },
  { code: 302921255, name: "MB SAULUTĖ D" },
  { code: 302921305, name: 'MB "Perojus"' },
  { code: 302921344, name: "MB SAULUTĖ E" },
  { code: 302921390, name: "MB SAULUTĖ G" },
  { code: 302921419, name: "MB SAULUTĖ J" },
  { code: 302921426, name: 'MB "Saulės elfas"' },
  { code: 302921433, name: 'MB "Saulės idilė"' },
  { code: 302921508, name: "UAB Calorie" },
  { code: 302922243, name: "UAB M-Tree" },
  { code: 302922542, name: 'MB "Saulės grifas"' },
  { code: 302922574, name: 'MB "Saulės ciklas"' },
  { code: 302922599, name: 'MB "Saulės jūra"' },
  { code: 302922617, name: 'MB "Saulės kasa"' },
  { code: 302922624, name: 'MB "Saulės bardas"' },
  { code: 302922962, name: 'MB "MIŠKIAI"' },
  { code: 302922994, name: 'UAB "Minvastos investicijos"' },
  { code: 302923466, name: 'UAB "BALTICORA"' },
  { code: 302924212, name: 'MB "Interi"' },
  { code: 302924493, name: "MB INVELITA" },
  { code: 302924511, name: 'UAB "Rievė"' },
  { code: 302926487, name: 'UAB "FATI"' },
  { code: 302926601, name: 'UAB "Girjuda"' },
  { code: 302927518, name: "SUNBIO, MB" },
  { code: 302927596, name: 'MB "Em Projektas"' },
  { code: 302927703, name: 'MB "Projektas Ka"' },
  { code: 302927774, name: "UAB DONTEKS" },
  { code: 302929241, name: 'UAB "Nerisena"' },
  { code: 302929355, name: 'UAB "Arlekino furniture"' },
  { code: 302929608, name: 'UAB "Eko domu"' },
  { code: 302929686, name: "Idex Biruliškių UAB" },
  { code: 302930966, name: 'UAB "ALMARKAS"' },
  { code: 302931064, name: 'MB "Auges energija"' },
  { code: 302931452, name: 'UAB "EJ Baltic"' },
  { code: 302932497, name: 'MB "Saulvėja"' },
  { code: 302933528, name: 'MB "ŽD Energija"' },
  { code: 302933581, name: 'MB "KK Energy"' },
  { code: 302933656, name: 'UAB "Momentum group"' },
  { code: 302933663, name: 'MB "Saulvita"' },
  { code: 302933752, name: 'UAB "Autonal"' },
  { code: 302933909, name: "Mažoji bendrija MARIJA 2" },
  { code: 302933916, name: "Mažoji bendrija Marija 5" },
  { code: 302933923, name: "Mažoji bendrija ALOYZAS 2" },
  { code: 302933930, name: "Mažoji bendrija Marija 4" },
  { code: 302933955, name: "Mažoji bendrija ALOYZAS 3" },
  { code: 302933962, name: "Mažoji bendrija Marija 3" },
  { code: 302933987, name: "Mažoji bendrija ALOYZAS 1" },
  { code: 302933994, name: "Mažoji bendrija MARIJA 1" },
  { code: 302934007, name: "Mažoji bendrija ALOYZAS 5" },
  { code: 302934014, name: "Mažoji bendrija ALOYZAS 4" },
  { code: 302934085, name: 'UAB "Norbuksas"' },
  { code: 302934690, name: "MB SOLARIS OPS" },
  { code: 302934701, name: "UAB ELSOLIS" },
  { code: 302937042, name: "MB Saubera" },
  { code: 302938393, name: 'UAB "BURNERA"' },
  { code: 302940113, name: "UAB HISE" },
  { code: 302940679, name: 'UAB "Urtnojus"' },
  { code: 302940914, name: 'UAB "Tomalis"' },
  { code: 302941119, name: "Micro Solaris, MB" },
  { code: 302941959, name: "Kaimukas, MB" },
  { code: 302942018, name: "UAB Chalet Baltic" },
  { code: 302944738, name: "MB Microgen Energy" },
  { code: 302946696, name: "MB SUNTRAP" },
  { code: 302947620, name: 'UAB "Šiluma Tau"' },
  { code: 302948590, name: 'MB "AIRAMA"' },
  { code: 302949393, name: 'MB "ArtemBaldai"' },
  { code: 302950417, name: 'MB "ALBARAS"' },
  { code: 302950424, name: 'UAB "Jovaro langai"' },
  { code: 302950463, name: "MB JUAGRA" },
  { code: 302950513, name: 'UAB "Furnivita"' },
  { code: 302953484, name: 'UAB "KODIJA"' },
  { code: 302956352, name: 'UAB "Bioenergy LT"' },
  { code: 302956929, name: 'MB "Pažiedupys"' },
  { code: 302958022, name: "UAB Balt windows" },
  { code: 302961965, name: "UAB Tąšelis" },
  { code: 302962412, name: "MB VERUS-EUROPE" },
  { code: 302962864, name: 'Mažoji bendrija "Vesca"' },
  { code: 302965426, name: "RJ Energija, MB" },
  { code: 302968949, name: 'UAB "Aldec General"' },
  { code: 302970366, name: "UAB VENT DIX" },
  { code: 302975438, name: "UAB INSCOCO" },
  { code: 302977909, name: "UAB VINAPACK" },
  { code: 302979504, name: 'MB "Langveda"' },
  { code: 302980887, name: "UAB KleiderHaus" },
  { code: 302986961, name: 'MB "Adorama"' },
  { code: 302987401, name: 'UAB "EGIDAVA"' },
  { code: 302992359, name: 'MB "BALDMEDA"' },
  { code: 302993443, name: 'Mažoji bendrija "Pontoon LT"' },
  { code: 302994926, name: 'MB "5 VOLTAI"' },
  { code: 302995519, name: 'MB "Black-snail"' },
  { code: 302995946, name: 'UAB "HG Plastic"' },
  { code: 302997363, name: 'Mažoji bendrija "Savi medžio sprendimai"' },
  { code: 302998390, name: "MB Liepalotų statyba" },
  { code: 302998757, name: 'MB "Laiptų konstrukcijos"' },
  { code: 303000364, name: 'UAB "MBE Group Company"' },
  { code: 303000407, name: 'UAB "CP baldai"' },
  { code: 303001555, name: "Mažoji bendrija GISKA" },
  { code: 303001644, name: 'MB "Truikių mediena"' },
  { code: 303002760, name: "WINDLIT, UAB" },
  { code: 303003620, name: 'UAB "Sistemis"' },
  { code: 303004996, name: 'MB "Augisbaldai"' },
  { code: 303005856, name: 'UAB "Medžio verslas ir konsultacijos"' },
  { code: 303006673, name: 'UAB "EVJ Technologies"' },
  { code: 303009833, name: 'UAB "Nerveda"' },
  { code: 303010255, name: 'UAB "Grenasa"' },
  { code: 303011275, name: 'UAB "Sūsmeda"' },
  { code: 303011414, name: "MB Žygėna" },
  { code: 303012929, name: "Norgia UAB" },
  { code: 303013290, name: "MB MINSAGA" },
  { code: 303013372, name: 'UAB "West Production Group"' },
  { code: 303014346, name: "Aspertus Group UAB" },
  { code: 303016379, name: "MB FONTA" },
  { code: 303017214, name: 'MB "MĖJA LT"' },
  { code: 303019852, name: 'UAB "Kike"' },
  { code: 303021006, name: 'UAB "AGRA Ferti LT"' },
  { code: 303022026, name: 'UAB "BIOSENA"' },
  { code: 303022154, name: "UAB KORELA" },
  { code: 303023530, name: 'UAB "IMELSA"' },
  { code: 303025677, name: 'UAB "Escolit"' },
  { code: 303028723, name: 'UAB "Oridijaus baldai"' },
  { code: 303028844, name: 'UAB "Kvapai ir spalvos"' },
  { code: 303028869, name: "MB Laikraštis Dovanų" },
  { code: 303029565, name: "MB Luteva" },
  { code: 303029654, name: "John&Martinas IĮ" },
  { code: 303031406, name: 'UAB "Evina"' },
  { code: 303031413, name: 'MB "MINI CARGO LT"' },
  { code: 303031865, name: "UAB Log Villa" },
  { code: 303032216, name: 'UAB "GAZAUTO"' },
  { code: 303034587, name: 'MB "Platanas"' },
  { code: 303036894, name: "MB Krovos projektai" },
  { code: 303037327, name: "UAB Pelkini" },
  { code: 303038144, name: 'UAB "ABRALIT"' },
  { code: 303038945, name: 'UAB "Octavius trade"' },
  { code: 303039463, name: 'UAB "Master of Constructions"' },
  { code: 303041749, name: "MB Juvyta" },
  { code: 303041756, name: 'UAB "Natūralios idėjos"' },
  { code: 303042388, name: 'MB "Rūtos baldai"' },
  { code: 303043732, name: 'UAB "Flexiprint.lt"' },
  { code: 303043796, name: "UAB NAIRA LT" },
  { code: 303045352, name: "UAB Elais" },
  { code: 303045847, name: 'UAB "PUORIŲ MEDIENA"' },
  { code: 303045950, name: 'MB "DAIVOS BALDŲ STUDIJA"' },
  { code: 303046696, name: "V.Guobienės IĮ" },
  { code: 303048003, name: "MB SVIPEKI" },
  { code: 303051031, name: "Kamė, UAB" },
  { code: 303051070, name: 'UAB "KG Group LT"' },
  { code: 303053388, name: 'IĮ "ERAE"' },
  { code: 303053413, name: "UAB LIŪTIS" },
  { code: 303053420, name: "UAB EVALDO BALDAI" },
  { code: 303053573, name: "A and D Plastic, MB" },
  { code: 303053655, name: 'UAB "BFP Group"' },
  { code: 303054522, name: 'UAB "FARZADA"' },
  { code: 303056929, name: 'MB "Stilo baldai"' },
  { code: 303058684, name: 'UAB "Impexus"' },
  { code: 303061901, name: "MB Driu Beauty" },
  { code: 303063293, name: 'UAB "Liarta"' },
  { code: 303065159, name: 'UAB "ALJARA"' },
  { code: 303065390, name: 'UAB "Sonreta"' },
  { code: 303065522, name: 'UAB "Dzūkijos kartonas"' },
  { code: 303065910, name: 'MB "Baldų interjeras"' },
  { code: 303066001, name: 'UAB "Komforto sprendimai"' },
  { code: 303066909, name: "UAB Elastoforce" },
  { code: 303067391, name: "UAB Morenot Baltic" },
  { code: 303068867, name: 'UAB "Lietuvos cukraus fabrikas"' },
  { code: 303070868, name: 'UAB "Dekogama"' },
  { code: 303071895, name: 'UAB "Skudžiūnų pašarai"' },
  { code: 303073693, name: 'UAB "Kosmita"' },
  { code: 303073864, name: 'UAB "Zert LT"' },
  { code: 303074877, name: 'UAB "Domila"' },
  { code: 303074973, name: 'UAB "Grainmore"' },
  { code: 303077994, name: 'UAB "Tegrana"' },
  { code: 303079244, name: 'UAB "DIRSIS"' },
  { code: 303082105, name: 'UAB "Akrilana"' },
  { code: 303082265, name: 'UAB "Gama baldai"' },
  { code: 303085763, name: "UAB Šviežio maisto įmonių grupė" },
  { code: 303086477, name: 'UAB "Uolus"' },
  { code: 303087369, name: 'MB "Linrasta"' },
  { code: 303087433, name: 'UAB "Bosneka"' },
  { code: 303087981, name: "UAB Arsenalo grupė" },
  { code: 303089854, name: 'UAB "Resursas"' },
  { code: 303090027, name: 'UAB "Alpuras"' },
  { code: 303090283, name: 'UAB "Regda"' },
  { code: 303090365, name: "FORCEMAX, UAB" },
  { code: 303091652, name: 'Uždaroji akcinė bendrovė "Laporta"' },
  { code: 303092373, name: 'UAB "Luknepa"' },
  { code: 303092551, name: 'UAB "Alytaus popierius"' },
  { code: 303092583, name: 'UAB "Modiled"' },
  { code: 303093087, name: "UAB Jautienos guru" },
  { code: 303093703, name: 'UAB "Vonin Lithuania"' },
  { code: 303094933, name: "Artline sprendimai MB" },
  { code: 303096756, name: 'UAB "Apstructus"' },
  { code: 303096927, name: 'UAB "Merinta"' },
  { code: 303097922, name: "UAB VERITAVA" },
  { code: 303098166, name: 'MB "Liepas"' },
  { code: 303101356, name: 'UAB "Verslana"' },
  { code: 303101370, name: 'MB "Paslaugos gamybai"' },
  { code: 303101655, name: "UAB EVABOX" },
  { code: 303102928, name: 'UAB "Hertas"' },
  { code: 303106442, name: 'UAB "Lietmeda"' },
  { code: 303107423, name: '"Plėšrūnas", MB' },
  { code: 303107640, name: 'MB "Meidvita"' },
  { code: 303107747, name: '"Probaldai", UAB' },
  { code: 303111425, name: 'UAB "Kevys"' },
  { code: 303113636, name: 'UAB "Gaventa"' },
  { code: 303113675, name: 'MB "MFurniture"' },
  { code: 303114033, name: 'UAB "REMESKAS"' },
  { code: 303115174, name: "UAB Agrokompanija" },
  { code: 303115313, name: 'UAB "Geltonas lapas"' },
  { code: 303117353, name: 'UAB "Visiga"' },
  { code: 303117595, name: 'MB "Pagirių projektai"' },
  { code: 303117848, name: 'MB "Olra"' },
  { code: 303118747, name: 'UAB "Baltijos beržas"' },
  { code: 303120029, name: "Mažoji bendrija D art baldai" },
  { code: 303121298, name: 'MB "Kėkšto inovacijos"' },
  { code: 303123064, name: 'MB "Skiga"' },
  { code: 303124465, name: 'UAB "Biržų vėjas"' },
  { code: 303125389, name: 'UAB "Ekokomplektas"' },
  { code: 303126879, name: 'UAB "Korista"' },
  { code: 303126904, name: 'UAB "Artus LT"' },
  { code: 303127568, name: 'UAB "Gildera"' },
  { code: 303127753, name: 'MB "Foreska"' },
  { code: 303127828, name: 'MB "Marškinėliai"' },
  { code: 303130429, name: 'UAB "Gintariniai baldai"' },
  { code: 303131958, name: 'UAB "Skobva"' },
  { code: 303132273, name: 'MB "Suklys"' },
  { code: 303132469, name: 'MB "Keružis"' },
  { code: 303134712, name: 'UAB "Propeleta"' },
  { code: 303134783, name: 'UAB "ANORD"' },
  { code: 303138746, name: 'UAB "Avastatos grupė"' },
  { code: 303139378, name: 'UAB "Geras baldas"' },
  { code: 303143800, name: 'MB "Atstatymas"' },
  { code: 303148263, name: 'UAB "Baltijos eukutecas"' },
  { code: 303159288, name: 'UAB "Paulas"' },
  { code: 303160155, name: 'MB "Doko sofa"' },
  { code: 303161944, name: 'UAB "Furnitor"' },
  { code: 303164189, name: 'UAB "Termoplastikas"' },
  { code: 303164310, name: 'UAB "Edeno baldai"' },
  { code: 303166980, name: 'UAB "Vox Baltica"' },
  { code: 303167178, name: 'UAB "Stonkai"' },
  { code: 303168885, name: 'UAB "Agitela"' },
  { code: 303169097, name: 'MB "Nipras"' },
  { code: 303169179, name: 'MB "Kreinta"' },
  { code: 303170669, name: "UAB Stillo" },
  { code: 303170950, name: 'IĮ "Miško putinas"' },
  { code: 303174614, name: 'UAB "Adpama"' },
  { code: 303177813, name: 'MB "Optimos baldai"' },
  { code: 303177838, name: 'UAB "Vaido medis"' },
  { code: 303178388, name: 'UAB "Baltic Panels"' },
  { code: 303178420, name: 'UAB "SPG Group"' },
  { code: 303178908, name: 'UAB "Trečiokas"' },
  { code: 303179650, name: 'UAB "Sienojus"' },
  { code: 303182543, name: 'UAB "Vilna LT"' },
  { code: 303182664, name: 'UAB "Merkadus"' },
  { code: 303183613, name: 'MB "Edo"' },
  { code: 303184850, name: "UAB Alstiko" },
  { code: 303186381, name: 'MB "Lipukas"' },
  { code: 303188980, name: 'UAB "Gamybos parkas"' },
  { code: 303190006, name: 'UAB "Bravika"' },
  { code: 303190084, name: "Sofų fabrikas, UAB" },
  { code: 303190180, name: 'UAB "Mobili grupė"' },
  { code: 303191229, name: 'UAB "RV Energija"' },
  { code: 303192021, name: 'UAB "Airega"' },
  { code: 303192441, name: 'SIA "OLDERI" atstovybė' },
  { code: 303192562, name: 'UAB "Magnum reditus"' },
  { code: 303192612, name: 'UAB "Vilanus"' },
  { code: 303193493, name: 'MB "Arkmeta"' },
  { code: 303193792, name: 'UAB "Baldai sielai"' },
  { code: 303193874, name: 'UAB "Delaris"' },
  { code: 303193924, name: 'UAB "Feriatus"' },
  { code: 303193956, name: 'UAB "Avia LT"' },
  { code: 303193988, name: 'UAB "MOTOROJUS"' },
  { code: 303199222, name: 'UAB "Brikers LT"' },
  { code: 303199980, name: 'UAB "Interama"' },
  { code: 303201004, name: 'UAB "Rimus"' },
  { code: 303202864, name: 'UAB "Stileta"' },
  { code: 303202939, name: 'UAB "Turbalda"' },
  { code: 303202960, name: 'UAB "Amrus"' },
  { code: 303203083, name: 'UAB "Technologijos"' },
  { code: 303203304, name: '"Laumartis", MB' },
  { code: 303204438, name: 'UAB "Vindita"' },
  { code: 303204598, name: 'UAB "Alvanta"' },
  { code: 303207669, name: 'UAB "Kaimelis LT"' },
  { code: 303211436, name: 'UAB "Myan Yachts" servisas' },
  { code: 303212050, name: 'UAB "Šilutės vėjo parkas 2"' },
  { code: 303212495, name: 'UAB "Pelėdos baldai"' },
  { code: 303215790, name: "UAB MARTIN CONSTRUCTION" },
  { code: 303215922, name: 'UAB "Mediniai laiptai"' },
  { code: 303216718, name: 'UAB "Sortolita"' },
  { code: 303218911, name: 'UAB "Moronė"' },
  { code: 303219123, name: 'UAB "Kotrynos baldai LT"' },
  { code: 303222518, name: 'UAB "Korio formulė"' },
  { code: 303222589, name: 'UAB "Doterus"' },
  { code: 303222728, name: 'UAB "Grūdmilta"' },
  { code: 303223901, name: 'UAB "Sontera"' },
  { code: 303224914, name: 'UAB "Medžio raštai"' },
  { code: 303225457, name: 'UAB "Biovatas"' },
  { code: 303226630, name: 'UAB "Medeinus"' },
  { code: 303227789, name: 'UAB "Medila"' },
  { code: 303227892, name: 'UAB "Alesa"' },
  { code: 303228542, name: 'UAB "Stanesta"' },
  { code: 303228617, name: 'UAB "Gusto baldai"' },
  { code: 303232220, name: 'IĮ "Statybų žinovas"' },
  { code: 303234132, name: 'Mažoji bendrija "Sūrio ekspertai"' },
  { code: 303235323, name: 'UAB "LHM Interior"' },
  { code: 303235362, name: 'UAB "LHM Step"' },
  { code: 303235679, name: 'UAB "Maxi Bonum"' },
  { code: 303235896, name: 'UAB "Kartono projektai"' },
  { code: 303235992, name: 'UAB "Ordera"' },
  {
    code: 303236172,
    name: "Mažoji bendrija Lietuvos Didžiosios Kunigaikštystės plytinė"
  },
  { code: 303236279, name: 'UAB "Canchema"' },
  { code: 303238472, name: 'UAB "Fiksikai"' },
  { code: 303239421, name: 'UAB "Tera LT"' },
  { code: 303242378, name: 'UAB "Rigresta"' },
  { code: 303242606, name: 'UAB "Lotagma"' },
  { code: 303243754, name: 'MB "Bratus FF"' },
  { code: 303243925, name: 'UAB "Roloteka"' },
  { code: 303244169, name: 'UAB "Rytų sprendimai"' },
  { code: 303245214, name: 'UAB "Nero baldai"' },
  { code: 303245335, name: 'UAB "Džemo baldai"' },
  { code: 303245958, name: 'UAB "Kauno agricentras"' },
  { code: 303249173, name: 'UAB "Litaiva"' },
  { code: 303250446, name: 'MB "Joritoma"' },
  { code: 303250535, name: 'UAB "Pašarų eksportas ir importas"' },
  { code: 303251943, name: 'UAB "Biotrąšos"' },
  { code: 303252187, name: 'UAB "Pjaustida"' },
  { code: 303252486, name: 'UAB "Smaragdo baldai"' },
  { code: 303252995, name: 'UAB "Novos projektai"' },
  { code: 303253086, name: 'UAB "Auvira"' },
  { code: 303254067, name: "UAB Biofertalis" },
  { code: 303254971, name: 'UAB "Arolesa"' },
  { code: 303255233, name: 'UAB "Konterplata"' },
  { code: 303255272, name: "MB Mažiūnų malūnai" },
  { code: 303256488, name: 'UAB "Mecorus"' },
  { code: 303257184, name: 'UAB "Mahadeva"' },
  { code: 303258902, name: "UAB Ripis" },
  { code: 303259954, name: 'MB "Kauno hidraulika"' },
  { code: 303260141, name: "R. Janavičiūtės IĮ" },
  { code: 303261026, name: 'UAB "Litvalana"' },
  { code: 303261211, name: 'UAB "Siguldos baldų gamyba"' },
  { code: 303261396, name: 'UAB "Laipmeda"' },
  { code: 303262007, name: 'UAB "Marisolis"' },
  { code: 303263461, name: 'MB "MEDPJOVA"' },
  { code: 303265569, name: '"Transbaltis", UAB' },
  { code: 303265754, name: '"Kamada", UAB' },
  { code: 303266614, name: 'MB "Virtuvė visiems"' },
  { code: 303267634, name: 'UAB "Baldų ekspertai"' },
  { code: 303270491, name: 'UAB "Kretingos baldai"' },
  { code: 303271611, name: 'UAB "Pašušvio energija"' },
  { code: 303271629, name: 'MB "Prometheus LT"' },
  { code: 303272567, name: 'MB "Stepo medis"' },
  { code: 303274212, name: '"Kirigami Design", UAB' },
  { code: 303274237, name: 'UAB "Agromėsa"' },
  { code: 303274447, name: 'Uždaroji akcinė bendrovė "Urbita"' },
  { code: 303275531, name: 'UAB "Universalus techninis servisas"' },
  { code: 303276658, name: 'VšĮ "LTU-1000"' },
  { code: 303277799, name: 'IĮ "Evgen"' },
  { code: 303283211, name: 'VšĮ "Senasis Ramygalos malūnas"' },
  { code: 303283898, name: 'UAB "Biretra"' },
  { code: 303286047, name: '"Idėja 3D", UAB' },
  { code: 303286104, name: 'UAB "Lingos sprendimai"' },
  { code: 303286613, name: 'UAB "Lerenta"' },
  { code: 303287505, name: 'UAB "Etoma"' },
  { code: 303287697, name: 'UAB "Ledmada"' },
  { code: 303287747, name: 'UAB "Medmeda"' },
  { code: 303289082, name: 'UAB "CSUB LT"' },
  { code: 303289239, name: "UAB Kairio studija" },
  { code: 303290451, name: '"Esbaldai", UAB' },
  { code: 303292783, name: 'IĮ "Medžio konversija"' },
  { code: 303292954, name: 'UAB "Numaitis"' },
  { code: 303293878, name: '"Vitramas", UAB' },
  { code: 303294446, name: 'UAB "S-brand"' },
  { code: 303295181, name: 'MB "Alsta LT"' },
  { code: 303297652, name: "PKC Group Lithuania UAB" },
  { code: 303297780, name: 'UAB "Laudelita"' },
  { code: 303298754, name: 'UAB "Livrista"' },
  { code: 303299055, name: 'UAB "Ventos paminklai"' },
  { code: 303299500, name: 'UAB "Edenpa"' },
  { code: 303299639, name: 'UAB "Milsita"' },
  { code: 303299767, name: 'UAB "Raskilė"' },
  { code: 303299888, name: 'UAB "Kondro baldai"' },
  { code: 303301150, name: 'UAB "Milpra"' },
  { code: 303303151, name: 'MB "Stiklo namas"' },
  { code: 303305259, name: 'UAB "Granorama"' },
  { code: 303305405, name: 'UAB "Denitus"' },
  { code: 303305412, name: 'UAB "Landista"' },
  { code: 303305490, name: 'MB "Illusionis lux"' },
  { code: 303305978, name: 'UAB "Vendiplas"' },
  { code: 303306998, name: 'UAB "Migansa"' },
  { code: 303309275, name: 'UAB "Tvirtė"' },
  { code: 303310060, name: "Allive Europe UAB" },
  { code: 303310224, name: 'UAB "NTA Baltic"' },
  { code: 303310338, name: 'UAB "Mano miegas"' },
  { code: 303312086, name: '"Alksnio projektai", MB' },
  { code: 303313067, name: 'UAB "Savas produktas"' },
  { code: 303315164, name: 'UAB "Ars vitri"' },
  { code: 303315506, name: "UAB Emhus" },
  { code: 303316138, name: 'Valstybės įmonė "Mūsų amatai"' },
  { code: 303317069, name: 'UAB "Euroginta"' },
  { code: 303317717, name: 'Asociacija "Pegasus artis"' },
  { code: 303322194, name: 'UAB "Klaikonta"' },
  { code: 303322575, name: 'UAB "Margi dygsniai"' },
  { code: 303323232, name: '"Aukštaitijos žvėriena", UAB' },
  { code: 303323314, name: 'MB "Ekoaura"' },
  { code: 303327049, name: '"Iglo namelis", MB' },
  { code: 303327120, name: 'UAB "Boskita"' },
  { code: 303327907, name: 'UAB "Autoir"' },
  { code: 303328489, name: '"Erneta", UAB' },
  { code: 303329915, name: 'MB "Zenverio baldai"' },
  { code: 303331318, name: '"Altas IT", UAB' },
  { code: 303334307, name: "UAB KIGI LT" },
  { code: 303334937, name: 'UAB "Sipkonas"' },
  { code: 303335149, name: '"Antransa", UAB' },
  { code: 303335334, name: 'UAB "Vaideka"' },
  { code: 303336087, name: 'UAB "SBT Textile"' },
  { code: 303336265, name: 'UAB "Armandika"' },
  { code: 303338145, name: 'UAB "Nobeliai"' },
  { code: 303338177, name: 'Mažoji bendrija "Tiksli linija"' },
  { code: 303339471, name: 'UAB "Govilė"' },
  { code: 303339628, name: 'UAB "Gausma"' },
  { code: 303339749, name: 'UAB "Pranveda"' },
  { code: 303340491, name: 'UAB "Sonerta"' },
  { code: 303340598, name: 'MB "Garsu"' },
  { code: 303341433, name: 'IĮ "Roserga LT"' },
  { code: 303342065, name: 'UAB "Inovaciniai virtuozai"' },
  { code: 303342567, name: 'UAB "Bioselos grupė"' },
  { code: 303342670, name: 'UAB "Laisparnis"' },
  { code: 303342713, name: 'UAB "Niklera"' },
  { code: 303344130, name: 'IĮ "Ievos gamyba"' },
  { code: 303344187, name: 'UAB "Aitvaro skrydis"' },
  { code: 303344778, name: 'UAB "Nowadays Technologies"' },
  { code: 303344956, name: 'UAB "Vaiterona"' },
  { code: 303345392, name: 'UAB "Interjero inovacijos"' },
  { code: 303345855, name: 'UAB "Tvigas"' },
  { code: 303346341, name: "Decoflux home, UAB" },
  { code: 303347404, name: 'UAB "Dailius ir Ko"' },
  { code: 303349152, name: 'UAB "Sontesa"' },
  { code: 303349540, name: 'UAB "Mirovita"' },
  { code: 303350496, name: 'UAB "Jungtinės stalių pajėgos"' },
  { code: 303350592, name: 'UAB "Varsena"' },
  { code: 303350838, name: 'UAB "Medžio dizaino aspektai"' },
  { code: 303351705, name: 'MB "Artoko grupė"' },
  { code: 303353364, name: 'UAB "Kitokia idėja"' },
  { code: 303354690, name: 'UAB "Stogų magija"' },
  { code: 303354879, name: "UAB EKO Perdirbimas" },
  { code: 303356652, name: 'UAB "Bioveža"' },
  { code: 303356912, name: 'UAB "Sandriff international"' },
  { code: 303363013, name: 'UAB "Nereta"' },
  { code: 303364122, name: 'UAB "Kanapės žiedas"' },
  { code: 303366970, name: 'UAB "Doto grupė"' },
  { code: 303367054, name: 'UAB "Perfektika"' },
  { code: 303367894, name: 'MB "Baldų idėjų fabrikas"' },
  { code: 303370196, name: 'UAB "Rolmedis"' },
  { code: 303371017, name: 'UAB "Edva Motorsport"' },
  { code: 303371330, name: 'UAB "Simareta"' },
  { code: 303373071, name: 'UAB "Super prekyba"' },
  { code: 303373922, name: 'UAB "DELTUVOS MEDIENA"' },
  { code: 303374038, name: 'UAB "Akvariumų sodai"' },
  { code: 303375300, name: 'UAB "Baldai sau"' },
  { code: 303376459, name: 'UAB "Magnalis"' },
  { code: 303379117, name: 'UAB "Aleksas Printas"' },
  { code: 303379633, name: "UAB Lubų Centras" },
  { code: 303379836, name: 'UAB "Medis & padėklai"' },
  { code: 303381253, name: "Deethifi consulting, UAB" },
  { code: 303381488, name: "Igorio Milio IĮ" },
  { code: 303383425, name: 'UAB "Aromatas"' },
  { code: 303384121, name: 'UAB "Vilniaus biokuras"' },
  { code: 303384947, name: 'UAB "Molmeta"' },
  { code: 303385045, name: 'VšĮ "Karmazinų keramika"' },
  { code: 303385305, name: 'MB "Agroprima"' },
  { code: 303385401, name: 'UAB "Baltų medis"' },
  { code: 303389474, name: '"Ūko medis", UAB' },
  { code: 303390601, name: 'UAB "Gudruolis"' },
  { code: 303391240, name: 'UAB "V. R. Laivininkystė"' },
  { code: 303392901, name: 'UAB "Eilvera"' },
  { code: 303397478, name: 'UAB "Automobilių dalys"' },
  { code: 303398879, name: 'UAB "Bioheat"' },
  { code: 303400554, name: 'UAB "Rūko karoliai"' },
  { code: 303400725, name: '"Rustikas", UAB' },
  { code: 303402046, name: 'UAB "Akustinės biotechnologijos"' },
  { code: 303403486, name: 'UAB "VT Trade Europe"' },
  { code: 303403707, name: 'UAB "Anykščių baldai"' },
  { code: 303406411, name: 'UAB "Lingreta"' },
  { code: 303406429, name: 'UAB "Torgera"' },
  { code: 303407029, name: 'UAB "Porolis"' },
  { code: 303409471, name: 'UAB "Metalstoma"' },
  { code: 303410235, name: 'UAB "Pilaitės mediena"' },
  { code: 303411903, name: 'UAB "Baltijos eksportas"' },
  { code: 303414874, name: 'UAB "Keturbriaunis"' },
  { code: 303416882, name: 'UAB "Lomista"' },
  { code: 303417895, name: 'UAB "Geldauda"' },
  { code: 303420432, name: 'UAB "Laiptasija"' },
  { code: 303422209, name: 'UAB "Ekostra"' },
  { code: 303422472, name: 'UAB "Rigesta"' },
  { code: 303423407, name: 'UAB "Dotrema"' },
  { code: 303425511, name: "AnVa Polytech, UAB" },
  { code: 303428482, name: 'UAB "Virgena"' },
  { code: 303429577, name: 'IĮ "KRYVORUCHKO OLEKSANDR"' },
  { code: 303431158, name: 'UAB "Spektronas"' },
  { code: 303432445, name: 'UAB "Aukso bitė"' },
  { code: 303432961, name: 'UAB "Steelita"' },
  { code: 303439386, name: 'UAB "Verialis"' },
  { code: 303439411, name: 'UAB "Replasta"' },
  { code: 303439991, name: 'UAB "Sonalus"' },
  { code: 303440680, name: 'UAB "Modernūs baldai"' },
  { code: 303440926, name: 'UAB "Uniko projektai"' },
  { code: 303444440, name: 'MB "Baldelita"' },
  { code: 303446085, name: 'MB "Hegreda"' },
  { code: 303448894, name: 'UAB "Eurocemaris"' },
  { code: 303449487, name: 'UAB "Galuono medis"' },
  { code: 303450411, name: 'UAB "Abonas"' },
  { code: 303451463, name: 'UAB "Grantecha"' },
  { code: 303452394, name: 'UAB "Sailora"' },
  { code: 303455860, name: 'UAB "Metalynas"' },
  { code: 303456930, name: 'UAB "Ąžuoliuko interjeras"' },
  { code: 303459403, name: "Aktsiaselts BLRT ERA Klaipėdos filialas" },
  { code: 303464350, name: 'UAB "Sitecha"' },
  { code: 303465897, name: 'Mažoji bendrija "Doridus"' },
  { code: 303466967, name: 'UAB "Dormana"' },
  { code: 303469493, name: '"TGS Group", UAB' },
  { code: 303470392, name: 'MB "Mikrobas"' },
  { code: 303472386, name: 'UAB "NTV KOMEX"' },
  { code: 303472913, name: "UAB Aisytės mėsinė" },
  { code: 303473367, name: 'UAB "Glasgas"' },
  { code: 303474469, name: 'UAB "Ofertus"' },
  { code: 303474978, name: 'UAB "Laisva Baltijos grupė"' },
  { code: 303475489, name: 'MB "Šviesos formos"' },
  { code: 303478460, name: 'UAB "Tėviškės mėsa"' },
  { code: 303478492, name: '"Steerprop", UAB' },
  { code: 303482868, name: 'UAB "Rėmai LT"' },
  { code: 303483856, name: 'UAB "Fusis"' },
  { code: 303486457, name: '"Specialusis techninis stiklas", UAB' },
  { code: 303486553, name: 'UAB "Dzūkų baldai"' },
  { code: 303486884, name: "UAB Spintų galerija" },
  { code: 303486902, name: 'UAB "Mediniai gaminiai"' },
  { code: 303491511, name: 'UAB "Glasus"' },
  { code: 303492937, name: 'UAB "Rogių šunys"' },
  { code: 303493900, name: 'UAB "Baltijos kartonas"' },
  { code: 303500864, name: 'UAB "Gidela"' },
  { code: 303502370, name: 'UAB "Litbeda"' },
  { code: 303503376, name: 'MB "Edva ir ko"' },
  { code: 303515859, name: 'MB "Metabolika"' },
  { code: 303516352, name: 'UAB "Ardata"' },
  { code: 303522864, name: 'UAB "Windex Group"' },
  { code: 303525351, name: 'UAB "Dominari medis"' },
  { code: 303531379, name: 'UAB "Namų partneriai"' },
  { code: 303533483, name: 'Uždaroji akcinė bendrovė "Agrofertis"' },
  { code: 303533896, name: 'MB "Gera funkcija"' },
  { code: 303534350, name: 'UAB "Medinis dublis"' },
  { code: 303547369, name: 'ŽŪB "Ganešos laivas"' },
  { code: 303547390, name: 'UAB "Kadagio dūmas"' },
  { code: 303547903, name: 'UAB "Aliejaus spaudimo bazė"' },
  { code: 303549861, name: 'UAB "Norvelda"' },
  { code: 303549893, name: 'UAB "Laiptadariai"' },
  { code: 303549904, name: 'MB "Gyvos idėjos"' },
  { code: 303551353, name: 'UAB "Skardos vilkai"' },
  { code: 303551360, name: 'UAB "Utenos senjorai"' },
  { code: 303554698, name: 'UAB "Silva medica"' },
  { code: 303558974, name: 'UAB "Adusas"' },
  { code: 303564482, name: "YCL furniture, UAB" },
  { code: 303566355, name: 'UAB "Gumos perdirbimas"' },
  { code: 303572422, name: 'UAB "Japaletė"' },
  { code: 303572511, name: 'UAB "Berlainių vėjas"' },
  { code: 303572995, name: 'UAB "Mirora"' },
  { code: 303575571, name: 'UAB "Dovimesta"' },
  { code: 303589884, name: 'MB "Transformuojamos sistemos"' },
  { code: 303652854, name: 'UAB "Pilnatvė"' },
  { code: 303673389, name: 'MB "Cypa"' },
  { code: 303680871, name: 'UAB "Klasikiniai langai"' },
  { code: 303711352, name: 'UAB "Mebelveisas"' },
  { code: 303713353, name: 'UAB "Gonas"' },
  { code: 303718368, name: 'MB "Almėtika"' },
  { code: 303724353, name: 'UAB "Aikis"' },
  { code: 303726354, name: 'UAB "Stikleris"' },
  { code: 303749367, name: "UAB Zam Zam Lithuania" },
  { code: 303763356, name: 'UAB "Elastas"' },
  { code: 303772362, name: 'UAB "Patogūs baldai"' },
  { code: 303781354, name: 'UAB "E. jungtis"' },
  { code: 303782367, name: "UAB Vilniaus kogeneracinė jėgainė" },
  { code: 303792888, name: "UAB Kauno kogeneracinė jėgainė" },
  { code: 303795350, name: 'UAB "Gajos baldai"' },
  { code: 303795382, name: 'UAB "Exporto lineas"' },
  { code: 303806359, name: 'UAB "Stalentus"' },
  { code: 303831451, name: 'UAB "Balduvita"' },
  { code: 303844363, name: 'UAB "Miglera plius"' },
  { code: 303846859, name: 'MB "Volfo baldai"' },
  { code: 303864864, name: "UAB Suvalkijos verslo grupė" },
  { code: 303867351, name: 'UAB "TableAir"' },
  { code: 303871410, name: 'UAB "Rira"' },
  { code: 303888858, name: 'MB "Deiritos medis"' },
  { code: 303888954, name: 'UAB "Balkonija"' },
  { code: 303902471, name: 'UAB "Baldlita LT"' },
  { code: 303906352, name: 'MB "Viskas mūsų namui"' },
  { code: 303906911, name: 'UAB "Baldų konstrukcijos"' },
  { code: 303919855, name: 'UAB "ScanEcoBalt"' },
  { code: 303923889, name: 'UAB "Canis opus"' },
  { code: 303925445, name: "UAB Palpės grupė" },
  { code: 303927859, name: 'UAB "Baldų svaja"' },
  { code: 303928854, name: 'IĮ "Liūdesio slėnis"' },
  { code: 303932354, name: "MB Baltijos uostų tiekimo kompanija" },
  { code: 303953875, name: 'UAB "Ardekas"' },
  { code: 303969858, name: 'MB "Jako baldai"' },
  { code: 303976596, name: 'UAB "Merksas"' },
  { code: 303984881, name: 'UAB "Dotnuvos mediena"' },
  { code: 303985353, name: 'MB "Aljuta"' },
  { code: 303989953, name: 'IĮ "Grenesta"' },
  { code: 303997366, name: 'UAB "Dermedika"' },
  { code: 304001355, name: 'UAB "Kbon"' },
  { code: 304013852, name: 'UAB "Artstonė"' },
  { code: 304028562, name: 'UAB "Atlantservis"' },
  { code: 304028644, name: 'UAB "Devenio"' },
  { code: 304028669, name: 'UAB "Borsas"' },
  { code: 304029290, name: 'UAB "Listika"' },
  { code: 304029568, name: 'UAB "Resta ES"' },
  { code: 304029618, name: 'UAB "Tartanas"' },
  { code: 304030773, name: 'UAB "Biliūnų prekyba"' },
  { code: 304031206, name: 'UAB "Panplasta"' },
  { code: 304032639, name: 'UAB "Sidomus"' },
  { code: 304032895, name: 'UAB "Molten Marine"' },
  { code: 304033036, name: 'UAB "Palterna"' },
  { code: 304033050, name: 'UAB "Ergosta"' },
  { code: 304034519, name: 'UAB "Zaga LT"' },
  { code: 304034704, name: 'UAB "Desidus"' },
  { code: 304034711, name: 'UAB "Litakva"' },
  { code: 304037508, name: 'UAB "Hidrovala"' },
  {
    code: 304037586,
    name: 'Žemės ūkio kooperatyvas "Sartų lankų jautiena"'
  },
  { code: 304038108, name: '"KUPFFER BIOTHECH", UAB' },
  { code: 304038154, name: 'UAB "Garliavos baldai"' },
  { code: 304038738, name: 'UAB "Alvudas"' },
  { code: 304041008, name: 'UAB "LARA projects"' },
  { code: 304045088, name: 'MB "Alplastas"' },
  { code: 304045095, name: 'UAB "Midos langai"' },
  { code: 304045825, name: '"FilaLAB", UAB' },
  { code: 304046966, name: 'UAB "Honas"' },
  { code: 304047018, name: 'MB "Medžio buveinė"' },
  { code: 304048440, name: 'UAB "Baldų tonas"' },
  { code: 304048472, name: 'UAB "Somėja"' },
  { code: 304049108, name: 'UAB "Lakameda"' },
  { code: 304049179, name: "UAB Inovatyvių baldų fabrikas" },
  { code: 304049275, name: "Justino Monkūno IĮ" },
  { code: 304050427, name: 'MB "Kastynga"' },
  { code: 304050669, name: "RFX Care Lithuania UAB" },
  { code: 304051550, name: "UAB Intermarinas" },
  { code: 304051664, name: 'UAB "Daloneta"' },
  { code: 304055805, name: 'UAB "Savero LT"' },
  { code: 304055837, name: 'UAB "Mėsos guru"' },
  { code: 304057628, name: 'MB "Batų namai"' },
  { code: 304058235, name: 'UAB "Ekobūsto namai"' },
  { code: 304058712, name: 'UAB "Elorika"' },
  { code: 304061377, name: 'UAB "Natureka"' },
  { code: 304062034, name: 'UAB "Jotjot"' },
  { code: 304062187, name: 'UAB "Augustino projektai"' },
  { code: 304062194, name: '"Dotekas", UAB' },
  { code: 304062956, name: 'UAB "Medžio raštas"' },
  { code: 304063848, name: 'UAB "Bidini"' },
  { code: 304063905, name: 'UAB "Garai"' },
  { code: 304064373, name: 'UAB "Juritos baldai"' },
  { code: 304064569, name: 'UAB "Dukata"' },
  { code: 304064964, name: 'MB "Masyvo durys"' },
  { code: 304064996, name: '"Marinetechas", UAB' },
  { code: 304065016, name: 'UAB "Timbersija"' },
  { code: 304065240, name: 'UAB "Nerona"' },
  { code: 304066310, name: "UAB LIGHTENTAGE" },
  { code: 304067540, name: "MB Audronės dizaino inovacijų studija" },
  { code: 304067896, name: 'Mažoji bendrija "Piritas"' },
  { code: 304067953, name: 'UAB "Slenka"' },
  { code: 304068329, name: 'UAB "Litgrasta"' },
  { code: 304068368, name: 'UAB "Saujelė"' },
  { code: 304068838, name: 'UAB "Sanosta"' },
  { code: 304070497, name: 'IĮ "Namų spalva"' },
  { code: 304070508, name: 'MB "Frezavimas"' },
  { code: 304070700, name: 'UAB "Ledakis"' },
  { code: 304070928, name: 'UAB "Nermeka"' },
  { code: 304071318, name: 'UAB "Meistrita"' },
  { code: 304071663, name: 'MB "Stream team models"' },
  { code: 304071802, name: 'UAB "Marės industrija"' },
  { code: 304072007, name: 'MB "BIOMASS"' },
  { code: 304073162, name: 'UAB "Neokuras"' },
  { code: 304073867, name: 'UAB "Amberlori"' },
  { code: 304073881, name: 'UAB "Bordena"' },
  { code: 304074104, name: 'UAB "Juodoji audinė plius"' },
  { code: 304074403, name: 'MB "Drožlė"' },
  { code: 304074606, name: 'UAB "Favitor"' },
  { code: 304075195, name: "UAB M.A.JOB" },
  { code: 304075220, name: 'MB "Lapė ir sesės"' },
  { code: 304075359, name: "UAB Santonika" },
  { code: 304076799, name: 'MB "Kepsnių gurmanai"' },
  { code: 304077342, name: '"Dizaino kodas", MB' },
  { code: 304078953, name: 'MB "Robertus"' },
  { code: 304079044, name: 'UAB "WBK"' },
  { code: 304079539, name: 'UAB "Charcoalit"' },
  { code: 304079560, name: 'MB "Baldų dėlionė"' },
  { code: 304079998, name: "MB COOLERWORX" },
  { code: 304080420, name: 'UAB "Šiaurės namas"' },
  { code: 304083021, name: "Edvino Jenkaus IĮ" },
  { code: 304088164, name: '"Transformuojami baldai", UAB' },
  { code: 304089565, name: 'UAB "Medžio prekyba"' },
  { code: 304089921, name: 'UAB "Medžio nuotaika"' },
  { code: 304090069, name: "Filtroom UAB" },
  { code: 304090603, name: 'MB "Šlaitų dekoras"' },
  { code: 304091566, name: 'UAB "Auromeda"' },
  { code: 304091598, name: "KK TRADE, UAB" },
  { code: 304092351, name: 'UAB "Ronelda"' },
  { code: 304092458, name: 'UAB "Karališka žuvis"' },
  { code: 304092839, name: 'MB "Vidneras"' },
  { code: 304092860, name: 'UAB "Sepo novus"' },
  { code: 304093873, name: 'MB "Arredo deko"' },
  { code: 304094263, name: 'UAB "Alsetas"' },
  { code: 304094975, name: 'UAB "Betono elementas"' },
  { code: 304095002, name: 'UAB "Euruja"' },
  { code: 304096086, name: 'UAB "Vilniaus Gralis"' },
  { code: 304096563, name: 'UAB "ICECO žuvis"' },
  { code: 304097409, name: 'UAB "Mileris"' },
  { code: 304097416, name: 'MB "Novum commercium"' },
  { code: 304097747, name: 'UAB "Izprojektai"' },
  { code: 304098482, name: 'MB "Baldų gaminiai"' },
  { code: 304099470, name: 'UAB "Skandus"' },
  { code: 304101327, name: 'MB "Polistera"' },
  { code: 304101882, name: 'UAB "Medlanas"' },
  { code: 304102190, name: "HC Betonas UAB" },
  { code: 304103246, name: 'MB "Dominyko projektai"' },
  { code: 304104191, name: 'MB "Giranda"' },
  { code: 304106687, name: 'UAB "Saulės miškas"' },
  { code: 304106769, name: 'UAB "Kadeira"' },
  { code: 304108115, name: 'UAB "Skaila"' },
  { code: 304110874, name: 'UAB "Polifarma"' },
  { code: 304110924, name: 'UAB "Šilutės vėjo parkas 3"' },
  { code: 304111499, name: 'UAB "Tauragės pašarai"' },
  { code: 304111848, name: 'UAB "Deimenta"' },
  { code: 304112366, name: 'UAB "Plastic Technology"' },
  { code: 304112868, name: 'UAB "Aiventus"' },
  { code: 304113030, name: 'UAB "Darytis"' },
  { code: 304114463, name: 'MB "Terasėlė"' },
  { code: 304119115, name: 'UAB "Intoka"' },
  { code: 304119414, name: 'UAB "Lenktas medis"' },
  { code: 304119834, name: 'MB "Indelis medyje"' },
  { code: 304120605, name: 'UAB "Vega plius"' },
  { code: 304124087, name: "MB Elegante Press" },
  { code: 304124354, name: 'UAB "Advela"' },
  { code: 304124482, name: "MB Klaipėdos laivų matavimo centras" },
  { code: 304124607, name: 'MB "Ąžuolina"' },
  { code: 304124870, name: 'UAB "Žemaitijos vėjas"' },
  { code: 304124888, name: 'UAB "Darbėnų vėjas"' },
  { code: 304125367, name: 'UAB "Plastoka"' },
  { code: 304126412, name: 'UAB "Suppleo"' },
  { code: 304130243, name: "UAB tic elkas Baltic" },
  { code: 304130880, name: 'MB "Miško raistas"' },
  { code: 304137444, name: 'UAB "Baldočiai"' },
  { code: 304137501, name: 'UAB "Argeanos grupė"' },
  { code: 304138471, name: 'UAB "Daigrė"' },
  { code: 304139235, name: '"Stambus", UAB' },
  { code: 304140344, name: 'UAB "FishNet"' },
  { code: 304141364, name: 'UAB "Ardlita"' },
  { code: 304142441, name: 'MB "Migvita"' },
  { code: 304142847, name: 'MB "Ekologinės paslaugos"' },
  { code: 304143365, name: "D. Lapo IĮ" },
  { code: 304144392, name: 'MB "Plastiko banga"' },
  { code: 304144620, name: 'Autoklubas "Auksiniai ratai"' },
  { code: 304145010, name: 'IĮ "Vibaldis"' },
  { code: 304147171, name: 'UAB "Laneba"' },
  { code: 304147495, name: 'UAB "Majednus"' },
  { code: 304148316, name: 'UAB "Kyborgas"' },
  { code: 304148533, name: 'MB "Kamšos medis"' },
  { code: 304148540, name: 'UAB "Tilvis"' },
  { code: 304148896, name: 'UAB "Tomėja"' },
  { code: 304149927, name: 'UAB "Geramada"' },
  { code: 304150908, name: "NEXTERA ENERGIJA, UAB" },
  { code: 304151180, name: 'UAB "Remantika"' },
  { code: 304154187, name: 'UAB "Deisida"' },
  { code: 304156291, name: 'UAB "Formigas"' },
  { code: 304157009, name: 'MB "Egis ir Ko"' },
  { code: 304157190, name: 'UAB "Palangos baldai"' },
  { code: 304158118, name: 'UAB "Adominus"' },
  { code: 304158417, name: 'UAB "Balta pieva"' },
  { code: 304159024, name: 'UAB "Lazerio darbai"' },
  { code: 304159234, name: 'UAB "Tauragės ąžuolas"' },
  { code: 304163421, name: "Belles Lignes, UAB" },
  { code: 304164765, name: 'UAB "Narenis"' },
  { code: 304165372, name: 'UAB "Mitapakas"' },
  { code: 304166378, name: 'UAB "Madženta"' },
  { code: 304168379, name: 'UAB "Dvaro mėsa"' },
  { code: 304168393, name: 'UAB "Medžio simfonija"' },
  { code: 304168596, name: 'UAB "Luft Master"' },
  { code: 304169239, name: 'UAB "Unoforma"' },
  { code: 304169787, name: 'UAB "Lipra"' },
  { code: 304169883, name: 'UAB "Granjuma"' },
  { code: 304170437, name: 'MB "Rovesa"' },
  { code: 304170572, name: 'UAB "Kerlida"' },
  { code: 304171090, name: 'UAB "Arnitana"' },
  { code: 304173102, name: 'UAB "Baldų zona"' },
  { code: 304173312, name: 'UAB "Dekosiena"' },
  { code: 304173629, name: 'MB "Aidola"' },
  { code: 304174389, name: 'MB "Rūkinta"' },
  { code: 304174955, name: 'UAB "Peonia lab"' },
  { code: 304176358, name: 'MB "3 kojos"' },
  { code: 304177638, name: 'UAB "Aroviksas"' },
  { code: 304177652, name: 'UAB "Mundus LT"' },
  { code: 304178074, name: 'UAB "Energoparks"' },
  { code: 304178569, name: 'MB "Noriu baldų"' },
  { code: 304178640, name: 'UAB "Jurgėnų biokuras"' },
  { code: 304178964, name: "AB Palemono keramikos gamykla" },
  { code: 304180328, name: '"Vygaka", UAB' },
  { code: 304180538, name: 'UAB "AMT Komex"' },
  { code: 304181355, name: 'UAB "Penktas skonis"' },
  { code: 304181978, name: 'MB "Statbusta"' },
  { code: 304182877, name: 'UAB "Darvaivita"' },
  { code: 304182891, name: 'MB "Sluoksniai"' },
  { code: 304182927, name: 'UAB "Dilersa"' },
  { code: 304183872, name: '"Carbonis", UAB' },
  { code: 304183993, name: 'UAB "Programeda"' },
  { code: 304184052, name: 'UAB "Timberis"' },
  { code: 304185962, name: 'UAB "Tamerlana"' },
  { code: 304186306, name: 'UAB "Alneka"' },
  { code: 304193140, name: 'UAB "Jono malkos"' },
  { code: 304195458, name: 'UAB "Samera"' },
  { code: 304196503, name: 'UAB "Baldupė"' },
  { code: 304197352, name: 'UAB "Rafimeta"' },
  { code: 304198721, name: 'MB "Tradiciniai amatai"' },
  { code: 304198778, name: 'MB "Klampu"' },
  { code: 304207597, name: 'UAB "Nordic SPAS"' },
  { code: 304213358, name: 'UAB "Andola"' },
  { code: 304214182, name: 'MB "Luko baldai"' },
  { code: 304214524, name: 'UAB "Dervos industrija LT"' },
  { code: 304215238, name: '"LT energija", UAB' },
  { code: 304215722, name: 'UAB "CNC taškas"' },
  { code: 304215957, name: 'UAB "Liepta"' },
  { code: 304217709, name: 'MB "Insa"' },
  { code: 304218280, name: 'UAB "Grinforta"' },
  { code: 304222065, name: 'UAB "Piluka"' },
  { code: 304222072, name: 'UAB "Jaukumo formulė"' },
  { code: 304224707, name: 'MB "Ceraminas"' },
  { code: 304224810, name: 'UAB "Genstata"' },
  { code: 304225015, name: 'UAB "Milberis"' },
  { code: 304226277, name: 'UAB "Fenstra"' },
  { code: 304228050, name: 'UAB "Maisto arsenalas"' },
  { code: 304228171, name: 'MB "Lubų menas"' },
  { code: 304228285, name: 'MB "Tvorų miestas"' },
  { code: 304228627, name: 'UAB "Tabariškių žaluma"' },
  { code: 304228666, name: 'UAB "MGS EU"' },
  { code: 304230806, name: 'UAB "Hermio priekabos"' },
  { code: 304231694, name: 'UAB "Baltasis bizonas"' },
  { code: 304231819, name: 'UAB "Jogusta"' },
  { code: 304232878, name: 'VšĮ "Žalieji laivai"' },
  { code: 304233407, name: "AJ Kalvarija, UAB" },
  { code: 304233827, name: "BIG MASTER LT, MB" },
  { code: 304234523, name: 'UAB "Siūlų magija"' },
  { code: 304234740, name: '"Lenada", UAB' },
  { code: 304239919, name: 'UAB "Jurginų mėsa"' },
  { code: 304244478, name: 'UAB "Medžiagų tiekimas"' },
  { code: 304248359, name: 'UAB "Jono ir Giedriaus baldai"' },
  { code: 304250972, name: 'UAB "Norvelina"' },
  { code: 304251088, name: 'UAB "Bargama"' },
  { code: 304251152, name: 'UAB "Madagaskaras"' },
  { code: 304254789, name: 'MB "Trikojis"' },
  { code: 304254942, name: 'MB "Rytbalda"' },
  { code: 304255542, name: 'UAB "Dakaras"' },
  { code: 304255720, name: "CRESCO EIENDOM LT, UAB" },
  { code: 304257130, name: 'UAB "Gerdma"' },
  { code: 304259708, name: 'UAB "Ventesa"' },
  { code: 304260062, name: 'UAB "Širvintų hidroenergija"' },
  { code: 304260183, name: 'MB "Brama"' },
  { code: 304270989, name: 'MB "Rūkauta"' },
  { code: 304271112, name: "InSky, UAB" },
  { code: 304271265, name: 'UAB "Alsaiga"' },
  { code: 304274471, name: 'MB "Terra Infinita"' },
  { code: 304279714, name: 'MB "Stiklo pluošto darbai"' },
  { code: 304282240, name: 'UAB "Trado"' },
  { code: 304283851, name: 'UAB "Joras"' },
  { code: 304283958, name: 'MB "Kovo 22"' },
  { code: 304284227, name: 'UAB "Biotekana"' },
  { code: 304285044, name: 'IĮ "Artmena"' },
  { code: 304285254, name: 'UAB "Serviso grupė"' },
  { code: 304288026, name: '"Nordevila", UAB' },
  { code: 304290504, name: 'UAB "Daps"' },
  { code: 304291360, name: 'MB "Interius"' },
  { code: 304291403, name: 'UAB "FURNEO"' },
  { code: 304292462, name: 'IĮ "Kimo medienos gaminiai"' },
  { code: 304294082, name: 'UAB "DOVISTA"' },
  { code: 304294374, name: '"Elematus", UAB' },
  { code: 304294470, name: 'UAB "Ligni linea"' },
  { code: 304295615, name: 'UAB "Konata"' },
  { code: 304295800, name: 'UAB "Dismena"' },
  { code: 304297986, name: 'UAB "Interchemie werken "De Adelaar" LT"' },
  { code: 304298878, name: 'UAB "Melada"' },
  { code: 304298967, name: 'UAB "AcrylicStone LT"' },
  { code: 304299873, name: 'UAB "Insola"' },
  { code: 304306855, name: "UAB Stiklo studija" },
  { code: 304310654, name: 'MB "Prekybos startuolis"' },
  { code: 304316867, name: 'UAB "Dermofaktorius"' },
  { code: 304317022, name: 'UAB "Viritekas"' },
  { code: 304317093, name: 'UAB "Ikarai"' },
  { code: 304324508, name: 'UAB "Arelneta"' },
  { code: 304324839, name: 'MB "Odos interjeras"' },
  { code: 304330379, name: 'UAB "Spalvoti baldai"' },
  { code: 304337352, name: 'UAB "Emlitos baldai"' },
  { code: 304353730, name: 'UAB "Tik medis"' },
  { code: 304355140, name: 'MB "Dūk"' },
  { code: 304367356, name: 'UAB "Anokis"' },
  { code: 304367915, name: "UAB Tomo dirbtuvė" },
  { code: 304368903, name: 'UAB "Bonum lignum"' },
  { code: 304381886, name: 'UAB "Misela"' },
  { code: 304382358, name: 'MB "Gurmanas"' },
  { code: 304386463, name: 'UAB "Armolita"' },
  { code: 304392530, name: 'UAB "Daimira"' },
  { code: 304395996, name: 'UAB "Algidėja"' },
  { code: 304399293, name: 'UAB "Eksporto kryptys"' },
  { code: 304400913, name: 'UAB "Roterdamas"' },
  { code: 304401221, name: 'UAB "Natūralus sezamas"' },
  { code: 304401303, name: 'MB "Kemida"' },
  { code: 304402540, name: 'UAB "VAKE GROUP"' },
  { code: 304403966, name: 'MB "Davidara"' },
  { code: 304405401, name: 'IĮ "Durys Tau"' },
  { code: 304405885, name: 'UAB "Nefelė LT"' },
  { code: 304406827, name: 'UAB "Arktalius"' },
  { code: 304408543, name: 'UAB "Daliaus baldai"' },
  { code: 304408792, name: 'MB "Melsva"' },
  { code: 304409015, name: 'UAB "Graneda"' },
  { code: 304409780, name: 'UAB "Daridenos servisas"' },
  { code: 304409823, name: 'MB "Lipdukinis"' },
  { code: 304410665, name: 'UAB "Zūbiškių konservai"' },
  { code: 304411144, name: 'UAB "Dadema"' },
  { code: 304411393, name: 'UAB "Getuva"' },
  { code: 304411557, name: 'MB "Medžio artelė"' },
  { code: 304412100, name: 'UAB "Ecohouse"' },
  { code: 304412709, name: 'MB "Baldų Marius"' },
  { code: 304413362, name: "Lottens UAB" },
  { code: 304413387, name: 'UAB "Skanuma"' },
  { code: 304413540, name: 'UAB "Adailiūna"' },
  { code: 304414973, name: 'UAB "Biometana"' },
  { code: 304415370, name: 'UAB "Įranga technologijos"' },
  { code: 304415776, name: 'MB "Minkšti projektai"' },
  { code: 304416892, name: 'MB "Medinė pėda"' },
  { code: 304417154, name: 'MB "Reimbalda"' },
  { code: 304418142, name: 'UAB "Karkasta"' },
  { code: 304418555, name: 'MB "1000 rankyčių"' },
  { code: 304419454, name: 'UAB "Odomus"' },
  { code: 304419550, name: 'UAB "Willprom"' },
  { code: 304419664, name: 'UAB "Ąžuolo įkvėpti"' },
  { code: 304419803, name: 'UAB "MEDIENTIEKA"' },
  { code: 304420218, name: 'UAB "Modinera"' },
  { code: 304420339, name: 'UAB "Medgara"' },
  { code: 304420645, name: 'UAB "Lignas"' },
  { code: 304421462, name: 'UAB "Ponelis"' },
  { code: 304421708, name: 'MB "Link progreso"' },
  { code: 304422144, name: '"Meistras Algis", MB' },
  { code: 304422354, name: 'UAB "Eglės kvapas"' },
  { code: 304422920, name: "MB TSF Group" },
  { code: 304423036, name: 'UAB "Baldadariai"' },
  { code: 304423513, name: 'UAB "Kubilų pasaulis"' },
  { code: 304423716, name: "MB Kilkų ūkis" },
  { code: 304424017, name: "GreenTech Baltic, UAB" },
  { code: 304424120, name: 'MB "Avjolna"' },
  { code: 304425496, name: 'UAB "Akmasta"' },
  { code: 304425514, name: 'MB "Iteracija"' },
  { code: 304426826, name: 'MB "Gaminiai LT"' },
  { code: 304427091, name: 'UAB "Ultimus LT"' },
  { code: 304427860, name: 'MB "Creo LT"' },
  { code: 304428784, name: 'UAB "Poliruotas betonas"' },
  { code: 304428948, name: 'MB "Pranasta"' },
  { code: 304429256, name: 'UAB "Viklis"' },
  { code: 304429377, name: 'UAB "Blesas"' },
  { code: 304429473, name: 'MB "Arti namų"' },
  { code: 304429651, name: 'UAB "Armatūrė"' },
  { code: 304429740, name: 'UAB "Kretingos medis"' },
  { code: 304430041, name: 'UAB "Adarta"' },
  { code: 304430123, name: 'UAB "Medienos technologijų sprendimai"' },
  { code: 304430625, name: 'MB "Tabmeda"' },
  { code: 304431296, name: 'UAB "Klerdena LT"' },
  { code: 304431403, name: 'MB "Larmega"' },
  { code: 304431862, name: 'MB "Proorganika"' },
  { code: 304432028, name: 'UAB "Emaja"' },
  { code: 304432067, name: 'VšĮ "Obliukas"' },
  { code: 304432131, name: 'UAB "Sinerga LT"' },
  { code: 304433379, name: 'VšĮ "Inkilai"' },
  { code: 304433450, name: 'MB "Nova factūra"' },
  { code: 304433557, name: 'UAB "Švento Jurgio puta"' },
  { code: 304434011, name: 'Mažoji bendrija "Rūpestingi namai"' },
  { code: 304434381, name: "VšĮ Charitės studija" },
  { code: 304435729, name: "UAB Kaišiadorių skerdykla" },
  { code: 304436560, name: 'UAB "Gergama"' },
  { code: 304436902, name: 'UAB "Adomo baldai"' },
  { code: 304436998, name: 'UAB "Jumika"' },
  { code: 304437242, name: 'MB "Restaurita"' },
  { code: 304437370, name: 'UAB "Sobeta"' },
  { code: 304437541, name: 'UAB "Juoda balta"' },
  { code: 304438223, name: 'UAB "Šviesos sprendimai"' },
  { code: 304438668, name: 'UAB "Minepolis"' },
  { code: 304439115, name: 'UAB "Iskarijotas"' },
  { code: 304439161, name: "UAB DG projektas" },
  { code: 304439371, name: 'UAB "Mantanis"' },
  { code: 304439446, name: 'UAB "Pamerela"' },
  { code: 304439713, name: 'UAB "Agmarina"' },
  { code: 304439873, name: 'MB "Tegulas"' },
  { code: 304441358, name: "UAB Stiklo Dirbtuvės" },
  { code: 304441543, name: 'UAB "Riglosa"' },
  { code: 304442257, name: "UAB Medinės detalės" },
  { code: 304442652, name: 'UAB "Jūsų vežimas"' },
  { code: 304442912, name: 'UAB "Fanerus"' },
  { code: 304443163, name: 'UAB "Esovera"' },
  { code: 304443989, name: 'UAB "Volo LT"' },
  { code: 304444062, name: 'UAB "Rūkyklėlė"' },
  { code: 304445043, name: 'UAB "Eimasa"' },
  { code: 304445577, name: 'UAB "GKF Lumber Group"' },
  { code: 304445591, name: 'UAB "Vaibloka"' },
  { code: 304447731, name: 'MB "Lapytė"' },
  { code: 304448986, name: 'UAB "Spunas"' },
  { code: 304449294, name: 'UAB "Baldų noras"' },
  { code: 304449337, name: "UAB Lusety" },
  { code: 304449967, name: 'UAB "Elektrolita"' },
  { code: 304450058, name: 'UAB "Interjero idėja"' },
  { code: 304450108, name: 'UAB "Bekoneta"' },
  { code: 304450777, name: 'MB "Virtuvijus"' },
  { code: 304452237, name: 'Uždaroji akcinė bendrovė "Gutas"' },
  { code: 304452874, name: 'UAB "Sidabras"' },
  { code: 304453182, name: 'MB "Lijonava"' },
  { code: 304453353, name: 'MB "Juodas vilkas"' },
  { code: 304453360, name: 'MB "Spirgius"' },
  { code: 304453428, name: 'UAB "Gilminta"' },
  { code: 304453702, name: 'UAB "Alenas"' },
  { code: 304456043, name: 'UAB "Biokuro energija"' },
  { code: 304456164, name: 'UAB "Inspiracija"' },
  { code: 304456285, name: 'UAB "Muilo Imperija"' },
  { code: 304456940, name: 'UAB "Komformatas"' },
  { code: 304457177, name: 'UAB "Batų namai LT"' },
  { code: 304458304, name: "UAB FREDA II" },
  { code: 304458788, name: 'UAB "Forelis"' },
  { code: 304458959, name: 'UAB "Baldmada"' },
  { code: 304459178, name: 'UAB "Medienos dažymas"' },
  { code: 304459502, name: "Mimaki Lithuania, UAB" },
  { code: 304460216, name: "MB Alteralt" },
  { code: 304460248, name: 'UAB "Gustonas"' },
  { code: 304460732, name: 'UAB "Baldemija"' },
  { code: 304461097, name: 'UAB "Airupė"' },
  { code: 304461229, name: 'UAB "Stambus projektas"' },
  { code: 304461364, name: 'MB "Interjero gaminiai"' },
  { code: 304461510, name: 'MB "Egidijaus baldai"' },
  { code: 304461567, name: 'UAB "Dupolis"' },
  { code: 304462683, name: 'MB "Meriksas"' },
  { code: 304462765, name: "A.R.S.A. group, UAB" },
  { code: 304462797, name: 'UAB "Šilko blizgesys"' },
  { code: 304462904, name: 'UAB "Nemira"' },
  { code: 304463924, name: "FREVINI, UAB" },
  { code: 304465380, name: 'UAB "Entalpija"' },
  { code: 304465416, name: 'UAB "Jusmas"' },
  { code: 304465932, name: "MB Dizaino dirbtuvės" },
  { code: 304465971, name: 'MB "Baldaisos"' },
  { code: 304466233, name: 'UAB "Ekila"' },
  { code: 304466518, name: 'UAB "Arevera"' },
  { code: 304467367, name: 'MB "Degintas medis"' },
  { code: 304468921, name: 'MB "Samana balta"' },
  { code: 304469382, name: 'MB "Daryk dabar"' },
  { code: 304470356, name: 'MB "Dažiukai"' },
  { code: 304471045, name: 'MB "Erega"' },
  { code: 304471383, name: 'MB "Gelgaudiškio mediena"' },
  { code: 304471611, name: 'UAB "Reklamdariai"' },
  { code: 304472364, name: 'UAB "Medžio forma"' },
  { code: 304472631, name: 'UAB "Rimluva"' },
  { code: 304476462, name: 'Mažoji bendrija "Mūsų augintinis"' },
  { code: 304476989, name: 'MB "Daiktų meistras"' },
  { code: 304479191, name: "UAB Arras Furniture" },
  { code: 304479782, name: 'MB "Pasvalio baldai"' },
  { code: 304480350, name: 'UAB "Betono stilius"' },
  { code: 304480546, name: 'UAB "Neidila"' },
  { code: 304483371, name: 'UAB "Formlita"' },
  { code: 304485002, name: "MB Lazerio dirbtuvės" },
  { code: 304485408, name: 'MB "Miego karaliai"' },
  { code: 304490992, name: 'UAB "Taktiniai sprendimai"' },
  { code: 304491642, name: 'UAB "Statvitra"' },
  { code: 304491667, name: '"Plonas", MB' },
  { code: 304491852, name: 'MB "Medlenta"' },
  { code: 304493255, name: "MB Molio namai" },
  { code: 304493383, name: 'UAB "Raivilė"' },
  { code: 304497385, name: 'UAB "Koalos medis"' },
  { code: 304497531, name: 'UAB "Smartech LT"' },
  { code: 304497702, name: 'UAB "Santamed LT"' },
  { code: 304497862, name: 'UAB "RM plast"' },
  { code: 304498124, name: 'UAB "Prometra"' },
  { code: 304498341, name: 'UAB "Liepsraigta"' },
  { code: 304498398, name: 'MB "Baldgela"' },
  { code: 304498989, name: 'UAB "Korfas"' },
  { code: 304499991, name: 'MB "Bajorų mėsa"' },
  { code: 304500194, name: 'MB "Myli"' },
  { code: 304500479, name: 'UAB "Ąžuolo linija"' },
  { code: 304500493, name: "MB Žemaituko dirbtuvės" },
  { code: 304502035, name: 'MB "Toro baldai"' },
  { code: 304502640, name: 'UAB "North hytte"' },
  { code: 304502875, name: 'UAB "Rimgvita"' },
  { code: 304504506, name: 'IĮ "Baltijos chemija"' },
  { code: 304504762, name: '"Flos projectus", MB' },
  { code: 304505960, name: 'MB "Medžio virsmas"' },
  { code: 304506069, name: 'UAB "SynHet"' },
  { code: 304506090, name: 'UAB "Raptus"' },
  { code: 304507833, name: 'UAB "Huslita"' },
  { code: 304509916, name: "UAB A2A LT" },
  { code: 304510085, name: 'MB "Medžio dizainas"' },
  { code: 304510594, name: '"Lumentis", MB' },
  { code: 304514450, name: 'UAB "Jugenta"' },
  { code: 304514518, name: 'MB "Inovalita"' },
  { code: 304514557, name: 'UAB "Finest goods"' },
  { code: 304516049, name: 'Mažoji bendrija "Spalvotas betonas"' },
  { code: 304516437, name: 'UAB "LECA Lithuania"' },
  { code: 304516729, name: 'UAB "Technoversa"' },
  { code: 304521861, name: 'MB "Aistis ir Rokas"' },
  { code: 304521950, name: '"Cignera", UAB' },
  { code: 304522881, name: 'UAB "Interjero durys.lt"' },
  { code: 304523595, name: 'MB "Zylės zylioja"' },
  { code: 304528382, name: 'UAB "Dargita tau"' },
  { code: 304530180, name: 'UAB "Granberg LT"' },
  { code: 304530376, name: '"LT production", UAB' },
  { code: 304530853, name: 'UAB "Lomeka"' },
  { code: 304532402, name: 'UAB "Minvestas"' },
  { code: 304532815, name: 'UAB "LEDSO"' },
  { code: 304536856, name: 'IĮ "Sanmeda"' },
  { code: 304538572, name: 'UAB "GBF sofa"' },
  { code: 304539037, name: 'UAB "Remavita"' },
  { code: 304539603, name: 'UAB "Moteda"' },
  { code: 304540057, name: "Shapemetics, UAB" },
  { code: 304540089, name: 'UAB "Teodoras"' },
  { code: 304541159, name: 'UAB "ELEKTROMAX"' },
  { code: 304541230, name: 'UAB "Denpasa"' },
  { code: 304546035, name: 'MB "Ekvija LT"' },
  { code: 304546156, name: "Mažoji bendrija Brazių ūkis" },
  { code: 304546245, name: "UAB HELLA Lithuania" },
  { code: 304548004, name: 'UAB "Orintas"' },
  { code: 304552045, name: 'MB "MILIJONAS"' },
  { code: 304553318, name: 'UAB "Kadaginė"' },
  { code: 304554199, name: 'UAB "Telatus"' },
  { code: 304555397, name: 'UAB "Draiga"' },
  { code: 304558030, name: 'MB "Interjero spalvos"' },
  { code: 304558048, name: 'UAB "Akmenės baldai"' },
  { code: 304558105, name: 'UAB "Proton Engineering Lithuania"' },
  { code: 304558685, name: 'MB "Romanta"' },
  { code: 304558774, name: 'MB "Miško takai"' },
  { code: 304562790, name: 'MB "Mibus"' },
  { code: 304562840, name: 'UAB "Lintechas"' },
  { code: 304564439, name: "Snolvalda, UAB" },
  { code: 304564866, name: 'UAB "Vilniaus baldžiai"' },
  { code: 304565167, name: 'MB "Printplasta"' },
  { code: 304565199, name: 'UAB "Retailas"' },
  { code: 304566949, name: 'UAB "Baltic tag"' },
  { code: 304567015, name: 'UAB "Conceptum"' },
  { code: 304567047, name: 'UAB "Indraima"' },
  { code: 304569404, name: 'UAB "Baldusa LT"' },
  { code: 304570381, name: "UAB STROMDAL DESIGN" },
  { code: 304572368, name: 'UAB "Adjura"' },
  { code: 304581246, name: 'UAB "Tygelis pakuoja"' },
  { code: 304584242, name: 'UAB "Naubertas"' },
  { code: 304584363, name: 'MB "Medgamas"' },
  { code: 304584954, name: 'MB "Vilniaus drabužių linija"' },
  { code: 304585369, name: 'UAB "Kego projektai"' },
  { code: 304589378, name: 'MB "Neatrastos spalvos"' },
  { code: 304589855, name: 'UAB "Koderus"' },
  { code: 304596860, name: '"ID baltas", MB' },
  { code: 304599479, name: 'UAB "Baltijos baldų projektai"' },
  { code: 304600173, name: 'MB "Norvilita"' },
  { code: 304602822, name: 'MB "Pakvista"' },
  { code: 304602847, name: 'MB "Vetgyda"' },
  { code: 304604563, name: 'UAB "PNK Oil"' },
  { code: 304605850, name: 'MB "Balvista"' },
  { code: 304605868, name: 'MB "Kabvista"' },
  { code: 304606913, name: 'MB "Gintarinis variklis"' },
  { code: 304607050, name: 'MB "Miego manija"' },
  { code: 304607132, name: 'UAB "Guma kitaip"' },
  { code: 304607367, name: 'MB "Užupio baldai"' },
  { code: 304607417, name: 'UAB "Eksporto kelias"' },
  { code: 304609891, name: 'UAB "Baltupakas"' },
  { code: 304610139, name: 'UAB "Solidomė"' },
  { code: 304611910, name: 'UAB "Votuma"' },
  { code: 304616366, name: 'MB "Injekcija menui"' },
  { code: 304617404, name: "Lumaire, UAB" },
  { code: 304617532, name: "UAB Dailylenčių centras" },
  { code: 304619519, name: 'UAB "Triteksta"' },
  { code: 304621961, name: 'MB "Ferus urbanus"' },
  { code: 304622166, name: 'MB "Varnos daiktai"' },
  { code: 304624473, name: 'UAB "Medlunga"' },
  { code: 304624605, name: "MB Algoritmų biuras" },
  { code: 304627359, name: 'UAB "Vivitas"' },
  { code: 304627373, name: 'UAB "Neoindustrija"' },
  { code: 304627996, name: 'UAB "Viduklės granulės"' },
  { code: 304628151, name: 'UAB "Asba"' },
  { code: 304628532, name: 'UAB "Medis ir forma"' },
  { code: 304628906, name: 'IĮ "Laruna"' },
  { code: 304632961, name: '"Juodas taškas", UAB' },
  { code: 304632979, name: 'MB "Labėja"' },
  { code: 304633305, name: 'UAB "Mykotra"' },
  { code: 304633369, name: 'MB "Laisvas būti"' },
  { code: 304636454, name: 'UAB "Navigatus"' },
  { code: 304637638, name: 'UAB "Pinteksa"' },
  { code: 304638156, name: 'UAB "Mėmelio žuvys"' },
  { code: 304638875, name: 'UAB "Modulių serviso centras"' },
  { code: 304638918, name: 'UAB "Tailored automotive solutions"' },
  { code: 304639354, name: 'UAB "JConstruction"' },
  { code: 304641355, name: 'IĮ "Molinė dėžutė"' },
  { code: 304642133, name: 'UAB Baldų studija "Inkilas"' },
  { code: 304642165, name: 'UAB "Kelsera"' },
  { code: 304642546, name: 'UAB "Partnerių spauda"' },
  { code: 304642642, name: 'MB "Įvairūs reikaliukai"' },
  { code: 304642934, name: 'UAB "Advus"' },
  { code: 304675432, name: 'UAB "Auresa Engineering"' },
  { code: 304675464, name: 'UAB "Natūralus pluoštas"' },
  { code: 304677127, name: 'UAB "Išmaniosios investicijos"' },
  { code: 304678791, name: 'UAB "ABC modulis"' },
  { code: 304681378, name: 'UAB "Sumanios plytos"' },
  { code: 304685953, name: 'UAB "Mokita"' },
  { code: 304686051, name: '"Idėjos forma", MB' },
  { code: 304686496, name: 'UAB "Deimantis"' },
  { code: 304687865, name: 'UAB "Lurseta"' },
  { code: 304690092, name: 'UAB "Scato"' },
  { code: 304690466, name: 'UAB "Triplex audax"' },
  { code: 304690644, name: 'UAB "Arvi kalakutai investicijos"' },
  { code: 304690854, name: 'UAB "Paranitas"' },
  { code: 304693124, name: 'UAB "Agroorganika"' },
  { code: 304693722, name: 'UAB "Trehus"' },
  { code: 304693875, name: "Ekornes Lithuania UAB" },
  { code: 304694354, name: 'UAB "Provectus compositus"' },
  { code: 304694404, name: 'MB "Skaitmeninė tekstilė"' },
  { code: 304695082, name: "UAB Minkštųjų baldų fabrikas" },
  { code: 304696444, name: 'MB "Vimora"' },
  { code: 304697464, name: 'MB "Yldara"' },
  { code: 304699885, name: 'UAB "Ponas ąžuolas"' },
  { code: 304701247, name: 'UAB "GLab LT"' },
  { code: 304702993, name: 'MB "Namuose... Namams..."' },
  { code: 304703369, name: 'UAB "Lidis"' },
  { code: 304704115, name: "Continental Automotive Lithuania UAB" },
  { code: 304707353, name: 'UAB "Stalernė"' },
  { code: 304707969, name: 'UAB "Vinitela"' },
  { code: 304708156, name: "UAB FENESTRUM" },
  { code: 304708334, name: 'UAB "Sugimeda"' },
  { code: 304708697, name: 'UAB "Spalvos spektras"' },
  { code: 304708868, name: "Instaride, UAB" },
  { code: 304709564, name: 'UAB "Niktra"' },
  { code: 304709991, name: 'MB "Gabmanta"' },
  { code: 304710666, name: 'UAB "Ateities baldai"' },
  { code: 304711857, name: 'UAB "Algio baldai"' },
  { code: 304711914, name: 'UAB "Roberto laiptai"' },
  { code: 304712044, name: 'MB "Darbų namai"' },
  { code: 304713986, name: 'UAB "Bioenergija LT"' },
  { code: 304714006, name: 'UAB "Dreamfront"' },
  { code: 304715492, name: 'MB "Joniškio betonas"' },
  { code: 304715581, name: 'UAB "Domuum experti"' },
  { code: 304715930, name: 'MB "Minsona"' },
  { code: 304716719, name: 'UAB "Promoartis"' },
  { code: 304716822, name: 'MB "Herdesa"' },
  { code: 304716968, name: 'UAB "Midoksas"' },
  { code: 304716975, name: 'MB "Vidaus klimatas"' },
  { code: 304718378, name: '"Fibrobetonas", UAB' },
  { code: 304718410, name: 'UAB "Matela"' },
  { code: 304718563, name: 'UAB "Sopka"' },
  { code: 304718613, name: 'MB "Vainera"' },
  { code: 304718816, name: 'UAB "Olfacto"' },
  { code: 304719380, name: 'MB "Orilis"' },
  { code: 304719836, name: 'UAB "Uoksa"' },
  { code: 304719850, name: 'MB "Elijos baldai"' },
  { code: 304720030, name: 'MB "Modlita"' },
  { code: 304721214, name: "MB Tavida" },
  { code: 304721310, name: 'UAB "Fornesa"' },
  { code: 304722960, name: 'UAB "Megasas"' },
  { code: 304723215, name: 'UAB "Linerda"' },
  { code: 304724000, name: 'UAB "Kanapiniai sprendimai"' },
  { code: 304724160, name: 'UAB "Sakaris"' },
  { code: 304725006, name: 'UAB "Skobas"' },
  { code: 304725611, name: 'VšĮ "Medienos amatai"' },
  { code: 304725853, name: 'MB "Medžio aidas"' },
  { code: 304725892, name: 'UAB "Stepė"' },
  { code: 304727587, name: 'UAB "Stiklinimo konstrukcijos"' },
  { code: 304727676, name: 'MB "Ąžuolinė malksna"' },
  { code: 304729862, name: 'UAB "Molėtų baldai"' },
  { code: 304729944, name: 'UAB "Modulinės sienos"' },
  { code: 304732381, name: 'MB "Gaju"' },
  { code: 304732577, name: 'UAB "Nasvė"' },
  { code: 304733907, name: 'UAB "Capella baltica"' },
  { code: 304733953, name: 'UAB "Babtų mediena"' },
  { code: 304737033, name: 'UAB "Elektrociklas"' },
  { code: 304737898, name: 'UAB "Kaizen Projects"' },
  { code: 304738128, name: 'UAB "Torontas"' },
  { code: 304738320, name: 'UAB "Emideira"' },
  { code: 304739931, name: 'UAB "Skandivita"' },
  { code: 304740645, name: 'MB "Novies decenas"' },
  { code: 304740983, name: 'MB "Evivė LT"' },
  { code: 304741416, name: "UAB TOPSTEP LT" },
  { code: 304741957, name: 'MB "Mėsos broliai"' },
  { code: 304742094, name: 'MB "Infinitum versum"' },
  { code: 304742870, name: 'MB "Picus LT"' },
  { code: 304743926, name: 'UAB "Nesto baldai"' },
  { code: 304744227, name: 'MB "Maži darbai"' },
  { code: 304745005, name: 'UAB "Somos komponentai"' },
  { code: 304745293, name: 'UAB "Kubilas"' },
  { code: 304745610, name: "UAB IDEALIGHT LT" },
  { code: 304745927, name: 'UAB "Barnabis"' },
  { code: 304747223, name: 'UAB "Rūkoriai"' },
  { code: 304748898, name: 'MB "Blindos baldai"' },
  { code: 304750358, name: 'IĮ "Akrilenas"' },
  { code: 304750753, name: 'UAB "Lietuviška jautiena"' },
  { code: 304751054, name: 'UAB "Tigredomus"' },
  { code: 304752900, name: 'UAB "Autosiuviniai"' },
  { code: 304753920, name: 'UAB "Majus LT"' },
  { code: 304756343, name: 'MB "Efektyvus šildymas"' },
  { code: 304757178, name: 'MB "Vytautera"' },
  { code: 304757267, name: 'UAB "Baltmarina"' },
  { code: 304757331, name: 'UAB "Proteinas plius"' },
  { code: 304757388, name: 'UAB "Baltsolus"' },
  { code: 304758903, name: 'UAB "Žemvyga"' },
  { code: 304759909, name: 'MB "Medinuko darbai"' },
  { code: 304760142, name: 'MB "Gėlių sapnas"' },
  { code: 304760612, name: 'MB "Instala"' },
  { code: 304765926, name: 'MB "Laiptų idėjos"' },
  { code: 304765972, name: 'UAB "Baldartas"' },
  { code: 304766558, name: 'MB "Noelia"' },
  { code: 304767044, name: 'MB "Asas LT"' },
  { code: 304767165, name: 'UAB "Besis"' },
  { code: 304767934, name: 'MB "Levandų ūkis"' },
  { code: 304771644, name: 'MB "Kas kita"' },
  { code: 304771726, name: 'MB "Motoseras"' },
  { code: 304772447, name: 'UAB "Pūcas"' },
  { code: 304772511, name: 'UAB "Topersas"' },
  { code: 304773403, name: 'UAB "Delvisa"' },
  { code: 304773969, name: '"Royal tree" UAB' },
  { code: 304774373, name: 'UAB "Norteltas"' },
  { code: 304774487, name: 'UAB "Grupas"' },
  { code: 304774569, name: 'MB "Inovitaminas"' },
  { code: 304777106, name: 'UAB "Karlų katilinė"' },
  { code: 304777113, name: 'UAB "Šilo katilinė"' },
  { code: 304777939, name: "UAB 89Bio Lithuania" },
  { code: 304778051, name: 'UAB "Selorsa"' },
  { code: 304779566, name: 'UAB "Karisana"' },
  { code: 304779573, name: 'MB "Ugintė"' },
  { code: 304782046, name: 'MB "Žuvis vandeny"' },
  { code: 304783949, name: 'MB "Unga LT"' },
  { code: 304784218, name: 'MB "Grinega"' },
  { code: 304785010, name: 'MB "Semseras"' },
  { code: 304786429, name: 'MB "Žemaitijos aruodas"' },
  { code: 304787933, name: 'MB "Metalplasta"' },
  { code: 304788152, name: "MB Funkcionaliojo meno studija" },
  { code: 304793466, name: 'UAB "Dabesta"' },
  { code: 304793562, name: "UAB Medžio malūnas" },
  { code: 304795360, name: 'UAB "Baltijos selektas"' },
  { code: 304800366, name: 'Mažoji bendrija "Nemuno vilkas"' },
  { code: 304802456, name: 'UAB "Spotmount"' },
  { code: 304804044, name: 'MB "Raudona peteliškė"' },
  { code: 304805363, name: 'MB "Šlepetija"' },
  { code: 304808911, name: 'UAB "Pitechas"' },
  { code: 304811416, name: 'UAB "Delkantus"' },
  { code: 304813374, name: 'UAB "Navis marina"' },
  { code: 304814857, name: 'MB "Alebaldai"' },
  { code: 304817401, name: 'UAB "Vežėjas"' },
  { code: 304819384, name: 'UAB "Lidėja"' },
  { code: 304819904, name: 'UAB "Hidraulikos meistrai"' },
  { code: 304820041, name: 'MB "Furnita"' },
  { code: 304820874, name: 'UAB "Mibasta"' },
  { code: 304821894, name: 'MB "Am spauda"' },
  { code: 304823105, name: 'UAB "Strategai"' },
  { code: 304823226, name: "Pakoteka UAB" },
  { code: 304824342, name: 'MB "Etnodizainas"' },
  { code: 304824755, name: 'MB "ED baldų projektavimas ir gamyba"' },
  { code: 304826489, name: 'MB "Dvi pelytės"' },
  { code: 304827160, name: 'UAB "Atakatas"' },
  { code: 304828052, name: 'MB "Balduna"' },
  { code: 304829624, name: 'UAB "Nugator"' },
  {
    code: 304829909,
    name: "MB Interjero dizaino ir architektūros studija"
  },
  { code: 304830256, name: 'UAB "Migsmilta"' },
  { code: 304831874, name: 'UAB "Nordvila Vilnius"' },
  { code: 304832976, name: 'MB "Vismanorus"' },
  { code: 304833804, name: 'UAB "Manetas"' },
  { code: 304835933, name: 'UAB "Ekoreforma"' },
  { code: 304835958, name: 'MB "Pintora"' },
  { code: 304837877, name: 'UAB "Apdaila statybai"' },
  { code: 304839718, name: 'MB "Jorova"' },
  { code: 304840040, name: 'IĮ "Vyšnių baldai"' },
  { code: 304840058, name: "Baltic Automotive Glass UAB" },
  { code: 304841968, name: 'UAB "Energijos sprendimai"' },
  { code: 304842543, name: 'MB "Oro labirintai"' },
  { code: 304842625, name: 'UAB "Anirola"' },
  { code: 304843620, name: 'MB "Tikslus pjūvis"' },
  { code: 304843684, name: 'MB "Kitos formos"' },
  { code: 304844544, name: 'UAB "Taelus"' },
  { code: 304844658, name: 'MB "Emelela"' },
  { code: 304845710, name: 'MB "Natyvai"' },
  { code: 304845938, name: 'Mažoji bendrija "Ditena"' },
  { code: 304848375, name: 'MB "Econatus"' },
  { code: 304848966, name: 'UAB "Norkelita"' },
  { code: 304850006, name: 'UAB "Roklista"' },
  { code: 304850700, name: "MB Smilgių šeimos ūkis" },
  { code: 304851414, name: 'UAB "Synergy Supply"' },
  { code: 304851460, name: 'UAB "Fiberauto"' },
  { code: 304852014, name: 'UAB "Ventmann"' },
  { code: 304852886, name: 'UAB "EK furniture"' },
  { code: 304853415, name: "Wood Step, UAB" },
  { code: 304853931, name: 'MB "Grynas oras"' },
  { code: 304853963, name: 'MB "Arstas"' },
  { code: 304854684, name: 'UAB "Acte"' },
  { code: 304856799, name: '"Mano džiaugsmas", MB' },
  { code: 304858234, name: 'UAB "Domus ABC"' },
  { code: 304858412, name: 'MB "Drutmena"' },
  { code: 304858597, name: 'MB "Luco tilia"' },
  { code: 304862649, name: 'MB "Lijanos baldai"' },
  { code: 304862752, name: 'UAB "Baldmanta"' },
  { code: 304863345, name: 'UAB "Būduva"' },
  { code: 304865054, name: 'UAB "Ekovėjai"' },
  { code: 304865346, name: 'MB "Kiaura siena"' },
  { code: 304866010, name: 'UAB "Narinda"' },
  { code: 304866829, name: 'UAB "Andriaus ir Andrėjaus baldai"' },
  { code: 304868221, name: '"Gražu plius", UAB' },
  { code: 304869490, name: "UAB Ekosloth" },
  { code: 304869501, name: 'UAB "Reakiro LT"' },
  { code: 304869540, name: 'MB "Dizaino kalvė"' },
  { code: 304869978, name: 'UAB "Furnipa"' },
  { code: 304869992, name: 'IĮ "Medienos gaminys"' },
  { code: 304871363, name: 'MB "Minkštųjų baldų furnitūra"' },
  { code: 304877971, name: 'MB "Betoniksas"' },
  { code: 304878087, name: 'UAB "Oro gūsis"' },
  { code: 304878176, name: 'UAB "Gileda"' },
  { code: 304878265, name: "E. Bliujaus MB" },
  { code: 304879100, name: 'UAB "Cantale"' },
  { code: 304879164, name: 'MB "Intersiuva"' },
  { code: 304879171, name: 'UAB "Tėvų baldai"' },
  { code: 304879196, name: 'UAB "Pakingas"' },
  { code: 304882559, name: "Malagris Prefab, UAB" },
  { code: 304882598, name: 'MB Kūrybos studija "Virsmas"' },
  { code: 304883369, name: 'IĮ "Mėsininkas"' },
  { code: 304885498, name: 'UAB "Evarosa"' },
  { code: 304885612, name: 'UAB "Evprojektas"' },
  { code: 304885772, name: 'MB "Akinys"' },
  { code: 304887143, name: 'UAB "Aikštis"' },
  { code: 304887620, name: 'MB "Sentrakis"' },
  { code: 304887809, name: 'UAB "Bajormedis"' },
  { code: 304888462, name: 'UAB "Cosmit LT"' },
  { code: 304888544, name: "RR construction UAB" },
  { code: 304888882, name: "UAB DW Systems" },
  { code: 304893370, name: 'UAB "Rąsto namas"' },
  { code: 304894472, name: '"Ekodrena", UAB' },
  { code: 304894757, name: 'UAB "Mobilus betonas"' },
  { code: 304894878, name: 'UAB "Žvėrelių pašarai"' },
  { code: 304895414, name: 'UAB "Degmeda"' },
  { code: 304895880, name: 'UAB "Tetriukas"' },
  { code: 304896701, name: 'MB "Amebos"' },
  { code: 304896719, name: 'UAB "Edictus"' },
  { code: 304896904, name: 'UAB "Aleksoma"' },
  { code: 304902403, name: "UAB Indigo studija" },
  { code: 304903252, name: 'UAB "Baldijos projektai"' },
  { code: 304903380, name: 'UAB "Magrus"' },
  { code: 304909732, name: "UAB Tempsteel" },
  { code: 304910122, name: 'UAB "AC3 technologijos"' },
  { code: 304910186, name: 'UAB "Samogitos SPA"' },
  { code: 304910229, name: 'MB "Biomika"' },
  { code: 304910453, name: 'UAB "Kormotech"' },
  { code: 304910706, name: 'MB "Naktibaldukai"' },
  { code: 304912358, name: 'UAB "9 sparnai"' },
  { code: 304912582, name: 'MB "Viešvilės statyba"' },
  { code: 304912803, name: 'UAB "Erdmetris"' },
  { code: 304914003, name: 'MB "Jovaro baldų" studija' },
  { code: 304914327, name: 'UAB "Kaladoka"' },
  { code: 304914380, name: 'UAB "Balt Build"' },
  { code: 304914715, name: 'UAB "Bau komanda"' },
  { code: 304916068, name: '"Būsto elektra", UAB' },
  { code: 304916890, name: "MB Klimato ir šaldymo grupė" },
  { code: 304917444, name: 'MB "Svajonių gildija"' },
  { code: 304919096, name: 'MB "Unikali namų idėja"' },
  { code: 304919274, name: 'MB "Rytų prekybos grupė"' },
  { code: 304919370, name: 'MB "Selemos briketai"' },
  { code: 304919705, name: 'UAB "Kirtimų katilinė"' },
  { code: 304919783, name: 'UAB "Laiptas"' },
  { code: 304920230, name: 'MB "Rokaja"' },
  { code: 304920312, name: 'MB "Kazimiero Šemetos baldai"' },
  { code: 304920942, name: 'VšĮ "Margi sakalai"' },
  { code: 304922092, name: 'UAB "Orgus"' },
  { code: 304922587, name: 'MB "Nermantas"' },
  { code: 304922886, name: 'UAB "Forto baldai"' },
  { code: 304923041, name: 'MB "Kanapėlė"' },
  { code: 304923212, name: 'MB "Metalo pjaustytojas"' },
  { code: 304923365, name: "UAB PSVL WIND" },
  { code: 304923931, name: 'UAB "Atitvara"' },
  { code: 304924022, name: 'UAB "Subrangovas"' },
  { code: 304924474, name: 'UAB "Mano šamas"' },
  {
    code: 304927972,
    name: 'Uždaroji akcinė bendrovė "Mėnesienos studija"'
  },
  { code: 304928049, name: "MB Dūminė laboratorija" },
  { code: 304928387, name: '"Baldeta", MB' },
  { code: 304928451, name: 'UAB "Climonta"' },
  { code: 304928469, name: 'MB "Baldenis"' },
  { code: 304928978, name: 'UAB "Rolo baldai"' },
  { code: 304929464, name: 'UAB "Stair Supply"' },
  { code: 304930438, name: "UAB Pasvalio biolaboratorija" },
  { code: 304930655, name: 'MB "Ginsonos baldai"' },
  { code: 304931013, name: 'MB "Mes architektai"' },
  { code: 304933384, name: 'MB "Sunzenus"' },
  { code: 304933904, name: 'UAB "Stido"' },
  { code: 304934041, name: 'UAB "Girstata"' },
  { code: 304934422, name: 'UAB "Kodema"' },
  { code: 304934689, name: 'MB "Martalina"' },
  { code: 304935307, name: 'UAB "Oreus"' },
  { code: 304935894, name: 'UAB "Autokraftas"' },
  { code: 304936117, name: 'UAB "Baltijos oda"' },
  { code: 304940471, name: "UAB OAKY OAK" },
  { code: 304942351, name: 'MB "Reikalingi ir stilingi baldai"' },
  { code: 304942629, name: 'UAB "Tikras dūmas"' },
  { code: 304942636, name: 'MB "Res metalli"' },
  { code: 304943446, name: 'MB "Kretesta"' },
  { code: 304944434, name: 'UAB "Mėsos taškas"' },
  { code: 304944968, name: 'MB "Herculis ministerium"' },
  { code: 304945041, name: 'UAB "Lielos gamyba"' },
  { code: 304945109, name: 'UAB "Lauba"' },
  { code: 304945212, name: 'UAB "Konstrukcijų sistemos"' },
  { code: 304945568, name: "UAB Vintori" },
  { code: 304946524, name: 'UAB "ITP saulės parkai"' },
  { code: 304946734, name: 'MB "Ruplita"' },
  { code: 304946780, name: 'UAB "Autogema"' },
  { code: 304947590, name: 'UAB "Mansurus"' },
  { code: 304948589, name: 'UAB "PortMare"' },
  { code: 304948703, name: 'MB "Esencija"' },
  { code: 304948906, name: 'UAB "Baltijos technikos grupė"' },
  { code: 304949068, name: 'MB "Danlinė"' },
  { code: 304949207, name: 'MB "Onlita"' },
  { code: 304951941, name: 'Mažoji bendrija "Natūralus akmuo"' },
  { code: 304952139, name: 'UAB "Laisvieji amatininkai"' },
  { code: 304952363, name: 'MB "Ledva"' },
  { code: 304952445, name: 'MB "Amatis"' },
  { code: 304954275, name: 'UAB "Dvarmedis"' },
  { code: 304954649, name: 'MB "Jankus"' },
  { code: 304954663, name: 'UAB "Repalis"' },
  { code: 304954738, name: 'UAB "Telšių vėjo parkas"' },
  { code: 304954916, name: "MB Onutės ūkis" },
  { code: 304954955, name: 'MB "Svirnelis"' },
  { code: 304955929, name: 'MB "Jomalka"' },
  { code: 304956536, name: 'UAB "Tiga LT"' },
  { code: 304956696, name: 'UAB "Norgelitas"' },
  { code: 304956995, name: 'MB "Mano baldita"' },
  { code: 304957677, name: 'UAB "Ąžuolo spalvos"' },
  { code: 304957809, name: 'UAB "Asonbaldis"' },
  { code: 304957951, name: 'MB "Ablius"' },
  { code: 304958117, name: 'MB "Medienos meka"' },
  { code: 304958238, name: 'UAB "Miautos"' },
  { code: 304958277, name: "UAB Laičių lentpjūvė" },
  { code: 304958494, name: "MB GRAN PELET" },
  { code: 304958537, name: 'MB "Renatos keramika"' },
  { code: 304958708, name: 'MB "VK JUSTED international"' },
  { code: 304959016, name: 'UAB "Terebras"' },
  { code: 304959500, name: 'MB "Birota"' },
  { code: 304959525, name: 'MB "Džiugis"' },
  { code: 304960125, name: 'MB "Versus interior"' },
  { code: 304960399, name: 'UAB "Arbor LT"' },
  { code: 304960520, name: 'MB "Riboka"' },
  { code: 304960659, name: "UAB Frezavimo namai" },
  { code: 304960958, name: 'MB "Norkvudas"' },
  { code: 304961540, name: 'MB "Puikio sodas"' },
  { code: 304961711, name: 'MB "Logorama"' },
  { code: 304963388, name: 'VŠĮ "AORC"' },
  { code: 304964508, name: 'MB "Katamaranas"' },
  { code: 304966587, name: 'UAB "Rasvaldas"' },
  { code: 304967415, name: 'MB "Rolmetra"' },
  { code: 304971552, name: 'MB "Medžio meka"' },
  { code: 304972323, name: '"Atliekų guru", VšĮ' },
  { code: 304972693, name: 'MB "Felica"' },
  { code: 304973539, name: 'UAB "Kondista"' },
  { code: 304973553, name: "UAB RECYCLING tec&sol" },
  { code: 304973770, name: 'IĮ "Nantu"' },
  { code: 304974025, name: 'UAB "Teneracelis"' },
  { code: 304974356, name: 'MB "Sorbus"' },
  { code: 304975465, name: 'UAB "Ekko LT"' },
  { code: 304975675, name: 'MB "Leonardo Ugolini"' },
  { code: 304975885, name: 'MB "Kalk vinį"' },
  { code: 304975974, name: 'MB "Paslaugos paukštininkams"' },
  { code: 304978027, name: "UAB LEAD TIME ZERO" },
  { code: 304978041, name: "UAB Epsoma" },
  { code: 304982958, name: 'UAB "Formatai"' },
  { code: 304982965, name: 'UAB "Igmanta"' },
  { code: 304983063, name: 'UAB "Hirtus"' },
  { code: 304984884, name: 'MB "Arūno baldai Kretingoje"' },
  { code: 304985961, name: 'UAB "Dimantra"' },
  { code: 304986579, name: 'MB "Rolandija"' },
  { code: 304986682, name: 'UAB "Gidovis"' },
  { code: 304987898, name: "MB Kintama srovė" },
  { code: 304987948, name: 'UAB "Homm space"' },
  { code: 304989657, name: 'UAB "Stamelos medis"' },
  { code: 304990022, name: '"Color factory", UAB' },
  { code: 304991131, name: 'MB "Lauryno baldai"' },
  { code: 304992938, name: 'UAB "Baldjana"' },
  { code: 304992945, name: 'UAB "Interjero ekspertai"' },
  { code: 304993374, name: 'UAB "HAASTE LIETUVA"' },
  { code: 304994394, name: 'UAB "Green Genius Global"' },
  { code: 304994907, name: 'UAB "Homanit Lietuva"' },
  { code: 304995471, name: 'MB "Atovitas"' },
  { code: 304995578, name: 'Uždaroji akcinė bendrovė "EKOPIK"' },
  { code: 304996178, name: 'MB "Įdomūs projektai"' },
  { code: 304998880, name: 'UAB "Oil & Process Services LT"' },
  { code: 305002894, name: 'MB "Akiratis"' },
  { code: 305003672, name: 'UAB "Monojus"' },
  { code: 305003779, name: "UAB Pop up sistemos" },
  { code: 305005919, name: 'UAB "Pantonas"' },
  { code: 305005972, name: "UAB Royal decors" },
  { code: 305006088, name: 'UAB "Medžio galia"' },
  { code: 305008662, name: 'UAB "DNR baldai"' },
  { code: 305010599, name: 'UAB "Harvuda"' },
  { code: 305011975, name: 'MB "Avteka"' },
  { code: 305012333, name: 'MB "Aro baldai"' },
  { code: 305016125, name: 'MB "Buratino slėptuvė"' },
  { code: 305016196, name: 'UAB "MARINE WORK"' },
  { code: 305016972, name: 'UAB "Undatus"' },
  { code: 305018044, name: 'MB "Svajojantis medis"' },
  { code: 305020052, name: 'UAB "Heksas"' },
  { code: 305022459, name: 'UAB "Noranas"' },
  { code: 305024517, name: 'MB "Bendra vizija"' },
  { code: 305025505, name: 'MB "Kiškio baldai"' },
  { code: 305025786, name: 'UAB "Metaltonas"' },
  { code: 305026660, name: "UAB Kosmetikos tyrimų centras" },
  { code: 305034034, name: 'UAB "Neto baldai"' },
  { code: 305035969, name: 'UAB "Teruna"' },
  { code: 305041477, name: 'UAB "Reivesta"' },
  { code: 305044384, name: 'UAB "Biokora"' },
  { code: 305044402, name: 'UAB "Idealus pasirinkimas"' },
  { code: 305047416, name: 'UAB "MINXfarming"' },
  { code: 305047626, name: 'MB "Gretos laiptai"' },
  { code: 305047932, name: 'UAB "Lietuviškas ąžuolas"' },
  { code: 305051400, name: "UAB SMART BOATS LT" },
  { code: 305052114, name: 'UAB "Gardų baldai"' },
  { code: 305053433, name: 'MB "Margas genys"' },
  { code: 305053839, name: "Glomar, UAB" },
  { code: 305053935, name: 'MB "Alrina"' },
  { code: 305060470, name: 'UAB "Artiga LT"' },
  { code: 305063559, name: 'UAB "Nauvija"' },
  { code: 305063566, name: 'MB "Plastikas ir metalas"' },
  { code: 305071972, name: 'MB "Gvijora"' },
  { code: 305072412, name: 'MB "9 pramanai"' },
  { code: 305072946, name: 'UAB "Vaupsa"' },
  { code: 305073158, name: 'UAB "Timberforma"' },
  { code: 305073471, name: "VšĮ Politechnologijų centras" },
  { code: 305078963, name: 'MB "Ponulio interjero sprendimai"' },
  { code: 305083921, name: 'UAB "RODAN Technologies"' },
  { code: 305083992, name: 'UAB "Solidi vizija"' },
  { code: 305088530, name: 'MB "Dvi knygos"' },
  { code: 305089899, name: 'UAB "Tybounas"' },
  { code: 305093381, name: 'UAB "Baldai jums NT"' },
  { code: 305094113, name: 'MB "Ekopolimeras"' },
  { code: 305094615, name: 'MB "Metalo idėjos"' },
  { code: 305098154, name: 'UAB "Asiogrupė"' },
  { code: 305098460, name: "UAB BUDULAI" },
  { code: 305098880, name: 'UAB "Jonytė"' },
  { code: 305099473, name: 'UAB "Marginimas"' },
  { code: 305102439, name: 'UAB "Rosa LT"' },
  { code: 305102663, name: 'MB "Valentino projektai"' },
  { code: 305115408, name: 'MB "Sabo baldai"' },
  { code: 305118030, name: 'UAB "Sunly Infra"' },
  { code: 305135250, name: 'MB "Daisona"' },
  { code: 305137568, name: 'UAB "Sipeko sprendimai"' },
  { code: 305142921, name: 'MB "Medžio tvirtovė"' },
  { code: 305143927, name: 'MB "Artizanas"' },
  { code: 305144534, name: 'MB "Frilanca"' },
  { code: 305144541, name: 'MB "Labomeda"' },
  { code: 305146001, name: 'MB "Apdailos avilys"' },
  { code: 305146389, name: 'UAB "Tomo baldai"' },
  { code: 305146492, name: 'UAB "Ekaja"' },
  { code: 305151831, name: 'UAB "Ventus industria"' },
  { code: 305151856, name: 'MB "Eineros baldai"' },
  { code: 305152082, name: 'UAB "Horreum"' },
  {
    code: 305152890,
    name: 'Žemės ūkio kooperatyvas "Dusetų krašto gaspadoriai"'
  },
  { code: 305154069, name: 'UAB "Kita prekyba"' },
  { code: 305154140, name: 'MB "Šilta ir šalta"' },
  { code: 305155000, name: "MB Medžio studija" },
  { code: 305156682, name: 'UAB "Teptukas"' },
  { code: 305156725, name: 'UAB "Vėjas LT"' },
  { code: 305159422, name: '"Larūna", MB' },
  { code: 305160022, name: 'MB "Modulina"' },
  { code: 305160570, name: 'MB "Baldų projektavimas"' },
  { code: 305160734, name: 'MB "Ginmonta"' },
  { code: 305163082, name: 'MB "Matodenta"' },
  { code: 305163844, name: 'MB "Sprendimai namam"' },
  { code: 305167141, name: 'MB "Ventima"' },
  { code: 305167419, name: 'UAB "Risteda"' },
  { code: 305173596, name: "UAB KRUSZBET Lithuania" },
  { code: 305176076, name: 'UAB "Harmony House dizainas"' },
  { code: 305176496, name: "UAB Glassbee" },
  { code: 305177993, name: 'MB "Madebytetis"' },
  { code: 305178006, name: 'UAB "NB eco"' },
  { code: 305180391, name: 'UAB "Pakomega"' },
  { code: 305180893, name: 'UAB "Raikona"' },
  { code: 305183099, name: "MB Darbykla" },
  { code: 305184386, name: "MB Nepriklausomos dirbtuvės" },
  { code: 305184977, name: 'MB "Ekoidėjos"' },
  { code: 305187012, name: 'UAB "Narantis"' },
  { code: 305189554, name: 'MB "Fimkona"' },
  { code: 305189636, name: 'IĮ "Buki kaltai"' },
  { code: 305191384, name: 'UAB "Eurobetonas"' },
  { code: 305192461, name: 'MB "Fricoma"' },
  { code: 305193022, name: 'UAB "Meistrinė"' },
  { code: 305198400, name: 'MB "Inoidėja"' },
  { code: 305201921, name: 'UAB "Emona LT"' },
  { code: 305202311, name: "A. Požės MB" },
  { code: 305205001, name: 'MB "Vėsinta"' },
  { code: 305207080, name: 'UAB "Tonuota"' },
  { code: 305207269, name: 'UAB "KSM Technologies"' },
  { code: 305208385, name: 'MB "Maneksa"' },
  { code: 305208442, name: 'UAB "Gera galia"' },
  { code: 305208588, name: "UAB Mykolajivo laivų statykla" },
  { code: 305208620, name: 'IĮ "Partus"' },
  { code: 305208773, name: 'UAB "Ado baldai"' },
  { code: 305208848, name: 'UAB "Bioenergijos projektai"' },
  { code: 305209829, name: 'UAB "Bioenergijos vystymas"' },
  { code: 305210240, name: 'UAB "Tomeura"' },
  { code: 305210258, name: 'UAB "Bioinvesticijos"' },
  { code: 305210468, name: 'UAB "Miego linija"' },
  { code: 305211627, name: 'UAB "Nydlas"' },
  { code: 305212907, name: 'UAB "Esabas"' },
  { code: 305217199, name: 'UAB "Lamelė"' },
  { code: 305217434, name: 'MB "Robalda"' },
  { code: 305217644, name: 'UAB "Ternis"' },
  { code: 305217651, name: 'MB "Archideka"' },
  { code: 305217669, name: 'UAB "Kelmas"' },
  { code: 305218372, name: 'UAB "Medžio laikas"' },
  { code: 305219524, name: 'UAB "Baldai pagal užsakymą"' },
  { code: 305219645, name: 'MB "Juknita"' },
  { code: 305221621, name: 'UAB "Školnikovas ir kompanija"' },
  { code: 305222463, name: 'MB "Venisonas"' },
  { code: 305222958, name: 'UAB "Auginsta"' },
  { code: 305224717, name: 'UAB "Chronus"' },
  { code: 305224934, name: 'UAB "Atnidonas"' },
  { code: 305225025, name: 'UAB "Garliavos granitas"' },
  { code: 305226604, name: 'UAB "Aztec oils baltic"' },
  { code: 305228391, name: "L. Murino IĮ" },
  { code: 305228441, name: 'UAB "Fenas"' },
  { code: 305228498, name: 'UAB "Holzo produktai"' },
  { code: 305228765, name: 'MB "Sakalo grupė"' },
  { code: 305229753, name: 'UAB "Saulės kontrolė"' },
  { code: 305229917, name: 'MB "Dušo kabinos"' },
  { code: 305230093, name: 'MB baldų studija "Plienmedis"' },
  { code: 305231761, name: 'MB "Salos mediena"' },
  { code: 305231875, name: 'UAB "Spartus augimas"' },
  { code: 305231957, name: 'UAB "Baldų garantas"' },
  { code: 305232151, name: 'MB "Oro sistemų instaliacija"' },
  { code: 305232233, name: 'MB "Perkūno kariai"' },
  { code: 305236609, name: 'MB "Milijonas kampų"' },
  { code: 305237038, name: 'MB "Marealas"' },
  { code: 305237967, name: 'UAB "Dangrita"' },
  { code: 305238880, name: 'MB "Stotfordas"' },
  { code: 305240504, name: 'UAB "Ekotogas"' },
  { code: 305241008, name: 'UAB "Timbersupra"' },
  { code: 305241257, name: 'UAB "Irvis LT"' },
  { code: 305244470, name: 'MB "Res sacra"' },
  { code: 305244716, name: 'MB "Iktas"' },
  { code: 305245152, name: 'MB "Plastiko sprendimai"' },
  { code: 305245907, name: 'UAB "Efektualus"' },
  { code: 305245946, name: 'MB "Įdarbink orą"' },
  { code: 305246806, name: 'MB "Aš ir ji"' },
  { code: 305248390, name: 'MB "Lamuras"' },
  { code: 305252296, name: 'MB "LED grandas"' },
  { code: 305253761, name: 'UAB "Nauteda"' },
  { code: 305253900, name: 'MB "Giguga"' },
  { code: 305254144, name: "LUXAS LT UAB" },
  { code: 305256565, name: 'UAB studija "Michael McCready"' },
  { code: 305256704, name: "BeeWaTec Baltics, UAB" },
  { code: 305260649, name: "KŪB Agrosindikatas" },
  { code: 305260720, name: 'UAB "Protomas"' },
  { code: 305264640, name: 'UAB "Adzida"' },
  { code: 305266417, name: 'UAB "Istvudas"' },
  { code: 305268820, name: 'UAB "Probaltica"' },
  { code: 305271460, name: 'UAB "Uferma"' },
  { code: 305273632, name: 'UAB "Skomis"' },
  { code: 305276048, name: "S.S.P.T. Limited atstovybė Lietuvoje" },
  { code: 305276233, name: 'MB "Rideris"' },
  { code: 305277381, name: 'MB "Mingrida"' },
  { code: 305277545, name: "A.R.S.A.project UAB" },
  { code: 305278437, name: 'MB "Babadis"' },
  { code: 305280534, name: 'UAB "Bukmedis"' },
  { code: 305280897, name: "IĮ Reinio dirbtuvės" },
  { code: 305281102, name: 'MB "Lafaba"' },
  { code: 305282439, name: 'UAB "Tvirtas baldas"' },
  { code: 305283904, name: 'UAB "SBA Modular"' },
  { code: 305287169, name: 'MB "Mireza"' },
  { code: 305288381, name: 'UAB "Lygeda"' },
  { code: 305288958, name: 'MB "Akstatyba"' },
  { code: 305289031, name: 'MB "Labai puiku"' },
  { code: 305289405, name: 'MB "Vardenis"' },
  { code: 305289501, name: 'UAB "Vėjomaina"' },
  { code: 305290197, name: 'MB "Suvalkijos mėsos gaminiai"' },
  { code: 305290941, name: "MB Stand4Expo" },
  { code: 305293492, name: '"Gamybos žemė", UAB' },
  { code: 305293955, name: 'UAB "LitBike"' },
  { code: 305294943, name: 'UAB "DP acetate"' },
  { code: 305295002, name: 'UAB "Livestra"' },
  { code: 305297971, name: 'MB "Nematomi"' },
  { code: 305304394, name: 'UAB "Arnus LT"' },
  { code: 305307515, name: 'MB "Ekogreinas"' },
  { code: 305328897, name: 'UAB "Baldineta"' },
  { code: 305336972, name: 'MB "Ovalūs kampai"' },
  { code: 305337316, name: "UAB Zenko Lithuania" },
  { code: 305340390, name: 'UAB "Huseby"' },
  { code: 305343408, name: 'MB "Irivis"' },
  { code: 305343454, name: 'MB "Kybo"' },
  { code: 305344684, name: 'UAB "Huseros baldai"' },
  { code: 305344969, name: 'UAB "Skandnova"' },
  { code: 305345380, name: 'MB "Ekspostendai"' },
  { code: 305347883, name: 'UAB "Aldo baldai"' },
  { code: 305350267, name: 'UAB "Prikas"' },
  { code: 305356398, name: 'MB "Egerta"' },
  { code: 305357404, name: 'UAB "Zoventa"' },
  { code: 305361281, name: 'MB "Simo žuvis"' },
  { code: 305361331, name: 'MB "Nenerita"' },
  { code: 305362892, name: 'MB "Lankoj"' },
  { code: 305363389, name: 'UAB "LTF gamyba"' },
  { code: 305363898, name: 'UAB "Bantinus"' },
  { code: 305366086, name: 'MB "Aleksandro baldai"' },
  { code: 305368895, name: 'MB "Namų bitė"' },
  { code: 305370889, name: 'UAB "Pajūrio energetika"' },
  { code: 305376415, name: 'UAB "Baldų mūza"' },
  { code: 305376632, name: 'MB "Polo baldai"' },
  { code: 305387397, name: 'MB "Keksas ir braškė"' },
  { code: 305390443, name: 'MB "Medinis projektas"' },
  { code: 305390614, name: 'UAB "Virkuma"' },
  { code: 305390728, name: 'MB "Labanoris"' },
  { code: 305393037, name: 'MB "Alfo mediniai gaminiai"' },
  { code: 305393158, name: 'MB "Rinktis stilingus baldus"' },
  { code: 305393560, name: 'MB "Stilingi baldai"' },
  { code: 305393603, name: 'MB "9 pirštai"' },
  { code: 305393884, name: 'UAB "Neitrina"' },
  { code: 305394032, name: 'MB "Tomtecha"' },
  { code: 305396898, name: 'MB "3D forma"' },
  { code: 305398881, name: 'MB "Laužai"' },
  { code: 305399168, name: 'UAB "Polistirolo granulės"' },
  { code: 305399517, name: "MB EffectSIM" },
  { code: 305399524, name: 'MB "Sorakita"' },
  { code: 305399606, name: 'UAB "Dariaus baldai"' },
  { code: 305400001, name: 'UAB "Inoverta"' },
  { code: 305400026, name: "Kinderio mažoji bendrija" },
  { code: 305400072, name: 'MB "Gitvida"' },
  { code: 305400930, name: 'MB "Zakis"' },
  { code: 305400948, name: "UAB M.A.D Group Baltic" },
  { code: 305400994, name: 'UAB "Žemaitijos betonas"' },
  { code: 305401886, name: 'MB "Lumenika"' },
  { code: 305402415, name: '"Solidsofa", UAB' },
  { code: 305402924, name: 'MB "Tentalis"' },
  { code: 305403894, name: 'UAB "Kailiai LT"' },
  { code: 305404932, name: "Compotexa, UAB" },
  { code: 305405920, name: 'MB "Algimus"' },
  { code: 305406495, name: 'MB "Pasaulio magija"' },
  { code: 305406698, name: 'UAB "Astecha"' },
  { code: 305408471, name: 'MB "Inkopa"' },
  { code: 305408891, name: '"Laiptų dizaino idėjos", MB' },
  { code: 305408966, name: 'MB "Bulaipta"' },
  { code: 305413899, name: '"Nilteka", MB' },
  { code: 305416169, name: 'MB "Brovita"' },
  { code: 305416226, name: 'MB "Gilbra"' },
  { code: 305419578, name: 'MB "Deco Lumina"' },
  { code: 305420705, name: "Sunly Land, UAB" },
  { code: 305420954, name: 'MB "Montis ir partneriai"' },
  { code: 305421216, name: 'UAB "Ulcero"' },
  { code: 305423701, name: 'MB "Topbaldai"' },
  { code: 305425054, name: 'UAB "Grevima"' },
  { code: 305425360, name: 'MB "Eimprojektai"' },
  { code: 305427009, name: 'UAB "Baldų ritmas"' },
  { code: 305427112, name: "MB BALDEKSIMA" },
  { code: 305427429, name: 'MB "Tinycamper"' },
  { code: 305428545, name: 'UAB "Langdesta"' },
  { code: 305428762, name: 'UAB "Alufsolutions"' },
  { code: 305429857, name: "UAB Ersemus" },
  { code: 305431897, name: 'UAB "PIR LT"' },
  { code: 305432052, name: 'UAB "HEXAWOOD"' },
  { code: 305434516, name: 'UAB "Garso barjerai"' },
  { code: 305434854, name: 'MB "Gelžbetonio gamyba"' },
  { code: 305435027, name: 'UAB "Layer Pharma"' },
  { code: 305437003, name: "ERMUL, UAB" },
  { code: 305438087, name: "EXONAS, MB" },
  { code: 305438169, name: 'UAB "NV CNC Projektai"' },
  { code: 305438201, name: 'MB "Ir stiklas ir veidrodis"' },
  { code: 305438429, name: "UAB Baldai lengvai" },
  { code: 305439851, name: 'MB "Albero baldai"' },
  { code: 305439876, name: 'UAB "Medna"' },
  { code: 305440387, name: 'MB "Automatic power"' },
  { code: 305440896, name: 'MB "Go Go betonas"' },
  { code: 305441788, name: 'UAB "Vudpra"' },
  { code: 305442477, name: 'MB "AVG projektas"' },
  { code: 305442840, name: "MB Veidrodis" },
  { code: 305442897, name: 'UAB "Team41 Santvara"' },
  { code: 305444300, name: 'UAB "Klijuotas medis"' },
  { code: 305445808, name: "UAB FC Service" },
  { code: 305450417, name: 'MB "Žeimėsa"' },
  { code: 305453025, name: 'UAB "Felix vita"' },
  { code: 305453228, name: 'UAB "Matmeta"' },
  { code: 305454134, name: 'UAB "Tovlita"' },
  { code: 305456968, name: 'MB "Darbalda"' },
  { code: 305457358, name: 'UAB "Opus conventum"' },
  { code: 305460183, name: 'UAB "Metvera"' },
  { code: 305460190, name: 'MB "Gatika"' },
  { code: 305460265, name: "Fitodenta, UAB" },
  { code: 305461488, name: 'UAB "Fabrica Lea"' },
  { code: 305462387, name: 'MB "Mattija"' },
  { code: 305462444, name: "MB Mideluma" },
  { code: 305464737, name: 'UAB "Estubos baldai"' },
  { code: 305464826, name: "MB Jorudas" },
  { code: 305465465, name: 'MB "Rimvydo paslaugos"' },
  { code: 305467822, name: "AMD Metalai, UAB" },
  { code: 305468504, name: 'MB "Laigrida"' },
  { code: 305470359, name: "UAB V.P.D. VISŲ POBŪDŽIŲ DARBAI" },
  { code: 305484227, name: 'MB "Piripork"' },
  { code: 305485382, name: 'UAB "Lieto marmuro gamyba"' },
  { code: 305487668, name: 'MB "Takeris"' },
  { code: 305492932, name: "MB SEVI CO" },
  { code: 305492964, name: "UAB Vilmarda" },
  { code: 305493710, name: 'UAB "MG grop"' },
  { code: 305496432, name: 'MB "SK baldai"' },
  { code: 305504427, name: "MB Pumpėnų mėsinė" },
  { code: 305506079, name: 'UAB "Adisant"' },
  { code: 305506887, name: 'MB "Vilgma"' },
  { code: 305508404, name: 'UAB "Mickūnų socialiniai sprendimai"' },
  { code: 305508525, name: "BIO 2 JOS, UAB" },
  { code: 305510412, name: 'UAB "Sifab"' },
  { code: 305514414, name: 'UAB "Lighting Brothers"' },
  { code: 305515384, name: 'UAB "Seaboats LT"' },
  { code: 305515427, name: 'MB "Vilniaus dailidės"' },
  { code: 305518398, name: 'UAB "Momose superfoods"' },
  { code: 305520424, name: 'MB "Narkaus baldai"' },
  { code: 305522471, name: "Farma tiekimas UAB" },
  { code: 305524604, name: "Bacteo, UAB" },
  { code: 305529957, name: "Resize Design UAB" },
  { code: 305531381, name: 'UAB "Hotlan"' },
  { code: 305534388, name: 'UAB "Ekolat"' },
  { code: 305534609, name: 'MB "SN PROJEKTAI"' },
  { code: 305537167, name: 'UAB "upcy Lithuania"' },
  { code: 305538408, name: 'MB "JP ENERGY"' },
  { code: 305540373, name: "UAB Klentus" },
  { code: 305541500, name: "Metso Outotec Lithuania UAB" },
  { code: 305543704, name: "MB New House Solution" },
  { code: 305545712, name: 'MB "Šilumos kontrolė"' },
  { code: 305546013, name: 'UAB "Cartopaka"' },
  { code: 305546440, name: 'UAB "Domersa"' },
  { code: 305548466, name: 'UAB "VIVA fertis"' },
  {
    code: 305548498,
    name: "Mažutėlių ir netradicinių namelių ascociacija"
  },
  { code: 305548726, name: "Poliruotas UAB" },
  { code: 305548733, name: "MB Ailema" },
  { code: 305549461, name: 'MB "Liux lovos"' },
  { code: 305550079, name: 'MB "Dabškinta"' },
  { code: 305552450, name: 'UAB "Gili mintis"' },
  { code: 305552475, name: 'MB "Lašinukas"' },
  { code: 305553125, name: 'UAB "Laudesta"' },
  { code: 305553310, name: 'UAB "Dailida"' },
  { code: 305553680, name: "UAB GRACETA" },
  { code: 305553755, name: 'MB "Dūmykla"' },
  { code: 305553787, name: '"Bodesos langai", UAB' },
  { code: 305555172, name: 'MB "LINAI"' },
  { code: 305555980, name: 'MB "Mažosios dirbtuvės"' },
  { code: 305556025, name: "IĮ Miros Žiedas" },
  { code: 305556139, name: 'UAB "Solidus furniture"' },
  { code: 305556826, name: 'MB "TETRI"' },
  { code: 305557045, name: 'UAB "Mudera"' },
  { code: 305558332, name: 'MB "Žuvija"' },
  { code: 305560529, name: 'UAB "ELIT partners"' },
  { code: 305560810, name: "MB Tumkeva" },
  { code: 305560949, name: "MB ROKIŠKIO BALDAI" },
  { code: 305560988, name: 'UAB "SGamyba"' },
  { code: 305561499, name: 'MB "Gylu"' },
  { code: 305561780, name: "UAB FITMENTA" },
  { code: 305561855, name: "MB Krikščioniškių mėsos cechas" },
  { code: 305561969, name: 'MB "Sausalapis"' },
  { code: 305561983, name: "MB Sergina" },
  { code: 305562081, name: 'MB "Izometra"' },
  { code: 305562117, name: "MB LEO Consult" },
  { code: 305562373, name: 'MB "Štrichas ir niuansas"' },
  { code: 305562626, name: 'MB "Drilius"' },
  { code: 305563482, name: 'MB "BALMATA"' },
  { code: 305563564, name: "MB EKO VIENAS" },
  { code: 305563685, name: "MB Eli Hus" },
  { code: 305563984, name: 'UAB "Santomartis"' },
  { code: 305564164, name: 'MB "Best baldai"' },
  { code: 305564285, name: 'UAB "Stiklo darbai"' },
  { code: 305564844, name: 'UAB "DEFRO LT"' },
  { code: 305564901, name: "MB Lentmedis" },
  { code: 305565070, name: "REHAU Production LT UAB" },
  { code: 305565280, name: "MB RimkusWoodWorks" },
  { code: 305565768, name: 'UAB "Ecoella"' },
  { code: 305566037, name: 'MB "Avalo LT"' },
  { code: 305566069, name: 'UAB "Baldų nestandartas"' },
  { code: 305566172, name: 'MB "Pastelės namai"' },
  { code: 305566279, name: 'UAB "Marijampolės NPK"' },
  { code: 305566432, name: 'MB "Jumipro"' },
  { code: 305567089, name: 'MB "Tikra mėsa"' },
  { code: 305567096, name: 'MB "Pelėdos medis"' },
  { code: 305567477, name: 'MB "Asiga concept"' },
  { code: 305567491, name: "MB SUJEILA" },
  { code: 305568369, name: 'UAB "Karvita"' },
  { code: 305568376, name: 'MB "Libona"' },
  { code: 305568426, name: 'MB "Jachta Kristina"' },
  { code: 305568604, name: "MB RC ESTETIKA" },
  { code: 305568814, name: 'UAB "Granuleda"' },
  { code: 305568878, name: "MB Plastmeka" },
  { code: 305568967, name: 'MB "Lurenos baldai"' },
  { code: 305569108, name: 'MB "Ivetos baldai"' },
  { code: 305569300, name: "UAB FUERTA" },
  { code: 305569332, name: "UAB KGTex" },
  { code: 305569866, name: 'MB "MP Group"' },
  { code: 305569909, name: 'MB "MJ GRANULĖS"' },
  { code: 305570135, name: "UAB Butkevič ir ko" },
  { code: 305570167, name: 'MB "Celula"' },
  { code: 305570690, name: 'MB "Lokera"' },
  { code: 305570854, name: 'MB "3D sfera"' },
  { code: 305570904, name: 'MB "Šiaurės Rytų aliejus"' },
  { code: 305571073, name: "MB Nerifox" },
  { code: 305571155, name: 'MB "Miltu"' },
  { code: 305571212, name: 'MB "NST baldai"' },
  { code: 305571283, name: 'MB "Adelė ir ko"' },
  { code: 305571454, name: 'MB "Šviesos mylia"' },
  { code: 305571760, name: 'UAB "Munetas"' },
  { code: 305572570, name: 'MB "TinT line"' },
  { code: 305572759, name: 'MB "Bardasta"' },
  { code: 305573003, name: 'MB "Lorduta"' },
  { code: 305574062, name: 'MB "JM projektas"' },
  { code: 305574169, name: 'MB "CEILING GO"' },
  { code: 305574176, name: 'MB "Perdirbimas"' },
  { code: 305574386, name: 'UAB "Metalo Interjero Studija"' },
  { code: 305574500, name: 'MB "LINO BALDŲ GAMYBA"' },
  { code: 305575118, name: 'MB "Simi baldai"' },
  { code: 305576978, name: "Jurovika MB" },
  { code: 305578395, name: 'MB "Ūkis veža"' },
  { code: 305582269, name: "MB Technical Engineering Service" },
  { code: 305585646, name: "UAB FABERONA" },
  { code: 305585863, name: "UAB SOLARCHECKin" },
  { code: 305586844, name: 'UAB "Palmila"' },
  { code: 305587565, name: "MB Santechnikai Kaune" },
  { code: 305587661, name: 'MB "INDUSTRINĖS IDĖJOS"' },
  { code: 305589018, name: 'MB "Balta gilė"' },
  { code: 305589395, name: "MB Ergo baldai" },
  { code: 305589826, name: "UAB Pakorama" },
  { code: 305589840, name: 'Mažoji bendrija "Renavo mėsinė"' },
  { code: 305590757, name: "Stiprios, MB" },
  { code: 305590853, name: 'UAB "Pilaitės servisas"' },
  { code: 305591453, name: 'MB "Miške augę"' },
  { code: 305591784, name: "Katmanukas MB" },
  { code: 305592548, name: 'MB "Liustrai"' },
  { code: 305592605, name: 'UAB "Baldai saldžiam miegui"' },
  { code: 305594015, name: 'UAB "Betono laužas"' },
  { code: 305595590, name: "UAB Ludus odoris" },
  { code: 305595925, name: 'MB "Gravelija"' },
  { code: 305596703, name: 'UAB "Višta rūke"' },
  { code: 305596849, name: 'MB "Armaniškės"' },
  { code: 305597965, name: 'MB "ASKI Baldai"' },
  { code: 305598106, name: 'UAB "Building Pro"' },
  { code: 305598202, name: "MB SJGROUPS" },
  { code: 305599197, name: "UAB ESSCALA rental" },
  { code: 305600233, name: 'UAB "Idėjos jūsų langams"' },
  { code: 305600404, name: 'MB "Violeda"' },
  { code: 305600564, name: "MB IMI Group" },
  { code: 305601285, name: "UAB Masters dep" },
  { code: 305603215, name: 'UAB "Sidema"' },
  { code: 305604139, name: "MB D.V.G.D." },
  { code: 305604331, name: 'UAB "ANP inovaciniai sprendimai"' },
  { code: 305607434, name: "UAB AJ Group LT" },
  { code: 305608536, name: 'UAB "Smeltės vėjų jėgainės"' },
  { code: 305608618, name: 'MB "Slaptas receptas"' },
  { code: 305610747, name: "MB Flamingwood" },
  {
    code: 305610964,
    name: 'Uždaroji akcinė bendrovė "VMG Akmenės baldai"'
  },
  { code: 305611009, name: 'UAB "Novus group"' },
  { code: 305612043, name: 'UAB "Romenita"' },
  { code: 305612958, name: "UAB Benauta" },
  { code: 305615000, name: 'MB "Dirba vyrai"' },
  { code: 305615609, name: "MB Oro Kondicionavimo Meistras" },
  { code: 305615655, name: "UAB MONTECO" },
  { code: 305617421, name: "UAB Memel Furniture" },
  { code: 305617581, name: 'MB "Brolių mėsinė"' },
  { code: 305617713, name: 'MB "Kauko ratai"' },
  { code: 305618377, name: "MB MediniukaS" },
  { code: 305618459, name: 'UAB "Diansa"' },
  { code: 305618555, name: 'MB "Polo baldai ir Ko"' },
  { code: 305619625, name: 'UAB "Pirkios"' },
  { code: 305619696, name: 'UAB "Almet"' },
  { code: 305619707, name: "MB Mamutas" },
  { code: 305620079, name: 'MB "D&Dr Company"' },
  { code: 305621373, name: 'UAB "Rateda"' },
  { code: 305621505, name: "UAB Baltic Perlite" },
  { code: 305621697, name: "Daugmeda, MB" },
  { code: 305621786, name: 'UAB "VMG Lignum construction"' },
  { code: 305621868, name: 'UAB "RamaZottius Lab"' },
  { code: 305622735, name: "IĮ Extruder" },
  { code: 305624782, name: "MB Stalas" },
  { code: 305625069, name: "MB Poufon" },
  { code: 305627629, name: 'UAB "Steel Deal"' },
  { code: 305628364, name: 'MB "EEE tentai"' },
  { code: 305630714, name: 'IĮ "Blindos medis"' },
  { code: 305631129, name: "T&M Furniture, MB" },
  { code: 305631727, name: 'UAB "M turtas"' },
  { code: 305632494, name: 'MB "Ganesha" baldai' },
  { code: 305632829, name: '"Linminta", MB' },
  { code: 305633607, name: "UAB Lautė" },
  { code: 305633945, name: "UAB Pramteka" },
  { code: 305634029, name: 'UAB "Balta klėtis"' },
  { code: 305634748, name: 'UAB "Jurestos prekyba"' },
  { code: 305635138, name: "MB Eivera" },
  { code: 305636133, name: "MB Saulėtekio elektrinės" },
  { code: 305636350, name: "Uždaroji akcinė bendrovė Baldeiva" },
  { code: 305636592, name: 'MB "MARKNORA"' },
  { code: 305638821, name: "Individuali įmonė Gamina" },
  { code: 305639019, name: 'UAB "Helira"' },
  { code: 305639688, name: 'MB "Montessori baldai"' },
  { code: 305640683, name: 'IĮ "Molini"' },
  { code: 305640950, name: "Monochromas, UAB" },
  { code: 305641123, name: 'UAB "Sip Land"' },
  { code: 305641269, name: "MB Jonvilda" },
  { code: 305641575, name: 'UAB "Kelmės konservai"' },
  { code: 305642606, name: "IĮ Skaldau malkas" },
  { code: 305642613, name: "MB Amlesta" },
  { code: 305643843, name: 'UAB "Rines"' },
  { code: 305643900, name: "MB Rugpjūčio studija" },
  { code: 305644297, name: "MB Slaptos dirbtuvės" },
  { code: 305644393, name: 'MB "Molinėja"' },
  { code: 305644735, name: 'MB "ArsMantus"' },
  { code: 305644856, name: "MB Ridomus LT" },
  { code: 305644984, name: 'MB "Chinchilla Market"' },
  { code: 305645488, name: 'UAB "GETANAS"' },
  { code: 305645627, name: 'UAB "Mindoplast"' },
  { code: 305646309, name: "IĮ Kaimo statyba" },
  { code: 305646323, name: "MB Gaslinda" },
  { code: 305646451, name: 'UAB "Jomėsa"' },
  { code: 305646647, name: "Provectus redivivus, UAB" },
  { code: 305646960, name: 'UAB "TimDam"' },
  { code: 305649999, name: 'UAB "Būsto ranga"' },
  { code: 305650275, name: "MB Tinybee" },
  { code: 305651377, name: "MB Medlinta" },
  { code: 305651459, name: 'MB "3 vektoriai"' },
  { code: 305651690, name: 'UAB "Light Forms"' },
  { code: 305652479, name: 'UAB "Baldužis"' },
  { code: 305653990, name: 'UAB "Baldų gamyba Tautiniai"' },
  { code: 305654366, name: 'UAB "Villacom"' },
  { code: 305654423, name: 'UAB "Sigmaris projects"' },
  { code: 305654779, name: 'MB "Impra plius"' },
  { code: 305655190, name: 'UAB "Relax čiužiniai"' },
  { code: 305655952, name: 'UAB "Pjausta"' },
  { code: 305656043, name: 'MB "Alsun"' },
  { code: 305657330, name: 'MB "Aivus"' },
  { code: 305658222, name: 'MB "Alexta"' },
  { code: 305658425, name: "MB Parfumaar" },
  { code: 305659121, name: "MB Solar energy inovation" },
  { code: 305659609, name: "MB Ateities energijos sprendimai" },
  { code: 305659872, name: 'MB "INNMARCO"' },
  { code: 305661097, name: 'MB "Ekoada"' },
  { code: 305661268, name: 'MB "Agroada"' },
  { code: 305661706, name: "UAB Romaton" },
  { code: 305662174, name: "UAB Smalla" },
  { code: 305662199, name: 'MB "Medinis kilimas"' },
  { code: 305663290, name: 'UAB "Monpeljė"' },
  { code: 305663867, name: "MB Streikų gaminiai" },
  { code: 305664353, name: "UAB Stiklometa" },
  { code: 305664428, name: "UAB Sukulentas" },
  { code: 305664677, name: 'UAB "HEAT TRANSFERS COMPANY"' },
  { code: 305664944, name: 'MB "Lemputis"' },
  { code: 305665277, name: "MB Hidrovyta" },
  { code: 305666895, name: "MB Gintagra" },
  { code: 305666977, name: "Plieno stilius MB" },
  { code: 305666984, name: 'MB "Comfort home"' },
  { code: 305667157, name: 'UAB "Stagva"' },
  { code: 305667716, name: "Mažoji bendrija Bildsol" },
  { code: 305668191, name: "UAB Solitek energy" },
  { code: 305668768, name: "MB Pačiulis" },
  { code: 305669902, name: "UAB Enrg projektas 4" },
  { code: 305669980, name: 'IĮ "Greenga"' },
  { code: 305670370, name: "UAB GG Biogas Investments" },
  { code: 305670388, name: "UAB GG Biogas PL" },
  { code: 305670406, name: "UAB Enrg projektas 2" },
  { code: 305670908, name: 'UAB "GG Dev Fin"' },
  { code: 305670961, name: "UAB GG LTU S11" },
  { code: 305671084, name: "UAB Pod Furniture" },
  { code: 305671903, name: "UAB Bamboo Forest Studio" },
  { code: 305672439, name: "G&E Furniture, UAB" },
  { code: 305672485, name: "MB Lafamilia" },
  { code: 305672827, name: "PBM BALTMARINE UAB" },
  { code: 305673181, name: 'UAB "Biodem LT"' },
  { code: 305674397, name: 'UAB "Kfp"' },
  { code: 305676658, name: "Mažoji bendrija Toro spalvos" },
  { code: 305676708, name: 'MB "Toto baldai"' },
  { code: 305676971, name: "MB Barva furniture" },
  { code: 305677016, name: "Oak Story MB" },
  { code: 305677468, name: "MB Joinery" },
  { code: 305677678, name: 'UAB "Kirpasaulis"' },
  { code: 305678253, name: "Hemprena, MB" },
  { code: 305678399, name: "MB ENODA" },
  { code: 305679775, name: 'MB "BA dirbtuvės"' },
  { code: 305679946, name: "UAB Kzk studija" },
  { code: 305681139, name: 'MB "Mdm projektai"' },
  { code: 305681438, name: 'MB "Baldų prizmė"' },
  { code: 305681865, name: 'UAB "Amber Home SPA"' },
  { code: 305681961, name: "MB Country Chef" },
  { code: 305683528, name: "MB Woodbenice" },
  { code: 305684306, name: "Brasta Glass, UAB" },
  { code: 305684352, name: 'MB "Elestra"' },
  { code: 305684562, name: "Mažoji bendrija Valdo projektai" },
  { code: 305685123, name: 'UAB "Aliteka"' },
  { code: 305687213, name: 'UAB "Ekonta"' },
  { code: 305687455, name: 'UAB "Traginta"' },
  { code: 305687722, name: "MB Molio galia" },
  { code: 305688728, name: 'UAB "Modularus"' },
  { code: 305688952, name: 'MB "Caroll timber"' },
  { code: 305689342, name: 'MB "Bioprojektai"' },
  { code: 305689417, name: 'MB "Pranciškaus malūnas"' },
  { code: 305690031, name: "Number 8 labs MB" },
  { code: 305691795, name: "MB Baldrama" },
  { code: 305693668, name: "Ilert Baltic UAB" },
  { code: 305696326, name: "UAB Pavilnio rūkykla" },
  { code: 305696340, name: 'MB "Asvejos baldai"' },
  { code: 305696397, name: "MB Valmoza" },
  { code: 305696568, name: "MB Baldkesta" },
  { code: 305697264, name: "UAB URBAN baldai" },
  { code: 305697474, name: 'MB "Pinigų lietus"' },
  { code: 305698900, name: "MB Geležiniai broliai" },
  { code: 305699233, name: "MB LK IDEAS" },
  { code: 305699404, name: 'MB "Furnimark"' },
  { code: 305701218, name: 'UAB "Gilės grindys"' },
  { code: 305701321, name: 'MB "ŽT furni"' },
  { code: 305701588, name: 'VšĮ "Saulėta keramika"' },
  { code: 305702416, name: "MB Medinis pro" },
  { code: 305702512, name: 'UAB "Engnessenis"' },
  { code: 305703870, name: 'MB "Andromedas"' },
  { code: 305704125, name: "MB Saiko projektai" },
  { code: 305704171, name: "MB Milvita baldai" },
  { code: 305705241, name: 'UAB "Elscada"' },
  { code: 305705280, name: 'MB "LeBark"' },
  { code: 305705914, name: 'UAB "Partnerystės projektai dešimt"' },
  { code: 305706044, name: "UAB Gaminiai LTU" },
  { code: 305706119, name: 'MB "Laimingi"' },
  { code: 305706603, name: "MB Re-lithium" },
  { code: 305706941, name: 'UAB "Fabricor"' },
  { code: 305707395, name: "Lankesos mediena, MB" },
  { code: 305707776, name: 'MB "Visi mūsų baldai"' },
  { code: 305708725, name: 'UAB "Vekor"' },
  { code: 305709250, name: 'UAB "Nordland house"' },
  { code: 305709738, name: 'MB "WOW baldai"' },
  { code: 305709930, name: 'MB "Steelray"' },
  { code: 305710391, name: 'MB "Dievyčio baldai"' },
  { code: 305710904, name: "PRESS GLASS UAB" },
  { code: 305711073, name: "MB Egidijaus laiptai" },
  { code: 305711447, name: "IĮ Wood Giedrius" },
  { code: 305711618, name: 'UAB "Eldern"' },
  { code: 305711867, name: "MB ZBIKE" },
  { code: 305713455, name: "MB Abuga" },
  { code: 305713804, name: 'IĮ "Raganius"' },
  { code: 305714393, name: "MB Wooden Style" },
  { code: 305714660, name: 'MB "Topgirda"' },
  { code: 305714984, name: "UAB Medis group" },
  { code: 305715207, name: 'MB "Algintas"' },
  { code: 305715463, name: 'UAB "Gera giria"' },
  { code: 305715470, name: 'MB "TLS Custom"' },
  { code: 305715627, name: 'MB "Medžio rankos"' },
  { code: 305716056, name: "UAB Insimeta" },
  { code: 305716106, name: 'MB "Konservai pas Vidą"' },
  { code: 305716113, name: "MB ECOBALTEC" },
  { code: 305716558, name: "UAB Molsolar" },
  { code: 305716615, name: 'MB "Žygstata"' },
  { code: 305716729, name: "MB Svajonės inžinerija" },
  { code: 305716921, name: "MB Ekogamyba" },
  { code: 305717425, name: "MB Stoginukas" },
  { code: 305718801, name: 'MB "Greidijus"' },
  { code: 305719369, name: "MB Gyppro" },
  { code: 305719718, name: 'MB "Chase innovation"' },
  { code: 305720108, name: "Danutela individuali įmonė" },
  { code: 305720161, name: 'UAB "Baltų dirbtuvės"' },
  { code: 305720243, name: "MB Bro korpusiniai baldai" },
  { code: 305720300, name: "UAB TermoWood LT" },
  { code: 305720624, name: "MB Meatus" },
  { code: 305720802, name: "UAB FURNITURE A" },
  { code: 305721352, name: 'UAB "Virteks"' },
  { code: 305721480, name: 'UAB "Termo Pluoštas"' },
  { code: 305721530, name: 'IĮ "Artūro BGL"' },
  { code: 305722059, name: "MB Minkšti sprendimai" },
  { code: 305722290, name: 'UAB "Jonux"' },
  { code: 305723182, name: "UAB LIGNUM FORMA factory" },
  { code: 305723524, name: "UAB Firlis" },
  { code: 305724448, name: 'UAB "Valnanera"' },
  { code: 305725087, name: 'UAB "Matek"' },
  { code: 305725151, name: "IĮ Šventinės dovanos LT" },
  { code: 305725525, name: "BALTMARINE SERVICES UAB" },
  { code: 305726221, name: "UAB Darom kitaip" },
  { code: 305726958, name: 'UAB "Sako Namai"' },
  { code: 305727031, name: 'MB "Dūmų katilas"' },
  { code: 305728051, name: 'MB "Gabūnas"' },
  { code: 305728756, name: 'UAB "VOLUI baldai"' },
  { code: 305729064, name: 'MB "MN baldai"' },
  { code: 305729299, name: "UAB Progips" },
  { code: 305729609, name: "MB Šiaulių Galvijai" },
  { code: 305729687, name: 'Individuali įmonė "Vajami"' },
  { code: 305731122, name: 'UAB "Rekodita"' },
  { code: 305731485, name: 'MB "Furnikit"' },
  { code: 305733625, name: 'UAB "Žuvita"' },
  { code: 305734061, name: "UAB Frezada" },
  { code: 305734403, name: "UAB Space idea" },
  { code: 305735074, name: "MB Metalo kodas" },
  { code: 305735170, name: 'MB "Magic art studio"' },
  { code: 305735754, name: 'UAB "Green Energy Solutions"' },
  { code: 305736678, name: 'MB "Ūžuolas"' },
  { code: 305736952, name: 'UAB "Mink feed"' },
  { code: 305737075, name: 'MB "Sauvitaka"' },
  { code: 305739215, name: "Outspirations MB" },
  { code: 305740694, name: 'UAB "Šetijų mediena"' },
  { code: 305741038, name: "JProject, UAB" },
  { code: 305741974, name: "MB Kvapų dizainas" },
  { code: 305742040, name: 'MB "Bagroma baldai"' },
  { code: 305742250, name: "UAB Laukrista" },
  { code: 305743135, name: 'UAB "BIO2LT"' },
  { code: 305743174, name: 'MB "Baldai222"' },
  { code: 305743644, name: 'IĮ "Di baldų surinkimas"' },
  { code: 305744536, name: 'UAB "Twin boats"' },
  { code: 305744739, name: 'UAB "Dovydo mediena"' },
  { code: 305744874, name: 'UAB "Mcwood"' },
  { code: 305745168, name: 'UAB "Statybų dailidės"' },
  { code: 305750838, name: "UAB Rasausta" },
  { code: 305755098, name: "UAB Sapelli" },
  { code: 305756175, name: 'MB "Poka group"' },
  { code: 305757875, name: 'UAB "Venka"' },
  { code: 305758856, name: "MB Agmila" },
  { code: 305758906, name: 'UAB "Dgflt"' },
  { code: 305758977, name: 'UAB "Megrana fasadai"' },
  { code: 305759004, name: 'IĮ "Durita"' },
  { code: 305762648, name: 'MB "Aqua regia"' },
  { code: 305765167, name: 'UAB "Limelit"' },
  { code: 305766917, name: 'UAB "Rest modules"' },
  { code: 305767677, name: 'Uždaroji akcinė bendrovė "Kailena.LT"' },
  { code: 305769169, name: 'UAB "Dijokiukas"' },
  { code: 305769653, name: "MB Unicus duo" },
  { code: 305769774, name: "UAB Vikto baldai" },
  { code: 305770132, name: 'UAB "Naturus"' },
  { code: 305771184, name: 'UAB "Rupėja"' },
  { code: 305771412, name: 'UAB "GES vėjo elektrinės"' },
  { code: 305771469, name: "UAB Salgna" },
  { code: 305772083, name: 'MB "Happy Smile go"' },
  { code: 305773993, name: "MB Timberin LK industries" },
  { code: 305775446, name: "Edmundo Ražansko IĮ" },
  { code: 305776402, name: 'MB "Aberika"' },
  { code: 305778798, name: 'UAB "Arredo deko projektai"' },
  { code: 305779430, name: "WOODCITY. LT, UAB" },
  { code: 305779793, name: 'UAB "Paperstep"' },
  { code: 305780016, name: "MB Furnega" },
  { code: 305780774, name: "UAB Vėjo linija 1" },
  { code: 305781680, name: "UAB Vėjo linija 2" },
  { code: 305785091, name: '"Gyvas kraštas", IĮ' },
  { code: 305785700, name: "UAB Vėjo galia" },
  { code: 305787701, name: 'MB "Juodoji pelėda"' },
  { code: 305790708, name: "MB Inside matters" },
  { code: 305793106, name: "UAB Dl baldai" },
  { code: 305795541, name: "JASA, UAB" },
  { code: 305795580, name: 'Mažoji bendrija "Medžių masyvas"' },
  { code: 305796173, name: 'UAB "Ryškios spalvos"' },
  { code: 305799244, name: "UAB Rapis" },
  { code: 305800611, name: "GraVik MDF Painting, MB" },
  { code: 305801179, name: 'MB "Auraforte"' },
  { code: 305802402, name: 'MB "Meistarnė"' },
  { code: 305802441, name: 'UAB "Amber ship"' },
  { code: 305804588, name: 'MB "Furnman"' },
  { code: 305807171, name: 'UAB "Wilbergs Service"' },
  { code: 305807260, name: "MB Aldeiso" },
  { code: 305807723, name: "MB RK Renewables" },
  { code: 305808152, name: 'UAB "Sprinteksas Baltic"' },
  { code: 305809286, name: "MB Wooden Mouse" },
  { code: 305812923, name: 'UAB "Anykščiai PV"' },
  { code: 305815613, name: 'UAB "Boxus"' },
  { code: 305818132, name: 'MB "Savarena"' },
  { code: 305818335, name: "MB Macda" },
  { code: 305819800, name: 'MB "Džerkis"' },
  { code: 305822305, name: "UAB Thorbek" },
  { code: 305825251, name: "MB Mobilus karkasas" },
  { code: 305826321, name: "MB Vojerdi" },
  { code: 305827117, name: 'MB "Titan LT"' },
  { code: 305831507, name: "MB Trimatis sprendimas" },
  { code: 305831539, name: 'Mažoji bendrija "Obrimus"' },
  { code: 305833180, name: 'MB "Majonetic woodwork"' },
  { code: 305833789, name: 'UAB "Dandaza"' },
  { code: 305834204, name: 'MB "Medžio takas"' },
  { code: 305836187, name: 'UAB "Rapsodijus"' },
  { code: 305838715, name: "UAB Lukšių investicijos" },
  { code: 305839094, name: 'UAB "Marga paletė"' },
  { code: 305839169, name: 'UAB "Boliodesign LT"' },
  { code: 305839258, name: 'UAB "Ateities forma"' },
  { code: 305842254, name: 'UAB "Nerteva"' },
  { code: 305844294, name: "MB Amber ceramics studio" },
  { code: 305844611, name: "MB Tovera" },
  { code: 305848620, name: 'MB "Užsakomieji baldai"' },
  { code: 305849633, name: 'UAB "Maviga Pro"' },
  { code: 305849843, name: 'MB "Id bravo"' },
  { code: 305850450, name: 'UAB "Tectio"' },
  { code: 305856115, name: 'UAB "Alrosa"' },
  { code: 305856122, name: "UAB Medienos partneris" },
  { code: 305857562, name: "MB Akvariumų projektai" },
  { code: 305860074, name: 'UAB "Mortyra"' },
  { code: 305861639, name: 'MB "Igdrasilas"' },
  { code: 305863597, name: "UAB ASP INVEST" },
  { code: 305864197, name: "Uždaroji akcinė bendrovė Sugiban wood" },
  { code: 305874227, name: "MB Madamedi" },
  { code: 305874266, name: 'UAB "Segara"' },
  { code: 305876580, name: 'MB "Darau 2"' },
  { code: 305879174, name: 'MB "Stanių mėsinė"' },
  { code: 305879669, name: '"Bijūnėlių energija", UAB' },
  { code: 305885693, name: 'UAB "WireTech"' },
  { code: 305886724, name: 'UAB "Baldelina"' },
  { code: 305888127, name: 'MB "Compact house"' },
  { code: 305888209, name: "MB Timoni" },
  { code: 305888287, name: "UAB AM grupė" },
  { code: 305888369, name: "Linmedis UAB" },
  { code: 305888903, name: 'UAB "Kantuva"' },
  { code: 305892613, name: 'MB "Vox ferro"' },
  { code: 305894347, name: 'MB "Timberrest"' },
  { code: 305894817, name: 'UAB "Anykščiai Renew"' },
  { code: 305900298, name: "MB Korar" },
  { code: 305901635, name: "MB Doverus" },
  { code: 305903351, name: 'UAB "Betono projektai"' },
  { code: 305903458, name: '"Jurami" UAB' },
  { code: 305904916, name: 'MB "RasaBal"' },
  { code: 305906657, name: "MB Cozylight" },
  { code: 305906739, name: 'MB "Techninis"' },
  { code: 305906792, name: "MB Narmoni" },
  { code: 305906835, name: 'UAB "TSPI"' },
  { code: 305906956, name: "MTEAM UAB" },
  { code: 305907271, name: 'UAB "Šaltūnų namai"' },
  { code: 305907798, name: "UAB Mėlynasis ąžuolas" },
  { code: 305909589, name: 'IĮ "EMAPORT"' },
  { code: 305909767, name: 'MB "Bright Solutions"' },
  { code: 305910641, name: 'MB "Vario virvės"' },
  { code: 305910723, name: "ŽŪB Vaškai biomethane" },
  { code: 305911608, name: 'UAB "Noble Wood House"' },
  { code: 305912126, name: "MB Grubuva" },
  { code: 305912813, name: 'MB "Comfortus"' },
  { code: 305913281, name: 'UAB "Start EU"' },
  { code: 305913872, name: 'UAB "Destructa"' },
  { code: 305915097, name: "UAB TECHMARINE" },
  { code: 305916338, name: 'MB "Baldų tvirtovė"' },
  { code: 305917212, name: "MB Plasnora" },
  { code: 305918435, name: 'UAB "Marūko mėsa"' },
  { code: 305918798, name: "UAB Baldaim" },
  { code: 305919195, name: 'MB "Liuanis"' },
  { code: 305919302, name: 'UAB "FABRICA VIRIDIS"' },
  { code: 305920297, name: "UAB Do timber" },
  { code: 305921125, name: 'UAB "Arsetas"' },
  { code: 305922362, name: "MB Jūsų konkurentas" },
  { code: 305922405, name: "MB Bergo baldai" },
  { code: 305924356, name: 'MB "Gravila"' },
  { code: 305925006, name: "MB ER baldai" },
  { code: 305926695, name: "UAB Baketas" },
  { code: 305929047, name: 'MB "Lunar baits"' },
  { code: 305929264, name: "UAB Aroma line" },
  { code: 305931870, name: "UAB Fish factory" },
  { code: 305934293, name: 'UAB "BHG Wood Production"' },
  { code: 305934329, name: 'MB "Vedega"' },
  { code: 305934350, name: 'UAB "SOLAR P6"' },
  { code: 305935203, name: 'MB "Dvylika keturiolika"' },
  { code: 305935228, name: 'Mažoji bendrija "Šernosis"' },
  { code: 305936426, name: 'MB "Glass-inov"' },
  { code: 305936508, name: 'MB "Idėja medžiui"' },
  { code: 305939194, name: "MB Room Top" },
  { code: 305939301, name: "MB Furnidėdės" },
  { code: 305939582, name: 'MB "Riqueza Home"' },
  { code: 305940563, name: "Brasta Pack, UAB" },
  { code: 305940652, name: 'UAB "Skyline LT"' },
  { code: 305940691, name: "MB Baldastika" },
  { code: 305942607, name: "UAB Litelektra" },
  { code: 305943043, name: 'UAB "Virtusas"' },
  { code: 305943381, name: 'MB "Balgirda"' },
  { code: 305943449, name: "UAB Baltas fasadas" },
  { code: 305944152, name: 'UAB "Spraytera"' },
  { code: 305944227, name: "UAB TMG sports vehicles" },
  { code: 305944743, name: 'UAB "VRC Baltic"' },
  { code: 305945585, name: "UAB ETT Furniture" },
  { code: 305946783, name: "UAB Industrinė pakuotė" },
  { code: 305946801, name: 'MB "Juodaičio baldai"' },
  { code: 305949950, name: 'UAB "Spintų sistemos"' },
  { code: 305950205, name: 'UAB "3D Gamyba"' },
  { code: 305950326, name: 'UAB "Trinkelė"' },
  { code: 305950479, name: "UAB Skonio iššūkis" },
  { code: 305954374, name: "UAB Endim" },
  { code: 305954488, name: 'MB "Diseko"' },
  { code: 305954513, name: 'UAB "Diveriks composite solutions"' },
  { code: 305954716, name: "UAB Enepaq" },
  { code: 305958636, name: "MB Dalitema" },
  { code: 305959656, name: 'MB "Mapa baldai"' },
  { code: 305959713, name: 'MB "Brikesta"' },
  { code: 305961116, name: "UAB South power" },
  { code: 305961707, name: "UAB Žalia žemė" },
  { code: 305961874, name: 'MB "Pasmeta LT"' },
  { code: 305964219, name: "MDAspect UAB" },
  { code: 305965157, name: "Sunelectro UAB" },
  { code: 305965381, name: 'MB "Baldturas"' },
  { code: 305968751, name: 'MB "Rb production"' },
  { code: 305969223, name: 'UAB "Mes service LT"' },
  { code: 305969789, name: "MB MM studija" },
  { code: 305970624, name: 'MB "Baldų surinkėjas"' },
  { code: 305971740, name: 'MB "Kokybiškos pirtys"' },
  { code: 305971808, name: "MB Balderija" },
  { code: 305972116, name: 'UAB "Kami timber"' },
  { code: 305972618, name: 'MB "Mariaus prekyba"' },
  { code: 305972874, name: 'UAB "Parmarine"' },
  { code: 305972988, name: 'UAB "GRUNWALD TRAILERS"' },
  { code: 305974284, name: 'UAB "Žalgirio baldai"' },
  { code: 305976303, name: 'UAB "Fasado pasaulis"' },
  { code: 305976659, name: 'UAB "SB Terminal"' },
  { code: 305977939, name: 'MB "Baltas ąžuolas"' },
  { code: 305978902, name: 'MB "4 coliai"' },
  { code: 305979783, name: "UAB Mejuma" },
  { code: 305981713, name: 'MB "Urtbalda"' },
  { code: 305982733, name: "MB Inflamed" },
  { code: 305985028, name: "UAB Juro projektai" },
  { code: 305985035, name: "MB Alfredo mėsos gaminiai" },
  { code: 305988622, name: "UAB Biologico" },
  { code: 305990954, name: 'MB "Dailas"' },
  { code: 305991312, name: "Karaliaus baldai, UAB" },
  { code: 305991885, name: 'MB "Theamely"' },
  { code: 305991974, name: "MB So glad" },
  { code: 305992186, name: 'UAB "Kismas"' },
  { code: 305993092, name: "Pon Bike Lithuania UAB" },
  { code: 305993644, name: "JH paslaugos MB" },
  { code: 305994251, name: 'MB "Papartynų mediena"' },
  { code: 305997169, name: 'UAB "Terra management"' },
  { code: 305999227, name: 'MB "Moskos"' },
  { code: 306001329, name: 'MB "PP transservis"' },
  { code: 306001432, name: "Ežiukų dirbtuvės, MB" },
  { code: 306004713, name: 'UAB "Wind arch"' },
  { code: 306006205, name: 'UAB "Skuodas renew"' },
  { code: 306006618, name: 'UAB "Spark energy Eko"' },
  { code: 306007086, name: "UAB Garnitūras" },
  { code: 306007136, name: 'UAB "Finejob"' },
  { code: 306008829, name: "IG Production, MB" },
  { code: 306008875, name: 'UAB "PBS LT"' },
  { code: 306010659, name: "UAB Aivintra" },
  { code: 306014732, name: "UAB Betonix" },
  { code: 306014771, name: "MB Puošni pavėsinė" },
  { code: 306016701, name: "MB Mariaus baldų studija" },
  { code: 306016772, name: 'MB "Nadrus"' },
  { code: 306018524, name: "MB Algirdas stalius dailidė" },
  { code: 306018734, name: 'UAB "Tenter"' },
  { code: 306019708, name: "VšĮ Šukė" },
  { code: 306020112, name: "UAB Energreen solutions" },
  { code: 306021189, name: 'UAB "Luxury furniture"' },
  { code: 306021246, name: 'MB "Jasiukaičių ūkis"' },
  { code: 306021292, name: 'MB "ArtsWood"' },
  { code: 306021730, name: "UAB Kauno skalda" },
  { code: 306023756, name: "Mažoji bendrija Ąžuolo gama" },
  { code: 306026720, name: "UAB Novalda stiklai" },
  { code: 306028080, name: 'UAB "Profi baldai"' },
  { code: 306028778, name: 'MB "Montarna"' },
  { code: 306032196, name: 'UAB "Green Power Group"' },
  { code: 306039109, name: "UAB MI saulės elektrinė 1" },
  { code: 306041747, name: 'MB "Ledutis"' },
  { code: 306041829, name: 'MB "Litgana"' },
  { code: 306042176, name: 'UAB "Baltic Boat Service"' },
  { code: 306042653, name: 'MB "Gordenos baldai"' },
  { code: 306044387, name: 'MB "Gutts"' },
  { code: 306045293, name: "UAB PV Energy 200" },
  { code: 306046737, name: 'ŽŪB "BROLIŲ AGRO"' },
  { code: 306047230, name: 'MB "Baldinta"' },
  { code: 306047910, name: 'MB "Fill illusion"' },
  { code: 306048019, name: 'UAB "ALANTOS STATYBINĖ MEDIENA"' },
  { code: 306050162, name: "UAB RM furniture" },
  { code: 306050244, name: 'UAB "Žvėriena LT"' },
  { code: 306051175, name: "C-serv UAB" },
  { code: 306052131, name: 'UAB "Solargen"' },
  { code: 306052373, name: "Sunly Solar, UAB" },
  { code: 306052380, name: 'UAB "Teraparkas"' },
  { code: 306052583, name: 'UAB "Nova projektai"' },
  { code: 306053571, name: 'MB "Betsalel"' },
  { code: 306056126, name: 'UAB "Get green LT"' },
  { code: 306056158, name: "Heidelberg Mobile UAB" },
  { code: 306057993, name: "UAB Energijos infrastruktūra" },
  { code: 306058248, name: 'MB "Atspalvis"' },
  { code: 306058515, name: "UAB FREDA IV" },
  { code: 306059670, name: 'UAB "Agroekologijos žalioji energija"' },
  { code: 306059898, name: 'UAB "Topival"' },
  { code: 306062182, name: "MB Wood Woo" },
  { code: 306063398, name: 'MB "Igma baldai"' },
  { code: 306064258, name: "UAB Baltijos langai" },
  { code: 306064450, name: "Green circle LT, UAB" },
  { code: 306066864, name: 'MB "Ąžuolai žaliuos"' },
  { code: 306066896, name: 'UAB "Nordemis"' },
  { code: 306067471, name: 'UAB "Frezera"' },
  { code: 306068922, name: 'UAB "Skuodas wind"' },
  { code: 306069344, name: "MB Baldukas" },
  { code: 306069853, name: 'MB "Re baldai"' },
  { code: 306069999, name: 'UAB "GG LTU S16"' },
  { code: 306070001, name: 'UAB "GG LTU S20"' },
  { code: 306070841, name: 'UAB "GG LTU S25"' },
  { code: 306070859, name: 'UAB "GG LTU S22"' },
  { code: 306071053, name: "MB VDM BALDŲ GAMYBA" },
  { code: 306071092, name: 'UAB "GG LTU S15"' },
  { code: 306071174, name: 'UAB "GG LTU S24"' },
  { code: 306071231, name: "UAB Forgedot service" },
  { code: 306071306, name: "Mažoji bendrija Belghard" },
  { code: 306072130, name: "MB AndVal" },
  { code: 306072714, name: 'MB "Baldvitra"' },
  { code: 306073855, name: 'UAB "Agrokoncerno biometanas"' },
  { code: 306074067, name: 'UAB "ABELT"' },
  { code: 306074665, name: "UAB 7 parkai" },
  { code: 306074836, name: 'UAB "Akmedizainas"' },
  { code: 306075169, name: "MB Estabula" },
  { code: 306077241, name: 'MB "Fortis project"' },
  { code: 306077782, name: 'UAB "Listeda"' },
  { code: 306078756, name: "MB Photovoltaic power systems" },
  { code: 306079908, name: "MB Custom things" },
  { code: 306080604, name: "MB Finalaw" },
  { code: 306080629, name: "UAB BaltNera" },
  { code: 306080892, name: 'MB "Asauna"' },
  { code: 306082181, name: "MB MHWP" },
  { code: 306082288, name: "MB Stiklo" },
  { code: 306082726, name: 'UAB "Baltijos langų grupė"' },
  { code: 306083447, name: 'MB "Kretingos betonas"' },
  { code: 306083828, name: 'UAB "GM ART"' },
  { code: 306084606, name: 'MB "Ramosi"' },
  { code: 306084823, name: "UAB KLAMETA PRODUCTION" },
  { code: 306084944, name: 'UAB "Nord Wind Park"' },
  { code: 306085697, name: 'UAB "Lightek"' },
  { code: 306091091, name: "MB Juodas flamingas" },
  { code: 306092738, name: 'MB "Gateriukas"' },
  { code: 306094582, name: "Driu Solutions MB" },
  { code: 306094835, name: "MB Vaivadų mediena" },
  { code: 306094899, name: '"Maler",UAB' },
  { code: 306095232, name: "MB Digris" },
  { code: 306095474, name: "Sunwind Energy UAB" },
  { code: 306095741, name: "MB SDLine" },
  { code: 306096035, name: 'UAB "Baldų studija Besta"' },
  { code: 306096099, name: "MB ELOI LT" },
  { code: 306097023, name: "UAB House concept" },
  { code: 306097265, name: 'UAB "Oss basis"' },
  { code: 306098157, name: "Alt Energy, UAB" },
  { code: 306099939, name: 'MB "Medienos fanai"' },
  { code: 306101087, name: 'MB "Artdesigns"' },
  { code: 306101991, name: 'UAB "Industrinių resursų sprendimai"' },
  { code: 306104172, name: 'MB "Ls gamyba"' },
  { code: 306105107, name: "MB ARMUS GROUP" },
  { code: 306105235, name: "MB Mejona" },
  { code: 306105573, name: "MB Baltbaldai" },
  { code: 306106038, name: "UAB Armalitas" },
  { code: 306107193, name: 'UAB "GG LTU S28"' },
  { code: 306107204, name: 'UAB "GG LTU S29"' },
  { code: 306107229, name: 'UAB "Swim & chill"' },
  { code: 306107777, name: 'UAB "GG LTU S30"' },
  { code: 306107802, name: 'UAB "GG LTU S32"' },
  { code: 306107948, name: 'UAB "GG LTU S31"' },
  { code: 306108320, name: "MB K&K Solutions" },
  { code: 306108509, name: "Argemas MB" },
  { code: 306108612, name: "Oakylux MB" },
  { code: 306109116, name: 'UAB "Bro Timber Trans"' },
  { code: 306109618, name: 'MB "Packaging solutions LT"' },
  { code: 306110047, name: "MB BALDRENA" },
  { code: 306110264, name: 'UAB "GG LTU S26"' },
  { code: 306111238, name: 'UAB "GG LTU S34"' },
  { code: 306111291, name: 'UAB "GG LTU S33"' },
  { code: 306111341, name: 'UAB "GG LTU S35"' },
  { code: 306111779, name: 'UAB "GG LTU S27"' },
  { code: 306112112, name: "MB Eltana" },
  { code: 306112977, name: 'UAB "GG LTU V4"' },
  { code: 306114483, name: "MB Eco Flameed" },
  { code: 306114526, name: 'UAB "GG LTU V2"' },
  { code: 306114914, name: 'UAB "GG LTU V3"' },
  { code: 306115083, name: 'UAB "GG LTU V5"' },
  { code: 306115165, name: 'UAB "GG LTU V1"' },
  { code: 306115560, name: 'UAB "Bioga"' },
  { code: 306115667, name: "ŽŪB Zvanagala biomethane" },
  { code: 306116228, name: "Edva racing MB" },
  { code: 306116662, name: "UAB Navalteq" },
  { code: 306117508, name: 'UAB "GG LTU S39"' },
  { code: 306117725, name: "Snoltherm, UAB" },
  { code: 306117846, name: 'UAB "GG LTU S38"' },
  { code: 306117903, name: 'UAB "GG LTU S40"' },
  { code: 306117928, name: 'UAB "GG LTU S36"' },
  { code: 306117935, name: 'UAB "GG LTU S37"' },
  { code: 306117999, name: 'UAB "SDR investicija"' },
  { code: 306118962, name: "MB ElViGa" },
  { code: 306119128, name: "MB Kazbaro baldai" },
  { code: 306119466, name: 'UAB "Meškalaukio energija"' },
  { code: 306119822, name: 'UAB "Agrokoncerno žalioji energija"' },
  { code: 306120034, name: "UAB Frezoma" },
  { code: 306121759, name: "UAB Molinė siena" },
  { code: 306123614, name: "MB Igro baldai" },
  { code: 306124246, name: 'UAB "GG LTU V10"' },
  { code: 306124965, name: 'UAB "GG LTU V9"' },
  { code: 306125063, name: 'MB "45 kampai"' },
  { code: 306125309, name: 'UAB "GG LTU V6"' },
  { code: 306125362, name: 'UAB "GG LTU V7"' },
  { code: 306125483, name: 'UAB "GG LTU V8"' },
  { code: 306126012, name: "MB Akmens forma" },
  { code: 306127573, name: 'UAB "Renekona"' },
  { code: 306127790, name: 'UAB "Bostmar"' },
  { code: 306128732, name: 'MB "Echomedis"' },
  { code: 306128789, name: "Eurofenas, UAB" },
  { code: 306129268, name: 'MB "Beluka"' },
  { code: 306129631, name: "Ligitos Laucytės IĮ" },
  { code: 306132360, name: "MB Adorahome" },
  { code: 306132894, name: "MB Alteritas" },
  { code: 306133238, name: "UAB Scipio Labaratorija" },
  { code: 306133626, name: 'MB "Klemiškės medinukas"' },
  { code: 306134906, name: "UAB Kraut" },
  { code: 306136191, name: "UAB BAT Recycling" },
  { code: 306136743, name: "MB Stka service" },
  { code: 306137628, name: "MB Inr projektai" },
  { code: 306137724, name: 'MB "Print me"' },
  { code: 306138623, name: 'MB "Biofulas"' },
  { code: 306138922, name: "MB Baldo kodas" },
  { code: 306139419, name: 'UAB "Sunly Offshore Wind"' },
  { code: 306140250, name: 'UAB "Litguma"' },
  { code: 306141174, name: "MB Sol Ventus Energy" },
  { code: 306141313, name: 'UAB "Laivrida"' },
  { code: 306141466, name: 'UAB "Asnersa"' },
  { code: 306142529, name: 'MB "Hot tub relax"' },
  { code: 306142988, name: "Baldžiaus namai UAB" },
  { code: 306143684, name: 'UAB "Kertupio saulė"' },
  { code: 306145347, name: "UAB Woodcycle" },
  { code: 306145621, name: 'ŽŪB "Ekoternum"' },
  { code: 306145692, name: 'UAB "GG LTU S41"' },
  { code: 306146424, name: 'MB "Melamin"' },
  { code: 306147227, name: "MB Unga home" },
  { code: 306147711, name: 'UAB "Ignitis renewables projektai 3"' },
  { code: 306147729, name: 'UAB "Ignitis renewables projektai 2"' },
  { code: 306147857, name: 'UAB "Rhino Automotive"' },
  { code: 306148279, name: "UAB TVTech" },
  { code: 306148813, name: 'MB "Domus Homini"' },
  { code: 306150287, name: 'UAB "MŽ baldai"' },
  { code: 306153671, name: "MB Turgaus aliejus" },
  { code: 306153721, name: "Fiberlita MB" },
  { code: 306154848, name: 'MB "SEASTEEL"' },
  { code: 306155206, name: "MB Furnifit" },
  { code: 306155583, name: 'UAB "NORD SOLAR LIETUVA"' },
  { code: 306155793, name: "MB Baldaiidea" },
  { code: 306155875, name: "UAB LuViTo" },
  { code: 306156322, name: 'MB "1D AGENCY"' },
  {
    code: 306158800,
    name: "Continental Autonomous Mobility Lithuania UAB"
  },
  { code: 306161860, name: 'MB "Minkštas ir minkštesnis"' },
  { code: 306162439, name: "MB Deluxus LT" },
  { code: 306163014, name: "IĮ JocMeda" },
  { code: 306163651, name: 'UAB "Vėjo galia bendruomenei"' },
  { code: 306167137, name: "MB Esteto baldai" },
  { code: 306167347, name: "UAB H2 LT" },
  { code: 306168253, name: 'UAB "Dabis"' },
  { code: 306171580, name: "MB Invora" },
  { code: 306174612, name: "MB Dynomeka" },
  { code: 306174644, name: 'UAB "Furniture Punks"' },
  { code: 306175479, name: "MB Dopamino keramika" },
  { code: 306175493, name: "Neoninis, MB" },
  { code: 306177188, name: 'UAB "Pabradės betonas"' },
  { code: 306179812, name: "MB Komforto baldai" },
  { code: 306180120, name: "MB Kamuto" },
  { code: 306181539, name: 'MB "Cultus LT"' },
  { code: 306185128, name: 'MB "RB Baldai"' },
  { code: 306185797, name: 'UAB "Plsolar"' },
  { code: 306185808, name: 'UAB "GG LTU S43"' },
  { code: 306185879, name: 'UAB "GG LTU S45"' },
  { code: 306185950, name: 'UAB "Nord energija"' },
  { code: 306186237, name: 'UAB "GG LTU S42"' },
  { code: 306186340, name: 'UAB "GG LTU S46"' },
  { code: 306186582, name: 'UAB "GG LTU S44"' },
  { code: 306191095, name: "UAB Flyt Interiorline" },
  { code: 306191978, name: "MB RIJA PROJECTS STUDIO" },
  { code: 306192681, name: "Pro-Flex Slibeborster LT, MB" },
  { code: 306197753, name: 'UAB "Pultana"' },
  { code: 306199900, name: 'UAB "GG LTU V12"' },
  { code: 306200758, name: "Monomera MB" },
  { code: 306200765, name: 'UAB "GG LTU V14"' },
  { code: 306200847, name: 'UAB "GG LTU V15"' },
  { code: 306200854, name: 'UAB "GG LTU V16"' },
  { code: 306200861, name: '"Vėjas vienas", UAB' },
  { code: 306200936, name: "RWE Renewables Lithuania, UAB" },
  { code: 306201155, name: "UAB Abakai" },
  { code: 306201187, name: 'UAB "GG LTU V13"' },
  { code: 306201244, name: 'UAB "GG LTU V18"' },
  { code: 306201618, name: 'UAB "GG LTU V17"' },
  { code: 306201625, name: 'UAB "GG LTU V11"' },
  { code: 306201632, name: "RWE Wind Services Lithuania, UAB" },
  { code: 306202823, name: 'MB "Madlab LT"' },
  { code: 306204361, name: 'MB "Maxas marine"' },
  { code: 306208858, name: 'UAB "Baldų mada"' },
  { code: 306217540, name: 'UAB "Biomass Energy Solutions"' },
  { code: 306219203, name: 'MB "STI wood house"' },
  { code: 306219413, name: 'MB "Dinaja"' },
  { code: 306220305, name: "UAB Lt 370" },
  { code: 306221097, name: "MB Composite solutions Lithuania" },
  { code: 306221841, name: "MB Vajogold" },
  { code: 306221873, name: 'MB "Lankstūs baldai"' },
  { code: 306221898, name: "UAB PP Wind Energy" },
  { code: 306222224, name: "MB Degirfo" },
  { code: 306223269, name: "MB Motociklų dizaino studija" },
  { code: 306225882, name: 'UAB "Ad baldai"' },
  { code: 306229329, name: "UAB Ostaralab" },
  { code: 306229432, name: 'UAB "Diamodus"' },
  { code: 306230107, name: "MB Augada" },
  { code: 306230712, name: "UAB Medis pro" },
  { code: 306231134, name: "Elektrėnų šiluma, UAB" },
  { code: 306231643, name: 'Mažoji bendrija "GDM furniture"' },
  { code: 306232108, name: "Medžio turas, MB" },
  { code: 306234194, name: "UAB A5 ECOENERGY" },
  { code: 306235175, name: "MB Marbalda" },
  { code: 306235410, name: 'UAB "Smal LT"' },
  { code: 306235563, name: "MB Naurio baldai" },
  { code: 306235759, name: 'MB "AK FREZAVIMAS"' },
  { code: 306236409, name: 'MB "Virtnora"' },
  { code: 306236665, name: "UAB SigMa house" },
  { code: 306237009, name: 'MB "Juodalksnio dūmas"' },
  { code: 306242472, name: "MB Wood magic" },
  { code: 306246734, name: '"Vėjas du", UAB' },
  { code: 306246983, name: 'UAB "Glasspic"' },
  { code: 306248105, name: "MB Sustainable house" },
  { code: 306249132, name: "UAB 28 Global" },
  { code: 306253604, name: 'UAB "Intermarkets"' },
  { code: 306253668, name: 'UAB "GG LTU S52"' },
  { code: 306253732, name: 'UAB "GG LTU S56"' },
  { code: 306253764, name: 'UAB "GG LTU S53"' },
  { code: 306253807, name: 'UAB "GG LTU S50"' },
  { code: 306253814, name: 'UAB "GG LTU S55"' },
  { code: 306254097, name: "MB 8 interjerai" },
  { code: 306254108, name: 'UAB "Siar baldai"' },
  { code: 306254122, name: 'UAB "GG LTU S47"' },
  { code: 306254236, name: 'UAB "GG LTU S49"' },
  { code: 306254318, name: 'UAB "GG LTU S51"' },
  { code: 306254357, name: 'UAB "GG LTU S54"' },
  { code: 306255085, name: 'UAB "GG LTU S48"' },
  { code: 306256269, name: "UAB Baldų erdvė" },
  { code: 306260303, name: "MB Valdrena" },
  { code: 306267187, name: 'MB "Gintauto baldų gamyba"' },
  { code: 306267237, name: "UAB Ardelux" },
  { code: 306267486, name: "Unlimited power, UAB" },
  { code: 306268620, name: "UAB Fivrec" },
  { code: 306268894, name: 'MB "Roborob"' },
  { code: 306269188, name: 'UAB "Gilės baldai"' },
  { code: 306269291, name: "Enery Lithuania UAB" },
  { code: 306269786, name: 'UAB "Lietas akmuo"' },
  { code: 306269964, name: "MB Kaskadas" },
  { code: 306271050, name: "UAB Earth 1" },
  { code: 306272914, name: "MB Master marine service" },
  { code: 306273471, name: "UAB MY QUB" },
  { code: 306275045, name: 'MB "Botany Room"' },
  { code: 306276581, name: 'MB "Medienos galia"' },
  { code: 306279428, name: "ATvizija, MB" },
  { code: 306282207, name: "MB Heat shock protein" },
  { code: 306288982, name: 'UAB "SAKTAGAN"' },
  { code: 306289155, name: 'MB "Medžio spalvos"' },
  { code: 306291302, name: "Uždaroji akcinė bendrovė Lodge LT" },
  { code: 306295998, name: "MB MadeKuci Art" },
  { code: 306296694, name: "UAB Saulės šviesos elektrinė" },
  { code: 306302275, name: 'MB "Brandukas"' },
  { code: 110846971, name: "UAB PAINA IR KO" },
  { code: 133753017, name: 'UAB "Alsena"' },
  { code: 153009143, name: 'Akcinė bendrovė "Akmenės cementas"' },
  { code: 244670310, name: 'UAB "TOKSIKA"' },
  { code: 301276531, name: "UAB Gren Klaipėda" },
  { code: 302620960, name: 'UAB "Arunta ir Ko"' },
  { code: 302881024, name: 'UAB "SIGMA SOLAR"' },
  { code: 303782367, name: "UAB Vilniaus kogeneracinė jėgainė" },
  { code: 303792888, name: "UAB Kauno kogeneracinė jėgainė" },
  { code: 304178964, name: "AB Palemono keramikos gamykla" },
  { code: 145787276, name: "Všį ŠIAULIŲ REGIONO ATLIEKŲ TVARKYMO CENTRAS" },
  {
    code: 151479265,
    name: "UAB Marijampolės apskrities atliekų tvarkymo centras"
  },
  {
    code: 163743744,
    name: "UAB Klaipėdos regiono atliekų tvarkymo centras"
  },
  {
    code: 171780190,
    name: 'UAB "Telšių regiono atliekų tvarkymo centras"'
  },
  {
    code: 179901854,
    name: "UAB Tauragės regiono atliekų tvarkymo centras"
  },
  { code: 181705485, name: "UAB „VAATC”" },
  { code: 250135860, name: "UAB Alytaus regiono atliekų tvarkymo centras" },
  {
    code: 300083878,
    name: 'UAB "Utenos regiono atliekų tvarkymo centras"'
  },
  { code: 300092998, name: "VšĮ „Kauno regiono atliekų tvarkymo centras“" },
  {
    code: 300031842,
    name: 'Uždarosios akcinės bendrovės "Toksika" Šiaulių filialas'
  },
  { code: 125722762, name: "UAB VILNIAUS BETONO DEMONTAVIMO TECHNIKA" },
  { code: 181522014, name: 'UAB "TRAKŲ PASLAUGOS"' },
  {
    code: 300127004,
    name: "UAB Panevėžio regiono atliekų tvarkymo centras"
  },
  { code: 302723177, name: 'UAB "Skansk Byggservice"' },
  { code: 126199731, name: 'UAB "Grigeo Baltwood"' },
  { code: 154742821, name: 'UAB "Agaras"' },
  { code: 158834726, name: 'UAB "Kaišiadorių vandenys"' },
  { code: 158891218, name: "Akcinė bendrovė Kaišiadorių paukštynas" },
  {
    code: 173045168,
    name: 'Akcinės bendrovės "Vilniaus degtinė" filialas OBELIŲ SPIRITO VARYKLA'
  },
  { code: 177356654, name: 'Ūkininkų kooperatinė bendrovė "Šilgaliai"' },
  { code: 302290758, name: 'UAB "Bionova Lt"' },
  { code: 302374252, name: 'UAB "FETOKSA"' },
  { code: 302850071, name: 'UAB "Psenergija"' },
  { code: 302850203, name: 'UAB "Lenergija"' },
  { code: 110193723, name: "IKI Lietuva, UAB" },
  { code: 110723673, name: 'Lietuvos ir Norvegijos UAB "NORVELITA"' },
  { code: 111679436, name: "UAB Gren Lietuva" },
  { code: 111693432, name: 'UAB "KUUSAMET"' },
  { code: 120504795, name: 'UAB "Žalvaris"' },
  { code: 123044722, name: 'UAB "Ecoservice"' },
  { code: 123107852, name: 'UAB "Katalizator. Lt"' },
  { code: 123739364, name: 'UAB "ALKUBIS"' },
  { code: 123852426, name: "UAB Cosma Metal" },
  { code: 123919953, name: 'UAB "Biodegra"' },
  { code: 124824426, name: 'UAB "EMP recycling"' },
  { code: 125521955, name: 'UAB "METRANSA"' },
  { code: 125544562, name: 'UAB "BATERIS"' },
  { code: 125587777, name: 'UAB "REGENERACIJA"' },
  { code: 125594056, name: "MAD Recycling Lietuva UAB" },
  { code: 125821828, name: 'UAB "Antras kvėpavimas"' },
  { code: 132616649, name: 'UAB "Kauno švara"' },
  { code: 132751369, name: 'UAB "Kauno vandenys"' },
  { code: 134357939, name: 'UAB "Urulys"' },
  { code: 135007799, name: 'UAB "Autokausta"' },
  { code: 135699511, name: 'UAB "Zeraida"' },
  { code: 135917853, name: 'UAB "Novitera"' },
  { code: 135935723, name: 'UAB "Autobilas"' },
  { code: 140410741, name: 'UAB Prekybos namai "Jeta"' },
  { code: 141316978, name: "L. Smilingienės individuali įmonė" },
  { code: 141686027, name: 'UAB "Ekonovus"' },
  { code: 142035336, name: 'UAB "KLAIPĖDOS KARTONO TARA"' },
  { code: 142095842, name: 'UAB "APK"' },
  { code: 142150210, name: 'UAB "PARSEKAS"' },
  { code: 144128180, name: 'UAB "Ecoservice projektai"' },
  { code: 145282046, name: 'UAB "ŠVARINTA"' },
  { code: 145507557, name: 'UAB "Kimlita"' },
  { code: 147485928, name: 'UAB "ŠATRUVA"' },
  { code: 147760566, name: 'UAB "Triglis"' },
  { code: 148040272, name: 'UAB "AUTOMERA"' },
  { code: 148328157, name: 'UAB "REMGERA"' },
  { code: 151005737, name: 'UAB "Marijampolės švara"' },
  { code: 153267923, name: "V. Kundroto individuali įmonė" },
  { code: 154111083, name: "UAB Anykščių komunalinis ūkis" },
  { code: 156600094, name: 'UAB "Verslo vizijos"' },
  { code: 157583833, name: 'UAB "ŠIAURĖS VILKAS"' },
  { code: 158320447, name: "R. Gusto UAB" },
  { code: 160288387, name: 'UAB "UTILSA"' },
  { code: 161651197, name: 'UAB "Kėdainių krovimo aikštelė"' },
  { code: 164301437, name: "R. Bružo komercinė firma" },
  { code: 166445258, name: 'UAB "Mažeikių komunalinis ūkis"' },
  { code: 166711075, name: 'UAB "Naikių autolaužynas"' },
  { code: 167357423, name: 'UAB "Metsuna"' },
  { code: 167361083, name: "UAB Mažeikių autocentras" },
  { code: 167392890, name: 'UAB "Ekovalis"' },
  { code: 168688134, name: 'UAB "DARBO BITĖS"' },
  { code: 168704033, name: "R. Gasiūno agroserviso įmonė" },
  { code: 169189593, name: 'UAB "Pasvalio gerovė"' },
  { code: 169269687, name: 'UAB "Daiman"' },
  { code: 169700486, name: 'UAB "VVARFF"' },
  { code: 170072719, name: 'UAB "Automobilių laužynas"' },
  { code: 171704584, name: 'UAB "Virginijus ir KO"' },
  { code: 173181894, name: 'UAB "Autoexpert"' },
  { code: 176597054, name: 'UAB "KVĖDARSTA"' },
  { code: 179437382, name: 'UAB "PARMATUS"' },
  { code: 179747363, name: 'UAB "Norketas"' },
  { code: 179761936, name: "Viešoji įstaiga Tauragės ligoninė" },
  { code: 179858698, name: "V. Jokūbausko įmonė" },
  { code: 179873458, name: 'UAB "Kabelita"' },
  { code: 180200843, name: 'UAB "TELŠIŲ KELIAI"' },
  { code: 182814975, name: 'Akcinė bendrovė "Ukmergės gelžbetonis"' },
  { code: 183694870, name: 'UAB "Šilinė"' },
  { code: 183728582, name: 'UAB "Umaras"' },
  { code: 184204892, name: 'UAB "Lairama"' },
  { code: 185472854, name: "Lolitos Špinderienės įmonė" },
  {
    code: 190272175,
    name: "Viešoji įstaiga Alytaus apskrities S. Kudirkos ligoninė"
  },
  { code: 220074960, name: 'UAB "VSA Vilnius"' },
  { code: 221904380, name: 'UAB "TEKASTA"' },
  { code: 232112130, name: 'Akcinė bendrovė "Kelių priežiūra"' },
  { code: 234706950, name: 'UAB "Jungalas"' },
  { code: 240752870, name: 'UAB "Redra"' },
  {
    code: 247025610,
    name: 'Akcinė bendrovė "PANEVĖŽIO SPECIALUS AUTOTRANSPORTAS"'
  },
  { code: 281324460, name: 'UAB "Gelmesta"' },
  { code: 300007204, name: 'UAB "AUTOFURORAS"' },
  { code: 300010887, name: 'UAB "Metvaldis"' },
  { code: 300012386, name: 'UAB "Baltic metal"' },
  { code: 300017230, name: 'UAB "ARGIMETAS"' },
  { code: 300032759, name: 'UAB "Baltmetas"' },
  { code: 300037592, name: 'UAB "Kauno stiklas"' },
  { code: 300059820, name: 'UAB "BLANCOMET"' },
  { code: 300064237, name: 'UAB "Ferax"' },
  { code: 300069533, name: 'UAB "Jurturas"' },
  { code: 300096110, name: 'UAB "Šaja"' },
  { code: 300107133, name: 'UAB "Alvinta"' },
  { code: 300110097, name: 'UAB "Stasmila"' },
  { code: 300138335, name: "Andriaus Šimkaus IĮ" },
  { code: 300153045, name: 'UAB "Ardona"' },
  { code: 300548925, name: 'UAB "Horeca sprendimai"' },
  { code: 300568358, name: "Baltic Recycling UAB" },
  { code: 300571938, name: 'UAB "Mototransa"' },
  { code: 300583068, name: 'UAB "ROKVESTA"' },
  { code: 300586388, name: "UAB LKREC auto" },
  { code: 300589039, name: "Faustos Užkuraitienės individuali įmonė" },
  { code: 300594367, name: 'UAB "SP verslas"' },
  { code: 300598006, name: 'UAB "Konversas"' },
  { code: 300608393, name: 'UAB "Metalų rinka"' },
  { code: 300608404, name: 'UAB "Metal invest"' },
  { code: 300613159, name: 'UAB "Padėklų centras"' },
  { code: 300621750, name: 'UAB "VRS RAPIDO"' },
  { code: 300624294, name: 'UAB "Biomotorai"' },
  { code: 300631068, name: 'UAB "Arauta"' },
  { code: 300638739, name: 'UAB "Bacmetall"' },
  { code: 300823481, name: 'UAB "JL transportas"' },
  { code: 300835462, name: 'UAB "Ekobazė"' },
  { code: 300838437, name: 'UAB "Domnesta"' },
  { code: 300869607, name: 'UAB "MINGRIDAS"' },
  { code: 300928571, name: 'UAB "Autmetas"' },
  { code: 301179566, name: 'UAB "Daramas"' },
  { code: 301258679, name: 'UAB "Juodmeda"' },
  { code: 301507162, name: 'UAB "Autodalių centras"' },
  { code: 301519549, name: 'UAB "Ekovalda"' },
  { code: 301534615, name: 'UAB "Metlana"' },
  { code: 301540739, name: 'UAB "KGMETA"' },
  { code: 301572740, name: 'UAB "Tramera"' },
  { code: 301574549, name: 'UAB "Transeco"' },
  { code: 301992534, name: 'UAB "RHODIUM.LT"' },
  { code: 302171565, name: "UAB SELTANAS" },
  { code: 302243783, name: 'UAB "Gruzdeda"' },
  { code: 302250984, name: 'UAB "DONARTA"' },
  { code: 302329061, name: 'UAB "Grigeo Packaging"' },
  { code: 302422409, name: 'UAB "Terra recycling"' },
  { code: 302428586, name: 'UAB "Stikloporas"' },
  { code: 302445137, name: "UAB Atliekų tvarkymo centras" },
  { code: 302470717, name: 'UAB "Feronsa"' },
  { code: 302476741, name: 'UAB "Tolmeta"' },
  { code: 302490189, name: 'UAB "Krisminda"' },
  { code: 302490303, name: 'UAB "Versor"' },
  { code: 302506362, name: "REMATRADE, UAB" },
  { code: 302508769, name: 'UAB "Tvari Energija"' },
  { code: 302513232, name: 'UAB "Metrampa"' },
  { code: 302521503, name: 'UAB "Dresta"' },
  { code: 302525505, name: 'UAB "Geležinis verslas"' },
  { code: 302529158, name: 'UAB "Grigeo Recycling"' },
  { code: 302568111, name: 'UAB "KIBIRAS"' },
  { code: 302597757, name: 'UAB "Autoaidas"' },
  { code: 302600210, name: 'UAB "Metruna"' },
  { code: 302603555, name: 'UAB "E-TAŠKAS"' },
  { code: 302625936, name: "UAB METEKO" },
  { code: 302632051, name: 'UAB "AUTOTAVA"' },
  { code: 302637963, name: 'UAB "HD servisas"' },
  { code: 302658719, name: 'UAB "Ekoatliekos"' },
  { code: 302682645, name: 'UAB "Astekas"' },
  { code: 302696755, name: 'UAB "NOFIR"' },
  { code: 302711972, name: 'UAB "Ekodarbai"' },
  { code: 302713254, name: 'UAB "Kesandra Group"' },
  { code: 302713350, name: 'UAB "DEVAUTO"' },
  { code: 302739644, name: "UAB Fupa" },
  { code: 302743767, name: 'UAB "Eko krantas"' },
  { code: 302744431, name: 'UAB "Laumina"' },
  { code: 302759668, name: "Šulcas ir partneriai, UAB" },
  { code: 302760567, name: 'UAB "Metalų komercija"' },
  { code: 302798450, name: 'UAB "DEVIRO"' },
  { code: 302803666, name: 'UAB "VEISTAS"' },
  { code: 302805578, name: 'UAB "NEG Recycling"' },
  { code: 302808364, name: 'UAB "Energesman"' },
  { code: 302809434, name: 'UAB "Leipmetalas"' },
  { code: 302818796, name: 'UAB "Polimeta"' },
  { code: 302822905, name: 'UAB "Metta"' },
  { code: 302829344, name: "UAB RetroPlast" },
  { code: 302839349, name: 'UAB "Už bokšto"' },
  { code: 302842886, name: 'UAB "Aufis"' },
  { code: 302843504, name: 'UAB "Jerameta"' },
  { code: 302862369, name: 'UAB "Harvest House"' },
  { code: 302868500, name: 'UAB "AUTOLISTA"' },
  { code: 302874492, name: "UAB ARVIMETA" },
  { code: 302879984, name: 'UAB "Pranmetal"' },
  { code: 302880043, name: 'UAB "Alvameta"' },
  { code: 302880082, name: 'UAB "Sigmeta"' },
  { code: 302888798, name: 'UAB "ANTRIMETA"' },
  { code: 302901352, name: "UAB Ruvis" },
  { code: 302923758, name: "MERCURY LOGISTICS, UAB" },
  { code: 302962451, name: "UAB Kamineros krovinių terminalas" },
  { code: 302997541, name: "MB Ratuotas" },
  { code: 303048804, name: 'MB "Skalvos projektai"' },
  { code: 303064324, name: '"Ecologus", UAB' },
  { code: 303078263, name: 'UAB "Regmeta"' },
  { code: 303151398, name: 'UAB "Elektronikos perdirbimas"' },
  { code: 303166069, name: 'UAB "Gembera"' },
  { code: 303192320, name: 'UAB "Katera"' },
  { code: 303198113, name: 'UAB "Autmeta"' },
  { code: 303289488, name: 'UAB "Auderas"' },
  { code: 303295135, name: 'UAB "Skaneta"' },
  { code: 303354879, name: "UAB EKO Perdirbimas" },
  { code: 303391030, name: 'UAB "Gaurės metalai"' },
  { code: 303391977, name: 'UAB "Petro metalai"' },
  { code: 303499910, name: 'UAB "Raideris"' },
  { code: 303680857, name: "UAB Opus Realis" },
  { code: 303930862, name: 'UAB "Ligerda"' },
  { code: 304040632, name: 'UAB "Partesa"' },
  { code: 304061377, name: 'UAB "Natureka"' },
  { code: 304116090, name: 'UAB "Klingis"' },
  { code: 304116343, name: 'MB "Juramas"' },
  { code: 304130019, name: 'UAB "Farmeta"' },
  { code: 304142847, name: 'MB "Ekologinės paslaugos"' },
  { code: 304156633, name: "UAB Atliekų rūšiavimo centras" },
  { code: 304197352, name: 'UAB "Rafimeta"' },
  { code: 304207857, name: 'UAB "Astrum industriae"' },
  { code: 304221287, name: 'UAB "Tolmeda"' },
  { code: 304419618, name: 'UAB "Griovimo ekspertai"' },
  { code: 304421380, name: 'UAB "Dauriusta"' },
  { code: 304422272, name: 'UAB "Fragmentum"' },
  { code: 304435608, name: 'UAB "Ipsas ir Ko"' },
  { code: 304439193, name: 'UAB "Metacona"' },
  { code: 304470477, name: 'UAB "Vygrida"' },
  { code: 304516597, name: 'UAB "SN2"' },
  { code: 304522657, name: 'UAB "Ferumita"' },
  { code: 304545467, name: 'UAB "Vitameta"' },
  { code: 304707759, name: 'UAB "Sakorus"' },
  { code: 304774779, name: 'UAB "Metalai"' },
  { code: 304936925, name: 'UAB "Humana LT"' },
  { code: 304962749, name: 'UAB "Ekonetas"' },
  { code: 304973553, name: "UAB RECYCLING tec&sol" },
  { code: 305015952, name: 'UAB "Baltijos metalo laužas"' },
  { code: 305031508, name: 'UAB "Daimora LT"' },
  { code: 305161697, name: 'UAB "Olimeta"' },
  { code: 305345455, name: 'UAB "Autopasiūlymas"' },
  { code: 305434669, name: 'UAB "Šroderis"' },
  { code: 305448409, name: 'UAB "AILIT"' },
  { code: 305467555, name: 'UAB "Litcarbalt"' },
  { code: 305657145, name: 'UAB "Alnemeta"' },
  { code: 305706603, name: "MB Re-lithium" },
  { code: 305743142, name: 'UAB "Detalera"' },
  { code: 305894308, name: "UAB GVT LT" },
  { code: 37310310480, name: "AUDRIUS JACKŪNAS" },
  { code: 37408110637, name: "ARVYDAS SIMONAITIS" },
  { code: 38005151387, name: "DARIUS PIPIRAS" },
  { code: 301950535, name: 'AB „K2 LT"' },
  { code: 302465307, name: 'UAB „Gvatosta"' },
  { code: 304484249, name: "UAB „Cremains Vilnius“" },
  { code: 304844074, name: 'UAB „Cremains"' },
  { code: 110087517, name: 'UAB "Visagino energija"' },
  { code: 120545849, name: 'UAB "VILNIAUS VANDENYS"' },
  { code: 122796063, name: 'UAB "VIDURYS"' },
  {
    code: 124010840,
    name: 'Viešoji įstaiga "Grunto valymo technologijos"'
  },
  { code: 140089260, name: 'Akcinė bendrovė "KLAIPĖDOS VANDUO"' },
  { code: 144133366, name: 'UAB "Šiaulių vandenys"' },
  { code: 147104754, name: 'UAB "AUKŠTAITIJOS VANDENYS"' },
  { code: 149566841, name: 'UAB "Dzūkijos vandenys"' },
  { code: 151104226, name: 'UAB "Sūduvos vandenys"' },
  { code: 152447391, name: 'UAB "PALANGOS VANDENYS"' },
  { code: 152767676, name: 'UAB "Neringos vanduo"' },
  {
    code: 153720195,
    name: 'Alytaus rajono savivaldybės įmonė "Simno komunalininkas"'
  },
  { code: 154138664, name: 'UAB "Anykščių vandenys"' },
  { code: 154850665, name: 'UAB "Biržų vandenys"' },
  { code: 155461670, name: 'UAB "Ignalinos vanduo"' },
  { code: 158275315, name: 'UAB "Jurbarko vandenys"' },
  { code: 159702357, name: 'UAB "Giraitės vandenys"' },
  { code: 161186428, name: 'UAB "Kėdainių vandenys"' },
  { code: 162559136, name: 'UAB "Kelmės vanduo"' },
  {
    code: 163182663,
    name: 'Akcinė bendrovė "Klaipėdos rajono vandenys"'
  },
  { code: 163994426, name: 'UAB "Kretingos vandenys"' },
  { code: 165695198, name: 'UAB "Kazlų Rūdos komunalininkas"' },
  { code: 166092559, name: 'UAB "Kazlų Rūdos šilumos tinklai"' },
  { code: 166486116, name: 'UAB "Mažeikių vandenys"' },
  { code: 167922698, name: 'UAB "Pakruojo vandentiekis"' },
  { code: 169845485, name: 'UAB "Plungės vandenys"' },
  { code: 172380181, name: 'UAB "Raseinių vandenys"' },
  { code: 173820527, name: 'UAB "Skuodo vandenys"' },
  { code: 176523470, name: 'UAB "Šilalės vandenys"' },
  { code: 177059215, name: 'UAB "Šilutės vandenys"' },
  { code: 179249836, name: 'UAB "Tauragės vandenys"' },
  { code: 180153137, name: 'UAB "Telšių vandenys"' },
  { code: 182743364, name: 'UAB "UKMERGĖS VANDENYS"' },
  { code: 186442084, name: 'UAB "Nemenčinės komunalininkas"' },
  { code: 253255950, name: 'UAB "Akmenės vandenys"' },
  { code: 256564350, name: 'UAB "Jonavos vandenys"' },
  { code: 300151902, name: 'UAB "Biastra plius"' },
  { code: 301500997, name: 'UAB "Druskininkų vandenys"' },
  { code: 302555797, name: 'UAB "Ecoramus"' },
  { code: 302644274, name: 'UAB "Branda LT"' },
  { code: 302703541, name: 'UAB "Biograta"' },
  { code: 304414973, name: 'UAB "Biometana"' },
  { code: 305208150, name: 'UAB "Bioineksas"' },
  { code: 110894795, name: "UAB 'DANMINK'" },
  { code: 111657920, name: "UŽDAROJI AKCINĖ BENDROVĖ 'IDAVANG'" },
  { code: 111697459, name: "UAB 'NORAGRA'" },
  { code: 111771518, name: "UAB 'GOLDENGRASS'" },
  { code: 120378325, name: 'Uždaroji akcinė bendrovė "LIBRA"' },
  { code: 121721595, name: "UŽDAROJI AKCINĖ BENDROVĖ 'JONDARA'" },
  { code: 122773794, name: "UŽDAROJI AKCINĖ BENDROVĖ 'TAKAŽOLĖ'" },
  {
    code: 123920012,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'NAUJASIS SOSTAPILIS'"
  },
  { code: 124286867, name: "Romualdo Žemgulio privati firma" },
  { code: 125244928, name: "VŠĮ ,,BISTRAMPOLIO DVARAS'" },
  { code: 125244928, name: "VŠĮ ,,BISTRAMPOLIO DVARAS'" },
  { code: 125244928, name: "VŠĮ ,,BISTRAMPOLIO DVARAS'" },
  { code: 125775028, name: 'UAB "VIPSERVIS"' },
  { code: 125846781, name: "UAB 'AGROLINIJA'" },
  { code: 125916233, name: 'UAB "BIOTELUS"' },
  { code: 125916233, name: 'UAB "BIOTELUS"' },
  { code: 125959067, name: 'UAB "Vilikridas"' },
  { code: 132340880, name: "VALSTYBĖS ĮMONĖ VALSTYBINIŲ MIŠKŲ URĖDIJA" },
  { code: 132340880, name: "VALSTYBĖS ĮMONĖ VALSTYBINIŲ MIŠKŲ URĖDIJA" },
  { code: 132882429, name: "UŽDAROJI AKCINĖ BENDROVĖ 'MIVA'" },
  { code: 134972359, name: "VŠĮ BALTIJOS CIRKAS" },
  { code: 135634182, name: "UŽDAROJI AKCINĖ BENDROVĖ 'LAZERTECHAS'" },
  { code: 135680411, name: "VĮ KAUNO VEDŲ KULTŪROS CENTRAS" },
  { code: 135738170, name: "UAB DZŪKIJOS AGROCENTRAS" },
  { code: 140548023, name: "UAB 'VYTURYS'" },
  { code: 140548023, name: "UAB 'VYTURYS'" },
  {
    code: 142003527,
    name: "UŽDAROJI AKCINĖ BENDROVĖ GIRKALNIO KIAULIŲ KOMPLEKSAS"
  },
  { code: 144315778, name: "UŽDAROJI AKCINĖ BENDROVĖ 'META'" },
  { code: 144535557, name: "UAB 'KASLITA'" },
  { code: 145297468, name: 'UAB "PATIKIMA PAKOPA"' },
  { code: 145450280, name: "UŽDAROJI AKCINĖ BENDROVĖ 'ASEKTAS'" },
  { code: 145541246, name: 'UAB "NARMONTA"' },
  { code: 145776785, name: 'Zenono Dominausko įmonė "Paringuvio ūkis"' },
  { code: 148426263, name: "Dainiaus Kukuraičio IĮ" },
  { code: 151289515, name: "UAB CAVALLUS" },
  { code: 152979693, name: "PAPILĖS ŽEMĖS ŪKIO BENDROVĖ" },
  {
    code: 153656134,
    name: "ALYTAUS RAJONO ŽEMĖS ŪKIO BENDROVĖ 'ATŽALYNAS'"
  },
  { code: 154162626, name: "BURBIŠKIO AGROSERVISO KOOPERATYVAS" },
  { code: 154179675, name: "ŽŪB 'AUGA NAUSODĖ'" },
  { code: 154189598, name: "UAB 'ANYKŠČIŲ VOSINTA'" },
  { code: 154315958, name: "UAB 'VR MOTORSPORT'" },
  { code: 154742821, name: 'UAB "AGARAS"' },
  { code: 154780537, name: "BIRŽŲ RAJONO KIRDONIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 154881372, name: "ŽEMĖS ŪKIO BENDROVĖ 'AGARO RIEŠUTAS'" },
  { code: 155307117, name: "UŽDAROJI AKCINĖ BENDROVĖ 'BIRŽŲ BEKONAS'" },
  { code: 156628581, name: "UŽDAROJI AKCINĖ BENDROVĖ 'BERŽŲ KOMPLEKSAS'" },
  { code: 156913032, name: "ŽŪB 'AUGA LANKESA'" },
  { code: 156913032, name: "ŽŪB 'AUGA LANKESA'" },
  { code: 157544798, name: "UŽDAROJI AKCINĖ BENDROVĖ 'DRĄSUTAIČIAI'" },
  { code: 157544798, name: "UŽDAROJI AKCINĖ BENDROVĖ 'DRĄSUTAIČIAI'" },
  {
    code: 157553466,
    name: "JONIŠKIO RAJONO SKILVIONIŲ ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 157562515, name: "JONIŠKIO RAJONO BARIŪNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 157564142, name: "DARGINIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 157564142, name: "DARGINIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  {
    code: 157567590,
    name: "JONIŠKIO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'KEPALIAI'"
  },
  { code: 157573910, name: "SKAISTGIRIO ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 157575167, name: "ALSIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 158174818, name: "ŽŪB 'AUGA JURBARKAI'" },
  { code: 158203935, name: "UAB 'DAINIAI'" },
  {
    code: 158257445,
    name: "JURBARKO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'ŠIMKAIČIAI'"
  },
  {
    code: 158257445,
    name: "JURBARKO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'ŠIMKAIČIAI'"
  },
  { code: 158886370, name: 'S. Petkevičiaus įmonė "Petkus"' },
  { code: 158891218, name: "AKCINĖ BENDROVĖ KAIŠIADORIŲ PAUKŠTYNAS" },
  {
    code: 158898910,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'RUMŠIŠKIŲ PAUKŠTYNAS'"
  },
  {
    code: 158898910,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'RUMŠIŠKIŲ PAUKŠTYNAS'"
  },
  { code: 158902846, name: "UŽDAROJI AKCINĖ BENDROVĖ GIRELĖS PAUKŠTYNAS" },
  { code: 158902846, name: "UŽDAROJI AKCINĖ BENDROVĖ GIRELĖS PAUKŠTYNAS" },
  { code: 159723337, name: "KAUNO RAJONO ŽŪB 'DAUGĖLIŠKIAI'" },
  { code: 159723337, name: "KAUNO RAJONO ŽŪB 'DAUGĖLIŠKIAI'" },
  { code: 159724624, name: "UAB 'JURAGIAI'" },
  { code: 159738225, name: "LYTAGROS ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 159739850, name: "AKCINĖ BENDROVĖ 'VILKIJOS ŪKIS'" },
  { code: 160204019, name: "ŽEMĖS ŪKIO BENDROVĖ 'MINKUVA'" },
  { code: 161228959, name: "KĖDAINIŲ RAJONO LABŪNAVOS ŽŪB" },
  { code: 161270029, name: "KĖDAINIŲ RAJONO KRAKIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 161272418, name: "KĖDAINIŲ RAJONO OKAINIŲ ŽŪB" },
  { code: 161274383, name: "KĖDAINIŲ RAJONO TRUSKAVOS ŽŪB" },
  { code: 161274426, name: "KĖDAINIŲ RAJONO VERMENOS ŽŪB" },
  {
    code: 161282483,
    name: "KĖDAINIŲ RAJONO DOVYDIŠKIŲ ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 161282526, name: "KĖDAINIŲ RAJONO AŽYTĖNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 161282526, name: "KĖDAINIŲ RAJONO AŽYTĖNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 161298135, name: "UAB ARISTAVOS ŪKIS" },
  {
    code: 161453347,
    name: 'Uždaroji akcinė bendrovė "LOLITA IR PARTNERIAI"'
  },
  { code: 161640934, name: "UAB DOTNUVOS EKSPERIMENTINIS ŪKIS" },
  { code: 161642038, name: "UAB 'AGROKONTAKTAS'" },
  { code: 161650095, name: "UAB 'LAUFRIZA'" },
  { code: 162463248, name: 'ŽEMĖS ŪKIO BENDROVĖ "KERKASIAI"' },
  { code: 162515168, name: "UAB 'INEUDA'" },
  { code: 162535950, name: "UŽDAROJI AKCINĖ BENDROVĖ 'ŠIMŠĖ'" },
  { code: 162727228, name: "ŽŪK 'ŠAUKĖNŲ AGROSERVISAS'" },
  { code: 163208841, name: "ŽEMĖS ŪKIO BENDROVĖ 'VĖŽAIČIAI'" },
  { code: 163338952, name: "ŽŪB 'AGLAURA'" },
  { code: 163338952, name: "ŽŪB 'AGLAURA'" },
  { code: 163338952, name: "ŽŪB 'AGLAURA'" },
  { code: 163338952, name: "ŽŪB 'AGLAURA'" },
  { code: 163713562, name: "KLAIPĖDOS ZOOLOGIJOS SODAS" },
  { code: 163713562, name: "KLAIPĖDOS ZOOLOGIJOS SODAS" },
  { code: 163731247, name: "UAB 'BRIDIMEKSO' VEISLININKYSTĖS FERMA" },
  { code: 164247060, name: "UŽDAROJI AKCINĖ BENDROVĖ 'KONTVAINIAI'" },
  { code: 164684042, name: "KRETINGOS RAJONO ŽŪB 'EKOLAGRA'" },
  { code: 164818064, name: "ŽEMĖS ŪKIO KOOPERATYVAS 'BALTOJI SMILGA'" },
  { code: 165243360, name: "LAIMUČIO PALIONIO FIRMA" },
  { code: 165601811, name: "UŽDAROJI AKCINĖ BENDROVĖ 'LAVIRDA'" },
  { code: 165663236, name: "SMILGIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165663236, name: "SMILGIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165663421, name: "SUVALKIJOS ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165664142, name: "ORIJOS ŽŪB" },
  { code: 165664861, name: "PADOVINIO ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165664861, name: "PADOVINIO ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165666499, name: "ŽŪB 'AUGA ŽELSVELĖ'" },
  { code: 165668169, name: "DOVINĖS ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 165669794, name: "ŽEMĖS ŪKIO BENDROVĖ 'VALAVIČIAI'" },
  { code: 165670049, name: "UAB ŠEŠUPĖS ŪKIS" },
  { code: 166736071, name: "KĘSTUČIO SONGAILOS INDIVIDUALI ĮMONĖ" },
  { code: 167527719, name: 'ŽŪB "AUGA ALANTA"' },
  { code: 167541334, name: "ŽŪB ŽAUGĖDAI" },
  { code: 167607955, name: "UŽDAROJI AKCINĖ BENDROVĖ 'ŠIAURINĖ LAPĖ'" },
  { code: 167928384, name: "PAKRUOJO R. ŽVIRBLONIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 167936331, name: "UAB KALPOKŲ ŪKIS" },
  { code: 167938154, name: "LYGUMŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 167938154, name: "LYGUMŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 167938688, name: "PAKRUOJO RAJ. GAČIONIŲ ŽŪB" },
  {
    code: 167939637,
    name: "PAKRUOJO RAJONO GUOSTAGALIO ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 167940372, name: "UŽDAROJI AKCINĖ BENDROVĖ 'ŠUKIONIAI'" },
  { code: 167994982, name: "PAKRUOJO RAJONO ŽALGIRIO ŽŪB" },
  { code: 167994982, name: "PAKRUOJO RAJONO ŽALGIRIO ŽŪB" },
  {
    code: 167995212,
    name: "PAKRUOJO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'PELANIŠKIAI'"
  },
  { code: 167995746, name: "ŽEIMELIO ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 168521815, name: "PANEVĖŽIO RAJONO ŽIBARTONIŲ ŽŪB" },
  { code: 168522721, name: "PANEVĖŽIO RAJONO ŽŪB 'JOTAINIAI'" },
  { code: 168522721, name: "PANEVĖŽIO RAJONO ŽŪB 'JOTAINIAI'" },
  { code: 168536660, name: "PANEVĖŽIO RAJONO ŽŪB 'BERČIŪNAI'" },
  { code: 168548972, name: "ŽŪB 'AUGA SMILGIAI'" },
  {
    code: 168563166,
    name: "PANEVĖŽIO RAJONO BERNATONIŲ ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 168573274, name: "PANEVĖŽIO RAJONO AUKŠTADVARIO ŽŪB" },
  {
    code: 168577867,
    name: "PANEVĖŽIO RAJONO DAUKNIŪNŲ ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 168689193, name: "Valstybės įmonė Panevėžio miškų urėdija" },
  { code: 168946951, name: 'UAB "KSATENA"' },
  { code: 168970685, name: "UAB ČEVAITA" },
  {
    code: 169149150,
    name: "PASVALIO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'KIEMELIAI'"
  },
  { code: 169154521, name: "UAB 'TETIRVINAI'" },
  {
    code: 169156159,
    name: 'PASVALIO RAJONO ŽEMĖS ŪKIO BENDROVĖ "MEŠKALAUKIS"'
  },
  {
    code: 169164978,
    name: "PASVALIO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'DRAUGYSTĖ'"
  },
  {
    code: 169164978,
    name: "PASVALIO RAJONO ŽEMĖS ŪKIO BENDROVĖ 'DRAUGYSTĖ'"
  },
  {
    code: 169203146,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'KUPIŠKIO AKMENLITA'"
  },
  {
    code: 169280769,
    name: "ŽEMĖS ŪKIO KOOPERATYVAS MIKOLIŠKIO PAUKŠTYNAS"
  },
  { code: 169282439, name: "ŽŪK BALTAS LAŠAS" },
  { code: 169284251, name: "UAB 'VIKEBAS'" },
  { code: 169852483, name: "PLUNGĖS RAJONO ŽEMĖS ŪKIO BENDROVĖ 'MINIJA'" },
  { code: 170662260, name: "VĖŽIONIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 170662260, name: "VĖŽIONIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 170665185, name: "PRIENŲ RAJONO NEMUNO ŽŪB" },
  { code: 171286494, name: "ŽEMĖS ŪKIO BENDROVĖ 'GRAŽIONIŲ BEKONAS'" },
  { code: 171295543, name: "ŽEMĖS ŪKIO BENDROVĖ 'DRAUGAS'" },
  { code: 171305165, name: "ŽŪB 'AUGA VĖRIŠKĖS'" },
  { code: 171306071, name: 'ŽŪB "AUGA SKĖMIAI"' },
  { code: 171314933, name: "DVARELIO ŽŪB" },
  { code: 171327432, name: "ŽŪB 'AUGA KAIRĖNAI'" },
  { code: 171331516, name: "SIDABRAVO ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 171647489, name: "ŽEMĖS ŪKIO KOOPERATYVAS 'EUBRĖ'" },
  { code: 172270144, name: "RASEINIŲ RAJONO KALNUJŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 172272871, name: "SKUDŽIŪNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 172290937, name: "RASEINIŲ ŽŪB 'ŠLYNA'" },
  { code: 172788069, name: "SARGELIŲ BENDRUOMENĖS CENTRAS" },
  { code: 173086898, name: "LAILŪNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  {
    code: 173732714,
    name: 'UŽDAROJI AKCINĖ BENDROVĖ "ŽIOBIŠKIO KOMPLEKSAS"'
  },
  { code: 173837423, name: "GĖSALŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 174274846, name: "UAB 'AGROLINA'" },
  {
    code: 174285675,
    name: "ŠAKIŲ RAJONO GRIŠKABŪDŽIO ŽEMĖS ŪKIO BENDROVĖ"
  },
  {
    code: 174285675,
    name: "ŠAKIŲ RAJONO GRIŠKABŪDŽIO ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 174286058, name: "ŠAKIŲ RAJONO PLOKŠČIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 174286439, name: "ŠAKIŲ RAJONO VONIŠKIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 174286581, name: "ŠAKIŲ RAJONO KUBILIŲ ŽŪB" },
  { code: 174292335, name: "ŠAKIŲ RAJONO GRINAIČIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 174317183, name: "ŠAKIŲ RAJONO LUKŠIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 174401546, name: "UAB 'AUGA GRŪDUVA'" },
  {
    code: 174929230,
    name: "ŠALČININKŲ RAJONO BUTRIMONIŲ ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 174931263, name: "UAB BAUŠŲ ŪKIS" },
  { code: 175682345, name: 'UAB "FAVORITAS"' },
  { code: 175706853, name: "ŽŪB 'AUGA ŽADŽIŪNAI'" },
  { code: 175708861, name: "ŠIUPYLIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 175712183, name: "BAZILIONŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 175712411, name: 'Žemės ūkio bendrovė "GINKŪNŲ PAUKŠTYNAS"' },
  { code: 175727833, name: "KURŠĖNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 175728892, name: "ŽEMĖS ŪKIO BENDROVĖ 'GINKŪNŲ AGROFIRMA'" },
  { code: 175729656, name: "VERBŪNŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 177005858, name: "UAB 'ŠILUTĖS VEISLININKYSTĖ'" },
  { code: 177076474, name: "ŽEMĖS ŪKIO BENDROVĖ 'PIKTUPĖNAI'" },
  { code: 177076474, name: "ŽEMĖS ŪKIO BENDROVĖ 'PIKTUPĖNAI'" },
  { code: 177078678, name: "ŽEMĖS ŪKIO BENDROVĖ 'BAJĖNAI'" },
  { code: 177095784, name: "ŽEMĖS ŪKIO KOOPERATYVAS 'LUMPĖNŲ RAMBYNAS'" },
  { code: 177103779, name: "ŠILUTĖS RAJONO ŽŪB SALA" },
  { code: 177223814, name: 'ŽŪB ,,AGROMANIJA"' },
  { code: 177324126, name: "UAB 'PAMARIO TVENKINIAI'" },
  {
    code: 177380388,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'VILKYČIŲ PAUKŠTYNAS'"
  },
  { code: 178656996, name: 'Uždaroji akcinė bendrovė "VĖJINĖ"' },
  { code: 178862455, name: "UŽDAROJI AKCINĖ BENDROVĖ 'AUKSINIS UNGURYS'" },
  { code: 179342781, name: "UAB VISBARŲ ŽUVININKYSTĖ" },
  { code: 179342781, name: "UAB VISBARŲ ŽUVININKYSTĖ" },
  { code: 179907017, name: "A. BUČINSKO ĮMONĖ" },
  { code: 180255117, name: "UAB 'EIGIRDŽIŲ AGROFIRMA'" },
  { code: 180715277, name: "Valstybės įmonė Telšių miškų urėdija" },
  { code: 181241113, name: "TRAKŲ RAJONO ŽEMĖS ŪKIO BENDROVĖ 'MERKYS'" },
  { code: 181323215, name: "AKCINĖ BENDROVĖ 'ZELVĖ'" },
  { code: 182765631, name: "Z. BELICKIENĖS AGROSERVISO ĮMONĖ 'ŪKININKAS'" },
  { code: 182779570, name: "KAZIO TROTOS ĮMONĖ" },
  { code: 182844057, name: "UŽDAROJI AKCINĖ BENDROVĖ 'TAUJĖNŲ DVARAS'" },
  { code: 183118052, name: "UAB ATKOČIŲ AGROSERVISAS" },
  { code: 183118052, name: "UAB ATKOČIŲ AGROSERVISAS" },
  { code: 183131338, name: "UŽDAROJI AKCINĖ BENDROVĖ ZUJŲ PAUKŠTYNAS" },
  { code: 183224792, name: "UAB 'KARALIŪNAI'" },
  { code: 183674120, name: "UŽDAROJI AKCINĖ BENDROVĖ 'ŽYDROJI LAPĖ'" },
  {
    code: 183845322,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'ANTALGĖS PAUKŠTYNAS'"
  },
  {
    code: 183845322,
    name: "UŽDAROJI AKCINĖ BENDROVĖ 'ANTALGĖS PAUKŠTYNAS'"
  },
  { code: 184550047, name: "VARĖNOS RAJONO DUBIČIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 186066568, name: "UAB 'VILNIAUS ŽIRGYNAS'" },
  {
    code: 186082768,
    name: 'Vilniaus rajono žemės ūkio bendrovė "Naujoji Rudamina"'
  },
  { code: 186107463, name: "AB VILNIAUS PAUKŠTYNAS" },
  { code: 186470062, name: "Jono Ručinsko individuali įmonė" },
  { code: 186489543, name: "UŽDAROJI AKCINĖ BENDROVĖ 'CESTOS MAISTAS'" },
  { code: 187834298, name: "ZARASŲ RAJONO ŽEMĖS ŪKIO BENDROVĖ 'SPARNAI'" },
  { code: 188600743, name: "LIETUVOS OLIMPINIS SPORTO CENTRAS" },
  {
    code: 188601279,
    name: "LR VALSTYBINĖ MAISTO IR VETERINARIJOS TARNYBA"
  },
  { code: 188737980, name: "KURTUVĖNŲ REGIONINIO PARKO DIREKCIJA" },
  {
    code: 190060724,
    name: "A. BARANAUSKO IR A. VIENUOLIO-ŽUKAUSKO MEMORIALINIS MUZIEJUS"
  },
  { code: 190087696, name: "KURŠĖNŲ ŠEIMOS NAMAI" },
  { code: 190464695, name: "Lietuvos jūrų muziejus" },
  { code: 190539984, name: "ŠIAULIŲ JAUNŲJŲ GAMTININKŲ CENTRAS" },
  {
    code: 190599771,
    name: "TELŠIŲ RAJONO SAVIVALDYBĖS ŽEMAIČIŲ MUZIEJUS 'ALKA'"
  },
  {
    code: 190599771,
    name: "TELŠIŲ RAJONO SAVIVALDYBĖS ŽEMAIČIŲ MUZIEJUS 'ALKA'"
  },
  {
    code: 190599771,
    name: "TELŠIŲ RAJONO SAVIVALDYBĖS ŽEMAIČIŲ MUZIEJUS 'ALKA'"
  },
  { code: 190650842, name: "BEIŽIONIŲ VAIKŲ GLOBOS NAMAI" },
  { code: 190757036, name: "ŠIAULIŲ 'AUŠROS' MUZIEJUS" },
  { code: 190757221, name: "LIETUVOS LIAUDIES BUITIES MUZIEJUS" },
  { code: 190757221, name: "LIETUVOS LIAUDIES BUITIES MUZIEJUS" },
  { code: 190797664, name: "PABRADĖS SOCIALINĖS GLOBOS NAMAI" },
  { code: 190804219, name: "JONIŠKIO ŽEMĖS ŪKIO MOKYKLA" },
  { code: 190804219, name: "JONIŠKIO ŽEMĖS ŪKIO MOKYKLA" },
  { code: 190862717, name: "PANEVĖŽIO GAMTOS MOKYKLA" },
  {
    code: 190977872,
    name: "RADVILIŠKIO TECHNOLOGIJŲ IR VERSLO MOKYMO CENTRAS"
  },
  { code: 191176774, name: "AUKŠTAITIJOS PROFESINIO RENGIMO CENTRAS" },
  { code: 191176774, name: "AUKŠTAITIJOS PROFESINIO RENGIMO CENTRAS" },
  { code: 191353153, name: "MEDŽIOTOJŲ KLUBAS 'BERŽAS'" },
  {
    code: 191395451,
    name: 'ŠALČININKŲ RAJONO JOJIMO SPORTO KLUBAS "PASAGA"'
  },
  { code: 191716918, name: "LIETUVOS ZOOLOGIJOS SODAS" },
  {
    code: 192050878,
    name: 'Labdaros ir paramos fondas "Vilties švyturys"'
  },
  { code: 193298937, name: 'SPORTO KLUBAS "MUSTANGAS"' },
  { code: 193412757, name: "Žirginio sporto klubas" },
  { code: 211950810, name: "Vilniaus universitetas" },
  { code: 234758790, name: "UAB 'VEDAUTOS AUTOTRANSPORTAS'" },
  { code: 253086190, name: "INDIVIDUALI ĮMONĖ 'ALMONA'" },
  { code: 256605060, name: "PAULIUKŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 256605060, name: "PAULIUKŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 256605060, name: "PAULIUKŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 265670420, name: "VAZNIŠKIŲ ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 267935230, name: "PAKRUOJO RAJONO LINKUVOS ŽEMĖS ŪKIO BENDROVĖ" },
  { code: 268565360, name: "PANEVĖŽIO RAJONO ŽŪB 'ŠILAGALIS'" },
  {
    code: 268573460,
    name: "PANEVĖŽIO RAJONO LIBERIŠKIO ŽEMĖS ŪKIO BENDROVĖ"
  },
  { code: 268685210, name: 'ŽIRGINIO SPORTO KLUBAS "VYTIS"' },
  { code: 269159640, name: "PASVALIO RAJONO ŽŪB 'VAŠKAI'" },
  { code: 269159640, name: "PASVALIO RAJONO ŽŪB 'VAŠKAI'" },
  { code: 273114380, name: "ŽEMĖS ŪKIO BENDROVĖ 'AUDRUPIO PAUKŠTYNAS'" },
  { code: 274588310, name: "R. KAUBRIO INDIVIDUALI ĮMONĖ" },
  { code: 274588310, name: "R. KAUBRIO INDIVIDUALI ĮMONĖ" },
  { code: 278231850, name: 'ŽEMĖS ŪKIO BENDROVĖ "ŽIRGYNAS"' },
  { code: 284268020, name: "BIOVELA-UTENOS MĖSA, UAB" },
  { code: 284563070, name: "UAB 'HESONA'" },
  { code: 284563070, name: "UAB 'HESONA'" },
  { code: 284563070, name: "UAB 'HESONA'" },
  { code: 284563070, name: "UAB 'HESONA'" },
  { code: 284563070, name: "UAB 'HESONA'" },
  { code: 286072650, name: "ŽEMĖS ŪKIO BENDROVĖ VILNIAUS ŽIRGYNAS" },
  { code: 293057830, name: 'Jojimo sporto klubas "Ulonai"' },
  {
    code: 300030822,
    name: "UŽDAROJI AKCINĖ BENDROVĖ DOMANTONIŲ PAUKŠTYNAS"
  },
  { code: 300042073, name: "UAB RASKAFAS" },
  { code: 300056646, name: "UAB 'GALVIJŲ CENTRAS'" },
  { code: 300065549, name: "IĮ 'EUROLINKAS'" },
  { code: 300065652, name: "ŽEMĖS ŪKIO BENDROVĖ 'JUČIŲ ARUODAS'" },
  { code: 300066494, name: 'UAB "Dertena"' },
  { code: 300073211, name: "UAB 'STANKŪNŲ KAILIŲ FERMA'" },
  { code: 300073371, name: 'Uždaroji akcinė bendrovė "Lietbro"' },
  { code: 300088277, name: "UAB 'JUODOJI AUDINĖ'" },
  {
    code: 300092247,
    name: "UŽDAROJI AKCINĖ BENDROVĖ ALESNINKŲ PAUKŠTYNAS"
  },
  { code: 300150191, name: "ŽŪB 'AUBRAKAS'" },
  { code: 300500898, name: "UAB 'KONIG ANGUS'" },
  { code: 300502066, name: "UAB 'OBLT'" },
  { code: 300505023, name: "UAB 'NORVŪKSA'" },
  { code: 300556199, name: "Jono Basanavičiaus sodyba-muziejus" },
  { code: 300575438, name: "IĮ 'RASARTA'" },
  { code: 300584138, name: "ŽEMĖS ŪKIO KOOPERATYVAS 'LIETUVIŠKA MĖSA'" },
  { code: 300593678, name: "OVIDIJAUS BALČIŪNO INDIVIDUALI ĮMONĖ" },
  { code: 300605625, name: 'UAB "A projektai"' },
  { code: 300605803, name: "VšĮ Hippoterapijos centras" },
  { code: 300634986, name: "KOOPERATYVAS 'MŪŠOS ARUODAI'" },
  { code: 300657593, name: 'SPORTO KLUBAS "VILARTAS"' },
  { code: 300662117, name: "VŠĮ 'VISUOMENĖS HARMONIZAVIMO PARKAS'" },
  { code: 300855707, name: "Integralios medicinos centras, UAB" },
  {
    code: 300876562,
    name: 'VŠĮ "LSMU BAISOGALOS GYVULININKYSTĖS CENTRAS"'
  },
  { code: 300890925, name: "ŽIRGŲ GLOBOS ASOCIACIJA" },
  { code: 300899714, name: "TROŠKŪNŲ KOOPERATINĖ BENDROVĖ" },
  { code: 301166540, name: "UAB  ''PARYŽĖ''" },
  { code: 301259539, name: "UAB 'BRETAGNĖ'" },
  { code: 301259539, name: "UAB 'BRETAGNĖ'" },
  { code: 301565824, name: 'ŽEMĖS ŪKIO KOOPERATYVAS "AGROLANKA"' },
  { code: 301651554, name: "ASOCIACIJA 'JUSAIČIŲ ŽIRGAI'" },
  { code: 301733624, name: "UAB 'BELARENDA'" },
  { code: 301741457, name: "VŠĮ ,,GABRIELIUS'" },
  { code: 301868682, name: 'UAB "Bajorų rūmai"' },
  { code: 302101531, name: "CLEANCO, UAB" },
  { code: 302249117, name: "UAB 'PAKRUOJO PARKAI'" },
  {
    code: 302296985,
    name: "VIEŠOJI ĮSTAIGA LIETUVOS SVEIKATOS MOKSLŲ UNIVERSITETO PRAKTINIO MOKYMO IR BANDYMŲ CENTRAS"
  },
  { code: 302303725, name: "VŠĮ 'HABITAS'" },
  { code: 302307346, name: "UAB 'AGVIKA'" },
  { code: 302310844, name: "UAB 'EURO WERTEX'" },
  { code: 302315688, name: 'VIEŠOJI ĮSTAIGA "HOBBY HORSE"' },
  { code: 302316224, name: 'JOJIMO SPORTO KLUBAS "TIES BALTIJA"' },
  { code: 302318385, name: 'UAB "Žirgų pasaulis"' },
  { code: 302328194, name: "MEDŽIOTOJŲ KLUBAS 'ARSIRA'" },
  { code: 302351182, name: 'UAB "LM BALTIC"' },
  { code: 302412475, name: "UAB 'BEST COMPANY LT'" },
  { code: 302412475, name: "UAB 'BEST COMPANY LT'" },
  { code: 302412952, name: "UAB 'COMANDOR EQUESTRIAN CENTER'" },
  { code: 302414825, name: "KARVIS, UAB" },
  {
    code: 302431671,
    name: "SENOVĖS BALTŲ ATKURIAMOSIOS ISTORIJOS KLUBAS 'SŪDUVOS TAURAS'"
  },
  { code: 302442810, name: "ŽŪB  PONIS" },
  { code: 302443691, name: "UAB 'CERVUS AUREUS'" },
  { code: 302454129, name: 'UAB "GINTARIUS"' },
  { code: 302454129, name: 'UAB "GINTARIUS"' },
  { code: 302465385, name: "UAB 'FARMERS LT'" },
  { code: 302474352, name: "UAB 'DUBINGIŲ ŽIRGYNAS'" },
  { code: 302474352, name: "UAB 'DUBINGIŲ ŽIRGYNAS'" },
  { code: 302474352, name: "UAB 'DUBINGIŲ ŽIRGYNAS'" },
  { code: 302474352, name: "UAB 'DUBINGIŲ ŽIRGYNAS'" },
  { code: 302493192, name: "UAB BARTYNCO" },
  { code: 302493192, name: "UAB BARTYNCO" },
  { code: 302493840, name: "UAB 'VIELRA'" },
  { code: 302497504, name: "UAB 'TRACKON'" },
  { code: 302504404, name: "MIEGĖNŲ ŽŪB" },
  { code: 302514779, name: 'VIEŠOJI ĮSTAIGA "RIETAVO ŽIRGYNAS"' },
  { code: 302533295, name: 'UAB "HORSEMARKET"' },
  { code: 302536989, name: "LIETUVOS SVEIKATOS MOKSLŲ UNIVERSITETAS" },
  { code: 302536989, name: "LIETUVOS SVEIKATOS MOKSLŲ UNIVERSITETAS" },
  { code: 302536989, name: "LIETUVOS SVEIKATOS MOKSLŲ UNIVERSITETAS" },
  { code: 302542547, name: "VŠĮ 'CONVIVO LAISVALAIKIO CENTRAS'" },
  { code: 302554364, name: "Jojimo centras Žirgas, UAB" },
  { code: 302563662, name: "VŠĮ 'ZOO PARKAS'" },
  { code: 302563662, name: "VŠĮ 'ZOO PARKAS'" },
  { code: 302567867, name: "UAB 'SITOLA'" },
  { code: 302569569, name: 'UAB "Kavarsko šilas"' },
  { code: 302572960, name: "UAB 'FUR FARM LT'" },
  { code: 302581347, name: "UAB 'SUVALKIJOS VERŠELIAI'" },
  { code: 302590428, name: 'UAB "Molėtų šilas"' },
  { code: 302601561, name: "MICKŪNŲ JOJIMO KLUBAS" },
  { code: 302601839, name: "ADUTIŠKIO KOOPERATINĖ BENDROVĖ" },
  { code: 302604714, name: "UAB 'ŪKIS MUMS'" },
  { code: 302618062, name: "D. GYLIO IĮ" },
  { code: 302620017, name: "UAB 'MINKIRTA'" },
  { code: 302622573, name: 'UAB "Horses Academy"' },
  { code: 302622630, name: "UAB ČERNIAUS ŪKIS" },
  { code: 302627015, name: 'VšĮ "HIPPO plius"' },
  { code: 302630157, name: "UAB 'SIMENTALAI LT'" },
  { code: 302635752, name: "VDU ŽEMĖS ŪKIO AKADEMIJOS MOKOMASIS ŪKIS" },
  { code: 302648187, name: "UAB 'VIKONAS'" },
  { code: 302687261, name: "UAB ,,VEIVERIŲ ŽIRGYNAS'" },
  { code: 302690738, name: 'UAB "Nevėžio žirgai"' },
  { code: 302691509, name: "ŽŪK BENDROVĖ 'AGROKAVIS'" },
  { code: 302705528, name: "AB 'LIETUVOS VEISLININKYSTĖ'" },
  { code: 302705528, name: "AB 'LIETUVOS VEISLININKYSTĖ'" },
  { code: 302710895, name: "VŠĮ 'TAUTMILĖS GLOBA'" },
  { code: 302710895, name: "VŠĮ 'TAUTMILĖS GLOBA'" },
  { code: 302728092, name: "UAB 'GEOVIKA'" },
  { code: 302756914, name: 'UAB "Tomlika"' },
  { code: 302764989, name: "ŽŪB 'TIKRAS LIETUVIŠKAS ŪKIS'" },
  { code: 302765009, name: "ŽELVIŲ KAIMO BENDRUOMENĖ" },
  { code: 302771866, name: "UAB 'ANTANO ŪKIS'" },
  { code: 302777666, name: "ŽŪB 'ARMI AGRO'" },
  { code: 302779578, name: "UAB SAVIEČIŲ ŪKIS" },
  { code: 302780908, name: "ŽŪB 'MAKUTIŠKIŲ ŽEMĖ'" },
  { code: 302785061, name: 'UAB "Vaporet"' },
  { code: 302795881, name: 'UAB "LIETUVOS ŽIRGYNAS"' },
  { code: 302811727, name: 'UAB "Vievio paukščiai"' },
  { code: 302825260, name: 'ŽIRGININKŲ KLUBAS "DRAUGAS"' },
  {
    code: 302848387,
    name: "LIETUVOS MOKINIŲ NEFORMALIOJO ŠVIETIMO CENTRAS"
  },
  { code: 302868735, name: "UAB 'HANTERA'" },
  { code: 302868735, name: "UAB 'HANTERA'" },
  { code: 302892597, name: "MB 'AVIDUS'" },
  { code: 302904850, name: "ASOCIACIJA 'HAPPY HORSES.LT'" },
  { code: 302916151, name: 'UAB "PAJŪRIO ŽIRGYNAS"' },
  { code: 302961851, name: "UAB 'GALVIJŲ EKSPORTAS'" },
  { code: 303000752, name: "ŽŪB 'EKOX'" },
  { code: 303011866, name: "KOOPERATINĖ BENDROVĖ 'AUDINUKĖ'" },
  { code: 303013290, name: "MB MINSAGA" },
  { code: 303021159, name: "UAB VIP DENARO" },
  { code: 303021159, name: "UAB VIP DENARO" },
  { code: 303025517, name: "UAB SFE STAR FROM EAST" },
  { code: 303027361, name: "UAB 'MILKONA'" },
  { code: 303037964, name: "UAB 'VAJA FARM'" },
  { code: 303060703, name: "MB 'NORKMINA'" },
  { code: 303062063, name: "ŽŪB 'AGRO GERVIŠKĖS'" },
  { code: 303076878, name: "BALTOS AVIES KOOPERATYVAS" },
  { code: 303093692, name: "UAB 'RUGETA'" },
  { code: 303119009, name: 'UAB "KASLINA"' },
  { code: 303128645, name: 'UAB "Liūtas LT"' },
  { code: 303139442, name: "UAB 'SMELNA'" },
  { code: 303142050, name: "ŽŪB 'DANAI'" },
  { code: 303148039, name: "KOOPERATINĖ BENDROVĖ 'ALSIŲ PAUKŠTYNAS'" },
  { code: 303151170, name: "UAB 'ŠVEDIJOS PIENAS'" },
  { code: 303201011, name: "ŽŪB 'MEDINILA'" },
  { code: 303211258, name: "UAB 'GLUOSNITA'" },
  { code: 303211799, name: "UAB 'TOPAGRO'" },
  { code: 303213686, name: 'UAB "ILZENBERGO VALDOS"' },
  { code: 303259420, name: "UAB ,,ŽIRGO STARTAS'" },
  { code: 303270648, name: "MARIJAMPOLĖS ŽIRGŲ SPORTO KLUBAS 'TANDEMAS'" },
  { code: 303270751, name: 'UAB "Biogilė"' },
  { code: 303271821, name: "UAB 'EDGA'" },
  { code: 303284644, name: "UAB 'ALGISA'" },
  { code: 303308675, name: "ŽŪB 'NAISIŲ ŪKIS'" },
  { code: 303315883, name: "UAB 'MIGANA'" },
  { code: 303324672, name: "ŽŪB 'LUMEDOS AGRO'" },
  { code: 303324672, name: "ŽŪB 'LUMEDOS AGRO'" },
  { code: 303340751, name: "UAB RADUVA" },
  { code: 303366963, name: "RODEO KLUBAS, MB" },
  { code: 303367086, name: "UAB 'FREZIKA'" },
  { code: 303384107, name: "UAB GEDIMINO ŪKIS" },
  { code: 303406863, name: 'ŽIRGINIO SPORTO KLUBAS "UTENOS ŽIRGYNAS"' },
  { code: 303462470, name: 'UAB "SICILIA ART IN FOOD"' },
  { code: 303476438, name: "JOJIMO KLUBAS 'KURTUVA'" },
  { code: 303481517, name: "VšĮ Minijos žirgynas" },
  { code: 303496871, name: "VŠĮ 'TRYS PARŠELIAI'" },
  { code: 303496946, name: 'VŠĮ JOJIMO MOKYKLA "LYDERIS"' },
  { code: 303497902, name: "KOOPERATYVAS 'AVININKAS'" },
  { code: 303888388, name: "UAB 'EKSPLOTA'" },
  { code: 304051949, name: "MB 'ŽALI LAUKAI'" },
  { code: 304057980, name: "ŽŪB 'EDAGRA'" },
  { code: 304060478, name: "ŽŪB 'EKOGRAIN'" },
  { code: 304061384, name: "UAB 'MĖSOS SPECIALISTAS'" },
  { code: 304064761, name: "ŽŪB 'ŠIAURĖS BULIUS'" },
  { code: 304094092, name: 'UAB "Veiklos idėjos ir investicijos"' },
  { code: 304150406, name: "VŠĮ SPORTO KLUBAS AUKŠTAITIJOS ŽIRGYNAS" },
  {
    code: 304157308,
    name: "ASOCIACIJA JOJIMO SPORTO KLUBAS 'ZIGMO ŽIRGAI'"
  },
  { code: 304164594, name: "UAB 'AGROAUKCIONAI'" },
  { code: 304164594, name: "UAB 'AGROAUKCIONAI'" },
  { code: 304172178, name: "VŠĮ 'RAUBONIŲ PARKAS'" },
  { code: 304175391, name: "UAB TECHNINIO SERVISO KOMPANIJA" },
  { code: 304224002, name: 'UAB "ATSIPŪTĘ BURUNDUKAI"' },
  { code: 304254967, name: "UAB 'AUGINIMO FORMULĖ'" },
  { code: 304254967, name: "UAB 'AUGINIMO FORMULĖ'" },
  { code: 304254967, name: "UAB 'AUGINIMO FORMULĖ'" },
  { code: 304260265, name: "UAB 'FURMITAS'" },
  { code: 304286744, name: 'UAB "Krikštonių paukštynas"' },
  { code: 304289605, name: 'UAB "NALA"' },
  { code: 304289861, name: "MB 'MOLĖTŲ ŽIRGAI'" },
  { code: 304399382, name: 'VšĮ "Krubina"' },
  { code: 304409054, name: 'UAB "ALGMITA"' },
  { code: 304409054, name: 'UAB "ALGMITA"' },
  { code: 304412940, name: 'VšĮ "Eiras"' },
  { code: 304416974, name: "UAB 'EKOANGUS'" },
  { code: 304438928, name: 'UAB "TRANSATRA"' },
  { code: 304447261, name: "VŠĮ 'ŠUNIUKŲ FĖJA'" },
  { code: 304478381, name: "MEDŽIOTOJŲ KLUBAS 'LAUKYMĖ'" },
  { code: 304540000, name: 'VšĮ "Lazdijų hipodromas"' },
  { code: 304573865, name: "KOOPERATINĖ BENDROVĖ 'RĖŽIS'" },
  { code: 304600440, name: 'UAB "PAUPIO SLĖNIS"' },
  { code: 304680454, name: "ŽEMĖS ŪKIO BENDROVĖ 'AGRORAGĖ'" },
  { code: 304680454, name: "ŽEMĖS ŪKIO BENDROVĖ 'AGRORAGĖ'" },
  { code: 304720393, name: "ŽŪB 'AVIENA IR ĖRIENA'" },
  { code: 304720393, name: "ŽŪB 'AVIENA IR ĖRIENA'" },
  { code: 304762531, name: "UAB AGROBAZĖ" },
  { code: 304771427, name: "UAB 'BEKONAS LT'" },
  { code: 304827114, name: "FARMERS CIRCLE UAB" },
  { code: 304827114, name: "FARMERS CIRCLE UAB" },
  { code: 304827114, name: "FARMERS CIRCLE UAB" },
  { code: 304827445, name: 'UAB "DALKOS"' },
  { code: 304828127, name: "UAB 'ŠILŲ ŪKIS'" },
  { code: 304840389, name: "UAB TAURAMĖ" },
  { code: 304847939, name: "MB 'PORKUS'" },
  { code: 304887862, name: 'MB "Rusnės žirgai"' },
  { code: 304894910, name: "UAB ILZENBERGO ŪKIS" },
  { code: 304909885, name: 'Asociacija "Svajonės pasaga"' },
  { code: 304935969, name: 'MB "REVŲ ŽIRGAI"' },
  { code: 304979983, name: "UAB 'GENETINIAI IŠTEKLIAI'" },
  { code: 304979983, name: "UAB 'GENETINIAI IŠTEKLIAI'" },
  { code: 304996185, name: "VŠĮ RODEO KLUBAS MARVELĖS ŽIRGYNAS" },
  { code: 305079143, name: 'UAB "Šlovė darbui"' },
  { code: 305098072, name: "UAB 'DZŪKIJOS ARTOJAS'" },
  { code: 305109437, name: "ŽŪB 'AGROHARMONIJA'" },
  { code: 305109437, name: "ŽŪB 'AGROHARMONIJA'" },
  { code: 305165959, name: "MB 'RAMUTIS'" },
  { code: 305167198, name: "MB 'SARTŲ ŪKIS'" },
  { code: 305174577, name: "LIT EGG, UAB" },
  { code: 305214986, name: "UAB KELMĖS ŽVĖRELIŲ ŪKIS" },
  { code: 305257642, name: "SMAGUS RATAS, MB" },
  { code: 305287062, name: "MB 'MARVELĖS PARKAS'" },
  { code: 305319909, name: "VŠĮ 'PRIE RAUBONIŲ MALŪNO'" },
  { code: 305393934, name: "UAB 'VERSLAS VISIEMS'" },
  { code: 305393934, name: "UAB 'VERSLAS VISIEMS'" },
  { code: 305393934, name: "UAB 'VERSLAS VISIEMS'" },
  { code: 305496891, name: 'UAB ,,ŽŪVF"' },
  { code: 305496891, name: 'UAB ,,ŽŪVF"' },
  { code: 305536503, name: "PAUPIO ATVIRASIS ŪKIS" },
  { code: 305561951, name: "MB RUGYS" },
  { code: 305590497, name: "UAB ALUMINAS" },
  { code: 305598686, name: 'VšĮ "JSK HAPPY HORSE"' },
  { code: 305604018, name: "ŽŪB 'LUKŠTAS'" },
  { code: 305618270, name: "ASOCIACIJA LAUMŽIRGIŲ SLĖNIS" },
  { code: 305659018, name: 'Kooperatyvas "Neries vingis"' },
  { code: 305666094, name: "UAB BALTIC Q" },
  { code: 305682928, name: "UAB 'AGRO X'" },
  { code: 305778086, name: 'UAB "Limuzinų galvijų stotis"' },
  { code: 305790131, name: "UAB 'BRESCOT'" },
  { code: 305824256, name: "UAB GREEN TERRA" },
  { code: 305897133, name: 'UAB "BALTMINK"' },
  { code: 305945215, name: "UAB 'SŪDUVOS ŪKIS'" },
  { code: 305959777, name: 'MB "Žirgynas pegasas"' },
  { code: 306013979, name: "MB Paupio žirgynas" },
  { code: 306021100, name: "MB ŠEŠUOLĖLIŲ ŪKIS" },
  { code: 306109002, name: "Žemaitijos saugomų teritorijų direkcija" },
  { code: 306205082, name: 'MB "Agrasto uoga"' },
  {
    code: 306286134,
    name: "ŪKININKŲ KOOPERATINĖ BENDROVĖ 'ŠILGALIAI AGRO'"
  },
  { code: 371090459, name: "ŠIURNA" },
  { code: 371090459, name: "ŠIURNA" }
];

export const companyCodes = [
  { codeId: 21, companyCode: 110012450 },
  { codeId: 34, companyCode: 110022220 },
  { codeId: 60, companyCode: 110087517 },
  { codeId: 31, companyCode: 110241050 },
  { codeId: 34, companyCode: 110309494 },
  { codeId: 34, companyCode: 110342843 },
  { codeId: 10, companyCode: 110382414 },
  { codeId: 64, companyCode: 110414641 },
  { codeId: 43, companyCode: 110433047 },
  { codeId: 24, companyCode: 110451593 },
  { codeId: 18, companyCode: 110484732 },
  { codeId: 16, companyCode: 110497046 },
  { codeId: 20, companyCode: 110547339 },
  { codeId: 28, companyCode: 110553420 },
  { codeId: 34, companyCode: 110572926 },
  { codeId: 16, companyCode: 110583940 },
  { codeId: 52, companyCode: 110599930 },
  { codeId: 24, companyCode: 110612687 },
  { codeId: 34, companyCode: 110618373 },
  { codeId: 34, companyCode: 110639887 },
  { codeId: 49, companyCode: 110652977 },
  { codeId: 6, companyCode: 110669492 },
  { codeId: 24, companyCode: 110673380 },
  { codeId: 19, companyCode: 110681480 },
  { codeId: 26, companyCode: 110689032 },
  { codeId: 34, companyCode: 110692352 },
  { codeId: 19, companyCode: 110697132 },
  { codeId: 37, companyCode: 110698387 },
  { codeId: 34, companyCode: 110702540 },
  { codeId: 54, companyCode: 110703457 },
  { codeId: 28, companyCode: 110708760 },
  { codeId: 34, companyCode: 110710455 },
  { codeId: 38, companyCode: 110711219 },
  { codeId: 43, companyCode: 110711742 },
  { codeId: 4, companyCode: 110723673 },
  { codeId: 3, companyCode: 110724394 },
  { codeId: 14, companyCode: 110737089 },
  { codeId: 26, companyCode: 110737317 },
  { codeId: 34, companyCode: 110739478 },
  { codeId: 34, companyCode: 110742111 },
  { codeId: 16, companyCode: 110746857 },
  { codeId: 26, companyCode: 110751085 },
  { codeId: 4, companyCode: 110753855 },
  { codeId: 16, companyCode: 110759541 },
  { codeId: 34, companyCode: 110760134 },
  { codeId: 19, companyCode: 110760287 },
  { codeId: 54, companyCode: 110764912 },
  { codeId: 21, companyCode: 110769311 },
  { codeId: 16, companyCode: 110773733 },
  { codeId: 40, companyCode: 110785479 },
  { codeId: 16, companyCode: 110788817 },
  { codeId: 57, companyCode: 110791941 },
  { codeId: 16, companyCode: 110795249 },
  { codeId: 15, companyCode: 110796340 },
  { codeId: 64, companyCode: 110797780 },
  { codeId: 34, companyCode: 110807598 },
  { codeId: 16, companyCode: 110808351 },
  { codeId: 5, companyCode: 110810384 },
  { codeId: 60, companyCode: 110818317 },
  { codeId: 16, companyCode: 110820916 },
  { codeId: 34, companyCode: 110830643 },
  { codeId: 19, companyCode: 110833415 },
  { codeId: 16, companyCode: 110846971 },
  { codeId: 10, companyCode: 110850336 },
  { codeId: 16, companyCode: 110853827 },
  { codeId: 34, companyCode: 110854886 },
  { codeId: 6, companyCode: 110855073 },
  { codeId: 53, companyCode: 110855269 },
  { codeId: 13, companyCode: 110859328 },
  { codeId: 58, companyCode: 110860444 },
  { codeId: 16, companyCode: 110860978 },
  { codeId: 53, companyCode: 110861884 },
  { codeId: 5, companyCode: 110864656 },
  { codeId: 13, companyCode: 110867232 },
  { codeId: 16, companyCode: 110871654 },
  { codeId: 53, companyCode: 110873477 },
  { codeId: 49, companyCode: 110876587 },
  { codeId: 19, companyCode: 110878595 },
  { codeId: 16, companyCode: 110879544 },
  { codeId: 48, companyCode: 110883585 },
  { codeId: 58, companyCode: 110884491 },
  { codeId: 58, companyCode: 110888899 },
  { codeId: 38, companyCode: 110890964 },
  { codeId: 9, companyCode: 111446581 },
  { codeId: 53, companyCode: 111449691 },
  { codeId: 57, companyCode: 111456885 },
  { codeId: 18, companyCode: 111475323 },
  { codeId: 57, companyCode: 111512921 },
  { codeId: 53, companyCode: 111513642 },
  { codeId: 34, companyCode: 111515650 },
  { codeId: 21, companyCode: 111516229 },
  { codeId: 32, companyCode: 111557610 },
  { codeId: 16, companyCode: 111568254 },
  { codeId: 53, companyCode: 111580057 },
  { codeId: 16, companyCode: 111587411 },
  { codeId: 34, companyCode: 111600015 },
  { codeId: 43, companyCode: 111618351 },
  { codeId: 16, companyCode: 111619649 },
  { codeId: 53, companyCode: 111627891 },
  { codeId: 21, companyCode: 111649820 },
  { codeId: 16, companyCode: 111654582 },
  { codeId: 16, companyCode: 111656829 },
  { codeId: 53, companyCode: 111656971 },
  { codeId: 31, companyCode: 111660489 },
  { codeId: 16, companyCode: 111661242 },
  { codeId: 16, companyCode: 111685332 },
  { codeId: 54, companyCode: 111687536 },
  { codeId: 57, companyCode: 111688976 },
  { codeId: 53, companyCode: 111692298 },
  { codeId: 16, companyCode: 111701427 },
  { codeId: 53, companyCode: 111712060 },
  { codeId: 10, companyCode: 111717036 },
  { codeId: 44, companyCode: 111719197 },
  { codeId: 53, companyCode: 111748843 },
  { codeId: 57, companyCode: 111755656 },
  { codeId: 16, companyCode: 111757130 },
  { codeId: 18, companyCode: 111765426 },
  { codeId: 3, companyCode: 111770569 },
  { codeId: 10, companyCode: 111776255 },
  { codeId: 21, companyCode: 111781430 },
  { codeId: 16, companyCode: 111783972 },
  { codeId: 9, companyCode: 111789092 },
  { codeId: 45, companyCode: 111795031 },
  { codeId: 19, companyCode: 111796286 },
  { codeId: 24, companyCode: 111800069 },
  { codeId: 19, companyCode: 111804128 },
  { codeId: 20, companyCode: 111812185 },
  { codeId: 11, companyCode: 111817531 },
  { codeId: 12, companyCode: 111823438 },
  { codeId: 49, companyCode: 111823961 },
  { codeId: 47, companyCode: 111824910 },
  { codeId: 21, companyCode: 111832597 },
  { codeId: 3, companyCode: 111879337 },
  { codeId: 17, companyCode: 111882080 },
  { codeId: 1, companyCode: 111882995 },
  { codeId: 57, companyCode: 111884512 },
  { codeId: 53, companyCode: 120013547 },
  { codeId: 53, companyCode: 120032857 },
  { codeId: 53, companyCode: 120040238 },
  { codeId: 53, companyCode: 120050531 },
  { codeId: 40, companyCode: 120090255 },
  { codeId: 35, companyCode: 120092110 },
  { codeId: 47, companyCode: 120094086 },
  { codeId: 53, companyCode: 120110170 },
  { codeId: 49, companyCode: 120112189 },
  { codeId: 61, companyCode: 120149878 },
  { codeId: 20, companyCode: 120179711 },
  { codeId: 15, companyCode: 120191471 },
  { codeId: 19, companyCode: 120191852 },
  { codeId: 47, companyCode: 120203525 },
  { codeId: 53, companyCode: 120213448 },
  { codeId: 42, companyCode: 120265669 },
  { codeId: 16, companyCode: 120274141 },
  { codeId: 17, companyCode: 120294553 },
  { codeId: 31, companyCode: 120312275 },
  { codeId: 49, companyCode: 120371341 },
  { codeId: 19, companyCode: 120375891 },
  { codeId: 16, companyCode: 120406340 },
  { codeId: 19, companyCode: 120415738 },
  { codeId: 20, companyCode: 120467425 },
  { codeId: 64, companyCode: 120504795 },
  { codeId: 53, companyCode: 120519345 },
  { codeId: 28, companyCode: 120536985 },
  { codeId: 36, companyCode: 120538993 },
  { codeId: 26, companyCode: 120539180 },
  { codeId: 21, companyCode: 120541256 },
  { codeId: 3, companyCode: 120561668 },
  { codeId: 53, companyCode: 120587613 },
  { codeId: 53, companyCode: 120605292 },
  { codeId: 40, companyCode: 120687923 },
  { codeId: 42, companyCode: 120694017 },
  { codeId: 53, companyCode: 120761035 },
  { codeId: 21, companyCode: 120779752 },
  { codeId: 49, companyCode: 120795386 },
  { codeId: 19, companyCode: 120813140 },
  { codeId: 4, companyCode: 120891331 },
  { codeId: 26, companyCode: 120945836 },
  { codeId: 53, companyCode: 120959622 },
  { codeId: 21, companyCode: 120973771 },
  { codeId: 47, companyCode: 121082286 },
  { codeId: 16, companyCode: 121087066 },
  { codeId: 27, companyCode: 121135536 },
  { codeId: 53, companyCode: 121195138 },
  { codeId: 64, companyCode: 121217823 },
  { codeId: 35, companyCode: 121235846 },
  { codeId: 26, companyCode: 121312253 },
  { codeId: 52, companyCode: 121412378 },
  { codeId: 20, companyCode: 121421612 },
  { codeId: 34, companyCode: 121436468 },
  { codeId: 16, companyCode: 121453617 },
  { codeId: 19, companyCode: 121461489 },
  { codeId: 61, companyCode: 121499212 },
  { codeId: 53, companyCode: 121504969 },
  { codeId: 58, companyCode: 121506443 },
  { codeId: 21, companyCode: 121516213 },
  { codeId: 20, companyCode: 121530939 },
  { codeId: 53, companyCode: 121537150 },
  { codeId: 28, companyCode: 121540470 },
  { codeId: 40, companyCode: 121559766 },
  { codeId: 40, companyCode: 121560544 },
  { codeId: 18, companyCode: 121680013 },
  { codeId: 21, companyCode: 121687335 },
  { codeId: 21, companyCode: 121723265 },
  { codeId: 53, companyCode: 121740371 },
  { codeId: 34, companyCode: 121779698 },
  { codeId: 53, companyCode: 121805367 },
  { codeId: 34, companyCode: 121841598 },
  { codeId: 40, companyCode: 121895336 },
  { codeId: 45, companyCode: 121914345 },
  { codeId: 53, companyCode: 121922783 },
  { codeId: 16, companyCode: 121929571 },
  { codeId: 16, companyCode: 121949830 },
  { codeId: 33, companyCode: 121992536 },
  { codeId: 3, companyCode: 122007945 },
  { codeId: 53, companyCode: 122051895 },
  { codeId: 3, companyCode: 122074011 },
  { codeId: 20, companyCode: 122104764 },
  { codeId: 16, companyCode: 122115636 },
  { codeId: 34, companyCode: 122148547 },
  { codeId: 21, companyCode: 122149453 },
  { codeId: 53, companyCode: 122218294 },
  { codeId: 58, companyCode: 122267971 },
  { codeId: 45, companyCode: 122276269 },
  { codeId: 34, companyCode: 122292120 },
  { codeId: 3, companyCode: 122294281 },
  { codeId: 34, companyCode: 122313333 },
  { codeId: 53, companyCode: 122331018 },
  { codeId: 24, companyCode: 122349354 },
  { codeId: 36, companyCode: 122400425 },
  { codeId: 21, companyCode: 122409036 },
  { codeId: 40, companyCode: 122489769 },
  { codeId: 19, companyCode: 122541027 },
  { codeId: 53, companyCode: 122580368 },
  { codeId: 34, companyCode: 122584427 },
  { codeId: 21, companyCode: 122590280 },
  { codeId: 3, companyCode: 122606350 },
  { codeId: 35, companyCode: 122609275 },
  { codeId: 34, companyCode: 122611155 },
  { codeId: 40, companyCode: 122619426 },
  { codeId: 19, companyCode: 122627864 },
  { codeId: 20, companyCode: 122628966 },
  { codeId: 53, companyCode: 122638693 },
  { codeId: 58, companyCode: 122643345 },
  { codeId: 16, companyCode: 122660451 },
  { codeId: 53, companyCode: 122675492 },
  { codeId: 60, companyCode: 122682152 },
  { codeId: 34, companyCode: 122700636 },
  { codeId: 20, companyCode: 122780988 },
  { codeId: 20, companyCode: 122831849 },
  { codeId: 19, companyCode: 122863277 },
  { codeId: 53, companyCode: 122875621 },
  { codeId: 45, companyCode: 122902698 },
  { codeId: 34, companyCode: 122903266 },
  { codeId: 27, companyCode: 122911747 },
  { codeId: 21, companyCode: 122930295 },
  { codeId: 61, companyCode: 122983846 },
  { codeId: 34, companyCode: 122990844 },
  { codeId: 53, companyCode: 122991227 },
  { codeId: 49, companyCode: 123016363 },
  { codeId: 53, companyCode: 123017084 },
  { codeId: 40, companyCode: 123018186 },
  { codeId: 53, companyCode: 123019473 },
  { codeId: 16, companyCode: 123126824 },
  { codeId: 15, companyCode: 123138036 },
  { codeId: 53, companyCode: 123153668 },
  { codeId: 16, companyCode: 123177224 },
  { codeId: 20, companyCode: 123205392 },
  { codeId: 43, companyCode: 123214256 },
  { codeId: 40, companyCode: 123220686 },
  { codeId: 34, companyCode: 123233228 },
  { codeId: 49, companyCode: 123236480 },
  { codeId: 42, companyCode: 123241470 },
  { codeId: 16, companyCode: 123241851 },
  { codeId: 44, companyCode: 123242953 },
  { codeId: 19, companyCode: 123245725 },
  { codeId: 20, companyCode: 123285787 },
  { codeId: 19, companyCode: 123293353 },
  { codeId: 34, companyCode: 123307568 },
  { codeId: 19, companyCode: 123309619 },
  { codeId: 40, companyCode: 123311641 },
  { codeId: 36, companyCode: 123356526 },
  { codeId: 19, companyCode: 123404234 },
  { codeId: 34, companyCode: 123424984 },
  { codeId: 31, companyCode: 123432212 },
  { codeId: 16, companyCode: 123446870 },
  { codeId: 54, companyCode: 123467284 },
  { codeId: 35, companyCode: 123469716 },
  { codeId: 53, companyCode: 123514314 },
  { codeId: 20, companyCode: 123522033 },
  { codeId: 16, companyCode: 123565814 },
  { codeId: 16, companyCode: 123570619 },
  { codeId: 40, companyCode: 123575541 },
  { codeId: 35, companyCode: 123587853 },
  { codeId: 29, companyCode: 123593564 },
  { codeId: 19, companyCode: 123619742 },
  { codeId: 20, companyCode: 123622877 },
  { codeId: 28, companyCode: 123631164 },
  { codeId: 53, companyCode: 123633891 },
  { codeId: 53, companyCode: 123637612 },
  { codeId: 19, companyCode: 123642036 },
  { codeId: 3, companyCode: 123644425 },
  { codeId: 53, companyCode: 123645146 },
  { codeId: 27, companyCode: 123681039 },
  { codeId: 15, companyCode: 123699756 },
  { codeId: 19, companyCode: 123701716 },
  { codeId: 38, companyCode: 123707893 },
  { codeId: 53, companyCode: 123709944 },
  { codeId: 16, companyCode: 123727771 },
  { codeId: 21, companyCode: 123743786 },
  { codeId: 20, companyCode: 123746177 },
  { codeId: 53, companyCode: 123758140 },
  { codeId: 34, companyCode: 123783542 },
  { codeId: 47, companyCode: 123807331 },
  { codeId: 21, companyCode: 123839293 },
  { codeId: 20, companyCode: 123850941 },
  { codeId: 60, companyCode: 123852230 },
  { codeId: 27, companyCode: 123854968 },
  { codeId: 16, companyCode: 123862491 },
  { codeId: 19, companyCode: 123915741 },
  { codeId: 19, companyCode: 123923122 },
  { codeId: 20, companyCode: 123945880 },
  { codeId: 21, companyCode: 123946644 },
  { codeId: 40, companyCode: 123961219 },
  { codeId: 20, companyCode: 123961938 },
  { codeId: 34, companyCode: 123968683 },
  { codeId: 15, companyCode: 123981435 },
  { codeId: 15, companyCode: 123993747 },
  { codeId: 23, companyCode: 124002740 },
  { codeId: 52, companyCode: 124008818 },
  { codeId: 16, companyCode: 124019451 },
  { codeId: 20, companyCode: 124026645 },
  { codeId: 20, companyCode: 124032018 },
  { codeId: 40, companyCode: 124040075 },
  { codeId: 53, companyCode: 124045389 },
  { codeId: 21, companyCode: 124064731 },
  { codeId: 20, companyCode: 124097980 },
  { codeId: 34, companyCode: 124105594 },
  { codeId: 58, companyCode: 124135580 },
  { codeId: 53, companyCode: 124140613 },
  { codeId: 20, companyCode: 124151823 },
  { codeId: 38, companyCode: 124174811 },
  { codeId: 16, companyCode: 124185640 },
  { codeId: 53, companyCode: 124190064 },
  { codeId: 16, companyCode: 124205947 },
  { codeId: 10, companyCode: 124242550 },
  { codeId: 16, companyCode: 124262243 },
  { codeId: 19, companyCode: 124262439 },
  { codeId: 53, companyCode: 124264828 },
  { codeId: 24, companyCode: 124289824 },
  { codeId: 53, companyCode: 124304589 },
  { codeId: 19, companyCode: 124317273 },
  { codeId: 53, companyCode: 124358665 },
  { codeId: 19, companyCode: 124382584 },
  { codeId: 16, companyCode: 124390346 },
  { codeId: 21, companyCode: 124395279 },
  { codeId: 53, companyCode: 124398193 },
  { codeId: 53, companyCode: 124420031 },
  { codeId: 53, companyCode: 124423522 },
  { codeId: 21, companyCode: 124433064 },
  { codeId: 19, companyCode: 124434885 },
  { codeId: 19, companyCode: 124443215 },
  { codeId: 58, companyCode: 124445180 },
  { codeId: 53, companyCode: 124445757 },
  { codeId: 53, companyCode: 124449392 },
  { codeId: 45, companyCode: 124452189 },
  { codeId: 16, companyCode: 124475939 },
  { codeId: 34, companyCode: 124479236 },
  { codeId: 16, companyCode: 124487521 },
  { codeId: 27, companyCode: 124493951 },
  { codeId: 53, companyCode: 124526419 },
  { codeId: 19, companyCode: 124532468 },
  { codeId: 58, companyCode: 124534519 },
  { codeId: 38, companyCode: 124545729 },
  { codeId: 53, companyCode: 124549898 },
  { codeId: 19, companyCode: 124554888 },
  { codeId: 35, companyCode: 124578825 },
  { codeId: 36, companyCode: 124579012 },
  { codeId: 16, companyCode: 124601875 },
  { codeId: 16, companyCode: 124637939 },
  { codeId: 53, companyCode: 124646183 },
  { codeId: 48, companyCode: 124715067 },
  { codeId: 31, companyCode: 124806937 },
  { codeId: 19, companyCode: 124807996 },
  { codeId: 21, companyCode: 124820171 },
  { codeId: 40, companyCode: 124827721 },
  { codeId: 11, companyCode: 124833051 },
  { codeId: 16, companyCode: 124843693 },
  { codeId: 20, companyCode: 124857860 },
  { codeId: 53, companyCode: 124929092 },
  { codeId: 19, companyCode: 124931734 },
  { codeId: 53, companyCode: 124940174 },
  { codeId: 24, companyCode: 124940740 },
  { codeId: 53, companyCode: 124968857 },
  { codeId: 61, companyCode: 125008415 },
  { codeId: 58, companyCode: 125028784 },
  { codeId: 19, companyCode: 125030479 },
  { codeId: 40, companyCode: 125104132 },
  { codeId: 16, companyCode: 125111511 },
  { codeId: 16, companyCode: 125121391 },
  { codeId: 40, companyCode: 125121968 },
  { codeId: 16, companyCode: 125161681 },
  { codeId: 24, companyCode: 125231852 },
  { codeId: 34, companyCode: 125261991 },
  { codeId: 53, companyCode: 125276922 },
  { codeId: 16, companyCode: 125288287 },
  { codeId: 20, companyCode: 125294564 },
  { codeId: 16, companyCode: 125355533 },
  { codeId: 64, companyCode: 125367998 },
  { codeId: 3, companyCode: 125373360 },
  { codeId: 34, companyCode: 125403437 },
  { codeId: 16, companyCode: 125464098 },
  { codeId: 12, companyCode: 125471858 },
  { codeId: 40, companyCode: 125472383 },
  { codeId: 16, companyCode: 125472426 },
  { codeId: 33, companyCode: 125474434 },
  { codeId: 53, companyCode: 125496092 },
  { codeId: 34, companyCode: 125515525 },
  { codeId: 64, companyCode: 125521955 },
  { codeId: 48, companyCode: 125526735 },
  { codeId: 16, companyCode: 125542935 },
  { codeId: 64, companyCode: 125544562 },
  { codeId: 58, companyCode: 125559265 },
  { codeId: 40, companyCode: 125563534 },
  { codeId: 34, companyCode: 125564789 },
  { codeId: 53, companyCode: 125573838 },
  { codeId: 64, companyCode: 125587777 },
  { codeId: 40, companyCode: 125638257 },
  { codeId: 53, companyCode: 125643585 },
  { codeId: 16, companyCode: 125657752 },
  { codeId: 53, companyCode: 125660734 },
  { codeId: 16, companyCode: 125675241 },
  { codeId: 34, companyCode: 125679834 },
  { codeId: 53, companyCode: 125688274 },
  { codeId: 15, companyCode: 125711171 },
  { codeId: 21, companyCode: 125716528 },
  { codeId: 21, companyCode: 125722381 },
  { codeId: 19, companyCode: 125730524 },
  { codeId: 33, companyCode: 125736897 },
  { codeId: 19, companyCode: 125739854 },
  { codeId: 19, companyCode: 125740632 },
  { codeId: 53, companyCode: 125744278 },
  { codeId: 64, companyCode: 125747420 },
  { codeId: 53, companyCode: 125751995 },
  { codeId: 34, companyCode: 125752944 },
  { codeId: 58, companyCode: 125758630 },
  { codeId: 16, companyCode: 125766730 },
  { codeId: 53, companyCode: 125780737 },
  { codeId: 53, companyCode: 125790845 },
  { codeId: 21, companyCode: 125799075 },
  { codeId: 34, companyCode: 125806349 },
  { codeId: 36, companyCode: 125810956 },
  { codeId: 53, companyCode: 125821785 },
  { codeId: 6, companyCode: 125829141 },
  { codeId: 20, companyCode: 125846258 },
  { codeId: 53, companyCode: 125850146 },
  { codeId: 16, companyCode: 125860069 },
  { codeId: 34, companyCode: 125875523 },
  { codeId: 53, companyCode: 125886167 },
  { codeId: 53, companyCode: 125889081 },
  { codeId: 3, companyCode: 125891495 },
  { codeId: 19, companyCode: 125908286 },
  { codeId: 53, companyCode: 125917716 },
  { codeId: 52, companyCode: 125931146 },
  { codeId: 52, companyCode: 125941592 },
  { codeId: 4, companyCode: 125950837 },
  { codeId: 3, companyCode: 125955040 },
  { codeId: 45, companyCode: 125957397 },
  { codeId: 16, companyCode: 125964776 },
  { codeId: 40, companyCode: 125976935 },
  { codeId: 53, companyCode: 125994424 },
  { codeId: 53, companyCode: 125999738 },
  { codeId: 28, companyCode: 126001039 },
  { codeId: 53, companyCode: 126001181 },
  { codeId: 53, companyCode: 126038151 },
  { codeId: 53, companyCode: 126045726 },
  { codeId: 53, companyCode: 126051056 },
  { codeId: 53, companyCode: 126060639 },
  { codeId: 53, companyCode: 126072755 },
  { codeId: 28, companyCode: 126085973 },
  { codeId: 40, companyCode: 126113999 },
  { codeId: 63, companyCode: 126138842 },
  { codeId: 48, companyCode: 126138995 },
  { codeId: 34, companyCode: 126141781 },
  { codeId: 24, companyCode: 126142011 },
  { codeId: 34, companyCode: 126150264 },
  { codeId: 53, companyCode: 126154095 },
  { codeId: 19, companyCode: 126164812 },
  { codeId: 53, companyCode: 126189438 },
  { codeId: 26, companyCode: 126191275 },
  { codeId: 17, companyCode: 126199731 },
  { codeId: 53, companyCode: 126214724 },
  { codeId: 35, companyCode: 126220620 },
  { codeId: 20, companyCode: 126220816 },
  { codeId: 53, companyCode: 126223698 },
  { codeId: 53, companyCode: 126233272 },
  { codeId: 53, companyCode: 126242136 },
  { codeId: 20, companyCode: 126243238 },
  { codeId: 4, companyCode: 126248356 },
  { codeId: 37, companyCode: 126261599 },
  { codeId: 53, companyCode: 126267666 },
  { codeId: 53, companyCode: 126273181 },
  { codeId: 53, companyCode: 126278919 },
  { codeId: 34, companyCode: 126297271 },
  { codeId: 53, companyCode: 126332480 },
  { codeId: 54, companyCode: 126339464 },
  { codeId: 34, companyCode: 126346658 },
  { codeId: 60, companyCode: 126356951 },
  { codeId: 34, companyCode: 126360316 },
  { codeId: 61, companyCode: 126383838 },
  { codeId: 61, companyCode: 126389481 },
  { codeId: 23, companyCode: 126400643 },
  { codeId: 35, companyCode: 126405619 },
  { codeId: 58, companyCode: 126408686 },
  { codeId: 58, companyCode: 126416829 },
  { codeId: 38, companyCode: 132009752 },
  { codeId: 34, companyCode: 132039891 },
  { codeId: 53, companyCode: 132067684 },
  { codeId: 53, companyCode: 132073395 },
  { codeId: 43, companyCode: 132084071 },
  { codeId: 40, companyCode: 132088511 },
  { codeId: 53, companyCode: 132103276 },
  { codeId: 53, companyCode: 132117062 },
  { codeId: 53, companyCode: 132136372 },
  { codeId: 51, companyCode: 132173171 },
  { codeId: 34, companyCode: 132201382 },
  { codeId: 60, companyCode: 132205975 },
  { codeId: 53, companyCode: 132206696 },
  { codeId: 38, companyCode: 132224032 },
  { codeId: 53, companyCode: 132241487 },
  { codeId: 20, companyCode: 132265958 },
  { codeId: 15, companyCode: 132305413 },
  { codeId: 38, companyCode: 132324723 },
  { codeId: 53, companyCode: 132335171 },
  { codeId: 6, companyCode: 132345137 },
  { codeId: 34, companyCode: 132350312 },
  { codeId: 34, companyCode: 132423365 },
  { codeId: 34, companyCode: 132427196 },
  { codeId: 32, companyCode: 132439693 },
  { codeId: 38, companyCode: 132461070 },
  { codeId: 10, companyCode: 132478543 },
  { codeId: 53, companyCode: 132515618 },
  { codeId: 53, companyCode: 132534732 },
  { codeId: 4, companyCode: 132566018 },
  { codeId: 53, companyCode: 132567981 },
  { codeId: 53, companyCode: 132613681 },
  { codeId: 31, companyCode: 132659091 },
  { codeId: 20, companyCode: 132721949 },
  { codeId: 20, companyCode: 132771051 },
  { codeId: 38, companyCode: 132795718 },
  { codeId: 26, companyCode: 132825937 },
  { codeId: 31, companyCode: 132907124 },
  { codeId: 19, companyCode: 132907277 },
  { codeId: 34, companyCode: 132930660 },
  { codeId: 38, companyCode: 132937110 },
  { codeId: 34, companyCode: 132941685 },
  { codeId: 4, companyCode: 132987857 },
  { codeId: 36, companyCode: 133031118 },
  { codeId: 25, companyCode: 133058841 },
  { codeId: 53, companyCode: 133083481 },
  { codeId: 19, companyCode: 133096938 },
  { codeId: 20, companyCode: 133097463 },
  { codeId: 3, companyCode: 133140587 },
  { codeId: 53, companyCode: 133144646 },
  { codeId: 15, companyCode: 133162669 },
  { codeId: 34, companyCode: 133217546 },
  { codeId: 34, companyCode: 133218071 },
  { codeId: 19, companyCode: 133236518 },
  { codeId: 58, companyCode: 133265217 },
  { codeId: 24, companyCode: 133291678 },
  { codeId: 31, companyCode: 133301147 },
  { codeId: 53, companyCode: 133306112 },
  { codeId: 53, companyCode: 133310915 },
  { codeId: 52, companyCode: 133324092 },
  { codeId: 20, companyCode: 133350062 },
  { codeId: 19, companyCode: 133360366 },
  { codeId: 53, companyCode: 133386126 },
  { codeId: 19, companyCode: 133414337 },
  { codeId: 34, companyCode: 133415581 },
  { codeId: 34, companyCode: 133418353 },
  { codeId: 42, companyCode: 133436180 },
  { codeId: 58, companyCode: 133464354 },
  { codeId: 53, companyCode: 133480935 },
  { codeId: 34, companyCode: 133489165 },
  { codeId: 40, companyCode: 133502520 },
  { codeId: 53, companyCode: 133505979 },
  { codeId: 53, companyCode: 133513698 },
  { codeId: 53, companyCode: 133562841 },
  { codeId: 15, companyCode: 133587314 },
  { codeId: 19, companyCode: 133616246 },
  { codeId: 27, companyCode: 133643122 },
  { codeId: 31, companyCode: 133644943 },
  { codeId: 19, companyCode: 133650654 },
  { codeId: 53, companyCode: 133663349 },
  { codeId: 40, companyCode: 133667027 },
  { codeId: 35, companyCode: 133689066 },
  { codeId: 32, companyCode: 133692429 },
  { codeId: 38, companyCode: 133701287 },
  { codeId: 16, companyCode: 133720597 },
  { codeId: 3, companyCode: 133724460 },
  { codeId: 24, companyCode: 133747263 },
  { codeId: 21, companyCode: 133766573 },
  { codeId: 10, companyCode: 133791822 },
  { codeId: 38, companyCode: 133799712 },
  { codeId: 6, companyCode: 133818917 },
  { codeId: 57, companyCode: 133820373 },
  { codeId: 38, companyCode: 133831626 },
  { codeId: 3, companyCode: 133854233 },
  { codeId: 58, companyCode: 133861427 },
  { codeId: 58, companyCode: 133959095 },
  { codeId: 15, companyCode: 133960788 },
  { codeId: 17, companyCode: 134016521 },
  { codeId: 53, companyCode: 134043365 },
  { codeId: 34, companyCode: 134059355 },
  { codeId: 17, companyCode: 134060133 },
  { codeId: 35, companyCode: 134074453 },
  { codeId: 16, companyCode: 134133229 },
  { codeId: 58, companyCode: 134160639 },
  { codeId: 29, companyCode: 134165942 },
  { codeId: 29, companyCode: 134170932 },
  { codeId: 53, companyCode: 134208094 },
  { codeId: 20, companyCode: 134240494 },
  { codeId: 53, companyCode: 134241824 },
  { codeId: 19, companyCode: 134248765 },
  { codeId: 24, companyCode: 134249529 },
  { codeId: 53, companyCode: 134261855 },
  { codeId: 49, companyCode: 134274354 },
  { codeId: 53, companyCode: 134284462 },
  { codeId: 53, companyCode: 134302565 },
  { codeId: 35, companyCode: 134308447 },
  { codeId: 16, companyCode: 134352810 },
  { codeId: 37, companyCode: 134355169 },
  { codeId: 31, companyCode: 134366945 },
  { codeId: 53, companyCode: 134372275 },
  { codeId: 53, companyCode: 134378538 },
  { codeId: 24, companyCode: 134403596 },
  { codeId: 53, companyCode: 134409282 },
  { codeId: 19, companyCode: 134424238 },
  { codeId: 16, companyCode: 134432295 },
  { codeId: 19, companyCode: 134436920 },
  { codeId: 21, companyCode: 134443886 },
  { codeId: 7, companyCode: 134481787 },
  { codeId: 53, companyCode: 134482921 },
  { codeId: 16, companyCode: 134487092 },
  { codeId: 21, companyCode: 134489143 },
  { codeId: 4, companyCode: 134505238 },
  { codeId: 53, companyCode: 134511515 },
  { codeId: 3, companyCode: 134517692 },
  { codeId: 40, companyCode: 134518118 },
  { codeId: 35, companyCode: 134543139 },
  { codeId: 11, companyCode: 134546053 },
  { codeId: 31, companyCode: 134555440 },
  { codeId: 27, companyCode: 134600387 },
  { codeId: 40, companyCode: 134600768 },
  { codeId: 53, companyCode: 134621552 },
  { codeId: 19, companyCode: 134640677 },
  { codeId: 49, companyCode: 134656329 },
  { codeId: 53, companyCode: 134665520 },
  { codeId: 16, companyCode: 134674156 },
  { codeId: 10, companyCode: 134693466 },
  { codeId: 53, companyCode: 134698431 },
  { codeId: 53, companyCode: 134709493 },
  { codeId: 53, companyCode: 134709536 },
  { codeId: 58, companyCode: 134716349 },
  { codeId: 11, companyCode: 134721524 },
  { codeId: 61, companyCode: 134738192 },
  { codeId: 16, companyCode: 134743410 },
  { codeId: 38, companyCode: 134761390 },
  { codeId: 53, companyCode: 134777957 },
  { codeId: 16, companyCode: 134778144 },
  { codeId: 38, companyCode: 134792293 },
  { codeId: 21, companyCode: 134793438 },
  { codeId: 3, companyCode: 134796014 },
  { codeId: 45, companyCode: 134816659 },
  { codeId: 58, companyCode: 134822174 },
  { codeId: 16, companyCode: 134833231 },
  { codeId: 21, companyCode: 134841484 },
  { codeId: 19, companyCode: 134842390 },
  { codeId: 19, companyCode: 134842771 },
  { codeId: 43, companyCode: 134870030 },
  { codeId: 34, companyCode: 134876446 },
  { codeId: 53, companyCode: 134879022 },
  { codeId: 38, companyCode: 134882342 },
  { codeId: 3, companyCode: 134900445 },
  { codeId: 38, companyCode: 134902453 },
  { codeId: 33, companyCode: 134913663 },
  { codeId: 19, companyCode: 134919545 },
  { codeId: 53, companyCode: 134926543 },
  { codeId: 25, companyCode: 134931914 },
  { codeId: 11, companyCode: 134951411 },
  { codeId: 6, companyCode: 134958886 },
  { codeId: 53, companyCode: 134961868 },
  { codeId: 21, companyCode: 134966648 },
  { codeId: 53, companyCode: 134985958 },
  { codeId: 53, companyCode: 135003434 },
  { codeId: 53, companyCode: 135007831 },
  { codeId: 42, companyCode: 135020889 },
  { codeId: 21, companyCode: 135026618 },
  { codeId: 40, companyCode: 135040614 },
  { codeId: 9, companyCode: 135058950 },
  { codeId: 53, companyCode: 135067433 },
  { codeId: 19, companyCode: 135068916 },
  { codeId: 40, companyCode: 135074965 },
  { codeId: 53, companyCode: 135104999 },
  { codeId: 21, companyCode: 135129657 },
  { codeId: 34, companyCode: 135133730 },
  { codeId: 20, companyCode: 135137223 },
  { codeId: 33, companyCode: 135160955 },
  { codeId: 34, companyCode: 135166118 },
  { codeId: 34, companyCode: 135167177 },
  { codeId: 26, companyCode: 135168845 },
  { codeId: 53, companyCode: 135177666 },
  { codeId: 16, companyCode: 135181554 },
  { codeId: 16, companyCode: 135188538 },
  { codeId: 58, companyCode: 135215070 },
  { codeId: 53, companyCode: 135217121 },
  { codeId: 3, companyCode: 135240515 },
  { codeId: 19, companyCode: 135242338 },
  { codeId: 53, companyCode: 135243244 },
  { codeId: 53, companyCode: 135249464 },
  { codeId: 11, companyCode: 135250623 },
  { codeId: 53, companyCode: 135251910 },
  { codeId: 16, companyCode: 135264758 },
  { codeId: 42, companyCode: 135275391 },
  { codeId: 53, companyCode: 135275772 },
  { codeId: 16, companyCode: 135276917 },
  { codeId: 3, companyCode: 135290870 },
  { codeId: 3, companyCode: 135440416 },
  { codeId: 24, companyCode: 135473327 },
  { codeId: 58, companyCode: 135480282 },
  { codeId: 20, companyCode: 135495747 },
  { codeId: 53, companyCode: 135499959 },
  { codeId: 53, companyCode: 135539033 },
  { codeId: 58, companyCode: 135542168 },
  { codeId: 26, companyCode: 135550834 },
  { codeId: 40, companyCode: 135551936 },
  { codeId: 31, companyCode: 135554131 },
  { codeId: 16, companyCode: 135556673 },
  { codeId: 34, companyCode: 135562384 },
  { codeId: 53, companyCode: 135575111 },
  { codeId: 20, companyCode: 135579095 },
  { codeId: 34, companyCode: 135584313 },
  { codeId: 53, companyCode: 135596582 },
  { codeId: 33, companyCode: 135599735 },
  { codeId: 58, companyCode: 135606966 },
  { codeId: 11, companyCode: 135607349 },
  { codeId: 15, companyCode: 135628286 },
  { codeId: 21, companyCode: 135631268 },
  { codeId: 16, companyCode: 135631649 },
  { codeId: 35, companyCode: 135631791 },
  { codeId: 34, companyCode: 135634182 },
  { codeId: 16, companyCode: 135645392 },
  { codeId: 16, companyCode: 135657366 },
  { codeId: 38, companyCode: 135663839 },
  { codeId: 19, companyCode: 135666415 },
  { codeId: 34, companyCode: 135668957 },
  { codeId: 20, companyCode: 135675193 },
  { codeId: 20, companyCode: 135675421 },
  { codeId: 53, companyCode: 135676480 },
  { codeId: 64, companyCode: 135685910 },
  { codeId: 53, companyCode: 135690291 },
  { codeId: 53, companyCode: 135699283 },
  { codeId: 61, companyCode: 135699511 },
  { codeId: 53, companyCode: 135699664 },
  { codeId: 24, companyCode: 135717614 },
  { codeId: 38, companyCode: 135725867 },
  { codeId: 53, companyCode: 135736739 },
  { codeId: 34, companyCode: 135739991 },
  { codeId: 53, companyCode: 135740627 },
  { codeId: 40, companyCode: 135744262 },
  { codeId: 60, companyCode: 135765057 },
  { codeId: 45, companyCode: 135765623 },
  { codeId: 34, companyCode: 135780874 },
  { codeId: 16, companyCode: 135797813 },
  { codeId: 58, companyCode: 135800832 },
  { codeId: 53, companyCode: 135806671 },
  { codeId: 11, companyCode: 135808494 },
  { codeId: 36, companyCode: 135810940 },
  { codeId: 34, companyCode: 135814771 },
  { codeId: 19, companyCode: 135823635 },
  { codeId: 53, companyCode: 135829289 },
  { codeId: 21, companyCode: 135847497 },
  { codeId: 16, companyCode: 135850130 },
  { codeId: 19, companyCode: 135852868 },
  { codeId: 34, companyCode: 135863544 },
  { codeId: 53, companyCode: 135871110 },
  { codeId: 45, companyCode: 135878628 },
  { codeId: 34, companyCode: 135880127 },
  { codeId: 19, companyCode: 135887634 },
  { codeId: 24, companyCode: 135888921 },
  { codeId: 24, companyCode: 135896640 },
  { codeId: 58, companyCode: 135897557 },
  { codeId: 21, companyCode: 135899184 },
  { codeId: 3, companyCode: 135907179 },
  { codeId: 40, companyCode: 135907211 },
  { codeId: 52, companyCode: 135922996 },
  { codeId: 31, companyCode: 135928878 },
  { codeId: 19, companyCode: 135931130 },
  { codeId: 16, companyCode: 135936597 },
  { codeId: 61, companyCode: 135958526 },
  { codeId: 26, companyCode: 135962567 },
  { codeId: 53, companyCode: 135969889 },
  { codeId: 16, companyCode: 135974879 },
  { codeId: 6, companyCode: 135976734 },
  { codeId: 21, companyCode: 135979278 },
  { codeId: 19, companyCode: 135979844 },
  { codeId: 26, companyCode: 135981724 },
  { codeId: 58, companyCode: 136024011 },
  { codeId: 58, companyCode: 136025070 },
  { codeId: 58, companyCode: 136033551 },
  { codeId: 32, companyCode: 136036519 },
  { codeId: 21, companyCode: 136044195 },
  { codeId: 53, companyCode: 136046812 },
  { codeId: 49, companyCode: 136051236 },
  { codeId: 19, companyCode: 136051574 },
  { codeId: 49, companyCode: 136053963 },
  { codeId: 3, companyCode: 136057456 },
  { codeId: 34, companyCode: 136057837 },
  { codeId: 16, companyCode: 136061344 },
  { codeId: 19, companyCode: 140022870 },
  { codeId: 53, companyCode: 140060248 },
  { codeId: 57, companyCode: 140064645 },
  { codeId: 57, companyCode: 140067755 },
  { codeId: 57, companyCode: 140103151 },
  { codeId: 57, companyCode: 140135341 },
  { codeId: 57, companyCode: 140155415 },
  { codeId: 57, companyCode: 140167727 },
  { codeId: 57, companyCode: 140245389 },
  { codeId: 53, companyCode: 140253140 },
  { codeId: 57, companyCode: 140355654 },
  { codeId: 40, companyCode: 140381058 },
  { codeId: 57, companyCode: 140419886 },
  { codeId: 64, companyCode: 140438324 },
  { codeId: 53, companyCode: 140455398 },
  { codeId: 20, companyCode: 140492382 },
  { codeId: 20, companyCode: 140504621 },
  { codeId: 58, companyCode: 140506825 },
  { codeId: 60, companyCode: 140513257 },
  { codeId: 38, companyCode: 140516029 },
  { codeId: 40, companyCode: 140518037 },
  { codeId: 57, companyCode: 140521161 },
  { codeId: 49, companyCode: 140528679 },
  { codeId: 15, companyCode: 140566765 },
  { codeId: 19, companyCode: 140568054 },
  { codeId: 58, companyCode: 140574299 },
  { codeId: 12, companyCode: 140582246 },
  { codeId: 57, companyCode: 140623860 },
  { codeId: 4, companyCode: 140647950 },
  { codeId: 53, companyCode: 140656052 },
  { codeId: 16, companyCode: 140661238 },
  { codeId: 16, companyCode: 140666018 },
  { codeId: 60, companyCode: 140683462 },
  { codeId: 19, companyCode: 140684183 },
  { codeId: 57, companyCode: 140724172 },
  { codeId: 19, companyCode: 140749026 },
  { codeId: 35, companyCode: 140768489 },
  { codeId: 53, companyCode: 140790247 },
  { codeId: 57, companyCode: 140801918 },
  { codeId: 6, companyCode: 140875459 },
  { codeId: 16, companyCode: 140955542 },
  { codeId: 20, companyCode: 140964025 },
  { codeId: 49, companyCode: 140992537 },
  { codeId: 21, companyCode: 141011268 },
  { codeId: 53, companyCode: 141062234 },
  { codeId: 57, companyCode: 141063140 },
  { codeId: 45, companyCode: 141074350 },
  { codeId: 4, companyCode: 141089772 },
  { codeId: 53, companyCode: 141183296 },
  { codeId: 13, companyCode: 141195074 },
  { codeId: 50, companyCode: 141237986 },
  { codeId: 57, companyCode: 141242638 },
  { codeId: 46, companyCode: 141268017 },
  { codeId: 20, companyCode: 141271675 },
  { codeId: 30, companyCode: 141280496 },
  { codeId: 53, companyCode: 141314774 },
  { codeId: 38, companyCode: 141315157 },
  { codeId: 19, companyCode: 141376950 },
  { codeId: 57, companyCode: 141390619 },
  { codeId: 4, companyCode: 141392812 },
  { codeId: 57, companyCode: 141453172 },
  { codeId: 53, companyCode: 141455223 },
  { codeId: 53, companyCode: 141455561 },
  { codeId: 57, companyCode: 141511946 },
  { codeId: 40, companyCode: 141524826 },
  { codeId: 57, companyCode: 141525351 },
  { codeId: 57, companyCode: 141525928 },
  { codeId: 53, companyCode: 141557737 },
  { codeId: 19, companyCode: 141570827 },
  { codeId: 19, companyCode: 141583511 },
  { codeId: 34, companyCode: 141597450 },
  { codeId: 19, companyCode: 141597984 },
  { codeId: 16, companyCode: 141602488 },
  { codeId: 16, companyCode: 141631187 },
  { codeId: 53, companyCode: 141642582 },
  { codeId: 58, companyCode: 141669825 },
  { codeId: 34, companyCode: 141681432 },
  { codeId: 61, companyCode: 141686027 },
  { codeId: 57, companyCode: 141703366 },
  { codeId: 35, companyCode: 141712372 },
  { codeId: 34, companyCode: 141725786 },
  { codeId: 57, companyCode: 141748055 },
  { codeId: 24, companyCode: 141753426 },
  { codeId: 34, companyCode: 141765357 },
  { codeId: 16, companyCode: 141765923 },
  { codeId: 4, companyCode: 141776414 },
  { codeId: 53, companyCode: 141805150 },
  { codeId: 34, companyCode: 141805531 },
  { codeId: 57, companyCode: 141811242 },
  { codeId: 42, companyCode: 141815116 },
  { codeId: 16, companyCode: 141828149 },
  { codeId: 19, companyCode: 141830367 },
  { codeId: 21, companyCode: 141844534 },
  { codeId: 16, companyCode: 141870995 },
  { codeId: 16, companyCode: 141907326 },
  { codeId: 53, companyCode: 141912316 },
  { codeId: 57, companyCode: 141915426 },
  { codeId: 20, companyCode: 141937650 },
  { codeId: 57, companyCode: 141960859 },
  { codeId: 49, companyCode: 141982898 },
  { codeId: 19, companyCode: 141986761 },
  { codeId: 53, companyCode: 142009932 },
  { codeId: 19, companyCode: 142010525 },
  { codeId: 53, companyCode: 142034953 },
  { codeId: 21, companyCode: 142035336 },
  { codeId: 40, companyCode: 142063652 },
  { codeId: 57, companyCode: 142071752 },
  { codeId: 34, companyCode: 142076728 },
  { codeId: 53, companyCode: 142079838 },
  { codeId: 64, companyCode: 142095842 },
  { codeId: 34, companyCode: 142098386 },
  { codeId: 53, companyCode: 142107091 },
  { codeId: 21, companyCode: 142108236 },
  { codeId: 48, companyCode: 142111218 },
  { codeId: 57, companyCode: 142129554 },
  { codeId: 11, companyCode: 142133061 },
  { codeId: 57, companyCode: 142136214 },
  { codeId: 19, companyCode: 142145220 },
  { codeId: 53, companyCode: 142158068 },
  { codeId: 57, companyCode: 142158972 },
  { codeId: 34, companyCode: 142162294 },
  { codeId: 50, companyCode: 142166353 },
  { codeId: 57, companyCode: 142172445 },
  { codeId: 57, companyCode: 142177182 },
  { codeId: 58, companyCode: 144083792 },
  { codeId: 38, companyCode: 144139433 },
  { codeId: 34, companyCode: 144217280 },
  { codeId: 15, companyCode: 144263858 },
  { codeId: 53, companyCode: 144264230 },
  { codeId: 53, companyCode: 144333114 },
  { codeId: 3, companyCode: 144360296 },
  { codeId: 35, companyCode: 144411495 },
  { codeId: 47, companyCode: 144446119 },
  { codeId: 53, companyCode: 144466488 },
  { codeId: 40, companyCode: 144482492 },
  { codeId: 53, companyCode: 144578423 },
  { codeId: 53, companyCode: 144592768 },
  { codeId: 38, companyCode: 144634427 },
  { codeId: 16, companyCode: 144663126 },
  { codeId: 3, companyCode: 144667719 },
  { codeId: 34, companyCode: 144675776 },
  { codeId: 47, companyCode: 144690874 },
  { codeId: 53, companyCode: 144723484 },
  { codeId: 20, companyCode: 144729170 },
  { codeId: 45, companyCode: 144749778 },
  { codeId: 58, companyCode: 144839631 },
  { codeId: 42, companyCode: 144855112 },
  { codeId: 20, companyCode: 144932815 },
  { codeId: 20, companyCode: 144939037 },
  { codeId: 19, companyCode: 144981883 },
  { codeId: 16, companyCode: 145019942 },
  { codeId: 53, companyCode: 145073477 },
  { codeId: 19, companyCode: 145130572 },
  { codeId: 58, companyCode: 145130953 },
  { codeId: 19, companyCode: 145150799 },
  { codeId: 53, companyCode: 145162424 },
  { codeId: 16, companyCode: 145163711 },
  { codeId: 53, companyCode: 145165534 },
  { codeId: 15, companyCode: 145167357 },
  { codeId: 16, companyCode: 145179669 },
  { codeId: 53, companyCode: 145181168 },
  { codeId: 34, companyCode: 145196056 },
  { codeId: 19, companyCode: 145206582 },
  { codeId: 35, companyCode: 145241220 },
  { codeId: 53, companyCode: 145262887 },
  { codeId: 34, companyCode: 145273225 },
  { codeId: 45, companyCode: 145278724 },
  { codeId: 53, companyCode: 145279979 },
  { codeId: 34, companyCode: 145289368 },
  { codeId: 53, companyCode: 145292688 },
  { codeId: 53, companyCode: 145324577 },
  { codeId: 53, companyCode: 145331618 },
  { codeId: 21, companyCode: 145342632 },
  { codeId: 21, companyCode: 145354378 },
  { codeId: 53, companyCode: 145371527 },
  { codeId: 53, companyCode: 145374594 },
  { codeId: 33, companyCode: 145376111 },
  { codeId: 53, companyCode: 145385313 },
  { codeId: 53, companyCode: 145387855 },
  { codeId: 15, companyCode: 145393751 },
  { codeId: 53, companyCode: 145395040 },
  { codeId: 3, companyCode: 145405577 },
  { codeId: 53, companyCode: 145407770 },
  { codeId: 38, companyCode: 145417355 },
  { codeId: 14, companyCode: 145420718 },
  { codeId: 53, companyCode: 145439622 },
  { codeId: 20, companyCode: 145453052 },
  { codeId: 14, companyCode: 145457830 },
  { codeId: 53, companyCode: 145458747 },
  { codeId: 16, companyCode: 145465179 },
  { codeId: 54, companyCode: 145466128 },
  { codeId: 16, companyCode: 145466651 },
  { codeId: 42, companyCode: 145479684 },
  { codeId: 3, companyCode: 145544018 },
  { codeId: 16, companyCode: 145547651 },
  { codeId: 21, companyCode: 145548034 },
  { codeId: 19, companyCode: 145557574 },
  { codeId: 33, companyCode: 145571766 },
  { codeId: 53, companyCode: 145582442 },
  { codeId: 3, companyCode: 145582976 },
  { codeId: 35, companyCode: 145595137 },
  { codeId: 53, companyCode: 145606950 },
  { codeId: 38, companyCode: 145624592 },
  { codeId: 40, companyCode: 145663442 },
  { codeId: 40, companyCode: 145744638 },
  { codeId: 16, companyCode: 145745697 },
  { codeId: 20, companyCode: 145760457 },
  { codeId: 53, companyCode: 145768913 },
  { codeId: 61, companyCode: 145787276 },
  { codeId: 20, companyCode: 145787657 },
  { codeId: 53, companyCode: 145788225 },
  { codeId: 16, companyCode: 145793368 },
  { codeId: 3, companyCode: 145838137 },
  { codeId: 53, companyCode: 145840736 },
  { codeId: 53, companyCode: 145842559 },
  { codeId: 53, companyCode: 145845473 },
  { codeId: 35, companyCode: 145847296 },
  { codeId: 16, companyCode: 145866072 },
  { codeId: 15, companyCode: 145867217 },
  { codeId: 49, companyCode: 145890187 },
  { codeId: 21, companyCode: 145910526 },
  { codeId: 53, companyCode: 147001291 },
  { codeId: 34, companyCode: 147012163 },
  { codeId: 20, companyCode: 147021550 },
  { codeId: 43, companyCode: 147027966 },
  { codeId: 6, companyCode: 147031669 },
  { codeId: 34, companyCode: 147032618 },
  { codeId: 35, companyCode: 147038795 },
  { codeId: 15, companyCode: 147058869 },
  { codeId: 19, companyCode: 147062419 },
  { codeId: 19, companyCode: 147086466 },
  { codeId: 38, companyCode: 147092024 },
  { codeId: 20, companyCode: 147094185 },
  { codeId: 34, companyCode: 147095287 },
  { codeId: 34, companyCode: 147099684 },
  { codeId: 19, companyCode: 147100161 },
  { codeId: 49, companyCode: 147125734 },
  { codeId: 38, companyCode: 147128278 },
  { codeId: 3, companyCode: 147143223 },
  { codeId: 34, companyCode: 147143942 },
  { codeId: 20, companyCode: 147162533 },
  { codeId: 45, companyCode: 147171016 },
  { codeId: 34, companyCode: 147173558 },
  { codeId: 34, companyCode: 147190130 },
  { codeId: 40, companyCode: 147203777 },
  { codeId: 16, companyCode: 147213928 },
  { codeId: 15, companyCode: 147217563 },
  { codeId: 34, companyCode: 147224561 },
  { codeId: 19, companyCode: 147227529 },
  { codeId: 43, companyCode: 147231755 },
  { codeId: 60, companyCode: 147248313 },
  { codeId: 49, companyCode: 147290255 },
  { codeId: 55, companyCode: 147300781 },
  { codeId: 34, companyCode: 147310028 },
  { codeId: 34, companyCode: 147324871 },
  { codeId: 34, companyCode: 147341792 },
  { codeId: 34, companyCode: 147348438 },
  { codeId: 3, companyCode: 147389787 },
  { codeId: 38, companyCode: 147397691 },
  { codeId: 40, companyCode: 147401093 },
  { codeId: 19, companyCode: 147421352 },
  { codeId: 38, companyCode: 147434951 },
  { codeId: 49, companyCode: 147441764 },
  { codeId: 38, companyCode: 147464567 },
  { codeId: 34, companyCode: 147517589 },
  { codeId: 38, companyCode: 147538569 },
  { codeId: 38, companyCode: 147579612 },
  { codeId: 19, companyCode: 147583272 },
  { codeId: 11, companyCode: 147669052 },
  { codeId: 34, companyCode: 147671270 },
  { codeId: 53, companyCode: 147706465 },
  { codeId: 47, companyCode: 147738655 },
  { codeId: 34, companyCode: 147781731 },
  { codeId: 58, companyCode: 147822822 },
  { codeId: 34, companyCode: 147829382 },
  { codeId: 53, companyCode: 147863452 },
  { codeId: 34, companyCode: 147955858 },
  { codeId: 34, companyCode: 147996488 },
  { codeId: 19, companyCode: 148042138 },
  { codeId: 38, companyCode: 148050042 },
  { codeId: 53, companyCode: 148051482 },
  { codeId: 53, companyCode: 148052627 },
  { codeId: 19, companyCode: 148055922 },
  { codeId: 43, companyCode: 148063794 },
  { codeId: 16, companyCode: 148078497 },
  { codeId: 32, companyCode: 148083868 },
  { codeId: 33, companyCode: 148084240 },
  { codeId: 38, companyCode: 148094359 },
  { codeId: 4, companyCode: 148107080 },
  { codeId: 20, companyCode: 148115757 },
  { codeId: 19, companyCode: 148128975 },
  { codeId: 38, companyCode: 148131423 },
  { codeId: 19, companyCode: 148143692 },
  { codeId: 34, companyCode: 148149955 },
  { codeId: 3, companyCode: 148150929 },
  { codeId: 53, companyCode: 148153843 },
  { codeId: 53, companyCode: 148153996 },
  { codeId: 38, companyCode: 148156768 },
  { codeId: 53, companyCode: 148187475 },
  { codeId: 19, companyCode: 148190119 },
  { codeId: 20, companyCode: 148194135 },
  { codeId: 34, companyCode: 148198913 },
  { codeId: 53, companyCode: 148216635 },
  { codeId: 32, companyCode: 148236132 },
  { codeId: 3, companyCode: 148236470 },
  { codeId: 64, companyCode: 148272744 },
  { codeId: 16, companyCode: 148284718 },
  { codeId: 34, companyCode: 148322122 },
  { codeId: 19, companyCode: 148332383 },
  { codeId: 45, companyCode: 148363514 },
  { codeId: 16, companyCode: 148371614 },
  { codeId: 35, companyCode: 148384832 },
  { codeId: 34, companyCode: 148392747 },
  { codeId: 3, companyCode: 148395661 },
  { codeId: 34, companyCode: 148396578 },
  { codeId: 64, companyCode: 148411941 },
  { codeId: 49, companyCode: 148427212 },
  { codeId: 53, companyCode: 148432889 },
  { codeId: 3, companyCode: 148434744 },
  { codeId: 53, companyCode: 148448530 },
  { codeId: 9, companyCode: 148451131 },
  { codeId: 53, companyCode: 148497260 },
  { codeId: 33, companyCode: 148500475 },
  { codeId: 3, companyCode: 148501196 },
  { codeId: 53, companyCode: 148504872 },
  { codeId: 53, companyCode: 148507797 },
  { codeId: 34, companyCode: 148516846 },
  { codeId: 17, companyCode: 148521074 },
  { codeId: 15, companyCode: 148523463 },
  { codeId: 58, companyCode: 148535394 },
  { codeId: 58, companyCode: 149525645 },
  { codeId: 16, companyCode: 149547684 },
  { codeId: 27, companyCode: 149562782 },
  { codeId: 34, companyCode: 149625421 },
  { codeId: 27, companyCode: 149692740 },
  { codeId: 16, companyCode: 149708397 },
  { codeId: 19, companyCode: 149717784 },
  { codeId: 40, companyCode: 149720232 },
  { codeId: 3, companyCode: 149723538 },
  { codeId: 19, companyCode: 149733112 },
  { codeId: 53, companyCode: 149751135 },
  { codeId: 47, companyCode: 149790661 },
  { codeId: 53, companyCode: 149887466 },
  { codeId: 42, companyCode: 149931539 },
  { codeId: 3, companyCode: 149938099 },
  { codeId: 60, companyCode: 149947714 },
  { codeId: 10, companyCode: 149955967 },
  { codeId: 19, companyCode: 149970346 },
  { codeId: 3, companyCode: 150062817 },
  { codeId: 33, companyCode: 150063157 },
  { codeId: 19, companyCode: 150090414 },
  { codeId: 33, companyCode: 150134768 },
  { codeId: 16, companyCode: 150142672 },
  { codeId: 34, companyCode: 150156088 },
  { codeId: 34, companyCode: 150162212 },
  { codeId: 16, companyCode: 150163652 },
  { codeId: 20, companyCode: 151031130 },
  { codeId: 34, companyCode: 151145760 },
  { codeId: 34, companyCode: 151150227 },
  { codeId: 20, companyCode: 151156970 },
  { codeId: 60, companyCode: 151214459 },
  { codeId: 10, companyCode: 151223650 },
  { codeId: 16, companyCode: 151240048 },
  { codeId: 19, companyCode: 151293022 },
  { codeId: 21, companyCode: 151296285 },
  { codeId: 40, companyCode: 151319168 },
  { codeId: 53, companyCode: 151329657 },
  { codeId: 57, companyCode: 151335891 },
  { codeId: 53, companyCode: 151386149 },
  { codeId: 45, companyCode: 151410148 },
  { codeId: 16, companyCode: 151416749 },
  { codeId: 51, companyCode: 151425755 },
  { codeId: 21, companyCode: 151440853 },
  { codeId: 16, companyCode: 151452250 },
  { codeId: 16, companyCode: 151454116 },
  { codeId: 34, companyCode: 151456277 },
  { codeId: 3, companyCode: 151466232 },
  { codeId: 11, companyCode: 151473383 },
  { codeId: 61, companyCode: 151479265 },
  { codeId: 19, companyCode: 152003664 },
  { codeId: 4, companyCode: 152087922 },
  { codeId: 53, companyCode: 152092499 },
  { codeId: 53, companyCode: 152403651 },
  { codeId: 3, companyCode: 152623245 },
  { codeId: 53, companyCode: 152658164 },
  { codeId: 60, companyCode: 152697886 },
  { codeId: 53, companyCode: 152701516 },
  { codeId: 61, companyCode: 152703524 },
  { codeId: 4, companyCode: 152760920 },
  { codeId: 51, companyCode: 152768582 },
  { codeId: 19, companyCode: 152833973 },
  { codeId: 60, companyCode: 152840633 },
  { codeId: 61, companyCode: 152903578 },
  { codeId: 58, companyCode: 152976245 },
  { codeId: 39, companyCode: 153009143 },
  { codeId: 20, companyCode: 153026826 },
  { codeId: 26, companyCode: 153040637 },
  { codeId: 19, companyCode: 153050211 },
  { codeId: 40, companyCode: 153074073 },
  { codeId: 60, companyCode: 153251171 },
  { codeId: 24, companyCode: 153251214 },
  { codeId: 58, companyCode: 153276025 },
  { codeId: 3, companyCode: 153656515 },
  { codeId: 17, companyCode: 153679122 },
  { codeId: 16, companyCode: 153682442 },
  { codeId: 16, companyCode: 153700164 },
  { codeId: 53, companyCode: 153702749 },
  { codeId: 34, companyCode: 153703655 },
  { codeId: 6, companyCode: 153719798 },
  { codeId: 60, companyCode: 153720195 },
  { codeId: 16, companyCode: 153722627 },
  { codeId: 19, companyCode: 153725694 },
  { codeId: 19, companyCode: 153731633 },
  { codeId: 37, companyCode: 153734558 },
  { codeId: 20, companyCode: 153735083 },
  { codeId: 19, companyCode: 153737091 },
  { codeId: 20, companyCode: 153740269 },
  { codeId: 19, companyCode: 153744328 },
  { codeId: 60, companyCode: 154112751 },
  { codeId: 3, companyCode: 154115523 },
  { codeId: 19, companyCode: 154147670 },
  { codeId: 19, companyCode: 154156915 },
  { codeId: 16, companyCode: 154161481 },
  { codeId: 19, companyCode: 154161524 },
  { codeId: 24, companyCode: 154167210 },
  { codeId: 35, companyCode: 154188681 },
  { codeId: 20, companyCode: 154203521 },
  { codeId: 19, companyCode: 154251530 },
  { codeId: 16, companyCode: 154271942 },
  { codeId: 16, companyCode: 154272478 },
  { codeId: 40, companyCode: 154284594 },
  { codeId: 53, companyCode: 154319821 },
  { codeId: 16, companyCode: 154329210 },
  { codeId: 53, companyCode: 154332879 },
  { codeId: 49, companyCode: 154333251 },
  { codeId: 19, companyCode: 154704169 },
  { codeId: 35, companyCode: 154709091 },
  { codeId: 3, companyCode: 154742821 },
  { codeId: 61, companyCode: 154799441 },
  { codeId: 60, companyCode: 154866655 },
  { codeId: 16, companyCode: 155294919 },
  { codeId: 16, companyCode: 155299122 },
  { codeId: 34, companyCode: 155306353 },
  { codeId: 16, companyCode: 155314072 },
  { codeId: 3, companyCode: 155316842 },
  { codeId: 16, companyCode: 155475129 },
  { codeId: 60, companyCode: 155513971 },
  { codeId: 3, companyCode: 155576498 },
  { codeId: 16, companyCode: 155597510 },
  { codeId: 16, companyCode: 155604556 },
  { codeId: 3, companyCode: 155605081 },
  { codeId: 16, companyCode: 155626780 },
  { codeId: 51, companyCode: 155634880 },
  { codeId: 16, companyCode: 155635982 },
  { codeId: 16, companyCode: 155637271 },
  { codeId: 42, companyCode: 155929959 },
  { codeId: 21, companyCode: 155937144 },
  { codeId: 21, companyCode: 155950049 },
  { codeId: 33, companyCode: 155967130 },
  { codeId: 16, companyCode: 156515249 },
  { codeId: 3, companyCode: 156581128 },
  { codeId: 34, companyCode: 156587533 },
  { codeId: 19, companyCode: 156591574 },
  { codeId: 16, companyCode: 156605974 },
  { codeId: 16, companyCode: 156607459 },
  { codeId: 6, companyCode: 156628243 },
  { codeId: 21, companyCode: 156637064 },
  { codeId: 3, companyCode: 156663949 },
  { codeId: 16, companyCode: 156665238 },
  { codeId: 24, companyCode: 156667399 },
  { codeId: 53, companyCode: 156667584 },
  { codeId: 51, companyCode: 156667812 },
  { codeId: 20, companyCode: 156671668 },
  { codeId: 3, companyCode: 156676633 },
  { codeId: 53, companyCode: 156716241 },
  { codeId: 3, companyCode: 156718630 },
  { codeId: 60, companyCode: 156720859 },
  { codeId: 53, companyCode: 156725596 },
  { codeId: 60, companyCode: 156737189 },
  { codeId: 19, companyCode: 156871035 },
  { codeId: 17, companyCode: 156876534 },
  { codeId: 19, companyCode: 156894361 },
  { codeId: 16, companyCode: 156915955 },
  { codeId: 16, companyCode: 157005345 },
  { codeId: 61, companyCode: 157015791 },
  { codeId: 16, companyCode: 157021698 },
  { codeId: 16, companyCode: 157022070 },
  { codeId: 3, companyCode: 157022832 },
  { codeId: 16, companyCode: 157024655 },
  { codeId: 16, companyCode: 157035527 },
  { codeId: 20, companyCode: 157531199 },
  { codeId: 20, companyCode: 157534722 },
  { codeId: 3, companyCode: 157547221 },
  { codeId: 19, companyCode: 157548095 },
  { codeId: 19, companyCode: 157593375 },
  { codeId: 9, companyCode: 157602461 },
  { codeId: 4, companyCode: 157609826 },
  { codeId: 3, companyCode: 157625264 },
  { codeId: 16, companyCode: 157634651 },
  { codeId: 3, companyCode: 157645480 },
  { codeId: 60, companyCode: 157687636 },
  { codeId: 53, companyCode: 157689644 },
  { codeId: 35, companyCode: 157693151 },
  { codeId: 19, companyCode: 157940388 },
  { codeId: 16, companyCode: 157940573 },
  { codeId: 20, companyCode: 158113548 },
  { codeId: 58, companyCode: 158139450 },
  { codeId: 19, companyCode: 158147027 },
  { codeId: 16, companyCode: 158183258 },
  { codeId: 16, companyCode: 158193747 },
  { codeId: 3, companyCode: 158203935 },
  { codeId: 33, companyCode: 158217689 },
  { codeId: 3, companyCode: 158238135 },
  { codeId: 3, companyCode: 158249726 },
  { codeId: 16, companyCode: 158257445 },
  { codeId: 19, companyCode: 158274366 },
  { codeId: 20, companyCode: 158285423 },
  { codeId: 3, companyCode: 158299362 },
  { codeId: 33, companyCode: 158323557 },
  { codeId: 16, companyCode: 158738628 },
  { codeId: 10, companyCode: 158742516 },
  { codeId: 16, companyCode: 158748540 },
  { codeId: 58, companyCode: 158756793 },
  { codeId: 53, companyCode: 158810974 },
  { codeId: 53, companyCode: 158813212 },
  { codeId: 53, companyCode: 158814271 },
  { codeId: 16, companyCode: 158819813 },
  { codeId: 19, companyCode: 158828449 },
  { codeId: 53, companyCode: 158831388 },
  { codeId: 53, companyCode: 158853993 },
  { codeId: 3, companyCode: 158891218 },
  { codeId: 58, companyCode: 158893183 },
  { codeId: 4, companyCode: 158906339 },
  { codeId: 3, companyCode: 158915911 },
  { codeId: 3, companyCode: 158919938 },
  { codeId: 16, companyCode: 158938714 },
  { codeId: 16, companyCode: 158953050 },
  { codeId: 38, companyCode: 158954871 },
  { codeId: 33, companyCode: 158955820 },
  { codeId: 19, companyCode: 158957643 },
  { codeId: 53, companyCode: 158959128 },
  { codeId: 19, companyCode: 158973277 },
  { codeId: 34, companyCode: 158978438 },
  { codeId: 49, companyCode: 158981224 },
  { codeId: 19, companyCode: 158985589 },
  { codeId: 16, companyCode: 158992815 },
  { codeId: 53, companyCode: 158994442 },
  { codeId: 60, companyCode: 158996646 },
  { codeId: 16, companyCode: 158997214 },
  { codeId: 16, companyCode: 159001242 },
  { codeId: 16, companyCode: 159001580 },
  { codeId: 16, companyCode: 159004167 },
  { codeId: 16, companyCode: 159421260 },
  { codeId: 16, companyCode: 159421456 },
  { codeId: 16, companyCode: 159428625 },
  { codeId: 53, companyCode: 159429727 },
  { codeId: 53, companyCode: 159501888 },
  { codeId: 40, companyCode: 159515717 },
  { codeId: 20, companyCode: 159519886 },
  { codeId: 49, companyCode: 159555779 },
  { codeId: 64, companyCode: 159592044 },
  { codeId: 20, companyCode: 159601098 },
  { codeId: 19, companyCode: 159603715 },
  { codeId: 3, companyCode: 159620440 },
  { codeId: 64, companyCode: 159658850 },
  { codeId: 19, companyCode: 159665097 },
  { codeId: 20, companyCode: 159673578 },
  { codeId: 38, companyCode: 159678696 },
  { codeId: 16, companyCode: 159707137 },
  { codeId: 16, companyCode: 159722192 },
  { codeId: 19, companyCode: 159753476 },
  { codeId: 19, companyCode: 159755331 },
  { codeId: 16, companyCode: 159760093 },
  { codeId: 6, companyCode: 159760855 },
  { codeId: 17, companyCode: 159770397 },
  { codeId: 3, companyCode: 159774794 },
  { codeId: 16, companyCode: 159785470 },
  { codeId: 3, companyCode: 159826942 },
  { codeId: 3, companyCode: 159833189 },
  { codeId: 53, companyCode: 159833221 },
  { codeId: 53, companyCode: 159835425 },
  { codeId: 53, companyCode: 159848262 },
  { codeId: 53, companyCode: 159850676 },
  { codeId: 40, companyCode: 159860446 },
  { codeId: 38, companyCode: 159867234 },
  { codeId: 45, companyCode: 159869395 },
  { codeId: 40, companyCode: 159874047 },
  { codeId: 53, companyCode: 159883968 },
  { codeId: 19, companyCode: 159890813 },
  { codeId: 49, companyCode: 159901341 },
  { codeId: 16, companyCode: 159912932 },
  { codeId: 20, companyCode: 159921949 },
  { codeId: 20, companyCode: 159925627 },
  { codeId: 53, companyCode: 159929643 },
  { codeId: 3, companyCode: 159931295 },
  { codeId: 21, companyCode: 159938083 },
  { codeId: 4, companyCode: 159940682 },
  { codeId: 53, companyCode: 159950267 },
  { codeId: 34, companyCode: 159963713 },
  { codeId: 21, companyCode: 159975644 },
  { codeId: 20, companyCode: 159984312 },
  { codeId: 9, companyCode: 160204019 },
  { codeId: 9, companyCode: 160285462 },
  { codeId: 20, companyCode: 160289293 },
  { codeId: 45, companyCode: 160302082 },
  { codeId: 38, companyCode: 160304329 },
  { codeId: 53, companyCode: 160304471 },
  { codeId: 16, companyCode: 160309447 },
  { codeId: 16, companyCode: 160311131 },
  { codeId: 16, companyCode: 160320333 },
  { codeId: 52, companyCode: 160328223 },
  { codeId: 16, companyCode: 160328757 },
  { codeId: 43, companyCode: 160421364 },
  { codeId: 11, companyCode: 160422847 },
  { codeId: 34, companyCode: 160425195 },
  { codeId: 58, companyCode: 160432617 },
  { codeId: 53, companyCode: 160445073 },
  { codeId: 16, companyCode: 160453216 },
  { codeId: 24, companyCode: 161110455 },
  { codeId: 8, companyCode: 161111219 },
  { codeId: 34, companyCode: 161124960 },
  { codeId: 60, companyCode: 161133639 },
  { codeId: 16, companyCode: 161192324 },
  { codeId: 34, companyCode: 161219180 },
  { codeId: 4, companyCode: 161223264 },
  { codeId: 34, companyCode: 161289086 },
  { codeId: 4, companyCode: 161300967 },
  { codeId: 34, companyCode: 161356713 },
  { codeId: 53, companyCode: 161411049 },
  { codeId: 16, companyCode: 161425173 },
  { codeId: 6, companyCode: 161427181 },
  { codeId: 6, companyCode: 161435858 },
  { codeId: 16, companyCode: 161453347 },
  { codeId: 34, companyCode: 161455355 },
  { codeId: 53, companyCode: 161637614 },
  { codeId: 61, companyCode: 161649545 },
  { codeId: 16, companyCode: 161732197 },
  { codeId: 16, companyCode: 161732763 },
  { codeId: 38, companyCode: 161735535 },
  { codeId: 16, companyCode: 161740144 },
  { codeId: 9, companyCode: 161740678 },
  { codeId: 53, companyCode: 162409528 },
  { codeId: 20, companyCode: 162444276 },
  { codeId: 16, companyCode: 162463433 },
  { codeId: 16, companyCode: 162526410 },
  { codeId: 3, companyCode: 162534510 },
  { codeId: 16, companyCode: 162537773 },
  { codeId: 53, companyCode: 162569810 },
  { codeId: 16, companyCode: 162582487 },
  { codeId: 16, companyCode: 162757748 },
  { codeId: 16, companyCode: 162768762 },
  { codeId: 16, companyCode: 162775575 },
  { codeId: 19, companyCode: 162941844 },
  { codeId: 35, companyCode: 163111851 },
  { codeId: 16, companyCode: 163112387 },
  { codeId: 42, companyCode: 163116784 },
  { codeId: 1, companyCode: 163131544 },
  { codeId: 20, companyCode: 163138147 },
  { codeId: 3, companyCode: 163185392 },
  { codeId: 16, companyCode: 163216375 },
  { codeId: 16, companyCode: 163231288 },
  { codeId: 53, companyCode: 163262419 },
  { codeId: 19, companyCode: 163273248 },
  { codeId: 16, companyCode: 163294947 },
  { codeId: 17, companyCode: 163314524 },
  { codeId: 3, companyCode: 163316913 },
  { codeId: 4, companyCode: 163335461 },
  { codeId: 16, companyCode: 163335657 },
  { codeId: 43, companyCode: 163344510 },
  { codeId: 53, companyCode: 163351095 },
  { codeId: 53, companyCode: 163356441 },
  { codeId: 16, companyCode: 163377574 },
  { codeId: 19, companyCode: 163381124 },
  { codeId: 53, companyCode: 163383132 },
  { codeId: 4, companyCode: 163383285 },
  { codeId: 16, companyCode: 163387725 },
  { codeId: 53, companyCode: 163387878 },
  { codeId: 58, companyCode: 163391951 },
  { codeId: 19, companyCode: 163396927 },
  { codeId: 64, companyCode: 163397267 },
  { codeId: 42, companyCode: 163400090 },
  { codeId: 16, companyCode: 163402675 },
  { codeId: 16, companyCode: 163545513 },
  { codeId: 16, companyCode: 163652255 },
  { codeId: 16, companyCode: 163659196 },
  { codeId: 58, companyCode: 163659239 },
  { codeId: 4, companyCode: 163659762 },
  { codeId: 4, companyCode: 163660921 },
  { codeId: 16, companyCode: 163666618 },
  { codeId: 4, companyCode: 163679793 },
  { codeId: 19, companyCode: 163679836 },
  { codeId: 53, companyCode: 163683158 },
  { codeId: 20, companyCode: 163683724 },
  { codeId: 19, companyCode: 163690722 },
  { codeId: 19, companyCode: 163691443 },
  { codeId: 47, companyCode: 163693070 },
  { codeId: 57, companyCode: 163710071 },
  { codeId: 16, companyCode: 163710648 },
  { codeId: 16, companyCode: 163722045 },
  { codeId: 34, companyCode: 163723866 },
  { codeId: 53, companyCode: 163728646 },
  { codeId: 16, companyCode: 163737848 },
  { codeId: 19, companyCode: 163742457 },
  { codeId: 61, companyCode: 163743744 },
  { codeId: 16, companyCode: 163746135 },
  { codeId: 16, companyCode: 163751082 },
  { codeId: 34, companyCode: 163752565 },
  { codeId: 49, companyCode: 163936832 },
  { codeId: 53, companyCode: 163961134 },
  { codeId: 16, companyCode: 164022010 },
  { codeId: 20, companyCode: 164035196 },
  { codeId: 16, companyCode: 164056219 },
  { codeId: 19, companyCode: 164080476 },
  { codeId: 16, companyCode: 164090246 },
  { codeId: 19, companyCode: 164111110 },
  { codeId: 16, companyCode: 164111459 },
  { codeId: 10, companyCode: 164112899 },
  { codeId: 34, companyCode: 164143987 },
  { codeId: 3, companyCode: 164196580 },
  { codeId: 16, companyCode: 164231647 },
  { codeId: 16, companyCode: 164272277 },
  { codeId: 20, companyCode: 164272462 },
  { codeId: 60, companyCode: 164294882 },
  { codeId: 4, companyCode: 164603122 },
  { codeId: 16, companyCode: 164694150 },
  { codeId: 34, companyCode: 164695829 },
  { codeId: 16, companyCode: 164699083 },
  { codeId: 38, companyCode: 164715363 },
  { codeId: 19, companyCode: 164749419 },
  { codeId: 3, companyCode: 164750688 },
  { codeId: 16, companyCode: 164756417 },
  { codeId: 34, companyCode: 164767399 },
  { codeId: 60, companyCode: 164780489 },
  { codeId: 19, companyCode: 164791699 },
  { codeId: 16, companyCode: 164802117 },
  { codeId: 5, companyCode: 164817877 },
  { codeId: 24, companyCode: 165107168 },
  { codeId: 20, companyCode: 165118182 },
  { codeId: 16, companyCode: 165142710 },
  { codeId: 3, companyCode: 165159236 },
  { codeId: 21, companyCode: 165213417 },
  { codeId: 51, companyCode: 165219441 },
  { codeId: 34, companyCode: 165220372 },
  { codeId: 19, companyCode: 165223482 },
  { codeId: 16, companyCode: 165224584 },
  { codeId: 19, companyCode: 165242835 },
  { codeId: 16, companyCode: 165381952 },
  { codeId: 58, companyCode: 165438652 },
  { codeId: 21, companyCode: 165635062 },
  { codeId: 38, companyCode: 165645747 },
  { codeId: 19, companyCode: 165651981 },
  { codeId: 9, companyCode: 165666531 },
  { codeId: 17, companyCode: 165697434 },
  { codeId: 16, companyCode: 165708539 },
  { codeId: 19, companyCode: 165711510 },
  { codeId: 3, companyCode: 165728940 },
  { codeId: 40, companyCode: 165733745 },
  { codeId: 16, companyCode: 165738678 },
  { codeId: 58, companyCode: 165741464 },
  { codeId: 16, companyCode: 165746625 },
  { codeId: 17, companyCode: 165746963 },
  { codeId: 53, companyCode: 165764267 },
  { codeId: 3, companyCode: 165773316 },
  { codeId: 40, companyCode: 165779874 },
  { codeId: 45, companyCode: 165793685 },
  { codeId: 16, companyCode: 165828499 },
  { codeId: 16, companyCode: 165828912 },
  { codeId: 19, companyCode: 165832049 },
  { codeId: 16, companyCode: 165841436 },
  { codeId: 19, companyCode: 166090017 },
  { codeId: 16, companyCode: 166091457 },
  { codeId: 60, companyCode: 166092559 },
  { codeId: 3, companyCode: 166427573 },
  { codeId: 53, companyCode: 166435716 },
  { codeId: 23, companyCode: 166451720 },
  { codeId: 19, companyCode: 166540592 },
  { codeId: 16, companyCode: 166558829 },
  { codeId: 64, companyCode: 166568360 },
  { codeId: 53, companyCode: 166635955 },
  { codeId: 58, companyCode: 166709957 },
  { codeId: 53, companyCode: 166727784 },
  { codeId: 3, companyCode: 166744171 },
  { codeId: 19, companyCode: 166778750 },
  { codeId: 3, companyCode: 166779286 },
  { codeId: 58, companyCode: 166821874 },
  { codeId: 45, companyCode: 166846151 },
  { codeId: 58, companyCode: 166857938 },
  { codeId: 19, companyCode: 166890678 },
  { codeId: 24, companyCode: 166893973 },
  { codeId: 53, companyCode: 166895458 },
  { codeId: 60, companyCode: 166901968 },
  { codeId: 58, companyCode: 166919432 },
  { codeId: 16, companyCode: 166919813 },
  { codeId: 53, companyCode: 167206670 },
  { codeId: 58, companyCode: 167344881 },
  { codeId: 58, companyCode: 167346170 },
  { codeId: 45, companyCode: 167350820 },
  { codeId: 34, companyCode: 167355415 },
  { codeId: 53, companyCode: 167359584 },
  { codeId: 53, companyCode: 167369888 },
  { codeId: 23, companyCode: 167390010 },
  { codeId: 16, companyCode: 167392171 },
  { codeId: 19, companyCode: 167394560 },
  { codeId: 58, companyCode: 167401072 },
  { codeId: 16, companyCode: 167531379 },
  { codeId: 19, companyCode: 167535057 },
  { codeId: 3, companyCode: 167540613 },
  { codeId: 16, companyCode: 167571135 },
  { codeId: 16, companyCode: 167580294 },
  { codeId: 16, companyCode: 167582911 },
  { codeId: 16, companyCode: 167583066 },
  { codeId: 34, companyCode: 167583447 },
  { codeId: 19, companyCode: 167584549 },
  { codeId: 42, companyCode: 167584887 },
  { codeId: 53, companyCode: 167602641 },
  { codeId: 16, companyCode: 167603939 },
  { codeId: 60, companyCode: 167610175 },
  { codeId: 60, companyCode: 167909640 },
  { codeId: 34, companyCode: 167930983 },
  { codeId: 18, companyCode: 167997220 },
  { codeId: 45, companyCode: 167999958 },
  { codeId: 40, companyCode: 168030339 },
  { codeId: 16, companyCode: 168048294 },
  { codeId: 18, companyCode: 168051842 },
  { codeId: 3, companyCode: 168290027 },
  { codeId: 34, companyCode: 168401627 },
  { codeId: 34, companyCode: 168402914 },
  { codeId: 19, companyCode: 168414998 },
  { codeId: 53, companyCode: 168419963 },
  { codeId: 15, companyCode: 168425489 },
  { codeId: 20, companyCode: 168431232 },
  { codeId: 34, companyCode: 168433621 },
  { codeId: 34, companyCode: 168465398 },
  { codeId: 34, companyCode: 168468921 },
  { codeId: 38, companyCode: 168471522 },
  { codeId: 38, companyCode: 168473683 },
  { codeId: 16, companyCode: 168477361 },
  { codeId: 16, companyCode: 168483072 },
  { codeId: 38, companyCode: 168489488 },
  { codeId: 19, companyCode: 168499443 },
  { codeId: 34, companyCode: 168515680 },
  { codeId: 32, companyCode: 168530974 },
  { codeId: 16, companyCode: 168535035 },
  { codeId: 16, companyCode: 168543092 },
  { codeId: 34, companyCode: 168544760 },
  { codeId: 34, companyCode: 168554911 },
  { codeId: 6, companyCode: 168560437 },
  { codeId: 17, companyCode: 168564453 },
  { codeId: 38, companyCode: 168567378 },
  { codeId: 3, companyCode: 168586873 },
  { codeId: 3, companyCode: 168586916 },
  { codeId: 3, companyCode: 168601332 },
  { codeId: 3, companyCode: 168620076 },
  { codeId: 3, companyCode: 168620838 },
  { codeId: 16, companyCode: 168623229 },
  { codeId: 19, companyCode: 168631133 },
  { codeId: 20, companyCode: 168634624 },
  { codeId: 32, companyCode: 168635726 },
  { codeId: 43, companyCode: 168641394 },
  { codeId: 19, companyCode: 168642496 },
  { codeId: 4, companyCode: 168644013 },
  { codeId: 16, companyCode: 168656859 },
  { codeId: 47, companyCode: 168660551 },
  { codeId: 3, companyCode: 168662755 },
  { codeId: 16, companyCode: 168664044 },
  { codeId: 20, companyCode: 168665527 },
  { codeId: 53, companyCode: 168667916 },
  { codeId: 53, companyCode: 168672678 },
  { codeId: 34, companyCode: 168673246 },
  { codeId: 21, companyCode: 168677796 },
  { codeId: 53, companyCode: 168678179 },
  { codeId: 34, companyCode: 168686083 },
  { codeId: 3, companyCode: 168688091 },
  { codeId: 34, companyCode: 168690548 },
  { codeId: 34, companyCode: 168695328 },
  { codeId: 38, companyCode: 168696953 },
  { codeId: 34, companyCode: 168935894 },
  { codeId: 3, companyCode: 168944943 },
  { codeId: 16, companyCode: 168956340 },
  { codeId: 19, companyCode: 168957442 },
  { codeId: 3, companyCode: 168961679 },
  { codeId: 16, companyCode: 168963534 },
  { codeId: 16, companyCode: 168979481 },
  { codeId: 19, companyCode: 168980074 },
  { codeId: 33, companyCode: 169104080 },
  { codeId: 6, companyCode: 169140735 },
  { codeId: 3, companyCode: 169153387 },
  { codeId: 16, companyCode: 169156497 },
  { codeId: 16, companyCode: 169225566 },
  { codeId: 19, companyCode: 169241766 },
  { codeId: 6, companyCode: 169245063 },
  { codeId: 16, companyCode: 169249460 },
  { codeId: 19, companyCode: 169249656 },
  { codeId: 16, companyCode: 169251874 },
  { codeId: 34, companyCode: 169281294 },
  { codeId: 16, companyCode: 169297284 },
  { codeId: 23, companyCode: 169704164 },
  { codeId: 19, companyCode: 169726812 },
  { codeId: 16, companyCode: 169730287 },
  { codeId: 16, companyCode: 169744988 },
  { codeId: 20, companyCode: 169751833 },
  { codeId: 16, companyCode: 169771145 },
  { codeId: 20, companyCode: 169796294 },
  { codeId: 19, companyCode: 169841469 },
  { codeId: 16, companyCode: 169850094 },
  { codeId: 6, companyCode: 169864838 },
  { codeId: 4, companyCode: 169887598 },
  { codeId: 16, companyCode: 169904631 },
  { codeId: 16, companyCode: 169920831 },
  { codeId: 16, companyCode: 169924477 },
  { codeId: 35, companyCode: 169939899 },
  { codeId: 20, companyCode: 169951549 },
  { codeId: 34, companyCode: 169955750 },
  { codeId: 20, companyCode: 169969166 },
  { codeId: 16, companyCode: 170000239 },
  { codeId: 16, companyCode: 170011068 },
  { codeId: 20, companyCode: 170011787 },
  { codeId: 16, companyCode: 170021895 },
  { codeId: 53, companyCode: 170027962 },
  { codeId: 20, companyCode: 170039936 },
  { codeId: 16, companyCode: 170055036 },
  { codeId: 1, companyCode: 170055940 },
  { codeId: 60, companyCode: 170535455 },
  { codeId: 35, companyCode: 170614447 },
  { codeId: 20, companyCode: 170647924 },
  { codeId: 16, companyCode: 170665947 },
  { codeId: 16, companyCode: 170667389 },
  { codeId: 34, companyCode: 170676438 },
  { codeId: 19, companyCode: 170679352 },
  { codeId: 3, companyCode: 170680479 },
  { codeId: 40, companyCode: 170687267 },
  { codeId: 16, companyCode: 170691721 },
  { codeId: 3, companyCode: 170707410 },
  { codeId: 3, companyCode: 170717714 },
  { codeId: 16, companyCode: 170733914 },
  { codeId: 19, companyCode: 170755615 },
  { codeId: 16, companyCode: 170755768 },
  { codeId: 34, companyCode: 170756674 },
  { codeId: 60, companyCode: 170759250 },
  { codeId: 42, companyCode: 170765538 },
  { codeId: 3, companyCode: 170778418 },
  { codeId: 53, companyCode: 170781161 },
  { codeId: 20, companyCode: 170784314 },
  { codeId: 19, companyCode: 170794575 },
  { codeId: 51, companyCode: 170795677 },
  { codeId: 64, companyCode: 170803661 },
  { codeId: 16, companyCode: 171031643 },
  { codeId: 9, companyCode: 171042853 },
  { codeId: 34, companyCode: 171047067 },
  { codeId: 16, companyCode: 171115372 },
  { codeId: 14, companyCode: 171119584 },
  { codeId: 53, companyCode: 171236139 },
  { codeId: 6, companyCode: 171259312 },
  { codeId: 20, companyCode: 171276233 },
  { codeId: 34, companyCode: 171276614 },
  { codeId: 27, companyCode: 171277488 },
  { codeId: 16, companyCode: 171307369 },
  { codeId: 6, companyCode: 171311061 },
  { codeId: 3, companyCode: 171378932 },
  { codeId: 51, companyCode: 171444859 },
  { codeId: 3, companyCode: 171651562 },
  { codeId: 60, companyCode: 171663689 },
  { codeId: 20, companyCode: 171665010 },
  { codeId: 16, companyCode: 171670534 },
  { codeId: 53, companyCode: 171694243 },
  { codeId: 60, companyCode: 171695726 },
  { codeId: 61, companyCode: 171704584 },
  { codeId: 57, companyCode: 171754459 },
  { codeId: 16, companyCode: 171754982 },
  { codeId: 20, companyCode: 171755027 },
  { codeId: 4, companyCode: 171757754 },
  { codeId: 16, companyCode: 171764033 },
  { codeId: 34, companyCode: 171769532 },
  { codeId: 53, companyCode: 171773192 },
  { codeId: 60, companyCode: 172201536 },
  { codeId: 19, companyCode: 172232962 },
  { codeId: 16, companyCode: 172237895 },
  { codeId: 16, companyCode: 172263299 },
  { codeId: 53, companyCode: 172292945 },
  { codeId: 16, companyCode: 172335663 },
  { codeId: 3, companyCode: 172343382 },
  { codeId: 20, companyCode: 172370835 },
  { codeId: 19, companyCode: 172385876 },
  { codeId: 16, companyCode: 172403826 },
  { codeId: 10, companyCode: 172411926 },
  { codeId: 60, companyCode: 172412113 },
  { codeId: 21, companyCode: 172427154 },
  { codeId: 19, companyCode: 172771696 },
  { codeId: 16, companyCode: 172773213 },
  { codeId: 16, companyCode: 172781847 },
  { codeId: 19, companyCode: 172786399 },
  { codeId: 51, companyCode: 173000664 },
  { codeId: 16, companyCode: 173016316 },
  { codeId: 49, companyCode: 173026577 },
  { codeId: 38, companyCode: 173046793 },
  { codeId: 20, companyCode: 173112229 },
  { codeId: 4, companyCode: 173123396 },
  { codeId: 16, companyCode: 173203294 },
  { codeId: 34, companyCode: 173208455 },
  { codeId: 3, companyCode: 173213445 },
  { codeId: 60, companyCode: 173213979 },
  { codeId: 24, companyCode: 173220062 },
  { codeId: 12, companyCode: 173228333 },
  { codeId: 16, companyCode: 173234382 },
  { codeId: 16, companyCode: 173236629 },
  { codeId: 19, companyCode: 173253735 },
  { codeId: 6, companyCode: 173727496 },
  { codeId: 19, companyCode: 173733620 },
  { codeId: 40, companyCode: 173738749 },
  { codeId: 35, companyCode: 173802657 },
  { codeId: 19, companyCode: 173835372 },
  { codeId: 58, companyCode: 173858175 },
  { codeId: 16, companyCode: 173893913 },
  { codeId: 12, companyCode: 173919678 },
  { codeId: 19, companyCode: 173919863 },
  { codeId: 58, companyCode: 173924853 },
  { codeId: 16, companyCode: 174164766 },
  { codeId: 53, companyCode: 174204036 },
  { codeId: 20, companyCode: 174276135 },
  { codeId: 53, companyCode: 174294496 },
  { codeId: 40, companyCode: 174323994 },
  { codeId: 16, companyCode: 174333383 },
  { codeId: 16, companyCode: 174357516 },
  { codeId: 4, companyCode: 174369828 },
  { codeId: 6, companyCode: 174383258 },
  { codeId: 53, companyCode: 174392079 },
  { codeId: 53, companyCode: 174392798 },
  { codeId: 34, companyCode: 174396095 },
  { codeId: 16, companyCode: 174397763 },
  { codeId: 16, companyCode: 174405039 },
  { codeId: 19, companyCode: 174405562 },
  { codeId: 60, companyCode: 174409393 },
  { codeId: 53, companyCode: 174419882 },
  { codeId: 19, companyCode: 174434119 },
  { codeId: 16, companyCode: 174442938 },
  { codeId: 16, companyCode: 174569871 },
  { codeId: 19, companyCode: 174574861 },
  { codeId: 16, companyCode: 174580572 },
  { codeId: 53, companyCode: 174585352 },
  { codeId: 3, companyCode: 174587556 },
  { codeId: 40, companyCode: 174904615 },
  { codeId: 35, companyCode: 174936043 },
  { codeId: 24, companyCode: 174943422 },
  { codeId: 34, companyCode: 174962732 },
  { codeId: 3, companyCode: 174969716 },
  { codeId: 3, companyCode: 174976148 },
  { codeId: 51, companyCode: 174976486 },
  { codeId: 37, companyCode: 174977969 },
  { codeId: 3, companyCode: 174987358 },
  { codeId: 34, companyCode: 175002824 },
  { codeId: 16, companyCode: 175006840 },
  { codeId: 16, companyCode: 175008282 },
  { codeId: 3, companyCode: 175023423 },
  { codeId: 16, companyCode: 175028160 },
  { codeId: 34, companyCode: 175028922 },
  { codeId: 3, companyCode: 175036118 },
  { codeId: 49, companyCode: 175613922 },
  { codeId: 38, companyCode: 175624566 },
  { codeId: 36, companyCode: 175633572 },
  { codeId: 38, companyCode: 175690598 },
  { codeId: 16, companyCode: 175711843 },
  { codeId: 6, companyCode: 175721428 },
  { codeId: 19, companyCode: 175721570 },
  { codeId: 3, companyCode: 175723436 },
  { codeId: 53, companyCode: 175759838 },
  { codeId: 16, companyCode: 175789977 },
  { codeId: 16, companyCode: 175799551 },
  { codeId: 16, companyCode: 175813638 },
  { codeId: 16, companyCode: 175821542 },
  { codeId: 16, companyCode: 175835481 },
  { codeId: 16, companyCode: 175841388 },
  { codeId: 16, companyCode: 175846549 },
  { codeId: 16, companyCode: 175870468 },
  { codeId: 12, companyCode: 175872095 },
  { codeId: 20, companyCode: 175885160 },
  { codeId: 21, companyCode: 175894743 },
  { codeId: 53, companyCode: 176520937 },
  { codeId: 19, companyCode: 176522030 },
  { codeId: 19, companyCode: 176526438 },
  { codeId: 3, companyCode: 176536731 },
  { codeId: 48, companyCode: 176562288 },
  { codeId: 3, companyCode: 176592121 },
  { codeId: 3, companyCode: 176594663 },
  { codeId: 3, companyCode: 176597816 },
  { codeId: 3, companyCode: 176603379 },
  { codeId: 16, companyCode: 176604851 },
  { codeId: 53, companyCode: 176606489 },
  { codeId: 16, companyCode: 176622689 },
  { codeId: 58, companyCode: 176636518 },
  { codeId: 16, companyCode: 176639966 },
  { codeId: 16, companyCode: 177003088 },
  { codeId: 40, companyCode: 177009721 },
  { codeId: 19, companyCode: 177034219 },
  { codeId: 53, companyCode: 177040791 },
  { codeId: 16, companyCode: 177058451 },
  { codeId: 3, companyCode: 177066932 },
  { codeId: 53, companyCode: 177092521 },
  { codeId: 16, companyCode: 177104870 },
  { codeId: 4, companyCode: 177109084 },
  { codeId: 20, companyCode: 177158238 },
  { codeId: 4, companyCode: 177161177 },
  { codeId: 24, companyCode: 177209437 },
  { codeId: 16, companyCode: 177211840 },
  { codeId: 60, companyCode: 177217875 },
  { codeId: 53, companyCode: 177243126 },
  { codeId: 16, companyCode: 177250462 },
  { codeId: 19, companyCode: 177250658 },
  { codeId: 16, companyCode: 177281027 },
  { codeId: 19, companyCode: 177301095 },
  { codeId: 16, companyCode: 177301138 },
  { codeId: 16, companyCode: 177303331 },
  { codeId: 53, companyCode: 177306975 },
  { codeId: 19, companyCode: 177307358 },
  { codeId: 58, companyCode: 177315458 },
  { codeId: 16, companyCode: 177318415 },
  { codeId: 19, companyCode: 177325947 },
  { codeId: 19, companyCode: 177337725 },
  { codeId: 64, companyCode: 177344157 },
  { codeId: 16, companyCode: 177351874 },
  { codeId: 64, companyCode: 177356316 },
  { codeId: 40, companyCode: 177392928 },
  { codeId: 53, companyCode: 177400727 },
  { codeId: 58, companyCode: 177413379 },
  { codeId: 4, companyCode: 177415191 },
  { codeId: 16, companyCode: 177417438 },
  { codeId: 53, companyCode: 177419827 },
  { codeId: 53, companyCode: 177420562 },
  { codeId: 19, companyCode: 177425876 },
  { codeId: 16, companyCode: 177974184 },
  { codeId: 6, companyCode: 177983614 },
  { codeId: 6, companyCode: 178200195 },
  { codeId: 19, companyCode: 178228910 },
  { codeId: 16, companyCode: 178234774 },
  { codeId: 3, companyCode: 178256094 },
  { codeId: 16, companyCode: 178263516 },
  { codeId: 19, companyCode: 178273396 },
  { codeId: 3, companyCode: 178281877 },
  { codeId: 21, companyCode: 178287410 },
  { codeId: 16, companyCode: 178296231 },
  { codeId: 45, companyCode: 178297486 },
  { codeId: 19, companyCode: 178305666 },
  { codeId: 16, companyCode: 178319114 },
  { codeId: 34, companyCode: 178323493 },
  { codeId: 17, companyCode: 178324780 },
  { codeId: 24, companyCode: 178324976 },
  { codeId: 61, companyCode: 178602952 },
  { codeId: 16, companyCode: 178609893 },
  { codeId: 40, companyCode: 178611392 },
  { codeId: 16, companyCode: 178612156 },
  { codeId: 16, companyCode: 178628484 },
  { codeId: 45, companyCode: 178628865 },
  { codeId: 19, companyCode: 178647837 },
  { codeId: 40, companyCode: 178649845 },
  { codeId: 3, companyCode: 178660012 },
  { codeId: 10, companyCode: 178667149 },
  { codeId: 16, companyCode: 178681298 },
  { codeId: 64, companyCode: 178690532 },
  { codeId: 19, companyCode: 178693457 },
  { codeId: 16, companyCode: 178696371 },
  { codeId: 16, companyCode: 178727920 },
  { codeId: 19, companyCode: 178735835 },
  { codeId: 42, companyCode: 178738411 },
  { codeId: 16, companyCode: 178740444 },
  { codeId: 16, companyCode: 178741927 },
  { codeId: 60, companyCode: 178860251 },
  { codeId: 34, companyCode: 178861549 },
  { codeId: 21, companyCode: 178993134 },
  { codeId: 20, companyCode: 179230355 },
  { codeId: 20, companyCode: 179242852 },
  { codeId: 19, companyCode: 179277433 },
  { codeId: 16, companyCode: 179277967 },
  { codeId: 19, companyCode: 179281474 },
  { codeId: 53, companyCode: 179287737 },
  { codeId: 3, companyCode: 179294920 },
  { codeId: 47, companyCode: 179321844 },
  { codeId: 53, companyCode: 179336732 },
  { codeId: 53, companyCode: 179337072 },
  { codeId: 3, companyCode: 179343883 },
  { codeId: 34, companyCode: 179349384 },
  { codeId: 16, companyCode: 179362136 },
  { codeId: 40, companyCode: 179370193 },
  { codeId: 35, companyCode: 179374971 },
  { codeId: 19, companyCode: 179376260 },
  { codeId: 19, companyCode: 179404852 },
  { codeId: 16, companyCode: 179423596 },
  { codeId: 19, companyCode: 179426553 },
  { codeId: 3, companyCode: 179451040 },
  { codeId: 40, companyCode: 179454531 },
  { codeId: 53, companyCode: 179463167 },
  { codeId: 53, companyCode: 179466996 },
  { codeId: 16, companyCode: 179472173 },
  { codeId: 16, companyCode: 179478589 },
  { codeId: 51, companyCode: 179478621 },
  { codeId: 3, companyCode: 179479723 },
  { codeId: 53, companyCode: 179490239 },
  { codeId: 7, companyCode: 179758420 },
  { codeId: 53, companyCode: 179759860 },
  { codeId: 53, companyCode: 179872018 },
  { codeId: 53, companyCode: 179875128 },
  { codeId: 16, companyCode: 179877093 },
  { codeId: 20, companyCode: 179884515 },
  { codeId: 16, companyCode: 179885236 },
  { codeId: 3, companyCode: 179885955 },
  { codeId: 12, companyCode: 179905685 },
  { codeId: 53, companyCode: 179909025 },
  { codeId: 40, companyCode: 180106372 },
  { codeId: 3, companyCode: 180182174 },
  { codeId: 25, companyCode: 180184410 },
  { codeId: 16, companyCode: 180189158 },
  { codeId: 20, companyCode: 180205242 },
  { codeId: 40, companyCode: 180245532 },
  { codeId: 16, companyCode: 180275486 },
  { codeId: 19, companyCode: 180277156 },
  { codeId: 16, companyCode: 180299195 },
  { codeId: 16, companyCode: 180303697 },
  { codeId: 4, companyCode: 180330235 },
  { codeId: 51, companyCode: 180373788 },
  { codeId: 40, companyCode: 180705888 },
  { codeId: 6, companyCode: 180720071 },
  { codeId: 16, companyCode: 180877382 },
  { codeId: 40, companyCode: 180878527 },
  { codeId: 16, companyCode: 180884042 },
  { codeId: 60, companyCode: 180884195 },
  { codeId: 60, companyCode: 181121797 },
  { codeId: 19, companyCode: 181124569 },
  { codeId: 20, companyCode: 181182348 },
  { codeId: 42, companyCode: 181194126 },
  { codeId: 34, companyCode: 181206094 },
  { codeId: 20, companyCode: 181210897 },
  { codeId: 20, companyCode: 181216245 },
  { codeId: 21, companyCode: 181221954 },
  { codeId: 16, companyCode: 181226353 },
  { codeId: 16, companyCode: 181292766 },
  { codeId: 3, companyCode: 181305911 },
  { codeId: 19, companyCode: 181306066 },
  { codeId: 16, companyCode: 181319131 },
  { codeId: 58, companyCode: 181320781 },
  { codeId: 19, companyCode: 181339881 },
  { codeId: 53, companyCode: 181347077 },
  { codeId: 53, companyCode: 181351346 },
  { codeId: 20, companyCode: 181355558 },
  { codeId: 19, companyCode: 181359955 },
  { codeId: 16, companyCode: 181361835 },
  { codeId: 19, companyCode: 181372479 },
  { codeId: 42, companyCode: 181373951 },
  { codeId: 25, companyCode: 181376495 },
  { codeId: 16, companyCode: 181377444 },
  { codeId: 16, companyCode: 181382053 },
  { codeId: 16, companyCode: 181396035 },
  { codeId: 16, companyCode: 181531173 },
  { codeId: 42, companyCode: 181611986 },
  { codeId: 60, companyCode: 181613656 },
  { codeId: 40, companyCode: 181621756 },
  { codeId: 16, companyCode: 181682264 },
  { codeId: 53, companyCode: 181686280 },
  { codeId: 61, companyCode: 181705485 },
  { codeId: 53, companyCode: 182700879 },
  { codeId: 53, companyCode: 182701251 },
  { codeId: 60, companyCode: 182714850 },
  { codeId: 53, companyCode: 182728266 },
  { codeId: 37, companyCode: 182755142 },
  { codeId: 19, companyCode: 182760513 },
  { codeId: 19, companyCode: 182760666 },
  { codeId: 47, companyCode: 182778664 },
  { codeId: 40, companyCode: 182814975 },
  { codeId: 16, companyCode: 182827474 },
  { codeId: 19, companyCode: 182837244 },
  { codeId: 3, companyCode: 182842768 },
  { codeId: 34, companyCode: 182858758 },
  { codeId: 16, companyCode: 182889312 },
  { codeId: 3, companyCode: 182899769 },
  { codeId: 20, companyCode: 182900965 },
  { codeId: 16, companyCode: 182909423 },
  { codeId: 19, companyCode: 182914566 },
  { codeId: 16, companyCode: 182919346 },
  { codeId: 16, companyCode: 182922328 },
  { codeId: 16, companyCode: 182923191 },
  { codeId: 16, companyCode: 182923768 },
  { codeId: 16, companyCode: 182941823 },
  { codeId: 20, companyCode: 183106274 },
  { codeId: 16, companyCode: 183110543 },
  { codeId: 19, companyCode: 183113087 },
  { codeId: 20, companyCode: 183115519 },
  { codeId: 3, companyCode: 183120847 },
  { codeId: 20, companyCode: 183127592 },
  { codeId: 20, companyCode: 183136456 },
  { codeId: 60, companyCode: 183204042 },
  { codeId: 19, companyCode: 183217075 },
  { codeId: 34, companyCode: 183219083 },
  { codeId: 3, companyCode: 183219998 },
  { codeId: 16, companyCode: 183227379 },
  { codeId: 19, companyCode: 183244866 },
  { codeId: 34, companyCode: 183245968 },
  { codeId: 19, companyCode: 183247638 },
  { codeId: 4, companyCode: 183654961 },
  { codeId: 16, companyCode: 183698210 },
  { codeId: 10, companyCode: 183709468 },
  { codeId: 16, companyCode: 183725668 },
  { codeId: 34, companyCode: 183728582 },
  { codeId: 16, companyCode: 183735776 },
  { codeId: 26, companyCode: 183750721 },
  { codeId: 38, companyCode: 183764856 },
  { codeId: 40, companyCode: 183765577 },
  { codeId: 20, companyCode: 183782683 },
  { codeId: 40, companyCode: 183794319 },
  { codeId: 45, companyCode: 183799394 },
  { codeId: 61, companyCode: 183800829 },
  { codeId: 35, companyCode: 183808480 },
  { codeId: 16, companyCode: 183819733 },
  { codeId: 60, companyCode: 183843314 },
  { codeId: 19, companyCode: 183843652 },
  { codeId: 3, companyCode: 183845322 },
  { codeId: 21, companyCode: 183863726 },
  { codeId: 16, companyCode: 184075312 },
  { codeId: 53, companyCode: 184084329 },
  { codeId: 42, companyCode: 184093335 },
  { codeId: 10, companyCode: 184198959 },
  { codeId: 19, companyCode: 184210799 },
  { codeId: 16, companyCode: 184220373 },
  { codeId: 19, companyCode: 184241353 },
  { codeId: 16, companyCode: 184266730 },
  { codeId: 34, companyCode: 184290126 },
  { codeId: 16, companyCode: 184532743 },
  { codeId: 16, companyCode: 184560917 },
  { codeId: 19, companyCode: 184564029 },
  { codeId: 40, companyCode: 184570797 },
  { codeId: 16, companyCode: 184582041 },
  { codeId: 16, companyCode: 184603635 },
  { codeId: 40, companyCode: 184617236 },
  { codeId: 42, companyCode: 184625489 },
  { codeId: 19, companyCode: 184630098 },
  { codeId: 16, companyCode: 184759659 },
  { codeId: 60, companyCode: 184827583 },
  { codeId: 16, companyCode: 184829787 },
  { codeId: 16, companyCode: 184842539 },
  { codeId: 17, companyCode: 184848563 },
  { codeId: 19, companyCode: 185114526 },
  { codeId: 26, companyCode: 185115813 },
  { codeId: 20, companyCode: 185125921 },
  { codeId: 3, companyCode: 185184617 },
  { codeId: 53, companyCode: 185186963 },
  { codeId: 53, companyCode: 185190132 },
  { codeId: 3, companyCode: 185220351 },
  { codeId: 53, companyCode: 185225850 },
  { codeId: 16, companyCode: 185226048 },
  { codeId: 15, companyCode: 185233427 },
  { codeId: 4, companyCode: 185272572 },
  { codeId: 53, companyCode: 185274242 },
  { codeId: 3, companyCode: 185274438 },
  { codeId: 53, companyCode: 185301885 },
  { codeId: 27, companyCode: 185312223 },
  { codeId: 20, companyCode: 185324492 },
  { codeId: 19, companyCode: 185487176 },
  { codeId: 53, companyCode: 185488997 },
  { codeId: 60, companyCode: 185492166 },
  { codeId: 58, companyCode: 185595629 },
  { codeId: 3, companyCode: 185596916 },
  { codeId: 16, companyCode: 185605393 },
  { codeId: 58, companyCode: 185610426 },
  { codeId: 20, companyCode: 185667612 },
  { codeId: 53, companyCode: 185667765 },
  { codeId: 19, companyCode: 185670366 },
  { codeId: 53, companyCode: 186019465 },
  { codeId: 20, companyCode: 186021498 },
  { codeId: 53, companyCode: 186021911 },
  { codeId: 6, companyCode: 186024793 },
  { codeId: 34, companyCode: 186044714 },
  { codeId: 38, companyCode: 186048926 },
  { codeId: 60, companyCode: 186063262 },
  { codeId: 16, companyCode: 186073566 },
  { codeId: 21, companyCode: 186074515 },
  { codeId: 20, companyCode: 186080030 },
  { codeId: 16, companyCode: 186081285 },
  { codeId: 16, companyCode: 186085910 },
  { codeId: 3, companyCode: 186098377 },
  { codeId: 40, companyCode: 186109471 },
  { codeId: 20, companyCode: 186112072 },
  { codeId: 19, companyCode: 186116850 },
  { codeId: 34, companyCode: 186119622 },
  { codeId: 16, companyCode: 186124427 },
  { codeId: 16, companyCode: 186128639 },
  { codeId: 19, companyCode: 186131425 },
  { codeId: 49, companyCode: 186134535 },
  { codeId: 16, companyCode: 186140399 },
  { codeId: 60, companyCode: 186143922 },
  { codeId: 16, companyCode: 186145211 },
  { codeId: 34, companyCode: 186154185 },
  { codeId: 20, companyCode: 186172817 },
  { codeId: 3, companyCode: 186185469 },
  { codeId: 16, companyCode: 186194660 },
  { codeId: 26, companyCode: 186195424 },
  { codeId: 42, companyCode: 186208341 },
  { codeId: 34, companyCode: 186230067 },
  { codeId: 42, companyCode: 186231692 },
  { codeId: 3, companyCode: 186232456 },
  { codeId: 16, companyCode: 186235751 },
  { codeId: 42, companyCode: 186255825 },
  { codeId: 60, companyCode: 186442084 },
  { codeId: 33, companyCode: 186442312 },
  { codeId: 16, companyCode: 186445956 },
  { codeId: 16, companyCode: 186449972 },
  { codeId: 60, companyCode: 186451090 },
  { codeId: 40, companyCode: 186452769 },
  { codeId: 58, companyCode: 186480028 },
  { codeId: 16, companyCode: 186482940 },
  { codeId: 19, companyCode: 186483661 },
  { codeId: 16, companyCode: 186720822 },
  { codeId: 53, companyCode: 186746888 },
  { codeId: 34, companyCode: 186749279 },
  { codeId: 53, companyCode: 186758513 },
  { codeId: 16, companyCode: 187831416 },
  { codeId: 53, companyCode: 187858388 },
  { codeId: 16, companyCode: 187860649 },
  { codeId: 3, companyCode: 187870376 },
  { codeId: 14, companyCode: 187882492 },
  { codeId: 3, companyCode: 187882688 },
  { codeId: 16, companyCode: 187886170 },
  { codeId: 16, companyCode: 187903367 },
  { codeId: 16, companyCode: 187909587 },
  { codeId: 20, companyCode: 187928025 },
  { codeId: 34, companyCode: 210036160 },
  { codeId: 16, companyCode: 210184860 },
  { codeId: 38, companyCode: 210384380 },
  { codeId: 57, companyCode: 210430080 },
  { codeId: 23, companyCode: 210502640 },
  { codeId: 21, companyCode: 210655020 },
  { codeId: 4, companyCode: 210713370 },
  { codeId: 34, companyCode: 210734350 },
  { codeId: 16, companyCode: 210742450 },
  { codeId: 16, companyCode: 210753660 },
  { codeId: 34, companyCode: 210764870 },
  { codeId: 34, companyCode: 210793950 },
  { codeId: 38, companyCode: 210863740 },
  { codeId: 34, companyCode: 211503720 },
  { codeId: 3, companyCode: 211514930 },
  { codeId: 16, companyCode: 211551010 },
  { codeId: 45, companyCode: 211574870 },
  { codeId: 24, companyCode: 211625350 },
  { codeId: 20, companyCode: 211690280 },
  { codeId: 45, companyCode: 211716120 },
  { codeId: 53, companyCode: 211791920 },
  { codeId: 20, companyCode: 220603470 },
  { codeId: 20, companyCode: 220858930 },
  { codeId: 49, companyCode: 221308170 },
  { codeId: 11, companyCode: 221421570 },
  { codeId: 42, companyCode: 221513780 },
  { codeId: 21, companyCode: 221514010 },
  { codeId: 53, companyCode: 221579830 },
  { codeId: 37, companyCode: 221968760 },
  { codeId: 15, companyCode: 222343320 },
  { codeId: 45, companyCode: 222432420 },
  { codeId: 38, companyCode: 222657470 },
  { codeId: 19, companyCode: 222785420 },
  { codeId: 53, companyCode: 222837340 },
  { codeId: 16, companyCode: 223761080 },
  { codeId: 42, companyCode: 223790160 },
  { codeId: 53, companyCode: 224031440 },
  { codeId: 16, companyCode: 224032880 },
  { codeId: 16, companyCode: 224253380 },
  { codeId: 53, companyCode: 224417050 },
  { codeId: 21, companyCode: 224470430 },
  { codeId: 53, companyCode: 224476650 },
  { codeId: 53, companyCode: 224912610 },
  { codeId: 53, companyCode: 225165360 },
  { codeId: 20, companyCode: 225178240 },
  { codeId: 16, companyCode: 225455310 },
  { codeId: 19, companyCode: 225490820 },
  { codeId: 19, companyCode: 225601320 },
  { codeId: 53, companyCode: 225643390 },
  { codeId: 21, companyCode: 225774450 },
  { codeId: 3, companyCode: 225821590 },
  { codeId: 34, companyCode: 225829480 },
  { codeId: 49, companyCode: 226077340 },
  { codeId: 53, companyCode: 226086880 },
  { codeId: 16, companyCode: 226095210 },
  { codeId: 34, companyCode: 226150450 },
  { codeId: 34, companyCode: 226281510 },
  { codeId: 49, companyCode: 226341530 },
  { codeId: 34, companyCode: 232051880 },
  { codeId: 24, companyCode: 233107990 },
  { codeId: 35, companyCode: 233370660 },
  { codeId: 53, companyCode: 233393310 },
  { codeId: 20, companyCode: 233414480 },
  { codeId: 53, companyCode: 233483850 },
  { codeId: 33, companyCode: 233898540 },
  { codeId: 53, companyCode: 234003980 },
  { codeId: 35, companyCode: 234116070 },
  { codeId: 21, companyCode: 234326460 },
  { codeId: 35, companyCode: 234489710 },
  { codeId: 11, companyCode: 234498150 },
  { codeId: 16, companyCode: 234509440 },
  { codeId: 53, companyCode: 234691110 },
  { codeId: 53, companyCode: 234983940 },
  { codeId: 60, companyCode: 235014830 },
  { codeId: 53, companyCode: 235073330 },
  { codeId: 34, companyCode: 235168650 },
  { codeId: 27, companyCode: 235497560 },
  { codeId: 3, companyCode: 235561130 },
  { codeId: 24, companyCode: 235573780 },
  { codeId: 34, companyCode: 235599540 },
  { codeId: 49, companyCode: 235677430 },
  { codeId: 53, companyCode: 235697080 },
  { codeId: 19, companyCode: 235746990 },
  { codeId: 53, companyCode: 235886490 },
  { codeId: 34, companyCode: 235953170 },
  { codeId: 34, companyCode: 235974150 },
  { codeId: 15, companyCode: 240188850 },
  { codeId: 17, companyCode: 240616710 },
  { codeId: 19, companyCode: 240781950 },
  { codeId: 16, companyCode: 241081730 },
  { codeId: 16, companyCode: 241616460 },
  { codeId: 34, companyCode: 241844720 },
  { codeId: 19, companyCode: 241856270 },
  { codeId: 16, companyCode: 241993760 },
  { codeId: 57, companyCode: 242061830 },
  { codeId: 60, companyCode: 242082810 },
  { codeId: 57, companyCode: 242110640 },
  { codeId: 50, companyCode: 242129740 },
  { codeId: 57, companyCode: 242155710 },
  { codeId: 57, companyCode: 242182020 },
  { codeId: 62, companyCode: 244670310 },
  { codeId: 19, companyCode: 244832310 },
  { codeId: 3, companyCode: 244951930 },
  { codeId: 16, companyCode: 245268040 },
  { codeId: 19, companyCode: 245273030 },
  { codeId: 49, companyCode: 245335820 },
  { codeId: 60, companyCode: 245358580 },
  { codeId: 19, companyCode: 245435030 },
  { codeId: 34, companyCode: 245908070 },
  { codeId: 45, companyCode: 247021170 },
  { codeId: 49, companyCode: 247108390 },
  { codeId: 58, companyCode: 247788520 },
  { codeId: 20, companyCode: 248213330 },
  { codeId: 20, companyCode: 248217880 },
  { codeId: 49, companyCode: 248331510 },
  { codeId: 45, companyCode: 248368590 },
  { codeId: 34, companyCode: 248515930 },
  { codeId: 19, companyCode: 250085610 },
  { codeId: 53, companyCode: 251237590 },
  { codeId: 21, companyCode: 251427230 },
  { codeId: 19, companyCode: 252632590 },
  { codeId: 3, companyCode: 253249520 },
  { codeId: 6, companyCode: 253250070 },
  { codeId: 58, companyCode: 253253180 },
  { codeId: 53, companyCode: 253604590 },
  { codeId: 16, companyCode: 254164820 },
  { codeId: 21, companyCode: 254221730 },
  { codeId: 3, companyCode: 254229620 },
  { codeId: 53, companyCode: 254704540 },
  { codeId: 43, companyCode: 255311910 },
  { codeId: 19, companyCode: 255401350 },
  { codeId: 62, companyCode: 255450080 },
  { codeId: 53, companyCode: 255973180 },
  { codeId: 3, companyCode: 256580550 },
  { codeId: 16, companyCode: 256694080 },
  { codeId: 63, companyCode: 257603030 },
  { codeId: 20, companyCode: 257693490 },
  { codeId: 60, companyCode: 258325370 },
  { codeId: 53, companyCode: 258888760 },
  { codeId: 3, companyCode: 258908490 },
  { codeId: 53, companyCode: 258921580 },
  { codeId: 19, companyCode: 258999180 },
  { codeId: 16, companyCode: 259423650 },
  { codeId: 3, companyCode: 259513090 },
  { codeId: 42, companyCode: 259595340 },
  { codeId: 9, companyCode: 259712270 },
  { codeId: 35, companyCode: 259765440 },
  { codeId: 3, companyCode: 259789530 },
  { codeId: 53, companyCode: 259936980 },
  { codeId: 10, companyCode: 262431810 },
  { codeId: 53, companyCode: 263156160 },
  { codeId: 20, companyCode: 263170690 },
  { codeId: 19, companyCode: 263341020 },
  { codeId: 53, companyCode: 263373210 },
  { codeId: 16, companyCode: 263531870 },
  { codeId: 19, companyCode: 263667710 },
  { codeId: 16, companyCode: 263687360 },
  { codeId: 16, companyCode: 263706750 },
  { codeId: 17, companyCode: 263713410 },
  { codeId: 4, companyCode: 263721510 },
  { codeId: 53, companyCode: 263740820 },
  { codeId: 19, companyCode: 264309470 },
  { codeId: 19, companyCode: 264592890 },
  { codeId: 16, companyCode: 265220220 },
  { codeId: 20, companyCode: 265641340 },
  { codeId: 20, companyCode: 265774180 },
  { codeId: 16, companyCode: 266591940 },
  { codeId: 16, companyCode: 266769930 },
  { codeId: 58, companyCode: 266856260 },
  { codeId: 16, companyCode: 267245140 },
  { codeId: 58, companyCode: 267366430 },
  { codeId: 58, companyCode: 267387410 },
  { codeId: 16, companyCode: 267395510 },
  { codeId: 16, companyCode: 268040590 },
  { codeId: 53, companyCode: 268428970 },
  { codeId: 34, companyCode: 268605730 },
  { codeId: 33, companyCode: 268983370 },
  { codeId: 16, companyCode: 270052450 },
  { codeId: 20, companyCode: 270532150 },
  { codeId: 53, companyCode: 270533590 },
  { codeId: 38, companyCode: 271205760 },
  { codeId: 19, companyCode: 271386990 },
  { codeId: 16, companyCode: 273737790 },
  { codeId: 20, companyCode: 273882280 },
  { codeId: 60, companyCode: 273889830 },
  { codeId: 16, companyCode: 274290470 },
  { codeId: 35, companyCode: 274974130 },
  { codeId: 16, companyCode: 276627840 },
  { codeId: 3, companyCode: 277046140 },
  { codeId: 58, companyCode: 277074990 },
  { codeId: 53, companyCode: 277132130 },
  { codeId: 53, companyCode: 277157660 },
  { codeId: 53, companyCode: 277171090 },
  { codeId: 24, companyCode: 277188410 },
  { codeId: 16, companyCode: 277275840 },
  { codeId: 3, companyCode: 277416860 },
  { codeId: 36, companyCode: 277418530 },
  { codeId: 16, companyCode: 278671180 },
  { codeId: 16, companyCode: 278724430 },
  { codeId: 19, companyCode: 278725870 },
  { codeId: 16, companyCode: 278993320 },
  { codeId: 3, companyCode: 279281660 },
  { codeId: 49, companyCode: 279357670 },
  { codeId: 3, companyCode: 279876220 },
  { codeId: 16, companyCode: 281367860 },
  { codeId: 20, companyCode: 282888930 },
  { codeId: 16, companyCode: 282912210 },
  { codeId: 53, companyCode: 283214870 },
  { codeId: 16, companyCode: 283675180 },
  { codeId: 49, companyCode: 283834070 },
  { codeId: 53, companyCode: 284232490 },
  { codeId: 3, companyCode: 284268020 },
  { codeId: 53, companyCode: 285317680 },
  { codeId: 16, companyCode: 285345320 },
  { codeId: 40, companyCode: 285470270 },
  { codeId: 16, companyCode: 286086970 },
  { codeId: 3, companyCode: 286479240 },
  { codeId: 33, companyCode: 286485670 },
  { codeId: 53, companyCode: 286752970 },
  { codeId: 16, companyCode: 287841710 },
  { codeId: 40, companyCode: 300000170 },
  { codeId: 16, companyCode: 300001176 },
  { codeId: 16, companyCode: 300001315 },
  { codeId: 23, companyCode: 300001735 },
  { codeId: 61, companyCode: 300002157 },
  { codeId: 53, companyCode: 300002495 },
  { codeId: 34, companyCode: 300003985 },
  { codeId: 19, companyCode: 300004343 },
  { codeId: 57, companyCode: 300004763 },
  { codeId: 45, companyCode: 300008142 },
  { codeId: 53, companyCode: 300008562 },
  { codeId: 19, companyCode: 300008929 },
  { codeId: 53, companyCode: 300009582 },
  { codeId: 53, companyCode: 300009600 },
  { codeId: 20, companyCode: 300009746 },
  { codeId: 53, companyCode: 300010200 },
  { codeId: 17, companyCode: 300010976 },
  { codeId: 28, companyCode: 300011010 },
  { codeId: 58, companyCode: 300011131 },
  { codeId: 40, companyCode: 300011188 },
  { codeId: 53, companyCode: 300011398 },
  { codeId: 4, companyCode: 300011672 },
  { codeId: 16, companyCode: 300011932 },
  { codeId: 64, companyCode: 300012386 },
  { codeId: 21, companyCode: 300012482 },
  { codeId: 19, companyCode: 300012518 },
  { codeId: 16, companyCode: 300013328 },
  { codeId: 20, companyCode: 300014704 },
  { codeId: 34, companyCode: 300014750 },
  { codeId: 53, companyCode: 300014914 },
  { codeId: 6, companyCode: 300014921 },
  { codeId: 53, companyCode: 300015208 },
  { codeId: 24, companyCode: 300015304 },
  { codeId: 53, companyCode: 300015585 },
  { codeId: 38, companyCode: 300015642 },
  { codeId: 16, companyCode: 300016192 },
  { codeId: 53, companyCode: 300016210 },
  { codeId: 19, companyCode: 300016527 },
  { codeId: 19, companyCode: 300016580 },
  { codeId: 53, companyCode: 300016801 },
  { codeId: 34, companyCode: 300017508 },
  { codeId: 53, companyCode: 300017586 },
  { codeId: 53, companyCode: 300018930 },
  { codeId: 57, companyCode: 300020080 },
  { codeId: 31, companyCode: 300020397 },
  { codeId: 57, companyCode: 300020835 },
  { codeId: 53, companyCode: 300021175 },
  { codeId: 20, companyCode: 300023952 },
  { codeId: 53, companyCode: 300024303 },
  { codeId: 53, companyCode: 300024748 },
  { codeId: 19, companyCode: 300024901 },
  { codeId: 53, companyCode: 300025992 },
  { codeId: 53, companyCode: 300026343 },
  { codeId: 42, companyCode: 300026464 },
  { codeId: 53, companyCode: 300027737 },
  { codeId: 58, companyCode: 300028305 },
  { codeId: 53, companyCode: 300029453 },
  { codeId: 53, companyCode: 300031881 },
  { codeId: 53, companyCode: 300032232 },
  { codeId: 53, companyCode: 300032556 },
  { codeId: 20, companyCode: 300033028 },
  { codeId: 19, companyCode: 300034055 },
  { codeId: 43, companyCode: 300034817 },
  { codeId: 34, companyCode: 300035577 },
  { codeId: 23, companyCode: 300036490 },
  { codeId: 3, companyCode: 300037101 },
  { codeId: 35, companyCode: 300037592 },
  { codeId: 34, companyCode: 300038445 },
  { codeId: 34, companyCode: 300038872 },
  { codeId: 34, companyCode: 300039390 },
  { codeId: 6, companyCode: 300039561 },
  { codeId: 64, companyCode: 300039860 },
  { codeId: 20, companyCode: 300040784 },
  { codeId: 53, companyCode: 300040898 },
  { codeId: 17, companyCode: 300041039 },
  { codeId: 49, companyCode: 300041523 },
  { codeId: 53, companyCode: 300042792 },
  { codeId: 35, companyCode: 300042810 },
  { codeId: 20, companyCode: 300043296 },
  { codeId: 44, companyCode: 300044551 },
  { codeId: 16, companyCode: 300044747 },
  { codeId: 19, companyCode: 300045482 },
  { codeId: 58, companyCode: 300045678 },
  { codeId: 21, companyCode: 300045781 },
  { codeId: 53, companyCode: 300045920 },
  { codeId: 61, companyCode: 300046029 },
  { codeId: 19, companyCode: 300046538 },
  { codeId: 53, companyCode: 300046641 },
  { codeId: 48, companyCode: 300046851 },
  { codeId: 4, companyCode: 300047914 },
  { codeId: 19, companyCode: 300049203 },
  { codeId: 34, companyCode: 300050141 },
  { codeId: 19, companyCode: 300051229 },
  { codeId: 16, companyCode: 300051485 },
  { codeId: 53, companyCode: 300051599 },
  { codeId: 34, companyCode: 300052352 },
  { codeId: 34, companyCode: 300052929 },
  { codeId: 60, companyCode: 300054175 },
  { codeId: 20, companyCode: 300055409 },
  { codeId: 19, companyCode: 300055494 },
  { codeId: 52, companyCode: 300057310 },
  { codeId: 16, companyCode: 300057997 },
  { codeId: 61, companyCode: 300058152 },
  { codeId: 19, companyCode: 300058191 },
  { codeId: 24, companyCode: 300058782 },
  { codeId: 53, companyCode: 300060908 },
  { codeId: 16, companyCode: 300061180 },
  { codeId: 49, companyCode: 300061547 },
  { codeId: 16, companyCode: 300061814 },
  { codeId: 20, companyCode: 300062688 },
  { codeId: 54, companyCode: 300063434 },
  { codeId: 34, companyCode: 300063815 },
  { codeId: 26, companyCode: 300063822 },
  { codeId: 45, companyCode: 300063847 },
  { codeId: 53, companyCode: 300064116 },
  { codeId: 63, companyCode: 300064237 },
  { codeId: 16, companyCode: 300065257 },
  { codeId: 49, companyCode: 300066398 },
  { codeId: 34, companyCode: 300066964 },
  { codeId: 53, companyCode: 300067151 },
  { codeId: 19, companyCode: 300067386 },
  { codeId: 58, companyCode: 300068520 },
  { codeId: 40, companyCode: 300069825 },
  { codeId: 20, companyCode: 300070304 },
  { codeId: 10, companyCode: 300070877 },
  { codeId: 12, companyCode: 300070959 },
  { codeId: 6, companyCode: 300071331 },
  { codeId: 10, companyCode: 300071915 },
  { codeId: 19, companyCode: 300072401 },
  { codeId: 57, companyCode: 300073065 },
  { codeId: 57, companyCode: 300073161 },
  { codeId: 24, companyCode: 300073229 },
  { codeId: 38, companyCode: 300073535 },
  { codeId: 60, companyCode: 300073802 },
  { codeId: 57, companyCode: 300075290 },
  { codeId: 57, companyCode: 300076467 },
  { codeId: 3, companyCode: 300076741 },
  { codeId: 53, companyCode: 300076798 },
  { codeId: 4, companyCode: 300077583 },
  { codeId: 19, companyCode: 300077697 },
  { codeId: 45, companyCode: 300078429 },
  { codeId: 16, companyCode: 300078817 },
  { codeId: 16, companyCode: 300079844 },
  { codeId: 35, companyCode: 300080017 },
  { codeId: 53, companyCode: 300080259 },
  { codeId: 53, companyCode: 300080661 },
  { codeId: 19, companyCode: 300081496 },
  { codeId: 52, companyCode: 300082153 },
  { codeId: 19, companyCode: 300082737 },
  { codeId: 16, companyCode: 300083127 },
  { codeId: 61, companyCode: 300083878 },
  { codeId: 64, companyCode: 300084072 },
  { codeId: 19, companyCode: 300084179 },
  { codeId: 3, companyCode: 300084236 },
  { codeId: 53, companyCode: 300085719 },
  { codeId: 53, companyCode: 300085733 },
  { codeId: 19, companyCode: 300087353 },
  { codeId: 9, companyCode: 300088277 },
  { codeId: 19, companyCode: 300088619 },
  { codeId: 24, companyCode: 300088793 },
  { codeId: 31, companyCode: 300089105 },
  { codeId: 45, companyCode: 300089315 },
  { codeId: 19, companyCode: 300089728 },
  { codeId: 35, companyCode: 300090730 },
  { codeId: 49, companyCode: 300091330 },
  { codeId: 24, companyCode: 300092090 },
  { codeId: 53, companyCode: 300092724 },
  { codeId: 61, companyCode: 300092998 },
  { codeId: 20, companyCode: 300093477 },
  { codeId: 16, companyCode: 300093687 },
  { codeId: 35, companyCode: 300095617 },
  { codeId: 19, companyCode: 300095834 },
  { codeId: 45, companyCode: 300095898 },
  { codeId: 20, companyCode: 300096726 },
  { codeId: 5, companyCode: 300097027 },
  { codeId: 34, companyCode: 300097582 },
  { codeId: 40, companyCode: 300097746 },
  { codeId: 57, companyCode: 300098104 },
  { codeId: 53, companyCode: 300098524 },
  { codeId: 53, companyCode: 300100644 },
  { codeId: 53, companyCode: 300103213 },
  { codeId: 53, companyCode: 300104258 },
  { codeId: 53, companyCode: 300104614 },
  { codeId: 40, companyCode: 300104831 },
  { codeId: 53, companyCode: 300104906 },
  { codeId: 53, companyCode: 300104977 },
  { codeId: 58, companyCode: 300105805 },
  { codeId: 53, companyCode: 300106476 },
  { codeId: 53, companyCode: 300108178 },
  { codeId: 9, companyCode: 300110161 },
  { codeId: 58, companyCode: 300110282 },
  { codeId: 53, companyCode: 300110503 },
  { codeId: 35, companyCode: 300110649 },
  { codeId: 58, companyCode: 300110784 },
  { codeId: 19, companyCode: 300110791 },
  { codeId: 34, companyCode: 300111338 },
  { codeId: 48, companyCode: 300113830 },
  { codeId: 27, companyCode: 300114124 },
  { codeId: 16, companyCode: 300114291 },
  { codeId: 53, companyCode: 300114359 },
  { codeId: 19, companyCode: 300114633 },
  { codeId: 16, companyCode: 300115105 },
  { codeId: 19, companyCode: 300115233 },
  { codeId: 19, companyCode: 300115404 },
  { codeId: 49, companyCode: 300116278 },
  { codeId: 20, companyCode: 300116456 },
  { codeId: 53, companyCode: 300116545 },
  { codeId: 16, companyCode: 300117177 },
  { codeId: 58, companyCode: 300118051 },
  { codeId: 53, companyCode: 300118592 },
  { codeId: 19, companyCode: 300119623 },
  { codeId: 45, companyCode: 300120390 },
  { codeId: 16, companyCode: 300120515 },
  { codeId: 19, companyCode: 300122306 },
  { codeId: 19, companyCode: 300122459 },
  { codeId: 53, companyCode: 300124759 },
  { codeId: 61, companyCode: 300127004 },
  { codeId: 53, companyCode: 300127342 },
  { codeId: 53, companyCode: 300127470 },
  { codeId: 20, companyCode: 300128070 },
  { codeId: 3, companyCode: 300128362 },
  { codeId: 17, companyCode: 300128597 },
  { codeId: 58, companyCode: 300128818 },
  { codeId: 19, companyCode: 300128914 },
  { codeId: 3, companyCode: 300129119 },
  { codeId: 40, companyCode: 300129521 },
  { codeId: 53, companyCode: 300129838 },
  { codeId: 19, companyCode: 300130566 },
  { codeId: 19, companyCode: 300131209 },
  { codeId: 16, companyCode: 300132040 },
  { codeId: 34, companyCode: 300132364 },
  { codeId: 16, companyCode: 300132752 },
  { codeId: 53, companyCode: 300132909 },
  { codeId: 33, companyCode: 300133391 },
  { codeId: 49, companyCode: 300134283 },
  { codeId: 20, companyCode: 300134600 },
  { codeId: 34, companyCode: 300136284 },
  { codeId: 57, companyCode: 300136779 },
  { codeId: 19, companyCode: 300137518 },
  { codeId: 57, companyCode: 300139266 },
  { codeId: 49, companyCode: 300139736 },
  { codeId: 45, companyCode: 300143485 },
  { codeId: 4, companyCode: 300146289 },
  { codeId: 31, companyCode: 300146524 },
  { codeId: 34, companyCode: 300146759 },
  { codeId: 49, companyCode: 300148685 },
  { codeId: 53, companyCode: 300148831 },
  { codeId: 53, companyCode: 300149812 },
  { codeId: 20, companyCode: 300150451 },
  { codeId: 19, companyCode: 300151934 },
  { codeId: 20, companyCode: 300153860 },
  { codeId: 58, companyCode: 300154161 },
  { codeId: 16, companyCode: 300154916 },
  { codeId: 58, companyCode: 300500236 },
  { codeId: 34, companyCode: 300501498 },
  { codeId: 60, companyCode: 300501975 },
  { codeId: 34, companyCode: 300503086 },
  { codeId: 33, companyCode: 300504576 },
  { codeId: 53, companyCode: 300504811 },
  { codeId: 13, companyCode: 300508724 },
  { codeId: 16, companyCode: 300508756 },
  { codeId: 26, companyCode: 300509491 },
  { codeId: 53, companyCode: 300509534 },
  { codeId: 16, companyCode: 300509694 },
  { codeId: 4, companyCode: 300512295 },
  { codeId: 58, companyCode: 300513244 },
  { codeId: 21, companyCode: 300513600 },
  { codeId: 52, companyCode: 300513664 },
  { codeId: 19, companyCode: 300513696 },
  { codeId: 58, companyCode: 300514022 },
  { codeId: 21, companyCode: 300514129 },
  { codeId: 49, companyCode: 300516781 },
  { codeId: 21, companyCode: 300516863 },
  { codeId: 19, companyCode: 300517100 },
  { codeId: 50, companyCode: 300518362 },
  { codeId: 53, companyCode: 300520491 },
  { codeId: 53, companyCode: 300520915 },
  { codeId: 53, companyCode: 300521593 },
  { codeId: 16, companyCode: 300524582 },
  { codeId: 19, companyCode: 300524632 },
  { codeId: 53, companyCode: 300524828 },
  { codeId: 58, companyCode: 300525435 },
  { codeId: 20, companyCode: 300526583 },
  { codeId: 53, companyCode: 300526626 },
  { codeId: 19, companyCode: 300527518 },
  { codeId: 12, companyCode: 300527557 },
  { codeId: 16, companyCode: 300529387 },
  { codeId: 17, companyCode: 300531210 },
  { codeId: 61, companyCode: 300531616 },
  { codeId: 3, companyCode: 300531630 },
  { codeId: 31, companyCode: 300533503 },
  { codeId: 19, companyCode: 300534441 },
  { codeId: 34, companyCode: 300535511 },
  { codeId: 19, companyCode: 300536613 },
  { codeId: 61, companyCode: 300536684 },
  { codeId: 48, companyCode: 300536727 },
  { codeId: 16, companyCode: 300541546 },
  { codeId: 3, companyCode: 300541966 },
  { codeId: 64, companyCode: 300542331 },
  { codeId: 58, companyCode: 300543725 },
  { codeId: 53, companyCode: 300544802 },
  { codeId: 21, companyCode: 300547524 },
  { codeId: 57, companyCode: 300547944 },
  { codeId: 49, companyCode: 300548875 },
  { codeId: 3, companyCode: 300549525 },
  { codeId: 53, companyCode: 300550196 },
  { codeId: 19, companyCode: 300550310 },
  { codeId: 19, companyCode: 300553210 },
  { codeId: 53, companyCode: 300554376 },
  { codeId: 53, companyCode: 300554401 },
  { codeId: 53, companyCode: 300555695 },
  { codeId: 48, companyCode: 300556651 },
  { codeId: 34, companyCode: 300556847 },
  { codeId: 20, companyCode: 300557454 },
  { codeId: 58, companyCode: 300559067 },
  { codeId: 53, companyCode: 300559868 },
  { codeId: 58, companyCode: 300560817 },
  { codeId: 38, companyCode: 300561837 },
  { codeId: 53, companyCode: 300561851 },
  { codeId: 24, companyCode: 300562729 },
  { codeId: 53, companyCode: 300563749 },
  { codeId: 64, companyCode: 300564961 },
  { codeId: 57, companyCode: 300565700 },
  { codeId: 58, companyCode: 300565839 },
  { codeId: 20, companyCode: 300566332 },
  { codeId: 53, companyCode: 300566841 },
  { codeId: 19, companyCode: 300567352 },
  { codeId: 11, companyCode: 300568810 },
  { codeId: 16, companyCode: 300569111 },
  { codeId: 16, companyCode: 300569346 },
  { codeId: 57, companyCode: 300571443 },
  { codeId: 40, companyCode: 300571571 },
  { codeId: 53, companyCode: 300573736 },
  { codeId: 34, companyCode: 300573864 },
  { codeId: 53, companyCode: 300573914 },
  { codeId: 53, companyCode: 300575744 },
  { codeId: 1, companyCode: 300575872 },
  { codeId: 21, companyCode: 300576006 },
  { codeId: 21, companyCode: 300576077 },
  { codeId: 58, companyCode: 300576942 },
  { codeId: 21, companyCode: 300577300 },
  { codeId: 34, companyCode: 300577421 },
  { codeId: 46, companyCode: 300577827 },
  { codeId: 53, companyCode: 300580499 },
  { codeId: 53, companyCode: 300582308 },
  { codeId: 16, companyCode: 300583641 },
  { codeId: 53, companyCode: 300583748 },
  { codeId: 45, companyCode: 300583958 },
  { codeId: 16, companyCode: 300584355 },
  { codeId: 53, companyCode: 300584608 },
  { codeId: 19, companyCode: 300585165 },
  { codeId: 34, companyCode: 300585902 },
  { codeId: 20, companyCode: 300586178 },
  { codeId: 34, companyCode: 300587344 },
  { codeId: 53, companyCode: 300589060 },
  { codeId: 53, companyCode: 300590739 },
  { codeId: 53, companyCode: 300591314 },
  { codeId: 40, companyCode: 300592067 },
  { codeId: 16, companyCode: 300592302 },
  { codeId: 58, companyCode: 300592665 },
  { codeId: 16, companyCode: 300593500 },
  { codeId: 58, companyCode: 300593653 },
  { codeId: 27, companyCode: 300594552 },
  { codeId: 35, companyCode: 300595686 },
  { codeId: 26, companyCode: 300596717 },
  { codeId: 20, companyCode: 300597502 },
  { codeId: 57, companyCode: 300597826 },
  { codeId: 53, companyCode: 300598077 },
  { codeId: 20, companyCode: 300598294 },
  { codeId: 40, companyCode: 300598522 },
  { codeId: 53, companyCode: 300599930 },
  { codeId: 35, companyCode: 300600140 },
  { codeId: 40, companyCode: 300601000 },
  { codeId: 57, companyCode: 300601406 },
  { codeId: 21, companyCode: 300601534 },
  { codeId: 53, companyCode: 300603617 },
  { codeId: 16, companyCode: 300604199 },
  { codeId: 53, companyCode: 300604338 },
  { codeId: 24, companyCode: 300604612 },
  { codeId: 26, companyCode: 300604950 },
  { codeId: 16, companyCode: 300605244 },
  { codeId: 16, companyCode: 300605319 },
  { codeId: 45, companyCode: 300606047 },
  { codeId: 41, companyCode: 300608190 },
  { codeId: 53, companyCode: 300608475 },
  { codeId: 53, companyCode: 300610679 },
  { codeId: 19, companyCode: 300611083 },
  { codeId: 53, companyCode: 300612954 },
  { codeId: 53, companyCode: 300614040 },
  { codeId: 57, companyCode: 300614193 },
  { codeId: 19, companyCode: 300614446 },
  { codeId: 58, companyCode: 300614752 },
  { codeId: 57, companyCode: 300615384 },
  { codeId: 34, companyCode: 300615459 },
  { codeId: 16, companyCode: 300616746 },
  { codeId: 3, companyCode: 300616792 },
  { codeId: 38, companyCode: 300616842 },
  { codeId: 19, companyCode: 300616970 },
  { codeId: 58, companyCode: 300618708 },
  { codeId: 50, companyCode: 300618971 },
  { codeId: 16, companyCode: 300619571 },
  { codeId: 23, companyCode: 300621266 },
  { codeId: 61, companyCode: 300621750 },
  { codeId: 57, companyCode: 300622293 },
  { codeId: 21, companyCode: 300623413 },
  { codeId: 58, companyCode: 300623452 },
  { codeId: 40, companyCode: 300623979 },
  { codeId: 16, companyCode: 300624216 },
  { codeId: 16, companyCode: 300625670 },
  { codeId: 51, companyCode: 300626295 },
  { codeId: 53, companyCode: 300626548 },
  { codeId: 16, companyCode: 300626701 },
  { codeId: 57, companyCode: 300627397 },
  { codeId: 16, companyCode: 300628937 },
  { codeId: 53, companyCode: 300629163 },
  { codeId: 3, companyCode: 300629430 },
  { codeId: 40, companyCode: 300629569 },
  { codeId: 35, companyCode: 300630728 },
  { codeId: 58, companyCode: 300631260 },
  { codeId: 53, companyCode: 300631997 },
  { codeId: 53, companyCode: 300632782 },
  { codeId: 24, companyCode: 300632946 },
  { codeId: 62, companyCode: 300634107 },
  { codeId: 53, companyCode: 300634598 },
  { codeId: 40, companyCode: 300636364 },
  { codeId: 35, companyCode: 300637562 },
  { codeId: 53, companyCode: 300637651 },
  { codeId: 16, companyCode: 300638778 },
  { codeId: 53, companyCode: 300639307 },
  { codeId: 53, companyCode: 300639403 },
  { codeId: 34, companyCode: 300642584 },
  { codeId: 53, companyCode: 300645833 },
  { codeId: 53, companyCode: 300646141 },
  { codeId: 11, companyCode: 300646604 },
  { codeId: 53, companyCode: 300647357 },
  { codeId: 58, companyCode: 300650296 },
  { codeId: 53, companyCode: 300650403 },
  { codeId: 53, companyCode: 300651252 },
  { codeId: 53, companyCode: 300651291 },
  { codeId: 53, companyCode: 300651804 },
  { codeId: 19, companyCode: 300654138 },
  { codeId: 53, companyCode: 300655610 },
  { codeId: 57, companyCode: 300656737 },
  { codeId: 34, companyCode: 300656961 },
  { codeId: 53, companyCode: 300657376 },
  { codeId: 20, companyCode: 300657700 },
  { codeId: 53, companyCode: 300659516 },
  { codeId: 16, companyCode: 300659936 },
  { codeId: 50, companyCode: 300660319 },
  { codeId: 16, companyCode: 300660550 },
  { codeId: 19, companyCode: 300663660 },
  { codeId: 38, companyCode: 300663692 },
  { codeId: 50, companyCode: 300666553 },
  { codeId: 16, companyCode: 300667057 },
  { codeId: 20, companyCode: 300667192 },
  { codeId: 58, companyCode: 300667274 },
  { codeId: 58, companyCode: 300669930 },
  { codeId: 53, companyCode: 300670181 },
  { codeId: 19, companyCode: 300670441 },
  { codeId: 21, companyCode: 300677336 },
  { codeId: 21, companyCode: 300701207 },
  { codeId: 53, companyCode: 300702191 },
  { codeId: 53, companyCode: 300709563 },
  { codeId: 33, companyCode: 300711379 },
  { codeId: 53, companyCode: 300731517 },
  { codeId: 45, companyCode: 300753442 },
  { codeId: 53, companyCode: 300764474 },
  { codeId: 16, companyCode: 300766639 },
  { codeId: 16, companyCode: 300772439 },
  { codeId: 53, companyCode: 300774447 },
  { codeId: 53, companyCode: 300802448 },
  { codeId: 19, companyCode: 300812435 },
  { codeId: 53, companyCode: 300814436 },
  { codeId: 19, companyCode: 300818445 },
  { codeId: 63, companyCode: 300823481 },
  { codeId: 64, companyCode: 300834435 },
  { codeId: 61, companyCode: 300835462 },
  { codeId: 53, companyCode: 300845563 },
  { codeId: 45, companyCode: 300847468 },
  { codeId: 40, companyCode: 300849519 },
  { codeId: 64, companyCode: 300851840 },
  { codeId: 3, companyCode: 300855778 },
  { codeId: 58, companyCode: 300857722 },
  { codeId: 16, companyCode: 300865904 },
  { codeId: 34, companyCode: 300867008 },
  { codeId: 53, companyCode: 300869607 },
  { codeId: 58, companyCode: 300870666 },
  { codeId: 53, companyCode: 300870869 },
  { codeId: 16, companyCode: 300870965 },
  { codeId: 48, companyCode: 300878556 },
  { codeId: 53, companyCode: 300882736 },
  { codeId: 53, companyCode: 300883610 },
  { codeId: 19, companyCode: 300883756 },
  { codeId: 53, companyCode: 300886777 },
  { codeId: 37, companyCode: 300886859 },
  { codeId: 24, companyCode: 300886880 },
  { codeId: 53, companyCode: 300887676 },
  { codeId: 35, companyCode: 300887943 },
  { codeId: 58, companyCode: 300892709 },
  { codeId: 45, companyCode: 300895598 },
  { codeId: 35, companyCode: 300895609 },
  { codeId: 40, companyCode: 300899696 },
  { codeId: 40, companyCode: 300902683 },
  { codeId: 40, companyCode: 300904691 },
  { codeId: 19, companyCode: 300905583 },
  { codeId: 45, companyCode: 300908533 },
  { codeId: 63, companyCode: 300909628 },
  { codeId: 53, companyCode: 300911700 },
  { codeId: 50, companyCode: 300916537 },
  { codeId: 19, companyCode: 300917824 },
  { codeId: 47, companyCode: 300931681 },
  { codeId: 53, companyCode: 300945535 },
  { codeId: 53, companyCode: 300958575 },
  { codeId: 57, companyCode: 300959549 },
  { codeId: 3, companyCode: 300959588 },
  { codeId: 53, companyCode: 300959606 },
  { codeId: 40, companyCode: 300960583 },
  { codeId: 20, companyCode: 300960672 },
  { codeId: 21, companyCode: 300970606 },
  { codeId: 19, companyCode: 300974533 },
  { codeId: 58, companyCode: 300974540 },
  { codeId: 53, companyCode: 300976705 },
  { codeId: 58, companyCode: 300989574 },
  { codeId: 53, companyCode: 301005793 },
  { codeId: 34, companyCode: 301007552 },
  { codeId: 34, companyCode: 301011643 },
  { codeId: 35, companyCode: 301019775 },
  { codeId: 19, companyCode: 301033547 },
  { codeId: 23, companyCode: 301049544 },
  { codeId: 53, companyCode: 301057637 },
  { codeId: 57, companyCode: 301060551 },
  { codeId: 12, companyCode: 301064539 },
  { codeId: 53, companyCode: 301064546 },
  { codeId: 3, companyCode: 301086044 },
  { codeId: 53, companyCode: 301088547 },
  { codeId: 53, companyCode: 301089599 },
  { codeId: 11, companyCode: 301089649 },
  { codeId: 53, companyCode: 301089656 },
  { codeId: 34, companyCode: 301095538 },
  { codeId: 64, companyCode: 301098712 },
  { codeId: 3, companyCode: 301098737 },
  { codeId: 53, companyCode: 301102616 },
  { codeId: 34, companyCode: 301105562 },
  { codeId: 19, companyCode: 301106778 },
  { codeId: 53, companyCode: 301126791 },
  { codeId: 19, companyCode: 301128575 },
  { codeId: 53, companyCode: 301133985 },
  { codeId: 45, companyCode: 301134012 },
  { codeId: 3, companyCode: 301142571 },
  { codeId: 16, companyCode: 301142639 },
  { codeId: 16, companyCode: 301147807 },
  { codeId: 19, companyCode: 301149911 },
  { codeId: 58, companyCode: 301150568 },
  { codeId: 16, companyCode: 301150664 },
  { codeId: 16, companyCode: 301150671 },
  { codeId: 16, companyCode: 301151848 },
  { codeId: 53, companyCode: 301152003 },
  { codeId: 27, companyCode: 301152544 },
  { codeId: 53, companyCode: 301162588 },
  { codeId: 34, companyCode: 301163569 },
  { codeId: 21, companyCode: 301168655 },
  { codeId: 58, companyCode: 301169796 },
  { codeId: 53, companyCode: 301170720 },
  { codeId: 53, companyCode: 301170827 },
  { codeId: 3, companyCode: 301171249 },
  { codeId: 53, companyCode: 301174583 },
  { codeId: 16, companyCode: 301188572 },
  { codeId: 20, companyCode: 301196537 },
  { codeId: 48, companyCode: 301206650 },
  { codeId: 57, companyCode: 301206732 },
  { codeId: 50, companyCode: 301227559 },
  { codeId: 53, companyCode: 301240738 },
  { codeId: 35, companyCode: 301247533 },
  { codeId: 40, companyCode: 301249865 },
  { codeId: 64, companyCode: 301250554 },
  { codeId: 21, companyCode: 301272764 },
  { codeId: 34, companyCode: 301275536 },
  { codeId: 16, companyCode: 301275582 },
  { codeId: 58, companyCode: 301276531 },
  { codeId: 53, companyCode: 301319566 },
  { codeId: 3, companyCode: 301355530 },
  { codeId: 33, companyCode: 301385534 },
  { codeId: 34, companyCode: 301405549 },
  { codeId: 52, companyCode: 301432539 },
  { codeId: 58, companyCode: 301440532 },
  { codeId: 57, companyCode: 301472537 },
  { codeId: 21, companyCode: 301489732 },
  { codeId: 3, companyCode: 301490542 },
  { codeId: 34, companyCode: 301498667 },
  { codeId: 35, companyCode: 301498973 },
  { codeId: 53, companyCode: 301505631 },
  { codeId: 3, companyCode: 301505859 },
  { codeId: 15, companyCode: 301506078 },
  { codeId: 53, companyCode: 301509626 },
  { codeId: 61, companyCode: 301519549 },
  { codeId: 16, companyCode: 301526636 },
  { codeId: 53, companyCode: 301530556 },
  { codeId: 34, companyCode: 301530570 },
  { codeId: 53, companyCode: 301531672 },
  { codeId: 53, companyCode: 301532991 },
  { codeId: 53, companyCode: 301533285 },
  { codeId: 34, companyCode: 301534622 },
  { codeId: 21, companyCode: 301534686 },
  { codeId: 57, companyCode: 301534693 },
  { codeId: 21, companyCode: 301537077 },
  { codeId: 31, companyCode: 301537305 },
  { codeId: 53, companyCode: 301537376 },
  { codeId: 19, companyCode: 301538122 },
  { codeId: 53, companyCode: 301538186 },
  { codeId: 9, companyCode: 301538649 },
  { codeId: 6, companyCode: 301540625 },
  { codeId: 64, companyCode: 301540739 },
  { codeId: 19, companyCode: 301540867 },
  { codeId: 43, companyCode: 301541079 },
  { codeId: 57, companyCode: 301541741 },
  { codeId: 53, companyCode: 301544545 },
  { codeId: 18, companyCode: 301549599 },
  { codeId: 34, companyCode: 301549738 },
  { codeId: 19, companyCode: 301554596 },
  { codeId: 19, companyCode: 301554607 },
  { codeId: 19, companyCode: 301555591 },
  { codeId: 53, companyCode: 301560649 },
  { codeId: 58, companyCode: 301566716 },
  { codeId: 53, companyCode: 301567679 },
  { codeId: 53, companyCode: 301582542 },
  { codeId: 16, companyCode: 301585823 },
  { codeId: 19, companyCode: 301592903 },
  { codeId: 20, companyCode: 301592942 },
  { codeId: 40, companyCode: 301593670 },
  { codeId: 60, companyCode: 301595632 },
  { codeId: 49, companyCode: 301608649 },
  { codeId: 48, companyCode: 301608994 },
  { codeId: 53, companyCode: 301611531 },
  { codeId: 50, companyCode: 301616539 },
  { codeId: 34, companyCode: 301628783 },
  { codeId: 16, companyCode: 301635692 },
  { codeId: 64, companyCode: 301645583 },
  { codeId: 53, companyCode: 301645601 },
  { codeId: 15, companyCode: 301645697 },
  { codeId: 48, companyCode: 301672694 },
  { codeId: 53, companyCode: 301673038 },
  { codeId: 58, companyCode: 301673515 },
  { codeId: 64, companyCode: 301673604 },
  { codeId: 53, companyCode: 301673668 },
  { codeId: 53, companyCode: 301673839 },
  { codeId: 6, companyCode: 301673928 },
  { codeId: 53, companyCode: 301674478 },
  { codeId: 58, companyCode: 301675719 },
  { codeId: 11, companyCode: 301676618 },
  { codeId: 19, companyCode: 301678590 },
  { codeId: 19, companyCode: 301694566 },
  { codeId: 4, companyCode: 301694687 },
  { codeId: 53, companyCode: 301695903 },
  { codeId: 53, companyCode: 301696898 },
  { codeId: 19, companyCode: 301697014 },
  { codeId: 45, companyCode: 301699531 },
  { codeId: 40, companyCode: 301699702 },
  { codeId: 58, companyCode: 301724582 },
  { codeId: 58, companyCode: 301724618 },
  { codeId: 58, companyCode: 301724632 },
  { codeId: 58, companyCode: 301724664 },
  { codeId: 58, companyCode: 301725531 },
  { codeId: 58, companyCode: 301726537 },
  { codeId: 21, companyCode: 301727692 },
  { codeId: 58, companyCode: 301729832 },
  { codeId: 16, companyCode: 301729946 },
  { codeId: 23, companyCode: 301730724 },
  { codeId: 28, companyCode: 301732173 },
  { codeId: 35, companyCode: 301732198 },
  { codeId: 53, companyCode: 301732312 },
  { codeId: 19, companyCode: 301733396 },
  { codeId: 11, companyCode: 301733421 },
  { codeId: 19, companyCode: 301733827 },
  { codeId: 34, companyCode: 301734078 },
  { codeId: 19, companyCode: 301734167 },
  { codeId: 53, companyCode: 301735664 },
  { codeId: 53, companyCode: 301735721 },
  { codeId: 58, companyCode: 301735753 },
  { codeId: 34, companyCode: 301735785 },
  { codeId: 53, companyCode: 301736549 },
  { codeId: 19, companyCode: 301736702 },
  { codeId: 53, companyCode: 301737697 },
  { codeId: 20, companyCode: 301737779 },
  { codeId: 19, companyCode: 301737989 },
  { codeId: 53, companyCode: 301738653 },
  { codeId: 19, companyCode: 301741368 },
  { codeId: 53, companyCode: 301741521 },
  { codeId: 20, companyCode: 301741959 },
  { codeId: 53, companyCode: 301743636 },
  { codeId: 37, companyCode: 301744542 },
  { codeId: 53, companyCode: 301785585 },
  { codeId: 19, companyCode: 301790582 },
  { codeId: 34, companyCode: 301791798 },
  { codeId: 58, companyCode: 301794755 },
  { codeId: 53, companyCode: 301806702 },
  { codeId: 25, companyCode: 301815605 },
  { codeId: 58, companyCode: 301817830 },
  { codeId: 34, companyCode: 301818042 },
  { codeId: 58, companyCode: 301819621 },
  { codeId: 15, companyCode: 301821540 },
  { codeId: 34, companyCode: 301824682 },
  { codeId: 58, companyCode: 301835682 },
  { codeId: 57, companyCode: 301836535 },
  { codeId: 40, companyCode: 301841703 },
  { codeId: 21, companyCode: 301841984 },
  { codeId: 34, companyCode: 301842837 },
  { codeId: 19, companyCode: 301842926 },
  { codeId: 34, companyCode: 301843597 },
  { codeId: 20, companyCode: 301844375 },
  { codeId: 35, companyCode: 301845694 },
  { codeId: 60, companyCode: 301846604 },
  { codeId: 34, companyCode: 301850773 },
  { codeId: 58, companyCode: 301851590 },
  { codeId: 35, companyCode: 301851633 },
  { codeId: 34, companyCode: 301852692 },
  { codeId: 4, companyCode: 301879554 },
  { codeId: 16, companyCode: 301880542 },
  { codeId: 53, companyCode: 301881772 },
  { codeId: 40, companyCode: 301886577 },
  { codeId: 16, companyCode: 301886794 },
  { codeId: 53, companyCode: 301887572 },
  { codeId: 53, companyCode: 301901538 },
  { codeId: 53, companyCode: 301911557 },
  { codeId: 19, companyCode: 301925585 },
  { codeId: 34, companyCode: 301928574 },
  { codeId: 21, companyCode: 301934545 },
  { codeId: 34, companyCode: 301945538 },
  { codeId: 31, companyCode: 301966607 },
  { codeId: 16, companyCode: 302031563 },
  { codeId: 19, companyCode: 302036546 },
  { codeId: 53, companyCode: 302041600 },
  { codeId: 58, companyCode: 302091592 },
  { codeId: 53, companyCode: 302097549 },
  { codeId: 3, companyCode: 302198530 },
  { codeId: 19, companyCode: 302240730 },
  { codeId: 34, companyCode: 302240755 },
  { codeId: 53, companyCode: 302243580 },
  { codeId: 58, companyCode: 302245535 },
  { codeId: 58, companyCode: 302245542 },
  { codeId: 58, companyCode: 302245567 },
  { codeId: 34, companyCode: 302247689 },
  { codeId: 49, companyCode: 302248143 },
  { codeId: 58, companyCode: 302248481 },
  { codeId: 53, companyCode: 302248499 },
  { codeId: 16, companyCode: 302248809 },
  { codeId: 53, companyCode: 302249074 },
  { codeId: 44, companyCode: 302249277 },
  { codeId: 40, companyCode: 302249462 },
  { codeId: 60, companyCode: 302250500 },
  { codeId: 53, companyCode: 302250532 },
  { codeId: 21, companyCode: 302250735 },
  { codeId: 49, companyCode: 302251100 },
  { codeId: 53, companyCode: 302251762 },
  { codeId: 19, companyCode: 302251958 },
  { codeId: 47, companyCode: 302254178 },
  { codeId: 53, companyCode: 302254662 },
  { codeId: 19, companyCode: 302276609 },
  { codeId: 19, companyCode: 302278574 },
  { codeId: 40, companyCode: 302281588 },
  { codeId: 23, companyCode: 302283532 },
  { codeId: 11, companyCode: 302290683 },
  { codeId: 43, companyCode: 302290719 },
  { codeId: 64, companyCode: 302290758 },
  { codeId: 58, companyCode: 302291155 },
  { codeId: 58, companyCode: 302291162 },
  { codeId: 58, companyCode: 302291187 },
  { codeId: 49, companyCode: 302295890 },
  { codeId: 47, companyCode: 302295901 },
  { codeId: 58, companyCode: 302296501 },
  { codeId: 19, companyCode: 302297304 },
  { codeId: 9, companyCode: 302298413 },
  { codeId: 49, companyCode: 302299401 },
  { codeId: 58, companyCode: 302301254 },
  { codeId: 53, companyCode: 302301724 },
  { codeId: 20, companyCode: 302302139 },
  { codeId: 57, companyCode: 302302826 },
  { codeId: 61, companyCode: 302302833 },
  { codeId: 4, companyCode: 302303383 },
  { codeId: 19, companyCode: 302305053 },
  { codeId: 9, companyCode: 302305480 },
  { codeId: 62, companyCode: 302305815 },
  { codeId: 6, companyCode: 302306671 },
  { codeId: 49, companyCode: 302306949 },
  { codeId: 34, companyCode: 302308245 },
  { codeId: 28, companyCode: 302308423 },
  { codeId: 3, companyCode: 302308868 },
  { codeId: 37, companyCode: 302309429 },
  { codeId: 58, companyCode: 302310545 },
  { codeId: 16, companyCode: 302311031 },
  { codeId: 19, companyCode: 302311686 },
  { codeId: 58, companyCode: 302315282 },
  { codeId: 35, companyCode: 302315752 },
  { codeId: 58, companyCode: 302316441 },
  { codeId: 57, companyCode: 302316555 },
  { codeId: 53, companyCode: 302316701 },
  { codeId: 34, companyCode: 302317333 },
  { codeId: 19, companyCode: 302320386 },
  { codeId: 53, companyCode: 302320429 },
  { codeId: 20, companyCode: 302323507 },
  { codeId: 53, companyCode: 302324534 },
  { codeId: 20, companyCode: 302327288 },
  { codeId: 53, companyCode: 302327402 },
  { codeId: 53, companyCode: 302328276 },
  { codeId: 19, companyCode: 302328760 },
  { codeId: 57, companyCode: 302329015 },
  { codeId: 21, companyCode: 302329061 },
  { codeId: 50, companyCode: 302329385 },
  { codeId: 61, companyCode: 302329727 },
  { codeId: 53, companyCode: 302330373 },
  { codeId: 28, companyCode: 302330633 },
  { codeId: 34, companyCode: 302330918 },
  { codeId: 40, companyCode: 302330957 },
  { codeId: 9, companyCode: 302331023 },
  { codeId: 3, companyCode: 302331817 },
  { codeId: 20, companyCode: 302331831 },
  { codeId: 40, companyCode: 302332883 },
  { codeId: 53, companyCode: 302333572 },
  { codeId: 21, companyCode: 302334012 },
  { codeId: 19, companyCode: 302334261 },
  { codeId: 61, companyCode: 302334788 },
  { codeId: 61, companyCode: 302334877 },
  { codeId: 53, companyCode: 302335032 },
  { codeId: 34, companyCode: 302335107 },
  { codeId: 34, companyCode: 302337325 },
  { codeId: 9, companyCode: 302337994 },
  { codeId: 16, companyCode: 302338014 },
  { codeId: 34, companyCode: 302338427 },
  { codeId: 49, companyCode: 302338434 },
  { codeId: 53, companyCode: 302339109 },
  { codeId: 58, companyCode: 302340517 },
  { codeId: 57, companyCode: 302341284 },
  { codeId: 53, companyCode: 302341900 },
  { codeId: 53, companyCode: 302342653 },
  { codeId: 35, companyCode: 302342895 },
  { codeId: 20, companyCode: 302343075 },
  { codeId: 20, companyCode: 302343214 },
  { codeId: 34, companyCode: 302343673 },
  { codeId: 53, companyCode: 302345514 },
  { codeId: 4, companyCode: 302345635 },
  { codeId: 21, companyCode: 302347141 },
  { codeId: 53, companyCode: 302347369 },
  { codeId: 19, companyCode: 302350924 },
  { codeId: 4, companyCode: 302357267 },
  { codeId: 27, companyCode: 302360256 },
  { codeId: 3, companyCode: 302381268 },
  { codeId: 53, companyCode: 302382256 },
  { codeId: 58, companyCode: 302409301 },
  { codeId: 19, companyCode: 302409536 },
  { codeId: 27, companyCode: 302410129 },
  { codeId: 4, companyCode: 302410321 },
  { codeId: 33, companyCode: 302412482 },
  { codeId: 58, companyCode: 302412557 },
  { codeId: 58, companyCode: 302412614 },
  { codeId: 53, companyCode: 302413260 },
  { codeId: 50, companyCode: 302413926 },
  { codeId: 58, companyCode: 302415133 },
  { codeId: 19, companyCode: 302415521 },
  { codeId: 19, companyCode: 302416107 },
  { codeId: 58, companyCode: 302416331 },
  { codeId: 34, companyCode: 302416349 },
  { codeId: 53, companyCode: 302419434 },
  { codeId: 34, companyCode: 302420059 },
  { codeId: 16, companyCode: 302421022 },
  { codeId: 20, companyCode: 302421168 },
  { codeId: 53, companyCode: 302421339 },
  { codeId: 64, companyCode: 302421816 },
  { codeId: 19, companyCode: 302422373 },
  { codeId: 64, companyCode: 302423379 },
  { codeId: 53, companyCode: 302423404 },
  { codeId: 16, companyCode: 302423735 },
  { codeId: 19, companyCode: 302423824 },
  { codeId: 53, companyCode: 302424132 },
  { codeId: 40, companyCode: 302424634 },
  { codeId: 20, companyCode: 302424958 },
  { codeId: 57, companyCode: 302425330 },
  { codeId: 16, companyCode: 302426133 },
  { codeId: 58, companyCode: 302427000 },
  { codeId: 57, companyCode: 302427751 },
  { codeId: 16, companyCode: 302427769 },
  { codeId: 53, companyCode: 302427986 },
  { codeId: 58, companyCode: 302428472 },
  { codeId: 43, companyCode: 302428586 },
  { codeId: 57, companyCode: 302428604 },
  { codeId: 53, companyCode: 302428846 },
  { codeId: 53, companyCode: 302430459 },
  { codeId: 21, companyCode: 302430879 },
  { codeId: 53, companyCode: 302431778 },
  { codeId: 53, companyCode: 302432638 },
  { codeId: 53, companyCode: 302432862 },
  { codeId: 53, companyCode: 302433715 },
  { codeId: 58, companyCode: 302433804 },
  { codeId: 20, companyCode: 302433836 },
  { codeId: 48, companyCode: 302433932 },
  { codeId: 53, companyCode: 302434062 },
  { codeId: 53, companyCode: 302434468 },
  { codeId: 6, companyCode: 302434870 },
  { codeId: 21, companyCode: 302435278 },
  { codeId: 53, companyCode: 302435381 },
  { codeId: 20, companyCode: 302440414 },
  { codeId: 53, companyCode: 302440567 },
  { codeId: 19, companyCode: 302441480 },
  { codeId: 58, companyCode: 302441516 },
  { codeId: 53, companyCode: 302442696 },
  { codeId: 24, companyCode: 302442739 },
  { codeId: 53, companyCode: 302442746 },
  { codeId: 58, companyCode: 302443150 },
  { codeId: 42, companyCode: 302443734 },
  { codeId: 34, companyCode: 302444010 },
  { codeId: 58, companyCode: 302444569 },
  { codeId: 58, companyCode: 302444640 },
  { codeId: 48, companyCode: 302445297 },
  { codeId: 58, companyCode: 302445379 },
  { codeId: 61, companyCode: 302446374 },
  { codeId: 17, companyCode: 302447526 },
  { codeId: 57, companyCode: 302447750 },
  { codeId: 21, companyCode: 302447953 },
  { codeId: 19, companyCode: 302448998 },
  { codeId: 38, companyCode: 302449025 },
  { codeId: 16, companyCode: 302449139 },
  { codeId: 53, companyCode: 302450629 },
  { codeId: 58, companyCode: 302452612 },
  { codeId: 16, companyCode: 302453034 },
  { codeId: 58, companyCode: 302453212 },
  { codeId: 58, companyCode: 302453881 },
  { codeId: 5, companyCode: 302454360 },
  { codeId: 58, companyCode: 302455633 },
  { codeId: 53, companyCode: 302456888 },
  { codeId: 58, companyCode: 302459119 },
  { codeId: 34, companyCode: 302460242 },
  { codeId: 53, companyCode: 302460783 },
  { codeId: 53, companyCode: 302461344 },
  { codeId: 58, companyCode: 302462040 },
  { codeId: 58, companyCode: 302462357 },
  { codeId: 58, companyCode: 302462396 },
  { codeId: 21, companyCode: 302462866 },
  { codeId: 40, companyCode: 302464803 },
  { codeId: 4, companyCode: 302465321 },
  { codeId: 58, companyCode: 302466334 },
  { codeId: 20, companyCode: 302466633 },
  { codeId: 58, companyCode: 302467450 },
  { codeId: 16, companyCode: 302467888 },
  { codeId: 58, companyCode: 302468029 },
  { codeId: 17, companyCode: 302469266 },
  { codeId: 53, companyCode: 302469437 },
  { codeId: 3, companyCode: 302469519 },
  { codeId: 57, companyCode: 302469743 },
  { codeId: 34, companyCode: 302470133 },
  { codeId: 58, companyCode: 302470343 },
  { codeId: 19, companyCode: 302470382 },
  { codeId: 57, companyCode: 302470966 },
  { codeId: 58, companyCode: 302471089 },
  { codeId: 58, companyCode: 302471687 },
  { codeId: 58, companyCode: 302471897 },
  { codeId: 58, companyCode: 302472052 },
  { codeId: 58, companyCode: 302472508 },
  { codeId: 58, companyCode: 302472682 },
  { codeId: 3, companyCode: 302472942 },
  { codeId: 48, companyCode: 302472967 },
  { codeId: 20, companyCode: 302473713 },
  { codeId: 16, companyCode: 302475073 },
  { codeId: 10, companyCode: 302475568 },
  { codeId: 57, companyCode: 302475778 },
  { codeId: 53, companyCode: 302476008 },
  { codeId: 9, companyCode: 302476570 },
  { codeId: 20, companyCode: 302477380 },
  { codeId: 19, companyCode: 302477882 },
  { codeId: 21, companyCode: 302477925 },
  { codeId: 58, companyCode: 302478176 },
  { codeId: 57, companyCode: 302478322 },
  { codeId: 40, companyCode: 302479157 },
  { codeId: 58, companyCode: 302479552 },
  { codeId: 16, companyCode: 302479748 },
  { codeId: 34, companyCode: 302480330 },
  { codeId: 58, companyCode: 302481368 },
  { codeId: 58, companyCode: 302481375 },
  { codeId: 16, companyCode: 302481699 },
  { codeId: 58, companyCode: 302482349 },
  { codeId: 53, companyCode: 302482858 },
  { codeId: 54, companyCode: 302482993 },
  { codeId: 53, companyCode: 302483814 },
  { codeId: 53, companyCode: 302484040 },
  { codeId: 19, companyCode: 302484752 },
  { codeId: 58, companyCode: 302485509 },
  { codeId: 53, companyCode: 302485740 },
  { codeId: 53, companyCode: 302486237 },
  { codeId: 58, companyCode: 302486461 },
  { codeId: 16, companyCode: 302487816 },
  { codeId: 9, companyCode: 302487983 },
  { codeId: 21, companyCode: 302488252 },
  { codeId: 19, companyCode: 302488569 },
  { codeId: 53, companyCode: 302489144 },
  { codeId: 64, companyCode: 302490189 },
  { codeId: 64, companyCode: 302490303 },
  { codeId: 60, companyCode: 302491095 },
  { codeId: 58, companyCode: 302491558 },
  { codeId: 21, companyCode: 302492813 },
  { codeId: 9, companyCode: 302492973 },
  { codeId: 53, companyCode: 302493228 },
  { codeId: 40, companyCode: 302493420 },
  { codeId: 35, companyCode: 302493541 },
  { codeId: 34, companyCode: 302493687 },
  { codeId: 4, companyCode: 302494248 },
  { codeId: 58, companyCode: 302494294 },
  { codeId: 35, companyCode: 302494860 },
  { codeId: 58, companyCode: 302495542 },
  { codeId: 34, companyCode: 302495567 },
  { codeId: 53, companyCode: 302496765 },
  { codeId: 19, companyCode: 302496772 },
  { codeId: 58, companyCode: 302498734 },
  { codeId: 53, companyCode: 302498912 },
  { codeId: 58, companyCode: 302498990 },
  { codeId: 50, companyCode: 302499081 },
  { codeId: 20, companyCode: 302500562 },
  { codeId: 37, companyCode: 302501002 },
  { codeId: 58, companyCode: 302501205 },
  { codeId: 58, companyCode: 302501803 },
  { codeId: 20, companyCode: 302502054 },
  { codeId: 19, companyCode: 302502563 },
  { codeId: 58, companyCode: 302502691 },
  { codeId: 53, companyCode: 302503035 },
  { codeId: 19, companyCode: 302504016 },
  { codeId: 16, companyCode: 302504525 },
  { codeId: 57, companyCode: 302505246 },
  { codeId: 58, companyCode: 302505278 },
  { codeId: 58, companyCode: 302505851 },
  { codeId: 38, companyCode: 302506120 },
  { codeId: 53, companyCode: 302506330 },
  { codeId: 34, companyCode: 302507101 },
  { codeId: 34, companyCode: 302507457 },
  { codeId: 58, companyCode: 302507902 },
  { codeId: 53, companyCode: 302508025 },
  { codeId: 59, companyCode: 302508477 },
  { codeId: 58, companyCode: 302508491 },
  { codeId: 53, companyCode: 302508687 },
  { codeId: 21, companyCode: 302509440 },
  { codeId: 27, companyCode: 302513143 },
  { codeId: 53, companyCode: 302513307 },
  { codeId: 58, companyCode: 302513563 },
  { codeId: 58, companyCode: 302514505 },
  { codeId: 58, companyCode: 302514786 },
  { codeId: 53, companyCode: 302515977 },
  { codeId: 20, companyCode: 302516972 },
  { codeId: 53, companyCode: 302518507 },
  { codeId: 20, companyCode: 302518521 },
  { codeId: 53, companyCode: 302520294 },
  { codeId: 58, companyCode: 302520668 },
  { codeId: 17, companyCode: 302521777 },
  { codeId: 40, companyCode: 302521898 },
  { codeId: 16, companyCode: 302523301 },
  { codeId: 20, companyCode: 302524734 },
  { codeId: 20, companyCode: 302525220 },
  { codeId: 53, companyCode: 302526443 },
  { codeId: 60, companyCode: 302530057 },
  { codeId: 53, companyCode: 302530580 },
  { codeId: 34, companyCode: 302530819 },
  { codeId: 58, companyCode: 302530908 },
  { codeId: 53, companyCode: 302531216 },
  { codeId: 53, companyCode: 302532624 },
  { codeId: 19, companyCode: 302532656 },
  { codeId: 19, companyCode: 302533014 },
  { codeId: 58, companyCode: 302533224 },
  { codeId: 16, companyCode: 302533270 },
  { codeId: 38, companyCode: 302533338 },
  { codeId: 3, companyCode: 302533466 },
  { codeId: 9, companyCode: 302535296 },
  { codeId: 34, companyCode: 302536590 },
  { codeId: 16, companyCode: 302536786 },
  { codeId: 58, companyCode: 302537361 },
  { codeId: 34, companyCode: 302537888 },
  { codeId: 53, companyCode: 302538538 },
  { codeId: 53, companyCode: 302539095 },
  { codeId: 53, companyCode: 302539736 },
  { codeId: 20, companyCode: 302540717 },
  { codeId: 53, companyCode: 302540998 },
  { codeId: 50, companyCode: 302541541 },
  { codeId: 53, companyCode: 302542006 },
  { codeId: 49, companyCode: 302542629 },
  { codeId: 24, companyCode: 302542718 },
  { codeId: 58, companyCode: 302543097 },
  { codeId: 53, companyCode: 302544231 },
  { codeId: 24, companyCode: 302544313 },
  { codeId: 58, companyCode: 302545340 },
  { codeId: 20, companyCode: 302545447 },
  { codeId: 34, companyCode: 302545657 },
  { codeId: 58, companyCode: 302546652 },
  { codeId: 58, companyCode: 302546741 },
  { codeId: 21, companyCode: 302547619 },
  { codeId: 53, companyCode: 302547989 },
  { codeId: 58, companyCode: 302548411 },
  { codeId: 21, companyCode: 302548596 },
  { codeId: 58, companyCode: 302550693 },
  { codeId: 45, companyCode: 302550864 },
  { codeId: 53, companyCode: 302551375 },
  { codeId: 53, companyCode: 302551382 },
  { codeId: 20, companyCode: 302551763 },
  { codeId: 58, companyCode: 302551806 },
  { codeId: 58, companyCode: 302553668 },
  { codeId: 26, companyCode: 302553928 },
  { codeId: 16, companyCode: 302554389 },
  { codeId: 60, companyCode: 302555473 },
  { codeId: 53, companyCode: 302555765 },
  { codeId: 12, companyCode: 302555904 },
  { codeId: 53, companyCode: 302556194 },
  { codeId: 24, companyCode: 302556290 },
  { codeId: 58, companyCode: 302556397 },
  { codeId: 4, companyCode: 302556486 },
  { codeId: 53, companyCode: 302556664 },
  { codeId: 34, companyCode: 302557894 },
  { codeId: 34, companyCode: 302558448 },
  { codeId: 53, companyCode: 302558989 },
  { codeId: 16, companyCode: 302559016 },
  { codeId: 34, companyCode: 302559183 },
  { codeId: 20, companyCode: 302559849 },
  { codeId: 54, companyCode: 302559856 },
  { codeId: 5, companyCode: 302560132 },
  { codeId: 53, companyCode: 302560171 },
  { codeId: 24, companyCode: 302560673 },
  { codeId: 14, companyCode: 302560716 },
  { codeId: 53, companyCode: 302560869 },
  { codeId: 61, companyCode: 302560958 },
  { codeId: 61, companyCode: 302561227 },
  { codeId: 16, companyCode: 302561234 },
  { codeId: 20, companyCode: 302561519 },
  { codeId: 61, companyCode: 302561622 },
  { codeId: 61, companyCode: 302562254 },
  { codeId: 19, companyCode: 302562560 },
  { codeId: 21, companyCode: 302563089 },
  { codeId: 19, companyCode: 302563153 },
  { codeId: 53, companyCode: 302563491 },
  { codeId: 3, companyCode: 302563712 },
  { codeId: 19, companyCode: 302565268 },
  { codeId: 53, companyCode: 302565275 },
  { codeId: 19, companyCode: 302565624 },
  { codeId: 49, companyCode: 302566060 },
  { codeId: 33, companyCode: 302566306 },
  { codeId: 48, companyCode: 302566466 },
  { codeId: 57, companyCode: 302566968 },
  { codeId: 3, companyCode: 302567333 },
  { codeId: 6, companyCode: 302567529 },
  { codeId: 34, companyCode: 302568111 },
  { codeId: 57, companyCode: 302568798 },
  { codeId: 53, companyCode: 302570870 },
  { codeId: 20, companyCode: 302571431 },
  { codeId: 34, companyCode: 302573208 },
  { codeId: 20, companyCode: 302573592 },
  { codeId: 57, companyCode: 302573959 },
  { codeId: 20, companyCode: 302575248 },
  { codeId: 6, companyCode: 302575433 },
  { codeId: 48, companyCode: 302576300 },
  { codeId: 15, companyCode: 302576987 },
  { codeId: 16, companyCode: 302578205 },
  { codeId: 36, companyCode: 302579232 },
  { codeId: 19, companyCode: 302579969 },
  { codeId: 58, companyCode: 302580238 },
  { codeId: 53, companyCode: 302580697 },
  { codeId: 58, companyCode: 302582381 },
  { codeId: 58, companyCode: 302582417 },
  { codeId: 35, companyCode: 302584959 },
  { codeId: 24, companyCode: 302585299 },
  { codeId: 19, companyCode: 302586262 },
  { codeId: 19, companyCode: 302586725 },
  { codeId: 53, companyCode: 302586764 },
  { codeId: 53, companyCode: 302586999 },
  { codeId: 58, companyCode: 302588644 },
  { codeId: 16, companyCode: 302589162 },
  { codeId: 19, companyCode: 302589600 },
  { codeId: 53, companyCode: 302589874 },
  { codeId: 3, companyCode: 302590129 },
  { codeId: 58, companyCode: 302591918 },
  { codeId: 34, companyCode: 302592468 },
  { codeId: 34, companyCode: 302592564 },
  { codeId: 57, companyCode: 302592596 },
  { codeId: 19, companyCode: 302593584 },
  { codeId: 24, companyCode: 302593997 },
  { codeId: 35, companyCode: 302594177 },
  { codeId: 58, companyCode: 302595806 },
  { codeId: 19, companyCode: 302596630 },
  { codeId: 53, companyCode: 302597084 },
  { codeId: 34, companyCode: 302597294 },
  { codeId: 58, companyCode: 302597337 },
  { codeId: 57, companyCode: 302597508 },
  { codeId: 34, companyCode: 302597522 },
  { codeId: 20, companyCode: 302597547 },
  { codeId: 40, companyCode: 302599263 },
  { codeId: 16, companyCode: 302599441 },
  { codeId: 28, companyCode: 302599758 },
  { codeId: 64, companyCode: 302599861 },
  { codeId: 11, companyCode: 302600363 },
  { codeId: 53, companyCode: 302601020 },
  { codeId: 58, companyCode: 302601465 },
  { codeId: 16, companyCode: 302601643 },
  { codeId: 20, companyCode: 302602243 },
  { codeId: 3, companyCode: 302602407 },
  { codeId: 58, companyCode: 302603142 },
  { codeId: 19, companyCode: 302604187 },
  { codeId: 58, companyCode: 302604657 },
  { codeId: 23, companyCode: 302604689 },
  { codeId: 53, companyCode: 302605257 },
  { codeId: 16, companyCode: 302605442 },
  { codeId: 34, companyCode: 302605741 },
  { codeId: 20, companyCode: 302606042 },
  { codeId: 53, companyCode: 302608680 },
  { codeId: 11, companyCode: 302608762 },
  { codeId: 58, companyCode: 302609049 },
  { codeId: 20, companyCode: 302609490 },
  { codeId: 3, companyCode: 302610012 },
  { codeId: 34, companyCode: 302611783 },
  { codeId: 60, companyCode: 302611833 },
  { codeId: 45, companyCode: 302612084 },
  { codeId: 19, companyCode: 302613211 },
  { codeId: 34, companyCode: 302613898 },
  { codeId: 24, companyCode: 302614053 },
  { codeId: 58, companyCode: 302614822 },
  { codeId: 3, companyCode: 302614861 },
  { codeId: 19, companyCode: 302615778 },
  { codeId: 21, companyCode: 302616086 },
  { codeId: 3, companyCode: 302616353 },
  { codeId: 62, companyCode: 302616442 },
  { codeId: 19, companyCode: 302617213 },
  { codeId: 58, companyCode: 302617576 },
  { codeId: 20, companyCode: 302617875 },
  { codeId: 53, companyCode: 302619591 },
  { codeId: 3, companyCode: 302620070 },
  { codeId: 58, companyCode: 302621724 },
  { codeId: 5, companyCode: 302622114 },
  { codeId: 21, companyCode: 302622502 },
  { codeId: 24, companyCode: 302622662 },
  { codeId: 53, companyCode: 302624115 },
  { codeId: 58, companyCode: 302625288 },
  { codeId: 58, companyCode: 302625498 },
  { codeId: 58, companyCode: 302625555 },
  { codeId: 58, companyCode: 302625683 },
  { codeId: 58, companyCode: 302626116 },
  { codeId: 63, companyCode: 302627093 },
  { codeId: 28, companyCode: 302627111 },
  { codeId: 58, companyCode: 302627442 },
  { codeId: 16, companyCode: 302627741 },
  { codeId: 53, companyCode: 302628423 },
  { codeId: 16, companyCode: 302628569 },
  { codeId: 53, companyCode: 302628619 },
  { codeId: 34, companyCode: 302628875 },
  { codeId: 53, companyCode: 302629055 },
  { codeId: 9, companyCode: 302629393 },
  { codeId: 4, companyCode: 302629799 },
  { codeId: 20, companyCode: 302630214 },
  { codeId: 58, companyCode: 302630812 },
  { codeId: 53, companyCode: 302631227 },
  { codeId: 53, companyCode: 302631259 },
  { codeId: 58, companyCode: 302631992 },
  { codeId: 16, companyCode: 302632432 },
  { codeId: 34, companyCode: 302632514 },
  { codeId: 58, companyCode: 302633420 },
  { codeId: 53, companyCode: 302634401 },
  { codeId: 49, companyCode: 302635282 },
  { codeId: 19, companyCode: 302635325 },
  { codeId: 53, companyCode: 302636459 },
  { codeId: 58, companyCode: 302636637 },
  { codeId: 53, companyCode: 302636968 },
  { codeId: 9, companyCode: 302637059 },
  { codeId: 58, companyCode: 302637155 },
  { codeId: 19, companyCode: 302637447 },
  { codeId: 38, companyCode: 302638143 },
  { codeId: 34, companyCode: 302638741 },
  { codeId: 34, companyCode: 302639067 },
  { codeId: 38, companyCode: 302640831 },
  { codeId: 19, companyCode: 302640863 },
  { codeId: 24, companyCode: 302641698 },
  { codeId: 43, companyCode: 302642362 },
  { codeId: 53, companyCode: 302642572 },
  { codeId: 16, companyCode: 302642864 },
  { codeId: 5, companyCode: 302643119 },
  { codeId: 16, companyCode: 302643457 },
  { codeId: 58, companyCode: 302644267 },
  { codeId: 61, companyCode: 302644274 },
  { codeId: 16, companyCode: 302644349 },
  { codeId: 34, companyCode: 302645248 },
  { codeId: 20, companyCode: 302645390 },
  { codeId: 19, companyCode: 302645981 },
  { codeId: 24, companyCode: 302646414 },
  { codeId: 20, companyCode: 302648237 },
  { codeId: 58, companyCode: 302648276 },
  { codeId: 58, companyCode: 302648707 },
  { codeId: 53, companyCode: 302648842 },
  { codeId: 37, companyCode: 302649136 },
  { codeId: 53, companyCode: 302649200 },
  { codeId: 53, companyCode: 302649289 },
  { codeId: 58, companyCode: 302649442 },
  { codeId: 58, companyCode: 302650124 },
  { codeId: 9, companyCode: 302651297 },
  { codeId: 57, companyCode: 302651347 },
  { codeId: 21, companyCode: 302651532 },
  { codeId: 58, companyCode: 302651703 },
  { codeId: 53, companyCode: 302651735 },
  { codeId: 53, companyCode: 302652602 },
  { codeId: 21, companyCode: 302653152 },
  { codeId: 53, companyCode: 302653501 },
  { codeId: 53, companyCode: 302654069 },
  { codeId: 53, companyCode: 302655566 },
  { codeId: 19, companyCode: 302656248 },
  { codeId: 58, companyCode: 302656351 },
  { codeId: 21, companyCode: 302657026 },
  { codeId: 61, companyCode: 302657357 },
  { codeId: 19, companyCode: 302657414 },
  { codeId: 20, companyCode: 302658014 },
  { codeId: 57, companyCode: 302658092 },
  { codeId: 53, companyCode: 302658167 },
  { codeId: 58, companyCode: 302658181 },
  { codeId: 58, companyCode: 302658199 },
  { codeId: 61, companyCode: 302658719 },
  { codeId: 34, companyCode: 302658847 },
  { codeId: 19, companyCode: 302658982 },
  { codeId: 19, companyCode: 302659205 },
  { codeId: 61, companyCode: 302660990 },
  { codeId: 53, companyCode: 302661455 },
  { codeId: 16, companyCode: 302661672 },
  { codeId: 34, companyCode: 302662105 },
  { codeId: 58, companyCode: 302663171 },
  { codeId: 58, companyCode: 302663335 },
  { codeId: 58, companyCode: 302663794 },
  { codeId: 53, companyCode: 302664818 },
  { codeId: 11, companyCode: 302665090 },
  { codeId: 24, companyCode: 302665560 },
  { codeId: 58, companyCode: 302666616 },
  { codeId: 58, companyCode: 302666947 },
  { codeId: 27, companyCode: 302667020 },
  { codeId: 53, companyCode: 302667209 },
  { codeId: 16, companyCode: 302667675 },
  { codeId: 49, companyCode: 302667682 },
  { codeId: 19, companyCode: 302668243 },
  { codeId: 58, companyCode: 302668439 },
  { codeId: 53, companyCode: 302668656 },
  { codeId: 19, companyCode: 302668688 },
  { codeId: 14, companyCode: 302669822 },
  { codeId: 53, companyCode: 302670251 },
  { codeId: 20, companyCode: 302670493 },
  { codeId: 58, companyCode: 302670550 },
  { codeId: 21, companyCode: 302670746 },
  { codeId: 58, companyCode: 302670760 },
  { codeId: 16, companyCode: 302671894 },
  { codeId: 53, companyCode: 302672590 },
  { codeId: 53, companyCode: 302672793 },
  { codeId: 53, companyCode: 302673895 },
  { codeId: 53, companyCode: 302674125 },
  { codeId: 60, companyCode: 302674488 },
  { codeId: 20, companyCode: 302674837 },
  { codeId: 49, companyCode: 302674869 },
  { codeId: 33, companyCode: 302675451 },
  { codeId: 58, companyCode: 302676133 },
  { codeId: 53, companyCode: 302677897 },
  { codeId: 53, companyCode: 302678383 },
  { codeId: 35, companyCode: 302679243 },
  { codeId: 34, companyCode: 302679407 },
  { codeId: 58, companyCode: 302680021 },
  { codeId: 60, companyCode: 302680046 },
  { codeId: 20, companyCode: 302680295 },
  { codeId: 54, companyCode: 302680466 },
  { codeId: 53, companyCode: 302680509 },
  { codeId: 60, companyCode: 302680516 },
  { codeId: 60, companyCode: 302680530 },
  { codeId: 13, companyCode: 302680555 },
  { codeId: 16, companyCode: 302680740 },
  { codeId: 40, companyCode: 302680943 },
  { codeId: 40, companyCode: 302681041 },
  { codeId: 23, companyCode: 302681116 },
  { codeId: 57, companyCode: 302681319 },
  { codeId: 16, companyCode: 302681899 },
  { codeId: 35, companyCode: 302682054 },
  { codeId: 20, companyCode: 302682563 },
  { codeId: 16, companyCode: 302682830 },
  { codeId: 20, companyCode: 302683010 },
  { codeId: 53, companyCode: 302683042 },
  { codeId: 28, companyCode: 302683195 },
  { codeId: 19, companyCode: 302683359 },
  { codeId: 53, companyCode: 302683932 },
  { codeId: 16, companyCode: 302684240 },
  { codeId: 53, companyCode: 302684361 },
  { codeId: 44, companyCode: 302684639 },
  { codeId: 16, companyCode: 302685214 },
  { codeId: 19, companyCode: 302685449 },
  { codeId: 45, companyCode: 302685926 },
  { codeId: 19, companyCode: 302686259 },
  { codeId: 19, companyCode: 302686394 },
  { codeId: 21, companyCode: 302687051 },
  { codeId: 24, companyCode: 302687279 },
  { codeId: 58, companyCode: 302687400 },
  { codeId: 20, companyCode: 302687578 },
  { codeId: 34, companyCode: 302687681 },
  { codeId: 34, companyCode: 302687884 },
  { codeId: 20, companyCode: 302687927 },
  { codeId: 20, companyCode: 302688018 },
  { codeId: 24, companyCode: 302688139 },
  { codeId: 49, companyCode: 302688178 },
  { codeId: 64, companyCode: 302688395 },
  { codeId: 34, companyCode: 302688979 },
  { codeId: 19, companyCode: 302689223 },
  { codeId: 20, companyCode: 302689458 },
  { codeId: 20, companyCode: 302690033 },
  { codeId: 58, companyCode: 302690236 },
  { codeId: 53, companyCode: 302690460 },
  { codeId: 60, companyCode: 302690656 },
  { codeId: 20, companyCode: 302690866 },
  { codeId: 20, companyCode: 302691338 },
  { codeId: 34, companyCode: 302691555 },
  { codeId: 53, companyCode: 302691758 },
  { codeId: 3, companyCode: 302691822 },
  { codeId: 34, companyCode: 302691904 },
  { codeId: 58, companyCode: 302692269 },
  { codeId: 58, companyCode: 302692326 },
  { codeId: 53, companyCode: 302692632 },
  { codeId: 35, companyCode: 302694252 },
  { codeId: 32, companyCode: 302694373 },
  { codeId: 33, companyCode: 302694448 },
  { codeId: 19, companyCode: 302695315 },
  { codeId: 49, companyCode: 302695614 },
  { codeId: 3, companyCode: 302696919 },
  { codeId: 19, companyCode: 302697519 },
  { codeId: 3, companyCode: 302697615 },
  { codeId: 58, companyCode: 302698350 },
  { codeId: 58, companyCode: 302698507 },
  { codeId: 34, companyCode: 302699274 },
  { codeId: 27, companyCode: 302699947 },
  { codeId: 16, companyCode: 302700189 },
  { codeId: 53, companyCode: 302700940 },
  { codeId: 58, companyCode: 302701120 },
  { codeId: 20, companyCode: 302701946 },
  { codeId: 58, companyCode: 302702425 },
  { codeId: 53, companyCode: 302703114 },
  { codeId: 24, companyCode: 302703413 },
  { codeId: 24, companyCode: 302703993 },
  { codeId: 58, companyCode: 302704629 },
  { codeId: 23, companyCode: 302705503 },
  { codeId: 53, companyCode: 302707212 },
  { codeId: 53, companyCode: 302707397 },
  { codeId: 40, companyCode: 302707447 },
  { codeId: 58, companyCode: 302709291 },
  { codeId: 16, companyCode: 302709569 },
  { codeId: 53, companyCode: 302710048 },
  { codeId: 16, companyCode: 302710596 },
  { codeId: 19, companyCode: 302710863 },
  { codeId: 53, companyCode: 302711050 },
  { codeId: 53, companyCode: 302711253 },
  { codeId: 53, companyCode: 302711723 },
  { codeId: 63, companyCode: 302713254 },
  { codeId: 34, companyCode: 302715369 },
  { codeId: 57, companyCode: 302716421 },
  { codeId: 53, companyCode: 302716446 },
  { codeId: 58, companyCode: 302716542 },
  { codeId: 54, companyCode: 302716663 },
  { codeId: 26, companyCode: 302716688 },
  { codeId: 58, companyCode: 302717174 },
  { codeId: 60, companyCode: 302717402 },
  { codeId: 16, companyCode: 302717943 },
  { codeId: 3, companyCode: 302718511 },
  { codeId: 34, companyCode: 302718625 },
  { codeId: 48, companyCode: 302719289 },
  { codeId: 53, companyCode: 302719951 },
  { codeId: 17, companyCode: 302720601 },
  { codeId: 32, companyCode: 302721379 },
  { codeId: 53, companyCode: 302721386 },
  { codeId: 48, companyCode: 302721799 },
  { codeId: 19, companyCode: 302722552 },
  { codeId: 20, companyCode: 302724165 },
  { codeId: 13, companyCode: 302724927 },
  { codeId: 19, companyCode: 302724966 },
  { codeId: 53, companyCode: 302725018 },
  { codeId: 34, companyCode: 302725025 },
  { codeId: 40, companyCode: 302725961 },
  { codeId: 20, companyCode: 302726376 },
  { codeId: 40, companyCode: 302728135 },
  { codeId: 53, companyCode: 302729123 },
  { codeId: 4, companyCode: 302729162 },
  { codeId: 53, companyCode: 302729194 },
  { codeId: 19, companyCode: 302729205 },
  { codeId: 20, companyCode: 302730474 },
  { codeId: 58, companyCode: 302730517 },
  { codeId: 58, companyCode: 302730549 },
  { codeId: 20, companyCode: 302731149 },
  { codeId: 24, companyCode: 302731291 },
  { codeId: 27, companyCode: 302733890 },
  { codeId: 53, companyCode: 302734864 },
  { codeId: 58, companyCode: 302735400 },
  { codeId: 58, companyCode: 302735852 },
  { codeId: 58, companyCode: 302736598 },
  { codeId: 40, companyCode: 302736687 },
  { codeId: 3, companyCode: 302736737 },
  { codeId: 57, companyCode: 302737383 },
  { codeId: 58, companyCode: 302737928 },
  { codeId: 58, companyCode: 302739384 },
  { codeId: 19, companyCode: 302739441 },
  { codeId: 53, companyCode: 302739911 },
  { codeId: 53, companyCode: 302740671 },
  { codeId: 58, companyCode: 302741524 },
  { codeId: 28, companyCode: 302741638 },
  { codeId: 3, companyCode: 302743386 },
  { codeId: 53, companyCode: 302743411 },
  { codeId: 58, companyCode: 302745227 },
  { codeId: 38, companyCode: 302745241 },
  { codeId: 19, companyCode: 302745451 },
  { codeId: 34, companyCode: 302746012 },
  { codeId: 49, companyCode: 302747623 },
  { codeId: 28, companyCode: 302747993 },
  { codeId: 58, companyCode: 302748180 },
  { codeId: 19, companyCode: 302749909 },
  { codeId: 36, companyCode: 302752136 },
  { codeId: 18, companyCode: 302752588 },
  { codeId: 58, companyCode: 302752613 },
  { codeId: 53, companyCode: 302752691 },
  { codeId: 58, companyCode: 302752702 },
  { codeId: 58, companyCode: 302752741 },
  { codeId: 58, companyCode: 302752759 },
  { codeId: 58, companyCode: 302752773 },
  { codeId: 58, companyCode: 302752798 },
  { codeId: 43, companyCode: 302752905 },
  { codeId: 53, companyCode: 302754639 },
  { codeId: 58, companyCode: 302755794 },
  { codeId: 58, companyCode: 302756992 },
  { codeId: 40, companyCode: 302757489 },
  { codeId: 58, companyCode: 302757514 },
  { codeId: 16, companyCode: 302757724 },
  { codeId: 58, companyCode: 302758623 },
  { codeId: 58, companyCode: 302758630 },
  { codeId: 53, companyCode: 302759903 },
  { codeId: 58, companyCode: 302760357 },
  { codeId: 53, companyCode: 302760624 },
  { codeId: 53, companyCode: 302761402 },
  { codeId: 58, companyCode: 302761480 },
  { codeId: 58, companyCode: 302761509 },
  { codeId: 58, companyCode: 302761968 },
  { codeId: 58, companyCode: 302762098 },
  { codeId: 58, companyCode: 302762162 },
  { codeId: 19, companyCode: 302763015 },
  { codeId: 12, companyCode: 302763182 },
  { codeId: 20, companyCode: 302764195 },
  { codeId: 53, companyCode: 302764455 },
  { codeId: 58, companyCode: 302765062 },
  { codeId: 53, companyCode: 302765856 },
  { codeId: 34, companyCode: 302766666 },
  { codeId: 3, companyCode: 302766748 },
  { codeId: 58, companyCode: 302766876 },
  { codeId: 53, companyCode: 302767501 },
  { codeId: 25, companyCode: 302769032 },
  { codeId: 53, companyCode: 302769388 },
  { codeId: 58, companyCode: 302769630 },
  { codeId: 21, companyCode: 302770636 },
  { codeId: 20, companyCode: 302771492 },
  { codeId: 58, companyCode: 302772039 },
  { codeId: 58, companyCode: 302772085 },
  { codeId: 58, companyCode: 302772690 },
  { codeId: 58, companyCode: 302773408 },
  { codeId: 58, companyCode: 302773447 },
  { codeId: 19, companyCode: 302773461 },
  { codeId: 58, companyCode: 302773536 },
  { codeId: 9, companyCode: 302773568 },
  { codeId: 58, companyCode: 302773657 },
  { codeId: 58, companyCode: 302773664 },
  { codeId: 58, companyCode: 302774887 },
  { codeId: 58, companyCode: 302775380 },
  { codeId: 54, companyCode: 302775900 },
  { codeId: 58, companyCode: 302776557 },
  { codeId: 58, companyCode: 302776913 },
  { codeId: 58, companyCode: 302776938 },
  { codeId: 53, companyCode: 302777004 },
  { codeId: 58, companyCode: 302777036 },
  { codeId: 16, companyCode: 302777506 },
  { codeId: 53, companyCode: 302777730 },
  { codeId: 37, companyCode: 302777748 },
  { codeId: 58, companyCode: 302778437 },
  { codeId: 58, companyCode: 302778444 },
  { codeId: 16, companyCode: 302778647 },
  { codeId: 53, companyCode: 302778704 },
  { codeId: 58, companyCode: 302779902 },
  { codeId: 21, companyCode: 302780057 },
  { codeId: 58, companyCode: 302780915 },
  { codeId: 53, companyCode: 302780922 },
  { codeId: 21, companyCode: 302781127 },
  { codeId: 51, companyCode: 302783231 },
  { codeId: 20, companyCode: 302783879 },
  { codeId: 58, companyCode: 302784283 },
  { codeId: 15, companyCode: 302784931 },
  { codeId: 58, companyCode: 302785951 },
  { codeId: 58, companyCode: 302785976 },
  { codeId: 58, companyCode: 302786042 },
  { codeId: 58, companyCode: 302786170 },
  { codeId: 58, companyCode: 302787169 },
  { codeId: 58, companyCode: 302787176 },
  { codeId: 58, companyCode: 302787450 },
  { codeId: 58, companyCode: 302788189 },
  { codeId: 58, companyCode: 302788463 },
  { codeId: 48, companyCode: 302788513 },
  { codeId: 64, companyCode: 302788602 },
  { codeId: 4, companyCode: 302788698 },
  { codeId: 20, companyCode: 302788723 },
  { codeId: 58, companyCode: 302788730 },
  { codeId: 58, companyCode: 302788755 },
  { codeId: 58, companyCode: 302790158 },
  { codeId: 53, companyCode: 302790934 },
  { codeId: 58, companyCode: 302791192 },
  { codeId: 58, companyCode: 302791858 },
  { codeId: 58, companyCode: 302792134 },
  { codeId: 58, companyCode: 302792223 },
  { codeId: 49, companyCode: 302792458 },
  { codeId: 58, companyCode: 302792465 },
  { codeId: 58, companyCode: 302792472 },
  { codeId: 58, companyCode: 302792497 },
  { codeId: 58, companyCode: 302792547 },
  { codeId: 53, companyCode: 302793040 },
  { codeId: 58, companyCode: 302793275 },
  { codeId: 23, companyCode: 302793916 },
  { codeId: 58, companyCode: 302794039 },
  { codeId: 59, companyCode: 302794217 },
  { codeId: 19, companyCode: 302794384 },
  { codeId: 58, companyCode: 302794391 },
  { codeId: 38, companyCode: 302794975 },
  { codeId: 53, companyCode: 302796129 },
  { codeId: 49, companyCode: 302796296 },
  { codeId: 58, companyCode: 302797067 },
  { codeId: 58, companyCode: 302797074 },
  { codeId: 58, companyCode: 302797569 },
  { codeId: 58, companyCode: 302797619 },
  { codeId: 58, companyCode: 302797640 },
  { codeId: 58, companyCode: 302797793 },
  { codeId: 58, companyCode: 302797804 },
  { codeId: 57, companyCode: 302798468 },
  { codeId: 58, companyCode: 302798482 },
  { codeId: 58, companyCode: 302798557 },
  { codeId: 58, companyCode: 302798621 },
  { codeId: 24, companyCode: 302798895 },
  { codeId: 58, companyCode: 302799189 },
  { codeId: 58, companyCode: 302799374 },
  { codeId: 58, companyCode: 302799602 },
  { codeId: 58, companyCode: 302799716 },
  { codeId: 58, companyCode: 302799723 },
  { codeId: 58, companyCode: 302799730 },
  { codeId: 58, companyCode: 302799748 },
  { codeId: 58, companyCode: 302799755 },
  { codeId: 58, companyCode: 302800524 },
  { codeId: 58, companyCode: 302800613 },
  { codeId: 58, companyCode: 302800652 },
  { codeId: 58, companyCode: 302800990 },
  { codeId: 3, companyCode: 302801003 },
  { codeId: 62, companyCode: 302802144 },
  { codeId: 58, companyCode: 302802183 },
  { codeId: 27, companyCode: 302802411 },
  { codeId: 53, companyCode: 302802475 },
  { codeId: 49, companyCode: 302802571 },
  { codeId: 58, companyCode: 302803417 },
  { codeId: 58, companyCode: 302803431 },
  { codeId: 58, companyCode: 302803456 },
  { codeId: 58, companyCode: 302803470 },
  { codeId: 9, companyCode: 302804362 },
  { codeId: 3, companyCode: 302804960 },
  { codeId: 58, companyCode: 302804978 },
  { codeId: 58, companyCode: 302804992 },
  { codeId: 16, companyCode: 302805546 },
  { codeId: 32, companyCode: 302805553 },
  { codeId: 58, companyCode: 302805731 },
  { codeId: 58, companyCode: 302805795 },
  { codeId: 58, companyCode: 302805891 },
  { codeId: 58, companyCode: 302805966 },
  { codeId: 58, companyCode: 302806018 },
  { codeId: 58, companyCode: 302806025 },
  { codeId: 58, companyCode: 302806032 },
  { codeId: 58, companyCode: 302806071 },
  { codeId: 58, companyCode: 302806107 },
  { codeId: 58, companyCode: 302806139 },
  { codeId: 53, companyCode: 302806356 },
  { codeId: 58, companyCode: 302806598 },
  { codeId: 58, companyCode: 302806687 },
  { codeId: 57, companyCode: 302806776 },
  { codeId: 16, companyCode: 302806872 },
  { codeId: 58, companyCode: 302806908 },
  { codeId: 53, companyCode: 302807771 },
  { codeId: 58, companyCode: 302808371 },
  { codeId: 58, companyCode: 302808389 },
  { codeId: 58, companyCode: 302808396 },
  { codeId: 58, companyCode: 302808574 },
  { codeId: 58, companyCode: 302808599 },
  { codeId: 58, companyCode: 302808617 },
  { codeId: 58, companyCode: 302808631 },
  { codeId: 58, companyCode: 302808695 },
  { codeId: 48, companyCode: 302808866 },
  { codeId: 58, companyCode: 302809651 },
  { codeId: 60, companyCode: 302809911 },
  { codeId: 58, companyCode: 302810340 },
  { codeId: 58, companyCode: 302810988 },
  { codeId: 58, companyCode: 302811143 },
  { codeId: 58, companyCode: 302811150 },
  { codeId: 58, companyCode: 302811168 },
  { codeId: 58, companyCode: 302811175 },
  { codeId: 58, companyCode: 302811182 },
  { codeId: 58, companyCode: 302811773 },
  { codeId: 58, companyCode: 302812373 },
  { codeId: 35, companyCode: 302812551 },
  { codeId: 53, companyCode: 302812601 },
  { codeId: 58, companyCode: 302812900 },
  { codeId: 58, companyCode: 302813607 },
  { codeId: 58, companyCode: 302813639 },
  { codeId: 58, companyCode: 302813774 },
  { codeId: 58, companyCode: 302813849 },
  { codeId: 53, companyCode: 302813952 },
  { codeId: 58, companyCode: 302814011 },
  { codeId: 58, companyCode: 302814036 },
  { codeId: 58, companyCode: 302814367 },
  { codeId: 58, companyCode: 302814424 },
  { codeId: 58, companyCode: 302814456 },
  { codeId: 58, companyCode: 302814520 },
  { codeId: 53, companyCode: 302814919 },
  { codeId: 58, companyCode: 302815501 },
  { codeId: 58, companyCode: 302815526 },
  { codeId: 53, companyCode: 302815729 },
  { codeId: 49, companyCode: 302815775 },
  { codeId: 58, companyCode: 302815896 },
  { codeId: 58, companyCode: 302815985 },
  { codeId: 58, companyCode: 302816941 },
  { codeId: 58, companyCode: 302817363 },
  { codeId: 58, companyCode: 302817445 },
  { codeId: 58, companyCode: 302817527 },
  { codeId: 58, companyCode: 302817573 },
  { codeId: 58, companyCode: 302817687 },
  { codeId: 58, companyCode: 302817744 },
  { codeId: 58, companyCode: 302817819 },
  { codeId: 3, companyCode: 302818465 },
  { codeId: 58, companyCode: 302819510 },
  { codeId: 58, companyCode: 302819567 },
  { codeId: 58, companyCode: 302819574 },
  { codeId: 58, companyCode: 302819599 },
  { codeId: 58, companyCode: 302819713 },
  { codeId: 58, companyCode: 302820498 },
  { codeId: 58, companyCode: 302820509 },
  { codeId: 53, companyCode: 302820719 },
  { codeId: 58, companyCode: 302821002 },
  { codeId: 58, companyCode: 302821034 },
  { codeId: 58, companyCode: 302821205 },
  { codeId: 53, companyCode: 302821290 },
  { codeId: 19, companyCode: 302821486 },
  { codeId: 58, companyCode: 302821504 },
  { codeId: 58, companyCode: 302821511 },
  { codeId: 19, companyCode: 302822780 },
  { codeId: 58, companyCode: 302822983 },
  { codeId: 58, companyCode: 302823373 },
  { codeId: 58, companyCode: 302823583 },
  { codeId: 53, companyCode: 302823811 },
  { codeId: 58, companyCode: 302824030 },
  { codeId: 58, companyCode: 302824105 },
  { codeId: 35, companyCode: 302824112 },
  { codeId: 1, companyCode: 302824151 },
  { codeId: 58, companyCode: 302824258 },
  { codeId: 31, companyCode: 302824856 },
  { codeId: 58, companyCode: 302825292 },
  { codeId: 58, companyCode: 302825342 },
  { codeId: 16, companyCode: 302826024 },
  { codeId: 19, companyCode: 302826056 },
  { codeId: 58, companyCode: 302826095 },
  { codeId: 57, companyCode: 302826330 },
  { codeId: 51, companyCode: 302827126 },
  { codeId: 58, companyCode: 302828406 },
  { codeId: 58, companyCode: 302828420 },
  { codeId: 58, companyCode: 302828438 },
  { codeId: 58, companyCode: 302828445 },
  { codeId: 58, companyCode: 302828477 },
  { codeId: 58, companyCode: 302828484 },
  { codeId: 58, companyCode: 302828541 },
  { codeId: 58, companyCode: 302828566 },
  { codeId: 58, companyCode: 302828573 },
  { codeId: 58, companyCode: 302828979 },
  { codeId: 58, companyCode: 302829038 },
  { codeId: 58, companyCode: 302829248 },
  { codeId: 18, companyCode: 302829376 },
  { codeId: 58, companyCode: 302830250 },
  { codeId: 58, companyCode: 302830414 },
  { codeId: 58, companyCode: 302830663 },
  { codeId: 58, companyCode: 302830670 },
  { codeId: 58, companyCode: 302830784 },
  { codeId: 58, companyCode: 302830834 },
  { codeId: 58, companyCode: 302830841 },
  { codeId: 58, companyCode: 302830859 },
  { codeId: 50, companyCode: 302830873 },
  { codeId: 58, companyCode: 302831352 },
  { codeId: 58, companyCode: 302832365 },
  { codeId: 58, companyCode: 302832408 },
  { codeId: 58, companyCode: 302832550 },
  { codeId: 58, companyCode: 302833378 },
  { codeId: 58, companyCode: 302833588 },
  { codeId: 58, companyCode: 302833848 },
  { codeId: 58, companyCode: 302833862 },
  { codeId: 11, companyCode: 302833983 },
  { codeId: 3, companyCode: 302834398 },
  { codeId: 34, companyCode: 302834658 },
  { codeId: 58, companyCode: 302834779 },
  { codeId: 58, companyCode: 302835518 },
  { codeId: 58, companyCode: 302836132 },
  { codeId: 58, companyCode: 302836883 },
  { codeId: 58, companyCode: 302836901 },
  { codeId: 53, companyCode: 302836940 },
  { codeId: 16, companyCode: 302837120 },
  { codeId: 58, companyCode: 302837145 },
  { codeId: 58, companyCode: 302837202 },
  { codeId: 58, companyCode: 302837273 },
  { codeId: 58, companyCode: 302837298 },
  { codeId: 58, companyCode: 302837348 },
  { codeId: 58, companyCode: 302838044 },
  { codeId: 58, companyCode: 302838286 },
  { codeId: 58, companyCode: 302838382 },
  { codeId: 58, companyCode: 302838877 },
  { codeId: 58, companyCode: 302838891 },
  { codeId: 58, companyCode: 302839025 },
  { codeId: 53, companyCode: 302839107 },
  { codeId: 58, companyCode: 302839178 },
  { codeId: 58, companyCode: 302839228 },
  { codeId: 58, companyCode: 302839274 },
  { codeId: 58, companyCode: 302839370 },
  { codeId: 58, companyCode: 302839388 },
  { codeId: 58, companyCode: 302839897 },
  { codeId: 58, companyCode: 302840006 },
  { codeId: 34, companyCode: 302840052 },
  { codeId: 26, companyCode: 302841243 },
  { codeId: 58, companyCode: 302841325 },
  { codeId: 24, companyCode: 302841364 },
  { codeId: 58, companyCode: 302841446 },
  { codeId: 58, companyCode: 302842060 },
  { codeId: 9, companyCode: 302842886 },
  { codeId: 58, companyCode: 302842936 },
  { codeId: 35, companyCode: 302842943 },
  { codeId: 58, companyCode: 302842950 },
  { codeId: 3, companyCode: 302843162 },
  { codeId: 58, companyCode: 302843276 },
  { codeId: 58, companyCode: 302843372 },
  { codeId: 53, companyCode: 302843511 },
  { codeId: 58, companyCode: 302843881 },
  { codeId: 58, companyCode: 302843924 },
  { codeId: 58, companyCode: 302843963 },
  { codeId: 49, companyCode: 302845213 },
  { codeId: 58, companyCode: 302846062 },
  { codeId: 58, companyCode: 302846094 },
  { codeId: 58, companyCode: 302846105 },
  { codeId: 58, companyCode: 302846112 },
  { codeId: 58, companyCode: 302846169 },
  { codeId: 58, companyCode: 302846233 },
  { codeId: 58, companyCode: 302846258 },
  { codeId: 58, companyCode: 302846354 },
  { codeId: 58, companyCode: 302846379 },
  { codeId: 58, companyCode: 302846386 },
  { codeId: 58, companyCode: 302846393 },
  { codeId: 58, companyCode: 302846404 },
  { codeId: 58, companyCode: 302846436 },
  { codeId: 58, companyCode: 302846468 },
  { codeId: 58, companyCode: 302846475 },
  { codeId: 58, companyCode: 302846482 },
  { codeId: 58, companyCode: 302846500 },
  { codeId: 58, companyCode: 302846518 },
  { codeId: 58, companyCode: 302846525 },
  { codeId: 58, companyCode: 302846532 },
  { codeId: 58, companyCode: 302846557 },
  { codeId: 58, companyCode: 302846614 },
  { codeId: 58, companyCode: 302846945 },
  { codeId: 24, companyCode: 302848070 },
  { codeId: 58, companyCode: 302848095 },
  { codeId: 58, companyCode: 302848145 },
  { codeId: 53, companyCode: 302848476 },
  { codeId: 58, companyCode: 302848864 },
  { codeId: 58, companyCode: 302849432 },
  { codeId: 34, companyCode: 302849507 },
  { codeId: 58, companyCode: 302850071 },
  { codeId: 59, companyCode: 302850107 },
  { codeId: 58, companyCode: 302850178 },
  { codeId: 58, companyCode: 302850242 },
  { codeId: 59, companyCode: 302850274 },
  { codeId: 58, companyCode: 302850388 },
  { codeId: 58, companyCode: 302850648 },
  { codeId: 58, companyCode: 302850801 },
  { codeId: 58, companyCode: 302851757 },
  { codeId: 58, companyCode: 302851764 },
  { codeId: 58, companyCode: 302851807 },
  { codeId: 49, companyCode: 302851903 },
  { codeId: 60, companyCode: 302852357 },
  { codeId: 17, companyCode: 302852706 },
  { codeId: 64, companyCode: 302852880 },
  { codeId: 58, companyCode: 302853772 },
  { codeId: 58, companyCode: 302853822 },
  { codeId: 58, companyCode: 302853854 },
  { codeId: 58, companyCode: 302854632 },
  { codeId: 58, companyCode: 302855104 },
  { codeId: 58, companyCode: 302855588 },
  { codeId: 58, companyCode: 302855613 },
  { codeId: 52, companyCode: 302856117 },
  { codeId: 58, companyCode: 302856455 },
  { codeId: 58, companyCode: 302856601 },
  { codeId: 58, companyCode: 302856868 },
  { codeId: 34, companyCode: 302856971 },
  { codeId: 58, companyCode: 302857393 },
  { codeId: 53, companyCode: 302857500 },
  { codeId: 58, companyCode: 302857646 },
  { codeId: 20, companyCode: 302857653 },
  { codeId: 58, companyCode: 302857703 },
  { codeId: 58, companyCode: 302858883 },
  { codeId: 53, companyCode: 302858940 },
  { codeId: 58, companyCode: 302859145 },
  { codeId: 58, companyCode: 302859330 },
  { codeId: 58, companyCode: 302859622 },
  { codeId: 58, companyCode: 302859661 },
  { codeId: 58, companyCode: 302859800 },
  { codeId: 53, companyCode: 302859825 },
  { codeId: 58, companyCode: 302860051 },
  { codeId: 58, companyCode: 302860119 },
  { codeId: 58, companyCode: 302860311 },
  { codeId: 50, companyCode: 302860343 },
  { codeId: 58, companyCode: 302860585 },
  { codeId: 58, companyCode: 302860667 },
  { codeId: 34, companyCode: 302861210 },
  { codeId: 58, companyCode: 302861527 },
  { codeId: 58, companyCode: 302861954 },
  { codeId: 58, companyCode: 302862084 },
  { codeId: 58, companyCode: 302862401 },
  { codeId: 58, companyCode: 302862472 },
  { codeId: 58, companyCode: 302862497 },
  { codeId: 58, companyCode: 302862515 },
  { codeId: 58, companyCode: 302862579 },
  { codeId: 58, companyCode: 302862593 },
  { codeId: 58, companyCode: 302862807 },
  { codeId: 58, companyCode: 302863161 },
  { codeId: 58, companyCode: 302863211 },
  { codeId: 58, companyCode: 302863243 },
  { codeId: 34, companyCode: 302863396 },
  { codeId: 58, companyCode: 302864014 },
  { codeId: 58, companyCode: 302864224 },
  { codeId: 58, companyCode: 302864320 },
  { codeId: 58, companyCode: 302864480 },
  { codeId: 58, companyCode: 302864555 },
  { codeId: 58, companyCode: 302865707 },
  { codeId: 58, companyCode: 302865714 },
  { codeId: 58, companyCode: 302865792 },
  { codeId: 58, companyCode: 302865874 },
  { codeId: 61, companyCode: 302865899 },
  { codeId: 58, companyCode: 302865995 },
  { codeId: 58, companyCode: 302866467 },
  { codeId: 58, companyCode: 302866990 },
  { codeId: 58, companyCode: 302867124 },
  { codeId: 58, companyCode: 302867131 },
  { codeId: 58, companyCode: 302867188 },
  { codeId: 16, companyCode: 302867220 },
  { codeId: 58, companyCode: 302867398 },
  { codeId: 58, companyCode: 302867626 },
  { codeId: 58, companyCode: 302867708 },
  { codeId: 58, companyCode: 302867754 },
  { codeId: 58, companyCode: 302867779 },
  { codeId: 57, companyCode: 302868404 },
  { codeId: 58, companyCode: 302868621 },
  { codeId: 58, companyCode: 302868767 },
  { codeId: 58, companyCode: 302868824 },
  { codeId: 58, companyCode: 302869043 },
  { codeId: 58, companyCode: 302869424 },
  { codeId: 58, companyCode: 302869513 },
  { codeId: 58, companyCode: 302869545 },
  { codeId: 58, companyCode: 302869552 },
  { codeId: 58, companyCode: 302869787 },
  { codeId: 58, companyCode: 302869844 },
  { codeId: 58, companyCode: 302869901 },
  { codeId: 60, companyCode: 302870049 },
  { codeId: 58, companyCode: 302870070 },
  { codeId: 58, companyCode: 302870088 },
  { codeId: 58, companyCode: 302870113 },
  { codeId: 58, companyCode: 302870120 },
  { codeId: 58, companyCode: 302870259 },
  { codeId: 58, companyCode: 302871090 },
  { codeId: 58, companyCode: 302871101 },
  { codeId: 58, companyCode: 302871197 },
  { codeId: 58, companyCode: 302871215 },
  { codeId: 58, companyCode: 302871286 },
  { codeId: 58, companyCode: 302871350 },
  { codeId: 58, companyCode: 302871418 },
  { codeId: 58, companyCode: 302871471 },
  { codeId: 24, companyCode: 302871496 },
  { codeId: 58, companyCode: 302871592 },
  { codeId: 58, companyCode: 302871674 },
  { codeId: 58, companyCode: 302871927 },
  { codeId: 58, companyCode: 302872395 },
  { codeId: 3, companyCode: 302872541 },
  { codeId: 58, companyCode: 302872573 },
  { codeId: 58, companyCode: 302873134 },
  { codeId: 58, companyCode: 302873853 },
  { codeId: 58, companyCode: 302874193 },
  { codeId: 58, companyCode: 302874229 },
  { codeId: 58, companyCode: 302874250 },
  { codeId: 58, companyCode: 302874282 },
  { codeId: 40, companyCode: 302874300 },
  { codeId: 58, companyCode: 302874364 },
  { codeId: 58, companyCode: 302874777 },
  { codeId: 53, companyCode: 302874784 },
  { codeId: 58, companyCode: 302874923 },
  { codeId: 53, companyCode: 302875427 },
  { codeId: 58, companyCode: 302875441 },
  { codeId: 58, companyCode: 302875740 },
  { codeId: 58, companyCode: 302875929 },
  { codeId: 58, companyCode: 302876269 },
  { codeId: 58, companyCode: 302876283 },
  { codeId: 58, companyCode: 302876358 },
  { codeId: 58, companyCode: 302876867 },
  { codeId: 58, companyCode: 302877271 },
  { codeId: 58, companyCode: 302877360 },
  { codeId: 58, companyCode: 302877499 },
  { codeId: 58, companyCode: 302877570 },
  { codeId: 58, companyCode: 302877652 },
  { codeId: 16, companyCode: 302877691 },
  { codeId: 58, companyCode: 302877944 },
  { codeId: 58, companyCode: 302878448 },
  { codeId: 58, companyCode: 302878487 },
  { codeId: 58, companyCode: 302878761 },
  { codeId: 58, companyCode: 302879201 },
  { codeId: 58, companyCode: 302879322 },
  { codeId: 58, companyCode: 302879386 },
  { codeId: 58, companyCode: 302879952 },
  { codeId: 58, companyCode: 302879977 },
  { codeId: 58, companyCode: 302880004 },
  { codeId: 58, companyCode: 302880029 },
  { codeId: 4, companyCode: 302880125 },
  { codeId: 58, companyCode: 302880303 },
  { codeId: 60, companyCode: 302881024 },
  { codeId: 58, companyCode: 302881120 },
  { codeId: 58, companyCode: 302881266 },
  { codeId: 58, companyCode: 302881273 },
  { codeId: 58, companyCode: 302881355 },
  { codeId: 58, companyCode: 302881394 },
  { codeId: 58, companyCode: 302881437 },
  { codeId: 58, companyCode: 302881476 },
  { codeId: 58, companyCode: 302881711 },
  { codeId: 58, companyCode: 302881736 },
  { codeId: 58, companyCode: 302881768 },
  { codeId: 53, companyCode: 302881782 },
  { codeId: 58, companyCode: 302882400 },
  { codeId: 58, companyCode: 302882464 },
  { codeId: 9, companyCode: 302882489 },
  { codeId: 58, companyCode: 302882507 },
  { codeId: 58, companyCode: 302882521 },
  { codeId: 47, companyCode: 302882603 },
  { codeId: 58, companyCode: 302882756 },
  { codeId: 58, companyCode: 302883064 },
  { codeId: 58, companyCode: 302883299 },
  { codeId: 16, companyCode: 302883395 },
  { codeId: 58, companyCode: 302883908 },
  { codeId: 58, companyCode: 302884013 },
  { codeId: 58, companyCode: 302884159 },
  { codeId: 58, companyCode: 302884312 },
  { codeId: 58, companyCode: 302884383 },
  { codeId: 58, companyCode: 302884440 },
  { codeId: 58, companyCode: 302884853 },
  { codeId: 58, companyCode: 302884903 },
  { codeId: 58, companyCode: 302884928 },
  { codeId: 58, companyCode: 302884942 },
  { codeId: 58, companyCode: 302884974 },
  { codeId: 58, companyCode: 302885229 },
  { codeId: 58, companyCode: 302885243 },
  { codeId: 58, companyCode: 302885478 },
  { codeId: 58, companyCode: 302885656 },
  { codeId: 53, companyCode: 302885738 },
  { codeId: 19, companyCode: 302885752 },
  { codeId: 58, companyCode: 302886427 },
  { codeId: 58, companyCode: 302886441 },
  { codeId: 58, companyCode: 302886733 },
  { codeId: 58, companyCode: 302887148 },
  { codeId: 58, companyCode: 302887251 },
  { codeId: 58, companyCode: 302887290 },
  { codeId: 58, companyCode: 302888403 },
  { codeId: 58, companyCode: 302888499 },
  { codeId: 58, companyCode: 302888702 },
  { codeId: 53, companyCode: 302889277 },
  { codeId: 64, companyCode: 302890361 },
  { codeId: 58, companyCode: 302890436 },
  { codeId: 58, companyCode: 302890621 },
  { codeId: 58, companyCode: 302890906 },
  { codeId: 58, companyCode: 302891029 },
  { codeId: 58, companyCode: 302891246 },
  { codeId: 58, companyCode: 302891253 },
  { codeId: 19, companyCode: 302891260 },
  { codeId: 58, companyCode: 302891303 },
  { codeId: 58, companyCode: 302891374 },
  { codeId: 58, companyCode: 302891456 },
  { codeId: 58, companyCode: 302892024 },
  { codeId: 58, companyCode: 302892049 },
  { codeId: 58, companyCode: 302892177 },
  { codeId: 58, companyCode: 302892330 },
  { codeId: 58, companyCode: 302892469 },
  { codeId: 60, companyCode: 302892572 },
  { codeId: 58, companyCode: 302892939 },
  { codeId: 58, companyCode: 302892985 },
  { codeId: 58, companyCode: 302893044 },
  { codeId: 58, companyCode: 302893133 },
  { codeId: 58, companyCode: 302893158 },
  { codeId: 58, companyCode: 302893247 },
  { codeId: 58, companyCode: 302893261 },
  { codeId: 58, companyCode: 302893400 },
  { codeId: 58, companyCode: 302893496 },
  { codeId: 53, companyCode: 302893539 },
  { codeId: 53, companyCode: 302893592 },
  { codeId: 58, companyCode: 302893877 },
  { codeId: 58, companyCode: 302893934 },
  { codeId: 58, companyCode: 302894018 },
  { codeId: 58, companyCode: 302894071 },
  { codeId: 28, companyCode: 302894445 },
  { codeId: 21, companyCode: 302894769 },
  { codeId: 19, companyCode: 302895383 },
  { codeId: 58, companyCode: 302895935 },
  { codeId: 58, companyCode: 302896001 },
  { codeId: 58, companyCode: 302896026 },
  { codeId: 58, companyCode: 302896033 },
  { codeId: 64, companyCode: 302896371 },
  { codeId: 58, companyCode: 302896446 },
  { codeId: 58, companyCode: 302896948 },
  { codeId: 58, companyCode: 302897085 },
  { codeId: 58, companyCode: 302897181 },
  { codeId: 38, companyCode: 302897224 },
  { codeId: 58, companyCode: 302897288 },
  { codeId: 58, companyCode: 302897295 },
  { codeId: 57, companyCode: 302897498 },
  { codeId: 58, companyCode: 302897683 },
  { codeId: 58, companyCode: 302898059 },
  { codeId: 58, companyCode: 302898408 },
  { codeId: 58, companyCode: 302898461 },
  { codeId: 58, companyCode: 302898536 },
  { codeId: 58, companyCode: 302898739 },
  { codeId: 58, companyCode: 302898828 },
  { codeId: 58, companyCode: 302898842 },
  { codeId: 58, companyCode: 302898917 },
  { codeId: 58, companyCode: 302899079 },
  { codeId: 58, companyCode: 302899150 },
  { codeId: 58, companyCode: 302899168 },
  { codeId: 58, companyCode: 302899339 },
  { codeId: 58, companyCode: 302899442 },
  { codeId: 58, companyCode: 302899517 },
  { codeId: 58, companyCode: 302899620 },
  { codeId: 58, companyCode: 302899855 },
  { codeId: 60, companyCode: 302899951 },
  { codeId: 58, companyCode: 302900396 },
  { codeId: 53, companyCode: 302900446 },
  { codeId: 58, companyCode: 302900670 },
  { codeId: 17, companyCode: 302900841 },
  { codeId: 58, companyCode: 302901594 },
  { codeId: 58, companyCode: 302901644 },
  { codeId: 58, companyCode: 302901651 },
  { codeId: 58, companyCode: 302901669 },
  { codeId: 32, companyCode: 302901676 },
  { codeId: 58, companyCode: 302901726 },
  { codeId: 58, companyCode: 302901797 },
  { codeId: 58, companyCode: 302901808 },
  { codeId: 58, companyCode: 302901822 },
  { codeId: 58, companyCode: 302901847 },
  { codeId: 58, companyCode: 302901936 },
  { codeId: 58, companyCode: 302902365 },
  { codeId: 58, companyCode: 302903015 },
  { codeId: 58, companyCode: 302903150 },
  { codeId: 58, companyCode: 302903360 },
  { codeId: 58, companyCode: 302904341 },
  { codeId: 58, companyCode: 302904487 },
  { codeId: 58, companyCode: 302904505 },
  { codeId: 58, companyCode: 302904512 },
  { codeId: 58, companyCode: 302904601 },
  { codeId: 58, companyCode: 302904658 },
  { codeId: 58, companyCode: 302904665 },
  { codeId: 58, companyCode: 302904715 },
  { codeId: 58, companyCode: 302904747 },
  { codeId: 58, companyCode: 302904754 },
  { codeId: 27, companyCode: 302904971 },
  { codeId: 58, companyCode: 302906260 },
  { codeId: 58, companyCode: 302906424 },
  { codeId: 19, companyCode: 302906506 },
  { codeId: 58, companyCode: 302906666 },
  { codeId: 58, companyCode: 302906673 },
  { codeId: 48, companyCode: 302907138 },
  { codeId: 58, companyCode: 302907191 },
  { codeId: 58, companyCode: 302907889 },
  { codeId: 58, companyCode: 302908293 },
  { codeId: 53, companyCode: 302908375 },
  { codeId: 58, companyCode: 302909491 },
  { codeId: 58, companyCode: 302909527 },
  { codeId: 58, companyCode: 302909534 },
  { codeId: 58, companyCode: 302909566 },
  { codeId: 58, companyCode: 302909986 },
  { codeId: 48, companyCode: 302910198 },
  { codeId: 34, companyCode: 302910515 },
  { codeId: 58, companyCode: 302910928 },
  { codeId: 58, companyCode: 302911204 },
  { codeId: 58, companyCode: 302911396 },
  { codeId: 58, companyCode: 302912110 },
  { codeId: 53, companyCode: 302912181 },
  { codeId: 58, companyCode: 302912459 },
  { codeId: 58, companyCode: 302912473 },
  { codeId: 58, companyCode: 302913212 },
  { codeId: 58, companyCode: 302913358 },
  { codeId: 58, companyCode: 302913504 },
  { codeId: 58, companyCode: 302913931 },
  { codeId: 58, companyCode: 302914054 },
  { codeId: 58, companyCode: 302914168 },
  { codeId: 40, companyCode: 302914385 },
  { codeId: 58, companyCode: 302914855 },
  { codeId: 58, companyCode: 302915131 },
  { codeId: 58, companyCode: 302915156 },
  { codeId: 58, companyCode: 302915195 },
  { codeId: 58, companyCode: 302915213 },
  { codeId: 58, companyCode: 302915626 },
  { codeId: 58, companyCode: 302915633 },
  { codeId: 58, companyCode: 302915640 },
  { codeId: 58, companyCode: 302915658 },
  { codeId: 58, companyCode: 302916265 },
  { codeId: 20, companyCode: 302916482 },
  { codeId: 53, companyCode: 302916945 },
  { codeId: 58, companyCode: 302917342 },
  { codeId: 21, companyCode: 302917495 },
  { codeId: 58, companyCode: 302918145 },
  { codeId: 21, companyCode: 302918490 },
  { codeId: 58, companyCode: 302918647 },
  { codeId: 23, companyCode: 302918896 },
  { codeId: 58, companyCode: 302919471 },
  { codeId: 58, companyCode: 302919489 },
  { codeId: 58, companyCode: 302919496 },
  { codeId: 58, companyCode: 302919507 },
  { codeId: 58, companyCode: 302919521 },
  { codeId: 58, companyCode: 302919539 },
  { codeId: 58, companyCode: 302919546 },
  { codeId: 58, companyCode: 302919560 },
  { codeId: 58, companyCode: 302919610 },
  { codeId: 58, companyCode: 302919635 },
  { codeId: 58, companyCode: 302919674 },
  { codeId: 58, companyCode: 302919681 },
  { codeId: 58, companyCode: 302919699 },
  { codeId: 50, companyCode: 302919731 },
  { codeId: 58, companyCode: 302919877 },
  { codeId: 58, companyCode: 302919884 },
  { codeId: 58, companyCode: 302919902 },
  { codeId: 58, companyCode: 302919927 },
  { codeId: 58, companyCode: 302919934 },
  { codeId: 58, companyCode: 302919941 },
  { codeId: 58, companyCode: 302919966 },
  { codeId: 58, companyCode: 302919973 },
  { codeId: 58, companyCode: 302919980 },
  { codeId: 58, companyCode: 302920139 },
  { codeId: 58, companyCode: 302920203 },
  { codeId: 58, companyCode: 302920267 },
  { codeId: 58, companyCode: 302920281 },
  { codeId: 58, companyCode: 302920317 },
  { codeId: 58, companyCode: 302920331 },
  { codeId: 58, companyCode: 302920527 },
  { codeId: 58, companyCode: 302920541 },
  { codeId: 58, companyCode: 302920580 },
  { codeId: 58, companyCode: 302920598 },
  { codeId: 58, companyCode: 302920609 },
  { codeId: 58, companyCode: 302920623 },
  { codeId: 58, companyCode: 302920655 },
  { codeId: 58, companyCode: 302920712 },
  { codeId: 58, companyCode: 302920744 },
  { codeId: 58, companyCode: 302920776 },
  { codeId: 58, companyCode: 302920783 },
  { codeId: 58, companyCode: 302920801 },
  { codeId: 58, companyCode: 302920826 },
  { codeId: 58, companyCode: 302920833 },
  { codeId: 58, companyCode: 302921102 },
  { codeId: 58, companyCode: 302921127 },
  { codeId: 11, companyCode: 302921134 },
  { codeId: 58, companyCode: 302921141 },
  { codeId: 58, companyCode: 302921159 },
  { codeId: 58, companyCode: 302921180 },
  { codeId: 58, companyCode: 302921198 },
  { codeId: 58, companyCode: 302921209 },
  { codeId: 58, companyCode: 302921216 },
  { codeId: 58, companyCode: 302921223 },
  { codeId: 58, companyCode: 302921255 },
  { codeId: 58, companyCode: 302921305 },
  { codeId: 58, companyCode: 302921344 },
  { codeId: 58, companyCode: 302921390 },
  { codeId: 58, companyCode: 302921419 },
  { codeId: 58, companyCode: 302921426 },
  { codeId: 58, companyCode: 302921433 },
  { codeId: 58, companyCode: 302921508 },
  { codeId: 58, companyCode: 302922243 },
  { codeId: 58, companyCode: 302922542 },
  { codeId: 58, companyCode: 302922574 },
  { codeId: 58, companyCode: 302922599 },
  { codeId: 58, companyCode: 302922617 },
  { codeId: 58, companyCode: 302922624 },
  { codeId: 58, companyCode: 302922962 },
  { codeId: 58, companyCode: 302922994 },
  { codeId: 20, companyCode: 302923466 },
  { codeId: 53, companyCode: 302924212 },
  { codeId: 40, companyCode: 302924493 },
  { codeId: 16, companyCode: 302924511 },
  { codeId: 35, companyCode: 302926487 },
  { codeId: 3, companyCode: 302926601 },
  { codeId: 58, companyCode: 302927518 },
  { codeId: 58, companyCode: 302927596 },
  { codeId: 58, companyCode: 302927703 },
  { codeId: 16, companyCode: 302927774 },
  { codeId: 58, companyCode: 302929241 },
  { codeId: 19, companyCode: 302929355 },
  { codeId: 19, companyCode: 302929608 },
  { codeId: 60, companyCode: 302929686 },
  { codeId: 16, companyCode: 302930966 },
  { codeId: 58, companyCode: 302931064 },
  { codeId: 19, companyCode: 302931452 },
  { codeId: 58, companyCode: 302932497 },
  { codeId: 58, companyCode: 302933528 },
  { codeId: 58, companyCode: 302933581 },
  { codeId: 58, companyCode: 302933656 },
  { codeId: 58, companyCode: 302933663 },
  { codeId: 58, companyCode: 302933752 },
  { codeId: 58, companyCode: 302933909 },
  { codeId: 58, companyCode: 302933916 },
  { codeId: 58, companyCode: 302933923 },
  { codeId: 58, companyCode: 302933930 },
  { codeId: 58, companyCode: 302933955 },
  { codeId: 58, companyCode: 302933962 },
  { codeId: 58, companyCode: 302933987 },
  { codeId: 58, companyCode: 302933994 },
  { codeId: 58, companyCode: 302934007 },
  { codeId: 58, companyCode: 302934014 },
  { codeId: 16, companyCode: 302934085 },
  { codeId: 58, companyCode: 302934690 },
  { codeId: 58, companyCode: 302934701 },
  { codeId: 51, companyCode: 302937042 },
  { codeId: 58, companyCode: 302938393 },
  { codeId: 58, companyCode: 302940113 },
  { codeId: 53, companyCode: 302940679 },
  { codeId: 20, companyCode: 302940914 },
  { codeId: 58, companyCode: 302941119 },
  { codeId: 58, companyCode: 302941959 },
  { codeId: 19, companyCode: 302942018 },
  { codeId: 58, companyCode: 302944738 },
  { codeId: 58, companyCode: 302946696 },
  { codeId: 20, companyCode: 302947620 },
  { codeId: 19, companyCode: 302948590 },
  { codeId: 53, companyCode: 302949393 },
  { codeId: 19, companyCode: 302950417 },
  { codeId: 16, companyCode: 302950424 },
  { codeId: 58, companyCode: 302950463 },
  { codeId: 53, companyCode: 302950513 },
  { codeId: 53, companyCode: 302953484 },
  { codeId: 24, companyCode: 302956352 },
  { codeId: 58, companyCode: 302956929 },
  { codeId: 19, companyCode: 302958022 },
  { codeId: 16, companyCode: 302961965 },
  { codeId: 9, companyCode: 302962412 },
  { codeId: 58, companyCode: 302962864 },
  { codeId: 58, companyCode: 302965426 },
  { codeId: 51, companyCode: 302968949 },
  { codeId: 57, companyCode: 302970366 },
  { codeId: 53, companyCode: 302975438 },
  { codeId: 21, companyCode: 302977909 },
  { codeId: 53, companyCode: 302979504 },
  { codeId: 53, companyCode: 302980887 },
  { codeId: 53, companyCode: 302986961 },
  { codeId: 16, companyCode: 302987401 },
  { codeId: 53, companyCode: 302992359 },
  { codeId: 50, companyCode: 302993443 },
  { codeId: 53, companyCode: 302994926 },
  { codeId: 3, companyCode: 302995519 },
  { codeId: 34, companyCode: 302995946 },
  { codeId: 53, companyCode: 302997363 },
  { codeId: 16, companyCode: 302998390 },
  { codeId: 19, companyCode: 302998757 },
  { codeId: 16, companyCode: 303000364 },
  { codeId: 19, companyCode: 303000407 },
  { codeId: 53, companyCode: 303001555 },
  { codeId: 16, companyCode: 303001644 },
  { codeId: 58, companyCode: 303002760 },
  { codeId: 20, companyCode: 303003620 },
  { codeId: 53, companyCode: 303004996 },
  { codeId: 20, companyCode: 303005856 },
  { codeId: 48, companyCode: 303006673 },
  { codeId: 16, companyCode: 303009833 },
  { codeId: 58, companyCode: 303010255 },
  { codeId: 16, companyCode: 303011275 },
  { codeId: 3, companyCode: 303011414 },
  { codeId: 57, companyCode: 303012929 },
  { codeId: 16, companyCode: 303013290 },
  { codeId: 20, companyCode: 303013372 },
  { codeId: 19, companyCode: 303014346 },
  { codeId: 34, companyCode: 303016379 },
  { codeId: 53, companyCode: 303017214 },
  { codeId: 53, companyCode: 303019852 },
  { codeId: 24, companyCode: 303021006 },
  { codeId: 20, companyCode: 303022026 },
  { codeId: 53, companyCode: 303022154 },
  { codeId: 15, companyCode: 303023530 },
  { codeId: 24, companyCode: 303025677 },
  { codeId: 53, companyCode: 303028723 },
  { codeId: 28, companyCode: 303028844 },
  { codeId: 11, companyCode: 303028869 },
  { codeId: 24, companyCode: 303029565 },
  { codeId: 63, companyCode: 303029654 },
  { codeId: 53, companyCode: 303031406 },
  { codeId: 24, companyCode: 303031413 },
  { codeId: 19, companyCode: 303031865 },
  { codeId: 19, companyCode: 303032216 },
  { codeId: 16, companyCode: 303034587 },
  { codeId: 21, companyCode: 303036894 },
  { codeId: 27, companyCode: 303037327 },
  { codeId: 43, companyCode: 303038144 },
  { codeId: 57, companyCode: 303038945 },
  { codeId: 19, companyCode: 303039463 },
  { codeId: 53, companyCode: 303041749 },
  { codeId: 28, companyCode: 303041756 },
  { codeId: 53, companyCode: 303042388 },
  { codeId: 21, companyCode: 303043732 },
  { codeId: 53, companyCode: 303043796 },
  { codeId: 28, companyCode: 303045352 },
  { codeId: 16, companyCode: 303045847 },
  { codeId: 53, companyCode: 303045950 },
  { codeId: 20, companyCode: 303046696 },
  { codeId: 21, companyCode: 303048003 },
  { codeId: 53, companyCode: 303051031 },
  { codeId: 6, companyCode: 303051070 },
  { codeId: 34, companyCode: 303053388 },
  { codeId: 15, companyCode: 303053413 },
  { codeId: 53, companyCode: 303053420 },
  { codeId: 34, companyCode: 303053573 },
  { codeId: 53, companyCode: 303053655 },
  { codeId: 18, companyCode: 303054522 },
  { codeId: 53, companyCode: 303056929 },
  { codeId: 5, companyCode: 303058684 },
  { codeId: 28, companyCode: 303061901 },
  { codeId: 19, companyCode: 303063293 },
  { codeId: 24, companyCode: 303065159 },
  { codeId: 53, companyCode: 303065390 },
  { codeId: 21, companyCode: 303065522 },
  { codeId: 53, companyCode: 303065910 },
  { codeId: 53, companyCode: 303066001 },
  { codeId: 33, companyCode: 303066909 },
  { codeId: 12, companyCode: 303067391 },
  { codeId: 8, companyCode: 303068867 },
  { codeId: 16, companyCode: 303070868 },
  { codeId: 9, companyCode: 303071895 },
  { codeId: 53, companyCode: 303073693 },
  { codeId: 35, companyCode: 303073864 },
  { codeId: 34, companyCode: 303074877 },
  { codeId: 6, companyCode: 303074973 },
  { codeId: 34, companyCode: 303077994 },
  { codeId: 34, companyCode: 303079244 },
  { codeId: 19, companyCode: 303082105 },
  { codeId: 53, companyCode: 303082265 },
  { codeId: 4, companyCode: 303085763 },
  { codeId: 57, companyCode: 303086477 },
  { codeId: 16, companyCode: 303087369 },
  { codeId: 16, companyCode: 303087433 },
  { codeId: 50, companyCode: 303087981 },
  { codeId: 58, companyCode: 303089854 },
  { codeId: 24, companyCode: 303090027 },
  { codeId: 53, companyCode: 303090283 },
  { codeId: 19, companyCode: 303090365 },
  { codeId: 19, companyCode: 303091652 },
  { codeId: 53, companyCode: 303092373 },
  { codeId: 21, companyCode: 303092551 },
  { codeId: 48, companyCode: 303092583 },
  { codeId: 3, companyCode: 303093087 },
  { codeId: 12, companyCode: 303093703 },
  { codeId: 53, companyCode: 303094933 },
  { codeId: 40, companyCode: 303096756 },
  { codeId: 19, companyCode: 303096927 },
  { codeId: 3, companyCode: 303097922 },
  { codeId: 53, companyCode: 303098166 },
  { codeId: 53, companyCode: 303101356 },
  { codeId: 53, companyCode: 303101370 },
  { codeId: 53, companyCode: 303101655 },
  { codeId: 19, companyCode: 303102928 },
  { codeId: 16, companyCode: 303106442 },
  { codeId: 53, companyCode: 303107423 },
  { codeId: 16, companyCode: 303107640 },
  { codeId: 53, companyCode: 303107747 },
  { codeId: 54, companyCode: 303111425 },
  { codeId: 4, companyCode: 303113636 },
  { codeId: 53, companyCode: 303113675 },
  { codeId: 16, companyCode: 303114033 },
  { codeId: 9, companyCode: 303115174 },
  { codeId: 58, companyCode: 303115313 },
  { codeId: 19, companyCode: 303117353 },
  { codeId: 53, companyCode: 303117595 },
  { codeId: 49, companyCode: 303117848 },
  { codeId: 16, companyCode: 303118747 },
  { codeId: 53, companyCode: 303120029 },
  { codeId: 53, companyCode: 303121298 },
  { codeId: 16, companyCode: 303123064 },
  { codeId: 58, companyCode: 303124465 },
  { codeId: 64, companyCode: 303125389 },
  { codeId: 19, companyCode: 303126879 },
  { codeId: 53, companyCode: 303126904 },
  { codeId: 19, companyCode: 303127568 },
  { codeId: 53, companyCode: 303127753 },
  { codeId: 11, companyCode: 303127828 },
  { codeId: 53, companyCode: 303130429 },
  { codeId: 53, companyCode: 303131958 },
  { codeId: 53, companyCode: 303132273 },
  { codeId: 53, companyCode: 303132469 },
  { codeId: 16, companyCode: 303134712 },
  { codeId: 57, companyCode: 303134783 },
  { codeId: 53, companyCode: 303138746 },
  { codeId: 53, companyCode: 303139378 },
  { codeId: 20, companyCode: 303143800 },
  { codeId: 47, companyCode: 303148263 },
  { codeId: 53, companyCode: 303159288 },
  { codeId: 53, companyCode: 303160155 },
  { codeId: 53, companyCode: 303161944 },
  { codeId: 24, companyCode: 303164189 },
  { codeId: 53, companyCode: 303164310 },
  { codeId: 40, companyCode: 303166980 },
  { codeId: 53, companyCode: 303167178 },
  { codeId: 21, companyCode: 303168885 },
  { codeId: 49, companyCode: 303169097 },
  { codeId: 53, companyCode: 303169179 },
  { codeId: 53, companyCode: 303170669 },
  { codeId: 20, companyCode: 303170950 },
  { codeId: 19, companyCode: 303174614 },
  { codeId: 53, companyCode: 303177813 },
  { codeId: 16, companyCode: 303177838 },
  { codeId: 34, companyCode: 303178388 },
  { codeId: 58, companyCode: 303178420 },
  { codeId: 34, companyCode: 303178908 },
  { codeId: 19, companyCode: 303179650 },
  { codeId: 10, companyCode: 303182543 },
  { codeId: 31, companyCode: 303182664 },
  { codeId: 53, companyCode: 303183613 },
  { codeId: 19, companyCode: 303184850 },
  { codeId: 21, companyCode: 303186381 },
  { codeId: 19, companyCode: 303188980 },
  { codeId: 23, companyCode: 303190006 },
  { codeId: 53, companyCode: 303190084 },
  { codeId: 53, companyCode: 303190180 },
  { codeId: 58, companyCode: 303191229 },
  { codeId: 3, companyCode: 303192021 },
  { codeId: 21, companyCode: 303192441 },
  { codeId: 53, companyCode: 303192562 },
  { codeId: 53, companyCode: 303192612 },
  { codeId: 49, companyCode: 303193493 },
  { codeId: 53, companyCode: 303193792 },
  { codeId: 53, companyCode: 303193874 },
  { codeId: 58, companyCode: 303193924 },
  { codeId: 49, companyCode: 303193956 },
  { codeId: 49, companyCode: 303193988 },
  { codeId: 40, companyCode: 303199222 },
  { codeId: 34, companyCode: 303199980 },
  { codeId: 53, companyCode: 303201004 },
  { codeId: 48, companyCode: 303202864 },
  { codeId: 53, companyCode: 303202939 },
  { codeId: 58, companyCode: 303202960 },
  { codeId: 53, companyCode: 303203083 },
  { codeId: 58, companyCode: 303203304 },
  { codeId: 16, companyCode: 303204438 },
  { codeId: 20, companyCode: 303204598 },
  { codeId: 16, companyCode: 303207669 },
  { codeId: 57, companyCode: 303211436 },
  { codeId: 58, companyCode: 303212050 },
  { codeId: 53, companyCode: 303212495 },
  { codeId: 19, companyCode: 303215790 },
  { codeId: 20, companyCode: 303215922 },
  { codeId: 64, companyCode: 303216718 },
  { codeId: 4, companyCode: 303218911 },
  { codeId: 53, companyCode: 303219123 },
  { codeId: 21, companyCode: 303222518 },
  { codeId: 36, companyCode: 303222589 },
  { codeId: 9, companyCode: 303222728 },
  { codeId: 53, companyCode: 303223901 },
  { codeId: 20, companyCode: 303224914 },
  { codeId: 60, companyCode: 303225457 },
  { codeId: 53, companyCode: 303226630 },
  { codeId: 16, companyCode: 303227789 },
  { codeId: 16, companyCode: 303227892 },
  { codeId: 58, companyCode: 303228542 },
  { codeId: 53, companyCode: 303228617 },
  { codeId: 35, companyCode: 303232220 },
  { codeId: 34, companyCode: 303234132 },
  { codeId: 53, companyCode: 303235323 },
  { codeId: 19, companyCode: 303235362 },
  { codeId: 53, companyCode: 303235679 },
  { codeId: 21, companyCode: 303235896 },
  { codeId: 53, companyCode: 303235992 },
  { codeId: 37, companyCode: 303236172 },
  { codeId: 24, companyCode: 303236279 },
  { codeId: 16, companyCode: 303238472 },
  { codeId: 34, companyCode: 303239421 },
  { codeId: 53, companyCode: 303242378 },
  { codeId: 53, companyCode: 303242606 },
  { codeId: 20, companyCode: 303243754 },
  { codeId: 34, companyCode: 303243925 },
  { codeId: 53, companyCode: 303244169 },
  { codeId: 53, companyCode: 303245214 },
  { codeId: 53, companyCode: 303245335 },
  { codeId: 9, companyCode: 303245958 },
  { codeId: 19, companyCode: 303249173 },
  { codeId: 53, companyCode: 303250446 },
  { codeId: 9, companyCode: 303250535 },
  { codeId: 24, companyCode: 303251943 },
  { codeId: 53, companyCode: 303252187 },
  { codeId: 53, companyCode: 303252486 },
  { codeId: 53, companyCode: 303252995 },
  { codeId: 20, companyCode: 303253086 },
  { codeId: 61, companyCode: 303254067 },
  { codeId: 53, companyCode: 303254971 },
  { codeId: 21, companyCode: 303255233 },
  { codeId: 50, companyCode: 303255272 },
  { codeId: 53, companyCode: 303256488 },
  { codeId: 21, companyCode: 303257184 },
  { codeId: 16, companyCode: 303258902 },
  { codeId: 49, companyCode: 303259954 },
  { codeId: 16, companyCode: 303260141 },
  { codeId: 19, companyCode: 303261026 },
  { codeId: 53, companyCode: 303261211 },
  { codeId: 19, companyCode: 303261396 },
  { codeId: 24, companyCode: 303262007 },
  { codeId: 16, companyCode: 303263461 },
  { codeId: 61, companyCode: 303265569 },
  { codeId: 53, companyCode: 303265754 },
  { codeId: 53, companyCode: 303266614 },
  { codeId: 53, companyCode: 303267634 },
  { codeId: 53, companyCode: 303270491 },
  { codeId: 20, companyCode: 303271611 },
  { codeId: 34, companyCode: 303271629 },
  { codeId: 19, companyCode: 303272567 },
  { codeId: 53, companyCode: 303274212 },
  { codeId: 3, companyCode: 303274237 },
  { codeId: 16, companyCode: 303274447 },
  { codeId: 57, companyCode: 303275531 },
  { codeId: 57, companyCode: 303276658 },
  { codeId: 11, companyCode: 303277799 },
  { codeId: 6, companyCode: 303283211 },
  { codeId: 57, companyCode: 303283898 },
  { codeId: 34, companyCode: 303286047 },
  { codeId: 53, companyCode: 303286104 },
  { codeId: 51, companyCode: 303286613 },
  { codeId: 17, companyCode: 303287505 },
  { codeId: 48, companyCode: 303287697 },
  { codeId: 16, companyCode: 303287747 },
  { codeId: 35, companyCode: 303289082 },
  { codeId: 53, companyCode: 303289239 },
  { codeId: 53, companyCode: 303290451 },
  { codeId: 19, companyCode: 303292783 },
  { codeId: 61, companyCode: 303292954 },
  { codeId: 4, companyCode: 303293878 },
  { codeId: 53, companyCode: 303294446 },
  { codeId: 53, companyCode: 303295181 },
  { codeId: 47, companyCode: 303297652 },
  { codeId: 19, companyCode: 303297780 },
  { codeId: 53, companyCode: 303298754 },
  { codeId: 40, companyCode: 303299055 },
  { codeId: 53, companyCode: 303299500 },
  { codeId: 50, companyCode: 303299639 },
  { codeId: 24, companyCode: 303299767 },
  { codeId: 53, companyCode: 303299888 },
  { codeId: 53, companyCode: 303301150 },
  { codeId: 16, companyCode: 303303151 },
  { codeId: 20, companyCode: 303305259 },
  { codeId: 53, companyCode: 303305405 },
  { codeId: 33, companyCode: 303305412 },
  { codeId: 47, companyCode: 303305490 },
  { codeId: 34, companyCode: 303305978 },
  { codeId: 50, companyCode: 303306998 },
  { codeId: 20, companyCode: 303309275 },
  { codeId: 5, companyCode: 303310060 },
  { codeId: 34, companyCode: 303310224 },
  { codeId: 54, companyCode: 303310338 },
  { codeId: 53, companyCode: 303312086 },
  { codeId: 10, companyCode: 303313067 },
  { codeId: 35, companyCode: 303315164 },
  { codeId: 19, companyCode: 303315506 },
  { codeId: 53, companyCode: 303316138 },
  { codeId: 3, companyCode: 303317069 },
  { codeId: 57, companyCode: 303317717 },
  { codeId: 50, companyCode: 303322194 },
  { codeId: 11, companyCode: 303322575 },
  { codeId: 3, companyCode: 303323232 },
  { codeId: 64, companyCode: 303323314 },
  { codeId: 19, companyCode: 303327049 },
  { codeId: 20, companyCode: 303327120 },
  { codeId: 17, companyCode: 303327907 },
  { codeId: 63, companyCode: 303328489 },
  { codeId: 53, companyCode: 303329915 },
  { codeId: 48, companyCode: 303331318 },
  { codeId: 53, companyCode: 303334307 },
  { codeId: 19, companyCode: 303334937 },
  { codeId: 49, companyCode: 303335149 },
  { codeId: 18, companyCode: 303335334 },
  { codeId: 11, companyCode: 303336087 },
  { codeId: 16, companyCode: 303336265 },
  { codeId: 16, companyCode: 303338145 },
  { codeId: 53, companyCode: 303338177 },
  { codeId: 21, companyCode: 303339471 },
  { codeId: 49, companyCode: 303339628 },
  { codeId: 3, companyCode: 303339749 },
  { codeId: 58, companyCode: 303340491 },
  { codeId: 53, companyCode: 303340598 },
  { codeId: 33, companyCode: 303341433 },
  { codeId: 53, companyCode: 303342065 },
  { codeId: 48, companyCode: 303342567 },
  { codeId: 58, companyCode: 303342670 },
  { codeId: 4, companyCode: 303342713 },
  { codeId: 53, companyCode: 303344130 },
  { codeId: 36, companyCode: 303344187 },
  { codeId: 34, companyCode: 303344778 },
  { codeId: 53, companyCode: 303344956 },
  { codeId: 53, companyCode: 303345392 },
  { codeId: 9, companyCode: 303345855 },
  { codeId: 53, companyCode: 303346341 },
  { codeId: 40, companyCode: 303347404 },
  { codeId: 58, companyCode: 303349152 },
  { codeId: 53, companyCode: 303349540 },
  { codeId: 53, companyCode: 303350496 },
  { codeId: 34, companyCode: 303350592 },
  { codeId: 17, companyCode: 303350838 },
  { codeId: 16, companyCode: 303351705 },
  { codeId: 53, companyCode: 303353364 },
  { codeId: 34, companyCode: 303354690 },
  { codeId: 34, companyCode: 303354879 },
  { codeId: 61, companyCode: 303356652 },
  { codeId: 35, companyCode: 303356912 },
  { codeId: 21, companyCode: 303363013 },
  { codeId: 5, companyCode: 303364122 },
  { codeId: 21, companyCode: 303366970 },
  { codeId: 34, companyCode: 303367054 },
  { codeId: 53, companyCode: 303367894 },
  { codeId: 16, companyCode: 303370196 },
  { codeId: 52, companyCode: 303371017 },
  { codeId: 20, companyCode: 303371330 },
  { codeId: 53, companyCode: 303373071 },
  { codeId: 19, companyCode: 303373922 },
  { codeId: 35, companyCode: 303374038 },
  { codeId: 53, companyCode: 303375300 },
  { codeId: 53, companyCode: 303376459 },
  { codeId: 11, companyCode: 303379117 },
  { codeId: 34, companyCode: 303379633 },
  { codeId: 16, companyCode: 303379836 },
  { codeId: 53, companyCode: 303381253 },
  { codeId: 53, companyCode: 303381488 },
  { codeId: 63, companyCode: 303383425 },
  { codeId: 20, companyCode: 303384121 },
  { codeId: 19, companyCode: 303384947 },
  { codeId: 38, companyCode: 303385045 },
  { codeId: 3, companyCode: 303385305 },
  { codeId: 16, companyCode: 303385401 },
  { codeId: 53, companyCode: 303389474 },
  { codeId: 58, companyCode: 303390601 },
  { codeId: 57, companyCode: 303391240 },
  { codeId: 40, companyCode: 303392901 },
  { codeId: 49, companyCode: 303397478 },
  { codeId: 20, companyCode: 303398879 },
  { codeId: 28, companyCode: 303400554 },
  { codeId: 20, companyCode: 303400725 },
  { codeId: 24, companyCode: 303402046 },
  { codeId: 28, companyCode: 303403486 },
  { codeId: 53, companyCode: 303403707 },
  { codeId: 53, companyCode: 303406411 },
  { codeId: 19, companyCode: 303406429 },
  { codeId: 53, companyCode: 303407029 },
  { codeId: 19, companyCode: 303409471 },
  { codeId: 16, companyCode: 303410235 },
  { codeId: 53, companyCode: 303411903 },
  { codeId: 53, companyCode: 303414874 },
  { codeId: 5, companyCode: 303416882 },
  { codeId: 19, companyCode: 303417895 },
  { codeId: 19, companyCode: 303420432 },
  { codeId: 20, companyCode: 303422209 },
  { codeId: 53, companyCode: 303422472 },
  { codeId: 21, companyCode: 303423407 },
  { codeId: 33, companyCode: 303425511 },
  { codeId: 53, companyCode: 303428482 },
  { codeId: 35, companyCode: 303429577 },
  { codeId: 53, companyCode: 303431158 },
  { codeId: 36, companyCode: 303432445 },
  { codeId: 50, companyCode: 303432961 },
  { codeId: 53, companyCode: 303439386 },
  { codeId: 64, companyCode: 303439411 },
  { codeId: 53, companyCode: 303439991 },
  { codeId: 53, companyCode: 303440680 },
  { codeId: 53, companyCode: 303440926 },
  { codeId: 53, companyCode: 303444440 },
  { codeId: 53, companyCode: 303446085 },
  { codeId: 63, companyCode: 303448894 },
  { codeId: 16, companyCode: 303449487 },
  { codeId: 16, companyCode: 303450411 },
  { codeId: 20, companyCode: 303451463 },
  { codeId: 61, companyCode: 303452394 },
  { codeId: 64, companyCode: 303455860 },
  { codeId: 53, companyCode: 303456930 },
  { codeId: 57, companyCode: 303459403 },
  { codeId: 45, companyCode: 303464350 },
  { codeId: 53, companyCode: 303465897 },
  { codeId: 20, companyCode: 303466967 },
  { codeId: 36, companyCode: 303469493 },
  { codeId: 53, companyCode: 303470392 },
  { codeId: 34, companyCode: 303472386 },
  { codeId: 3, companyCode: 303472913 },
  { codeId: 35, companyCode: 303473367 },
  { codeId: 16, companyCode: 303474469 },
  { codeId: 53, companyCode: 303474978 },
  { codeId: 48, companyCode: 303475489 },
  { codeId: 3, companyCode: 303478460 },
  { codeId: 57, companyCode: 303478492 },
  { codeId: 20, companyCode: 303482868 },
  { codeId: 20, companyCode: 303483856 },
  { codeId: 35, companyCode: 303486457 },
  { codeId: 53, companyCode: 303486553 },
  { codeId: 53, companyCode: 303486884 },
  { codeId: 19, companyCode: 303486902 },
  { codeId: 35, companyCode: 303491511 },
  { codeId: 9, companyCode: 303492937 },
  { codeId: 21, companyCode: 303493900 },
  { codeId: 53, companyCode: 303500864 },
  { codeId: 54, companyCode: 303502370 },
  { codeId: 16, companyCode: 303503376 },
  { codeId: 9, companyCode: 303515859 },
  { codeId: 19, companyCode: 303516352 },
  { codeId: 34, companyCode: 303522864 },
  { codeId: 53, companyCode: 303525351 },
  { codeId: 53, companyCode: 303531379 },
  { codeId: 24, companyCode: 303533483 },
  { codeId: 53, companyCode: 303533896 },
  { codeId: 20, companyCode: 303534350 },
  { codeId: 3, companyCode: 303547369 },
  { codeId: 3, companyCode: 303547390 },
  { codeId: 5, companyCode: 303547903 },
  { codeId: 34, companyCode: 303549861 },
  { codeId: 16, companyCode: 303549893 },
  { codeId: 53, companyCode: 303549904 },
  { codeId: 20, companyCode: 303551353 },
  { codeId: 34, companyCode: 303551360 },
  { codeId: 31, companyCode: 303554698 },
  { codeId: 16, companyCode: 303558974 },
  { codeId: 53, companyCode: 303564482 },
  { codeId: 64, companyCode: 303566355 },
  { codeId: 21, companyCode: 303572422 },
  { codeId: 58, companyCode: 303572511 },
  { codeId: 53, companyCode: 303572995 },
  { codeId: 16, companyCode: 303575571 },
  { codeId: 53, companyCode: 303589884 },
  { codeId: 28, companyCode: 303652854 },
  { codeId: 20, companyCode: 303673389 },
  { codeId: 19, companyCode: 303680871 },
  { codeId: 53, companyCode: 303711352 },
  { codeId: 4, companyCode: 303713353 },
  { codeId: 34, companyCode: 303718368 },
  { codeId: 9, companyCode: 303724353 },
  { codeId: 35, companyCode: 303726354 },
  { codeId: 3, companyCode: 303749367 },
  { codeId: 53, companyCode: 303763356 },
  { codeId: 53, companyCode: 303772362 },
  { codeId: 47, companyCode: 303781354 },
  { codeId: 58, companyCode: 303782367 },
  { codeId: 58, companyCode: 303792888 },
  { codeId: 53, companyCode: 303795350 },
  { codeId: 53, companyCode: 303795382 },
  { codeId: 53, companyCode: 303806359 },
  { codeId: 53, companyCode: 303831451 },
  { codeId: 53, companyCode: 303844363 },
  { codeId: 19, companyCode: 303846859 },
  { codeId: 53, companyCode: 303864864 },
  { codeId: 53, companyCode: 303867351 },
  { codeId: 53, companyCode: 303871410 },
  { codeId: 16, companyCode: 303888858 },
  { codeId: 34, companyCode: 303888954 },
  { codeId: 53, companyCode: 303902471 },
  { codeId: 53, companyCode: 303906352 },
  { codeId: 53, companyCode: 303906911 },
  { codeId: 3, companyCode: 303919855 },
  { codeId: 9, companyCode: 303923889 },
  { codeId: 35, companyCode: 303925445 },
  { codeId: 53, companyCode: 303927859 },
  { codeId: 31, companyCode: 303997366 },
  { codeId: 53, companyCode: 304001355 },
  { codeId: 53, companyCode: 304013852 },
  { codeId: 57, companyCode: 304028562 },
  { codeId: 40, companyCode: 304028644 },
  { codeId: 57, companyCode: 304028669 },
  { codeId: 40, companyCode: 304029290 },
  { codeId: 19, companyCode: 304029568 },
  { codeId: 53, companyCode: 304029618 },
  { codeId: 16, companyCode: 304030773 },
  { codeId: 34, companyCode: 304031206 },
  { codeId: 20, companyCode: 304032639 },
  { codeId: 50, companyCode: 304032895 },
  { codeId: 21, companyCode: 304033036 },
  { codeId: 53, companyCode: 304033050 },
  { codeId: 20, companyCode: 304034519 },
  { codeId: 50, companyCode: 304034704 },
  { codeId: 34, companyCode: 304034711 },
  { codeId: 34, companyCode: 304037508 },
  { codeId: 3, companyCode: 304037586 },
  { codeId: 31, companyCode: 304038108 },
  { codeId: 53, companyCode: 304038154 },
  { codeId: 16, companyCode: 304038738 },
  { codeId: 53, companyCode: 304041008 },
  { codeId: 35, companyCode: 304045088 },
  { codeId: 34, companyCode: 304045095 },
  { codeId: 24, companyCode: 304045825 },
  { codeId: 19, companyCode: 304046966 },
  { codeId: 19, companyCode: 304047018 },
  { codeId: 53, companyCode: 304048440 },
  { codeId: 53, companyCode: 304048472 },
  { codeId: 21, companyCode: 304049108 },
  { codeId: 53, companyCode: 304049179 },
  { codeId: 16, companyCode: 304049275 },
  { codeId: 16, companyCode: 304050427 },
  { codeId: 34, companyCode: 304050669 },
  { codeId: 50, companyCode: 304051550 },
  { codeId: 16, companyCode: 304051664 },
  { codeId: 53, companyCode: 304055805 },
  { codeId: 3, companyCode: 304055837 },
  { codeId: 15, companyCode: 304057628 },
  { codeId: 19, companyCode: 304058235 },
  { codeId: 11, companyCode: 304058712 },
  { codeId: 62, companyCode: 304061377 },
  { codeId: 53, companyCode: 304062034 },
  { codeId: 19, companyCode: 304062187 },
  { codeId: 53, companyCode: 304062194 },
  { codeId: 53, companyCode: 304062956 },
  { codeId: 53, companyCode: 304063848 },
  { codeId: 3, companyCode: 304063905 },
  { codeId: 53, companyCode: 304064373 },
  { codeId: 50, companyCode: 304064569 },
  { codeId: 19, companyCode: 304064964 },
  { codeId: 57, companyCode: 304064996 },
  { codeId: 19, companyCode: 304065016 },
  { codeId: 53, companyCode: 304065240 },
  { codeId: 48, companyCode: 304066310 },
  { codeId: 38, companyCode: 304067540 },
  { codeId: 53, companyCode: 304067896 },
  { codeId: 53, companyCode: 304067953 },
  { codeId: 40, companyCode: 304068329 },
  { codeId: 20, companyCode: 304068368 },
  { codeId: 61, companyCode: 304068838 },
  { codeId: 53, companyCode: 304070497 },
  { codeId: 20, companyCode: 304070508 },
  { codeId: 48, companyCode: 304070700 },
  { codeId: 19, companyCode: 304070928 },
  { codeId: 34, companyCode: 304071318 },
  { codeId: 34, companyCode: 304071663 },
  { codeId: 4, companyCode: 304071802 },
  { codeId: 16, companyCode: 304072007 },
  { codeId: 23, companyCode: 304073162 },
  { codeId: 28, companyCode: 304073867 },
  { codeId: 53, companyCode: 304073881 },
  { codeId: 9, companyCode: 304074104 },
  { codeId: 16, companyCode: 304074403 },
  { codeId: 53, companyCode: 304074606 },
  { codeId: 19, companyCode: 304075195 },
  { codeId: 26, companyCode: 304075220 },
  { codeId: 31, companyCode: 304075359 },
  { codeId: 3, companyCode: 304076799 },
  { codeId: 16, companyCode: 304077342 },
  { codeId: 53, companyCode: 304078953 },
  { codeId: 53, companyCode: 304079044 },
  { codeId: 24, companyCode: 304079539 },
  { codeId: 53, companyCode: 304079560 },
  { codeId: 49, companyCode: 304079998 },
  { codeId: 19, companyCode: 304080420 },
  { codeId: 19, companyCode: 304083021 },
  { codeId: 53, companyCode: 304088164 },
  { codeId: 16, companyCode: 304089565 },
  { codeId: 53, companyCode: 304089921 },
  { codeId: 49, companyCode: 304090069 },
  { codeId: 42, companyCode: 304090603 },
  { codeId: 19, companyCode: 304091566 },
  { codeId: 53, companyCode: 304091598 },
  { codeId: 21, companyCode: 304092351 },
  { codeId: 4, companyCode: 304092458 },
  { codeId: 3, companyCode: 304092839 },
  { codeId: 23, companyCode: 304092860 },
  { codeId: 53, companyCode: 304093873 },
  { codeId: 19, companyCode: 304094263 },
  { codeId: 40, companyCode: 304094975 },
  { codeId: 53, companyCode: 304095002 },
  { codeId: 49, companyCode: 304096086 },
  { codeId: 4, companyCode: 304096563 },
  { codeId: 9, companyCode: 304097409 },
  { codeId: 20, companyCode: 304097416 },
  { codeId: 57, companyCode: 304097747 },
  { codeId: 53, companyCode: 304098482 },
  { codeId: 19, companyCode: 304099470 },
  { codeId: 64, companyCode: 304101327 },
  { codeId: 16, companyCode: 304101882 },
  { codeId: 40, companyCode: 304102190 },
  { codeId: 53, companyCode: 304103246 },
  { codeId: 19, companyCode: 304104191 },
  { codeId: 16, companyCode: 304106687 },
  { codeId: 53, companyCode: 304106769 },
  { codeId: 57, companyCode: 304108115 },
  { codeId: 31, companyCode: 304110874 },
  { codeId: 58, companyCode: 304110924 },
  { codeId: 9, companyCode: 304111499 },
  { codeId: 53, companyCode: 304111848 },
  { codeId: 34, companyCode: 304112366 },
  { codeId: 34, companyCode: 304112868 },
  { codeId: 53, companyCode: 304113030 },
  { codeId: 16, companyCode: 304114463 },
  { codeId: 24, companyCode: 304119115 },
  { codeId: 16, companyCode: 304119414 },
  { codeId: 38, companyCode: 304119834 },
  { codeId: 57, companyCode: 304120605 },
  { codeId: 11, companyCode: 304124087 },
  { codeId: 57, companyCode: 304124354 },
  { codeId: 57, companyCode: 304124482 },
  { codeId: 16, companyCode: 304124607 },
  { codeId: 58, companyCode: 304124870 },
  { codeId: 58, companyCode: 304124888 },
  { codeId: 24, companyCode: 304125367 },
  { codeId: 53, companyCode: 304126412 },
  { codeId: 45, companyCode: 304130243 },
  { codeId: 16, companyCode: 304130880 },
  { codeId: 53, companyCode: 304137444 },
  { codeId: 64, companyCode: 304137501 },
  { codeId: 3, companyCode: 304138471 },
  { codeId: 53, companyCode: 304139235 },
  { codeId: 4, companyCode: 304140344 },
  { codeId: 23, companyCode: 304141364 },
  { codeId: 19, companyCode: 304142441 },
  { codeId: 64, companyCode: 304142847 },
  { codeId: 53, companyCode: 304143365 },
  { codeId: 34, companyCode: 304144392 },
  { codeId: 49, companyCode: 304144620 },
  { codeId: 53, companyCode: 304145010 },
  { codeId: 20, companyCode: 304147171 },
  { codeId: 53, companyCode: 304147495 },
  { codeId: 42, companyCode: 304148316 },
  { codeId: 16, companyCode: 304148533 },
  { codeId: 23, companyCode: 304148540 },
  { codeId: 42, companyCode: 304148896 },
  { codeId: 19, companyCode: 304149927 },
  { codeId: 58, companyCode: 304150908 },
  { codeId: 53, companyCode: 304151180 },
  { codeId: 40, companyCode: 304154187 },
  { codeId: 34, companyCode: 304156291 },
  { codeId: 19, companyCode: 304157009 },
  { codeId: 53, companyCode: 304157190 },
  { codeId: 53, companyCode: 304158118 },
  { codeId: 53, companyCode: 304158417 },
  { codeId: 20, companyCode: 304159024 },
  { codeId: 58, companyCode: 304159234 },
  { codeId: 53, companyCode: 304163421 },
  { codeId: 53, companyCode: 304164765 },
  { codeId: 21, companyCode: 304165372 },
  { codeId: 53, companyCode: 304166378 },
  { codeId: 3, companyCode: 304168379 },
  { codeId: 53, companyCode: 304168393 },
  { codeId: 49, companyCode: 304168596 },
  { codeId: 21, companyCode: 304169239 },
  { codeId: 21, companyCode: 304169787 },
  { codeId: 35, companyCode: 304169883 },
  { codeId: 53, companyCode: 304170437 },
  { codeId: 61, companyCode: 304170572 },
  { codeId: 53, companyCode: 304171090 },
  { codeId: 53, companyCode: 304173102 },
  { codeId: 19, companyCode: 304173312 },
  { codeId: 58, companyCode: 304173629 },
  { codeId: 3, companyCode: 304174389 },
  { codeId: 28, companyCode: 304174955 },
  { codeId: 53, companyCode: 304176358 },
  { codeId: 53, companyCode: 304177638 },
  { codeId: 24, companyCode: 304177652 },
  { codeId: 20, companyCode: 304178074 },
  { codeId: 53, companyCode: 304178569 },
  { codeId: 20, companyCode: 304178640 },
  { codeId: 37, companyCode: 304178964 },
  { codeId: 16, companyCode: 304180328 },
  { codeId: 64, companyCode: 304180538 },
  { codeId: 3, companyCode: 304181355 },
  { codeId: 53, companyCode: 304181978 },
  { codeId: 16, companyCode: 304182877 },
  { codeId: 20, companyCode: 304182891 },
  { codeId: 53, companyCode: 304182927 },
  { codeId: 24, companyCode: 304183872 },
  { codeId: 53, companyCode: 304183993 },
  { codeId: 16, companyCode: 304184052 },
  { codeId: 53, companyCode: 304185962 },
  { codeId: 53, companyCode: 304186306 },
  { codeId: 20, companyCode: 304193140 },
  { codeId: 3, companyCode: 304195458 },
  { codeId: 18, companyCode: 304196503 },
  { codeId: 64, companyCode: 304197352 },
  { codeId: 16, companyCode: 304198721 },
  { codeId: 38, companyCode: 304198778 },
  { codeId: 34, companyCode: 304207597 },
  { codeId: 53, companyCode: 304213358 },
  { codeId: 53, companyCode: 304214182 },
  { codeId: 23, companyCode: 304214524 },
  { codeId: 58, companyCode: 304215238 },
  { codeId: 16, companyCode: 304215722 },
  { codeId: 16, companyCode: 304215957 },
  { codeId: 53, companyCode: 304217709 },
  { codeId: 57, companyCode: 304218280 },
  { codeId: 53, companyCode: 304222065 },
  { codeId: 53, companyCode: 304222072 },
  { codeId: 37, companyCode: 304224707 },
  { codeId: 19, companyCode: 304224810 },
  { codeId: 64, companyCode: 304225015 },
  { codeId: 34, companyCode: 304226277 },
  { codeId: 3, companyCode: 304228050 },
  { codeId: 34, companyCode: 304228171 },
  { codeId: 19, companyCode: 304228285 },
  { codeId: 24, companyCode: 304228627 },
  { codeId: 42, companyCode: 304228666 },
  { codeId: 49, companyCode: 304230806 },
  { codeId: 53, companyCode: 304231694 },
  { codeId: 53, companyCode: 304231819 },
  { codeId: 50, companyCode: 304232878 },
  { codeId: 53, companyCode: 304233407 },
  { codeId: 53, companyCode: 304233827 },
  { codeId: 11, companyCode: 304234523 },
  { codeId: 16, companyCode: 304234740 },
  { codeId: 3, companyCode: 304239919 },
  { codeId: 53, companyCode: 304244478 },
  { codeId: 53, companyCode: 304248359 },
  { codeId: 3, companyCode: 304250972 },
  { codeId: 53, companyCode: 304251088 },
  { codeId: 34, companyCode: 304251152 },
  { codeId: 19, companyCode: 304254789 },
  { codeId: 53, companyCode: 304254942 },
  { codeId: 62, companyCode: 304255542 },
  { codeId: 19, companyCode: 304255720 },
  { codeId: 20, companyCode: 304257130 },
  { codeId: 61, companyCode: 304259708 },
  { codeId: 58, companyCode: 304260062 },
  { codeId: 53, companyCode: 304260183 },
  { codeId: 4, companyCode: 304270989 },
  { codeId: 53, companyCode: 304271112 },
  { codeId: 33, companyCode: 304271265 },
  { codeId: 33, companyCode: 304274471 },
  { codeId: 34, companyCode: 304279714 },
  { codeId: 58, companyCode: 304282240 },
  { codeId: 53, companyCode: 304283851 },
  { codeId: 20, companyCode: 304283958 },
  { codeId: 20, companyCode: 304284227 },
  { codeId: 20, companyCode: 304285044 },
  { codeId: 53, companyCode: 304285254 },
  { codeId: 19, companyCode: 304288026 },
  { codeId: 3, companyCode: 304290504 },
  { codeId: 53, companyCode: 304291360 },
  { codeId: 53, companyCode: 304291403 },
  { codeId: 19, companyCode: 304292462 },
  { codeId: 19, companyCode: 304294082 },
  { codeId: 40, companyCode: 304294374 },
  { codeId: 16, companyCode: 304294470 },
  { codeId: 49, companyCode: 304295615 },
  { codeId: 16, companyCode: 304295800 },
  { codeId: 25, companyCode: 304297986 },
  { codeId: 16, companyCode: 304298878 },
  { codeId: 53, companyCode: 304298967 },
  { codeId: 24, companyCode: 304299873 },
  { codeId: 35, companyCode: 304306855 },
  { codeId: 53, companyCode: 304310654 },
  { codeId: 28, companyCode: 304316867 },
  { codeId: 57, companyCode: 304317022 },
  { codeId: 24, companyCode: 304317093 },
  { codeId: 16, companyCode: 304324508 },
  { codeId: 53, companyCode: 304324839 },
  { codeId: 53, companyCode: 304330379 },
  { codeId: 53, companyCode: 304337352 },
  { codeId: 16, companyCode: 304353730 },
  { codeId: 15, companyCode: 304355140 },
  { codeId: 58, companyCode: 304367356 },
  { codeId: 19, companyCode: 304367915 },
  { codeId: 3, companyCode: 304368903 },
  { codeId: 53, companyCode: 304381886 },
  { codeId: 3, companyCode: 304382358 },
  { codeId: 53, companyCode: 304386463 },
  { codeId: 53, companyCode: 304392530 },
  { codeId: 53, companyCode: 304395996 },
  { codeId: 3, companyCode: 304399293 },
  { codeId: 53, companyCode: 304400913 },
  { codeId: 5, companyCode: 304401221 },
  { codeId: 53, companyCode: 304401303 },
  { codeId: 45, companyCode: 304402540 },
  { codeId: 42, companyCode: 304403966 },
  { codeId: 17, companyCode: 304405401 },
  { codeId: 34, companyCode: 304405885 },
  { codeId: 50, companyCode: 304406827 },
  { codeId: 53, companyCode: 304408543 },
  { codeId: 48, companyCode: 304408792 },
  { codeId: 20, companyCode: 304409015 },
  { codeId: 57, companyCode: 304409780 },
  { codeId: 21, companyCode: 304409823 },
  { codeId: 3, companyCode: 304410665 },
  { codeId: 61, companyCode: 304411144 },
  { codeId: 28, companyCode: 304411393 },
  { codeId: 53, companyCode: 304411557 },
  { codeId: 19, companyCode: 304412100 },
  { codeId: 53, companyCode: 304412709 },
  { codeId: 53, companyCode: 304413362 },
  { codeId: 3, companyCode: 304413387 },
  { codeId: 53, companyCode: 304413540 },
  { codeId: 59, companyCode: 304414973 },
  { codeId: 13, companyCode: 304415370 },
  { codeId: 53, companyCode: 304415776 },
  { codeId: 19, companyCode: 304416892 },
  { codeId: 53, companyCode: 304417154 },
  { codeId: 20, companyCode: 304418142 },
  { codeId: 38, companyCode: 304418555 },
  { codeId: 53, companyCode: 304419454 },
  { codeId: 53, companyCode: 304419550 },
  { codeId: 16, companyCode: 304419664 },
  { codeId: 16, companyCode: 304419803 },
  { codeId: 53, companyCode: 304420218 },
  { codeId: 16, companyCode: 304420339 },
  { codeId: 19, companyCode: 304420645 },
  { codeId: 33, companyCode: 304421462 },
  { codeId: 34, companyCode: 304421708 },
  { codeId: 20, companyCode: 304422144 },
  { codeId: 16, companyCode: 304422354 },
  { codeId: 20, companyCode: 304422920 },
  { codeId: 53, companyCode: 304423036 },
  { codeId: 34, companyCode: 304423513 },
  { codeId: 19, companyCode: 304423716 },
  { codeId: 34, companyCode: 304424017 },
  { codeId: 53, companyCode: 304424120 },
  { codeId: 42, companyCode: 304425496 },
  { codeId: 21, companyCode: 304425514 },
  { codeId: 53, companyCode: 304426826 },
  { codeId: 53, companyCode: 304427091 },
  { codeId: 34, companyCode: 304427860 },
  { codeId: 40, companyCode: 304428784 },
  { codeId: 19, companyCode: 304428948 },
  { codeId: 53, companyCode: 304429256 },
  { codeId: 16, companyCode: 304429377 },
  { codeId: 27, companyCode: 304429473 },
  { codeId: 35, companyCode: 304429651 },
  { codeId: 16, companyCode: 304429740 },
  { codeId: 34, companyCode: 304430041 },
  { codeId: 16, companyCode: 304430123 },
  { codeId: 16, companyCode: 304430625 },
  { codeId: 42, companyCode: 304431296 },
  { codeId: 19, companyCode: 304431403 },
  { codeId: 24, companyCode: 304431862 },
  { codeId: 53, companyCode: 304432028 },
  { codeId: 53, companyCode: 304432067 },
  { codeId: 34, companyCode: 304432131 },
  { codeId: 16, companyCode: 304433379 },
  { codeId: 20, companyCode: 304433450 },
  { codeId: 53, companyCode: 304433557 },
  { codeId: 21, companyCode: 304434011 },
  { codeId: 27, companyCode: 304434381 },
  { codeId: 3, companyCode: 304435729 },
  { codeId: 54, companyCode: 304436560 },
  { codeId: 53, companyCode: 304436902 },
  { codeId: 53, companyCode: 304436998 },
  { codeId: 53, companyCode: 304437242 },
  { codeId: 58, companyCode: 304437370 },
  { codeId: 37, companyCode: 304437541 },
  { codeId: 48, companyCode: 304438223 },
  { codeId: 16, companyCode: 304438668 },
  { codeId: 58, companyCode: 304439115 },
  { codeId: 53, companyCode: 304439161 },
  { codeId: 19, companyCode: 304439371 },
  { codeId: 43, companyCode: 304439446 },
  { codeId: 57, companyCode: 304439713 },
  { codeId: 40, companyCode: 304439873 },
  { codeId: 35, companyCode: 304441358 },
  { codeId: 19, companyCode: 304441543 },
  { codeId: 19, companyCode: 304442257 },
  { codeId: 52, companyCode: 304442652 },
  { codeId: 53, companyCode: 304442912 },
  { codeId: 53, companyCode: 304443163 },
  { codeId: 34, companyCode: 304443989 },
  { codeId: 3, companyCode: 304444062 },
  { codeId: 3, companyCode: 304445043 },
  { codeId: 16, companyCode: 304445577 },
  { codeId: 57, companyCode: 304445591 },
  { codeId: 53, companyCode: 304447731 },
  { codeId: 53, companyCode: 304448986 },
  { codeId: 53, companyCode: 304449294 },
  { codeId: 48, companyCode: 304449337 },
  { codeId: 52, companyCode: 304449967 },
  { codeId: 53, companyCode: 304450058 },
  { codeId: 3, companyCode: 304450108 },
  { codeId: 17, companyCode: 304450777 },
  { codeId: 53, companyCode: 304452237 },
  { codeId: 34, companyCode: 304452874 },
  { codeId: 53, companyCode: 304453182 },
  { codeId: 53, companyCode: 304453353 },
  { codeId: 3, companyCode: 304453360 },
  { codeId: 38, companyCode: 304453428 },
  { codeId: 57, companyCode: 304453702 },
  { codeId: 60, companyCode: 304456043 },
  { codeId: 53, companyCode: 304456164 },
  { codeId: 5, companyCode: 304456285 },
  { codeId: 19, companyCode: 304456940 },
  { codeId: 15, companyCode: 304457177 },
  { codeId: 53, companyCode: 304458304 },
  { codeId: 53, companyCode: 304458788 },
  { codeId: 53, companyCode: 304458959 },
  { codeId: 19, companyCode: 304459178 },
  { codeId: 26, companyCode: 304459502 },
  { codeId: 19, companyCode: 304460216 },
  { codeId: 19, companyCode: 304460248 },
  { codeId: 53, companyCode: 304460732 },
  { codeId: 19, companyCode: 304461097 },
  { codeId: 53, companyCode: 304461229 },
  { codeId: 53, companyCode: 304461364 },
  { codeId: 53, companyCode: 304461510 },
  { codeId: 64, companyCode: 304461567 },
  { codeId: 34, companyCode: 304462683 },
  { codeId: 57, companyCode: 304462765 },
  { codeId: 53, companyCode: 304462797 },
  { codeId: 19, companyCode: 304462904 },
  { codeId: 18, companyCode: 304463924 },
  { codeId: 60, companyCode: 304465380 },
  { codeId: 53, companyCode: 304465416 },
  { codeId: 20, companyCode: 304465932 },
  { codeId: 53, companyCode: 304465971 },
  { codeId: 3, companyCode: 304466233 },
  { codeId: 53, companyCode: 304466518 },
  { codeId: 3, companyCode: 304467367 },
  { codeId: 38, companyCode: 304468921 },
  { codeId: 53, companyCode: 304469382 },
  { codeId: 53, companyCode: 304470356 },
  { codeId: 53, companyCode: 304471045 },
  { codeId: 16, companyCode: 304471383 },
  { codeId: 34, companyCode: 304471611 },
  { codeId: 53, companyCode: 304472364 },
  { codeId: 53, companyCode: 304472631 },
  { codeId: 61, companyCode: 304476462 },
  { codeId: 16, companyCode: 304476989 },
  { codeId: 19, companyCode: 304479191 },
  { codeId: 53, companyCode: 304479782 },
  { codeId: 40, companyCode: 304480350 },
  { codeId: 35, companyCode: 304480546 },
  { codeId: 49, companyCode: 304483371 },
  { codeId: 20, companyCode: 304485002 },
  { codeId: 54, companyCode: 304485408 },
  { codeId: 13, companyCode: 304490992 },
  { codeId: 34, companyCode: 304491642 },
  { codeId: 53, companyCode: 304491667 },
  { codeId: 16, companyCode: 304491852 },
  { codeId: 38, companyCode: 304493255 },
  { codeId: 53, companyCode: 304493383 },
  { codeId: 53, companyCode: 304497385 },
  { codeId: 38, companyCode: 304497531 },
  { codeId: 31, companyCode: 304497702 },
  { codeId: 34, companyCode: 304497862 },
  { codeId: 53, companyCode: 304498124 },
  { codeId: 19, companyCode: 304498341 },
  { codeId: 53, companyCode: 304498398 },
  { codeId: 60, companyCode: 304498989 },
  { codeId: 3, companyCode: 304499991 },
  { codeId: 28, companyCode: 304500194 },
  { codeId: 20, companyCode: 304500479 },
  { codeId: 53, companyCode: 304500493 },
  { codeId: 53, companyCode: 304502035 },
  { codeId: 19, companyCode: 304502640 },
  { codeId: 20, companyCode: 304502875 },
  { codeId: 27, companyCode: 304504506 },
  { codeId: 57, companyCode: 304504762 },
  { codeId: 16, companyCode: 304505960 },
  { codeId: 24, companyCode: 304506069 },
  { codeId: 53, companyCode: 304506090 },
  { codeId: 19, companyCode: 304507833 },
  { codeId: 53, companyCode: 304509916 },
  { codeId: 53, companyCode: 304510085 },
  { codeId: 48, companyCode: 304510594 },
  { codeId: 53, companyCode: 304514450 },
  { codeId: 53, companyCode: 304514518 },
  { codeId: 16, companyCode: 304514557 },
  { codeId: 40, companyCode: 304516049 },
  { codeId: 36, companyCode: 304516437 },
  { codeId: 57, companyCode: 304516729 },
  { codeId: 53, companyCode: 304521861 },
  { codeId: 24, companyCode: 304521950 },
  { codeId: 19, companyCode: 304522881 },
  { codeId: 21, companyCode: 304523595 },
  { codeId: 3, companyCode: 304528382 },
  { codeId: 13, companyCode: 304530180 },
  { codeId: 16, companyCode: 304530376 },
  { codeId: 19, companyCode: 304530853 },
  { codeId: 60, companyCode: 304532402 },
  { codeId: 48, companyCode: 304532815 },
  { codeId: 16, companyCode: 304536856 },
  { codeId: 53, companyCode: 304538572 },
  { codeId: 16, companyCode: 304539037 },
  { codeId: 21, companyCode: 304539603 },
  { codeId: 28, companyCode: 304540057 },
  { codeId: 20, companyCode: 304540089 },
  { codeId: 45, companyCode: 304541159 },
  { codeId: 28, companyCode: 304541230 },
  { codeId: 40, companyCode: 304546035 },
  { codeId: 3, companyCode: 304546156 },
  { codeId: 49, companyCode: 304546245 },
  { codeId: 58, companyCode: 304548004 },
  { codeId: 11, companyCode: 304552045 },
  { codeId: 3, companyCode: 304553318 },
  { codeId: 53, companyCode: 304554199 },
  { codeId: 34, companyCode: 304555397 },
  { codeId: 53, companyCode: 304558030 },
  { codeId: 53, companyCode: 304558048 },
  { codeId: 49, companyCode: 304558105 },
  { codeId: 53, companyCode: 304558685 },
  { codeId: 53, companyCode: 304558774 },
  { codeId: 16, companyCode: 304562790 },
  { codeId: 16, companyCode: 304562840 },
  { codeId: 36, companyCode: 304564439 },
  { codeId: 53, companyCode: 304564866 },
  { codeId: 34, companyCode: 304565167 },
  { codeId: 3, companyCode: 304565199 },
  { codeId: 53, companyCode: 304566949 },
  { codeId: 40, companyCode: 304567015 },
  { codeId: 20, companyCode: 304567047 },
  { codeId: 53, companyCode: 304569404 },
  { codeId: 53, companyCode: 304570381 },
  { codeId: 16, companyCode: 304572368 },
  { codeId: 21, companyCode: 304581246 },
  { codeId: 53, companyCode: 304584242 },
  { codeId: 16, companyCode: 304584363 },
  { codeId: 11, companyCode: 304584954 },
  { codeId: 53, companyCode: 304585369 },
  { codeId: 11, companyCode: 304589378 },
  { codeId: 40, companyCode: 304589855 },
  { codeId: 53, companyCode: 304596860 },
  { codeId: 53, companyCode: 304599479 },
  { codeId: 4, companyCode: 304600173 },
  { codeId: 3, companyCode: 304602822 },
  { codeId: 3, companyCode: 304602847 },
  { codeId: 5, companyCode: 304604563 },
  { codeId: 3, companyCode: 304605850 },
  { codeId: 3, companyCode: 304605868 },
  { codeId: 44, companyCode: 304606913 },
  { codeId: 11, companyCode: 304607050 },
  { codeId: 64, companyCode: 304607132 },
  { codeId: 53, companyCode: 304607367 },
  { codeId: 3, companyCode: 304607417 },
  { codeId: 34, companyCode: 304609891 },
  { codeId: 34, companyCode: 304610139 },
  { codeId: 34, companyCode: 304611910 },
  { codeId: 11, companyCode: 304616366 },
  { codeId: 48, companyCode: 304617404 },
  { codeId: 16, companyCode: 304617532 },
  { codeId: 64, companyCode: 304619519 },
  { codeId: 28, companyCode: 304621961 },
  { codeId: 53, companyCode: 304622166 },
  { codeId: 53, companyCode: 304624473 },
  { codeId: 53, companyCode: 304624605 },
  { codeId: 26, companyCode: 304627359 },
  { codeId: 4, companyCode: 304627373 },
  { codeId: 16, companyCode: 304627996 },
  { codeId: 53, companyCode: 304628151 },
  { codeId: 53, companyCode: 304628532 },
  { codeId: 57, companyCode: 304628906 },
  { codeId: 53, companyCode: 304632961 },
  { codeId: 53, companyCode: 304632979 },
  { codeId: 28, companyCode: 304633305 },
  { codeId: 11, companyCode: 304633369 },
  { codeId: 13, companyCode: 304636454 },
  { codeId: 53, companyCode: 304637638 },
  { codeId: 4, companyCode: 304638156 },
  { codeId: 19, companyCode: 304638875 },
  { codeId: 49, companyCode: 304638918 },
  { codeId: 16, companyCode: 304639354 },
  { codeId: 38, companyCode: 304641355 },
  { codeId: 53, companyCode: 304642133 },
  { codeId: 40, companyCode: 304642165 },
  { codeId: 21, companyCode: 304642546 },
  { codeId: 52, companyCode: 304642642 },
  { codeId: 53, companyCode: 304642934 },
  { codeId: 49, companyCode: 304675432 },
  { codeId: 10, companyCode: 304675464 },
  { codeId: 31, companyCode: 304677127 },
  { codeId: 19, companyCode: 304678791 },
  { codeId: 40, companyCode: 304681378 },
  { codeId: 58, companyCode: 304685953 },
  { codeId: 48, companyCode: 304686051 },
  { codeId: 53, companyCode: 304686496 },
  { codeId: 40, companyCode: 304687865 },
  { codeId: 20, companyCode: 304690092 },
  { codeId: 53, companyCode: 304690466 },
  { codeId: 3, companyCode: 304690644 },
  { codeId: 34, companyCode: 304690854 },
  { codeId: 24, companyCode: 304693124 },
  { codeId: 19, companyCode: 304693722 },
  { codeId: 53, companyCode: 304693875 },
  { codeId: 34, companyCode: 304694354 },
  { codeId: 11, companyCode: 304694404 },
  { codeId: 53, companyCode: 304695082 },
  { codeId: 53, companyCode: 304696444 },
  { codeId: 35, companyCode: 304697464 },
  { codeId: 20, companyCode: 304699885 },
  { codeId: 48, companyCode: 304701247 },
  { codeId: 16, companyCode: 304702993 },
  { codeId: 50, companyCode: 304703369 },
  { codeId: 49, companyCode: 304704115 },
  { codeId: 53, companyCode: 304707353 },
  { codeId: 16, companyCode: 304707969 },
  { codeId: 19, companyCode: 304708156 },
  { codeId: 16, companyCode: 304708334 },
  { codeId: 53, companyCode: 304708697 },
  { codeId: 19, companyCode: 304708868 },
  { codeId: 20, companyCode: 304709564 },
  { codeId: 19, companyCode: 304709991 },
  { codeId: 53, companyCode: 304710666 },
  { codeId: 53, companyCode: 304711857 },
  { codeId: 19, companyCode: 304711914 },
  { codeId: 53, companyCode: 304712044 },
  { codeId: 53, companyCode: 304713986 },
  { codeId: 53, companyCode: 304714006 },
  { codeId: 40, companyCode: 304715492 },
  { codeId: 34, companyCode: 304715581 },
  { codeId: 16, companyCode: 304715930 },
  { codeId: 53, companyCode: 304716719 },
  { codeId: 53, companyCode: 304716822 },
  { codeId: 16, companyCode: 304716968 },
  { codeId: 60, companyCode: 304716975 },
  { codeId: 40, companyCode: 304718378 },
  { codeId: 62, companyCode: 304718410 },
  { codeId: 53, companyCode: 304718563 },
  { codeId: 53, companyCode: 304718613 },
  { codeId: 24, companyCode: 304718816 },
  { codeId: 28, companyCode: 304719380 },
  { codeId: 19, companyCode: 304719836 },
  { codeId: 53, companyCode: 304719850 },
  { codeId: 19, companyCode: 304720030 },
  { codeId: 53, companyCode: 304721214 },
  { codeId: 49, companyCode: 304721310 },
  { codeId: 16, companyCode: 304722960 },
  { codeId: 16, companyCode: 304723215 },
  { codeId: 21, companyCode: 304724000 },
  { codeId: 3, companyCode: 304724160 },
  { codeId: 16, companyCode: 304725006 },
  { codeId: 16, companyCode: 304725611 },
  { codeId: 16, companyCode: 304725853 },
  { codeId: 16, companyCode: 304725892 },
  { codeId: 34, companyCode: 304727587 },
  { codeId: 16, companyCode: 304727676 },
  { codeId: 53, companyCode: 304729862 },
  { codeId: 19, companyCode: 304729944 },
  { codeId: 53, companyCode: 304732381 },
  { codeId: 19, companyCode: 304732577 },
  { codeId: 3, companyCode: 304733907 },
  { codeId: 16, companyCode: 304733953 },
  { codeId: 49, companyCode: 304737033 },
  { codeId: 53, companyCode: 304737898 },
  { codeId: 16, companyCode: 304738128 },
  { codeId: 16, companyCode: 304738320 },
  { codeId: 19, companyCode: 304739931 },
  { codeId: 34, companyCode: 304740645 },
  { codeId: 54, companyCode: 304740983 },
  { codeId: 20, companyCode: 304741416 },
  { codeId: 3, companyCode: 304741957 },
  { codeId: 20, companyCode: 304742094 },
  { codeId: 53, companyCode: 304742870 },
  { codeId: 53, companyCode: 304743926 },
  { codeId: 11, companyCode: 304744227 },
  { codeId: 53, companyCode: 304745005 },
  { codeId: 34, companyCode: 304745293 },
  { codeId: 48, companyCode: 304745610 },
  { codeId: 34, companyCode: 304745927 },
  { codeId: 4, companyCode: 304747223 },
  { codeId: 53, companyCode: 304748898 },
  { codeId: 26, companyCode: 304750358 },
  { codeId: 3, companyCode: 304750753 },
  { codeId: 19, companyCode: 304751054 },
  { codeId: 49, companyCode: 304752900 },
  { codeId: 53, companyCode: 304753920 },
  { codeId: 20, companyCode: 304756343 },
  { codeId: 53, companyCode: 304757178 },
  { codeId: 50, companyCode: 304757267 },
  { codeId: 6, companyCode: 304757331 },
  { codeId: 19, companyCode: 304757388 },
  { codeId: 16, companyCode: 304758903 },
  { codeId: 19, companyCode: 304759909 },
  { codeId: 38, companyCode: 304760142 },
  { codeId: 53, companyCode: 304760612 },
  { codeId: 19, companyCode: 304765926 },
  { codeId: 53, companyCode: 304765972 },
  { codeId: 53, companyCode: 304766558 },
  { codeId: 16, companyCode: 304767044 },
  { codeId: 53, companyCode: 304767165 },
  { codeId: 28, companyCode: 304767934 },
  { codeId: 53, companyCode: 304771644 },
  { codeId: 57, companyCode: 304771726 },
  { codeId: 32, companyCode: 304772447 },
  { codeId: 24, companyCode: 304772511 },
  { codeId: 53, companyCode: 304773403 },
  { codeId: 20, companyCode: 304773969 },
  { codeId: 17, companyCode: 304774373 },
  { codeId: 40, companyCode: 304774487 },
  { codeId: 34, companyCode: 304774569 },
  { codeId: 60, companyCode: 304777106 },
  { codeId: 60, companyCode: 304777113 },
  { codeId: 31, companyCode: 304777939 },
  { codeId: 34, companyCode: 304778051 },
  { codeId: 16, companyCode: 304779566 },
  { codeId: 28, companyCode: 304779573 },
  { codeId: 16, companyCode: 304782046 },
  { codeId: 53, companyCode: 304783949 },
  { codeId: 53, companyCode: 304784218 },
  { codeId: 24, companyCode: 304785010 },
  { codeId: 9, companyCode: 304786429 },
  { codeId: 34, companyCode: 304787933 },
  { codeId: 53, companyCode: 304788152 },
  { codeId: 53, companyCode: 304793466 },
  { codeId: 20, companyCode: 304793562 },
  { codeId: 40, companyCode: 304795360 },
  { codeId: 53, companyCode: 304800366 },
  { codeId: 53, companyCode: 304802456 },
  { codeId: 21, companyCode: 304804044 },
  { codeId: 15, companyCode: 304805363 },
  { codeId: 49, companyCode: 304808911 },
  { codeId: 53, companyCode: 304811416 },
  { codeId: 57, companyCode: 304813374 },
  { codeId: 53, companyCode: 304814857 },
  { codeId: 61, companyCode: 304817401 },
  { codeId: 16, companyCode: 304819384 },
  { codeId: 9, companyCode: 304819904 },
  { codeId: 53, companyCode: 304820041 },
  { codeId: 40, companyCode: 304820874 },
  { codeId: 11, companyCode: 304821894 },
  { codeId: 50, companyCode: 304823105 },
  { codeId: 21, companyCode: 304823226 },
  { codeId: 20, companyCode: 304824342 },
  { codeId: 53, companyCode: 304824755 },
  { codeId: 21, companyCode: 304826489 },
  { codeId: 35, companyCode: 304827160 },
  { codeId: 53, companyCode: 304828052 },
  { codeId: 57, companyCode: 304829624 },
  { codeId: 53, companyCode: 304829909 },
  { codeId: 16, companyCode: 304830256 },
  { codeId: 19, companyCode: 304831874 },
  { codeId: 19, companyCode: 304832976 },
  { codeId: 3, companyCode: 304833804 },
  { codeId: 34, companyCode: 304835933 },
  { codeId: 53, companyCode: 304835958 },
  { codeId: 40, companyCode: 304837877 },
  { codeId: 53, companyCode: 304839718 },
  { codeId: 53, companyCode: 304840040 },
  { codeId: 35, companyCode: 304840058 },
  { codeId: 47, companyCode: 304841968 },
  { codeId: 60, companyCode: 304842543 },
  { codeId: 27, companyCode: 304842625 },
  { codeId: 16, companyCode: 304843620 },
  { codeId: 20, companyCode: 304843684 },
  { codeId: 53, companyCode: 304844544 },
  { codeId: 53, companyCode: 304844658 },
  { codeId: 53, companyCode: 304845710 },
  { codeId: 60, companyCode: 304845938 },
  { codeId: 24, companyCode: 304848375 },
  { codeId: 53, companyCode: 304848966 },
  { codeId: 3, companyCode: 304850006 },
  { codeId: 5, companyCode: 304850700 },
  { codeId: 53, companyCode: 304851414 },
  { codeId: 35, companyCode: 304851460 },
  { codeId: 42, companyCode: 304852014 },
  { codeId: 53, companyCode: 304852886 },
  { codeId: 16, companyCode: 304853415 },
  { codeId: 60, companyCode: 304853931 },
  { codeId: 3, companyCode: 304853963 },
  { codeId: 60, companyCode: 304854684 },
  { codeId: 3, companyCode: 304856799 },
  { codeId: 16, companyCode: 304858234 },
  { codeId: 53, companyCode: 304858412 },
  { codeId: 53, companyCode: 304858597 },
  { codeId: 53, companyCode: 304862649 },
  { codeId: 53, companyCode: 304862752 },
  { codeId: 19, companyCode: 304863345 },
  { codeId: 58, companyCode: 304865054 },
  { codeId: 53, companyCode: 304865346 },
  { codeId: 32, companyCode: 304866010 },
  { codeId: 53, companyCode: 304866829 },
  { codeId: 11, companyCode: 304868221 },
  { codeId: 64, companyCode: 304869490 },
  { codeId: 5, companyCode: 304869501 },
  { codeId: 53, companyCode: 304869540 },
  { codeId: 53, companyCode: 304869978 },
  { codeId: 53, companyCode: 304869992 },
  { codeId: 53, companyCode: 304871363 },
  { codeId: 40, companyCode: 304877971 },
  { codeId: 60, companyCode: 304878087 },
  { codeId: 34, companyCode: 304878176 },
  { codeId: 16, companyCode: 304878265 },
  { codeId: 3, companyCode: 304879100 },
  { codeId: 11, companyCode: 304879164 },
  { codeId: 53, companyCode: 304879171 },
  { codeId: 21, companyCode: 304879196 },
  { codeId: 40, companyCode: 304882559 },
  { codeId: 20, companyCode: 304882598 },
  { codeId: 3, companyCode: 304883369 },
  { codeId: 53, companyCode: 304885498 },
  { codeId: 53, companyCode: 304885612 },
  { codeId: 20, companyCode: 304885772 },
  { codeId: 53, companyCode: 304887143 },
  { codeId: 34, companyCode: 304887620 },
  { codeId: 16, companyCode: 304887809 },
  { codeId: 28, companyCode: 304888462 },
  { codeId: 19, companyCode: 304888544 },
  { codeId: 34, companyCode: 304888882 },
  { codeId: 16, companyCode: 304893370 },
  { codeId: 34, companyCode: 304894472 },
  { codeId: 40, companyCode: 304894757 },
  { codeId: 9, companyCode: 304894878 },
  { codeId: 16, companyCode: 304895414 },
  { codeId: 34, companyCode: 304895880 },
  { codeId: 19, companyCode: 304896701 },
  { codeId: 24, companyCode: 304896719 },
  { codeId: 16, companyCode: 304896904 },
  { codeId: 11, companyCode: 304902403 },
  { codeId: 53, companyCode: 304903252 },
  { codeId: 50, companyCode: 304903380 },
  { codeId: 57, companyCode: 304909732 },
  { codeId: 61, companyCode: 304910122 },
  { codeId: 53, companyCode: 304910186 },
  { codeId: 31, companyCode: 304910229 },
  { codeId: 9, companyCode: 304910453 },
  { codeId: 53, companyCode: 304910706 },
  { codeId: 58, companyCode: 304912358 },
  { codeId: 16, companyCode: 304912582 },
  { codeId: 16, companyCode: 304912803 },
  { codeId: 53, companyCode: 304914003 },
  { codeId: 53, companyCode: 304914327 },
  { codeId: 57, companyCode: 304914380 },
  { codeId: 57, companyCode: 304914715 },
  { codeId: 47, companyCode: 304916068 },
  { codeId: 60, companyCode: 304916890 },
  { codeId: 16, companyCode: 304917444 },
  { codeId: 53, companyCode: 304919096 },
  { codeId: 49, companyCode: 304919274 },
  { codeId: 20, companyCode: 304919370 },
  { codeId: 60, companyCode: 304919705 },
  { codeId: 19, companyCode: 304919783 },
  { codeId: 19, companyCode: 304920230 },
  { codeId: 53, companyCode: 304920312 },
  { codeId: 52, companyCode: 304920942 },
  { codeId: 53, companyCode: 304922092 },
  { codeId: 53, companyCode: 304922587 },
  { codeId: 53, companyCode: 304922886 },
  { codeId: 36, companyCode: 304923041 },
  { codeId: 64, companyCode: 304923212 },
  { codeId: 58, companyCode: 304923365 },
  { codeId: 34, companyCode: 304923931 },
  { codeId: 53, companyCode: 304924022 },
  { codeId: 4, companyCode: 304924474 },
  { codeId: 38, companyCode: 304927972 },
  { codeId: 53, companyCode: 304928049 },
  { codeId: 53, companyCode: 304928387 },
  { codeId: 60, companyCode: 304928451 },
  { codeId: 53, companyCode: 304928469 },
  { codeId: 53, companyCode: 304928978 },
  { codeId: 19, companyCode: 304929464 },
  { codeId: 24, companyCode: 304930438 },
  { codeId: 53, companyCode: 304930655 },
  { codeId: 53, companyCode: 304931013 },
  { codeId: 48, companyCode: 304933384 },
  { codeId: 34, companyCode: 304933904 },
  { codeId: 40, companyCode: 304934041 },
  { codeId: 34, companyCode: 304934422 },
  { codeId: 53, companyCode: 304934689 },
  { codeId: 40, companyCode: 304935307 },
  { codeId: 49, companyCode: 304935894 },
  { codeId: 14, companyCode: 304936117 },
  { codeId: 53, companyCode: 304940471 },
  { codeId: 53, companyCode: 304942351 },
  { codeId: 3, companyCode: 304942629 },
  { codeId: 45, companyCode: 304942636 },
  { codeId: 53, companyCode: 304943446 },
  { codeId: 3, companyCode: 304944434 },
  { codeId: 49, companyCode: 304944968 },
  { codeId: 16, companyCode: 304945041 },
  { codeId: 53, companyCode: 304945109 },
  { codeId: 19, companyCode: 304945212 },
  { codeId: 53, companyCode: 304945568 },
  { codeId: 58, companyCode: 304946524 },
  { codeId: 20, companyCode: 304946734 },
  { codeId: 49, companyCode: 304946780 },
  { codeId: 53, companyCode: 304947590 },
  { codeId: 50, companyCode: 304948589 },
  { codeId: 5, companyCode: 304948703 },
  { codeId: 49, companyCode: 304948906 },
  { codeId: 53, companyCode: 304949068 },
  { codeId: 3, companyCode: 304949207 },
  { codeId: 48, companyCode: 304951941 },
  { codeId: 16, companyCode: 304952139 },
  { codeId: 48, companyCode: 304952363 },
  { codeId: 20, companyCode: 304952445 },
  { codeId: 16, companyCode: 304954275 },
  { codeId: 19, companyCode: 304954649 },
  { codeId: 16, companyCode: 304954663 },
  { codeId: 58, companyCode: 304954738 },
  { codeId: 5, companyCode: 304954916 },
  { codeId: 16, companyCode: 304954955 },
  { codeId: 16, companyCode: 304955929 },
  { codeId: 16, companyCode: 304956536 },
  { codeId: 20, companyCode: 304956696 },
  { codeId: 53, companyCode: 304956995 },
  { codeId: 20, companyCode: 304957677 },
  { codeId: 3, companyCode: 304957809 },
  { codeId: 53, companyCode: 304957951 },
  { codeId: 16, companyCode: 304958117 },
  { codeId: 52, companyCode: 304958238 },
  { codeId: 16, companyCode: 304958277 },
  { codeId: 20, companyCode: 304958494 },
  { codeId: 38, companyCode: 304958537 },
  { codeId: 34, companyCode: 304958708 },
  { codeId: 53, companyCode: 304959016 },
  { codeId: 21, companyCode: 304959500 },
  { codeId: 21, companyCode: 304959525 },
  { codeId: 53, companyCode: 304960125 },
  { codeId: 53, companyCode: 304960399 },
  { codeId: 53, companyCode: 304960520 },
  { codeId: 19, companyCode: 304960659 },
  { codeId: 16, companyCode: 304960958 },
  { codeId: 53, companyCode: 304961540 },
  { codeId: 11, companyCode: 304961711 },
  { codeId: 57, companyCode: 304963388 },
  { codeId: 50, companyCode: 304964508 },
  { codeId: 3, companyCode: 304966587 },
  { codeId: 16, companyCode: 304967415 },
  { codeId: 20, companyCode: 304971552 },
  { codeId: 61, companyCode: 304972323 },
  { codeId: 36, companyCode: 304972693 },
  { codeId: 60, companyCode: 304973539 },
  { codeId: 64, companyCode: 304973553 },
  { codeId: 53, companyCode: 304973770 },
  { codeId: 21, companyCode: 304974025 },
  { codeId: 20, companyCode: 304974356 },
  { codeId: 5, companyCode: 304975465 },
  { codeId: 42, companyCode: 304975675 },
  { codeId: 53, companyCode: 304975885 },
  { codeId: 3, companyCode: 304975974 },
  { codeId: 53, companyCode: 304978027 },
  { codeId: 43, companyCode: 304978041 },
  { codeId: 53, companyCode: 304982958 },
  { codeId: 53, companyCode: 304982965 },
  { codeId: 24, companyCode: 304983063 },
  { codeId: 53, companyCode: 304984884 },
  { codeId: 53, companyCode: 304985961 },
  { codeId: 38, companyCode: 304986579 },
  { codeId: 11, companyCode: 304986682 },
  { codeId: 50, companyCode: 304987898 },
  { codeId: 19, companyCode: 304987948 },
  { codeId: 19, companyCode: 304989657 },
  { codeId: 17, companyCode: 304990022 },
  { codeId: 53, companyCode: 304991131 },
  { codeId: 53, companyCode: 304992938 },
  { codeId: 57, companyCode: 304992945 },
  { codeId: 26, companyCode: 304993374 },
  { codeId: 58, companyCode: 304994394 },
  { codeId: 17, companyCode: 304994907 },
  { codeId: 34, companyCode: 304995471 },
  { codeId: 62, companyCode: 304995578 },
  { codeId: 35, companyCode: 304996178 },
  { codeId: 24, companyCode: 304998880 },
  { codeId: 53, companyCode: 305002894 },
  { codeId: 34, companyCode: 305003672 },
  { codeId: 52, companyCode: 305003779 },
  { codeId: 53, companyCode: 305005919 },
  { codeId: 34, companyCode: 305005972 },
  { codeId: 16, companyCode: 305006088 },
  { codeId: 53, companyCode: 305008662 },
  { codeId: 53, companyCode: 305010599 },
  { codeId: 57, companyCode: 305011975 },
  { codeId: 53, companyCode: 305012333 },
  { codeId: 20, companyCode: 305016125 },
  { codeId: 57, companyCode: 305016196 },
  { codeId: 40, companyCode: 305016972 },
  { codeId: 53, companyCode: 305018044 },
  { codeId: 34, companyCode: 305020052 },
  { codeId: 53, companyCode: 305022459 },
  { codeId: 34, companyCode: 305024517 },
  { codeId: 53, companyCode: 305025505 },
  { codeId: 34, companyCode: 305025786 },
  { codeId: 28, companyCode: 305026660 },
  { codeId: 53, companyCode: 305034034 },
  { codeId: 53, companyCode: 305035969 },
  { codeId: 16, companyCode: 305041477 },
  { codeId: 16, companyCode: 305044384 },
  { codeId: 34, companyCode: 305044402 },
  { codeId: 9, companyCode: 305047416 },
  { codeId: 16, companyCode: 305047626 },
  { codeId: 53, companyCode: 305047932 },
  { codeId: 50, companyCode: 305051400 },
  { codeId: 53, companyCode: 305052114 },
  { codeId: 53, companyCode: 305053433 },
  { codeId: 50, companyCode: 305053839 },
  { codeId: 34, companyCode: 305053935 },
  { codeId: 50, companyCode: 305060470 },
  { codeId: 53, companyCode: 305063559 },
  { codeId: 34, companyCode: 305063566 },
  { codeId: 53, companyCode: 305071972 },
  { codeId: 21, companyCode: 305072412 },
  { codeId: 34, companyCode: 305072946 },
  { codeId: 16, companyCode: 305073158 },
  { codeId: 20, companyCode: 305073471 },
  { codeId: 53, companyCode: 305078963 },
  { codeId: 47, companyCode: 305083921 },
  { codeId: 53, companyCode: 305083992 },
  { codeId: 21, companyCode: 305088530 },
  { codeId: 3, companyCode: 305089899 },
  { codeId: 3, companyCode: 305093381 },
  { codeId: 34, companyCode: 305094113 },
  { codeId: 19, companyCode: 305094615 },
  { codeId: 34, companyCode: 305098154 },
  { codeId: 57, companyCode: 305098460 },
  { codeId: 3, companyCode: 305098880 },
  { codeId: 11, companyCode: 305099473 },
  { codeId: 31, companyCode: 305102439 },
  { codeId: 53, companyCode: 305102663 },
  { codeId: 53, companyCode: 305115408 },
  { codeId: 58, companyCode: 305118030 },
  { codeId: 53, companyCode: 305135250 },
  { codeId: 17, companyCode: 305137568 },
  { codeId: 16, companyCode: 305142921 },
  { codeId: 53, companyCode: 305143927 },
  { codeId: 11, companyCode: 305144534 },
  { codeId: 53, companyCode: 305144541 },
  { codeId: 53, companyCode: 305146001 },
  { codeId: 53, companyCode: 305146389 },
  { codeId: 53, companyCode: 305146492 },
  { codeId: 58, companyCode: 305151831 },
  { codeId: 53, companyCode: 305151856 },
  { codeId: 58, companyCode: 305152082 },
  { codeId: 3, companyCode: 305152890 },
  { codeId: 34, companyCode: 305154069 },
  { codeId: 60, companyCode: 305154140 },
  { codeId: 19, companyCode: 305155000 },
  { codeId: 19, companyCode: 305156682 },
  { codeId: 58, companyCode: 305156725 },
  { codeId: 53, companyCode: 305159422 },
  { codeId: 20, companyCode: 305160022 },
  { codeId: 53, companyCode: 305160570 },
  { codeId: 53, companyCode: 305160734 },
  { codeId: 43, companyCode: 305163082 },
  { codeId: 53, companyCode: 305163844 },
  { codeId: 60, companyCode: 305167141 },
  { codeId: 57, companyCode: 305167419 },
  { codeId: 40, companyCode: 305173596 },
  { codeId: 53, companyCode: 305176076 },
  { codeId: 34, companyCode: 305176496 },
  { codeId: 16, companyCode: 305177993 },
  { codeId: 21, companyCode: 305178006 },
  { codeId: 21, companyCode: 305180391 },
  { codeId: 53, companyCode: 305180893 },
  { codeId: 11, companyCode: 305183099 },
  { codeId: 53, companyCode: 305184386 },
  { codeId: 20, companyCode: 305184977 },
  { codeId: 16, companyCode: 305187012 },
  { codeId: 20, companyCode: 305189554 },
  { codeId: 53, companyCode: 305189636 },
  { codeId: 40, companyCode: 305191384 },
  { codeId: 60, companyCode: 305192461 },
  { codeId: 53, companyCode: 305193022 },
  { codeId: 38, companyCode: 305198400 },
  { codeId: 53, companyCode: 305201921 },
  { codeId: 53, companyCode: 305202311 },
  { codeId: 60, companyCode: 305205001 },
  { codeId: 53, companyCode: 305207080 },
  { codeId: 53, companyCode: 305207269 },
  { codeId: 34, companyCode: 305208385 },
  { codeId: 58, companyCode: 305208442 },
  { codeId: 57, companyCode: 305208588 },
  { codeId: 11, companyCode: 305208620 },
  { codeId: 53, companyCode: 305208773 },
  { codeId: 59, companyCode: 305208848 },
  { codeId: 59, companyCode: 305209829 },
  { codeId: 4, companyCode: 305210240 },
  { codeId: 59, companyCode: 305210258 },
  { codeId: 53, companyCode: 305210468 },
  { codeId: 11, companyCode: 305211627 },
  { codeId: 42, companyCode: 305212907 },
  { codeId: 20, companyCode: 305217199 },
  { codeId: 53, companyCode: 305217434 },
  { codeId: 53, companyCode: 305217644 },
  { codeId: 40, companyCode: 305217651 },
  { codeId: 20, companyCode: 305217669 },
  { codeId: 20, companyCode: 305218372 },
  { codeId: 53, companyCode: 305219524 },
  { codeId: 53, companyCode: 305219645 },
  { codeId: 15, companyCode: 305221621 },
  { codeId: 9, companyCode: 305222463 },
  { codeId: 53, companyCode: 305222958 },
  { codeId: 34, companyCode: 305224717 },
  { codeId: 20, companyCode: 305224934 },
  { codeId: 40, companyCode: 305225025 },
  { codeId: 23, companyCode: 305226604 },
  { codeId: 16, companyCode: 305228391 },
  { codeId: 58, companyCode: 305228441 },
  { codeId: 20, companyCode: 305228498 },
  { codeId: 13, companyCode: 305228765 },
  { codeId: 34, companyCode: 305229753 },
  { codeId: 38, companyCode: 305229917 },
  { codeId: 53, companyCode: 305230093 },
  { codeId: 16, companyCode: 305231761 },
  { codeId: 24, companyCode: 305231875 },
  { codeId: 53, companyCode: 305231957 },
  { codeId: 60, companyCode: 305232151 },
  { codeId: 47, companyCode: 305232233 },
  { codeId: 53, companyCode: 305236609 },
  { codeId: 57, companyCode: 305237038 },
  { codeId: 35, companyCode: 305237967 },
  { codeId: 53, companyCode: 305238880 },
  { codeId: 24, companyCode: 305240504 },
  { codeId: 16, companyCode: 305241008 },
  { codeId: 53, companyCode: 305241257 },
  { codeId: 53, companyCode: 305244470 },
  { codeId: 53, companyCode: 305244716 },
  { codeId: 34, companyCode: 305245152 },
  { codeId: 53, companyCode: 305245907 },
  { codeId: 60, companyCode: 305245946 },
  { codeId: 57, companyCode: 305246806 },
  { codeId: 53, companyCode: 305248390 },
  { codeId: 48, companyCode: 305252296 },
  { codeId: 50, companyCode: 305253761 },
  { codeId: 11, companyCode: 305253900 },
  { codeId: 48, companyCode: 305254144 },
  { codeId: 53, companyCode: 305256565 },
  { codeId: 53, companyCode: 305256704 },
  { codeId: 5, companyCode: 305260649 },
  { codeId: 26, companyCode: 305260720 },
  { codeId: 9, companyCode: 305264640 },
  { codeId: 16, companyCode: 305266417 },
  { codeId: 57, companyCode: 305268820 },
  { codeId: 4, companyCode: 305271460 },
  { codeId: 53, companyCode: 305273632 },
  { codeId: 57, companyCode: 305276048 },
  { codeId: 20, companyCode: 305276233 },
  { codeId: 3, companyCode: 305277381 },
  { codeId: 57, companyCode: 305277545 },
  { codeId: 53, companyCode: 305278437 },
  { codeId: 53, companyCode: 305280534 },
  { codeId: 20, companyCode: 305280897 },
  { codeId: 53, companyCode: 305281102 },
  { codeId: 53, companyCode: 305282439 },
  { codeId: 19, companyCode: 305283904 },
  { codeId: 53, companyCode: 305287169 },
  { codeId: 16, companyCode: 305288381 },
  { codeId: 19, companyCode: 305288958 },
  { codeId: 53, companyCode: 305289031 },
  { codeId: 53, companyCode: 305289405 },
  { codeId: 58, companyCode: 305289501 },
  { codeId: 3, companyCode: 305290197 },
  { codeId: 53, companyCode: 305290941 },
  { codeId: 9, companyCode: 305293492 },
  { codeId: 52, companyCode: 305293955 },
  { codeId: 30, companyCode: 305294943 },
  { codeId: 26, companyCode: 305295002 },
  { codeId: 19, companyCode: 305297971 },
  { codeId: 34, companyCode: 305304394 },
  { codeId: 34, companyCode: 305307515 },
  { codeId: 53, companyCode: 305328897 },
  { codeId: 53, companyCode: 305336972 },
  { codeId: 57, companyCode: 305337316 },
  { codeId: 53, companyCode: 305340390 },
  { codeId: 19, companyCode: 305343408 },
  { codeId: 53, companyCode: 305343454 },
  { codeId: 53, companyCode: 305344684 },
  { codeId: 53, companyCode: 305344969 },
  { codeId: 53, companyCode: 305345380 },
  { codeId: 53, companyCode: 305347883 },
  { codeId: 16, companyCode: 305350267 },
  { codeId: 53, companyCode: 305356398 },
  { codeId: 57, companyCode: 305357404 },
  { codeId: 4, companyCode: 305361281 },
  { codeId: 16, companyCode: 305361331 },
  { codeId: 20, companyCode: 305362892 },
  { codeId: 16, companyCode: 305363389 },
  { codeId: 16, companyCode: 305363898 },
  { codeId: 53, companyCode: 305366086 },
  { codeId: 19, companyCode: 305368895 },
  { codeId: 58, companyCode: 305370889 },
  { codeId: 53, companyCode: 305376415 },
  { codeId: 53, companyCode: 305376632 },
  { codeId: 53, companyCode: 305387397 },
  { codeId: 16, companyCode: 305390443 },
  { codeId: 53, companyCode: 305390614 },
  { codeId: 53, companyCode: 305390728 },
  { codeId: 53, companyCode: 305393037 },
  { codeId: 53, companyCode: 305393158 },
  { codeId: 53, companyCode: 305393560 },
  { codeId: 19, companyCode: 305393603 },
  { codeId: 53, companyCode: 305393884 },
  { codeId: 46, companyCode: 305394032 },
  { codeId: 34, companyCode: 305396898 },
  { codeId: 16, companyCode: 305398881 },
  { codeId: 24, companyCode: 305399168 },
  { codeId: 26, companyCode: 305399517 },
  { codeId: 53, companyCode: 305399524 },
  { codeId: 53, companyCode: 305399606 },
  { codeId: 17, companyCode: 305400001 },
  { codeId: 3, companyCode: 305400026 },
  { codeId: 16, companyCode: 305400072 },
  { codeId: 40, companyCode: 305400930 },
  { codeId: 57, companyCode: 305400948 },
  { codeId: 40, companyCode: 305400994 },
  { codeId: 48, companyCode: 305401886 },
  { codeId: 53, companyCode: 305402415 },
  { codeId: 21, companyCode: 305402924 },
  { codeId: 14, companyCode: 305403894 },
  { codeId: 38, companyCode: 305404932 },
  { codeId: 53, companyCode: 305405920 },
  { codeId: 53, companyCode: 305406495 },
  { codeId: 45, companyCode: 305406698 },
  { codeId: 53, companyCode: 305408471 },
  { codeId: 16, companyCode: 305408891 },
  { codeId: 16, companyCode: 305408966 },
  { codeId: 53, companyCode: 305413899 },
  { codeId: 53, companyCode: 305416169 },
  { codeId: 53, companyCode: 305416226 },
  { codeId: 48, companyCode: 305419578 },
  { codeId: 58, companyCode: 305420705 },
  { codeId: 35, companyCode: 305420954 },
  { codeId: 16, companyCode: 305421216 },
  { codeId: 53, companyCode: 305423701 },
  { codeId: 60, companyCode: 305425054 },
  { codeId: 53, companyCode: 305425360 },
  { codeId: 53, companyCode: 305427009 },
  { codeId: 53, companyCode: 305427112 },
  { codeId: 49, companyCode: 305427429 },
  { codeId: 19, companyCode: 305428545 },
  { codeId: 35, companyCode: 305428762 },
  { codeId: 57, companyCode: 305429857 },
  { codeId: 36, companyCode: 305431897 },
  { codeId: 16, companyCode: 305432052 },
  { codeId: 40, companyCode: 305434516 },
  { codeId: 40, companyCode: 305434854 },
  { codeId: 31, companyCode: 305435027 },
  { codeId: 20, companyCode: 305437003 },
  { codeId: 53, companyCode: 305438087 },
  { codeId: 53, companyCode: 305438169 },
  { codeId: 35, companyCode: 305438201 },
  { codeId: 53, companyCode: 305438429 },
  { codeId: 53, companyCode: 305439851 },
  { codeId: 19, companyCode: 305439876 },
  { codeId: 45, companyCode: 305440387 },
  { codeId: 40, companyCode: 305440896 },
  { codeId: 57, companyCode: 305441788 },
  { codeId: 53, companyCode: 305442477 },
  { codeId: 53, companyCode: 305442840 },
  { codeId: 19, companyCode: 305442897 },
  { codeId: 16, companyCode: 305444300 },
  { codeId: 53, companyCode: 305445808 },
  { codeId: 3, companyCode: 305450417 },
  { codeId: 53, companyCode: 305453025 },
  { codeId: 53, companyCode: 305453228 },
  { codeId: 40, companyCode: 305454134 },
  { codeId: 53, companyCode: 305456968 },
  { codeId: 57, companyCode: 305457358 },
  { codeId: 19, companyCode: 305460183 },
  { codeId: 21, companyCode: 305460190 },
  { codeId: 31, companyCode: 305460265 },
  { codeId: 16, companyCode: 305461488 },
  { codeId: 53, companyCode: 305462387 },
  { codeId: 49, companyCode: 305462444 },
  { codeId: 53, companyCode: 305464737 },
  { codeId: 53, companyCode: 305464826 },
  { codeId: 53, companyCode: 305465465 },
  { codeId: 34, companyCode: 305467822 },
  { codeId: 3, companyCode: 305468504 },
  { codeId: 47, companyCode: 305470359 },
  { codeId: 3, companyCode: 305484227 },
  { codeId: 34, companyCode: 305485382 },
  { codeId: 19, companyCode: 305487668 },
  { codeId: 53, companyCode: 305492932 },
  { codeId: 53, companyCode: 305492964 },
  { codeId: 53, companyCode: 305493710 },
  { codeId: 53, companyCode: 305496432 },
  { codeId: 3, companyCode: 305504427 },
  { codeId: 24, companyCode: 305506079 },
  { codeId: 53, companyCode: 305506887 },
  { codeId: 16, companyCode: 305508404 },
  { codeId: 59, companyCode: 305508525 },
  { codeId: 57, companyCode: 305510412 },
  { codeId: 48, companyCode: 305514414 },
  { codeId: 50, companyCode: 305515384 },
  { codeId: 19, companyCode: 305515427 },
  { codeId: 6, companyCode: 305518398 },
  { codeId: 53, companyCode: 305520424 },
  { codeId: 24, companyCode: 305522471 },
  { codeId: 48, companyCode: 305524604 },
  { codeId: 53, companyCode: 305529957 },
  { codeId: 16, companyCode: 305531381 },
  { codeId: 53, companyCode: 305534388 },
  { codeId: 53, companyCode: 305534609 },
  { codeId: 10, companyCode: 305537167 },
  { codeId: 57, companyCode: 305538408 },
  { codeId: 61, companyCode: 305540373 },
  { codeId: 24, companyCode: 305541500 },
  { codeId: 40, companyCode: 305543704 },
  { codeId: 60, companyCode: 305545712 },
  { codeId: 21, companyCode: 305546013 },
  { codeId: 11, companyCode: 305546440 },
  { codeId: 25, companyCode: 305548466 },
  { codeId: 19, companyCode: 305548498 },
  { codeId: 40, companyCode: 305548726 },
  { codeId: 20, companyCode: 305548733 },
  { codeId: 53, companyCode: 305549461 },
  { codeId: 20, companyCode: 305550079 },
  { codeId: 34, companyCode: 305552450 },
  { codeId: 3, companyCode: 305552475 },
  { codeId: 16, companyCode: 305553125 },
  { codeId: 16, companyCode: 305553310 },
  { codeId: 53, companyCode: 305553680 },
  { codeId: 3, companyCode: 305553755 },
  { codeId: 34, companyCode: 305553787 },
  { codeId: 53, companyCode: 305555172 },
  { codeId: 53, companyCode: 305555980 },
  { codeId: 11, companyCode: 305556025 },
  { codeId: 53, companyCode: 305556139 },
  { codeId: 42, companyCode: 305556826 },
  { codeId: 3, companyCode: 305557045 },
  { codeId: 4, companyCode: 305558332 },
  { codeId: 53, companyCode: 305560529 },
  { codeId: 19, companyCode: 305560810 },
  { codeId: 53, companyCode: 305560949 },
  { codeId: 11, companyCode: 305560988 },
  { codeId: 20, companyCode: 305561499 },
  { codeId: 53, companyCode: 305561780 },
  { codeId: 3, companyCode: 305561855 },
  { codeId: 20, companyCode: 305561969 },
  { codeId: 11, companyCode: 305561983 },
  { codeId: 53, companyCode: 305562081 },
  { codeId: 20, companyCode: 305562117 },
  { codeId: 53, companyCode: 305562373 },
  { codeId: 53, companyCode: 305562626 },
  { codeId: 53, companyCode: 305563482 },
  { codeId: 21, companyCode: 305563564 },
  { codeId: 16, companyCode: 305563685 },
  { codeId: 19, companyCode: 305563984 },
  { codeId: 53, companyCode: 305564164 },
  { codeId: 35, companyCode: 305564285 },
  { codeId: 34, companyCode: 305564844 },
  { codeId: 16, companyCode: 305564901 },
  { codeId: 34, companyCode: 305565070 },
  { codeId: 16, companyCode: 305565280 },
  { codeId: 20, companyCode: 305565768 },
  { codeId: 53, companyCode: 305566037 },
  { codeId: 53, companyCode: 305566069 },
  { codeId: 20, companyCode: 305566172 },
  { codeId: 24, companyCode: 305566279 },
  { codeId: 53, companyCode: 305566432 },
  { codeId: 3, companyCode: 305567089 },
  { codeId: 20, companyCode: 305567096 },
  { codeId: 53, companyCode: 305567477 },
  { codeId: 5, companyCode: 305567491 },
  { codeId: 20, companyCode: 305568369 },
  { codeId: 3, companyCode: 305568376 },
  { codeId: 57, companyCode: 305568426 },
  { codeId: 28, companyCode: 305568604 },
  { codeId: 20, companyCode: 305568814 },
  { codeId: 34, companyCode: 305568878 },
  { codeId: 53, companyCode: 305568967 },
  { codeId: 53, companyCode: 305569108 },
  { codeId: 34, companyCode: 305569300 },
  { codeId: 53, companyCode: 305569332 },
  { codeId: 34, companyCode: 305569866 },
  { codeId: 20, companyCode: 305569909 },
  { codeId: 40, companyCode: 305570135 },
  { codeId: 24, companyCode: 305570167 },
  { codeId: 20, companyCode: 305570690 },
  { codeId: 34, companyCode: 305570854 },
  { codeId: 5, companyCode: 305570904 },
  { codeId: 53, companyCode: 305571073 },
  { codeId: 3, companyCode: 305571155 },
  { codeId: 53, companyCode: 305571212 },
  { codeId: 19, companyCode: 305571283 },
  { codeId: 48, companyCode: 305571454 },
  { codeId: 32, companyCode: 305571760 },
  { codeId: 53, companyCode: 305572570 },
  { codeId: 34, companyCode: 305572759 },
  { codeId: 10, companyCode: 305573003 },
  { codeId: 53, companyCode: 305574062 },
  { codeId: 53, companyCode: 305574169 },
  { codeId: 16, companyCode: 305574176 },
  { codeId: 53, companyCode: 305574386 },
  { codeId: 53, companyCode: 305574500 },
  { codeId: 53, companyCode: 305575118 },
  { codeId: 34, companyCode: 305576978 },
  { codeId: 5, companyCode: 305578395 },
  { codeId: 57, companyCode: 305582269 },
  { codeId: 61, companyCode: 305585646 },
  { codeId: 58, companyCode: 305585863 },
  { codeId: 53, companyCode: 305586844 },
  { codeId: 60, companyCode: 305587565 },
  { codeId: 53, companyCode: 305587661 },
  { codeId: 16, companyCode: 305589018 },
  { codeId: 53, companyCode: 305589395 },
  { codeId: 21, companyCode: 305589826 },
  { codeId: 3, companyCode: 305589840 },
  { codeId: 35, companyCode: 305590757 },
  { codeId: 49, companyCode: 305590853 },
  { codeId: 53, companyCode: 305591453 },
  { codeId: 53, companyCode: 305591784 },
  { codeId: 48, companyCode: 305592548 },
  { codeId: 53, companyCode: 305592605 },
  { codeId: 40, companyCode: 305594015 },
  { codeId: 28, companyCode: 305595590 },
  { codeId: 20, companyCode: 305595925 },
  { codeId: 3, companyCode: 305596703 },
  { codeId: 53, companyCode: 305596849 },
  { codeId: 53, companyCode: 305597965 },
  { codeId: 19, companyCode: 305598106 },
  { codeId: 57, companyCode: 305598202 },
  { codeId: 53, companyCode: 305599197 },
  { codeId: 11, companyCode: 305600233 },
  { codeId: 3, companyCode: 305600404 },
  { codeId: 53, companyCode: 305600564 },
  { codeId: 53, companyCode: 305601285 },
  { codeId: 20, companyCode: 305603215 },
  { codeId: 53, companyCode: 305604139 },
  { codeId: 53, companyCode: 305604331 },
  { codeId: 49, companyCode: 305607434 },
  { codeId: 58, companyCode: 305608536 },
  { codeId: 3, companyCode: 305608618 },
  { codeId: 16, companyCode: 305610747 },
  { codeId: 53, companyCode: 305610964 },
  { codeId: 34, companyCode: 305611009 },
  { codeId: 19, companyCode: 305612043 },
  { codeId: 19, companyCode: 305612958 },
  { codeId: 53, companyCode: 305615000 },
  { codeId: 60, companyCode: 305615609 },
  { codeId: 19, companyCode: 305615655 },
  { codeId: 53, companyCode: 305617421 },
  { codeId: 3, companyCode: 305617581 },
  { codeId: 20, companyCode: 305617713 },
  { codeId: 53, companyCode: 305618377 },
  { codeId: 53, companyCode: 305618459 },
  { codeId: 53, companyCode: 305618555 },
  { codeId: 53, companyCode: 305619625 },
  { codeId: 64, companyCode: 305619696 },
  { codeId: 53, companyCode: 305619707 },
  { codeId: 19, companyCode: 305620079 },
  { codeId: 64, companyCode: 305621373 },
  { codeId: 35, companyCode: 305621505 },
  { codeId: 53, companyCode: 305621697 },
  { codeId: 19, companyCode: 305621786 },
  { codeId: 31, companyCode: 305621868 },
  { codeId: 34, companyCode: 305622735 },
  { codeId: 53, companyCode: 305624782 },
  { codeId: 53, companyCode: 305625069 },
  { codeId: 53, companyCode: 305627629 },
  { codeId: 49, companyCode: 305628364 },
  { codeId: 19, companyCode: 305630714 },
  { codeId: 53, companyCode: 305631129 },
  { codeId: 3, companyCode: 305631727 },
  { codeId: 53, companyCode: 305632494 },
  { codeId: 53, companyCode: 305632829 },
  { codeId: 52, companyCode: 305633607 },
  { codeId: 49, companyCode: 305633945 },
  { codeId: 11, companyCode: 305634029 },
  { codeId: 3, companyCode: 305634748 },
  { codeId: 40, companyCode: 305635138 },
  { codeId: 58, companyCode: 305636133 },
  { codeId: 53, companyCode: 305636350 },
  { codeId: 28, companyCode: 305636592 },
  { codeId: 20, companyCode: 305638821 },
  { codeId: 53, companyCode: 305639019 },
  { codeId: 53, companyCode: 305639688 },
  { codeId: 38, companyCode: 305640683 },
  { codeId: 20, companyCode: 305640950 },
  { codeId: 19, companyCode: 305641123 },
  { codeId: 14, companyCode: 305641269 },
  { codeId: 3, companyCode: 305641575 },
  { codeId: 20, companyCode: 305642606 },
  { codeId: 16, companyCode: 305642613 },
  { codeId: 53, companyCode: 305643843 },
  { codeId: 38, companyCode: 305643900 },
  { codeId: 53, companyCode: 305644297 },
  { codeId: 38, companyCode: 305644393 },
  { codeId: 48, companyCode: 305644735 },
  { codeId: 53, companyCode: 305644856 },
  { codeId: 14, companyCode: 305644984 },
  { codeId: 53, companyCode: 305645488 },
  { codeId: 34, companyCode: 305645627 },
  { codeId: 19, companyCode: 305646309 },
  { codeId: 20, companyCode: 305646323 },
  { codeId: 3, companyCode: 305646451 },
  { codeId: 64, companyCode: 305646647 },
  { codeId: 53, companyCode: 305646960 },
  { codeId: 53, companyCode: 305649999 },
  { codeId: 49, companyCode: 305650275 },
  { codeId: 16, companyCode: 305651377 },
  { codeId: 34, companyCode: 305651459 },
  { codeId: 48, companyCode: 305651690 },
  { codeId: 53, companyCode: 305652479 },
  { codeId: 53, companyCode: 305653990 },
  { codeId: 16, companyCode: 305654366 },
  { codeId: 57, companyCode: 305654423 },
  { codeId: 3, companyCode: 305654779 },
  { codeId: 54, companyCode: 305655190 },
  { codeId: 53, companyCode: 305655952 },
  { codeId: 58, companyCode: 305656043 },
  { codeId: 58, companyCode: 305657330 },
  { codeId: 53, companyCode: 305658222 },
  { codeId: 28, companyCode: 305658425 },
  { codeId: 58, companyCode: 305659121 },
  { codeId: 58, companyCode: 305659609 },
  { codeId: 53, companyCode: 305659872 },
  { codeId: 58, companyCode: 305661097 },
  { codeId: 58, companyCode: 305661268 },
  { codeId: 50, companyCode: 305661706 },
  { codeId: 19, companyCode: 305662174 },
  { codeId: 18, companyCode: 305662199 },
  { codeId: 53, companyCode: 305663290 },
  { codeId: 3, companyCode: 305663867 },
  { codeId: 35, companyCode: 305664353 },
  { codeId: 19, companyCode: 305664428 },
  { codeId: 11, companyCode: 305664677 },
  { codeId: 4, companyCode: 305664944 },
  { codeId: 33, companyCode: 305665277 },
  { codeId: 25, companyCode: 305666895 },
  { codeId: 53, companyCode: 305666977 },
  { codeId: 53, companyCode: 305666984 },
  { codeId: 20, companyCode: 305667157 },
  { codeId: 57, companyCode: 305667716 },
  { codeId: 46, companyCode: 305668191 },
  { codeId: 21, companyCode: 305668768 },
  { codeId: 58, companyCode: 305669902 },
  { codeId: 35, companyCode: 305669980 },
  { codeId: 58, companyCode: 305670370 },
  { codeId: 58, companyCode: 305670388 },
  { codeId: 58, companyCode: 305670406 },
  { codeId: 58, companyCode: 305670908 },
  { codeId: 58, companyCode: 305670961 },
  { codeId: 53, companyCode: 305671084 },
  { codeId: 19, companyCode: 305671903 },
  { codeId: 53, companyCode: 305672439 },
  { codeId: 21, companyCode: 305672485 },
  { codeId: 57, companyCode: 305672827 },
  { codeId: 24, companyCode: 305673181 },
  { codeId: 53, companyCode: 305674397 },
  { codeId: 53, companyCode: 305676658 },
  { codeId: 53, companyCode: 305676708 },
  { codeId: 53, companyCode: 305676971 },
  { codeId: 20, companyCode: 305677016 },
  { codeId: 53, companyCode: 305677468 },
  { codeId: 34, companyCode: 305677678 },
  { codeId: 5, companyCode: 305678253 },
  { codeId: 40, companyCode: 305678399 },
  { codeId: 53, companyCode: 305679775 },
  { codeId: 53, companyCode: 305679946 },
  { codeId: 53, companyCode: 305681139 },
  { codeId: 53, companyCode: 305681438 },
  { codeId: 19, companyCode: 305681865 },
  { codeId: 3, companyCode: 305681961 },
  { codeId: 53, companyCode: 305683528 },
  { codeId: 35, companyCode: 305684306 },
  { codeId: 58, companyCode: 305684352 },
  { codeId: 53, companyCode: 305684562 },
  { codeId: 40, companyCode: 305685123 },
  { codeId: 19, companyCode: 305687213 },
  { codeId: 58, companyCode: 305687455 },
  { codeId: 37, companyCode: 305687722 },
  { codeId: 19, companyCode: 305688728 },
  { codeId: 16, companyCode: 305688952 },
  { codeId: 28, companyCode: 305689342 },
  { codeId: 6, companyCode: 305689417 },
  { codeId: 31, companyCode: 305690031 },
  { codeId: 53, companyCode: 305691795 },
  { codeId: 53, companyCode: 305693668 },
  { codeId: 4, companyCode: 305696326 },
  { codeId: 53, companyCode: 305696340 },
  { codeId: 19, companyCode: 305696397 },
  { codeId: 53, companyCode: 305696568 },
  { codeId: 53, companyCode: 305697264 },
  { codeId: 21, companyCode: 305697474 },
  { codeId: 53, companyCode: 305698900 },
  { codeId: 35, companyCode: 305699233 },
  { codeId: 53, companyCode: 305699404 },
  { codeId: 18, companyCode: 305701218 },
  { codeId: 53, companyCode: 305701321 },
  { codeId: 38, companyCode: 305701588 },
  { codeId: 16, companyCode: 305702416 },
  { codeId: 19, companyCode: 305702512 },
  { codeId: 53, companyCode: 305703870 },
  { codeId: 28, companyCode: 305704125 },
  { codeId: 53, companyCode: 305704171 },
  { codeId: 45, companyCode: 305705241 },
  { codeId: 9, companyCode: 305705280 },
  { codeId: 58, companyCode: 305705914 },
  { codeId: 53, companyCode: 305706044 },
  { codeId: 38, companyCode: 305706119 },
  { codeId: 61, companyCode: 305706603 },
  { codeId: 34, companyCode: 305706941 },
  { codeId: 16, companyCode: 305707395 },
  { codeId: 53, companyCode: 305707776 },
  { codeId: 3, companyCode: 305708725 },
  { codeId: 19, companyCode: 305709250 },
  { codeId: 53, companyCode: 305709738 },
  { codeId: 34, companyCode: 305709930 },
  { codeId: 53, companyCode: 305710391 },
  { codeId: 35, companyCode: 305710904 },
  { codeId: 19, companyCode: 305711073 },
  { codeId: 19, companyCode: 305711447 },
  { codeId: 34, companyCode: 305711618 },
  { codeId: 52, companyCode: 305711867 },
  { codeId: 11, companyCode: 305713455 },
  { codeId: 53, companyCode: 305713804 },
  { codeId: 53, companyCode: 305714393 },
  { codeId: 53, companyCode: 305714660 },
  { codeId: 16, companyCode: 305714984 },
  { codeId: 16, companyCode: 305715207 },
  { codeId: 53, companyCode: 305715463 },
  { codeId: 34, companyCode: 305715470 },
  { codeId: 19, companyCode: 305715627 },
  { codeId: 64, companyCode: 305716056 },
  { codeId: 3, companyCode: 305716106 },
  { codeId: 19, companyCode: 305716113 },
  { codeId: 58, companyCode: 305716558 },
  { codeId: 19, companyCode: 305716615 },
  { codeId: 53, companyCode: 305716729 },
  { codeId: 33, companyCode: 305716921 },
  { codeId: 19, companyCode: 305717425 },
  { codeId: 40, companyCode: 305718801 },
  { codeId: 40, companyCode: 305719369 },
  { codeId: 34, companyCode: 305719718 },
  { codeId: 16, companyCode: 305720108 },
  { codeId: 53, companyCode: 305720161 },
  { codeId: 53, companyCode: 305720243 },
  { codeId: 19, companyCode: 305720300 },
  { codeId: 3, companyCode: 305720624 },
  { codeId: 53, companyCode: 305720802 },
  { codeId: 19, companyCode: 305721352 },
  { codeId: 37, companyCode: 305721480 },
  { codeId: 40, companyCode: 305721530 },
  { codeId: 53, companyCode: 305722059 },
  { codeId: 16, companyCode: 305722290 },
  { codeId: 19, companyCode: 305723182 },
  { codeId: 58, companyCode: 305723524 },
  { codeId: 58, companyCode: 305724448 },
  { codeId: 19, companyCode: 305725087 },
  { codeId: 53, companyCode: 305725151 },
  { codeId: 57, companyCode: 305725525 },
  { codeId: 53, companyCode: 305726221 },
  { codeId: 53, companyCode: 305726958 },
  { codeId: 3, companyCode: 305727031 },
  { codeId: 19, companyCode: 305728051 },
  { codeId: 53, companyCode: 305728756 },
  { codeId: 53, companyCode: 305729064 },
  { codeId: 40, companyCode: 305729299 },
  { codeId: 3, companyCode: 305729609 },
  { codeId: 53, companyCode: 305729687 },
  { codeId: 58, companyCode: 305731122 },
  { codeId: 53, companyCode: 305731485 },
  { codeId: 4, companyCode: 305733625 },
  { codeId: 53, companyCode: 305734061 },
  { codeId: 53, companyCode: 305734403 },
  { codeId: 53, companyCode: 305735074 },
  { codeId: 40, companyCode: 305735170 },
  { codeId: 58, companyCode: 305735754 },
  { codeId: 20, companyCode: 305736678 },
  { codeId: 9, companyCode: 305736952 },
  { codeId: 34, companyCode: 305737075 },
  { codeId: 53, companyCode: 305739215 },
  { codeId: 16, companyCode: 305740694 },
  { codeId: 53, companyCode: 305741038 },
  { codeId: 28, companyCode: 305741974 },
  { codeId: 53, companyCode: 305742040 },
  { codeId: 53, companyCode: 305742250 },
  { codeId: 59, companyCode: 305743135 },
  { codeId: 53, companyCode: 305743174 },
  { codeId: 53, companyCode: 305743644 },
  { codeId: 50, companyCode: 305744536 },
  { codeId: 16, companyCode: 305744739 },
  { codeId: 19, companyCode: 305744874 },
  { codeId: 19, companyCode: 305745168 },
  { codeId: 26, companyCode: 305750838 },
  { codeId: 53, companyCode: 305755098 },
  { codeId: 53, companyCode: 305756175 },
  { codeId: 58, companyCode: 305757875 },
  { codeId: 16, companyCode: 305758856 },
  { codeId: 53, companyCode: 305758906 },
  { codeId: 35, companyCode: 305758977 },
  { codeId: 53, companyCode: 305759004 },
  { codeId: 28, companyCode: 305762648 },
  { codeId: 39, companyCode: 305765167 },
  { codeId: 19, companyCode: 305766917 },
  { codeId: 14, companyCode: 305767677 },
  { codeId: 5, companyCode: 305769169 },
  { codeId: 20, companyCode: 305769653 },
  { codeId: 53, companyCode: 305769774 },
  { codeId: 5, companyCode: 305770132 },
  { codeId: 53, companyCode: 305771184 },
  { codeId: 58, companyCode: 305771412 },
  { codeId: 53, companyCode: 305771469 },
  { codeId: 36, companyCode: 305772083 },
  { codeId: 35, companyCode: 305773993 },
  { codeId: 21, companyCode: 305775446 },
  { codeId: 19, companyCode: 305776402 },
  { codeId: 53, companyCode: 305778798 },
  { codeId: 19, companyCode: 305779430 },
  { codeId: 21, companyCode: 305779793 },
  { codeId: 53, companyCode: 305780016 },
  { codeId: 58, companyCode: 305780774 },
  { codeId: 58, companyCode: 305781680 },
  { codeId: 19, companyCode: 305785091 },
  { codeId: 58, companyCode: 305785700 },
  { codeId: 53, companyCode: 305787701 },
  { codeId: 53, companyCode: 305790708 },
  { codeId: 53, companyCode: 305793106 },
  { codeId: 21, companyCode: 305795541 },
  { codeId: 16, companyCode: 305795580 },
  { codeId: 24, companyCode: 305796173 },
  { codeId: 19, companyCode: 305799244 },
  { codeId: 53, companyCode: 305800611 },
  { codeId: 19, companyCode: 305801179 },
  { codeId: 53, companyCode: 305802402 },
  { codeId: 57, companyCode: 305802441 },
  { codeId: 53, companyCode: 305804588 },
  { codeId: 18, companyCode: 305807171 },
  { codeId: 20, companyCode: 305807260 },
  { codeId: 58, companyCode: 305807723 },
  { codeId: 21, companyCode: 305808152 },
  { codeId: 20, companyCode: 305809286 },
  { codeId: 58, companyCode: 305812923 },
  { codeId: 53, companyCode: 305815613 },
  { codeId: 53, companyCode: 305818132 },
  { codeId: 17, companyCode: 305818335 },
  { codeId: 3, companyCode: 305819800 },
  { codeId: 53, companyCode: 305822305 },
  { codeId: 19, companyCode: 305825251 },
  { codeId: 53, companyCode: 305826321 },
  { codeId: 53, companyCode: 305827117 },
  { codeId: 34, companyCode: 305831507 },
  { codeId: 53, companyCode: 305831539 },
  { codeId: 53, companyCode: 305833180 },
  { codeId: 53, companyCode: 305833789 },
  { codeId: 19, companyCode: 305834204 },
  { codeId: 5, companyCode: 305836187 },
  { codeId: 58, companyCode: 305838715 },
  { codeId: 53, companyCode: 305839094 },
  { codeId: 53, companyCode: 305839169 },
  { codeId: 53, companyCode: 305839258 },
  { codeId: 34, companyCode: 305842254 },
  { codeId: 37, companyCode: 305844294 },
  { codeId: 53, companyCode: 305844611 },
  { codeId: 53, companyCode: 305848620 },
  { codeId: 50, companyCode: 305849633 },
  { codeId: 53, companyCode: 305849843 },
  { codeId: 21, companyCode: 305850450 },
  { codeId: 50, companyCode: 305856115 },
  { codeId: 16, companyCode: 305856122 },
  { codeId: 35, companyCode: 305857562 },
  { codeId: 49, companyCode: 305860074 },
  { codeId: 53, companyCode: 305861639 },
  { codeId: 58, companyCode: 305863597 },
  { codeId: 3, companyCode: 305864197 },
  { codeId: 53, companyCode: 305874227 },
  { codeId: 50, companyCode: 305874266 },
  { codeId: 53, companyCode: 305876580 },
  { codeId: 3, companyCode: 305879174 },
  { codeId: 59, companyCode: 305879669 },
  { codeId: 45, companyCode: 305885693 },
  { codeId: 53, companyCode: 305886724 },
  { codeId: 19, companyCode: 305888127 },
  { codeId: 53, companyCode: 305888209 },
  { codeId: 16, companyCode: 305888287 },
  { codeId: 16, companyCode: 305888369 },
  { codeId: 16, companyCode: 305888903 },
  { codeId: 50, companyCode: 305892613 },
  { codeId: 19, companyCode: 305894347 },
  { codeId: 58, companyCode: 305894817 },
  { codeId: 48, companyCode: 305900298 },
  { codeId: 53, companyCode: 305901635 },
  { codeId: 40, companyCode: 305903351 },
  { codeId: 53, companyCode: 305903458 },
  { codeId: 38, companyCode: 305904916 },
  { codeId: 48, companyCode: 305906657 },
  { codeId: 43, companyCode: 305906739 },
  { codeId: 53, companyCode: 305906792 },
  { codeId: 45, companyCode: 305906835 },
  { codeId: 53, companyCode: 305906956 },
  { codeId: 58, companyCode: 305907271 },
  { codeId: 53, companyCode: 305907798 },
  { codeId: 57, companyCode: 305909589 },
  { codeId: 48, companyCode: 305909767 },
  { codeId: 49, companyCode: 305910641 },
  { codeId: 59, companyCode: 305910723 },
  { codeId: 53, companyCode: 305911608 },
  { codeId: 19, companyCode: 305912126 },
  { codeId: 53, companyCode: 305912813 },
  { codeId: 53, companyCode: 305913281 },
  { codeId: 53, companyCode: 305913872 },
  { codeId: 57, companyCode: 305915097 },
  { codeId: 53, companyCode: 305916338 },
  { codeId: 34, companyCode: 305917212 },
  { codeId: 3, companyCode: 305918435 },
  { codeId: 53, companyCode: 305918798 },
  { codeId: 34, companyCode: 305919195 },
  { codeId: 17, companyCode: 305919302 },
  { codeId: 53, companyCode: 305920297 },
  { codeId: 21, companyCode: 305921125 },
  { codeId: 38, companyCode: 305922362 },
  { codeId: 53, companyCode: 305922405 },
  { codeId: 20, companyCode: 305924356 },
  { codeId: 53, companyCode: 305925006 },
  { codeId: 34, companyCode: 305926695 },
  { codeId: 9, companyCode: 305929047 },
  { codeId: 28, companyCode: 305929264 },
  { codeId: 4, companyCode: 305931870 },
  { codeId: 19, companyCode: 305934293 },
  { codeId: 53, companyCode: 305934329 },
  { codeId: 58, companyCode: 305934350 },
  { codeId: 57, companyCode: 305935203 },
  { codeId: 53, companyCode: 305935228 },
  { codeId: 35, companyCode: 305936426 },
  { codeId: 53, companyCode: 305936508 },
  { codeId: 34, companyCode: 305939194 },
  { codeId: 53, companyCode: 305939301 },
  { codeId: 53, companyCode: 305939582 },
  { codeId: 21, companyCode: 305940563 },
  { codeId: 49, companyCode: 305940652 },
  { codeId: 53, companyCode: 305940691 },
  { codeId: 58, companyCode: 305942607 },
  { codeId: 19, companyCode: 305943043 },
  { codeId: 53, companyCode: 305943381 },
  { codeId: 53, companyCode: 305943449 },
  { codeId: 53, companyCode: 305944152 },
  { codeId: 52, companyCode: 305944227 },
  { codeId: 19, companyCode: 305944743 },
  { codeId: 53, companyCode: 305945585 },
  { codeId: 21, companyCode: 305946783 },
  { codeId: 53, companyCode: 305946801 },
  { codeId: 53, companyCode: 305949950 },
  { codeId: 34, companyCode: 305950205 },
  { codeId: 40, companyCode: 305950326 },
  { codeId: 3, companyCode: 305950479 },
  { codeId: 53, companyCode: 305954374 },
  { codeId: 53, companyCode: 305954488 },
  { codeId: 49, companyCode: 305954513 },
  { codeId: 46, companyCode: 305954716 },
  { codeId: 20, companyCode: 305958636 },
  { codeId: 53, companyCode: 305959656 },
  { codeId: 43, companyCode: 305959713 },
  { codeId: 58, companyCode: 305961116 },
  { codeId: 58, companyCode: 305961707 },
  { codeId: 53, companyCode: 305961874 },
  { codeId: 17, companyCode: 305964219 },
  { codeId: 58, companyCode: 305965157 },
  { codeId: 53, companyCode: 305965381 },
  { codeId: 19, companyCode: 305968751 },
  { codeId: 57, companyCode: 305969223 },
  { codeId: 38, companyCode: 305969789 },
  { codeId: 53, companyCode: 305970624 },
  { codeId: 19, companyCode: 305971740 },
  { codeId: 53, companyCode: 305971808 },
  { codeId: 16, companyCode: 305972116 },
  { codeId: 53, companyCode: 305972618 },
  { codeId: 57, companyCode: 305972874 },
  { codeId: 49, companyCode: 305972988 },
  { codeId: 53, companyCode: 305974284 },
  { codeId: 43, companyCode: 305976303 },
  { codeId: 64, companyCode: 305976659 },
  { codeId: 19, companyCode: 305977939 },
  { codeId: 53, companyCode: 305978902 },
  { codeId: 53, companyCode: 305979783 },
  { codeId: 53, companyCode: 305981713 },
  { codeId: 20, companyCode: 305982733 },
  { codeId: 57, companyCode: 305985028 },
  { codeId: 3, companyCode: 305985035 },
  { codeId: 21, companyCode: 305988622 },
  { codeId: 20, companyCode: 305990954 },
  { codeId: 53, companyCode: 305991312 },
  { codeId: 57, companyCode: 305991885 },
  { codeId: 53, companyCode: 305991974 },
  { codeId: 48, companyCode: 305992186 },
  { codeId: 52, companyCode: 305993092 },
  { codeId: 64, companyCode: 305993644 },
  { codeId: 16, companyCode: 305994251 },
  { codeId: 58, companyCode: 305997169 },
  { codeId: 28, companyCode: 305999227 },
  { codeId: 40, companyCode: 306001329 },
  { codeId: 53, companyCode: 306001432 },
  { codeId: 34, companyCode: 306004713 },
  { codeId: 58, companyCode: 306006205 },
  { codeId: 58, companyCode: 306006618 },
  { codeId: 53, companyCode: 306007086 },
  { codeId: 53, companyCode: 306007136 },
  { codeId: 49, companyCode: 306008829 },
  { codeId: 40, companyCode: 306008875 },
  { codeId: 19, companyCode: 306010659 },
  { codeId: 40, companyCode: 306014732 },
  { codeId: 19, companyCode: 306014771 },
  { codeId: 53, companyCode: 306016701 },
  { codeId: 53, companyCode: 306016772 },
  { codeId: 19, companyCode: 306018524 },
  { codeId: 19, companyCode: 306018734 },
  { codeId: 38, companyCode: 306019708 },
  { codeId: 58, companyCode: 306020112 },
  { codeId: 53, companyCode: 306021189 },
  { codeId: 5, companyCode: 306021246 },
  { codeId: 16, companyCode: 306021292 },
  { codeId: 40, companyCode: 306021730 },
  { codeId: 53, companyCode: 306023756 },
  { codeId: 35, companyCode: 306026720 },
  { codeId: 53, companyCode: 306028080 },
  { codeId: 53, companyCode: 306028778 },
  { codeId: 58, companyCode: 306032196 },
  { codeId: 58, companyCode: 306039109 },
  { codeId: 53, companyCode: 306041747 },
  { codeId: 53, companyCode: 306041829 },
  { codeId: 57, companyCode: 306042176 },
  { codeId: 53, companyCode: 306042653 },
  { codeId: 57, companyCode: 306044387 },
  { codeId: 58, companyCode: 306045293 },
  { codeId: 9, companyCode: 306046737 },
  { codeId: 53, companyCode: 306047230 },
  { codeId: 53, companyCode: 306047910 },
  { codeId: 16, companyCode: 306048019 },
  { codeId: 53, companyCode: 306050162 },
  { codeId: 3, companyCode: 306050244 },
  { codeId: 57, companyCode: 306051175 },
  { codeId: 58, companyCode: 306052131 },
  { codeId: 58, companyCode: 306052373 },
  { codeId: 58, companyCode: 306052380 },
  { codeId: 19, companyCode: 306052583 },
  { codeId: 53, companyCode: 306053571 },
  { codeId: 58, companyCode: 306056126 },
  { codeId: 40, companyCode: 306056158 },
  { codeId: 58, companyCode: 306057993 },
  { codeId: 53, companyCode: 306058248 },
  { codeId: 53, companyCode: 306058515 },
  { codeId: 58, companyCode: 306059670 },
  { codeId: 16, companyCode: 306059898 },
  { codeId: 53, companyCode: 306062182 },
  { codeId: 53, companyCode: 306063398 },
  { codeId: 34, companyCode: 306064258 },
  { codeId: 25, companyCode: 306064450 },
  { codeId: 19, companyCode: 306066864 },
  { codeId: 53, companyCode: 306066896 },
  { codeId: 53, companyCode: 306067471 },
  { codeId: 58, companyCode: 306068922 },
  { codeId: 53, companyCode: 306069344 },
  { codeId: 53, companyCode: 306069853 },
  { codeId: 58, companyCode: 306069999 },
  { codeId: 58, companyCode: 306070001 },
  { codeId: 58, companyCode: 306070841 },
  { codeId: 58, companyCode: 306070859 },
  { codeId: 53, companyCode: 306071053 },
  { codeId: 58, companyCode: 306071092 },
  { codeId: 58, companyCode: 306071174 },
  { codeId: 57, companyCode: 306071231 },
  { codeId: 3, companyCode: 306071306 },
  { codeId: 53, companyCode: 306072130 },
  { codeId: 53, companyCode: 306072714 },
  { codeId: 59, companyCode: 306073855 },
  { codeId: 19, companyCode: 306074067 },
  { codeId: 58, companyCode: 306074665 },
  { codeId: 40, companyCode: 306074836 },
  { codeId: 19, companyCode: 306075169 },
  { codeId: 53, companyCode: 306077241 },
  { codeId: 58, companyCode: 306077782 },
  { codeId: 58, companyCode: 306078756 },
  { codeId: 34, companyCode: 306079908 },
  { codeId: 57, companyCode: 306080604 },
  { codeId: 34, companyCode: 306080629 },
  { codeId: 19, companyCode: 306080892 },
  { codeId: 17, companyCode: 306082181 },
  { codeId: 35, companyCode: 306082288 },
  { codeId: 34, companyCode: 306082726 },
  { codeId: 40, companyCode: 306083447 },
  { codeId: 53, companyCode: 306083828 },
  { codeId: 53, companyCode: 306084606 },
  { codeId: 30, companyCode: 306084823 },
  { codeId: 58, companyCode: 306084944 },
  { codeId: 48, companyCode: 306085697 },
  { codeId: 53, companyCode: 306091091 },
  { codeId: 16, companyCode: 306092738 },
  { codeId: 48, companyCode: 306094582 },
  { codeId: 16, companyCode: 306094835 },
  { codeId: 17, companyCode: 306094899 },
  { codeId: 53, companyCode: 306095232 },
  { codeId: 58, companyCode: 306095474 },
  { codeId: 53, companyCode: 306095741 },
  { codeId: 53, companyCode: 306096035 },
  { codeId: 23, companyCode: 306096099 },
  { codeId: 19, companyCode: 306097023 },
  { codeId: 57, companyCode: 306097265 },
  { codeId: 58, companyCode: 306098157 },
  { codeId: 19, companyCode: 306099939 },
  { codeId: 53, companyCode: 306101087 },
  { codeId: 50, companyCode: 306101991 },
  { codeId: 43, companyCode: 306104172 },
  { codeId: 34, companyCode: 306105107 },
  { codeId: 16, companyCode: 306105235 },
  { codeId: 53, companyCode: 306105573 },
  { codeId: 34, companyCode: 306106038 },
  { codeId: 58, companyCode: 306107193 },
  { codeId: 58, companyCode: 306107204 },
  { codeId: 50, companyCode: 306107229 },
  { codeId: 58, companyCode: 306107777 },
  { codeId: 58, companyCode: 306107802 },
  { codeId: 58, companyCode: 306107948 },
  { codeId: 57, companyCode: 306108320 },
  { codeId: 57, companyCode: 306108509 },
  { codeId: 48, companyCode: 306108612 },
  { codeId: 16, companyCode: 306109116 },
  { codeId: 34, companyCode: 306109618 },
  { codeId: 53, companyCode: 306110047 },
  { codeId: 58, companyCode: 306110264 },
  { codeId: 58, companyCode: 306111238 },
  { codeId: 58, companyCode: 306111291 },
  { codeId: 58, companyCode: 306111341 },
  { codeId: 58, companyCode: 306111779 },
  { codeId: 45, companyCode: 306112112 },
  { codeId: 58, companyCode: 306112977 },
  { codeId: 20, companyCode: 306114483 },
  { codeId: 58, companyCode: 306114526 },
  { codeId: 58, companyCode: 306114914 },
  { codeId: 58, companyCode: 306115083 },
  { codeId: 58, companyCode: 306115165 },
  { codeId: 59, companyCode: 306115560 },
  { codeId: 59, companyCode: 306115667 },
  { codeId: 49, companyCode: 306116228 },
  { codeId: 50, companyCode: 306116662 },
  { codeId: 58, companyCode: 306117508 },
  { codeId: 36, companyCode: 306117725 },
  { codeId: 58, companyCode: 306117846 },
  { codeId: 58, companyCode: 306117903 },
  { codeId: 58, companyCode: 306117928 },
  { codeId: 58, companyCode: 306117935 },
  { codeId: 58, companyCode: 306117999 },
  { codeId: 53, companyCode: 306118962 },
  { codeId: 53, companyCode: 306119128 },
  { codeId: 59, companyCode: 306119466 },
  { codeId: 58, companyCode: 306119822 },
  { codeId: 53, companyCode: 306120034 },
  { codeId: 37, companyCode: 306121759 },
  { codeId: 53, companyCode: 306123614 },
  { codeId: 58, companyCode: 306124246 },
  { codeId: 58, companyCode: 306124965 },
  { codeId: 53, companyCode: 306125063 },
  { codeId: 58, companyCode: 306125309 },
  { codeId: 58, companyCode: 306125362 },
  { codeId: 58, companyCode: 306125483 },
  { codeId: 53, companyCode: 306126012 },
  { codeId: 58, companyCode: 306127573 },
  { codeId: 57, companyCode: 306127790 },
  { codeId: 17, companyCode: 306128732 },
  { codeId: 58, companyCode: 306128789 },
  { codeId: 53, companyCode: 306129268 },
  { codeId: 21, companyCode: 306129631 },
  { codeId: 38, companyCode: 306132360 },
  { codeId: 47, companyCode: 306132894 },
  { codeId: 17, companyCode: 306133238 },
  { codeId: 16, companyCode: 306133626 },
  { codeId: 53, companyCode: 306134906 },
  { codeId: 64, companyCode: 306136191 },
  { codeId: 57, companyCode: 306136743 },
  { codeId: 19, companyCode: 306137628 },
  { codeId: 34, companyCode: 306137724 },
  { codeId: 20, companyCode: 306138623 },
  { codeId: 53, companyCode: 306138922 },
  { codeId: 58, companyCode: 306139419 },
  { codeId: 32, companyCode: 306140250 },
  { codeId: 58, companyCode: 306141174 },
  { codeId: 57, companyCode: 306141313 },
  { codeId: 19, companyCode: 306141466 },
  { codeId: 35, companyCode: 306142529 },
  { codeId: 53, companyCode: 306142988 },
  { codeId: 58, companyCode: 306143684 },
  { codeId: 20, companyCode: 306145347 },
  { codeId: 59, companyCode: 306145621 },
  { codeId: 58, companyCode: 306145692 },
  { codeId: 53, companyCode: 306146424 },
  { codeId: 53, companyCode: 306147227 },
  { codeId: 58, companyCode: 306147711 },
  { codeId: 58, companyCode: 306147729 },
  { codeId: 49, companyCode: 306147857 },
  { codeId: 57, companyCode: 306148279 },
  { codeId: 53, companyCode: 306148813 },
  { codeId: 53, companyCode: 306150287 },
  { codeId: 5, companyCode: 306153671 },
  { codeId: 48, companyCode: 306153721 },
  { codeId: 50, companyCode: 306154848 },
  { codeId: 53, companyCode: 306155206 },
  { codeId: 58, companyCode: 306155583 },
  { codeId: 53, companyCode: 306155793 },
  { codeId: 50, companyCode: 306155875 },
  { codeId: 50, companyCode: 306156322 },
  { codeId: 49, companyCode: 306158800 },
  { codeId: 53, companyCode: 306161860 },
  { codeId: 53, companyCode: 306162439 },
  { codeId: 16, companyCode: 306163014 },
  { codeId: 58, companyCode: 306163651 },
  { codeId: 53, companyCode: 306167137 },
  { codeId: 59, companyCode: 306167347 },
  { codeId: 53, companyCode: 306168253 },
  { codeId: 57, companyCode: 306171580 },
  { codeId: 49, companyCode: 306174612 },
  { codeId: 53, companyCode: 306174644 },
  { codeId: 38, companyCode: 306175479 },
  { codeId: 48, companyCode: 306175493 },
  { codeId: 40, companyCode: 306177188 },
  { codeId: 53, companyCode: 306179812 },
  { codeId: 49, companyCode: 306180120 },
  { codeId: 28, companyCode: 306181539 },
  { codeId: 53, companyCode: 306185128 },
  { codeId: 58, companyCode: 306185797 },
  { codeId: 58, companyCode: 306185808 },
  { codeId: 58, companyCode: 306185879 },
  { codeId: 58, companyCode: 306185950 },
  { codeId: 58, companyCode: 306186237 },
  { codeId: 58, companyCode: 306186340 },
  { codeId: 58, companyCode: 306186582 },
  { codeId: 53, companyCode: 306191095 },
  { codeId: 53, companyCode: 306191978 },
  { codeId: 43, companyCode: 306192681 },
  { codeId: 3, companyCode: 306197753 },
  { codeId: 58, companyCode: 306199900 },
  { codeId: 34, companyCode: 306200758 },
  { codeId: 58, companyCode: 306200765 },
  { codeId: 58, companyCode: 306200847 },
  { codeId: 58, companyCode: 306200854 },
  { codeId: 58, companyCode: 306200861 },
  { codeId: 58, companyCode: 306200936 },
  { codeId: 3, companyCode: 306201155 },
  { codeId: 58, companyCode: 306201187 },
  { codeId: 58, companyCode: 306201244 },
  { codeId: 58, companyCode: 306201618 },
  { codeId: 58, companyCode: 306201625 },
  { codeId: 58, companyCode: 306201632 },
  { codeId: 44, companyCode: 306202823 },
  { codeId: 57, companyCode: 306204361 },
  { codeId: 53, companyCode: 306208858 },
  { codeId: 20, companyCode: 306217540 },
  { codeId: 19, companyCode: 306219203 },
  { codeId: 49, companyCode: 306219413 },
  { codeId: 53, companyCode: 306220305 },
  { codeId: 34, companyCode: 306221097 },
  { codeId: 20, companyCode: 306221841 },
  { codeId: 53, companyCode: 306221873 },
  { codeId: 58, companyCode: 306221898 },
  { codeId: 21, companyCode: 306222224 },
  { codeId: 52, companyCode: 306223269 },
  { codeId: 53, companyCode: 306225882 },
  { codeId: 49, companyCode: 306229329 },
  { codeId: 34, companyCode: 306229432 },
  { codeId: 48, companyCode: 306230107 },
  { codeId: 19, companyCode: 306230712 },
  { codeId: 60, companyCode: 306231134 },
  { codeId: 53, companyCode: 306231643 },
  { codeId: 16, companyCode: 306232108 },
  { codeId: 58, companyCode: 306234194 },
  { codeId: 53, companyCode: 306235175 },
  { codeId: 19, companyCode: 306235410 },
  { codeId: 53, companyCode: 306235563 },
  { codeId: 34, companyCode: 306235759 },
  { codeId: 53, companyCode: 306236409 },
  { codeId: 19, companyCode: 306236665 },
  { codeId: 4, companyCode: 306237009 },
  { codeId: 20, companyCode: 306242472 },
  { codeId: 58, companyCode: 306246734 },
  { codeId: 35, companyCode: 306246983 },
  { codeId: 19, companyCode: 306248105 },
  { codeId: 57, companyCode: 306249132 },
  { codeId: 34, companyCode: 306253604 },
  { codeId: 58, companyCode: 306253668 },
  { codeId: 58, companyCode: 306253732 },
  { codeId: 58, companyCode: 306253764 },
  { codeId: 58, companyCode: 306253807 },
  { codeId: 58, companyCode: 306253814 },
  { codeId: 53, companyCode: 306254097 },
  { codeId: 53, companyCode: 306254108 },
  { codeId: 58, companyCode: 306254122 },
  { codeId: 58, companyCode: 306254236 },
  { codeId: 58, companyCode: 306254318 },
  { codeId: 58, companyCode: 306254357 },
  { codeId: 58, companyCode: 306255085 },
  { codeId: 53, companyCode: 306256269 },
  { codeId: 53, companyCode: 306260303 },
  { codeId: 53, companyCode: 306267187 },
  { codeId: 3, companyCode: 306267237 },
  { codeId: 58, companyCode: 306267486 },
  { codeId: 64, companyCode: 306268620 },
  { codeId: 48, companyCode: 306268894 },
  { codeId: 53, companyCode: 306269188 },
  { codeId: 58, companyCode: 306269291 },
  { codeId: 53, companyCode: 306269786 },
  { codeId: 53, companyCode: 306269964 },
  { codeId: 9, companyCode: 306271050 },
  { codeId: 57, companyCode: 306272914 },
  { codeId: 40, companyCode: 306273471 },
  { codeId: 21, companyCode: 306275045 },
  { codeId: 16, companyCode: 306276581 },
  { codeId: 53, companyCode: 306279428 },
  { codeId: 53, companyCode: 306282207 },
  { codeId: 49, companyCode: 306288982 },
  { codeId: 53, companyCode: 306289155 },
  { codeId: 19, companyCode: 306291302 },
  { codeId: 53, companyCode: 306295998 },
  { codeId: 58, companyCode: 306296694 },
  { codeId: 3, companyCode: 306302275 },
  { companyCode: 110846971, codeId: 65 },
  { companyCode: 133753017, codeId: 65 },
  { companyCode: 153009143, codeId: 65 },
  { companyCode: 244670310, codeId: 65 },
  { companyCode: 244670310, codeId: 71 },
  { companyCode: 301276531, codeId: 65 },
  { companyCode: 302620960, codeId: 65 },
  { companyCode: 302620960, codeId: 71 },
  { companyCode: 302881024, codeId: 65 },
  { companyCode: 303782367, codeId: 65 },
  { companyCode: 303792888, codeId: 65 },
  { companyCode: 304178964, codeId: 65 },
  { companyCode: 145787276, codeId: 66 },
  { companyCode: 145787276, codeId: 69 },
  { companyCode: 145787276, codeId: 73 },
  { companyCode: 145787276, codeId: 74 },
  { companyCode: 151479265, codeId: 66 },
  { companyCode: 151479265, codeId: 69 },
  { companyCode: 151479265, codeId: 71 },
  { companyCode: 151479265, codeId: 73 },
  { companyCode: 151479265, codeId: 74 },
  { companyCode: 163743744, codeId: 66 },
  { companyCode: 163743744, codeId: 69 },
  { companyCode: 163743744, codeId: 71 },
  { companyCode: 163743744, codeId: 73 },
  { companyCode: 163743744, codeId: 74 },
  { companyCode: 171780190, codeId: 66 },
  { companyCode: 171780190, codeId: 69 },
  { companyCode: 171780190, codeId: 73 },
  { companyCode: 171780190, codeId: 74 },
  { companyCode: 179901854, codeId: 66 },
  { companyCode: 179901854, codeId: 69 },
  { companyCode: 179901854, codeId: 71 },
  { companyCode: 179901854, codeId: 73 },
  { companyCode: 179901854, codeId: 74 },
  { companyCode: 181705485, codeId: 66 },
  { companyCode: 181705485, codeId: 69 },
  { companyCode: 181705485, codeId: 73 },
  { companyCode: 181705485, codeId: 74 },
  { companyCode: 250135860, codeId: 66 },
  { companyCode: 250135860, codeId: 69 },
  { companyCode: 250135860, codeId: 71 },
  { companyCode: 250135860, codeId: 73 },
  { companyCode: 250135860, codeId: 74 },
  { companyCode: 300083878, codeId: 66 },
  { companyCode: 300083878, codeId: 69 },
  { companyCode: 300083878, codeId: 73 },
  { companyCode: 300083878, codeId: 74 },
  { companyCode: 300092998, codeId: 66 },
  { companyCode: 300092998, codeId: 69 },
  { companyCode: 300092998, codeId: 71 },
  { companyCode: 300092998, codeId: 73 },
  { companyCode: 300092998, codeId: 74 },
  { companyCode: 300031842, codeId: 67 },
  { companyCode: 125722762, codeId: 68 },
  { companyCode: 181522014, codeId: 69 },
  { companyCode: 300127004, codeId: 69 },
  { companyCode: 300127004, codeId: 73 },
  { companyCode: 300127004, codeId: 74 },
  { companyCode: 302723177, codeId: 69 },
  { companyCode: 126199731, codeId: 70 },
  { companyCode: 154742821, codeId: 70 },
  { companyCode: 158834726, codeId: 70 },
  { companyCode: 158891218, codeId: 70 },
  { companyCode: 173045168, codeId: 70 },
  { companyCode: 177356654, codeId: 70 },
  { companyCode: 302290758, codeId: 70 },
  { companyCode: 302374252, codeId: 70 },
  { companyCode: 302850071, codeId: 70 },
  { companyCode: 302850203, codeId: 70 },
  { companyCode: 110193723, codeId: 71 },
  { companyCode: 110723673, codeId: 71 },
  { companyCode: 111679436, codeId: 71 },
  { companyCode: 111693432, codeId: 71 },
  { companyCode: 120504795, codeId: 71 },
  { companyCode: 123044722, codeId: 71 },
  { companyCode: 123107852, codeId: 71 },
  { companyCode: 123739364, codeId: 71 },
  { companyCode: 123852426, codeId: 71 },
  { companyCode: 123919953, codeId: 71 },
  { companyCode: 124824426, codeId: 71 },
  { companyCode: 125521955, codeId: 71 },
  { companyCode: 125544562, codeId: 71 },
  { companyCode: 125587777, codeId: 71 },
  { companyCode: 125594056, codeId: 71 },
  { companyCode: 125821828, codeId: 71 },
  { companyCode: 132616649, codeId: 71 },
  { companyCode: 132751369, codeId: 71 },
  { companyCode: 132751369, codeId: 75 },
  { companyCode: 134357939, codeId: 71 },
  { companyCode: 135007799, codeId: 71 },
  { companyCode: 135699511, codeId: 71 },
  { companyCode: 135917853, codeId: 71 },
  { companyCode: 135935723, codeId: 71 },
  { companyCode: 140410741, codeId: 71 },
  { companyCode: 141316978, codeId: 71 },
  { companyCode: 141686027, codeId: 71 },
  { companyCode: 142035336, codeId: 71 },
  { companyCode: 142095842, codeId: 71 },
  { companyCode: 142150210, codeId: 71 },
  { companyCode: 142150210, codeId: 75 },
  { companyCode: 144128180, codeId: 71 },
  { companyCode: 145282046, codeId: 71 },
  { companyCode: 145507557, codeId: 71 },
  { companyCode: 147485928, codeId: 71 },
  { companyCode: 147760566, codeId: 71 },
  { companyCode: 148040272, codeId: 71 },
  { companyCode: 148328157, codeId: 71 },
  { companyCode: 151005737, codeId: 71 },
  { companyCode: 153267923, codeId: 71 },
  { companyCode: 154111083, codeId: 71 },
  { companyCode: 156600094, codeId: 71 },
  { companyCode: 157583833, codeId: 71 },
  { companyCode: 158320447, codeId: 71 },
  { companyCode: 160288387, codeId: 71 },
  { companyCode: 161651197, codeId: 71 },
  { companyCode: 164301437, codeId: 71 },
  { companyCode: 166445258, codeId: 71 },
  { companyCode: 166711075, codeId: 71 },
  { companyCode: 167357423, codeId: 71 },
  { companyCode: 167361083, codeId: 71 },
  { companyCode: 167392890, codeId: 71 },
  { companyCode: 168688134, codeId: 71 },
  { companyCode: 168704033, codeId: 71 },
  { companyCode: 169189593, codeId: 71 },
  { companyCode: 169269687, codeId: 71 },
  { companyCode: 169700486, codeId: 71 },
  { companyCode: 170072719, codeId: 71 },
  { companyCode: 171704584, codeId: 71 },
  { companyCode: 173181894, codeId: 71 },
  { companyCode: 176597054, codeId: 71 },
  { companyCode: 179437382, codeId: 71 },
  { companyCode: 179747363, codeId: 71 },
  { companyCode: 179761936, codeId: 71 },
  { companyCode: 179858698, codeId: 71 },
  { companyCode: 179873458, codeId: 71 },
  { companyCode: 180200843, codeId: 71 },
  { companyCode: 182814975, codeId: 71 },
  { companyCode: 183694870, codeId: 71 },
  { companyCode: 183728582, codeId: 71 },
  { companyCode: 184204892, codeId: 71 },
  { companyCode: 185472854, codeId: 71 },
  { companyCode: 190272175, codeId: 71 },
  { companyCode: 220074960, codeId: 71 },
  { companyCode: 221904380, codeId: 71 },
  { companyCode: 232112130, codeId: 71 },
  { companyCode: 234706950, codeId: 71 },
  { companyCode: 240752870, codeId: 71 },
  { companyCode: 247025610, codeId: 71 },
  { companyCode: 281324460, codeId: 71 },
  { companyCode: 300007204, codeId: 71 },
  { companyCode: 300010887, codeId: 71 },
  { companyCode: 300012386, codeId: 71 },
  { companyCode: 300017230, codeId: 71 },
  { companyCode: 300032759, codeId: 71 },
  { companyCode: 300037592, codeId: 71 },
  { companyCode: 300059820, codeId: 71 },
  { companyCode: 300064237, codeId: 71 },
  { companyCode: 300069533, codeId: 71 },
  { companyCode: 300096110, codeId: 71 },
  { companyCode: 300107133, codeId: 71 },
  { companyCode: 300110097, codeId: 71 },
  { companyCode: 300138335, codeId: 71 },
  { companyCode: 300153045, codeId: 71 },
  { companyCode: 300548925, codeId: 71 },
  { companyCode: 300568358, codeId: 71 },
  { companyCode: 300571938, codeId: 71 },
  { companyCode: 300583068, codeId: 71 },
  { companyCode: 300586388, codeId: 71 },
  { companyCode: 300589039, codeId: 71 },
  { companyCode: 300594367, codeId: 71 },
  { companyCode: 300598006, codeId: 71 },
  { companyCode: 300608393, codeId: 71 },
  { companyCode: 300608404, codeId: 71 },
  { companyCode: 300613159, codeId: 71 },
  { companyCode: 300621750, codeId: 71 },
  { companyCode: 300624294, codeId: 71 },
  { companyCode: 300631068, codeId: 71 },
  { companyCode: 300638739, codeId: 71 },
  { companyCode: 300823481, codeId: 71 },
  { companyCode: 300835462, codeId: 71 },
  { companyCode: 300838437, codeId: 71 },
  { companyCode: 300869607, codeId: 71 },
  { companyCode: 300928571, codeId: 71 },
  { companyCode: 301179566, codeId: 71 },
  { companyCode: 301258679, codeId: 71 },
  { companyCode: 301507162, codeId: 71 },
  { companyCode: 301519549, codeId: 71 },
  { companyCode: 301534615, codeId: 71 },
  { companyCode: 301540739, codeId: 71 },
  { companyCode: 301572740, codeId: 71 },
  { companyCode: 301574549, codeId: 71 },
  { companyCode: 301992534, codeId: 71 },
  { companyCode: 302171565, codeId: 71 },
  { companyCode: 302243783, codeId: 71 },
  { companyCode: 302250984, codeId: 71 },
  { companyCode: 302329061, codeId: 71 },
  { companyCode: 302422409, codeId: 71 },
  { companyCode: 302428586, codeId: 71 },
  { companyCode: 302445137, codeId: 71 },
  { companyCode: 302470717, codeId: 71 },
  { companyCode: 302476741, codeId: 71 },
  { companyCode: 302490189, codeId: 71 },
  { companyCode: 302490303, codeId: 71 },
  { companyCode: 302506362, codeId: 71 },
  { companyCode: 302508769, codeId: 71 },
  { companyCode: 302513232, codeId: 71 },
  { companyCode: 302521503, codeId: 71 },
  { companyCode: 302525505, codeId: 71 },
  { companyCode: 302529158, codeId: 71 },
  { companyCode: 302568111, codeId: 71 },
  { companyCode: 302597757, codeId: 71 },
  { companyCode: 302600210, codeId: 71 },
  { companyCode: 302603555, codeId: 71 },
  { companyCode: 302625936, codeId: 71 },
  { companyCode: 302632051, codeId: 71 },
  { companyCode: 302637963, codeId: 71 },
  { companyCode: 302658719, codeId: 71 },
  { companyCode: 302682645, codeId: 71 },
  { companyCode: 302696755, codeId: 71 },
  { companyCode: 302711972, codeId: 71 },
  { companyCode: 302713254, codeId: 71 },
  { companyCode: 302713350, codeId: 71 },
  { companyCode: 302739644, codeId: 71 },
  { companyCode: 302743767, codeId: 71 },
  { companyCode: 302744431, codeId: 71 },
  { companyCode: 302759668, codeId: 71 },
  { companyCode: 302760567, codeId: 71 },
  { companyCode: 302798450, codeId: 71 },
  { companyCode: 302803666, codeId: 71 },
  { companyCode: 302805578, codeId: 71 },
  { companyCode: 302808364, codeId: 71 },
  { companyCode: 302809434, codeId: 71 },
  { companyCode: 302818796, codeId: 71 },
  { companyCode: 302822905, codeId: 71 },
  { companyCode: 302829344, codeId: 71 },
  { companyCode: 302839349, codeId: 71 },
  { companyCode: 302842886, codeId: 71 },
  { companyCode: 302843504, codeId: 71 },
  { companyCode: 302862369, codeId: 71 },
  { companyCode: 302868500, codeId: 71 },
  { companyCode: 302874492, codeId: 71 },
  { companyCode: 302879984, codeId: 71 },
  { companyCode: 302880043, codeId: 71 },
  { companyCode: 302880082, codeId: 71 },
  { companyCode: 302888798, codeId: 71 },
  { companyCode: 302901352, codeId: 71 },
  { companyCode: 302923758, codeId: 71 },
  { companyCode: 302962451, codeId: 71 },
  { companyCode: 302997541, codeId: 71 },
  { companyCode: 303048804, codeId: 71 },
  { companyCode: 303064324, codeId: 71 },
  { companyCode: 303078263, codeId: 71 },
  { companyCode: 303151398, codeId: 71 },
  { companyCode: 303166069, codeId: 71 },
  { companyCode: 303192320, codeId: 71 },
  { companyCode: 303198113, codeId: 71 },
  { companyCode: 303289488, codeId: 71 },
  { companyCode: 303295135, codeId: 71 },
  { companyCode: 303354879, codeId: 71 },
  { companyCode: 303391030, codeId: 71 },
  { companyCode: 303391977, codeId: 71 },
  { companyCode: 303499910, codeId: 71 },
  { companyCode: 303680857, codeId: 71 },
  { companyCode: 303930862, codeId: 71 },
  { companyCode: 304040632, codeId: 71 },
  { companyCode: 304061377, codeId: 71 },
  { companyCode: 304116090, codeId: 71 },
  { companyCode: 304116343, codeId: 71 },
  { companyCode: 304130019, codeId: 71 },
  { companyCode: 304142847, codeId: 71 },
  { companyCode: 304156633, codeId: 71 },
  { companyCode: 304197352, codeId: 71 },
  { companyCode: 304207857, codeId: 71 },
  { companyCode: 304221287, codeId: 71 },
  { companyCode: 304419618, codeId: 71 },
  { companyCode: 304421380, codeId: 71 },
  { companyCode: 304422272, codeId: 71 },
  { companyCode: 304435608, codeId: 71 },
  { companyCode: 304439193, codeId: 71 },
  { companyCode: 304470477, codeId: 71 },
  { companyCode: 304516597, codeId: 71 },
  { companyCode: 304522657, codeId: 71 },
  { companyCode: 304545467, codeId: 71 },
  { companyCode: 304707759, codeId: 71 },
  { companyCode: 304774779, codeId: 71 },
  { companyCode: 304936925, codeId: 71 },
  { companyCode: 304962749, codeId: 71 },
  { companyCode: 304973553, codeId: 71 },
  { companyCode: 305015952, codeId: 71 },
  { companyCode: 305031508, codeId: 71 },
  { companyCode: 305161697, codeId: 71 },
  { companyCode: 305345455, codeId: 71 },
  { companyCode: 305434669, codeId: 71 },
  { companyCode: 305448409, codeId: 71 },
  { companyCode: 305467555, codeId: 71 },
  { companyCode: 305657145, codeId: 71 },
  { companyCode: 305706603, codeId: 71 },
  { companyCode: 305743142, codeId: 71 },
  { companyCode: 305894308, codeId: 71 },
  { companyCode: 305894308, codeId: 75 },
  { companyCode: 37310310480, codeId: 71 },
  { companyCode: 37408110637, codeId: 71 },
  { companyCode: 38005151387, codeId: 71 },
  { companyCode: 301950535, codeId: 72 },
  { companyCode: 302465307, codeId: 72 },
  { companyCode: 304484249, codeId: 72 },
  { companyCode: 304844074, codeId: 72 },
  { companyCode: 110087517, codeId: 75 },
  { companyCode: 120545849, codeId: 75 },
  { companyCode: 122796063, codeId: 75 },
  { companyCode: 124010840, codeId: 75 },
  { companyCode: 140089260, codeId: 75 },
  { companyCode: 144133366, codeId: 75 },
  { companyCode: 147104754, codeId: 75 },
  { companyCode: 149566841, codeId: 75 },
  { companyCode: 151104226, codeId: 75 },
  { companyCode: 152447391, codeId: 75 },
  { companyCode: 152767676, codeId: 75 },
  { companyCode: 153720195, codeId: 75 },
  { companyCode: 154138664, codeId: 75 },
  { companyCode: 154850665, codeId: 75 },
  { companyCode: 155461670, codeId: 75 },
  { companyCode: 158275315, codeId: 75 },
  { companyCode: 159702357, codeId: 75 },
  { companyCode: 161186428, codeId: 75 },
  { companyCode: 162559136, codeId: 75 },
  { companyCode: 163182663, codeId: 75 },
  { companyCode: 163994426, codeId: 75 },
  { companyCode: 165695198, codeId: 75 },
  { companyCode: 166092559, codeId: 75 },
  { companyCode: 166486116, codeId: 75 },
  { companyCode: 167922698, codeId: 75 },
  { companyCode: 169845485, codeId: 75 },
  { companyCode: 172380181, codeId: 75 },
  { companyCode: 173820527, codeId: 75 },
  { companyCode: 176523470, codeId: 75 },
  { companyCode: 177059215, codeId: 75 },
  { companyCode: 179249836, codeId: 75 },
  { companyCode: 180153137, codeId: 75 },
  { companyCode: 182743364, codeId: 75 },
  { companyCode: 186442084, codeId: 75 },
  { companyCode: 253255950, codeId: 75 },
  { companyCode: 256564350, codeId: 75 },
  { companyCode: 300151902, codeId: 75 },
  { companyCode: 301500997, codeId: 75 },
  { companyCode: 302555797, codeId: 75 },
  { companyCode: 302644274, codeId: 75 },
  { companyCode: 302703541, codeId: 75 },
  { companyCode: 304414973, codeId: 75 },
  { companyCode: 305208150, codeId: 75 },
  { codeId: 77, companyCode: 110894795 },
  { codeId: 78, companyCode: 111657920 },
  { codeId: 80, companyCode: 111697459 },
  { codeId: 80, companyCode: 111771518 },
  { codeId: 77, companyCode: 120378325 },
  { codeId: 79, companyCode: 121721595 },
  { codeId: 78, companyCode: 122773794 },
  { codeId: 77, companyCode: 123920012 },
  { codeId: 77, companyCode: 124286867 },
  { codeId: 77, companyCode: 125244928 },
  { codeId: 76, companyCode: 125244928 },
  { codeId: 79, companyCode: 125244928 },
  { codeId: 77, companyCode: 125775028 },
  { codeId: 80, companyCode: 125846781 },
  { codeId: 77, companyCode: 125916233 },
  { codeId: 80, companyCode: 125916233 },
  { codeId: 80, companyCode: 125959067 },
  { codeId: 77, companyCode: 132340880 },
  { codeId: 80, companyCode: 132340880 },
  { codeId: 78, companyCode: 132882429 },
  { codeId: 77, companyCode: 134972359 },
  { codeId: 77, companyCode: 135634182 },
  { codeId: 77, companyCode: 135680411 },
  { codeId: 77, companyCode: 135738170 },
  { codeId: 78, companyCode: 140548023 },
  { codeId: 79, companyCode: 140548023 },
  { codeId: 78, companyCode: 142003527 },
  { codeId: 77, companyCode: 144315778 },
  { codeId: 77, companyCode: 144535557 },
  { codeId: 77, companyCode: 145297468 },
  { codeId: 77, companyCode: 145450280 },
  { codeId: 77, companyCode: 145541246 },
  { codeId: 77, companyCode: 145776785 },
  { codeId: 80, companyCode: 148426263 },
  { codeId: 80, companyCode: 151289515 },
  { codeId: 80, companyCode: 152979693 },
  { codeId: 80, companyCode: 153656134 },
  { codeId: 80, companyCode: 154162626 },
  { codeId: 80, companyCode: 154179675 },
  { codeId: 78, companyCode: 154189598 },
  { codeId: 77, companyCode: 154315958 },
  { codeId: 80, companyCode: 154742821 },
  { codeId: 80, companyCode: 154780537 },
  { codeId: 80, companyCode: 154881372 },
  { codeId: 78, companyCode: 155307117 },
  { codeId: 78, companyCode: 156628581 },
  { codeId: 80, companyCode: 156913032 },
  { codeId: 79, companyCode: 156913032 },
  { codeId: 77, companyCode: 157544798 },
  { codeId: 80, companyCode: 157544798 },
  { codeId: 80, companyCode: 157553466 },
  { codeId: 80, companyCode: 157562515 },
  { codeId: 77, companyCode: 157564142 },
  { codeId: 80, companyCode: 157564142 },
  { codeId: 80, companyCode: 157567590 },
  { codeId: 80, companyCode: 157573910 },
  { codeId: 79, companyCode: 157575167 },
  { codeId: 80, companyCode: 158174818 },
  { codeId: 78, companyCode: 158203935 },
  { codeId: 80, companyCode: 158257445 },
  { codeId: 77, companyCode: 158257445 },
  { codeId: 79, companyCode: 158886370 },
  { codeId: 79, companyCode: 158891218 },
  { codeId: 77, companyCode: 158898910 },
  { codeId: 79, companyCode: 158898910 },
  { codeId: 77, companyCode: 158902846 },
  { codeId: 79, companyCode: 158902846 },
  { codeId: 77, companyCode: 159723337 },
  { codeId: 80, companyCode: 159723337 },
  { codeId: 80, companyCode: 159724624 },
  { codeId: 80, companyCode: 159738225 },
  { codeId: 77, companyCode: 159739850 },
  { codeId: 77, companyCode: 160204019 },
  { codeId: 80, companyCode: 161228959 },
  { codeId: 80, companyCode: 161270029 },
  { codeId: 80, companyCode: 161272418 },
  { codeId: 80, companyCode: 161274383 },
  { codeId: 80, companyCode: 161274426 },
  { codeId: 80, companyCode: 161282483 },
  { codeId: 77, companyCode: 161282526 },
  { codeId: 80, companyCode: 161282526 },
  { codeId: 80, companyCode: 161298135 },
  { codeId: 77, companyCode: 161453347 },
  { codeId: 80, companyCode: 161640934 },
  { codeId: 80, companyCode: 161642038 },
  { codeId: 80, companyCode: 161650095 },
  { codeId: 80, companyCode: 162463248 },
  { codeId: 80, companyCode: 162515168 },
  { codeId: 80, companyCode: 162535950 },
  { codeId: 80, companyCode: 162727228 },
  { codeId: 78, companyCode: 163208841 },
  { codeId: 77, companyCode: 163338952 },
  { codeId: 80, companyCode: 163338952 },
  { codeId: 78, companyCode: 163338952 },
  { codeId: 79, companyCode: 163338952 },
  { codeId: 77, companyCode: 163713562 },
  { codeId: 80, companyCode: 163713562 },
  { codeId: 78, companyCode: 163731247 },
  { codeId: 78, companyCode: 164247060 },
  { codeId: 80, companyCode: 164684042 },
  { codeId: 80, companyCode: 164818064 },
  { codeId: 80, companyCode: 165243360 },
  { codeId: 77, companyCode: 165601811 },
  { codeId: 77, companyCode: 165663236 },
  { codeId: 80, companyCode: 165663236 },
  { codeId: 80, companyCode: 165663421 },
  { codeId: 80, companyCode: 165664142 },
  { codeId: 77, companyCode: 165664861 },
  { codeId: 80, companyCode: 165664861 },
  { codeId: 80, companyCode: 165666499 },
  { codeId: 80, companyCode: 165668169 },
  { codeId: 80, companyCode: 165669794 },
  { codeId: 80, companyCode: 165670049 },
  { codeId: 77, companyCode: 166736071 },
  { codeId: 80, companyCode: 167527719 },
  { codeId: 80, companyCode: 167541334 },
  { codeId: 77, companyCode: 167607955 },
  { codeId: 80, companyCode: 167928384 },
  { codeId: 80, companyCode: 167936331 },
  { codeId: 77, companyCode: 167938154 },
  { codeId: 80, companyCode: 167938154 },
  { codeId: 80, companyCode: 167938688 },
  { codeId: 80, companyCode: 167939637 },
  { codeId: 80, companyCode: 167940372 },
  { codeId: 77, companyCode: 167994982 },
  { codeId: 80, companyCode: 167994982 },
  { codeId: 80, companyCode: 167995212 },
  { codeId: 80, companyCode: 167995746 },
  { codeId: 80, companyCode: 168521815 },
  { codeId: 77, companyCode: 168522721 },
  { codeId: 80, companyCode: 168522721 },
  { codeId: 80, companyCode: 168536660 },
  { codeId: 80, companyCode: 168548972 },
  { codeId: 80, companyCode: 168563166 },
  { codeId: 80, companyCode: 168573274 },
  { codeId: 80, companyCode: 168577867 },
  { codeId: 80, companyCode: 168689193 },
  { codeId: 77, companyCode: 168946951 },
  { codeId: 80, companyCode: 168970685 },
  { codeId: 80, companyCode: 169149150 },
  { codeId: 80, companyCode: 169154521 },
  { codeId: 80, companyCode: 169156159 },
  { codeId: 77, companyCode: 169164978 },
  { codeId: 80, companyCode: 169164978 },
  { codeId: 78, companyCode: 169203146 },
  { codeId: 79, companyCode: 169280769 },
  { codeId: 80, companyCode: 169282439 },
  { codeId: 80, companyCode: 169284251 },
  { codeId: 80, companyCode: 169852483 },
  { codeId: 77, companyCode: 170662260 },
  { codeId: 80, companyCode: 170662260 },
  { codeId: 80, companyCode: 170665185 },
  { codeId: 78, companyCode: 171286494 },
  { codeId: 80, companyCode: 171295543 },
  { codeId: 80, companyCode: 171305165 },
  { codeId: 80, companyCode: 171306071 },
  { codeId: 80, companyCode: 171314933 },
  { codeId: 80, companyCode: 171327432 },
  { codeId: 80, companyCode: 171331516 },
  { codeId: 78, companyCode: 171647489 },
  { codeId: 80, companyCode: 172270144 },
  { codeId: 77, companyCode: 172272871 },
  { codeId: 80, companyCode: 172290937 },
  { codeId: 77, companyCode: 172788069 },
  { codeId: 80, companyCode: 173086898 },
  { codeId: 78, companyCode: 173732714 },
  { codeId: 80, companyCode: 173837423 },
  { codeId: 80, companyCode: 174274846 },
  { codeId: 77, companyCode: 174285675 },
  { codeId: 80, companyCode: 174285675 },
  { codeId: 80, companyCode: 174286058 },
  { codeId: 80, companyCode: 174286439 },
  { codeId: 80, companyCode: 174286581 },
  { codeId: 80, companyCode: 174292335 },
  { codeId: 80, companyCode: 174317183 },
  { codeId: 80, companyCode: 174401546 },
  { codeId: 80, companyCode: 174929230 },
  { codeId: 80, companyCode: 174931263 },
  { codeId: 77, companyCode: 175682345 },
  { codeId: 80, companyCode: 175706853 },
  { codeId: 80, companyCode: 175708861 },
  { codeId: 79, companyCode: 175712183 },
  { codeId: 79, companyCode: 175712411 },
  { codeId: 80, companyCode: 175727833 },
  { codeId: 80, companyCode: 175728892 },
  { codeId: 80, companyCode: 175729656 },
  { codeId: 80, companyCode: 177005858 },
  { codeId: 77, companyCode: 177076474 },
  { codeId: 80, companyCode: 177076474 },
  { codeId: 80, companyCode: 177078678 },
  { codeId: 80, companyCode: 177095784 },
  { codeId: 80, companyCode: 177103779 },
  { codeId: 80, companyCode: 177223814 },
  { codeId: 77, companyCode: 177324126 },
  { codeId: 79, companyCode: 177380388 },
  { codeId: 78, companyCode: 178656996 },
  { codeId: 77, companyCode: 178862455 },
  { codeId: 77, companyCode: 179342781 },
  { codeId: 80, companyCode: 179342781 },
  { codeId: 77, companyCode: 179907017 },
  { codeId: 78, companyCode: 180255117 },
  { codeId: 77, companyCode: 180715277 },
  { codeId: 80, companyCode: 181241113 },
  { codeId: 79, companyCode: 181323215 },
  { codeId: 80, companyCode: 182765631 },
  { codeId: 77, companyCode: 182779570 },
  { codeId: 77, companyCode: 182844057 },
  { codeId: 77, companyCode: 183118052 },
  { codeId: 80, companyCode: 183118052 },
  { codeId: 79, companyCode: 183131338 },
  { codeId: 79, companyCode: 183224792 },
  { codeId: 77, companyCode: 183674120 },
  { codeId: 77, companyCode: 183845322 },
  { codeId: 79, companyCode: 183845322 },
  { codeId: 77, companyCode: 184550047 },
  { codeId: 77, companyCode: 186066568 },
  { codeId: 77, companyCode: 186082768 },
  { codeId: 79, companyCode: 186107463 },
  { codeId: 77, companyCode: 186470062 },
  { codeId: 78, companyCode: 186489543 },
  { codeId: 79, companyCode: 187834298 },
  { codeId: 77, companyCode: 188600743 },
  { codeId: 80, companyCode: 188601279 },
  { codeId: 77, companyCode: 188737980 },
  { codeId: 77, companyCode: 190060724 },
  { codeId: 77, companyCode: 190087696 },
  { codeId: 77, companyCode: 190464695 },
  { codeId: 77, companyCode: 190539984 },
  { codeId: 77, companyCode: 190599771 },
  { codeId: 76, companyCode: 190599771 },
  { codeId: 79, companyCode: 190599771 },
  { codeId: 77, companyCode: 190650842 },
  { codeId: 77, companyCode: 190757036 },
  { codeId: 77, companyCode: 190757221 },
  { codeId: 80, companyCode: 190757221 },
  { codeId: 77, companyCode: 190797664 },
  { codeId: 77, companyCode: 190804219 },
  { codeId: 78, companyCode: 190804219 },
  { codeId: 77, companyCode: 190862717 },
  { codeId: 77, companyCode: 190977872 },
  { codeId: 77, companyCode: 191176774 },
  { codeId: 80, companyCode: 191176774 },
  { codeId: 77, companyCode: 191353153 },
  { codeId: 77, companyCode: 191395451 },
  { codeId: 77, companyCode: 191716918 },
  { codeId: 77, companyCode: 192050878 },
  { codeId: 77, companyCode: 193298937 },
  { codeId: 77, companyCode: 193412757 },
  { codeId: 77, companyCode: 211950810 },
  { codeId: 77, companyCode: 234758790 },
  { codeId: 77, companyCode: 253086190 },
  { codeId: 77, companyCode: 256605060 },
  { codeId: 80, companyCode: 256605060 },
  { codeId: 78, companyCode: 256605060 },
  { codeId: 80, companyCode: 265670420 },
  { codeId: 80, companyCode: 267935230 },
  { codeId: 80, companyCode: 268565360 },
  { codeId: 80, companyCode: 268573460 },
  { codeId: 77, companyCode: 268685210 },
  { codeId: 77, companyCode: 269159640 },
  { codeId: 80, companyCode: 269159640 },
  { codeId: 79, companyCode: 273114380 },
  { codeId: 77, companyCode: 274588310 },
  { codeId: 80, companyCode: 274588310 },
  { codeId: 77, companyCode: 278231850 },
  { codeId: 80, companyCode: 284268020 },
  { codeId: 77, companyCode: 284563070 },
  { codeId: 80, companyCode: 284563070 },
  { codeId: 78, companyCode: 284563070 },
  { codeId: 76, companyCode: 284563070 },
  { codeId: 79, companyCode: 284563070 },
  { codeId: 77, companyCode: 286072650 },
  { codeId: 77, companyCode: 293057830 },
  { codeId: 79, companyCode: 300030822 },
  { codeId: 80, companyCode: 300042073 },
  { codeId: 80, companyCode: 300056646 },
  { codeId: 77, companyCode: 300065549 },
  { codeId: 77, companyCode: 300065652 },
  { codeId: 77, companyCode: 300066494 },
  { codeId: 77, companyCode: 300073211 },
  { codeId: 79, companyCode: 300073371 },
  { codeId: 77, companyCode: 300088277 },
  { codeId: 79, companyCode: 300092247 },
  { codeId: 80, companyCode: 300150191 },
  { codeId: 80, companyCode: 300500898 },
  { codeId: 77, companyCode: 300502066 },
  { codeId: 77, companyCode: 300505023 },
  { codeId: 77, companyCode: 300556199 },
  { codeId: 79, companyCode: 300575438 },
  { codeId: 80, companyCode: 300584138 },
  { codeId: 80, companyCode: 300593678 },
  { codeId: 77, companyCode: 300605625 },
  { codeId: 77, companyCode: 300605803 },
  { codeId: 80, companyCode: 300634986 },
  { codeId: 77, companyCode: 300657593 },
  { codeId: 77, companyCode: 300662117 },
  { codeId: 77, companyCode: 300855707 },
  { codeId: 80, companyCode: 300876562 },
  { codeId: 77, companyCode: 300890925 },
  { codeId: 80, companyCode: 300899714 },
  { codeId: 78, companyCode: 301166540 },
  { codeId: 77, companyCode: 301259539 },
  { codeId: 80, companyCode: 301259539 },
  { codeId: 80, companyCode: 301565824 },
  { codeId: 77, companyCode: 301651554 },
  { codeId: 80, companyCode: 301733624 },
  { codeId: 77, companyCode: 301741457 },
  { codeId: 77, companyCode: 301868682 },
  { codeId: 77, companyCode: 302101531 },
  { codeId: 77, companyCode: 302249117 },
  { codeId: 80, companyCode: 302296985 },
  { codeId: 77, companyCode: 302303725 },
  { codeId: 79, companyCode: 302307346 },
  { codeId: 77, companyCode: 302310844 },
  { codeId: 77, companyCode: 302315688 },
  { codeId: 77, companyCode: 302316224 },
  { codeId: 77, companyCode: 302318385 },
  { codeId: 77, companyCode: 302328194 },
  { codeId: 77, companyCode: 302351182 },
  { codeId: 77, companyCode: 302412475 },
  { codeId: 80, companyCode: 302412475 },
  { codeId: 77, companyCode: 302412952 },
  { codeId: 77, companyCode: 302414825 },
  { codeId: 77, companyCode: 302431671 },
  { codeId: 77, companyCode: 302442810 },
  { codeId: 77, companyCode: 302443691 },
  { codeId: 77, companyCode: 302454129 },
  { codeId: 80, companyCode: 302454129 },
  { codeId: 77, companyCode: 302465385 },
  { codeId: 77, companyCode: 302474352 },
  { codeId: 80, companyCode: 302474352 },
  { codeId: 76, companyCode: 302474352 },
  { codeId: 79, companyCode: 302474352 },
  { codeId: 77, companyCode: 302493192 },
  { codeId: 80, companyCode: 302493192 },
  { codeId: 80, companyCode: 302493840 },
  { codeId: 77, companyCode: 302497504 },
  { codeId: 80, companyCode: 302504404 },
  { codeId: 77, companyCode: 302514779 },
  { codeId: 77, companyCode: 302533295 },
  { codeId: 77, companyCode: 302536989 },
  { codeId: 78, companyCode: 302536989 },
  { codeId: 79, companyCode: 302536989 },
  { codeId: 77, companyCode: 302542547 },
  { codeId: 77, companyCode: 302554364 },
  { codeId: 77, companyCode: 302563662 },
  { codeId: 80, companyCode: 302563662 },
  { codeId: 77, companyCode: 302567867 },
  { codeId: 77, companyCode: 302569569 },
  { codeId: 77, companyCode: 302572960 },
  { codeId: 80, companyCode: 302581347 },
  { codeId: 77, companyCode: 302590428 },
  { codeId: 77, companyCode: 302601561 },
  { codeId: 80, companyCode: 302601839 },
  { codeId: 80, companyCode: 302604714 },
  { codeId: 77, companyCode: 302618062 },
  { codeId: 77, companyCode: 302620017 },
  { codeId: 77, companyCode: 302622573 },
  { codeId: 80, companyCode: 302622630 },
  { codeId: 77, companyCode: 302627015 },
  { codeId: 80, companyCode: 302630157 },
  { codeId: 80, companyCode: 302635752 },
  { codeId: 78, companyCode: 302648187 },
  { codeId: 77, companyCode: 302687261 },
  { codeId: 77, companyCode: 302690738 },
  { codeId: 80, companyCode: 302691509 },
  { codeId: 80, companyCode: 302705528 },
  { codeId: 78, companyCode: 302705528 },
  { codeId: 77, companyCode: 302710895 },
  { codeId: 80, companyCode: 302710895 },
  { codeId: 77, companyCode: 302728092 },
  { codeId: 80, companyCode: 302756914 },
  { codeId: 80, companyCode: 302764989 },
  { codeId: 77, companyCode: 302765009 },
  { codeId: 77, companyCode: 302771866 },
  { codeId: 80, companyCode: 302777666 },
  { codeId: 80, companyCode: 302779578 },
  { codeId: 77, companyCode: 302780908 },
  { codeId: 77, companyCode: 302785061 },
  { codeId: 77, companyCode: 302795881 },
  { codeId: 79, companyCode: 302811727 },
  { codeId: 77, companyCode: 302825260 },
  { codeId: 77, companyCode: 302848387 },
  { codeId: 77, companyCode: 302868735 },
  { codeId: 80, companyCode: 302868735 },
  { codeId: 80, companyCode: 302892597 },
  { codeId: 77, companyCode: 302904850 },
  { codeId: 77, companyCode: 302916151 },
  { codeId: 80, companyCode: 302961851 },
  { codeId: 80, companyCode: 303000752 },
  { codeId: 77, companyCode: 303011866 },
  { codeId: 77, companyCode: 303013290 },
  { codeId: 77, companyCode: 303021159 },
  { codeId: 80, companyCode: 303021159 },
  { codeId: 80, companyCode: 303025517 },
  { codeId: 80, companyCode: 303027361 },
  { codeId: 80, companyCode: 303037964 },
  { codeId: 77, companyCode: 303060703 },
  { codeId: 80, companyCode: 303062063 },
  { codeId: 80, companyCode: 303076878 },
  { codeId: 80, companyCode: 303093692 },
  { codeId: 80, companyCode: 303119009 },
  { codeId: 77, companyCode: 303128645 },
  { codeId: 80, companyCode: 303139442 },
  { codeId: 80, companyCode: 303142050 },
  { codeId: 79, companyCode: 303148039 },
  { codeId: 80, companyCode: 303151170 },
  { codeId: 80, companyCode: 303201011 },
  { codeId: 80, companyCode: 303211258 },
  { codeId: 80, companyCode: 303211799 },
  { codeId: 80, companyCode: 303213686 },
  { codeId: 77, companyCode: 303259420 },
  { codeId: 77, companyCode: 303270648 },
  { codeId: 77, companyCode: 303270751 },
  { codeId: 77, companyCode: 303271821 },
  { codeId: 80, companyCode: 303284644 },
  { codeId: 80, companyCode: 303308675 },
  { codeId: 77, companyCode: 303315883 },
  { codeId: 77, companyCode: 303324672 },
  { codeId: 80, companyCode: 303324672 },
  { codeId: 78, companyCode: 303340751 },
  { codeId: 77, companyCode: 303366963 },
  { codeId: 80, companyCode: 303367086 },
  { codeId: 80, companyCode: 303384107 },
  { codeId: 77, companyCode: 303406863 },
  { codeId: 77, companyCode: 303462470 },
  { codeId: 77, companyCode: 303476438 },
  { codeId: 77, companyCode: 303481517 },
  { codeId: 80, companyCode: 303496871 },
  { codeId: 77, companyCode: 303496946 },
  { codeId: 77, companyCode: 303497902 },
  { codeId: 80, companyCode: 303888388 },
  { codeId: 79, companyCode: 304051949 },
  { codeId: 80, companyCode: 304057980 },
  { codeId: 80, companyCode: 304060478 },
  { codeId: 80, companyCode: 304061384 },
  { codeId: 80, companyCode: 304064761 },
  { codeId: 80, companyCode: 304094092 },
  { codeId: 77, companyCode: 304150406 },
  { codeId: 77, companyCode: 304157308 },
  { codeId: 77, companyCode: 304164594 },
  { codeId: 80, companyCode: 304164594 },
  { codeId: 77, companyCode: 304172178 },
  { codeId: 77, companyCode: 304175391 },
  { codeId: 80, companyCode: 304224002 },
  { codeId: 77, companyCode: 304254967 },
  { codeId: 76, companyCode: 304254967 },
  { codeId: 79, companyCode: 304254967 },
  { codeId: 77, companyCode: 304260265 },
  { codeId: 79, companyCode: 304286744 },
  { codeId: 77, companyCode: 304289605 },
  { codeId: 77, companyCode: 304289861 },
  { codeId: 77, companyCode: 304399382 },
  { codeId: 77, companyCode: 304409054 },
  { codeId: 80, companyCode: 304409054 },
  { codeId: 77, companyCode: 304412940 },
  { codeId: 80, companyCode: 304416974 },
  { codeId: 80, companyCode: 304438928 },
  { codeId: 77, companyCode: 304447261 },
  { codeId: 77, companyCode: 304478381 },
  { codeId: 77, companyCode: 304540000 },
  { codeId: 78, companyCode: 304573865 },
  { codeId: 77, companyCode: 304600440 },
  { codeId: 80, companyCode: 304680454 },
  { codeId: 77, companyCode: 304680454 },
  { codeId: 77, companyCode: 304720393 },
  { codeId: 80, companyCode: 304720393 },
  { codeId: 80, companyCode: 304762531 },
  { codeId: 78, companyCode: 304771427 },
  { codeId: 77, companyCode: 304827114 },
  { codeId: 80, companyCode: 304827114 },
  { codeId: 79, companyCode: 304827114 },
  { codeId: 80, companyCode: 304827445 },
  { codeId: 79, companyCode: 304828127 },
  { codeId: 80, companyCode: 304840389 },
  { codeId: 78, companyCode: 304847939 },
  { codeId: 77, companyCode: 304887862 },
  { codeId: 77, companyCode: 304894910 },
  { codeId: 77, companyCode: 304909885 },
  { codeId: 77, companyCode: 304935969 },
  { codeId: 77, companyCode: 304979983 },
  { codeId: 80, companyCode: 304979983 },
  { codeId: 77, companyCode: 304996185 },
  { codeId: 80, companyCode: 305079143 },
  { codeId: 80, companyCode: 305098072 },
  { codeId: 77, companyCode: 305109437 },
  { codeId: 80, companyCode: 305109437 },
  { codeId: 80, companyCode: 305165959 },
  { codeId: 80, companyCode: 305167198 },
  { codeId: 79, companyCode: 305174577 },
  { codeId: 77, companyCode: 305214986 },
  { codeId: 77, companyCode: 305257642 },
  { codeId: 77, companyCode: 305287062 },
  { codeId: 77, companyCode: 305319909 },
  { codeId: 77, companyCode: 305393934 },
  { codeId: 76, companyCode: 305393934 },
  { codeId: 79, companyCode: 305393934 },
  { codeId: 77, companyCode: 305496891 },
  { codeId: 80, companyCode: 305496891 },
  { codeId: 77, companyCode: 305536503 },
  { codeId: 80, companyCode: 305561951 },
  { codeId: 80, companyCode: 305590497 },
  { codeId: 77, companyCode: 305598686 },
  { codeId: 79, companyCode: 305604018 },
  { codeId: 77, companyCode: 305618270 },
  { codeId: 80, companyCode: 305659018 },
  { codeId: 80, companyCode: 305666094 },
  { codeId: 77, companyCode: 305682928 },
  { codeId: 80, companyCode: 305778086 },
  { codeId: 77, companyCode: 305790131 },
  { codeId: 80, companyCode: 305824256 },
  { codeId: 77, companyCode: 305897133 },
  { codeId: 77, companyCode: 305945215 },
  { codeId: 77, companyCode: 305959777 },
  { codeId: 77, companyCode: 306013979 },
  { codeId: 77, companyCode: 306021100 },
  { codeId: 77, companyCode: 306109002 },
  { codeId: 79, companyCode: 306205082 },
  { codeId: 80, companyCode: 306286134 },
  { codeId: 77, companyCode: 371090459 },
  { codeId: 78, companyCode: 371090459 }
];

export const table = [
  {
    id: 1,
    label: "Gamybinio objekto arba jame vykdomos veiklos pavadinimas"
  },
  {
    id: 2,
    label: "Komunalinio objekto (įrenginio) pavadinimas"
  },
  {
    id: 3,
    label:
      "Pastatuose, kuriuose laikomi ūkiniai gyvūnai, nustatytų sutartinių gyvulių skaičius (SG)*",
    bottomLabel:
      "* Sutartinių gyvulių skaičius nustatomas perskaičiuojant gyvūnų skaičių Lietuvos Respublikos aplinkos ministro ir Lietuvos Respublikos žemės ūkio ministro nustatyta tvarka. "
  }
];

export const codes = [
  { parentCode: null, code: "06.10", id: 1, tableId: 1 },
  { parentCode: null, code: "06.20", id: 2, tableId: 1 },
  { parentCode: null, code: "10.1", id: 3, tableId: 1 },
  { parentCode: null, code: "10.2", id: 4, tableId: 1 },
  { parentCode: null, code: "10.4", id: 5, tableId: 1 },
  { parentCode: null, code: "10.61", id: 6, tableId: 1 },
  { parentCode: null, code: "10.62", id: 7, tableId: 1 },
  { parentCode: null, code: "10.81", id: 8, tableId: 1 },
  { parentCode: null, code: "10.9", id: 9, tableId: 1 },
  { parentCode: null, code: "15.11", id: 14, tableId: 1 },
  { parentCode: null, code: "16.1", id: 16, tableId: 1 },
  { parentCode: null, code: "16.21", id: 17, tableId: 1 },
  { parentCode: null, code: "17", id: 21, tableId: 1 },
  { parentCode: null, code: "19", id: 22, tableId: 1 },
  { parentCode: 22, code: "19.20", id: 23, tableId: 1 },
  { parentCode: null, code: "20.1", id: 24, tableId: 1 },
  { parentCode: null, code: "20.2", id: 25, tableId: 1 },
  { parentCode: null, code: "20.3", id: 26, tableId: 1 },
  { parentCode: null, code: "20.41", id: 27, tableId: 1 },
  { parentCode: null, code: "20.42", id: 28, tableId: 1 },
  { parentCode: null, code: "20.51", id: 29, tableId: 1 },
  { parentCode: null, code: "22.11", id: 32, tableId: 1 },
  { parentCode: null, code: "22.19", id: 33, tableId: 1 },
  { parentCode: null, code: "22.2", id: 34, tableId: 1 },
  { parentCode: null, code: "23.20", id: 36, tableId: 1 },
  { parentCode: null, code: "23.3", id: 37, tableId: 1 },
  { parentCode: null, code: "23.5", id: 39, tableId: 1 },
  { parentCode: null, code: "23.6", id: 40, tableId: 1 },
  { parentCode: null, code: "23.65", id: 41, tableId: 1 },
  { parentCode: null, code: "23.69", id: 42, tableId: 1 },
  { parentCode: null, code: "30.11", id: 50, tableId: 1 },
  { parentCode: null, code: "32.40.20", id: 55, tableId: 1 },
  { parentCode: null, code: "32.40.60", id: 56, tableId: 1 },
  { parentCode: null, code: "33.15", id: 57, tableId: 1 },
  { parentCode: null, code: "35.21", id: 59, tableId: 1 },
  { parentCode: null, code: "38.21", id: 61, tableId: 1 },
  { parentCode: null, code: "38.22", id: 62, tableId: 1 },
  {
    parentCode: null,
    code: "1",
    id: 65,
    tableId: 2
  },
  { id: 66, parentCode: null, code: "2", tableId: 2 },
  { id: 67, parentCode: null, code: "3", tableId: 2 },
  { id: 68, parentCode: null, code: "4", tableId: 2 },
  { id: 69, parentCode: null, code: "5", tableId: 2 },
  { id: 70, parentCode: null, code: "6", tableId: 2 },
  { id: 71, parentCode: null, code: "7", tableId: 2 },
  { id: 72, parentCode: null, code: "8", tableId: 2 },
  { id: 73, parentCode: null, code: "9", tableId: 2 },
  { id: 74, parentCode: null, code: "10", tableId: 2 },
  { id: 75, parentCode: null, code: "11", tableId: 2 },
  { id: 76, parentCode: null, code: "D1", tableId: 3 },
  { id: 77, parentCode: null, code: "D2", tableId: 3 },
  { id: 78, parentCode: null, code: "D3", tableId: 3 },
  { id: 79, parentCode: null, code: "D4", tableId: 3 },
  { id: 80, parentCode: null, code: "D5", tableId: 3 }
];

export const activities = [
  {
    id: 1,
    label: "Žalios naftos gavyba",
    area: "1000"
  },
  {
    id: 2,
    label: "Gamtinių dujų gavyba",
    area: "1000"
  },
  {
    id: 3,
    label: "Mėsos perdirbimas, konservavimas ir mėsos produktų gamyba:",
    area: ""
  },
  {
    id: 4,
    label:
      "objektai, turintys skerdyklas, skerdyklos, kurių gamybos pajėgumas – 5 ir daugiau tonų šviežios mėsos ir (ar) mėsos produktų per parą;",
    area: "200"
  },
  {
    id: 5,
    label:
      "objektai, turintys skerdyklas, skerdyklos, kurių gamybos pajėgumas – iki 5 tonų šviežios mėsos ir (ar) mėsos produktų per parą, išskyrus šios lentelės 3.3 papunktyje nurodytus objektus;",
    area: "100"
  },
  {
    id: 6,
    label:
      "objektai, turintys skerdyklas, skerdyklos, kurių gamybos pajėgumas – iki 3 tonų šviežios mėsos ir (ar) 0,7 tonos mėsos produktų per savaitę;",
    area: "50"
  },
  {
    id: 7,
    label:
      "objektai, neturintys skerdyklų, kurių gamybos pajėgumas – 5 ir daugiau tonų mėsos ir (ar) mėsos produktų per parą;",
    area: "100"
  },
  {
    id: 8,
    label:
      "objektai, neturintys skerdyklų, kurių gamybos pajėgumas – iki 5 tonų mėsos ir (ar) mėsos produktų per parą",
    area: "50"
  },
  {
    id: 9,
    label: "Žuvų perdirbimas ir konservavimas:",
    area: ""
  },
  {
    id: 10,
    label:
      "objektai, kurių gamybos pajėgumas – 5 ir daugiau tonų žuvies per parą;",
    area: "100"
  },
  {
    id: 11,
    label:
      "objektai, kurių gamybos pajėgumas – iki 5 tonų žuvies per parą, išskyrus šios lentelės 4.3 papunktyje nurodytus atvejus;",
    area: "50"
  },
  {
    id: 12,
    label:
      "objektai, kurių pajėgumas – 50 ir mažiau kilogramų žuvies per parą, kai gamyba vykdoma savivaldybės tarybos nustatyta tvarka",
    area: "0"
  },
  {
    id: 13,
    label:
      "Gyvūninių ir augalinių riebalų bei aliejaus gamyba, kai gamybos pajėgumas – 5 ir daugiau tonų per parą",
    area: "100"
  },
  {
    id: 14,
    label:
      "Grūdų malimo produktų gamyba, kai gamybos, kai pajėgumas – 5 ir daugiau tonų per parą ",
    area: "100"
  },
  {
    id: 15,
    label:
      "Krakmolo ir krakmolo produktų gamyba, kai gamybos pajėgumas – 5 ir daugiau tonų per parą",
    area: "100"
  },
  {
    id: 16,
    label: "Cukraus gamyba",
    area: "300"
  },
  {
    id: 17,
    label: "Paruoštų pašarų gyvuliams gamyba:",
    area: ""
  },
  {
    id: 18,
    label:
      "paruoštų pašarų gyvuliams gamyba, išskyrus šios lentelės 9.2 papunktyje nurodytus objektus;",
    area: "100"
  },
  {
    id: 19,
    label: "skerdyklų atliekų perdirbimas gyvūnų pašarui gaminti",
    area: "500"
  },

  {
    id: 26,
    label:
      "Odų rauginimas ir išdirbimas, kailių išdirbimas ir dažymas, kai gamybos pajėgumas – 500 m2 ir daugiau per parą ",
    area: "300"
  },

  {
    id: 28,
    label:
      "Medienos pjaustymas ir obliavimas, kai gamybos pajėgumas – 5 000 m2 (ar 50 m3) ir daugiau per parą",
    area: "100"
  },
  {
    id: 29,
    label: "Faneravimo dangos ir medienos plokščių gamyba:",
    area: ""
  },
  {
    id: 30,
    label:
      "faneravimo dangos ir medienos plokščių gamyba, išskyrus medienos plaušo plokščių, medienos drožlių plokščių, faneros gamybą;",
    area: "200"
  },
  {
    id: 31,
    label:
      "medienos plaušo plokščių gamyba, kai gamybos pajėgumas 5 000 m2 ir daugiau per parą;",
    area: "200"
  },
  {
    id: 32,
    label:
      "medienos drožlių plokščių gamyba, kai gamybos pajėgumas 100 m3 ir daugiau per parą;",
    area: "200"
  },
  {
    id: 33,
    label: "faneros gamyba, kai gamybos pajėgumas – 50 m3 ir daugiau per parą",
    area: "200"
  },

  {
    id: 37,
    label:
      "Popieriaus ir popieriaus gaminių gamyba, kai gamybos pajėgumas – 20 ir daugiau tonų per parą",
    area: "100"
  },
  {
    id: 38,
    label:
      "Kokso ir rafinuotų naftos produktų gamyba, išskyrus biokuro maišymą (etanolio maišymą su naftos produktais), durpių briketų gamybą",
    area: "1000"
  },
  {
    id: 39,
    label: "Biokuro maišymas (etanolio maišymas su naftos produktais)",
    area: "300"
  },
  {
    id: 40,
    label: "Durpių briketų gamyba",
    area: "200"
  },
  {
    id: 41,
    label:
      "Pagrindinių chemikalų, trąšų ir azoto junginių, pirminių plastikų ir pirminio sintetinio kaučiuko gamyba",
    area: "500"
  },
  {
    id: 42,
    label: "Augalų apsaugos produktų ir kitų agrocheminių medžiagų gamyba",
    area: "500"
  },
  {
    id: 43,
    label:
      "Dažų, lakų ir panašių dangų medžiagų, spaustuvinių dažų ir mastikų gamyba:",
    area: ""
  },
  {
    id: 44,
    label: "kai gamybos pajėgumas – 10 ir daugiau tonų per parą;",
    area: "300"
  },
  {
    id: 46,
    label:
      "Muilo ir ploviklių, valiklių ir blizgiklių gamyba, kai gamybos pajėgumas – 10 ir daugiau tonų per parą",
    area: "100"
  },
  {
    id: 47,
    label: "Kvepalų ir tualeto priemonių gamyba:",
    area: ""
  },
  {
    id: 48,
    label: "kai gamybos pajėgumas – 5 ir daugiau tonų per parą;",
    area: "100"
  },
  {
    id: 49,
    label: "kai gamybos pajėgumas – nuo 0,5 iki 5 tonų per parą",
    area: "50"
  },
  {
    id: 50,
    label: "Sprogiųjų medžiagų gamyba",
    area: "300"
  },

  {
    id: 53,
    label: "Guminių gaminių gamyba ir perdirbimas (atgavimas):",
    area: ""
  },
  {
    id: 54,
    label:
      "guminių padangų ir kamerų gamyba, guminių padangų restauravimas ir atnaujinimas, kitų guminių gaminių gamyba, kai gamybos pajėgumas – 15 ir daugiau tonų per parą;",
    area: "300"
  },
  {
    id: 55,
    label:
      "guminių padangų ir kamerų gamyba, guminių padangų restauravimas ir atnaujinimas, kitų guminių gaminių gamyba, kai gamybos pajėgumas – nuo vienos iki 15 tonų per parą;",
    area: "100"
  },
  {
    id: 56,
    label: "guminių gaminių perdirbimas (atgavimas)",
    area: "300"
  },
  {
    id: 57,
    label: "Plastikinių gaminių gamyba ir perdirbimas (atgavimas):",
    area: ""
  },
  {
    id: 58,
    label:
      "plastikinių gaminių gamyba, plastikinių žaislų gamyba, plastikinių triračių ir kitų žaislų su ratukais gamyba, kur naudojama sintezė, kai gamybos pajėgumas – viena ir daugiau tonų per parą;",
    area: "300"
  },
  {
    id: 59,
    label:
      "plastikinių gaminių gamyba, plastikinių žaislų gamyba, plastikinių triračių ir kitų žaislų su ratukais gamyba, kur nenaudojama sintezė, kai gamybos pajėgumas – viena ir daugiau tonų per parą;",
    area: "150"
  },
  {
    id: 60,
    label: "plastikinių gaminių perdirbimas (atgavimas)",
    area: "300"
  },

  {
    id: 64,
    label:
      "Ugniai atsparių gaminių gamyba, kai gamybos pajėgumas – 10 ir daugiau tonų per parą",
    area: "100"
  },
  {
    id: 65,
    label:
      "Statybinių medžiagų iš molio gamyba, kai gamybos pajėgumas – 10 ir daugiau tonų per parą",
    area: "100"
  },

  {
    id: 67,
    label:
      "Cemento, kalkių ir gipso gamyba, kai gamybos pajėgumas – 10 ir daugiau tonų per parą",
    area: "1000"
  },
  {
    id: 68,
    label: "Betono, cemento ir gipso gaminių bei dirbinių gamyba:",
    area: ""
  },
  {
    id: 69,
    label:
      "betono, cemento ir gipso gaminių bei dirbinių gamyba, kai gamybos pajėgumas – daugiau kaip 5 000 m3 per metus, išskyrus šios lentelės 28.2 papunktyje nurodytus objektus;",
    area: "100"
  },
  {
    id: 70,
    label:
      "fibrolito gamyba, kitų betono, gipso ir cemento gaminių bei dirbinių gamyba, kai gamybos pajėgumas – viena ir daugiau tonų per parą",
    area: "100"
  },

  {
    id: 79,
    label: "Laivų ir plūdriųjų konstrukcijų statyba ir remontas",
    area: "100"
  },

  {
    id: 83,
    label: "Elektros gamyba, garo tiekimas ir oro kondicionavimas:",
    area: ""
  },
  {
    id: 84,
    label:
      "katilinių, šiluminių elektrinių, kogeneracinių elektrinių, kurių suminė vardinė (nominali) įrenginių šiluminė galia yra 50 MW ir didesnė",
    area: "100"
  },
  {
    id: 85,
    label: "Dujų gamyba:",
    area: ""
  },
  {
    id: 86,
    label: "dujų, išskyrus biodujas, gamyba;",
    area: "500"
  },
  {
    id: 87,
    label: "biodujų gamyba ",
    area: "200"
  },
  {
    id: 88,
    label:
      "Užkrėstų gyvų ar kritusių gyvūnų, taip pat gyvūninės kilmės atliekų apdorojimas ir šalinimas ",
    area: "500"
  },

  { id: 93, label: "Atliekų deginimo įrenginys", area: "500" },
  { id: 94, label: "Nepavojingųjų atliekų sąvartynas", area: "500" },
  { id: 95, label: "Pavojingųjų atliekų sąvartynas", area: "500" },
  { id: 96, label: "Inertinių atliekų sąvartynas", area: "200" },
  {
    id: 97,
    label:
      "Biologiškai skaidžių atliekų kompostavimo aikštelė (be maisto atliekų, gamybinės kilmės biologiškai skaidžių atliekų, gyvūninės kilmės šalutinių produktų, nuotekų dumblo kompostavimo)",
    area: "100"
  },
  {
    id: 98,
    label:
      "Biologiškai skaidžių atliekų kompostavimo aikštelė (kompostuojant maisto atliekas, gamybinės kilmės biologiškai skaidžias atliekas, gyvūninės kilmės šalutinius produktus, nuotekų dumblą)",
    area: "500"
  },
  {
    id: 99,
    label:
      "Atliekų laikymo, perkrovimo ir rūšiavimo įmonės įrenginiai (statiniai)",
    area: "100"
  },
  { id: 100, label: "Gyvūnų laidojimo vietos (kapinės)", area: "100" },
  {
    id: 101,
    label:
      "Steigiamos naujos kapinės ir plečiamos įsteigtų kapinių teritorijos",
    area: "100"
  },
  { id: 102, label: "Krematoriumas", area: "200" },
  {
    id: 103,
    label: "Uždarytas pavojingųjų ir nepavojingųjų atliekų sąvartynas*",
    area: "100"
  },
  {
    id: 104,
    label:
      "Atviri mechaninio ir (arba) biologinio ir (arba) cheminio nuotekų valymo įrenginiai"
  },
  {
    id: 105,
    label: "Nuotekų dumblo sausinimo ir (arba) kaupimo aikštelės"
  },
  {
    id: 106,
    label:
      "Nuotekų dumblo apdorojimo įrenginiai (biodujų gavyba ir (arba) terminis dumblo apdorojimas ir (arba) kompostavimas)"
  },
  {
    id: 107,
    label:
      "Uždari mechaninio ir (arba) biologinio ir (arba) cheminio nuotekų valymo įrenginiai"
  },
  {
    id: 108,
    label: "Mechaniškai apvalytų nuotekų antžeminė filtravimo sistema"
  },
  { id: 109, label: "Triušių (nuo 300)", area: "100" },
  {
    id: 110,
    label:
      "Arklių, avių, ožkų, žvėrelių, paukščių, išskyrus broilerius ir vištas (nuo 300)",
    area: "300"
  },
  { id: 111, label: "Kiaulių nuo 300 iki 499", area: "500" },
  { id: 115, label: "Kiaulių nuo 500", area: "1000" },
  { id: 112, label: "Broilerių, vištų (nuo 300)", area: "1000" },
  { id: 113, label: "Galvijų nuo 300 iki 1199", area: "300" },
  { id: 114, label: "Galvijų 1200", area: "500" }
];

export const activityCode = [
  { codeId: 1, activityId: 1 },
  { codeId: 2, activityId: 2 },
  { codeId: 3, activityId: 3 },
  { codeId: 3, activityId: 4 },
  { codeId: 3, activityId: 5 },
  { codeId: 3, activityId: 6 },
  { codeId: 3, activityId: 7 },
  { codeId: 3, activityId: 8 },
  { codeId: 4, activityId: 7 },
  { codeId: 4, activityId: 8 },
  { codeId: 4, activityId: 9 },
  { codeId: 4, activityId: 10 },
  { codeId: 4, activityId: 11 },
  { codeId: 4, activityId: 12 },
  { codeId: 5, activityId: 13 },
  { codeId: 6, activityId: 14 },
  { codeId: 7, activityId: 15 },
  { codeId: 8, activityId: 16 },
  { codeId: 9, activityId: 17 },
  { codeId: 9, activityId: 18 },
  { codeId: 9, activityId: 19 },
  { codeId: 10, activityId: 20 },
  { codeId: 10, activityId: 21 },
  { codeId: 10, activityId: 22 },
  { codeId: 11, activityId: 23 },
  { codeId: 12, activityId: 24 },
  { codeId: 13, activityId: 25 },
  { codeId: 14, activityId: 26 },
  { codeId: 15, activityId: 27 },
  { codeId: 16, activityId: 28 },
  { codeId: 17, activityId: 29 },
  { codeId: 17, activityId: 30 },
  { codeId: 17, activityId: 31 },
  { codeId: 17, activityId: 32 },
  { codeId: 17, activityId: 33 },
  { codeId: 18, activityId: 34 },
  { codeId: 19, activityId: 35 },
  { codeId: 20, activityId: 36 },
  { codeId: 21, activityId: 37 },
  { codeId: 22, activityId: 38 },
  { codeId: 23, activityId: 39 },
  { codeId: 23, activityId: 40 },
  { codeId: 24, activityId: 41 },
  { codeId: 25, activityId: 42 },
  { codeId: 26, activityId: 43 },
  { codeId: 26, activityId: 44 },
  { codeId: 26, activityId: 45 },
  { codeId: 27, activityId: 46 },
  { codeId: 28, activityId: 47 },
  { codeId: 28, activityId: 48 },
  { codeId: 28, activityId: 49 },
  { codeId: 29, activityId: 50 },
  { codeId: 30, activityId: 51 },
  { codeId: 31, activityId: 52 },
  { codeId: 32, activityId: 53 },
  { codeId: 32, activityId: 54 },
  { codeId: 32, activityId: 55 },
  { codeId: 33, activityId: 53 },
  { codeId: 33, activityId: 54 },
  { codeId: 33, activityId: 55 },
  { codeId: 34, activityId: 57 },
  { codeId: 34, activityId: 58 },
  { codeId: 34, activityId: 59 },
  { codeId: 35, activityId: 61 },
  { codeId: 35, activityId: 62 },
  { codeId: 35, activityId: 63 },
  { codeId: 36, activityId: 64 },
  { codeId: 37, activityId: 65 },
  { codeId: 38, activityId: 66 },
  { codeId: 39, activityId: 67 },
  { codeId: 40, activityId: 68 },
  { codeId: 40, activityId: 69 },
  { codeId: 41, activityId: 70 },
  { codeId: 42, activityId: 70 },
  { codeId: 43, activityId: 71 },
  { codeId: 44, activityId: 72 },
  { codeId: 44, activityId: 73 },
  { codeId: 45, activityId: 72 },
  { codeId: 45, activityId: 74 },
  { codeId: 46, activityId: 72 },
  { codeId: 46, activityId: 75 },
  { codeId: 47, activityId: 72 },
  { codeId: 47, activityId: 76 },
  { codeId: 48, activityId: 72 },
  { codeId: 48, activityId: 77 },
  { codeId: 49, activityId: 78 },
  { codeId: 50, activityId: 79 },
  { codeId: 51, activityId: 80 },
  { codeId: 52, activityId: 81 },
  { codeId: 53, activityId: 82 },
  { codeId: 54, activityId: 82 },
  { codeId: 55, activityId: 57 },
  { codeId: 55, activityId: 58 },
  { codeId: 55, activityId: 59 },
  { codeId: 56, activityId: 57 },
  { codeId: 56, activityId: 58 },
  { codeId: 56, activityId: 59 },
  { codeId: 57, activityId: 79 },
  { codeId: 58, activityId: 83 },
  { codeId: 58, activityId: 84 },
  { codeId: 59, activityId: 85 },
  { codeId: 59, activityId: 86 },
  { codeId: 59, activityId: 87 },
  { codeId: 60, activityId: 83 },
  { codeId: 60, activityId: 84 },
  { codeId: 61, activityId: 89 },
  { codeId: 61, activityId: 90 },
  { codeId: 61, activityId: 91 },
  { codeId: 62, activityId: 88 },
  { codeId: 62, activityId: 89 },
  { codeId: 62, activityId: 90 },
  { codeId: 62, activityId: 91 },
  { codeId: 63, activityId: 89 },
  { codeId: 63, activityId: 90 },
  { codeId: 63, activityId: 91 },
  { codeId: 63, activityId: 92 },
  { codeId: 64, activityId: 53 },
  { codeId: 64, activityId: 56 },
  { codeId: 64, activityId: 57 },
  { codeId: 64, activityId: 60 },
  { codeId: 64, activityId: 89 },
  { codeId: 64, activityId: 90 },
  { codeId: 64, activityId: 91 },
  { codeId: 64, activityId: 92 },
  { codeId: 65, activityId: 93 },
  { codeId: 66, activityId: 94 },
  { codeId: 67, activityId: 95 },
  { codeId: 68, activityId: 96 },
  { codeId: 69, activityId: 97 },
  { codeId: 70, activityId: 98 },
  { codeId: 71, activityId: 99 },
  { codeId: 72, activityId: 102 },
  { codeId: 73, activityId: 103 },
  { codeId: 74, activityId: 99 },
  { codeId: 75, activityId: 104 },
  { codeId: 75, activityId: 105 },
  { codeId: 75, activityId: 106 },
  { codeId: 75, activityId: 107 },
  { codeId: 75, activityId: 108 },
  { codeId: 76, activityId: 109 },
  { codeId: 77, activityId: 110 },
  { codeId: 78, activityId: 111 },
  { codeId: 78, activityId: 115 },
  { codeId: 79, activityId: 112 },
  { codeId: 80, activityId: 113 },
  { codeId: 80, activityId: 114 }
];

export const activityRange = [
  { id: 1, rangeId: 1, activityId: 104 },
  { id: 2, rangeId: 2, activityId: 104 },
  { id: 3, rangeId: 3, activityId: 104 },
  { id: 4, rangeId: 4, activityId: 104 },
  { id: 5, rangeId: 5, activityId: 104 },
  { id: 6, rangeId: 1, activityId: 105 },
  { id: 7, rangeId: 2, activityId: 105 },
  { id: 8, rangeId: 3, activityId: 105 },
  { id: 9, rangeId: 4, activityId: 105 },
  { id: 10, rangeId: 5, activityId: 105 },
  { id: 11, rangeId: 1, activityId: 106 },
  { id: 12, rangeId: 2, activityId: 106 },
  { id: 13, rangeId: 3, activityId: 106 },
  { id: 14, rangeId: 4, activityId: 106 },
  { id: 15, rangeId: 5, activityId: 106 },
  { id: 16, rangeId: 1, activityId: 107 },
  { id: 17, rangeId: 2, activityId: 107 },
  { id: 18, rangeId: 3, activityId: 107 },
  { id: 19, rangeId: 4, activityId: 107 },
  { id: 20, rangeId: 5, activityId: 107 },
  { id: 21, rangeId: 1, activityId: 108 },
  { id: 22, rangeId: 2, activityId: 108 },
  { id: 23, rangeId: 3, activityId: 108 },
  { id: 24, rangeId: 4, activityId: 108 },
  { id: 25, rangeId: 5, activityId: 108 }
];

export const activityRangeValue = [
  { id: 1, rangeActivityId: 2, area: "100" },
  { id: 2, rangeActivityId: 3, area: "200" },
  { id: 3, rangeActivityId: 4, area: "400" },
  { id: 4, rangeActivityId: 5, area: "500" },
  { id: 5, rangeActivityId: 6, area: "100" },
  { id: 6, rangeActivityId: 7, area: "150" },
  { id: 7, rangeActivityId: 8, area: "200" },
  { id: 8, rangeActivityId: 9, area: "400" },
  { id: 9, rangeActivityId: 10, area: "500" },
  { id: 10, rangeActivityId: 11, area: "100" },
  { id: 11, rangeActivityId: 12, area: "150" },
  { id: 12, rangeActivityId: 13, area: "200" },
  { id: 13, rangeActivityId: 14, area: "400" },
  { id: 14, rangeActivityId: 15, area: "500" },
  { id: 15, rangeActivityId: 19, area: "100" },
  { id: 16, rangeActivityId: 20, area: "200" },
  { id: 17, rangeActivityId: 21, area: "100" },
  { id: 18, rangeActivityId: 22, area: "200" },
  { id: 19, rangeActivityId: 23, area: "300" },
  { id: 20, rangeActivityId: 24, area: "400" },
  { id: 21, rangeActivityId: 25, area: "500" }
];

export const ranges = [
  {
    id: 1,
    label: "nuo 0,005 iki 0,05"
  },
  {
    id: 2,
    label: "nuo 0,05 iki 0,2"
  },
  {
    id: 3,
    label: "nuo 0,2 iki 5"
  },
  {
    id: 4,
    label: "nuo 5 iki 50"
  },
  {
    id: 5,
    label: "daugiau kaip 50"
  }
];
